import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { FirstDataRenderedEvent, GridApi, GridOptions, RowSelectedEvent } from 'ag-grid-community';
import { AgCheckboxComponent } from '@components/ag-actions/ag-checkbox.component';
import {
  CategoryType,
  EntityType,
  EventType,
  GqlService,
  UpdateActivityDiscountsInput,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { CategoryService } from '../category/category.service';
import { CategoryQuery } from '../category/category.query';
import { ActivityQuery } from '../activity/activity.query';
import { ActivityStore } from '../activity/activity.store';

@UntilDestroy()
@Component({
  templateUrl: './forecast-discount-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
      }

      ::ng-deep .forecast-discount-dialog-table .ag-row-selected.ag-row-odd:not(.ag-row-hover) {
        background: var(--aux-gray-light) !important;
      }
      ::ng-deep .forecast-discount-dialog-table .ag-row-selected.ag-row-even:not(.ag-row-hover) {
        background: white !important;
      }
      ::ng-deep .forecast-discount-dialog-table .ag-row-selected.ag-row-hover {
        background-color: rgba(33, 150, 243, 0.1);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastDiscountDialogComponent {
  loading$ = new BehaviorSubject(false);

  gridApi!: GridApi;

  constructor(
    public ref: CustomOverlayRef<any, { vendorId: string }>,
    private categoryService: CategoryService,
    private categoryQuery: CategoryQuery,
    private activityQuery: ActivityQuery,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private activityStore: ActivityStore
  ) {}

  gridData$ = this.categoryQuery
    .selectAll({
      filterBy: (entity) =>
        entity.parent_category_id == null &&
        entity.category_type !== CategoryType.CATEGORY_INVESTIGATOR &&
        entity.category_type !== CategoryType.CATEGORY_PASSTHROUGH,
    })
    .pipe(
      map((categories) => {
        const rowData = [] as {
          category_ids: string[];
          activity_id: string;
          activity_name: string;
          activity_discount_flag: boolean;
        }[];
        categories.forEach((category) => {
          this.tree_filler(category.id, rowData);
        });
        return rowData;
      })
    );

  gridOptions: GridOptions = {
    treeData: true,
    defaultColDef: {
      sortable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      editable: false,
    },
    suppressMenuHide: true,
    headerHeight: 40,
    suppressCellFocus: true,
    suppressRowClickSelection: true,
    suppressAggFuncInHeader: true,
    // groupSelectsChildren: true,
    rowSelection: 'multiple',
    // rowClassRules: {
    //   'has-error': (params: any) => params.data.showError,
    // },
    getDataPath: (data) => {
      return data?.category_ids;
    },
    autoGroupColumnDef: {
      headerClass: 'ag-header-align-center',
      headerName: 'Activities',
      suppressMenu: true,
      minWidth: 350,
      width: 350,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        suppressCount: true,
      },
    },
    columnDefs: [
      {
        field: 'activity_id',
        hide: true,
      },
      {
        headerName: 'Discount Applies',
        field: 'activity_discount_flag',
        minWidth: 120,
        width: 150,
        maxWidth: 200,
        editable: false,
        cellRenderer: AgCheckboxComponent,
      },
    ],
  };

  tree_filler(
    category_id: string,
    row_data = [] as {
      category_ids: string[];
      activity_id: string;
      activity_name: string;
      activity_discount_flag: boolean;
    }[],
    hierarchy = [] as string[]
  ) {
    const category = this.categoryQuery.getEntity(category_id);
    if (category) {
      // eslint-disable-next-line no-param-reassign
      hierarchy = [...hierarchy, `${category.display_label || ''}${category.name}`];
      category.sub_category_ids.forEach((sub_category_id) => {
        this.tree_filler(sub_category_id, row_data, hierarchy);
      });

      category.activity_ids.forEach((activity_id) => {
        const activity = this.activityQuery.getEntity(activity_id);
        const name = `${activity?.display_label || ''} ${activity?.name || ''}`;
        row_data.push({
          category_ids: [...hierarchy, name],
          activity_id,
          activity_name: name,
          activity_discount_flag: !!activity?.discount_flag,
        });
      });
    }
  }

  async onSave() {
    const updatedDiscounts: Array<UpdateActivityDiscountsInput> = [];
    this.gridApi.forEachLeafNode((leaf) => {
      const { activity_discount_flag, activity_id } = leaf.data;
      const activity = this.activityQuery.getEntity(activity_id);
      if (activity && activity.discount_flag !== activity_discount_flag) {
        updatedDiscounts.push({
          activity_id,
          discount: activity_discount_flag,
        });
      }
    });

    if (updatedDiscounts.length) {
      const ref = this.overlayService.loading();
      const { success, data, errors } = await this.gqlService
        .updateActivityDiscounts$(updatedDiscounts)
        .toPromise();

      let event_success = true;
      let event_errors: string[] = [];
      if (this.ref.data?.vendorId) {
        const { vendorId } = this.ref.data;
        ({ success: event_success, errors: event_errors } = await this.gqlService
          .processEvent$({
            type: EventType.BUDGET_FORECAST_SETTINGS_UPDATED,
            entity_type: EntityType.ORGANIZATION,
            entity_id: vendorId,
          })
          .toPromise());
      }

      ref.close();

      if (success && event_success && data) {
        this.overlayService.success();
        updatedDiscounts.forEach(({ activity_id, discount }) => {
          this.activityStore.upsert(activity_id, { discount_flag: discount });
        });
        this.ref.close(true);
      } else {
        this.overlayService.error(errors);
        this.overlayService.error(event_errors);
      }
    }
  }

  onRowSelected(e: RowSelectedEvent) {
    const { parent } = e.node;
    if (parent && parent.level !== -1) {
      const siblings = parent.childrenAfterFilter || [];
      let indeterminate = false;
      let selectedCount = 0;
      for (const sibling of siblings) {
        if (sibling.data?.activity_discount_flag === 'indeterminate') {
          indeterminate = true;
          break;
        }
        if (sibling.isSelected()) {
          selectedCount += 1;
        }
      }
      if (indeterminate) {
        parent.setDataValue('activity_discount_flag', 'indeterminate');
        if (!parent.isSelected()) {
          parent.setSelected(true);
        }
        parent.setSelected(false);
      } else {
        const isSomeSelected = selectedCount > 0;
        if (isSomeSelected) {
          const isAllSelected = siblings.length === selectedCount;

          parent.setDataValue('activity_discount_flag', isAllSelected ? true : 'indeterminate');
          if (!parent.isSelected() && !isAllSelected) {
            parent.setSelected(true);
          }
          parent.setSelected(isAllSelected);
        } else {
          parent.setSelected(false);
          parent.setDataValue('activity_discount_flag', false);
        }
      }
    }
    const children = e.node.childrenAfterFilter || [];
    switch (e.data?.activity_discount_flag) {
      case true: {
        children.forEach((child) => {
          if (!child.data?.activity_discount_flag) {
            child.setDataValue('activity_discount_flag', true);
            child.setSelected(true);
          }
        });
        break;
      }
      case false: {
        children.forEach((child) => {
          if (child.data?.activity_discount_flag) {
            child.setDataValue('activity_discount_flag', false);
            child.setSelected(false);
          }
        });
        break;
      }
      case 'indeterminate': {
        break;
      }
      default:
        break;
    }
  }

  onDataRendered({ api }: FirstDataRenderedEvent) {
    this.gridApi = api;
    api.forEachLeafNode((leaf) => {
      if (leaf.data.activity_discount_flag) {
        // this is a hack to trigger ag-grid to update the rows so the leaf rows should update the group rows
        leaf.setSelected(false);
        leaf.setSelected(true);
      }
    });
  }
}
