import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { Milestone } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface MilestoneState
  extends EntityState<Omit<RequireSome<Partial<Milestone>, 'id'>, 'milestone_category'>> {}

export type MilestoneModel = getEntityType<MilestoneState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'milestone' })
export class MilestoneStore extends EntityStore<MilestoneState> {
  constructor() {
    super({});
  }
}
