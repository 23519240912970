import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { NgHeroiconsModule } from '@dimaslz/ng-heroicons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgGridModule } from 'ag-grid-angular';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ChartsModule } from 'ng2-charts';
import 'ag-grid-enterprise';
import { DirectivesModule } from '@directives/directives.module';
import { ComponentsModule } from '@components/components.module';
import { PipeModule } from '@pipes/pipe.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { DialogsModule } from '@components/dialogs/dialogs.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { AppInitService } from '@services/app-init.service';
import { ApiService } from '@services/api.service';
import { TimeagoModule } from 'ngx-timeago';

import { CanDeactivateGuard } from '@services/can-deactivate.guard';
import { OverlayService } from '@services/overlay.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { InvoicesComponent } from './pages/vendor-payments-page/tabs/invoices/invoices.component';
import { RiskAnalyticsComponent } from './pages/risk-analytics/risk-analytics.component';
import { PatientTrackerComponent } from './pages/patients-page/patient-tracker/patient-tracker.component';
import { ScenarioComponent } from './pages/scenario/scenario.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { UsersComponent } from './pages/settings/users/users.component';
import { VendorsComponent } from './pages/vendor-payments-page/tabs/vendors/vendors.component';
import { AuthModule } from './modules/auth/auth.module';
import { InvoiceComponent } from './pages/vendor-payments-page/tabs/invoices/invoice/invoice.component';
import { InvoiceCardComponent } from './pages/vendor-payments-page/tabs/invoices/invoice/invoice-card.component';
import { AgInvoiceActionsComponent } from './pages/vendor-payments-page/tabs/invoices/ag-invoice-actions/ag-invoice-actions.component';
import { RiskCardComponent } from './pages/risk-analytics/risk-card/risk-card.component';
import { RiskAlertDialogComponent } from './pages/risk-analytics/risk-card/risk-alert-dialog/risk-alert-dialog.component';
import { NewTrialDialogComponent } from './layouts/main-layout/new-trial-dialog/new-trial-dialog.component';
import { CisLogComponent } from './pages/budget-page/tabs/cis-log/cis-log.component';
import { ChangeLogItemStatusComponent } from './pages/budget-page/tabs/cis-log/cis-log-status.component';
import { ChangeOrderReviewComponent } from './pages/budget-page/tabs/change-order-review/change-order-review.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { AgDocumentActionsComponent } from './pages/documents/ag-document-actions/ag-document-actions.component';
import { NewInvoiceDialogComponent } from './pages/vendor-payments-page/tabs/invoices/new-invoice-dialog/new-invoice-dialog.component';
import { UploadDocumentsDialogComponent } from './pages/vendor-payments-page/tabs/invoices/upload-documents-dialog/upload-documents-dialog.component';
import { SitesComponent } from './pages/sites-page/sites/sites.component';
import { AgCisLogApprovalComponent } from './pages/budget-page/tabs/cis-log/ag-cis-log-approval/ag-cis-log-approval.component';
import { AgCisLogActionsComponent } from './pages/budget-page/tabs/cis-log/ag-cis-log-actions/ag-cis-log-actions.component';
import { AgCisLogUserApprovalComponent } from './pages/budget-page/tabs/cis-log/ag-cis-log-user-approval/ag-cis-log-user-approval.component';
import { ChangeLogItemDialogComponent } from './pages/budget-page/tabs/cis-log/change-log-item-dialog/change-log-item-dialog.component';
import { ForecastAccrualsPageComponent } from './pages/forecast-accruals-page/forecast-accruals-page.component';
import { ForecastComponent } from './pages/forecast-accruals-page/tabs/forecast/forecast.component';
import { TimelineComponent } from './pages/forecast-accruals-page/tabs/timeline-group/timeline/timeline.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { BudgetPageComponent } from './pages/budget-page/budget-page.component';
import {
  BudgetUploadComponent,
  ReconciliationComponent,
  BudgetLibraryComponent,
  PaymentsHistoryComponent,
  BudgetEnhancedComponent,
  BudgetComponent,
} from './pages/budget-page/tabs';
import { SiteDialogComponent } from './pages/sites-page/sites/site-dialog/site-dialog.component';
import { ForecastTimelineDialogComponent } from './pages/forecast-accruals-page/tabs/forecast/forecast-timeline-dialog/forecast-timeline-dialog.component';
import { ForecastDiscountDialogComponent } from './pages/forecast-accruals-page/tabs/forecast/forecast-discount-dialog/forecast-discount-dialog.component';
import { ForecastManualOverrideDialogComponent } from './pages/forecast-accruals-page/tabs/forecast/forecast-manual-override-dialog/forecast-manual-override-dialog.component';
import { TimelineDialogComponent } from './pages/forecast-accruals-page/tabs/timeline-group/timeline/timeline-dialog/timeline-dialog.component';
import { SpecificationsComponent } from './pages/forecast-accruals-page/tabs/specifications/specifications.component';
import { SpecificationsUploadComponent } from './pages/forecast-accruals-page/tabs/specifications/specifications-upload/specifications-upload.component';
import { VendorPaymentsPageComponent } from './pages/vendor-payments-page/vendor-payments-page.component';
import { PurchaseOrdersComponent } from './pages/vendor-payments-page/tabs/purchase-orders/purchase-orders.component';
import { AgPoDownloadComponent } from './pages/vendor-payments-page/tabs/purchase-orders/ag-po-download.component';
import { AgPoViewInvoiceComponent } from './pages/vendor-payments-page/tabs/purchase-orders/ag-po-view-invoice.component';
import { NewPoDialogComponent } from './pages/vendor-payments-page/tabs/purchase-orders/new-po-dialog/new-po-dialog.component';
import { OrganizationDialogComponent } from './pages/vendor-payments-page/tabs/vendors/organization-dialog/organization-dialog.component';
import { MultipleOrganizationsDialogComponent } from './pages/vendor-payments-page/tabs/vendors/multiple-organizations-dialog/multiple-organizations-dialog';
import { AccountComponent } from './pages/account/account.component';
import { ScenarioManagerComponent } from './pages/scenario/scenario-manager/scenario-manager.component';
import { ScenarioDialogComponent } from './pages/scenario/scenario-manager/scenario-dialog/scenario-dialog.component';
import { PaymentScheduleComponent } from './pages/vendor-payments-page/tabs/payment-schedule/payment-schedule.component';
import { InvestigatorForecastComponent } from './pages/sites-page/investigator-forecast/investigator-forecast.component';
import { InvestigatorDetailComponent } from './pages/sites-page/investigator-detail/investigator-detail.component';
import { PaymentMilestonesComponent } from './pages/vendor-payments-page/tabs/payment-milestones/payment-milestones.component';
import { QuarterCloseComponent } from './pages/closing-page/tabs/quarter-close/quarter-close.component';
import {
  PatientProtocolComponent,
  PatientBudgetTableComponent,
  CurrencyCheckboxesComponent,
} from './pages/patients-page/patient-protocol';
import { PatientProtocolEditComponent } from './pages/patients-page/patient-protocol-edit/patient-protocol-edit.component';
import { PatientTrackerUploadComponent } from './pages/patients-page/patient-tracker/patient-tracker-upload/patient-tracker-upload.component';
import { SiteDriverUploadComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/forecast-sites/site-driver-upload/site-driver-upload.component';
import { PatientDriverUploadComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-driver-upload/patient-driver-upload.component';
import { PatientsPageComponent } from './pages/patients-page/patients-page.component';
import { AgQuarterCloseApprovalComponent } from './pages/closing-page/tabs/quarter-close/ag-quarter-close-approval.component';
import { QuarterCloseManualOverrideComponent } from './pages/closing-page/tabs/quarter-close/quarter-close-manual-override.component';
import { AddVendorEstimateUploadComponent } from './pages/closing-page/tabs/quarter-close/add-vendor-estimate-upload/add-vendor-estimate-upload.component';
import { TimelineDependencyComponent } from './pages/forecast-accruals-page/tabs/timeline-group/timeline/timeline-dependency/timeline-dependency.component';
import { CompareNewComponent } from './pages/budget-page/tabs/compare-new/compare-new.component';
import { AccountSettingsComponent } from './pages/account/tabs/account-settings/account-settings.component';
import { AccountNotificationsComponent } from './pages/account/tabs/account-notifications/account-notifications.component';
import { DocumentUploadComponent } from './pages/documents/document-upload/document-upload.component';
import { AgPoActionsComponent } from './pages/vendor-payments-page/tabs/purchase-orders/ag-po-actions.component ';
import { ChangeOrderActionsComponent } from './pages/budget-page/tabs/change-order/change-order-actions.component';
import { ChangeOrderComponent } from './pages/budget-page/tabs/change-order/change-order.component';
import { ChangeOrderStatusComponent } from './pages/budget-page/tabs/change-order/change-order-status.component';
import { ChangeOrderUploadComponent } from './pages/budget-page/tabs/change-order/change-order-upload/change-order-upload.component';
import { PatientGroupsComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/patient-groups.component';
import { ChangeOrderDetailComponent } from './pages/budget-page/tabs/change-order-detail/change-order-detail.component';
import { InvoicesDetailComponent } from './pages/vendor-payments-page/tabs/invoices/invoices-detail/invoices-detail.component';
import { InvoicesStatusComponent } from './pages/vendor-payments-page/tabs/invoices/invoices-status.component';
import { PaymentStatusComponent } from './pages/vendor-payments-page/tabs/invoices/payment-status.component';
import { VariationStatusComponent } from './pages/design-system/tables';
import { AgHeaderExpandComponent } from './pages/budget-page/tabs/budget/ag-header-expand.component';
import { AgHeaderActionsComponent } from './pages/vendor-payments-page/tabs/invoices/ag-invoice-actions/ag-header-actions.component';
import { ChangeOrderBudgetUploadComponent } from './pages/budget-page/tabs/change-order-detail/change-order-budget-upload.component';
import { ChangeOrderVerificationCardComponent } from './pages/budget-page/tabs/change-order-detail/change-order-verification-card.component';
import { PatientCurveGroupComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-curve-group/patient-curve-group.component';
import { VendorEstimateUploadComponent } from './pages/closing-page/tabs/quarter-close/vendor-estimate-upload/vendor-estimate-upload.component';
import { AgQuarterCloseAdjustmentComponent } from './pages/closing-page/tabs/quarter-close/ag-quarter-close-adjustment.component';
import { AgInMonthGroupHeaderComponent } from './pages/closing-page/tabs/quarter-close/ag-in-month-group-header.component';
import { QuarterCloseDialogComponent } from './pages/closing-page/tabs/quarter-close/quarter-close-dialog/quarter-close-dialog.component';
import { AgQuarterCloseGroupHeaderComponent } from './pages/closing-page/tabs/quarter-close/ag-quarter-close-group-header.component';
import { BudgetLibraryActionsComponent } from './pages/budget-page/tabs/budget/budget-library/budget-library-actions.component';
import { BudgetLibraryUploadBaselineComponent } from './pages/budget-page/tabs/budget/budget-library/budget-library-upload-baseline.component';
import { DesignSystemRoutingModule, DesignSystemModule } from './pages/design-system';
import { ColumnChooserComponent } from './pages/budget-page/tabs/budget-enhanced/column-chooser-component/column-chooser.component';
import {
  CloseQuarterCheckListComponent,
  StaticCheckListComponent,
} from './pages/closing-page/tabs/quarter-close/close-quarter-check-list';
import {
  WorkflowPanelComponent,
  WorkflowPanelGeneralItemComponent,
} from './pages/closing-page/tabs/quarter-close/workflow-panel';
import './chart-default-styles';
import { AuditHistoryComponent } from './pages/audit-history/audit-history.component';
import { AuditHistoryCategoryComponent } from './pages/audit-history/state/actions/audit-history-category.component';
import { PortfolioDashboardModule } from './pages/portfolio-dashboard';
import { TrialTasksComponent } from './layouts/main-layout/trial-tasks/trial-tasks.component';
import { TimelineGroupComponent } from './pages/forecast-accruals-page/tabs/timeline-group/timeline-group.component';
import { ClosingPageComponent } from './pages/closing-page/closing-page.component';
import { UserPermissionsComponent } from './pages/settings/user-permissions/user-permissions.component';
import { BudgetCustomCreateComponent } from './pages/budget-page/tabs/budget/state/budget-custom-create.component';
import { BudgetCustomUpdateComponent } from './pages/budget-page/tabs/budget/state/budget-custom-update.component';
import { AgQuarterExpandableGroupHeaderComponent } from './pages/forecast-accruals-page/tabs/quarter-close/ag-quarter-expandable-group-header.component';
import { SitesPageComponent } from './pages/sites-page/sites-page.component';
import { ForecastSiteCurvesComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/forecast-sites/forecast-site-curves.component';
import { ForecastSiteDriversComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/forecast-site-drivers.component';
import { ForecastSiteGroupsComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/forecast-sites/forecast-site-groups.component';
import { PatientDriversComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/patient-drivers.component';
import { PatientCurvesComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-curves.component';
import { SiteBlendedCurveModalComponent } from './pages/forecast-accruals-page/tabs/forecast/drivers/forecast-sites/site-blended-curve-modal/site-blended-curve-modal.component';
import { ExchangeRatesComponent } from './pages/settings/exchange-rates/exchange-rates.component';
import { IntegrationsComponent } from './pages/settings/integrations/integrations.component';
import { QuarterCloseProcessingConfirmationComponent } from './pages/closing-page/tabs/quarter-close/quarter-close-processing-confirmation.component';
import { QuarterCloseProcessingConfirmationHeaderComponent } from './pages/closing-page/tabs/quarter-close/quarter-close-processing-confirmation-header.component';
import { AddBulkSitesDialogComponent } from './modules/onboarding/new-site/add-bulk-sites-dialog/add-bulk-sites-dialog.component';
import { DocumentLibraryComponent } from './pages/documents/document-library/document-library.component';
import { AgDropdownComponent, AgInputComponent } from './pages/documents/document-library/controls';
import { AgControlComponent } from './pages/documents/document-library/controls/ag-control.component';
import { ForecastSiteCurveSettingsComponent } from './pages/forecast-accruals-page/tabs/forecast/forecast-site-curve-settings/forecast-site-curve-settings.component';
import { SnapshotModalComponent } from './pages/budget-page/tabs/budget-enhanced/snapshot-modal/snapshot-modal.component';
import { CompareDropdownComponent } from './pages/budget-page/tabs/budget-enhanced/compare-dropdown/compare-dropdown.component';
import { DocumentLibraryFiltersComponent } from './pages/documents/document-library-filters/document-library-filters.component';
import { EditMultipleDocumentsModalComponent } from './pages/documents/edit-multiple-documents-modal/edit-multiple-documents-modal';

function launchDarklyInit(service: LaunchDarklyService) {
  return (): Promise<void> => {
    return service.initLaunchDarkly();
  };
}

function appServiceInit(api: ApiService, service: AppInitService) {
  return async (): Promise<void> => {
    const x = await api.getAppVersion().toPromise();
    // eslint-disable-next-line no-param-reassign
    service.APP_VERSION = x.version;
  };
}

function routerInit(service: LaunchDarklyService, router: Router) {
  return async (): Promise<void> => {
    await service.loaded$.pipe(first((s) => s)).toPromise();
    const flags = service.flags$.getValue();
    const { config } = router;

    const mainRoute = config.find((route) => {
      return route.path === '';
    });

    if (mainRoute?.children) {
      mainRoute.children.push({
        path: '**',
        redirectTo: flags.nav_portfolio ? 'home' : 'budget',
      });
    }
    router.resetConfig(config);
  };
}

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    InvoicesComponent,
    BudgetComponent,
    BudgetEnhancedComponent,
    RiskAnalyticsComponent,
    ForecastComponent,
    ChangeOrderReviewComponent,
    PatientTrackerComponent,
    ScenarioComponent,
    SettingsComponent,
    UsersComponent,
    VendorsComponent,
    InvoiceComponent,
    InvoiceCardComponent,
    AgInvoiceActionsComponent,
    RiskCardComponent,
    RiskAlertDialogComponent,
    NewTrialDialogComponent,
    CisLogComponent,
    ChangeLogItemStatusComponent,
    DocumentsComponent,
    AgDocumentActionsComponent,
    NewInvoiceDialogComponent,
    UploadDocumentsDialogComponent,
    SitesComponent,
    AddBulkSitesDialogComponent,
    AgCisLogApprovalComponent,
    AgCisLogUserApprovalComponent,
    AgCisLogActionsComponent,
    ChangeLogItemDialogComponent,
    ForecastAccrualsPageComponent,
    TimelineComponent,
    DashboardComponent,
    BudgetPageComponent,
    BudgetUploadComponent,
    SiteDialogComponent,
    ForecastTimelineDialogComponent,
    ForecastManualOverrideDialogComponent,
    TimelineDialogComponent,
    SpecificationsComponent,
    SpecificationsUploadComponent,
    ChangeOrderUploadComponent,
    VendorPaymentsPageComponent,
    PurchaseOrdersComponent,
    AgPoDownloadComponent,
    AgPoViewInvoiceComponent,
    NewPoDialogComponent,
    OrganizationDialogComponent,
    PatientCurvesComponent,
    ForecastSiteCurvesComponent,
    PatientCurvesComponent,
    ForecastSiteDriversComponent,
    PatientDriversComponent,
    ForecastSiteGroupsComponent,
    PatientGroupsComponent,
    AccountComponent,
    ScenarioManagerComponent,
    ScenarioDialogComponent,
    PaymentScheduleComponent,
    InvestigatorForecastComponent,
    InvestigatorDetailComponent,
    PaymentMilestonesComponent,
    QuarterCloseComponent,
    PatientProtocolComponent,
    PatientProtocolEditComponent,
    PatientTrackerUploadComponent,
    SiteDriverUploadComponent,
    PatientDriverUploadComponent,
    PatientCurveGroupComponent,
    PatientsPageComponent,
    SitesPageComponent,
    AgQuarterCloseApprovalComponent,
    QuarterCloseManualOverrideComponent,
    ReconciliationComponent,
    AddVendorEstimateUploadComponent,
    VendorEstimateUploadComponent,
    DocumentUploadComponent,
    TimelineDependencyComponent,
    CompareNewComponent,
    AccountSettingsComponent,
    AccountNotificationsComponent,
    ChangeOrderActionsComponent,
    ChangeOrderComponent,
    ChangeOrderStatusComponent,
    AgPoActionsComponent,
    ChangeOrderDetailComponent,
    InvoicesDetailComponent,
    InvoicesStatusComponent,
    AuditHistoryCategoryComponent,
    PaymentStatusComponent,
    VariationStatusComponent,
    AgHeaderExpandComponent,
    ChangeOrderBudgetUploadComponent,
    ChangeOrderVerificationCardComponent,
    AgHeaderActionsComponent,
    AgQuarterCloseAdjustmentComponent,
    AgInMonthGroupHeaderComponent,
    QuarterCloseDialogComponent,
    ForecastDiscountDialogComponent,
    AgQuarterCloseGroupHeaderComponent,
    BudgetLibraryComponent,
    BudgetLibraryActionsComponent,
    PatientBudgetTableComponent,
    PaymentsHistoryComponent,
    BudgetLibraryUploadBaselineComponent,
    ColumnChooserComponent,
    CloseQuarterCheckListComponent,
    WorkflowPanelComponent,
    StaticCheckListComponent,
    WorkflowPanelGeneralItemComponent,
    AuditHistoryComponent,
    AuditHistoryCategoryComponent,
    TrialTasksComponent,
    BudgetCustomCreateComponent,
    BudgetCustomUpdateComponent,
    AgQuarterExpandableGroupHeaderComponent,
    TimelineGroupComponent,
    ClosingPageComponent,
    UserPermissionsComponent,
    SiteBlendedCurveModalComponent,
    ExchangeRatesComponent,
    CurrencyCheckboxesComponent,
    IntegrationsComponent,
    QuarterCloseProcessingConfirmationComponent,
    QuarterCloseProcessingConfirmationHeaderComponent,
    MultipleOrganizationsDialogComponent,
    DocumentLibraryComponent,
    AgDropdownComponent,
    AgInputComponent,
    AgControlComponent,
    ForecastSiteCurveSettingsComponent,
    SnapshotModalComponent,
    CompareDropdownComponent,
    DocumentLibraryFiltersComponent,
    EditMultipleDocumentsModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DesignSystemRoutingModule,
    DesignSystemModule,
    PortfolioDashboardModule,
    TimeagoModule,
    HttpClientModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    DirectivesModule,
    PipeModule,
    ComponentsModule,
    NgHeroiconsModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    PortalModule,
    AuthModule,
    AgGridModule.withComponents([AgHeaderExpandComponent]),
    ChartsModule,
    InlineSVGModule.forRoot({ baseUrl: '/assets/svg/' }),
    NgSelectModule,
    DialogsModule,
  ],
  providers: [
    OverlayService,
    LaunchDarklyService,
    CanDeactivateGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: launchDarklyInit,
      multi: true,
      deps: [LaunchDarklyService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appServiceInit,
      multi: true,
      deps: [ApiService, AppInitService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: routerInit,
      multi: true,
      deps: [LaunchDarklyService, Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
