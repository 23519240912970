<ng-template #forecastFilters>
  <div class="flex justify-between items-center">
    <div class="flex space-x-4">
      <div class="flex items-center">
        <div class="text-sm mr-2">Filter By</div>
        <ng-select
          class="w-64 text-sm"
          [multiple]="false"
          [clearable]="false"
          (change)="onOrganizationSelected($event)"
          [formControl]="selectedVendor"
          placeholder="Vendors"
        >
          <ng-option
            [value]="vendor.id"
            *ngFor="let vendor of organizationQuery.allVendors$ | async"
          >
            <span [title]="vendor.name">{{ vendor.name }}</span>
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
</ng-template>

<aux-workflow-panel
  [workflowName]="workflowName"
  [isAdminUser]="isAdminUser"
  *ngIf="isQuarterCloseEnabled$ | async"
  className="mb-7"
  [processing]="(iCloseMonthsProcessing$ | async)!"
></aux-workflow-panel>

<ng-template #disabledPatient>
  <div
    class="w-full border rounded-md p-3 h-12 flex items-center justify-between bg-aux-gray-light text-aux-gray-dark-100"
  >
    <ng-container> Patient Curve </ng-container>
  </div>
</ng-template>

<ng-template let-primary_settings="primary_settings" #disabledSelect>
  <div
    class="w-full border rounded-md p-3 h-12 flex items-center justify-between bg-aux-gray-light text-aux-gray-dark-100"
    [ngSwitch]="primary_settings?.driver"
  >
    <ng-container *ngSwitchCase="DriverType.DRIVER_SERVICES_BLENDED"> Services Curve </ng-container>
    <ng-container *ngSwitchCase="DriverType.DRIVER_SITE">
      {{ getSiteGroupName(primary_settings?.driver_setting_id)?.name || '' }}
    </ng-container>
    <ng-container *ngSwitchCase="DriverType.DRIVER_TIME">
      {{ getTimeMethod(primary_settings.forecast_method) }}
    </ng-container>
    <ng-container *ngSwitchCase="DriverType.DRIVER_PATIENT">
      {{ getPatientGroupName(primary_settings?.driver_setting_id)?.name || 'Patient Curve' }}
    </ng-container>
    <ng-container *ngSwitchDefault> </ng-container>
  </div>
</ng-template>

<div
  class="border-8 h-32 m-auto mt-40 spinner w-32"
  *ngIf="(categoryQuery.selectLoading() | async) === true"
></div>

<ng-container *ngIf="(categoryQuery.selectLoading() | async) === false">
  <ng-container *ngIf="{ manual: isSelectedVendorHasManualForecast$ | async } as obj">
    <ng-container *ngIf="obj.manual === true">
      <div class="flex items-center justify-center flex-col text-aux-gray-dark mt-28 space-y-5">
        <div inlineSVG="documents.svg" class="w-20"></div>
        <div class="text-xl font-bold">Selected vendor has a manual forecast</div>
      </div>
    </ng-container>

    <ng-container *ngIf="obj.manual === false">
      <div *ngIf="showAnalyticsSection$ | async" class="grid grid-cols-3 gap-7.5">
        <div class="border rounded p-4 grid grid-cols-4 col-span-2 h-28">
          <div class="border-r font-bold flex items-center">Activities by Driver</div>
          <ng-container *ngIf="(activitiesByDriverLoading$ | async) === true">
            <div class="col-span-3 flex items-center justify-center">
              <div class="border-4 m-auto spinner"></div>
            </div>
          </ng-container>
          <ng-container *ngIf="(activitiesByDriverLoading$ | async) === false">
            <div
              class="col-span-3 pl-5 flex flex-col gap-3 justify-between tabular-nums"
              *ngIf="forecastQuery.select('activitiesByDriver') | async as activitiesByDriver"
            >
              <div class="flex text-white font-bold text-xs w-full h-6">
                <div
                  class="px-2 py-1 text-center bg-aux-blue rounded-l"
                  *ngIf="activitiesByDriver.Time.percentage as percentage"
                  [ngStyle]="{ flexGrow: percentage }"
                  [ngClass]="{ 'rounded-r': percentage === 100 }"
                >
                  {{ percentage }}%
                </div>
                <div
                  class="px-2 py-1 text-center bg-aux-green"
                  *ngIf="activitiesByDriver.Patient.percentage as percentage"
                  [ngStyle]="{ flexGrow: percentage }"
                  [ngClass]="{ rounded: percentage === 100 }"
                >
                  {{ percentage }}%
                </div>
                <div
                  class="px-2 py-1 text-center bg-aux-blue-light rounded-r"
                  *ngIf="activitiesByDriver.Site.percentage as percentage"
                  [ngStyle]="{ flexGrow: percentage }"
                  [ngClass]="{ 'rounded-l': percentage === 100 }"
                >
                  {{ percentage }}%
                </div>
              </div>
              <div class="grid grid-cols-3">
                <div>
                  <div class="flex items-center justify-center mb-2 mr-1">
                    <div class="w-4 h-4 rounded-full bg-aux-blue mr-2"></div>
                    <div class="text-xs">Time</div>
                  </div>
                  <div class="text-sm font-bold flex items-center justify-center mb-2 mr-1">
                    {{ activitiesByDriver.Time.costStr }}
                  </div>
                </div>
                <div>
                  <div class="flex items-center justify-center mb-2 mr-1">
                    <div class="w-4 h-4 rounded-full bg-aux-green mr-2"></div>
                    <div class="text-xs">Patient</div>
                  </div>
                  <div class="text-sm font-bold flex items-center justify-center mb-2 mr-1">
                    {{ activitiesByDriver.Patient.costStr }}
                  </div>
                </div>
                <div>
                  <div class="flex items-center justify-center mb-2 mr-1">
                    <div class="w-4 h-4 rounded-full bg-aux-blue-light mr-2"></div>
                    <div class="text-xs">Site</div>
                  </div>
                  <div class="text-sm font-bold flex items-center justify-center mb-2 mr-1">
                    {{ activitiesByDriver.Site.costStr }}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="border flex items-stretch justify-between">
          <ng-container *ngIf="(unforecastedCostsLoading$ | async) === true">
            <div class="flex-1 flex items-center justify-center">
              <div class="border-4 m-auto spinner"></div>
            </div>
          </ng-container>
          <ng-container *ngIf="(unforecastedCostsLoading$ | async) === false">
            <div class="p-3 flex bg-aux-gray-light items-center text-aux-blue-dark">
              Unforecasted Costs
            </div>
            <div class="p-3 text-xl flex items-center text-aux-blue-dark">
              <div
                *ngIf="forecastQuery.select('unforecastedCosts') | async"
                class="inline-block text-aux-red-dark pr-3"
                inlineSVG="alert.svg"
              ></div>
              {{ forecastQuery.select('unforecastedCosts') | async | money }}
            </div>
          </ng-container>
        </div>
      </div>

      <ng-container *ngIf="(categoryQuery.selectLoading() | async) === false">
        <div id="notSuccessForecastMessage" *ngIf="(successForecast$ | async) === false">
          <div class="flex items-start bg-aux-warn p-3 mt-8">
            <strong class="mr-1">Drivers Not Set:</strong> Drivers have not been added.
            <a
              *ngIf="isPatientDriversLinkVisible"
              [routerLink]="[patientDriversLink]"
              class="font-bold text-aux-blue-light-200 ml-1"
              >Add patient driver now.</a
            >

            <a
              *ngIf="isSiteDriversLinkVisible"
              [routerLink]="[siteDriversLink]"
              class="font-bold text-aux-blue-light-200 ml-3"
              >Add site driver now.</a
            >
          </div>
        </div>

        <div class="flex items-center space-x-6 mt-8 text-xs">
          <aux-checkbox
            id="hide-activities-with-no-remaining-cost"
            [checked]="(categoryStore.hideActivitiesWithNoRemainingCost$ | async)!"
            (customChange)="onHideActivitiesWithNoRemainingCost($event)"
          >
            Hide Activities with No Remaining Costs
          </aux-checkbox>
          <aux-checkbox
            id="show-only-unforecasted-activities"
            [checked]="(categoryStore.hideForecastedActivities$ | async)!"
            (customChange)="onShowOnlyUnforecasted($event)"
          >
            Show Only Unforecasted Activities
          </aux-checkbox>
        </div>

        <ng-container *ngIf="(categoryQuery.selectTopCategories$ | async)?.length">
          <div
            id="stickyHeader"
            [ngClass]="{ 'bg-aux-gray-light border-b shadow-sticky': whenStickyPosition === true }"
            class="font-medium gap-1.5 grid grid-cols-12 mb-1 px-8 mt-6 text-xs items-center z-10 bg-aux-white h-14 sticky top-0"
          >
            <div class="col-span-2"></div>
            <div class="col-span-1 flex justify-end mr-5">Total ($)</div>
            <div class="col-span-1">Remaining ($)</div>
            <div class="col-span-1 flex justify-center">Units</div>
            <div class="col-span-1 flex justify-center">UoM</div>
            <div class="col-span-2 flex justify-center">Driver</div>
            <div class="col-span-2 flex justify-center">Method</div>
            <div class="col-span-2 flex justify-center">Period</div>
          </div>
        </ng-container>

        <ng-container *ngIf="(categoryQuery.selectTopCategories$ | async)?.length">
          <ng-container *ngIf="serviceCategory$ | async">
            <ng-container *ngFor="let category of serviceCategory$ | async; trackBy: identify">
              <ng-container
                [ngTemplateOutlet]="categoryTemplate"
                [ngTemplateOutletContext]="{
                  cat: category,
                  isChild: false,
                  childApplyAll: false,
                  isCost: false,
                  childIndex: 0
                }"
              ></ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="costCategory$ | async">
            <ng-container *ngFor="let category of costCategory$ | async; trackBy: identify">
              <ng-container
                [ngTemplateOutlet]="categoryTemplate"
                [ngTemplateOutletContext]="{
                  cat: category,
                  isChild: false,
                  childApplyAll: false,
                  isCost: true,
                  childIndex: 0
                }"
              ></ng-container>
            </ng-container>
          </ng-container>
          <!-- Discount -->
          <ng-container *ngIf="(hideDiscounts$ | async) === false">
            <div
              class="font-medium rounded-md h-16 gap-5 grid grid-cols-12 mb-1 py-1 px-8 text-xs items-center z-10 bg-aux-gray-light border"
              [ngClass]="{ 'mb-20': (saveCheck | async)! }"
            >
              <div class="col-span-2 text-base font-bold">
                <div class="flex items-center font-semibold text-xs h-full justify-between">
                  Services Discount
                </div>
              </div>
              <div class="col-span-3 text-sm justify-items-end">
                <div class="flex text-xs items-center">
                  <span class="w-24">Percentage (%):</span>
                  <span>{{ changeDiscountTotalPercent.value }}</span>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- Discount -->
        </ng-container>

        <ng-template
          let-cat="cat"
          #categoryTemplate
          let-isChild="isChild"
          let-childApplyAll="childApplyAll"
          let-isCost="isCost"
          let-childIndex="childIndex"
        >
          <ng-container
            *ngIf="{
              catOverride: getCatType(cat).primary_settings.override
            } as obj2"
          >
            <ng-container
              *ngIf="{
                open: isCatOpen(getCatType(cat).id) | async,
                loading: isCatLoading(getCatType(cat).id) | async,
                category: getCatType(cat),
                unforecasted: isCategoryUnforecasted(getCatType(cat).primary_settings.id) | async,
                isCategoryDisabled: !obj2.catOverride || !userHasModifyPermissions,
                isActivityDisabled:
                  isChild && childApplyAll ? true : obj2.catOverride || !userHasModifyPermissions,
                catShowError: isShowError(getCatType(cat).primary_settings.id) | async,
                catShowErrorMessage:
                  isShowErrorMessage(getCatType(cat).primary_settings.id) | async,
                isCatDriverSiteOrPatient:
                  getCatType(cat).primary_settings.driver === DriverType.DRIVER_SITE ||
                  getCatType(cat).primary_settings.driver === DriverType.DRIVER_PATIENT ||
                  getCatType(cat).primary_settings.driver === DriverType.DRIVER_SERVICES_BLENDED,
                isCatDriverPatient:
                  getCatType(cat).primary_settings.driver === DriverType.DRIVER_PATIENT,
                isCatDriverEmpty: !getCatType(cat).primary_settings.driver,
                isForecastFinalized:
                  (isForecastFinalized$ | async) || (iCloseMonthsProcessing$ | async) || false
              } as obj"
            >
              <div [id]="obj.category.primary_settings.id"></div>
              <hr *ngIf="isChild" class="mx-1.5 mt-4" />
              <div
                class="grid grid-cols-12 gap-x-1.5 text-xs tabular-nums"
                [ngClass]="{
                  'bg-aux-gray-light': obj.open || isCost,
                  'border rounded-md mb-5 pl-1 pr-1.5 py-4': !isChild,
                  'pt-4': isChild
                }"
              >
                <div
                  class="col-span-2 relative flex items-center"
                  [ngStyle]="{
                    'margin-left': childIndex * 22 + 'px'
                  }"
                >
                  <button
                    (click)="toggleCat(obj.category.id)"
                    type="button"
                    class="flex items-center justify-center w-5 h-5 ml-1 mr-2"
                  >
                    <chevron-down-solid-icon *ngIf="!obj.open"></chevron-down-solid-icon>
                    <chevron-up-solid-icon *ngIf="obj.open"></chevron-up-solid-icon>
                  </button>
                  <div>
                    <div class="flex items-center font-semibold text-xs h-full mb-1">
                      <div *ngIf="obj.unforecasted" class="mr-1">
                        <span
                          inlineSVG="alert.svg"
                          class="text-aux-red-dark"
                          [setSVGAttributes]="{ class: 'w-5 h-5 -ml-0.5' }"
                        ></span>
                      </div>
                      <span class="overflow-ellipsis overflow-hidden">
                        {{ obj.category.display_label }} {{ obj.category.name }}
                      </span>
                    </div>
                    <div class="font-normal">
                      <aux-checkbox
                        [id]="'cat' + obj.category.id"
                        (customChange)="
                          toggleCatApplyAll($event, obj.category.primary_settings, obj.category.id)
                        "
                        [checked]="obj2.catOverride"
                        [indeterminate]="
                          obj2.catOverride && isCategoryIndeterminate(obj.category.id)
                        "
                        [disabled]="!obj.open || obj.isForecastFinalized"
                        [auxTooltip]="pageLockedTooltipText(obj.isForecastFinalized)"
                        class="text-xs"
                      >
                        Apply to activities
                        <span
                          class="text-xxs"
                          *ngIf="obj2.catOverride && obj.category.activity_checked_count"
                          >({{ obj.category.activity_checked_count }} Override)</span
                        >
                      </aux-checkbox>
                    </div>
                  </div>
                </div>

                <div class="col-span-1 flex items-center justify-center mr-5 text-sm">
                  {{ obj.category.primary_settings.total_cost | money }}
                </div>
                <div class="col-span-1 flex items-center justify-center mr-5 text-sm">
                  {{
                    obj.category.primary_settings.total_cost -
                      obj.category.primary_settings.total_wp | money
                  }}
                </div>

                <div class="col-span-1 flex items-center justify-center mr-5 text-sm">—</div>

                <div class="col-span-1 flex items-center justify-center mr-5 text-sm">—</div>

                <div class="col-span-2 mt-0 items-center">
                  <ng-select
                    class="select select__big mt-0"
                    [items]="driverOptions"
                    [searchable]="false"
                    [clearable]="true"
                    [ngModel]="obj.category.primary_settings.driver"
                    [placeholder]="
                      obj.isCategoryDisabled ? '' : formControlConstants.PLACEHOLDER.SELECT
                    "
                    bindValue="value"
                    [disabled]="obj.isCategoryDisabled || obj.isForecastFinalized"
                    (ngModelChange)="
                      onDriverChange($event, obj.category.primary_settings, {
                        name: 'cat',
                        id: obj.category.id
                      })
                    "
                    [ngClass]="{
                      'is-invalid': obj.catShowError || obj.unforecasted
                    }"
                    [auxTooltip]="driverTooltipText(obj.isForecastFinalized)"
                  >
                  </ng-select>

                  <div *ngIf="obj.catShowError" class="w-full font-bold mt-2 text-aux-red-dark">
                    {{ obj.catShowErrorMessage }}
                  </div>
                </div>

                <aux-forecast-site-curve-settings
                  *ngIf="
                    getCatType(cat).primary_settings.driver === DriverType.DRIVER_SITE;
                    else categoryTimeOrPatient
                  "
                  class="col-span-4 grid grid-cols-2 gap-x-1.5"
                  [driverSettingId]="obj.category.primary_settings.driver_setting_id || ''"
                  [unfilteredSiteCurveGroups]="unfilteredSiteCurveGroups"
                  [disabled]="
                    obj.isCategoryDisabled || obj.isForecastFinalized || !isSiteDriverAvailable
                  "
                  [isInvalid]="obj.catShowError || obj.unforecasted || false"
                  [tooltip]="methodAndPeriodTooltipText(obj.isForecastFinalized)"
                  (siteGroupChange)="
                    onMethodChange($event, obj.category.primary_settings, {
                      name: 'cat',
                      prop: 'driver_setting',
                      id: obj.category.id
                    })
                  "
                ></aux-forecast-site-curve-settings>

                <ng-template #categoryTimeOrPatient>
                  <div class="col-span-2 flex">
                    <ng-container [ngSwitch]="getCatType(cat).primary_settings.driver">
                      <ng-select
                        *ngSwitchCase="DriverType.DRIVER_TIME"
                        class="select select__big mt-0"
                        [items]="methodOptions"
                        [searchable]="false"
                        [clearable]="true"
                        [placeholder]="formControlConstants.PLACEHOLDER.SELECT"
                        bindValue="value"
                        [ngModel]="obj.category.primary_settings.forecast_method"
                        [disabled]="obj.isCategoryDisabled || obj.isForecastFinalized"
                        (ngModelChange)="
                          onMethodChange($event, obj.category.primary_settings, {
                            name: 'cat',
                            prop: 'forecast_method',
                            id: obj.category.id
                          })
                        "
                        [ngClass]="{
                          'is-invalid': obj.catShowError || obj.unforecasted
                        }"
                        [auxTooltip]="methodAndPeriodTooltipText(obj.isForecastFinalized)"
                      >
                      </ng-select>

                      <ng-container *ngSwitchCase="DriverType.DRIVER_PATIENT">
                        <ng-container
                          *ngIf="obj.isCategoryDisabled"
                          [ngTemplateOutlet]="disabledSelect"
                          [ngTemplateOutletContext]="{
                            primary_settings: obj.category.primary_settings
                          }"
                          [auxTooltip]="
                            userHasModifyPermissions
                              ? ''
                              : messagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
                          "
                        >
                        </ng-container>

                        <ng-container *ngIf="driverPatientGroups$ | async as driverPatientGroup">
                          <div
                            class="w-full mt-0"
                            *ngIf="driverPatientGroup.length && !obj.isCategoryDisabled"
                          >
                            <aux-patient-dropdown
                              [options]="driverPatientGroup"
                              [isCurve]="false"
                              [firstRender]="false"
                              [disabled]="obj.isForecastFinalized"
                              [selectedId]="obj.category.primary_settings.driver_setting_id"
                              [placeholder]="formControlConstants.PLACEHOLDER.SELECT"
                              (customChange)="
                                onMethodChange(
                                  $any($event?.driver_setting_id),
                                  obj.category.primary_settings,
                                  {
                                    name: 'cat',
                                    prop: 'driver_setting',
                                    id: obj.category.id
                                  }
                                )
                              "
                              [showError]="!!obj.catShowError || !!obj.unforecasted"
                            ></aux-patient-dropdown>
                          </div>
                          <div
                            class="w-full border rounded-md p-3 h-12 flex items-center justify-between bg-aux-gray-light text-aux-gray-dark-100"
                            *ngIf="driverPatientGroup.length < 1"
                          >
                            Patient Curve
                          </div>
                        </ng-container>
                      </ng-container>

                      <ng-container
                        *ngSwitchDefault
                        [ngTemplateOutlet]="disabledSelect"
                        [ngTemplateOutletContext]="{
                          primary_settings: obj.category.primary_settings
                        }"
                        [auxTooltip]="
                          userHasModifyPermissions
                            ? ''
                            : messagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
                        "
                      >
                      </ng-container>
                    </ng-container>
                  </div>

                  <div
                    class="col-span-2 flex"
                    [auxTooltip]="methodAndPeriodTooltipText(obj.isForecastFinalized)"
                  >
                    <div
                      *ngIf="!obj.isCatDriverSiteOrPatient && !obj.isCatDriverEmpty"
                      class="border rounded-md p-3 h-12 flex items-center justify-between w-full"
                      [ngClass]="{
                        'bg-white cursor-pointer': !obj.isCategoryDisabled,
                        'bg-aux-gray-light text-aux-gray-dark-100':
                          obj.isCategoryDisabled || obj.isForecastFinalized,
                        'ring-2 ring-aux-error': obj.catShowError || obj.unforecasted,
                        'pointer-events-none': obj.isForecastFinalized
                      }"
                      (click)="
                        !obj.isCategoryDisabled && !obj.isForecastFinalized
                          ? onPeriodChange(obj.category.primary_settings, {
                              name: 'cat',
                              id: obj.category.id
                            })
                          : ''
                      "
                    >
                      <span
                        class="text-xs font-medium line-clamp-2"
                        *ngIf="obj.category.primary_settings as set"
                      >
                        <span *ngIf="set.milestone_category">
                          Preset: {{ set.milestone_category.name }}
                        </span>
                        <span
                          *ngIf="
                            !set.milestone_category && set.start_milestone && set.end_milestone
                          "
                        >
                          Custom: {{ set.start_milestone.name }} - {{ set.end_milestone.name }}
                        </span>
                        <span
                          *ngIf="
                            !set.milestone_category && !set.start_milestone && !set.end_milestone
                          "
                        >
                        </span>
                      </span>
                      <span inlineSVG="datepicker.svg" *ngIf="!obj.isCategoryDisabled"></span>
                    </div>

                    <ng-container
                      *ngIf="obj.isCatDriverPatient || obj.isCatDriverEmpty"
                      [ngTemplateOutlet]="disabledSelect"
                      [ngTemplateOutletContext]="{
                        primary_settings: obj.category.primary_settings
                      }"
                    >
                    </ng-container>
                  </div>
                </ng-template>

                <ng-container *ngIf="obj.open">
                  <ng-container *ngIf="obj.loading">
                    <div class="border-8 h-32 m-auto my-16 spinner w-32 col-span-12"></div>
                  </ng-container>
                  <ng-container *ngIf="!obj.loading">
                    <div
                      *ngFor="let sub_category_id of obj.category.sub_categories"
                      class="col-span-12"
                    >
                      <ng-container
                        *ngIf="
                          categoryQuery.selectCategory(sub_category_id) | async as sub_category
                        "
                      >
                        <ng-container
                          [ngTemplateOutlet]="categoryTemplate"
                          [ngTemplateOutletContext]="{
                            cat: sub_category,
                            isChild: true,
                            childApplyAll: isChild && childApplyAll ? true : obj2.catOverride,
                            childIndex: childIndex + 1
                          }"
                        ></ng-container>
                      </ng-container>
                    </div>

                    <ng-container *ngFor="let sub_activity of obj.category.activities; index as i">
                      <hr class="mx-1.5 my-4 col-span-12" />
                      <div class="col-span-12" [id]="sub_activity.primary_settings.id"></div>
                      <ng-container
                        *ngIf="{
                          isActivityDisabled:
                            (obj2.catOverride && !sub_activity.primary_settings.override) ||
                            !userHasModifyPermissions,
                          showError: isShowError(sub_activity.primary_settings.id) | async,
                          isActivityDriverSiteOrPatient:
                            getCatType(sub_activity).primary_settings.driver ===
                              DriverType.DRIVER_SITE ||
                            getCatType(sub_activity).primary_settings.driver ===
                              DriverType.DRIVER_PATIENT,
                          isActivityDriverPatient:
                            getCatType(sub_activity).primary_settings.driver ===
                            DriverType.DRIVER_PATIENT,
                          isActivityDriverSite:
                            getCatType(sub_activity).primary_settings.driver ===
                            DriverType.DRIVER_SITE,
                          unforecasted:
                            (isActivityUnforecasted(getCatType(sub_activity).id) | async),
                          isActivityDriverEmpty: !getCatType(sub_activity).primary_settings.driver
                        } as sub_obj"
                      >
                        <div
                          class="col-span-2 relative flex flex-col justify-center"
                          [ngStyle]="{
                            'margin-left': (childIndex + 1) * 30 + 'px'
                          }"
                        >
                          <div class="flex items-center">
                            <div *ngIf="sub_obj.unforecasted" class="mr-1">
                              <span
                                inlineSVG="alert.svg"
                                class="text-aux-red-dark"
                                [setSVGAttributes]="{ class: 'w-5 h-5 -ml-0.5' }"
                              ></span>
                            </div>
                            <div class="text-xs overflow-ellipsis line-clamp-3">
                              {{ sub_activity.display_label }} {{ sub_activity.name }}
                            </div>
                          </div>
                          <div class="text-xs" *ngIf="obj2.catOverride">
                            <aux-checkbox
                              [id]="'cat' + sub_activity.id"
                              (customChange)="
                                toggleActivityApplyAll(
                                  $event,
                                  sub_activity,
                                  obj.category.primary_settings
                                )
                              "
                              [checked]="sub_activity.primary_settings.override"
                              [disabled]="obj.isForecastFinalized || !userHasModifyPermissions"
                              [auxTooltip]="pageLockedTooltipText(obj.isForecastFinalized)"
                              class="text-xs"
                            >
                              Override
                            </aux-checkbox>
                          </div>
                        </div>

                        <div class="col-span-1 flex items-center justify-center mr-5">
                          {{ sub_activity.primary_settings.total_cost | money }}
                        </div>
                        <div class="col-span-1 flex items-center justify-center mr-5">
                          {{
                            sub_activity.primary_settings.total_cost -
                              sub_activity.primary_settings.total_wp | money
                          }}
                        </div>
                        <div class="col-span-1 flex items-center justify-center mr-5">
                          {{ sub_activity.unit_num }}
                        </div>
                        <div class="col-span-1 flex items-center justify-center mr-5">
                          {{ sub_activity.uom }}
                        </div>

                        <div class="col-span-2 flex">
                          <ng-select
                            class="select select__big mt-0"
                            [items]="driverOptions"
                            [searchable]="false"
                            [clearable]="true"
                            [placeholder]="
                              sub_obj.isActivityDisabled
                                ? ''
                                : formControlConstants.PLACEHOLDER.SELECT
                            "
                            bindValue="value"
                            [ngModel]="sub_activity.primary_settings.driver"
                            [disabled]="sub_obj.isActivityDisabled || obj.isForecastFinalized"
                            (ngModelChange)="
                              onDriverChange($event, sub_activity.primary_settings, {
                                name: 'act',
                                id: sub_activity.id
                              })
                            "
                            [ngClass]="{
                              'is-invalid': sub_obj.showError || sub_obj.unforecasted
                            }"
                            [auxTooltip]="methodAndPeriodTooltipText(obj.isForecastFinalized)"
                          >
                          </ng-select>
                        </div>

                        <aux-forecast-site-curve-settings
                          *ngIf="sub_obj.isActivityDriverSite; else subObjectTimeOrPatient"
                          class="col-span-4 grid grid-cols-2 gap-x-1.5"
                          [driverSettingId]="sub_activity.primary_settings.driver_setting_id || ''"
                          [unfilteredSiteCurveGroups]="unfilteredSiteCurveGroups"
                          [disabled]="
                            sub_obj.isActivityDisabled ||
                            obj.isForecastFinalized ||
                            !isSiteDriverAvailable
                          "
                          [isInvalid]="sub_obj.showError || sub_obj.unforecasted || false"
                          [tooltip]="methodAndPeriodTooltipText(obj.isForecastFinalized)"
                          (siteGroupChange)="
                            onMethodChange($event, sub_activity.primary_settings, {
                              name: 'act',
                              prop: 'driver_setting',
                              id: sub_activity.id
                            })
                          "
                        ></aux-forecast-site-curve-settings>

                        <ng-template #subObjectTimeOrPatient>
                          <div class="col-span-2 flex">
                            <div
                              *ngIf="sub_obj.isActivityDisabled"
                              class="w-full"
                              [auxTooltip]="pageLockedTooltipText(obj.isForecastFinalized)"
                            >
                              <ng-container
                                [ngTemplateOutlet]="disabledSelect"
                                [ngTemplateOutletContext]="{
                                  primary_settings: sub_activity.primary_settings
                                }"
                              >
                              </ng-container>
                            </div>

                            <ng-container *ngIf="!sub_obj.isActivityDisabled">
                              <ng-container
                                [ngSwitch]="getCatType(sub_activity).primary_settings.driver"
                              >
                                <ng-select
                                  *ngSwitchCase="DriverType.DRIVER_TIME"
                                  class="select select__big mt-0"
                                  [items]="methodOptions"
                                  [searchable]="false"
                                  [clearable]="true"
                                  [placeholder]="formControlConstants.PLACEHOLDER.SELECT"
                                  bindValue="value"
                                  [ngModel]="sub_activity.primary_settings.forecast_method"
                                  [disabled]="sub_obj.isActivityDisabled || obj.isForecastFinalized"
                                  (ngModelChange)="
                                    onMethodChange(
                                      $event,
                                      sub_activity.primary_settings,

                                      {
                                        name: 'act',
                                        prop: 'forecast_method',
                                        id: sub_activity.id
                                      }
                                    )
                                  "
                                  [ngClass]="{
                                    'is-invalid': sub_obj.showError || sub_obj.unforecasted
                                  }"
                                  [auxTooltip]="methodAndPeriodTooltipText(obj.isForecastFinalized)"
                                >
                                </ng-select>

                                <ng-container *ngSwitchCase="DriverType.DRIVER_PATIENT">
                                  <div
                                    class="w-full mt-0 h-12"
                                    *ngIf="driverPatientGroups$ | async as driverPatientGroup"
                                  >
                                    <aux-patient-dropdown
                                      *ngIf="driverPatientGroup.length"
                                      [options]="driverPatientGroup"
                                      [isCurve]="false"
                                      [firstRender]="false"
                                      [disabled]="obj.isForecastFinalized"
                                      [selectedId]="sub_activity.primary_settings.driver_setting_id"
                                      [placeholder]="formControlConstants.PLACEHOLDER.SELECT"
                                      [showError]="!!sub_obj.showError || !!sub_obj.unforecasted"
                                      (customChange)="
                                        onMethodChange(
                                          $any($event?.driver_setting_id),
                                          sub_activity.primary_settings,
                                          {
                                            name: 'act',
                                            prop: 'driver_setting',
                                            id: sub_activity.id
                                          }
                                        )
                                      "
                                    ></aux-patient-dropdown>

                                    <ng-container
                                      *ngIf="!driverPatientGroup.length"
                                      [ngTemplateOutlet]="disabledSelect"
                                      [ngTemplateOutletContext]="{
                                        primary_settings: sub_activity.primary_settings
                                      }"
                                    >
                                    </ng-container>
                                  </div>
                                </ng-container>

                                <ng-container
                                  *ngSwitchDefault
                                  [ngTemplateOutlet]="disabledSelect"
                                  [ngTemplateOutletContext]="{
                                    primary_settings: sub_activity.primary_settings
                                  }"
                                >
                                </ng-container>
                              </ng-container>
                            </ng-container>
                          </div>

                          <div
                            class="col-span-2 flex"
                            [auxTooltip]="pageLockedTooltipText(obj.isForecastFinalized)"
                          >
                            <div
                              *ngIf="
                                !sub_obj.isActivityDriverSiteOrPatient &&
                                !sub_obj.isActivityDriverEmpty
                              "
                              class="border rounded-md p-3 h-12 flex items-center justify-between w-full"
                              [ngClass]="{
                                'bg-white cursor-pointer': !sub_obj.isActivityDisabled,
                                'bg-aux-gray-light text-aux-gray-dark-100':
                                  sub_obj.isActivityDisabled || obj.isForecastFinalized,
                                'ring-2 ring-aux-error': sub_obj.showError || sub_obj.unforecasted,
                                'pointer-events-none': false
                              }"
                              (click)="
                                !sub_obj.isActivityDisabled && !obj.isForecastFinalized
                                  ? onPeriodChange(sub_activity.primary_settings, {
                                      name: 'act',
                                      id: sub_activity.id
                                    })
                                  : ''
                              "
                            >
                              <span
                                class="text-xs font-medium line-clamp-2"
                                *ngIf="sub_activity.primary_settings as set"
                              >
                                <span *ngIf="set.milestone_category">
                                  Preset: {{ set.milestone_category.name }}
                                </span>
                                <span
                                  *ngIf="
                                    !set.milestone_category &&
                                    set.start_milestone &&
                                    set.end_milestone
                                  "
                                >
                                  Custom: {{ set.start_milestone.name }} -
                                  {{ set.end_milestone.name }}
                                </span>
                                <span
                                  *ngIf="
                                    !set.milestone_category &&
                                    !set.start_milestone &&
                                    !set.end_milestone
                                  "
                                  [auxTooltip]="
                                    userHasModifyPermissions
                                      ? ''
                                      : messagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
                                  "
                                >
                                </span>
                              </span>
                              <span
                                inlineSVG="datepicker.svg"
                                *ngIf="!sub_obj.isActivityDisabled"
                              ></span>
                            </div>

                            <ng-container
                              *ngIf="
                                sub_obj.isActivityDriverPatient || sub_obj.isActivityDriverEmpty
                              "
                              [ngTemplateOutlet]="disabledSelect"
                              [ngTemplateOutletContext]="{
                                primary_settings: sub_activity.primary_settings
                              }"
                            >
                            </ng-container>
                          </div>
                        </ng-template>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </ng-template>

        <div *ngIf="!(categoryQuery.selectTopCategories$ | async)?.length" class="mt-5">
          <ng-container *ngIf="(categoryQuery.topCategories$ | async)?.length; else notFound">
            No Results found with your selection. Please try
            <span (click)="clearFilters()" class="aux-link cursor-pointer">
              clearing the filters
            </span>
            .
          </ng-container>
          <ng-template #notFound> No budget data found for this vendor </ng-template>
        </div>
      </ng-container>

      <aux-save-changes [onSaveChanges]="saveChanges" *ngIf="(saveCheck | async)!">
      </aux-save-changes>
    </ng-container>
  </ng-container>
</ng-container>
