import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DriverPatientGroup } from '@services/gql.service';

export interface PatientCurveState
  extends EntityState<
    DriverPatientGroup & {
      showLine: boolean;
      patient_group_ids: string[];
      patient_group_id: string;
    }
  > {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patient-curve', idKey: 'driver_setting_id' })
export class PatientCurveStore extends EntityStore<PatientCurveState> {
  constructor() {
    super({});
  }
}
