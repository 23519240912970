<!-- <div role="alert" class="alert alert-info alert-dismissible customAlert">
  <button aria-label="Close" class="close" type="button">
    <span aria-hidden="true">×</span>
    <span class="sr-only">Close</span>
  </button>
  <span class="warning">!</span> You Have 1 alert
</div> -->

<ng-template #progressTemplate let-config="config">
  <div class="relative">
    <div class="h-1.5 my-2 bg-aux-gray-dark rounded-full"></div>
    <div
      class="absolute h-1.5 rounded-full inset-0"
      [ngClass]="{
        'bg-aux-green': config.progressColor === 'green',
        'bg-aux-blue': config.progressColor === 'blue'
      }"
      [style.width]="config.value + '%'"
    ></div>
  </div>
</ng-template>

<div class="flex justify-between items-center mb-16">
  <h1 class="text-2xl text-aux-gray-darkest font-bold h-11 flex items-center">Risk Analytics</h1>
</div>

<div class="flex flex-col flex-wrap mb-24" style="height: 900px; width: calc(100% - 2.5rem)">
  <aux-risk-card class="mb-5 mr-5 w-1/3" [alerts]="timelineAlerts">
    <div class="font-bold text-xl text-aux-black mb-4">Timelines / Milestones</div>

    <ng-template #progress let-config="config">
      <div>
        <div class="font-medium text-aux-black text-sm">
          {{ config.header }}
        </div>

        <ng-container
          [ngTemplateOutlet]="progressTemplate"
          [ngTemplateOutletContext]="{
            config: config
          }"
        ></ng-container>

        <!-- <progressbar-->
        <!--   [value]="config.value"-->
        <!--   [max]="100"-->
        <!--   [ngClass]="{-->
        <!--     green: config.progressColor === 'green',-->
        <!--     blue: config.progressColor === 'blue'-->
        <!--   }"-->
        <!--   class="h-1.5 my-2 bg-aux-gray-dark"-->
        <!-- ></progressbar>-->

        <div class="flex justify-between items-center">
          <div class="text-aux-black text-xs">{{ config.leftLabel }}</div>
          <div class="text-aux-black text-xs">{{ config.rightLabel }}</div>
        </div>
      </div>
    </ng-template>

    <div class="space-y-4">
      <ng-container *ngFor="let timeline of timelineData">
        <ng-container
          [ngTemplateOutlet]="progress"
          [ngTemplateOutletContext]="{
            config: timeline
          }"
        ></ng-container>
      </ng-container>
    </div>
  </aux-risk-card>

  <aux-risk-card class="mb-5 mr-5 w-1/3" [alerts]="paceOfSpendAlerts">
    <div class="font-bold text-xl text-aux-black mb-4">Pace of Spend</div>

    <div class="flex justify-between mb-8">
      <div class="text-xs text-aux-black">
        <div>Current Spend</div>
        <div class="text-sm text-aux-black font-bold mt-2">$789,409</div>
      </div>
      <div class="text-xs text-aux-black">
        <div>Total Budget</div>
        <div class="text-sm text-aux-black font-bold mt-2">$789,409</div>
      </div>
      <div class="text-xs text-aux-black">
        <div>Amount Remaining</div>
        <div class="text-sm text-aux-black font-bold mt-2">$1,910,591 / 71%</div>
      </div>
    </div>

    <div class="relative">
      <div class="h-6 my-2 bg-aux-gray-dark rounded-l-none rounded-r"></div>
      <div
        class="absolute h-6 rounded-l-none rounded-r inset-0 bg-aux-blue"
        style="width: 30%"
      ></div>
    </div>

    <hr class="my-2" />
    <div class="flex justify-between items-center">
      <div class="text-xs text-aux-black">$0</div>
      <div class="text-xs text-aux-black">$500k</div>
      <div class="text-xs text-aux-black">$1m</div>
      <div class="text-xs text-aux-black">$1.5m</div>
      <div class="text-xs text-aux-black">$2m</div>
      <div class="text-xs text-aux-black">$2.5m</div>
      <div class="text-xs text-aux-black">$3m</div>
    </div>
  </aux-risk-card>

  <aux-risk-card class="mb-5 mr-5 w-1/3" [alerts]="patientAlerts">
    <div class="font-bold text-xl text-aux-black mb-4">Patient Enrollment</div>

    <div class="text-aux-black text-xs font-medium mb-2"># of Patients / Avg Patient Cost</div>
    <!--    <canvas-->
    <!--      baseChart-->
    <!--      class="chart"-->
    <!--      [datasets]="lineChartData"-->
    <!--      [options]="lineChartOptions"-->
    <!--      [colors]="lineChartColours"-->
    <!--      [legend]="lineChartLegend"-->
    <!--      [chartType]="lineChartType"-->
    <!--    ></canvas>-->
  </aux-risk-card>

  <aux-risk-card class="mb-5 mr-5 w-1/3" [alerts]="fpAlerts">
    <div class="font-bold text-xl text-aux-black mb-4">FP&A Health</div>

    <!--    <canvas-->
    <!--      baseChart-->
    <!--      class="chart"-->
    <!--      [datasets]="barChartData"-->
    <!--      [labels]="barChartLabels"-->
    <!--      [options]="barChartOptions"-->
    <!--      [legend]="barChartLegend"-->
    <!--      [chartType]="barChartType"-->
    <!--    ></canvas>-->
  </aux-risk-card>

  <aux-risk-card class="mb-5 mr-5 w-1/3" [alerts]="siteAlerts">
    <div class="font-bold text-xl text-aux-black mb-4">Site Health</div>

    <div class="text-aux-black text-xs font-medium mb-4">
      Average Site Health Score: <span class="font-bold">50</span>
    </div>

    <table class="w-full">
      <thead>
        <th></th>
        <th class="text-center text-aux-black text-xs font-normal">Enrolled Patients</th>
        <th class="text-center text-aux-black text-xs font-normal">Health Score</th>
      </thead>
      <tbody>
        <tr class="border-b border-aux-gray-light">
          <td class="font-medium text-aux-black text-sm py-2">Columbia Presbyterian</td>
          <td class="text-center text-sm"><strong>61</strong></td>
          <td class="text-center text-sm"><strong>58</strong></td>
        </tr>
        <tr class="border-b border-aux-gray-light">
          <td class="font-medium text-aux-black text-sm py-2">Mt. Sinai West</td>
          <td class="text-center text-sm"><strong>24</strong></td>
          <td class="text-center text-sm"><strong>47</strong></td>
        </tr>
        <tr class="border-b border-aux-gray-light">
          <td class="font-medium text-aux-black text-sm py-2">Mass General Hospital</td>
          <td class="text-center text-sm"><strong>12</strong></td>
          <td class="text-center text-sm"><strong>34</strong></td>
        </tr>
      </tbody>
    </table>

    <a href="#" class="mt-2 text-sm block font-medium underline text-aux-blue">See all sites</a>
  </aux-risk-card>
</div>
