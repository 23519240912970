<ag-grid-angular
  class="ag-theme-aux document-library-table mb-8 tabular-nums w-full"
  style="height: 400px"
  [rowData]="$any(gridData$ | async)"
  [gridOptions]="$any(gridOptions$ | async)"
  (gridReady)="onGridReady($event)"
  [context]="getGridContext()"
  [rowSelection]="'multiple'"
  [suppressRowClickSelection]="true"
  (rowDataChanged)="refreshFormControls(true)"
  [quickFilterText]="formGroup.controls.search.valueChanges | async"
  (rowSelected)="rowSelectedHandler($event)"
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
>
</ag-grid-angular>
