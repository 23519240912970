import { Injectable } from '@angular/core';
import { ValuesType } from '@services/utils';
import { AmountType, ExpenseType, listBudgetGridQuery, PeriodType } from '@services/gql.service';
import { OrganizationModel } from '@models/organization/organization.store';
import { groupBy, round } from 'lodash-es';
import * as dayjs from 'dayjs';
import { OrganizationQuery } from '@models/organization/organization.query';

import { MainQuery } from '../../../../../layouts/main-layout/state/main.query';

export type BudgetDataType = listBudgetGridQuery['budget_data'];

type BudgetType = ValuesType<NonNullable<BudgetDataType>>;

type Expenses = ValuesType<NonNullable<BudgetType['expenses']>>;

type VendorData = {
  baseline: number;
  vendor_id: string;
  cost_category: string;
  activity_name: string;
  group_index: number;
};

@Injectable({
  providedIn: 'root',
})
export class BudgetGridService {
  constructor(private mainQuery: MainQuery, private vendorsQuery: OrganizationQuery) {}

  private readonly expenseTypes: ExpenseType[] = [
    ExpenseType.EXPENSE_FORECAST,
    ExpenseType.EXPENSE_WP,
  ];

  getBudgetInfo(budgetInfo: listBudgetGridQuery['budget_info'], vendors: OrganizationModel[]) {
    return (budgetInfo || []).map((bd) => {
      const vendor = vendors.find((v) => v.id === bd.vendor_id);
      const vendor_name = vendor?.name || bd.vendor_id;
      return {
        ...bd,
        vendor_name,
      };
    });
  }

  private getExpensesForCostCategory = (
    groupedExpenses: Record<ExpenseType, Expenses[]>,
    amountType: AmountType,
    customExpensesKey?: string,
    useForBaselinePrefix = true
  ): Record<string, number> => {
    return (groupedExpenses[ExpenseType.EXPENSE_QUOTE] || [])
      .filter((expense: NonNullable<Expenses>) => expense.amount_type === amountType)
      .reduce((accum, expense) => {
        const prefix = useForBaselinePrefix ? `::${expense.source}` : '';

        return {
          ...accum,
          [customExpensesKey || `${ExpenseType.EXPENSE_QUOTE}${prefix}`]:
            Number(expense.amount) || 0,
        };
      }, {});
  };

  private groupExpensesByCostCategory(
    costCategory: string,
    groupedExpenses: Record<ExpenseType, Expenses[]>,
    customExpensesKey?: string,
    useForBaselinePrefix = true
  ): Record<string, number> {
    const mapCostCategory = new Map<string, () => Record<string, number>>([
      [
        'Services',
        () =>
          this.getExpensesForCostCategory(
            groupedExpenses,
            AmountType.AMOUNT_SERVICE,
            customExpensesKey,
            useForBaselinePrefix
          ),
      ],
      [
        'Discount',
        () =>
          this.getExpensesForCostCategory(
            groupedExpenses,
            AmountType.AMOUNT_DISCOUNT,
            customExpensesKey,
            useForBaselinePrefix
          ),
      ],
      [
        'Investigator',
        () =>
          this.getExpensesForCostCategory(
            groupedExpenses,
            AmountType.AMOUNT_INVESTIGATOR,
            customExpensesKey,
            useForBaselinePrefix
          ),
      ],
      [
        'Pass-through',
        () =>
          this.getExpensesForCostCategory(
            groupedExpenses,
            AmountType.AMOUNT_PASSTHROUGH,
            customExpensesKey,
            useForBaselinePrefix
          ),
      ],
    ]);

    const fn = mapCostCategory.get(costCategory);

    return fn ? fn() : {};
  }

  private getPeriodExpensesData(
    groupedExpenses: Record<ExpenseType, Expenses[]>
  ): Record<string, number> {
    return this.expenseTypes.reduce((accum, expensesKey) => {
      return {
        ...accum,
        ...(groupedExpenses[expensesKey] || []).reduce((expenses, expense) => {
          return {
            ...expenses,
            [`${expensesKey}::${expense.period}`]: Number(expense.amount) || 0,
          };
        }, {}),
      };
    }, {});
  }

  private getDataForPlan(
    groupedExpenses: Record<ExpenseType, Expenses[]>,
    expensesByCost: Record<string, number>
  ): Record<string, number> {
    const result: Record<string, number> = {};
    [
      ExpenseType.EXPENSE_ACCRUAL,
      ExpenseType.EXPENSE_FORECAST_AT_CLOSE,
      ExpenseType.EXPENSE_WP,
    ].forEach((expense_type) => {
      (groupedExpenses[expense_type] || []).forEach(({ period, amount }) => {
        const actuals = expensesByCost[`${ExpenseType.EXPENSE_WP}::${period}`];

        const plan = result[`${period}::PLAN`] || Number(amount) || 0;
        const varCost = actuals - plan || 0;

        result[`${period}::PLAN`] = plan || 0;
        result[`${period}::VAR_COST`] = varCost;
        result[`${period}::VAR_PERC`] = plan ? round(varCost / plan, 2) : 0;
      });
    });

    return result;
  }

  private getForecastValue(groupedExpenses: Record<ExpenseType, Expenses[]>) {
    return (groupedExpenses[ExpenseType.EXPENSE_FORECAST] || []).reduce(
      (_, expense) => expense.amount || 0,
      0
    );
  }

  private getVendorName(vendors: OrganizationModel[], budgetData: BudgetType) {
    const vendor = vendors.find((v) => v.id === budgetData.vendor_id);
    return vendor?.name || budgetData.vendor_id;
  }

  getVarPercent(current_lre: number, var_amount: number, baseline: number) {
    let var_percent = 0;

    if (baseline) {
      var_percent = current_lre ? (var_amount / Math.abs(baseline)) * 100 : -100;
    }

    return round(var_percent, 2);
  }

  private getBaseLine(
    vendorsBaseline: VendorData[],
    row: VendorData,
    isOneVendorSelected: boolean
  ) {
    return isOneVendorSelected
      ? vendorsBaseline.find(({ vendor_id, cost_category, activity_name }) => {
          return (
            vendor_id === row.vendor_id &&
            cost_category === row.cost_category &&
            activity_name === row.activity_name
          );
        })?.baseline
      : vendorsBaseline
          .filter(({ vendor_id, cost_category, group_index }) => {
            return (
              vendor_id === row.vendor_id &&
              cost_category === row.cost_category &&
              group_index === row.group_index
            );
          })
          ?.reduce((sum, data) => {
            return sum + (data.baseline || 0);
          }, 0);
  }

  getBudgetGridWithBaseLineForVendors(
    budgetData: BudgetDataType,
    vendors: OrganizationModel[],
    baseLineBudgetList: (BudgetDataType | null)[],
    periodType: PeriodType,
    useForBaselinePrefix = true
  ) {
    const gridData = this.getBaselineBudgetData(
      budgetData,
      vendors,
      periodType,
      useForBaselinePrefix,
      true
    );

    const vendorsBaseline = baseLineBudgetList
      .map((data) => {
        return this.getBaselineBudgetData(data || [], vendors, periodType, false);
      })
      .reduce((result, vendorData) => {
        return result.concat(vendorData);
      }, []);

    return gridData.map((row) => {
      const { current_lre } = row;

      const isOneVendorSelected = !!this.vendorsQuery.getActive();

      const baseline = round(
        this.getBaseLine(vendorsBaseline as VendorData[], row as VendorData, isOneVendorSelected) ||
          0,
        2
      );

      const var_amount = round((current_lre || 0) - baseline, 2);

      return {
        ...row,
        baseline: baseline || 0,
        var_amount,
        var_percent: this.getVarPercent(current_lre, var_amount, baseline),
      };
    });
  }

  getBaselineBudgetData(
    budgetData: BudgetDataType,
    vendors: OrganizationModel[],
    periodType: PeriodType,
    useForBaselinePrefix = true,
    shouldRoundTheBaseline = false
  ) {
    return (budgetData || []).map((rowData) => {
      const { unit_cost, unit_num } = rowData;
      const groupedExpenses = groupBy(rowData.expenses, 'expense_type') as Record<
        ExpenseType,
        Expenses[]
      >;

      const costCategoryExpenses = this.groupExpensesByCostCategory(
        rowData.cost_category as string,
        groupedExpenses,
        '',
        useForBaselinePrefix
      );

      const periodExpensesData = this.getPeriodExpensesData(groupedExpenses);

      const newExpenses = {
        ...costCategoryExpenses,
        ...periodExpensesData,
        ...this.getDataForPlan(groupedExpenses, {
          ...costCategoryExpenses,
          ...periodExpensesData,
        }),
      };

      let current_lre =
        newExpenses[`${ExpenseType.EXPENSE_QUOTE}${useForBaselinePrefix ? '::LATEST' : ''}`] || 0;
      let baseline =
        newExpenses[`${ExpenseType.EXPENSE_QUOTE}${useForBaselinePrefix ? '::BASE' : ''}`] || 0;

      if (shouldRoundTheBaseline) {
        current_lre = round(current_lre, 2);
        baseline = round(baseline, 2);
      }

      const wp_cost = newExpenses[`${ExpenseType.EXPENSE_WP}::TO_DATE`] || 0;
      const wp_percentage = (wp_cost / current_lre) * 100 || 0;

      const remaining_cost = current_lre - wp_cost;
      const remaining_percentage = current_lre || baseline ? 100 - wp_percentage : 0;

      const var_amount = current_lre - baseline;

      let wp_unit_num = 0;

      if (unit_cost) {
        wp_unit_num = wp_cost / unit_cost;
      }

      const remaining_unit_num = (unit_num || 0) - wp_unit_num;

      return {
        ...rowData,
        ...newExpenses,
        vendor_name: this.getVendorName(vendors, rowData),
        trial_to_date: this.getTrialToDate(periodType, groupedExpenses),
        wp_unit_num,
        remaining_unit_num,
        forecast: this.getForecastValue(groupedExpenses),
        current_lre,
        baseline,
        var_amount,
        var_percent: this.getVarPercent(current_lre, var_amount, baseline),
        remaining_cost,
        remaining_percentage,
        wp_percentage,
        wp_cost,
        accrual: 0,
        total_monthly_accrual: 0,
        adjustment: 0,
      };
    });
  }

  getActualBudgetData(
    budgetData: BudgetDataType,
    vendors: OrganizationModel[],
    periodType: PeriodType
  ) {
    return (budgetData || []).map((rowData) => {
      const groupedExpenses = groupBy(
        rowData.expenses?.filter((expense) => expense.source !== 'BASE'),
        'expense_type'
      ) as Record<ExpenseType, Expenses[]>;

      const costCategoryExpenses = this.groupExpensesByCostCategory(
        rowData.cost_category as string,
        groupedExpenses,
        ExpenseType.EXPENSE_QUOTE
      );

      const periodExpensesData = this.getPeriodExpensesData(groupedExpenses);

      const newExpenses = {
        ...costCategoryExpenses,
        ...periodExpensesData,
      };

      const directCost = newExpenses[`${ExpenseType.EXPENSE_QUOTE}`] || 0;

      const wp_cost = newExpenses[`${ExpenseType.EXPENSE_WP}::TO_DATE`] || 0;
      const wp_percentage = (wp_cost / directCost) * 100 || 0;

      const remaining_cost = directCost - wp_cost;
      const remaining_percentage = wp_cost || directCost ? 100 - wp_percentage : 0;

      const { unit_cost } = rowData;

      let wp_unit_num = 0;
      let remaining_unit_num = 0;

      if (unit_cost) {
        wp_unit_num = wp_cost / unit_cost;
        remaining_unit_num = remaining_cost / unit_cost;
      }

      return {
        ...rowData,
        ...newExpenses,
        vendor_name: this.getVendorName(vendors, rowData),
        remaining_cost,
        trial_to_date: this.getTrialToDate(periodType, groupedExpenses),
        remaining_percentage,
        wp_percentage,
        wp_cost,
        wp_unit_num,
        remaining_unit_num,
        forecast: this.getForecastValue(groupedExpenses),
        accrual: 0,
        total_monthly_accrual: 0,
        adjustment: 0,
      };
    });
  }

  private getTrialToDate(
    period_type: PeriodType,
    groupedExpenses: Record<ExpenseType, Expenses[]>
  ) {
    let trial_to_date = 0;
    if (period_type === PeriodType.PERIOD_MONTH) {
      const { auxilius_start_date } = this.mainQuery.getSelectedTrial() || {};
      if (auxilius_start_date) {
        (groupedExpenses[ExpenseType.EXPENSE_WP] || []).forEach((x) => {
          if (x.period) {
            if (
              dayjs(auxilius_start_date)
                .date(1)
                .isAfter(dayjs(`01/${x.period.replace('-', '/')}`))
            ) {
              trial_to_date += x.amount || 0;
            }
          }
        });
      }
    }

    return trial_to_date;
  }
}
