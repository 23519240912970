export class MessagesConstants {
  static readonly PAGE_LOCKED_FOR_PERIOD_CLOSE = 'This Page is Locked for Period Close';

  static readonly CHANGES_UNABLE_SINCE_PERIOD_CLOSED =
    'Changes are unable to be made since the period is closed';

  static readonly DO_NOT_HAVE_PERMISSIONS_TO_ACTION =
    'You do not have permission to perform this action. Please contact your system administrator if this has been restricted by mistake.';

  static readonly FORGOT_PASSWORD = {
    EMAIL_HAS_BEEN_SEND:
      'An email has been sent to supplied email address. Please fill in the fields by entering your new password along with the code sent.',
    PASSWORD_RESET_SUCCESSFUL: 'Password reset successful!',
  };

  static readonly SITE = {
    CURRENCY_WARNING: `We've noticed your site's currency has changed. Please update your site costs on both the Visit Costs and Other Costs tabs.`,
  };

  static readonly SITE_CURVES = {
    SUCCESSFULLY_CREATED: 'Site Curve successfully created',
  };

  static readonly RESOLVE_TABLE_ERRORS = 'Please resolve the errors inside the table!';

  static readonly SUCCESSFULLY_SAVED = 'Successfully Saved';

  static readonly ERROR_OCCURRED = 'An error has occurred';
}
