import { Injectable } from '@angular/core';
import { OverlayService } from '@services/overlay.service';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  BudgetType,
  ContactType,
  EntityType,
  GqlService,
  listOrganizationsWithTotalBudgetAmountQuery,
  OrganizationType,
} from '@services/gql.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';

import { OrganizationModel, OrganizationStore } from './organization.store';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  constructor(
    private vendorsStore: OrganizationStore,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private mainQuery: MainQuery
  ) {}

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.vendorsStore.setLoading(true);
        return this.gqlService.listOrganizations$().pipe(
          tap(({ data }) => {
            this.vendorsStore.set(this.organizationMapper(data || []));
            this.vendorsStore.setLoading(false);
          })
        );
      })
    );
  }

  organizationMapper(arr: listOrganizationsWithTotalBudgetAmountQuery[]) {
    return arr.map((org) => {
      const versions: Record<
        string,
        {
          total_budget_amount: number;
          budget_version_id: string;
          manual_forecast: boolean;
          bucket_key: string;
          budget_name: string;
        }
      > = {};
      org.current_budget_versions.forEach((bv) => {
        versions[`${bv.budget_type}-${bv.entity_id}-${bv.entity_type}`] = {
          budget_version_id: bv.budget_version_id,
          manual_forecast: bv.manual_forecast,
          total_budget_amount: bv.total_budget_amount || 0,
          bucket_key: bv.bucket_key || '',
          budget_name: bv.budget_name || '',
        };
      });

      return { ...org, budget_version: versions };
    });
  }

  getListWithTotalBudgetAmount(budgetType?: BudgetType) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.vendorsStore.setLoading(true);
        return this.gqlService.listOrganizationsWithTotalBudgetAmount$(budgetType).pipe(
          tap(({ data }) => {
            this.vendorsStore.set(this.organizationMapper(data || []));
            this.vendorsStore.setLoading(false);
          })
        );
      })
    );
  }

  getContacts(entity_id: string) {
    return this.gqlService.listContacts$(entity_id, ContactType.CONTACT_VENDOR).pipe(
      map((x) => {
        return { ...x, data: x.data ? x.data[0] : null };
      })
    );
  }

  listOrganizationNames() {
    return this.gqlService.listOrganizationNames$().pipe(
      map((x) => {
        return { ...x, data: x.data ? x.data : null };
      })
    );
  }

  async add(
    name: string,
    {
      email,
      family_name,
      given_name,
      phone_number,
      title,
      parent_organization_id,
      costs_included_in_parent_wo,
    }: {
      given_name: string;
      family_name: string;
      email: string;
      title: string;
      phone_number: string;
      parent_organization_id: string;
      costs_included_in_parent_wo: boolean;
    },
    organization_type: OrganizationType = OrganizationType.ORGANIZATION_VENDOR
  ) {
    let operationSuccess = false;
    let operationErrors;
    let organizationData = null;

    this.vendorsStore.setLoading(true);
    try {
      const { success, data, errors } = await this.gqlService
        .createOrganization$({
          organization_type,
          name,
          parent_organization_id,
          costs_included_in_parent_wo,
        })
        .toPromise();
      if (success && data) {
        organizationData = data;
        this.vendorsStore.add({
          name: organizationData.name,
          id: organizationData.id,
          parent_organization: organizationData.parent_organization || undefined,
          costs_included_in_parent_wo,
          baseline_budget_version: null,
          organization_type: organizationData.organization_type,
          __typename: 'Organization',
          budget_version: {},
        });
        if (given_name || family_name || email || title || phone_number) {
          const { success: vSuccess, errors: vError } = await this.gqlService
            .createContact$({
              entity_id: organizationData.id,
              entity_type: EntityType.ORGANIZATION,
              contact_type: ContactType.CONTACT_VENDOR,
              given_name,
              family_name,
              email: email === '' ? null : email,
              title,
              phone_number,
            })
            .toPromise();
          if (vSuccess) {
            operationSuccess = true;
          } else {
            operationErrors = vError;
          }
        } else {
          operationSuccess = true;
        }
      } else {
        operationErrors = errors;
      }
    } finally {
      this.vendorsStore.setLoading(false);
    }

    return { success: operationSuccess, errors: operationErrors, data: organizationData };
  }

  async update(
    id: string,
    name: string,
    {
      email,
      family_name,
      given_name,
      phone_number,
      title,
      parent_organization_id,
      costs_included_in_parent_wo,
    }: {
      given_name: string;
      family_name: string;
      email: string;
      title: string;
      phone_number: string;
      parent_organization_id: string;
      costs_included_in_parent_wo: boolean;
    }
  ) {
    let operationSuccess = false;
    let operationErrors;
    let organizationData = null;

    this.vendorsStore.setLoading(true);
    try {
      const { success, data, errors } = await this.gqlService
        .updateOrganization$({
          id,
          name,
          parent_organization_id,
          costs_included_in_parent_wo,
        })
        .toPromise();
      if (success && data) {
        organizationData = data;
        this.vendorsStore.update(id, {
          ...data,
          parent_organization: organizationData.parent_organization,
          costs_included_in_parent_wo,
          __typename: 'Organization',
        });
        if (given_name || family_name || email || title || phone_number) {
          const getContactResult = await this.getContacts(id).toPromise();
          if (getContactResult.success) {
            let vSuccess;
            let vError;
            if (getContactResult.data?.id) {
              const updateContactResult = await this.gqlService
                .updateContact$({
                  id: getContactResult.data.id,
                  given_name,
                  family_name,
                  email: email === '' ? null : email,
                  title,
                  phone_number,
                })
                .toPromise();
              vSuccess = updateContactResult.success;
              vError = updateContactResult.errors;
            } else {
              const createContactResult = await this.gqlService
                .createContact$({
                  entity_id: organizationData.id,
                  entity_type: EntityType.ORGANIZATION,
                  contact_type: ContactType.CONTACT_VENDOR,
                  given_name,
                  family_name,
                  email: email === '' ? null : email,
                  title,
                  phone_number,
                })
                .toPromise();
              vSuccess = createContactResult.success;
              vError = createContactResult.errors;
            }
            if (vSuccess) {
              operationSuccess = true;
            } else {
              operationErrors = vError;
            }
          } else {
            operationErrors = getContactResult.errors;
          }
        } else {
          operationSuccess = true;
        }
      } else {
        operationErrors = errors;
      }
    } finally {
      this.vendorsStore.setLoading(false);
    }

    return { success: operationSuccess, errors: operationErrors, data: organizationData };
  }

  async remove(vendor: OrganizationModel) {
    const { success, errors } = await this.gqlService.removeOrganization$(vendor.id).toPromise();
    if (success) {
      this.overlayService.success(`${vendor.name} successfully removed!`);
      this.vendorsStore.remove(vendor.id);
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors };
  }
}
