<ng-template #reconciliationExport>
  <div class="flex items-start space-x-8">
    <div class="flex items-center">
      <div class="text-sm mr-2">Vendor</div>
      <ng-select
        class="w-64 text-sm"
        [clearable]="false"
        (change)="onVendorSelected($event)"
        [formControl]="selectedVendor"
      >
        <ng-container *ngVar="organizationQuery.allVendors$ | async as vendors">
          <ng-option [value]="''" *ngIf="vendors.length > 1">All</ng-option>
          <ng-option [value]="vendor.id" *ngFor="let vendor of vendors">
            <span [title]="vendor.name">{{ vendor.name }}</span>
          </ng-option>
        </ng-container>
      </ng-select>
    </div>
    <div class="flex items-center">
      <div class="text-sm mr-2">Select Month</div>
      <ng-select
        class="w-64 text-sm"
        [formControl]="selectedStartingMonth"
        [clearable]="false"
        [multiple]="false"
        [searchable]="false"
        [items]="(startingMonths$ | async) || []"
        bindValue="value"
        bindLabel="label"
      >
      </ng-select>
    </div>
  </div>

  <aux-export-excel-button
    [gridAPI]="gridAPI"
    [excelOptions]="excelOptions"
    [ignoreColsId]="['vendor_id']"
    [customColFilter]="customColFilter"
    [variant]="exportButtonVariant"
    [getDynamicExcelParamsCallback]="getDynamicExcelParams"
    [className]="'text-sm btn btn--blue py-1 px-2'"
  >
  </aux-export-excel-button>
</ng-template>

<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="(loading$ | async) === false">
  <div *ngIf="showAnalyticsSection$ | async">
    <div class="grid grid-cols-3 gap-7.5 mb-8">
      <div class="border rounded p-4 grid grid-cols-12 h-28">
        <div class="border-r flex flex-col justify-center col-span-6">
          <div class="font-bold leading-5">
            In-month <br />
            Overrides
          </div>
          <div class="text-xs">% of Total WP</div>
          <div class="text-xs">Overrides $</div>
        </div>
        <div
          class="pl-5 text-lg font-bold flex justify-center items-center col-span-6 tabular-nums"
        >
          <div>
            <div>12%</div>
            <div>$300,000</div>
          </div>
        </div>
      </div>
      <div class="border rounded p-4 grid grid-cols-12 h-28">
        <div class="border-r flex flex-col justify-center col-span-6">
          <div class="font-bold leading-5">
            Avg. Delta to <br />
            Vendor Fcts
          </div>
          <div class="text-xs">% of Total WP</div>
          <div class="text-xs">Average $</div>
        </div>
        <div
          class="pl-5 text-lg font-bold flex justify-center items-center col-span-6 tabular-nums"
        >
          <div>
            <div>24%</div>
            <div>$600,000</div>
          </div>
        </div>
      </div>

      <div class="border rounded p-4 grid grid-cols-12 h-28">
        <div class="border-r flex flex-col justify-center col-span-6">
          <div class="font-bold leading-5">
            Accrual vs. <br />
            Previous Qtr
          </div>
          <div class="text-xs">% Increase</div>
          <div class="text-xs">Increase $</div>
        </div>
        <div
          class="pl-5 text-lg font-bold flex justify-center items-center col-span-6 tabular-nums"
        >
          <div>
            <div>8%</div>
            <div>$82,000</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="width: 100%; overflow: auto" *ngIf="gridOptions$ | async as go">
    <ag-grid-angular
      *ngIf="showGrid$ | async"
      style="height: 500px; max-width: 100%"
      [ngStyle]="{
        width: (width$ | async) === 0 ? '100%' : (width$ | async) + 'px'
      }"
      class="ag-theme-alpine reconciliation-grid tabular-nums"
      [gridOptions]="go"
      [rowData]="filteredGridData$ | async"
      (firstDataRendered)="onDataRendered($event)"
      (gridReady)="onGridReady($event)"
      (columnResized)="autoSize()"
    >
    </ag-grid-angular>
  </div>

  <div class="flex justify-end mt-8">
    <div class="flex items-end">
      <button type="button" (click)="navigateToInMonth()" class="text-sm btn btn--blue">
        <span class="ml-2">Create Adjustment</span>
      </button>
    </div>
  </div>
</ng-container>
