import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationStore } from '@models/organization/organization.store';
import {
  Column,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowHeightParams,
  RowNode,
} from 'ag-grid-community';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import {
  CellClassParams,
  GetQuickFilterTextParams,
  ValueFormatterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { Utils } from '@services/utils';
import { ApiService } from '@services/api.service';
import { OverlayService } from '@services/overlay.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { OrganizationService } from '@models/organization/organization.service';
import { BudgetType, EventType, listUserNamesWithEmailQuery, User } from '@services/gql.service';
import { EventService } from '@services/event.service';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { AuthQuery } from '@models/auth/auth.query';

import { BudgetLibraryActionsComponent } from './budget-library-actions.component';
import { BudgetLibraryService } from './state/budget-library.service';
import { BudgetLibraryQuery } from './state/budget-library.query';
import { BudgetLibraryUploadBaselineComponent } from './budget-library-upload-baseline.component';

@UntilDestroy()
@Component({
  selector: 'aux-budget-library',
  templateUrl: './budget-library.component.html',
  styles: [
    `
      ::ng-deep .budget-library-table .ag-cell.vendor-value-cell {
        border-width: 0px !important;
        justify-items: end;
        width: 300px !important;
      }

      ::ng-deep
        .budget-library-table
        .ag-row:not(.ag-row-first)
        .ag-cell.vendor-value-cell
        .text-sm {
        padding: 25px 0 16px;
      }

      ::ng-deep .budget-library-table .ag-cell {
        color: var(--aux-black);
      }

      ::ng-deep .budget-library-table .ag-cell.vendor-cell {
        border-width: 0px !important;
        justify-items: end;
        width: 100px;
      }

      ::ng-deep .budget-library-table .ag-cell.grid-cell {
        border-top-width: 0px !important;
        border-left-width: 0px !important;
        border-right-width: 0px !important;
        border-bottom-width: 1px !important;
        border-color: var(--aux-gray-dark) !important;
      }

      ::ng-deep .budget-library-table .ag-header {
        border: 1px solid var(--aux-gray-dark) !important;
        border-radius: 0px 0px 0 0 !important;
        border-left-width: 0px !important;
      }
      ::ng-deep .budget-library-table .ag-header-row,
      ::ng-deep .budget-library-table .ag-header-cell {
        overflow: unset;
      }
      ::ng-deep .budget-library-table .ag-header-cell-text,
      ::ng-deep .budget-library-table .ag-row {
        font-size: 1rem;
      }
      ::ng-deep .budget-library-table .ag-header-cell {
        text-align: center;
        border-left: 1px solid var(--aux-gray-dark) !important;
      }
      ::ng-deep .budget-library-table .ag-header-cell-label {
        justify-content: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetLibraryComponent implements OnInit {
  selectedVendor = new FormControl('');

  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  nameFilterValue = '';

  gridAPI?: GridApi;

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridColumnApi!: ColumnApi;

  gridData$: any = null;

  width$ = new BehaviorSubject(0);

  vendors: any[] = [];

  gridOptions$ = new BehaviorSubject({
    defaultColDef: {
      sortable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      minWidth: 80,
      cellClass: (x: CellClassParams) => {
        if (!x?.data) {
          if (x.value) {
            return 'vendor-value-cell';
          }
          return 'vendor-cell';
        }
        return 'grid-cell';
      },
    },
    suppressCellFocus: true,
    columnTypes: Utils.columnTypes,
    groupDefaultExpanded: -1,
    autoGroupColumnDef: {
      headerName: '',
      field: 'actions',
      cellRenderer: BudgetLibraryActionsComponent,
      cellRendererParams: {
        downloadClickFN: ({ rowNode }: { rowNode: RowNode }) => {
          this.downloadBudgetLibrary(rowNode);
        },
        deleteClickFN: ({ rowNode }: { rowNode: RowNode }) => {
          this.budgetLibraryService.removeBudgetVersion(rowNode.data);
        },
        hideRadioButton: true,
      },
      maxWidth: 90,
      minWidth: 80,
      suppressSizeToFit: true,
      editable: false,
    },
    columnDefs: [
      {
        headerName: 'Vendor',
        field: 'vendor_id',
        valueFormatter: (params: ValueFormatterParams) => {
          return this.organizationQuery.getEntity(params.value)?.name || '';
        },
        rowGroup: true,
        hide: true,
      },
      {
        headerName: 'Vendor',
        field: 'vendor_name',
        hide: true,
      },
      {
        headerName: 'Current (LRE)',
        field: 'is_current',
        width: 80,
        maxWidth: 80,
        editable: false,
        cellRenderer: BudgetLibraryActionsComponent,
        cellRendererParams: {
          hideDownloadButton: true,
          hideDeleteButton: true,
        },
        cellClass: (x: CellClassParams) => {
          if (!x?.data) {
            return 'vendor-cell';
          }
          return ['justify-center', 'grid-cell'];
        },
      },
      {
        headerName: 'Baseline',
        field: 'is_baseline',
        width: 80,
        maxWidth: 80,
        editable: false,
        cellClass: (x: CellClassParams) => {
          if (!x?.data) {
            return 'vendor-cell';
          }
          return ['justify-center', 'grid-cell'];
        },
        cellRenderer: BudgetLibraryActionsComponent,
        cellRendererParams: {
          baselineClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            if (!rowNode.data.is_baseline) {
              this.changeBudgetTypeBaseline(rowNode);
            }
          },
          hideDownloadButton: true,
          hideDeleteButton: true,
          hideIsCurrent: true,
        },
      },
      {
        headerName: 'Budget Name',
        maxWidth: 350,
        minWidth: 300,
        field: 'budget_name',
        tooltipField: 'budget_name',
        valueFormatter: (x: any) => {
          if (x.value) {
            if (x.data.is_change_order) {
              return Utils.replaceChangeOrderFormat(x.value);
            }
            return x.value;
          }
          return '';
        },
        cellClass: (x: CellClassParams) => {
          if (!x?.data) {
            return 'vendor-cell';
          }
          return ['justify-start', 'grid-cell'];
        },
      },
      {
        headerName: 'Budget Type',
        field: 'budget_type',
        maxWidth: 250,
        minWidth: 200,
        valueFormatter: (x) =>
          this.getFormattedbudgetTypeStatus(
            x.value,
            x.data === undefined ? false : x.data.is_change_order
          ),
        cellClass: (x: CellClassParams) => {
          if (!x?.data) {
            return 'vendor-cell';
          }
          return ['justify-start', 'grid-cell'];
        },
      },
      {
        headerName: 'Total',
        field: 'total_budget_amount',
        maxWidth: 250,
        minWidth: 200,
        valueFormatter: (x: any) => {
          if (x.value) {
            return Utils.agCurrencyFormatter(x);
          }
          return '';
        },
        cellClass: (x: CellClassParams) => {
          if (!x?.data) {
            return 'vendor-cell';
          }
          return ['justify-end', 'grid-cell'];
        },
      },
      {
        headerName: 'Date Created',
        field: 'create_date',
        minWidth: 100,
        maxWidth: 100,
        type: 'date',
        cellClass: (x: CellClassParams) => {
          if (!x?.data) {
            return 'vendor-cell';
          }
          return ['justify-end', 'grid-cell'];
        },
        valueFormatter: (x: any) => {
          if (x.value) {
            return Utils.agDateFormatter(x);
          }
          return '';
        },
        getQuickFilterText: Utils.agDateFormatter,
      },
      {
        headerName: 'Created by',
        field: 'created_by',
        maxWidth: 250,
        minWidth: 200,
        valueFormatter: (x: any) => {
          if (x.value) {
            return this.userFormatter(x.value);
          }
          return '';
        },
        getQuickFilterText: (params: GetQuickFilterTextParams) => this.userFormatter(params.value),
        cellClass: (x: CellClassParams) => {
          if (!x?.data) {
            return 'vendor-cell';
          }
          return ['grid-cell'];
        },
      },
    ],
  } as GridOptions);

  constructor(
    private organizationStore: OrganizationStore,
    public organizationQuery: OrganizationQuery,
    private vendorsService: OrganizationService,
    private budgetLibraryService: BudgetLibraryService,
    public budgetLibraryQuery: BudgetLibraryQuery,
    private overlayService: OverlayService,
    private apiService: ApiService,
    private eventService: EventService,
    private trialUserService: TrialUserService,
    private authQuery: AuthQuery
  ) {
    combineLatest([this.trialUserService.listUserNamesWithEmail()])
      .pipe(untilDestroyed(this))
      .subscribe(([_users]) => {
        _users?.data?.forEach((user: listUserNamesWithEmailQuery) => {
          this.users.set(user.sub, user);
        });
      });
  }

  ngOnInit() {
    this.eventService
      .select$(EventType.TRIAL_CHANGED)
      .pipe(
        startWith(null),
        switchMap(() => {
          return combineLatest([
            this.budgetLibraryService.get(),
            this.vendorsService.get().pipe(
              switchMap(() => {
                return this.budgetLibraryQuery.selectAll().pipe(
                  tap((budgets: any[]) => {
                    const vendorsWithBudgets = budgets.map((budget) => budget.vendor_id);
                    const allVendors = this.organizationQuery.getAllVendors();

                    this.vendors = allVendors.filter((vendor) =>
                      vendorsWithBudgets.includes(vendor.id)
                    );

                    if (this.vendors.length === 1) {
                      this.organizationStore.setActive(this.vendors[0].id);
                      this.selectedVendor.setValue(this.vendors[0].id);
                    } else {
                      // reset any older selected vendors.
                      this.organizationStore.setActive(null);
                      this.selectedVendor.setValue('');
                    }
                  })
                );
              })
            ),
          ]);
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.gridData$ = this.budgetLibraryQuery.selectAll().pipe(
          switchMap((listData: any[]) => {
            return this.selectedVendor.valueChanges.pipe(
              startWith(this.selectedVendor.value as string),
              map((selected_organization) => {
                const org = this.organizationQuery.getEntity(selected_organization);
                if (selected_organization) {
                  return listData.filter((budget) => {
                    return (budget.vendor_name as string) === (org?.name as string);
                  });
                }
                return listData.sort((x: any, y: any) => {
                  // eslint-disable-next-line no-nested-ternary
                  let comp = 0;
                  if (x.vendor_name === y.vendor_name) {
                    if (
                      x.budget_type === BudgetType.BUDGET_PRIMARY &&
                      (x.is_current ||
                        (x.is_baseline &&
                          (!y.is_current || y.budget_type !== BudgetType.BUDGET_PRIMARY)))
                    ) {
                      comp = -1;
                    }
                  } else {
                    comp = x?.vendor_name > y?.vendor_name ? 1 : -1;
                  }
                  return comp;
                });
              })
            );
          })
        );
      });
  }

  onDataRendered(e: any) {
    this.gridAPI = e.api;
    this.gridAPI$.next(e.api);
    const { columnApi } = e;
    const allColumnIds: string[] = [];
    columnApi.getAllColumns().forEach((column: Column) => {
      allColumnIds.push(column.getColId());
    });
    columnApi.autoSizeColumns(allColumnIds, false);
    this.gridOptions$.getValue()?.api?.sizeColumnsToFit();

    this.refreshGridWidth();
  }

  onGridReady({ api }: GridReadyEvent) {
    api.sizeColumnsToFit();
    this.refreshGridWidth();
  }

  autoSize() {
    this.gridOptions$.getValue()?.api?.sizeColumnsToFit();
  }

  getRowHeight(params: RowHeightParams): number | null {
    return params.node.rowIndex !== 0 && params.node.group ? 60 : null;
  }

  refreshGridWidth() {
    const div = document.querySelector('.ag-header-container') as HTMLDivElement;
    if (div) {
      const paddingOffset = 2;
      this.width$.next(div.getBoundingClientRect().width + paddingOffset);
    } else {
      this.width$.next(0);
    }
  }

  async downloadBudgetLibrary(rowNode: RowNode) {
    const { bucket_key } = rowNode.data;
    if (!bucket_key) {
      this.overlayService.error("This budget version doesn't have file");
      return;
    }
    const ref = this.overlayService.loading();
    const { success, data } = await this.apiService.getS3ZipFile(bucket_key);
    if (success && data) {
      const fileName =
        `${rowNode.data.budget_name}_${rowNode.data.vendor_name}_Budget_Library_` +
        `${rowNode.data.budget_id}_${(rowNode.data.create_date || '').slice(0, 10)}`;
      await this.apiService.downloadZipOrFile(data, fileName);
    }
    ref.close();
  }

  async changeBudgetTypeBaseline(rowNode: RowNode) {
    const { budget_version_id, vendor_id } = rowNode.data;
    if (!budget_version_id) {
      this.overlayService.error("This bugdet doesn't have a version.");
      return;
    }
    const ref = this.overlayService.open({ content: BudgetLibraryUploadBaselineComponent });
    const event = await ref.afterClosed$.toPromise();
    if (event.data) {
      await this.budgetLibraryService.updateBudgetVersionAsBaseline(budget_version_id, vendor_id);
    } else {
      await this.budgetLibraryService.updateLastBudgetVersion(rowNode.data);
    }
  }

  userFormatter(sub: string | undefined) {
    const user = this.users.get(sub || '');
    if (user) {
      const isUserAuxAdmin = user.email.includes('@auxili.us');
      if (this.authQuery.isAuxAdmin() || !isUserAuxAdmin) {
        return `${user.given_name} ${user.family_name}`;
      }
      return 'Auxilius Expert';
    }
    return Utils.zeroHyphen;
  }

  getFormattedbudgetTypeStatus(type: BudgetType, isChangeOrder: boolean): string {
    const mapBudgetStatus = new Map<BudgetType, string>([
      [BudgetType.BUDGET_CHANGE_ORDER, 'Change Order'],
      [BudgetType.BUDGET_MODEL, 'Model'],
      [BudgetType.BUDGET_PRIMARY, isChangeOrder ? 'Change Order' : 'Budget Upload'],
      [BudgetType.BUDGET_SECONDARY, 'Scenario Budget'],
      [BudgetType.BUDGET_VENDOR_ESTIMATE, 'Vendor Estimate'],
    ]);

    return mapBudgetStatus.get(type) || '';
  }
}
