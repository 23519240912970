<div class="grid grid-cols-12 gap-6">
  <div class="col-span-5 border rounded p-4 tabular-nums">
    <div class="font-bold mb-4">Patient-Site Mix Trajectory and Delta</div>
    <div class="flex justify-center">
      <div class="flex-grow">
        <div class="text-white font-bold text-xs mt-5 flex">
          <div class="py-1 text-center bg-aux-blue rounded-l" [ngStyle]="{ flexGrow: 16 }">16</div>
          <div class="py-1 text-center bg-aux-green" [ngStyle]="{ flexGrow: 2 }">2</div>
          <div class="py-1 text-center bg-aux-blue-light rounded-r" [ngStyle]="{ flexGrow: 5 }">
            5
          </div>
        </div>

        <div class="grid grid-cols-3 mt-5">
          <div>
            <div class="flex items-center mb-2">
              <div class="w-4 h-4 rounded-full bg-aux-blue mr-2"></div>
              <div class="text-xs">Low</div>
            </div>
            <div class="text-sm font-bold">$1.84M</div>
          </div>
          <div>
            <div class="flex items-center mb-2">
              <div class="w-4 h-4 rounded-full bg-aux-green mr-2"></div>
              <div class="text-xs">Mid</div>
            </div>
            <div class="text-sm font-bold">$1.13M</div>
          </div>
          <div>
            <div class="flex items-center mb-2">
              <div class="w-4 h-4 rounded-full bg-aux-blue-light mr-2"></div>
              <div class="text-xs">High</div>
            </div>
            <div class="text-sm font-bold">$536K</div>
          </div>
        </div>
      </div>

      <div>
        <div class="pl-5 text-xs">Assumed</div>
        <div class="pl-5 text-xl font-bold">$34,350</div>
      </div>
    </div>

    <div class="flex justify-center">
      <div class="flex-grow">
        <div class="text-white font-bold text-xs mt-5 flex">
          <div class="py-1 text-center bg-aux-blue rounded-l" [ngStyle]="{ flexGrow: 12 }">12</div>
          <div class="py-1 text-center bg-aux-green" [ngStyle]="{ flexGrow: 4 }">4</div>
          <div class="py-1 text-center bg-aux-blue-light rounded-r" [ngStyle]="{ flexGrow: 7 }">
            7
          </div>
        </div>
      </div>

      <div>
        <div class="pl-5 text-xs">Actual</div>
        <div class="pl-5 text-xl font-bold">$34,350</div>
      </div>
    </div>
  </div>

  <div class="col-span-3 border rounded p-4 flex flex-col justify-between tabular-nums">
    <div class="font-bold mb-4">“Other” Patient Cost Trajectory and Delta</div>
    <div class="flex gap-4 justify-between">
      <div>
        <div class="text-xs">Planned %</div>
        <div class="mt-2 text-sm font-bold">%12</div>
      </div>
      <div>
        <div class="text-xs">Planned $</div>
        <div class="mt-2 text-sm font-bold">$178,000</div>
      </div>
      <div>
        <div class="text-xs">Actual %</div>
        <div class="mt-2 text-sm font-bold">14%</div>
      </div>
      <div>
        <div class="text-xs">Actual $ (Fcst)</div>
        <div class="mt-2 text-sm font-bold">$224,000</div>
      </div>
    </div>
    <div class="flex justify-end">
      <button class="mt-6 font-medium underline text-aux-blue text-sm">
        View Other Costs Detail
      </button>
    </div>
  </div>

  <div class="col-span-4 border rounded p-4 flex flex-col">
    <div class="font-bold mb-4">Source Data Status Report</div>
    <div class="grid grid-cols-2 gap-4 text-xs flex-grow">
      <div class="flex justify-center">
        <div class="flex flex-col">
          <div class="flex items-center flex-1">
            <div class="w-4 h-4 rounded-full bg-aux-green"></div>
            <div class="ml-2">Proposal/Initial Budget</div>
          </div>
          <div class="flex items-center flex-1">
            <div>
              <div class="w-4 h-4 rounded-full bg-aux-warn"></div>
            </div>
            <div class="ml-2">Site Level Contract</div>
          </div>
          <div class="flex items-center flex-1">
            <div>
              <div class="w-4 h-4 rounded-full bg-aux-error"></div>
            </div>
            <div class="ml-2">CTMS/EDC Actuals</div>
          </div>
          <div class="flex items-center flex-1">
            <div>
              <div class="w-4 h-4 rounded-full bg-aux-green"></div>
            </div>
            <div class="ml-2">Sponsor Modifications</div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  </div>
</div>

<div class="border rounded p-4 mt-10">
  <div class="grid grid-cols-3 gap-3">
    <div class="border-r pr-8 pt-4 flex flex-col justify-between tabular-nums">
      <div>
        <div class="font-bold mb-3">Contracted Assumptions</div>
        <div class="text-xs text-aux-gray-darkest mb-4">Last Updated: 06/22/2021</div>
        <div class="grid grid-cols-3 text-xs font-medium">
          <div class="col-span-full py-2"></div>

          <div></div>
          <div class="text-right font-bold">Total $</div>
          <div class="text-right font-bold">Remaining</div>

          <div class="col-span-full py-2"></div>

          <div>Total</div>
          <div class="text-right">$1,340,000</div>
          <div class="text-right">$1,340,000</div>

          <div class="col-span-full py-4"></div>

          <div>Visits $</div>
          <div class="text-right">{{ 130000 | money }}</div>
          <div class="text-right">{{ 130000 | money }}</div>

          <div class="col-span-full py-1"></div>

          <div>Other $</div>
          <div class="text-right">{{ 130000 | money }}</div>
          <div class="text-right">{{ 130000 | money }}</div>

          <div class="col-span-full py-1"></div>

          <div>Overhead $</div>
          <div class="text-right">$0</div>
          <div class="text-right">$0</div>
        </div>
      </div>
      <div class="">
        <aux-radio-button [checked]="true" name="radioGroup" tabIndex="1">
          <span class="pl-3 text-xs">Set as forecast Assumption</span>
        </aux-radio-button>
      </div>
    </div>

    <div class="pl-8 pt-4 flex flex-col justify-between tabular-nums">
      <div>
        <div class="font-bold mb-3">Adjusted Assumptions</div>
        <div class="text-xs text-aux-gray-darkest mb-4">Last Updated: 06/22/2021</div>
        <div class="grid grid-cols-3 text-xs font-medium">
          <div class="col-span-full py-2"></div>

          <div></div>
          <div class="text-right font-bold">Total $</div>
          <div class="text-right font-bold">Remaining</div>

          <div class="col-span-full py-2"></div>

          <div>Total</div>
          <div class="text-right">{{ 1850000 | money }}</div>
          <div class="text-right">{{ 1850000 | money }}</div>

          <div class="col-span-full py-4"></div>

          <div>Visits $</div>
          <div class="text-right">{{ 130000 | money }}</div>
          <div class="text-right">{{ 130000 | money }}</div>

          <div class="col-span-full py-1"></div>

          <div>Other $</div>
          <div class="text-right">{{ 130000 | money }}</div>
          <div class="text-right">{{ 130000 | money }}</div>

          <div class="col-span-full py-1"></div>

          <div>Overhead $</div>
          <div class="text-right">$0</div>
          <div class="text-right">$0</div>

          <div class="col-span-full py-1"></div>

          <div>Delta to Contracted</div>
          <div class="text-right">{{ 11000 | money }}</div>
          <div class="text-right">{{ 11000 | money }}</div>
        </div>
      </div>

      <div class="">
        <aux-radio-button name="radioGroup" tabIndex="1">
          <span class="pl-3 text-xs">Set as forecast Assumption</span>
        </aux-radio-button>
      </div>
    </div>

    <div class="border bg-aux-gray-light rounded ml-8 pl-8 pr-8 pt-4 pb-4 tabular-nums">
      <div class="flex justify-between items-center mb-8">
        <div class="font-bold mb-3">Modifications</div>
      </div>

      <!--<div class="text-xs text-aux-gray-darkest mb-8">Last Updated: 06/22/2021</div>-->

      <div class="grid grid-cols-12 gap-5 text-xs">
        <div class="col-span-6">
          <div class="mb-3 text-xs flex items-end h-5">Screen Fail Rate</div>
          <aux-input
            [(ngModel)]="modifications.screen_fail"
            [disabled]="!editMode"
            [type]="'text'"
          ></aux-input>
        </div>
        <div class="col-span-6">
          <div class="mb-3 text-xs flex items-end h-5">Other Payments (% of Patient Cost)</div>
          <aux-input
            [(ngModel)]="modifications.other_payments"
            [disabled]="!editMode"
            [type]="'text'"
          ></aux-input>
        </div>
        <div class="col-span-6">
          <div class="mb-3 mt-3 text-xs flex items-end h-5">Site Mix</div>
          <aux-input
            [(ngModel)]="modifications.site_mix"
            [disabled]="!editMode"
            [type]="'text'"
          ></aux-input>
        </div>
        <div class="col-span-6">
          <div class="mb-3 mt-3 text-xs flex items-end h-5">
            “Other” Site Cost (as % of Site Overhead)
          </div>
          <aux-input
            [(ngModel)]="modifications.site_cost"
            [disabled]="!editMode"
            [type]="'text'"
          ></aux-input>
        </div>

        <hr class="col-span-full" />

        <div class="col-span-6">
          <div class="mb-3 text-xs flex items-end h-5">Apply Master Buffer</div>
          <aux-input
            [(ngModel)]="modifications.buffer"
            [disabled]="!editMode"
            [type]="'text'"
          ></aux-input>
        </div>
        <div class="col-span-6 justify-self-end self-end mb-2" *ngIf="editMode">
          <button class="btn btn--blue w-32" (click)="editMode = false">Save Edits</button>
        </div>
        <div class="col-span-6 justify-self-end self-end mb-4" *ngIf="!editMode">
          <button class="flex items-center no-underline aux-link" (click)="editMode = true">
            <span class="w-6" inlineSVG="edit.svg"></span>
            <span>Edit</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="font-semibold mt-8 mb-4">Investigator - Site-Level Reference Table</div>

<ag-grid-angular
  style="width: 100%"
  class="ag-theme-alpine tabular-nums"
  [rowData]="[]"
  [gridOptions]="gridOptions"
></ag-grid-angular>
