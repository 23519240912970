<ng-template #poTemp>
  <div class="flex space-x-2">
    <div class="flex items-center">
      <div class="text-sm mr-2">Filter By</div>
      <ng-select
        class="w-48 text-sm"
        [multiple]="false"
        [clearable]="true"
        (change)="refreshGridFilter()"
        [formControl]="selectedVendor"
      >
        <ng-container *ngVar="organizationQuery.allVendors$ | async as vendors">
          <ng-option [value]="''" *ngIf="vendors.length > 1">All</ng-option>
          <ng-option [value]="vendor.id" *ngFor="let vendor of vendors">
            <span [title]="vendor.name">{{ vendor.name }}</span>
          </ng-option>
        </ng-container>
      </ng-select>
    </div>
  </div>

  <button type="button" class="text-sm btn btn--blue" (click)="onAddPO()">
    <plus-outline-icon></plus-outline-icon>
    <span class="ml-2">Add Purchase Order</span>
  </button>
</ng-template>
<ng-container *ngIf="(purchaseOrdersQuery.selectLoading() | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="(purchaseOrdersQuery.selectLoading() | async) === false">
  <div class="mt-4"></div>
  <div class="text-xl font-bold">Invoiced to Date, by PO</div>

  <div class="mt-4"></div>
  <div class="flex items-center space-x-4 mb-4 text-sm">
    <div class="flex items-center space-x-2">
      <div class="bg-aux-blue w-4 h-4 rounded-full border"></div>
      <div>Invoices Received</div>
    </div>
    <div class="flex items-center space-x-2">
      <div class="bg-aux-green-secondary w-4 h-4 rounded-full border"></div>
      <div>Invoices Paid</div>
    </div>
  </div>
  <div class="grid grid-cols-12 text-sm gap-5">
    <div class="col-span-10"></div>
    <div class="col-span-2 font-bold">Total PO ($)</div>
    <ng-container *ngFor="let po of filteredGridData$ | async">
      <div class="col-span-10 font-medium">Purchase Order #{{ po.po_number }}</div>
      <div class="col-span-2"></div>

      <div class="col-span-10 font-bold text-xs flex">
        <div
          *ngIf="po.received_percentage > 0"
          class="h-5 rounded-l flex items-center pr-8 justify-end"
          [ngClass]="{
            'bg-aux-blue': po.received_percentage <= 100,
            'bg-aux-error': po.received_percentage > 100,
            'rounded-r': po.paid_percentage === 0 && po.received_percentage > 100
          }"
          [ngStyle]="{ flexGrow: po.received_percentage }"
        >
          <span class="text-white tabular-nums">
            {{ po.received_amount | money }} ({{ po.received_percentage }}%)
          </span>
        </div>
        <div
          *ngIf="po.paid_percentage > 0"
          class="h-5 flex items-center pr-8 justify-end bg-aux-green-secondary"
          [ngStyle]="{ flexGrow: po.paid_percentage }"
          [ngClass]="{
            'rounded-l': po.received_percentage === 0,
            'rounded-r': po.paid_percentage + po.received_percentage > 100
          }"
        >
          <span class="text-white tabular-nums">
            {{ po.paid_amount | money }} ({{ po.paid_percentage }}%)
          </span>
        </div>
        <ng-container
          *ngIf="100 - (po.received_percentage + po.paid_percentage) as remaining_percentage"
        >
          <div
            *ngIf="remaining_percentage > 0"
            class="h-5 bg-aux-gray-light rounded-r flex items-center pl-8"
            [ngClass]="{
              'rounded-l': remaining_percentage === 100
            }"
            [ngStyle]="{ flexGrow: remaining_percentage }"
          >
            <span *ngIf="remaining_percentage === 100">—</span>
          </div>
        </ng-container>
      </div>
      <div
        class="col-span-2 text-xs text-aux-gray-darkest opacity-75 flex items-center tabular-nums"
      >
        {{ po.po_amount | money }}
      </div>
    </ng-container>
  </div>

  <!-- <div class="aux-link mt-4 text-xs">View all (5)</div> -->

  <div class="mt-8"></div>

  <div class="font-semibold text-xl mb-4">Purchase Orders</div>

  <ng-container *ngIf="filteredGridData$">
    <ag-grid-angular
      style="width: 100%; height: 400px"
      class="ag-theme-alpine tabular-nums"
      [rowData]="filteredGridData$ | async"
      [gridOptions]="$any(gridOptions$ | async)"
      (gridReady)="onGridReady($event)"
      (firstDataRendered)="onGridReady($event)"
      [components]="components"
    >
    </ag-grid-angular>
  </ng-container>
</ng-container>
