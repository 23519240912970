import { ChangeDetectionStrategy, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { differenceWith, isEqual } from 'lodash-es';
import * as dayjs from 'dayjs';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '@services/api.service';
import { AuthQuery } from '@models/auth/auth.query';
import { OrganizationService } from '@models/organization/organization.service';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { OverlayService } from '@services/overlay.service';
import { SitesService } from '@models/sites/sites.service';
import { TrialsService } from '@models/trials/trials.service';

import { Document } from '@services/gql.service';
import { FormBuilder } from '@angular/forms';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { RowNode, RowSelectedEvent } from 'ag-grid-community';
import { PurchaseOrdersService } from '../vendor-payments-page/tabs/purchase-orders/state/purchase-orders.service';
import { InvoiceService } from '../vendor-payments-page/tabs/invoices/state/invoice.service';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { ChangeOrderService } from '../budget-page/tabs/change-order/state/change-order.service';
import { DocumentLibraryService } from './document-library.service';
import { MainStore } from '../../layouts/main-layout/state/main.store';
import { DocumentLibraryComponent } from './document-library/document-library.component';
import {
  EditMultipleDocumentsModalComponent,
  EditMultipleDocumentsModalData,
} from './edit-multiple-documents-modal/edit-multiple-documents-modal';

@UntilDestroy()
@Component({
  selector: 'aux-document',
  templateUrl: './documents.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsComponent implements OnInit, OnDestroy {
  @ViewChild('documentLibraryComponent') documentLibraryComponent!: DocumentLibraryComponent;

  refresh$ = new Subject();

  documentLibraryGridData$ = new BehaviorSubject<Document[]>([]);

  documentLibraryForm = this.fb.group({
    search: undefined,
    documentTypes: [],
    vendors: [],
    sites: [],
    dateFrom: undefined,
    dateTo: undefined,
  });

  removedRows: string[] = [];

  selectedRows: Document[] = [];

  initialValue$?: BehaviorSubject<any[]>;

  hasChanges$ = new BehaviorSubject(false);

  loading$ = new BehaviorSubject(false);

  isBulkApplyButtonDisabled$ = new BehaviorSubject(true);

  constructor(
    private apiService: ApiService,
    private mainQuery: MainQuery,
    private authQuery: AuthQuery,
    private vendorsService: OrganizationService,
    private trialUserService: TrialUserService,
    private overlayService: OverlayService,
    private sitesService: SitesService,
    private purchaseOrdersService: PurchaseOrdersService,
    private trialsService: TrialsService,
    private invoiceService: InvoiceService,
    private changeOrderService: ChangeOrderService,
    public documentLibrary: DocumentLibraryService,
    private fb: FormBuilder,
    private mainStore: MainStore
  ) {
    this.mainStore.update({ fullPage: true });
  }

  ngOnInit(): void {
    combineLatest([this.refresh$.pipe(startWith(null)), this.mainQuery.select('trialKey')])
      .pipe(
        tap(() => {
          this.resetViewState();
          this.loading$.next(true);
        }),
        untilDestroyed(this),
        switchMap(() => {
          return this.documentLibrary
            .getRequiredDictionaries()
            .pipe(switchMap(() => this.documentLibrary.getDocumentLibraryList()));
        })
      )
      .subscribe((gridData) => {
        this.loading$.next(false);
        this.documentLibraryGridData$.next(gridData);
      });

    this.subscribeOnFormChange();
  }

  ngOnDestroy(): void {
    this.mainStore.update({ fullPage: false });
  }

  private subscribeOnFormChange() {
    this.documentLibraryForm.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
      if (!this.initialValue$ && values.table) {
        this.initialValue$ = new BehaviorSubject<any[]>(values.table);
        return;
      }

      if (this.initialValue$ && values.table) {
        this.initialValue$.getValue();
        this.hasChanges$.next(!isEqual(this.initialValue$.getValue(), values.table));
      }
    });
  }

  private resetViewState() {
    this.hasChanges$.next(false);
    this.removedRows = [];
    this.initialValue$ = undefined;
  }

  updateGridData = () => {
    const formValues = this.documentLibraryForm.value.table;
    const gridData = this.documentLibraryGridData$
      .getValue()
      .map(({ id, description, site_id, document_type_id, vendor_id }: Document) => ({
        id,
        description,
        site_id,
        document_type_id,
        vendor_id,
      }));
    const changes = differenceWith(formValues, gridData, isEqual);

    this.documentLibraryComponent.gridAPI.forEachNode((node) => {
      const changesForNode = changes.find(({ id }) => id === node.data.id);

      if (changesForNode) {
        node.setData({ ...node.data, ...changesForNode });
      }
    });
  };

  saveAllChanges = async () => {
    if (this.initialValue$) {
      const changesList = differenceWith(
        this.documentLibraryForm.value.table,
        this.initialValue$.getValue(),
        isEqual
      );

      await this.documentLibrary.updateDocuments(changesList);

      if (this.removedRows.length) {
        await this.documentLibrary.removeDocuments(this.removedRows);
        this.removedRows = [];
      }

      this.documentLibraryComponent.gridAPI.deselectAll();
      this.initialValue$.next(this.documentLibraryForm.value.table);
      this.hasChanges$.next(false);
    }
  };

  onRemoveRow(id: string) {
    this.removedRows.push(id);
  }

  async onDocumentUploadClick() {
    const resp = await this.overlayService
      .open({
        content: DocumentUploadComponent,
        data: { vendors: this.documentLibrary.vendors, sites: this.documentLibrary.sites },
      })
      .afterClosed$.toPromise();
    if (resp.data) {
      this.refresh$.next();
    }
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges$.getValue()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await result.afterClosed$.toPromise();
      return !!event.data;
    }
    return true;
  }

  hasFilters = () => {
    const { documentTypes, vendors, sites, dateFrom, dateTo } = this.documentLibraryForm.value;

    return !!(documentTypes?.length || vendors?.length || sites?.length || dateFrom || dateTo);
  };

  shouldRowBeShown = (node: RowNode) => {
    const { documentTypes, vendors, sites, dateFrom, dateTo } = this.documentLibraryForm.value;

    return (
      (!documentTypes?.length || documentTypes.includes(node.data.document_type_id)) &&
      (!vendors?.length || vendors.includes(node.data.vendor_id)) &&
      (!sites?.length || sites.includes(node.data.site_id)) &&
      (!dateFrom || dayjs(node.data.create_date).isSameOrAfter(dateFrom)) &&
      (!dateTo || dayjs(dateTo).isSameOrAfter(node.data.create_date, 'day'))
    );
  };

  onFilterChange = () => {
    this.documentLibraryComponent.gridAPI?.onFilterChanged();
  };

  onRowSelected = (event: RowSelectedEvent) => {
    this.selectedRows = event.api.getSelectedRows();
    this.isBulkApplyButtonDisabled$.next(!this.selectedRows.length);
  };

  selectRows() {
    const selectedDocumentIds = this.selectedRows.map((document) => document.id);

    this.documentLibraryComponent.gridAPI.forEachNode((node) => {
      if (selectedDocumentIds.includes(node.data.id)) {
        node.setSelected(true);
      }
    });
  }

  onBulkApplyButtonClick = () => {
    const modalRef = this.overlayService.open<any, EditMultipleDocumentsModalData>({
      content: EditMultipleDocumentsModalComponent,
      data: {
        selectedRows: this.selectedRows,
        formGroup: this.documentLibraryForm,
      },
    });

    modalRef.afterClosed$.pipe(untilDestroyed(this)).subscribe(({ data }) => {
      if (data?.updateGrid) {
        this.updateGridData();
      }
    });
  };
}
