import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { InvoiceModel } from '../state/invoice.model';

@Component({
  template: `
    <div
      class="flex items-center justify-center h-full"
      *ngVar="{
        isInvoiceFinalized: params.isInvoiceFinalized$ | async,
        invoiceLockTooltip: params.invoiceLockTooltip$ | async,
        iCloseMonthsProcessing: params.iCloseMonthsProcessing$ | async
      } as obj"
    >
      <button
        class="p-0 w-8 h-8 flex justify-center items-center"
        type="button"
        (click)="onDownloadClick()"
        [auxTooltip]="'Download'"
        *ngIf="!params.hideDownloadButton"
        [disabled]="params.disableDownloadButton"
      >
        <download-solid-icon [size]="16" class="text-aux-blue"></download-solid-icon>
      </button>

      <div [auxTooltip]="obj?.invoiceLockTooltip || 'Delete'">
        <button
          *ngIf="!params.hideDeleteButton"
          class="p-0 w-8 h-8 flex justify-center items-center"
          type="button"
          (click)="onDeleteClick()"
          [disabled]="obj.isInvoiceFinalized || obj.iCloseMonthsProcessing"
        >
          <trash-solid-icon
            [size]="16"
            class="text-aux-error"
            [ngClass]="{ 'opacity-50': obj.isInvoiceFinalized || obj.iCloseMonthsProcessing }"
          ></trash-solid-icon>
        </button>
      </div>

      <div [auxTooltip]="obj?.invoiceLockTooltip || 'Upload'">
        <button
          class="p-0 w-8 h-8 flex justify-center items-center"
          type="button"
          (click)="onUploadClick()"
          [disabled]="obj.isInvoiceFinalized || obj.iCloseMonthsProcessing"
        >
          <upload-solid-icon
            [size]="16"
            class="text-aux-blue"
            [ngClass]="{ 'opacity-50': obj.isInvoiceFinalized || obj.iCloseMonthsProcessing }"
          ></upload-solid-icon>
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgInvoiceActionsComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams & {
    deleteClickFN: undefined | ((params: { rowNode: RowNode }) => void);
    downloadClickFN: undefined | ((params: { rowNode: RowNode }) => void);
    uploadClickFN: undefined | ((invoice: InvoiceModel) => void);
    hideDownloadButton?: boolean;
    disableDownloadButton?: boolean;
    hideDeleteButton?: boolean;
    isInvoiceFinalized$: Observable<boolean>;
    iCloseMonthsProcessing$: Observable<boolean>;
    invoiceLockTooltip$: Observable<string>;
  };

  refresh(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    if (!this.params.value) {
      this.params.disableDownloadButton = true;
    }
  }

  onDownloadClick() {
    if (this.params.downloadClickFN) {
      this.params.downloadClickFN({ rowNode: this.params.node });
    }
  }

  onDeleteClick() {
    if (this.params.deleteClickFN) {
      this.params.deleteClickFN({ rowNode: this.params.node });
    }
  }

  onUploadClick() {
    if (this.params.uploadClickFN) {
      this.params.uploadClickFN(this.params.node.data);
    }
  }
}
