<div class="flex justify-between items-center mb-8">
  <h1 class="text-2xl text-aux-gray-darkest font-bold h-11 flex items-center">Patient Tracker</h1>
  <div
    class="flex space-x-2"
    *ngIf="(isTrackerFinalized$ | async) === false && (iCloseMonthsProcessing$ | async) === false"
  >
    <div class="flex justify-between items-center space-x-4">
      <aux-menu #patient_tracker_menu>
        <button
          type="button"
          role="menuitem"
          class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 px-4 py-2 text-sm w-full flex items-center focus:outline-none"
          (click)="onPatientTrackerUploadClick(); patient_tracker_menu.close()"
        >
          <span inlineSVG="file-upload.svg"></span>
          <span class="ml-2">Upload</span>
        </button>
      </aux-menu>
    </div>
  </div>
</div>

<aux-workflow-panel
  [workflowName]="workflowName"
  [isAdminUser]="isAdminUser"
  *ngIf="isQuarterCloseEnabled$ | async"
  [processing]="(iCloseMonthsProcessing$ | async)!"
  className="mb-7"
></aux-workflow-panel>

<ng-container
  *ngIf="
    $any(patientTrackerQuery.selectLoading() | async) ||
    (isHandlingUpload$ | async) ||
    (isLoadingData$ | async)
  "
>
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container
  *ngIf="
    (patientTrackerQuery.selectLoading() | async) === false &&
    (isHandlingUpload$ | async) === false &&
    (isLoadingData$ | async) === false
  "
>
  <ng-container *ngIf="!(gridData$ | async)?.length">
    <div class="flex items-center justify-center flex-col text-aux-gray-dark mt-28 space-y-5">
      <div inlineSVG="documents.svg" class="w-20"></div>
      <div class="text-xl font-bold">No Patient Data to Review</div>
    </div>
  </ng-container>

  <ng-container *ngIf="(gridData$ | async)?.length">
    <div class="grid grid-cols-3 gap-7.5 mb-8 mt-4" *ngIf="showAnalyticsSection$ | async">
      <div
        class="border border-aux-gray-dark rounded p-5 h-40 flex flex-col justify-between tabular-nums"
        *ngFor="let card of cards$ | async; let i = index"
        [attr.data-index]="i"
      >
        <div class="font-semibold text-aux-black h-14 w-72 pb-2">{{ card.header }}</div>
        <div
          class="flex justify-around items-center self-start"
          [ngClass]="{
            '-mt-3': i == 1
          }"
        >
          <div
            class="text-aux-gray-darkest justify-start font-bold text-xl w-44"
            [innerHTML]="card.data"
          ></div>
          <div class="flex justify-center flex-col">
            <div
              *ngFor="let prop of [card.firstProp, card.secondProp]; last as last"
              class="flex items-center"
              [ngClass]="{
                'mb-2': !last
              }"
            >
              <div *ngIf="prop.status && (prop.status === 'high' || prop.status === 'low')">
                <svg
                  class="w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  [ngClass]="{
                    'text-aux-error': prop.status && prop.status === 'high',
                    'text-aux-green transform rotate-180': prop.status && prop.status === 'low'
                  }"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <span *ngIf="prop.status && prop.status === 'middle'" class="text-yellow-300">
                —
              </span>
              <div
                class="ml-1 font-medium text-aux-gray-darkest tracking-tighter text-sm whitespace-nowrap"
              >
                {{ prop.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <span class="font-bold text-base"> Patient-level Actuals </span>

      <span class="ml-4 text-aux-gray-lightest text-base">
        Last Source Data Refresh: {{ lastSourceDataRefreshDate | date: 'MMMM d, y, h:mm a' }}
      </span>
    </div>

    <div class="my-4 text-sm flex justify-between items-center space-x-8">
      <div class="flex space-x-8">
        <div class="flex items-center">
          <div class="mr-2">Site:</div>
          <ng-select
            class="w-72 tabular-nums"
            [multiple]="true"
            [clearable]="true"
            [searchable]="false"
            (change)="onSiteSelected($event)"
            [(ngModel)]="selectedSites"
            placeholder="ALL"
          >
            <ng-option
              [value]="site.id"
              *ngFor="let site of sitesQuery.selectSortedListBy('site_no') | async"
            >
              {{ site.site_no }}
            </ng-option>
          </ng-select>
        </div>

        <div class="flex space-x-2 items-center">
          <div>Dates</div>
          <aux-toggle [value]="isDisplayCosts" (change)="display$.next($event ? 'costs' : 'dates')">
          </aux-toggle>
          <div>Costs</div>
        </div>

        <div class="flex items-center">
          <aux-toggle-currency
            [value]="isContractedCurrency"
            (nameSelectedCurrency)="selectedVisibleCurrency$.next($event)"
          >
          </aux-toggle-currency>
        </div>
      </div>

      <div class="flex items-center">
        <div class="mr-2">Version:</div>
        <ng-select
          class="w-72 tabular-nums mr-4"
          [clearable]="false"
          (change)="onVersionChange($event)"
          [(ngModel)]="selectedVersion"
          placeholder="Versions"
        >
          <ng-option [value]="version.id" *ngFor="let version of versions$ | async">
            {{ version.create_date | date: 'MM.dd.YYYY HH:mm' }}
          </ng-option>
        </ng-select>

        <aux-export-excel-button
          [gridAPI]="gridAPI"
          [excelOptions]="gridOptions"
          [ignoreColsId]="['site_id']"
          [getDynamicExcelParamsCallback]="getDynamicExcelParams"
        >
        </aux-export-excel-button>
      </div>
    </div>

    <ag-grid-angular
      id="patientTrackerGrid"
      class="ag-theme-aux mb-4 tabular-nums"
      [rowData]="$any(gridData$ | async)"
      [gridOptions]="$any(gridOptions$ | async)"
      (gridReady)="onGridReady($event)"
      (firstDataRendered)="sizeColumnsToFit()"
      (gridSizeChanged)="sizeColumnsToFit()"
      (filterChanged)="updateGridLayout()"
    >
    </ag-grid-angular>

    <a class="aux-link text-xs mb-16 block" [routerLink]="[patientBudgetLink]"
      >View Schedule of Assessments</a
    >
  </ng-container>
</ng-container>
