import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AgActionsComponent } from '@components/ag-actions/ag-actions.component';
import { OverlayService } from '@services/overlay.service';
import { RequireSome, Utils } from '@services/utils';
import {
  CellValueChangedEvent,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDragEndEvent,
  RowNode,
} from 'ag-grid-community';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, take } from 'rxjs/operators';
import { EventService } from '@services/event.service';
import { Organization, EventType } from '@services/gql.service';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { SiteGroupsService } from './state/site-groups.service';
import { SiteGroupsQuery } from './state/site-groups.query';
import { SiteGroupModel } from '../models/site-group.model';
import { SiteCurveQuery } from './site-curve/site-curve.query';
import { SiteCurveService } from './site-curve/site-curve.service';

@UntilDestroy()
@Component({
  selector: 'aux-forecast-site-groups',
  templateUrl: 'forecast-site-groups.component.html',
  styles: [
    `
      ::ng-deep .group-actions .ag-cell-value {
        overflow: initial;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastSiteGroupsComponent {
  gridOptions$: BehaviorSubject<GridOptions> = new BehaviorSubject({
    defaultColDef: {
      sortable: true,
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
      editable: true,
      cellStyle: {
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
        overflow: 'hidden',
      },
    },
    enableRangeSelection: true,
    suppressMenuHide: true,
    headerHeight: 40,
    rowClassRules: {
      'has-error': (params) => params.data.showError,
    },
    rowDragManaged: true,
    animateRows: true,
    getRowId: ({ data }) => data?.id || data?.randomID,
    columnDefs: [
      {
        headerName: 'id',
        field: 'id',
        hide: true,
      },
      {
        headerName: '',
        field: 'actions',
        cellRenderer: AgActionsComponent,
        cellRendererParams: {
          editClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            if (typeof rowNode.rowIndex === 'number') {
              this.gridAPI.startEditingCell({
                rowIndex: rowNode.rowIndex,
                colKey: 'name',
              });
            }
          },
          deleteClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            if (!rowNode.data.id) {
              this.gridAPI.applyTransaction({ remove: [rowNode.data] });
              if (this.addedRowscounter === 1) {
                this.reorderedRows.clear();
                this.removedRows = [];
                this.newRowAdded = false;
              } else {
                this.removedRows.pop();
              }
              this.addedRowscounter -= 1;
              this.checkChanges();
            } else {
              this.dialogConfirmRemoveSiteGroup(rowNode);
            }
          },
        },
        cellClass: 'group-actions',
        maxWidth: 120,
        minWidth: 110,
        suppressSizeToFit: true,
        editable: false,
        rowDrag: true,
      },
      {
        headerName: 'Site Group',
        field: 'name',
        tooltipField: 'name',
      },
      {
        headerName: 'Description',
        field: 'description',
        tooltipField: 'description',
      },
    ],
  } as GridOptions);

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  gridColumnApi$ = new ReplaySubject<ColumnApi>(1);

  gridColumnApi!: ColumnApi;

  gridData$ = this.siteGroupQuery.selectAll().pipe(
    map((value) => {
      return value.map(({ id, name, description, rank_order }) => ({
        id,
        name,
        description,
        rank_order,
      }));
    })
  );

  editedRows = new Set<string>();

  removedRows: SiteGroupModel[] = [];

  newRowAdded = false;

  addedRowscounter = 0;

  reorderQueue: { ids: string; new_index: number }[] = [];

  reorderedRows = new Set<string>();

  initialValues: SiteGroupModel[] = [];

  hasChanges = false;

  valuesBeforeRemove: SiteGroupModel[] = [];

  constructor(
    public overlayService: OverlayService,
    public siteGroupQuery: SiteGroupsQuery,
    public siteGroupsService: SiteGroupsService,
    public siteCurveQuery: SiteCurveQuery,
    private siteCurveService: SiteCurveService,
    private eventService: EventService
  ) {
    this.siteGroupsService.get().pipe(untilDestroyed(this)).subscribe();
    this.siteCurveService.get().pipe(untilDestroyed(this)).subscribe();

    this.gridData$.pipe(untilDestroyed(this)).subscribe((gridData) => {
      this.initialValues = gridData.map((value) => {
        return { ...value, description: value?.description === '' ? null : value.description };
      });

      if (this.valuesBeforeRemove.length) {
        setTimeout(() => {
          this.gridAPI?.setRowData(this.valuesBeforeRemove);
          this.valuesBeforeRemove = [];
        }, 0);
      }

      if (!this.hasChanges) {
        this.gridAPI?.setRowData(gridData);
      }

      this.checkChanges();
    });

    this.eventService
      .select$(EventType.TRIAL_CHANGED)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.initialValues = [];
        this.valuesBeforeRemove = [];
        this.hasChanges = false;
      });
  }

  getCurrentValues() {
    const currentValues: SiteGroupModel[] = [];

    this.gridAPI?.forEachNode(({ data }) => {
      currentValues.push({
        ...data,
        description: data.description?.length === 0 ? null : data.description,
      });
    });

    return currentValues;
  }

  checkChanges() {
    const currentValues = this.getCurrentValues();

    if (currentValues.length) {
      this.hasChanges = !isEqual(this.initialValues, currentValues);
    }
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await result.afterClosed$.toPromise();
      return !!event.data;
    }
    return true;
  }

  onGridReady({ api, columnApi }: GridReadyEvent) {
    this.gridAPI$.next(api);
    this.gridAPI = api;
    this.gridColumnApi$.next(columnApi);
    this.gridColumnApi = columnApi;
    api.sizeColumnsToFit();
  }

  onAddSiteGroups() {
    const ss = this.gridAPI.applyTransaction({
      add: [{ randomID: Utils.uuid() }],
    });
    if (ss?.add.length && ss.add[0].rowIndex) {
      this.gridAPI.startEditingCell({
        rowIndex: ss.add[0].rowIndex,
        colKey: 'name',
      });
    }
    this.addedRowscounter += 1;
    this.newRowAdded = true;
    this.checkChanges();
  }

  cellValueChanged(event: CellValueChangedEvent) {
    if (event.data.id) {
      this.editedRows.add(event.data.id);
      this.checkChanges();
    }
  }

  showErrors() {
    const rowNodes: RowNode[] = [];
    let isThereAnyInvalidRow = false;

    const isGridHasData = this.gridAPI.getEditingCells();
    if (isGridHasData.length > 0) {
      this.gridAPI.stopEditing();
    }
    this.gridAPI.forEachNode((node) => {
      const { name } = node.data as SiteGroupModel;
      if (name) {
        isThereAnyInvalidRow = false;
        // eslint-disable-next-line no-param-reassign
        node.data.showError = false;
      } else {
        isThereAnyInvalidRow = true;
        // eslint-disable-next-line no-param-reassign
        node.data.showError = true;
      }
      rowNodes.push(node);
    });
    return isThereAnyInvalidRow;
  }

  onSaveAll = async () => {
    const isError = this.showErrors();
    if (!isError) {
      const updateData: SiteGroupModel[] = [];
      const insertData: SiteGroupModel[] = [];
      const reorderData: SiteGroupModel[] = [];

      if (
        this.reorderQueue.length < this.gridAPI.getDisplayedRowCount() &&
        !this.removedRows.length
      ) {
        this.reorderQueue.forEach(({ new_index, ids }) => {
          const pp = this.siteGroupQuery.getEntity(ids);
          if (pp) {
            const { id, name, description } = pp;
            updateData.push({
              id,
              name,
              description,
              rank_order: new_index,
            });
          }
        });
        this.gridAPI.forEachNode((node) => {
          const rowIndex = (node.rowIndex || 0) + 1;

          const { id, name, description } = node.data as RequireSome<
            SiteGroupModel,
            'id' | 'name' | 'description' | 'rank_order'
          >;

          if (!id && !this.editedRows.has(id)) {
            insertData.push({
              id,
              name,
              description,
              rank_order: rowIndex,
            });
          }

          if (id && this.editedRows.has(id)) {
            updateData.push({
              id,
              name,
              description,
              rank_order: rowIndex,
            });
          }

          if (id) {
            reorderData.push({
              id,
              name,
              description,
              rank_order: rowIndex,
            });
          }
        });
      } else {
        this.gridAPI.forEachNode((node) => {
          const rowIndex = (node.rowIndex || 0) + 1;
          const { id, name, description } = node.data as RequireSome<
            SiteGroupModel,
            'id' | 'name' | 'description' | 'rank_order'
          >;

          if (
            this.reorderQueue.length ||
            !id ||
            this.editedRows.has(id) ||
            this.removedRows.length
          ) {
            updateData.push({
              id,
              name,
              description,
              rank_order: rowIndex,
            });
          }

          if (id) {
            reorderData.push({
              id,
              name,
              description,
              rank_order: rowIndex,
            });
          }
        });
      }

      let hasSuccess = true;

      if (updateData.length) {
        for (const { id, name, description, rank_order } of reorderData) {
          this.siteGroupsService.update({ id, name, description, rank_order });
        }
        this.siteCurveService.get().pipe(take(1)).toPromise();
      }

      if (insertData.length) {
        insertData.forEach(async (pg) => {
          hasSuccess = (await this.siteGroupsService.add(pg)).success;
        });
      }

      if (this.removedRows.length) {
        for (const { id, name, description, rank_order } of this.removedRows) {
          this.siteGroupsService.remove({ id, name, description, rank_order });
        }
      }

      if (hasSuccess) {
        this.overlayService.success(`Successfully Saved`);

        this.reorderedRows.clear();
        this.editedRows.clear();
        this.removedRows = [];
        this.reorderQueue = [];
        this.newRowAdded = false;
        this.addedRowscounter = 0;
        this.hasChanges = false;
        this.initialValues = [];
        this.valuesBeforeRemove = [];
      } else {
        this.overlayService.error(`An error has occurred`);
      }
    } else {
      this.overlayService.error(`An error has occurred`);
    }
  };

  onRowDragEnd(event: RowDragEndEvent) {
    if (event.node.data?.id && event.node.rowIndex !== null) {
      this.reorderQueue.push({
        ids: event.node.data.id,
        new_index: event.node.rowIndex + 1,
      });
      this.reorderedRows.add(event.node.data.id);
      this.checkChanges();
    }
  }

  async dialogConfirmRemoveSiteGroup(rowNode: RowNode) {
    const site = this.siteCurveQuery.getAll();
    const curves = site.filter((el) => el.site_group_id === rowNode.data.id);

    const organizations_with_forecasts = [...curves].reduce((acc: Organization[], el) => {
      const organizations = el.organizations_with_forecasts;
      organizations.forEach((org: Organization) => {
        if (!acc.find((e) => e.id === org.id)) {
          acc.push(org);
        }
      });
      return acc;
    }, []);

    if (!curves.length) {
      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Site Group',
        message: `Are you sure you want to remove ${rowNode.data.name}?<br>`,
        okBtnText: 'Remove',
      });

      resp.afterClosed$.toPromise().then(({ data }) => {
        if (data?.result) {
          this.siteGroupsService.remove({
            id: rowNode.data.id,
            name: rowNode.data.name,
            description: rowNode.data.description,
            rank_order: rowNode.data.rank_order,
          } as SiteGroupModel);

          this.gridAPI.applyTransaction({ remove: [rowNode.data] });
          this.valuesBeforeRemove = this.getCurrentValues();
          this.checkChanges();
        }
      });
      return;
    }

    let list = `<br>Site Curves:<br><ul class="list-disc pl-4">${curves
      .map(
        ({ name }) =>
          `<li><a class="aux-link" href="/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES}">${name}</a></li>`
      )
      .join('')}</ul>`;
    if (organizations_with_forecasts.length) {
      list += `<br>Forecast Methodology:<br><ul class="list-disc pl-4">${organizations_with_forecasts
        .map(
          ({ name }) =>
            `<li><a class="aux-link" href="/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}">${name}</a></li>`
        )
        .join('')}</ul>`;
    }
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Site Group',
      message: `${rowNode.data.name} cannot be deleted because it is being used in other areas of the application.<br>Please first remove the dependencies below and then ${rowNode.data.name} can be successfully deleted.<br>${list}`,
      okBtnText: 'Remove',
      okBtnDisabled: true,
    });

    resp.afterClosed$.toPromise().then(({ data }) => {
      if (data?.result) {
        this.removedRows.push(rowNode.data);
        this.gridAPI.applyTransaction({ remove: [rowNode.data] });
        this.valuesBeforeRemove = this.getCurrentValues();
        this.checkChanges();
      }
    });
  }
}
