import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { DriverSiteSetting } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface DriverSiteState
  extends EntityState<RequireSome<Partial<DriverSiteSetting>, 'id'>> {}

export type DriverSiteSettingModel = getEntityType<DriverSiteState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'driver-site' })
export class DriverSiteStore extends EntityStore<DriverSiteState> {
  constructor() {
    super({});
  }
}
