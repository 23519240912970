import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
  CellValueChangedEvent,
  ColDef,
  Column,
  ColumnApi,
  ExcelExportParams,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ProcessCellForExportParams,
  ProcessGroupHeaderForExportParams,
  RowClassParams,
  RowNode,
} from 'ag-grid-community';
import { Utils } from '@services/utils';
import {
  CellClassParams,
  ColGroupDef,
  ValueFormatterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { OverlayService } from '@services/overlay.service';
import {
  Approval,
  ApprovalType,
  BudgetData,
  BudgetType,
  CategoryType,
  DriverType,
  EntityType,
  EventType,
  GqlService,
  InvoiceStatus,
  listBudgetVersionsQuery,
  listSitePatientTrackerMonthlyAmountsQuery,
  PermissionType,
  processEventMutation,
} from '@services/gql.service';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { first, get, groupBy, round, uniq } from 'lodash-es';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationStore } from '@models/organization/organization.store';
import { OrganizationService } from '@models/organization/organization.service';
import { AuthQuery } from '@models/auth/auth.query';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiService } from '@services/api.service';
import { memo } from 'helpful-decorators';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  from,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { BudgetQuery } from 'src/app/pages/budget-page/tabs/budget/state/budget.query';
import { BudgetService } from 'src/app/pages/budget-page/tabs/budget/state/budget.service';
import { BudgetStore } from 'src/app/pages/budget-page/tabs/budget/state/budget.store';
import { AuthService } from '@models/auth/auth.service';
import { formatDate, ViewportScroller } from '@angular/common';
import { TrialsQuery } from '@models/trials/trials.query';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { Router } from '@angular/router';
import { AgHtmlHeaderComponent } from '@components/ag-html-header/ag-html-header.component';
import {
  BudgetState,
  ExtendedBudgetData,
} from 'src/app/pages/budget-page/tabs/budget/state/budget.model';
import { MainStore } from 'src/app/layouts/main-layout/state/main.store';
import { ApprovalQuery } from 'src/app/pages/budget-page/tabs/budget/state/approval.query';
import { EventService } from '@services/event.service';
import { LoggedInUser } from '@models/auth/LoggedInUser';
import { ExcelButtonVariant } from '@components/export-excel-button/export-excel-button.component';
import { FormControl } from '@angular/forms';
import * as dayjs from 'dayjs';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { CellClickedEvent } from 'ag-grid-community/dist/lib/events';
import {
  AgCellWrapperComponent,
  getWrapperCellOptions,
} from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { AgQuarterCloseApprovalComponent } from './ag-quarter-close-approval.component';
import { QuarterCloseManualOverrideComponent } from './quarter-close-manual-override.component';
import {
  CategoryQuery,
  FullActivity,
  FullCategory,
} from '../../../forecast-accruals-page/tabs/forecast/category/category.query';
import { CategoryService } from '../../../forecast-accruals-page/tabs/forecast/category/category.service';
import { AddVendorEstimateUploadComponent } from './add-vendor-estimate-upload/add-vendor-estimate-upload.component';
import { VendorEstimateUploadComponent } from './vendor-estimate-upload/vendor-estimate-upload.component';
import { AgQuarterCloseAdjustmentComponent } from './ag-quarter-close-adjustment.component';
import { AgInMonthGroupHeaderComponent } from './ag-in-month-group-header.component';
import { QuarterCloseDialogComponent } from './quarter-close-dialog/quarter-close-dialog.component';
import { InvoiceAmounts } from '../../../vendor-payments-page/tabs/invoices/state/invoice.model';
import { InvoiceService } from '../../../vendor-payments-page/tabs/invoices/state/invoice.service';
import { ApprovalState } from '../../../budget-page/tabs/budget/state/approval.model';
import { AgQuarterCloseGroupHeaderComponent } from './ag-quarter-close-group-header.component';
import { WorkflowQuery } from './close-quarter-check-list/store';
import { getAuditText } from './workflow-panel';
import { MONTH_ADJUSTMENT_TOOLTIP, WORKFLOW_NAMES } from './close-quarter-check-list';
import { AgQuarterExpandableGroupHeaderComponent } from '../../../forecast-accruals-page/tabs/quarter-close/ag-quarter-expandable-group-header.component';
import { ROUTING_PATH } from '../../../../app-routing-path.const';
import { TableConstants } from '../../../../constants/table.constants';
import { QuarterCloseProcessingConfirmationComponent } from './quarter-close-processing-confirmation.component';
import { QuarterCloseProcessingConfirmationHeaderComponent } from './quarter-close-processing-confirmation-header.component';

// todo move this to utils
dayjs.extend(quarterOfYear);

interface MonthCloseTableRowData {
  forecast_obj: Record<string, number>;
  adjustment_obj: Record<string, number>;
  accrual_adjusted_obj: Record<string, number>;
  invoice_amounts: Record<string, number>;
  net_accruals: Record<string, number>;
  initial_wp_obj: Record<string, number>;
  eom_accruals: Record<string, number>;
}

interface QuarterDate {
  parsedDate: dayjs.Dayjs;
  date: string;
  iso: string;
}

interface InvestigatorEstimate {
  patient: number;
  other: number;
  overhead: number;
  total_all: number;
  show_adjustment?: boolean;
}

interface QuarterStartData {
  wp_ltd: number;
  invoice_ltd: number;
  starting_accruals: number;
}

interface QuarterEndData {
  wp_ltd: number;
  invoice_ltd: number;
  ending_accruals: number;
}

@UntilDestroy()
@Component({
  selector: 'aux-quarter-close',
  templateUrl: './quarter-close.component.html',
  styles: [
    `
      .month-name {
        font-size: 3.5rem;
        line-height: 3.5rem;
      }

      ::ng-deep .month-close-table .adjustment ng-component {
        width: 100%;
      }
      ::ng-deep .month-close-table .ag-header-row-column-group {
        /*background: var(--aux-gray-dark);*/
      }
      ::ng-deep .month-close-table .activities-header {
        border-top: 0 !important;
      }
      ::ng-deep .month-close-table .ag-header-group-cell {
        background: var(--aux-gray-light);
        border: 0.5px solid var(--aux-gray-dark);
        border-bottom: 0;
      }
      ::ng-deep .month-close-table .open {
        background: var(--aux-gray-dark);
        color: var(--aux-black);
      }
      ::ng-deep .month-close-table .closed,
      ::ng-deep .month-close-table .future {
        background: var(--aux-blue-dark);
        color: white;
      }
      ::ng-deep .month-close-table .ag-header-row,
      ::ng-deep .month-close-table .ag-header-cell {
        font-size: 1rem;
        overflow: unset;
      }

      ::ng-deep .month-close-table .ag-header-cell-text,
      ::ng-deep .month-close-table .ag-row {
        font-size: 1rem;
        color: var(--aux-black);
      }
      ::ng-deep .month-close-table .ag-row {
        background: white !important;
      }
      ::ng-deep .month-close-table .ag-header-cell {
        text-align: center;
      }
      ::ng-deep .month-close-table .ag-header-cell-label {
        justify-content: center;
      }
      ::ng-deep .month-close-table .ag-header-cell-text {
        white-space: normal !important;
      }
      ::ng-deep .month-close-table .net_accruals_header {
        padding: 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuarterCloseComponent implements OnInit, OnDestroy {
  upperShorNameMonths = Utils.SHORT_MONTH_NAMES.map((month) => month.toUpperCase());

  closeMonthProcessingMessage =
    'The period close process is currently running. It can take up to 5 minutes. You can navigate away from this page. You will receive an email notification when it is complete.';

  columnsForMonth: (keyof MonthCloseTableRowData)[] = [
    'initial_wp_obj',
    'adjustment_obj',
    'accrual_adjusted_obj',
    'invoice_amounts',
    'net_accruals',
    'eom_accruals',
  ];

  tags = ['All', 'Waiting Approval', 'Approved'];

  selectedTags = ['All', 'Waiting Approval', 'Approved'];

  investigatorDetailLink = `/${ROUTING_PATH.SITES.INDEX}/${ROUTING_PATH.SITES.INVESTIGATOR_DETAIL}`;

  exportButtonVariant = ExcelButtonVariant.OUTLINE_DOWNLOAD;

  DriverType = DriverType;

  BudgetType = BudgetType;

  userHasClosingPermission$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_APPROVE_IN_MONTH],
  });

  userHasAdjustPermission$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_MODIFY_OPEN_MONTH_ADJUSTMENTS],
  });

  components = {
    approvalRenderer: AgQuarterCloseApprovalComponent,
  };

  workflowName = WORKFLOW_NAMES.ADJUSTMENT;

  inMonthAdjustmentTooltip = MONTH_ADJUSTMENT_TOOLTIP;

  getWorkflowAuditText = getAuditText(
    'Locked for Close by',
    'Unlocked - You must lock this section before closing your month.'
  );

  isAdjustmentAvailable$ = this.workflowQuery.getInMonthAdjustmentAvailability();

  adjustmentWorkflow$ = this.workflowQuery.getWorkflowByName(this.workflowName);

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  invoicesLoading$ = new BehaviorSubject(false);

  budgetInfo$ = this.organizationQuery.selectActiveId().pipe(
    switchMap((org_id) =>
      this.budgetQuery.select('budget_info').pipe(
        map((x) => {
          return x.find((y) => y.vendor_id === org_id);
        })
      )
    )
  );

  invoices$ = new BehaviorSubject<
    Record<
      string,
      {
        id: string;
        invoice_date: string;
        organization_id: string;
        expense_amounts: InvoiceAmounts;
      }[]
    >
  >({});

  isQuarterCloseEnabled$ = this.workflowQuery.isQuarterCloseEnabled$;

  isWorkflowAvailable$ = this.workflowQuery.isWorkflowAvailable$;

  monthClosed$ = new BehaviorSubject(false);

  isCloseMonthAvailable$ = combineLatest([
    this.workflowQuery.getMonthCloseButtonAvailability(),
    this.monthClosed$.pipe(map((bool) => !bool)),
  ]).pipe(map((arr) => arr.every((bool) => bool)));

  closedMonthColumns = [
    'forecast_obj',
    'adjustment_obj',
    'accrual_adjusted_obj',
    'invoice_amounts',
    'net_accruals',
    'eom_accruals',
    'initial_wp_obj',
  ];

  bottomRowData$ = new BehaviorSubject<MonthCloseTableRowData>({
    adjustment_obj: {},
    accrual_adjusted_obj: {},
    forecast_obj: {},
    invoice_amounts: {},
    net_accruals: {},
    initial_wp_obj: {},
    eom_accruals: {},
  });

  quarter_start_data: QuarterStartData = {
    wp_ltd: 0,
    invoice_ltd: 0,
    starting_accruals: 0,
  };

  quarter_end_data: QuarterEndData = {
    wp_ltd: 0,
    invoice_ltd: 0,
    ending_accruals: 0,
  };

  analytics$ = new BehaviorSubject<{
    initial_wp: number;
    total_adjustments: number;
    invoices_received: number;
    starting_accrual_balance: number;
    ending_accruals: number;
  }>({
    initial_wp: 0,
    total_adjustments: 0,
    invoices_received: 0,
    starting_accrual_balance: 0,
    ending_accruals: 0,
  });

  defaultColumns: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Vendor',
      field: 'vendor_id',
      resizable: false,
      rowGroup: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return this.organizationQuery.getEntity(params.value)?.name || '';
      },
      hide: true,
      cellClass: ['vendor_id'],
    },
    {
      headerName: 'Cost Category',
      field: 'cost_category',
      hide: true,
      cellClass: ['cost_category'],
    },
    TableConstants.SPACER_COLUMN,
    {
      headerName: 'Quarter Start',
      headerClass: ['future', 'ag-header-align-center'],
      headerGroupComponent: AgQuarterExpandableGroupHeaderComponent,
      children: [
        {
          headerName: 'WP (LTD)',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          field: `wp_ltd_start`,
          valueFormatter: Utils.agCurrencyFormatterAccounting,
          aggFunc: Utils.agSumFunc,
          cellClass: ['cost', 'ag-cell-align-right'],
        },
        {
          headerName: 'Invoiced (LTD)',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          field: `invoice_ltd_start`,
          valueFormatter: Utils.agCurrencyFormatterAccounting,
          aggFunc: Utils.agSumFunc,
          cellClass: ['cost', 'ag-cell-align-right'],
        },
        {
          headerName: 'Starting Accruals / (Prepaid)',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          field: `starting_accruals`,
          valueFormatter: Utils.agCurrencyFormatterAccounting,
          aggFunc: Utils.agSumFunc,
          cellClass: ['cost', 'ag-cell-align-right'],
        },
      ],
    },
  ];

  gridOptions$ = new BehaviorSubject<GridOptions>({
    defaultColDef: {
      sortable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      minWidth: 70,
      cellRenderer: AgCellWrapperComponent,
    },
    getRowStyle: (param: RowClassParams): any => {
      if (param.node.rowPinned) {
        return { 'font-weight': 'bold' };
      }
      return undefined;
    },
    initialGroupOrderComparator: (params) => {
      const a = this.organizationQuery.getEntity(params.nodeA.key)?.name || 0;
      const b = this.organizationQuery.getEntity(params.nodeB.key)?.name || 0;
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    },
    suppressMenuHide: true,
    headerHeight: 40,
    suppressCellFocus: true,
    groupDefaultExpanded: -1,
    suppressAggFuncInHeader: true,
    suppressRowHoverHighlight: false,
    autoGroupColumnDef: {
      headerName: '',
      minWidth: 200,
      maxWidth: 200,
      field: 'cost_category',
      tooltipField: 'cost_category',
      headerClass: 'activities-header',
      headerComponent: AgQuarterCloseGroupHeaderComponent,
      ...getWrapperCellOptions(),
      pinned: 'left',
      headerComponentParams: {
        template: `Activities`,
      },
      cellRendererParams: {
        suppressCount: true,
      },
    },
    rowSelection: 'single',
    groupSelectsChildren: false,
    columnDefs: [],
    excelStyles: [...Utils.auxExcelStyle],
  });

  excelOptions = {
    sheetName: 'Quarter Close',
    fileName: 'auxilius-quarter-close.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.cost_category;
    },
    skipPinnedBottom: true,
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'vendor_id':
          return 280;
        case 'initial_monthly_accrual':
        case 'total_monthly_accrual':
        case 'invoice_amount':
        case 'net_accruals':
          return 170;
        default:
          return 150;
      }
    },
    processGroupHeaderCallback: (params: ProcessGroupHeaderForExportParams): string => {
      const colGroupDef = params.columnGroup.getColGroupDef() || ({} as any);
      const headerName = (colGroupDef.headerName || '') as string;
      if (headerName && headerName.match(/\d+-\d+/)) {
        return dayjs(headerName).format('MMMM YYYY');
      }
      return headerName;
    },
    processCellCallback: (params: ProcessCellForExportParams): string => {
      if (params.column.getColId() === 'vendor_id') {
        return this.organizationQuery.getEntity(params.value)?.name || '';
      }

      return params.value;
    },
  } as ExcelExportParams;

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  gridColumnApi$ = new ReplaySubject<ColumnApi>(1);

  gridColumnApi!: ColumnApi;

  isAdminUser = false;

  // isSelectedVendorHasManualForecast$ = this.budgetInfo$.pipe(map((x) => !!x?.manual_forecast));

  loadingSPT$ = new BehaviorSubject(false);

  loading$ = combineLatest([
    this.organizationQuery.selectLoading(),
    this.budgetQuery.selectLoading(),
    this.loadingSPT$,
    this.invoicesLoading$,
  ]).pipe(map((boolArr) => boolArr.some((bool) => bool)));

  selectedCategoryType$ = new BehaviorSubject<CategoryType>(CategoryType.CATEGORY_SERVICE);

  isSelectedCategoryTypeInvestigator$ = this.selectedCategoryType$.pipe(
    map((x) => x === CategoryType.CATEGORY_INVESTIGATOR)
  );

  selectedCategoryHeader$ = this.selectedCategoryType$.pipe(
    map((x) => {
      switch (x) {
        case CategoryType.CATEGORY_INVESTIGATOR:
          return 'Investigator';
        case CategoryType.CATEGORY_SERVICE:
          return 'Services';
        case CategoryType.CATEGORY_ROOT:
          return 'Services';
        case CategoryType.CATEGORY_PASSTHROUGH:
          return 'Pass-through';
        default:
          return 'Services';
      }
    })
  );

  refresh$ = new Subject();

  refreshNgOnInit$ = new Subject();

  listSitePatientTrackerMonthlyAmountsData$ = new BehaviorSubject<
    listSitePatientTrackerMonthlyAmountsQuery[]
  >([]);

  groupedInvestigatorEstimate$ = this.listSitePatientTrackerMonthlyAmountsData$.pipe(
    map((sitePatientTrackerMonthlyAmounts) => {
      const vendor_date_spts: Record<string, Record<string, InvestigatorEstimate>> = {};

      for (const sptMonthlyAmount of sitePatientTrackerMonthlyAmounts) {
        vendor_date_spts[sptMonthlyAmount.vendor_id] ||= {};
        vendor_date_spts[sptMonthlyAmount.vendor_id][
          sptMonthlyAmount.completion_month.toUpperCase()
        ] = {
          patient: round(sptMonthlyAmount.patient_amount || 0, 2),
          other: round(sptMonthlyAmount.other_amount || 0, 2),
          overhead: round(sptMonthlyAmount.overhead_amount || 0, 2),
          total_all: round(sptMonthlyAmount.total_amount || 0, 2),
        } as InvestigatorEstimate;
      }

      return vendor_date_spts;
    }),
    shareReplay(1)
  );

  selectedMonth$ = new BehaviorSubject('');

  investigatorEstimate$ = combineLatest([
    this.budgetInfo$,
    this.selectedMonth$,
    this.groupedInvestigatorEstimate$,
  ]).pipe(
    map(([budget_info, selectedMonth, groupedInvestigatorEstimate]) => {
      const defaultReturn = {
        patient: 0,
        other: 0,
        overhead: 0,
        total_all: 0,
        show_adjustment: true,
      } as InvestigatorEstimate;

      if (budget_info?.vendor_id && budget_info.current_month) {
        return (
          groupedInvestigatorEstimate[budget_info.vendor_id]?.[
            dayjs(selectedMonth).format('MMM-YYYY').toUpperCase()
          ] || defaultReturn
        );
      }

      return defaultReturn;
    }, shareReplay(1))
  );

  removeVendorEstimateLoading$ = new BehaviorSubject(false);

  vendorEstimates$ = new BehaviorSubject([] as listBudgetVersionsQuery[]);

  refreshDownload$ = new Subject();

  isDownloadVendorEstimateButtonDisabled$ = this.refreshDownload$.pipe(
    startWith(null),
    switchMap(() => combineLatest([this.budgetQuery.select(), this.mainQuery.select('trialKey')])),
    switchMap(([{ budget_info }, trialKey]) => {
      const first_vendor_current_month = budget_info[0]?.current_month;

      if (!first_vendor_current_month) {
        return of(0);
      }
      const formatted_current_month = formatDate(first_vendor_current_month, 'MMMM-y', 'en-US');

      return from(
        this.apiService.fileCounts(
          `trials/${trialKey}/vendors/vendor-estimate/${formatted_current_month}`
        )
      ).pipe(map((count) => !count));
    })
  );

  showGrid$ = new BehaviorSubject(true);

  quarters: string[] = [];

  quartersObj: Record<string, QuarterDate[]> = {};

  selectedQuarter = new FormControl('');

  currentQuarter = '';

  isCurrentQuarterSelected$ = new BehaviorSubject(true);

  currentMonth = '';

  doesSelectedMonthHasVendorEstimate$ = new BehaviorSubject(false);

  isSelectedMonthInPast = false;

  tabs: { label: string; show: Observable<boolean> }[] = [
    { label: 'Open Month Adjustments', show: of(true) },
    { label: 'Comments', show: of(false) },
    { label: 'History', show: of(false) },
  ];

  // when we change the trial we need to reset selectedQuarter
  // so this variable is holding the latest value for selected trial.
  lastSelectedQuarterValue: string | null = null;

  gridData$ = combineLatest([
    this.loading$,
    this.groupedInvestigatorEstimate$,
    this.budgetQuery.select(),
    this.approvalQuery.select(),
    this.invoices$,
  ]).pipe(
    switchMap(
      ([loading, groupedInvestigatorEstimate, budget_state, approval_state, groupedInvoices]) => {
        if (loading) {
          return EMPTY;
        }

        return from(this.authService.getLoggedInUser()).pipe(
          map((user) => {
            return {
              groupedInvestigatorEstimate,
              user,
              budget_state,
              approval_state,
              groupedInvoices,
            };
          })
        );
      }
    ),
    map(
      ({
        user,
        budget_state,
        approval_state,
        groupedInvoices,
        groupedInvestigatorEstimate,
      }: {
        user: LoggedInUser | null;
        budget_state: BudgetState;
        approval_state: ApprovalState;
        groupedInvoices: Record<
          string,
          {
            id: string;
            invoice_date: string;
            organization_id: string;
            expense_amounts: InvoiceAmounts;
          }[]
        >;
        groupedInvestigatorEstimate: Record<string, Record<string, InvestigatorEstimate>>;
      }) => {
        const { budget_data, budget_info, header_data } = budget_state;

        if (!budget_info?.[0]) {
          return [];
        }

        // const current_months = budget_state.budget_info.reduce((acc, info) => {
        //   if (info?.vendor_id) {
        //     acc[info.vendor_id] = (info.current_month || '').slice(0, 7);
        //   }
        //   return acc;
        // }, {} as Record<string, string>);

        const { current_month } = budget_info[0];

        let quarters: string[] = [];
        const quartersObj: Record<
          string,
          {
            parsedDate: dayjs.Dayjs;
            date: string;
            iso: string;
          }[]
        > = {};
        let currentQuarter = '';
        header_data
          .filter((x) => x.group_name === 'Timeline')[0]
          .date_headers.forEach((date) => {
            const parsedDate = this.parseBudgetHeaderDate(date);
            const q = parsedDate.quarter();
            const year = parsedDate.year();
            const str = `Q${q}-${year}`;
            quartersObj[str] ||= [];
            const iso = parsedDate.format('YYYY-MM-DD');

            if (current_month?.slice(0, 7) === iso.slice(0, 7)) {
              currentQuarter = str;
            }
            quartersObj[str].push({
              parsedDate,
              date,
              iso,
            });
            quarters.push(str);
          });
        quarters = uniq(quarters);

        const auxilius_start_date = this.mainQuery.getAuxiliusStartDate();

        const current_quarter_index = quarters.findIndex((q) => q === currentQuarter);
        this.quarters = quarters.slice(0, current_quarter_index + 1).reverse();

        if (auxilius_start_date) {
          this.quarters = this.quarters.filter((q) => {
            const monthIndex = quartersObj[q].length - 1;

            return (
              quartersObj[q][monthIndex].parsedDate.isSameOrAfter(auxilius_start_date, 'month') ||
              quartersObj[q][monthIndex].parsedDate.isSameOrAfter(current_month, 'month')
            );
          });
        }

        this.quartersObj = quartersObj;
        this.currentQuarter = currentQuarter;

        if (!this.lastSelectedQuarterValue) {
          this.selectedQuarter.setValue(currentQuarter, { emitEvent: false });
        }
        this.currentMonth = current_month || '';
        this.selectedMonth$.next(current_month || '');
        this.updateGridOptions();

        this.quarter_start_data = {
          wp_ltd: 0,
          invoice_ltd: 0,
          starting_accruals: 0,
        };

        this.quarter_end_data = {
          wp_ltd: 0,
          invoice_ltd: 0,
          ending_accruals: 0,
        };

        const data = budget_data.map((row: ExtendedBudgetData) => {
          const approvals: Approval[] = [];
          const usedApprovals: Approval[] = [];
          const arr: {
            name: string;
            permission: string;
            checked: boolean;
            disabled: boolean;
          }[] = [];
          const rules = this.launchDarklyService.flags$.getValue().approval_rule_in_month.rule;

          if (approval_state.approvals) {
            approval_state.approvals.forEach((approval) => {
              if (row.vendor_id === approval.vendor_id && row.cost_category === approval.category) {
                approvals.push(approval);
              }
            });
          }

          for (let i = 0; i < rules.length; i++) {
            let checked = false;
            let disabled = false;
            const { name, permission_type } = rules[i];

            for (let index = 0; index < approvals.length; index++) {
              if (approvals[index].permission === permission_type) {
                usedApprovals.push(...approvals.splice(index));
                checked = true;
                disabled = true;
                break;
              }
            }

            if (!checked && user && !user.IsSysAdmin()) {
              const isAuthorized = this.authService.isAuthorizedSync(user, {
                permissions: [permission_type],
              });

              if (isAuthorized) {
                disabled = usedApprovals.some(({ aux_user_id, permission }) => {
                  return aux_user_id === user.getSub() && permission === permission_type;
                });
              } else {
                disabled = true;
              }
            }

            arr.push({
              name,
              permission: permission_type,
              checked,
              disabled: checked || disabled,
            });
          }

          const obj = arr.reduce((acc, { name, permission, disabled, checked }) => {
            acc[`${name}::${permission}`] = checked;
            acc[`${name}::${permission}::disabled`] = disabled;

            return acc;
          }, {} as Record<string, boolean>);

          // const date = budget_info[0].current_month?.slice(0, 7);
          const invoices = groupedInvoices[row.vendor_id || ''] || [];

          let invoice_ltd_start = 0;

          let invoice_ltd_end = 0;

          const months = this.quartersObj[this.selectedQuarter.value];

          const quarter_start = months[0].iso;

          const invoice_amounts = invoices.reduce((i_acc, val) => {
            const period = dayjs(val.invoice_date).format('MMM-YYYY').toUpperCase();
            const is_old = dayjs(quarter_start).date(1).diff(val.invoice_date) > 0;
            if (row.cost_category === 'Services') {
              // eslint-disable-next-line no-param-reassign
              i_acc[period] = (i_acc[period] || 0) + val.expense_amounts.services_total.value;
              if (is_old) {
                invoice_ltd_start += val.expense_amounts.services_total.value;
              }
            }
            if (row.cost_category === 'Discount') {
              // eslint-disable-next-line no-param-reassign
              i_acc[period] = (i_acc[period] || 0) + val.expense_amounts.discount_total.value;
              if (is_old) {
                invoice_ltd_start += val.expense_amounts.discount_total.value;
              }
            }
            if (row.cost_category === 'Investigator') {
              // eslint-disable-next-line no-param-reassign
              i_acc[period] = (i_acc[period] || 0) + val.expense_amounts.investigator_total.value;
              if (is_old) {
                invoice_ltd_start += val.expense_amounts.investigator_total.value;
              }
            }
            if (row.cost_category === 'Pass-through') {
              // eslint-disable-next-line no-param-reassign
              i_acc[period] = (i_acc[period] || 0) + val.expense_amounts.pass_thru_total.value;
              if (is_old) {
                invoice_ltd_start += val.expense_amounts.pass_thru_total.value;
              }
            }
            return i_acc;
          }, {} as Record<string, number>);

          let forecast_obj = row.forecast_obj as Record<string, number>;
          const adjustment_obj = row.adjustment_obj as Record<string, number>;
          const { accrual_obj, accrual_adjusted_obj } = row;
          const initial_wp_obj: Record<string, number> = {};

          if (row.cost_category === 'Investigator') {
            const investigator_forecast_obj: Record<string, number> = {};
            if (row.vendor_id && groupedInvestigatorEstimate[row.vendor_id]) {
              for (const estimateMonth of Object.keys(groupedInvestigatorEstimate[row.vendor_id])) {
                const estimate = groupedInvestigatorEstimate[row.vendor_id][estimateMonth];
                investigator_forecast_obj[estimateMonth] = estimate.total_all;
              }
            }

            forecast_obj = { ...forecast_obj, ...investigator_forecast_obj };
          }
          let wp_ltd_end = row.wp_ltd;
          const starting_accruals = row.wp_ltd - invoice_ltd_start;

          this.quarter_start_data.wp_ltd += row.wp_ltd;
          this.quarter_start_data.invoice_ltd += invoice_ltd_start;
          this.quarter_start_data.starting_accruals += starting_accruals;

          months.forEach((month) => {
            initial_wp_obj[month.date] =
              accrual_obj && Object.prototype.hasOwnProperty.call(accrual_obj, month.date)
                ? accrual_obj[month.date]
                : forecast_obj[month.date];

            if (dayjs(month.iso).date(1).isAfter(dayjs(current_month).date(1))) {
              accrual_adjusted_obj[month.date] = initial_wp_obj[month.date];
              adjustment_obj[month.date] = 0;
            }

            wp_ltd_end += accrual_adjusted_obj[month.date] ? accrual_adjusted_obj[month.date] : 0;
          });

          const eom_accruals: Record<string, number> = {};
          const net_accruals = Object.keys(row.accrual_adjusted_obj).reduce((acc, val) => {
            return {
              ...acc,
              [val]: row.accrual_adjusted_obj[val] - (invoice_amounts[val] || 0),
            };
          }, {} as Record<string, number>);

          months.forEach((month, index) => {
            invoice_ltd_end += invoice_amounts[month.date] || 0;
            eom_accruals[month.date] =
              starting_accruals +
              months.slice(0, index + 1).reduce((acc, m) => {
                return acc + net_accruals[m.date] || 0;
              }, 0);
          });

          invoice_ltd_end += invoice_ltd_start;

          const ending_accruals = wp_ltd_end - invoice_ltd_end;

          this.quarter_end_data.ending_accruals += ending_accruals;
          this.quarter_end_data.wp_ltd += wp_ltd_end;
          this.quarter_end_data.invoice_ltd += invoice_ltd_end;

          return {
            ...row,
            ...obj,
            wp_ltd_start: row.wp_ltd,
            invoice_ltd_start,
            starting_accruals,
            forecast_obj,
            adjustment_obj,
            invoice_amounts,
            net_accruals,
            eom_accruals,
            initial_wp_obj,
            invoice_ltd_end,
            wp_ltd_end,
            ending_accruals,
          };
        });

        return this.getBudgetDataWithSummary((data as unknown) as MonthCloseTableRowData[]);
      }
    ),
    shareReplay(1)
  );

  width$ = new BehaviorSubject(0);

  // forecastData$ = combineLatest([this.investigatorEstimate$, this.selectedCategoryType$]).pipe(
  //   switchMap(([investigatorEstimate, type]) => {
  //     return this.categoryQuery.selectTopCategories$.pipe(
  //       map((categories) => {
  //         const filteredCategories = categories.filter((x) => x.category_type === type);
  //         if (type === CategoryType.CATEGORY_INVESTIGATOR) {
  //           return filteredCategories.map((category) => {
  //             return this.mapCategoryWithInvestigatorEstimates(investigatorEstimate, category);
  //           });
  //         }
  //         return filteredCategories;
  //       })
  //     );
  //   })
  // );

  // selectCategory$ = (id: string) =>
  //   combineLatest([this.investigatorEstimate$, this.categoryQuery.selectCategory(id)]).pipe(
  //     map(([investigatorEstimate, category]) => {
  //       if (
  //         category &&
  //         this.selectedCategoryType$.getValue() === CategoryType.CATEGORY_INVESTIGATOR
  //       ) {
  //         return this.mapCategoryWithInvestigatorEstimates(investigatorEstimate, category);
  //       }
  //       return category;
  //     })
  //   );

  forecastData$ = this.selectedCategoryType$.pipe(
    switchMap((type) => {
      return this.categoryQuery.selectTopCategories$.pipe(
        map((categories) => {
          return categories.filter((x) => x.category_type === type);
        })
      );
    })
  );

  invoice_statuses: Array<InvoiceStatus> = [
    InvoiceStatus.STATUS_APPROVED,
    InvoiceStatus.STATUS_IN_QUEUE,
    InvoiceStatus.STATUS_PENDING_REVIEW,
    InvoiceStatus.STATUS_PENDING_APPROVAL,
  ];

  activeTabIndex = 0;

  runningOnInit = false;

  private isColGroup(cell: ColDef | ColGroupDef): cell is ColGroupDef {
    return !!(cell as ColGroupDef)?.children;
  }

  private isColDef(col: ColDef | ColGroupDef): col is ColDef {
    return !!(col as ColDef).colId;
  }

  private getChildrenGroupIds(cols: (ColDef | ColGroupDef)[]): string[] {
    return cols.filter(this.isColDef).map((col) => col.colId) as string[];
  }

  private getDashboardIDs(): string[] {
    if (!this.gridAPI) {
      return [];
    }

    return (this.gridAPI?.getColumnDefs() || [])
      .filter((cel) => !!cel.headerName)
      .reduce<string[]>((accum, cell: ColDef | ColGroupDef) => {
        if (this.isColGroup(cell)) {
          const res = this.getChildrenGroupIds(cell.children);
          // eslint-disable-next-line no-param-reassign
          accum = accum.concat(res || []);
        }

        if (this.isColDef(cell)) {
          accum.push(cell.colId || '');
        }

        return accum;
      }, []);
  }

  isSelectedMonthEndOfQuarter = () => {
    return dayjs(this.selectedMonth$.value).month() % 3 === 2;
  };

  async exportExcelToS3() {
    const additionalExcelOption = this.getDynamicExcelParams();
    const defaultExcelOptions: ExcelExportParams = {
      author: 'Auxilius',
      fontSize: 11,
      columnWidth: 105,
    };

    const exportedData = this.gridAPI?.getDataAsExcel({
      ...defaultExcelOptions,
      columnKeys: this.getDashboardIDs(),
      ...this.excelOptions,
      ...additionalExcelOption,
    });

    const month = dayjs(this.selectedMonth$.value);
    const fileFormattedDate = this.isSelectedMonthEndOfQuarter()
      ? `q${month.quarter()}-${month.format('YYYY')}`
      : month.format('MMMM-YYYY').toLowerCase();
    const currentTimeStamp = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    const { trialKey } = this.mainQuery.getValue();
    const fileName = `${currentTimeStamp}_accrual-summary-${fileFormattedDate}.xlsx`;
    const key = `trials/${trialKey}/period-close/${fileName}`;

    const user = await this.authService.getLoggedInUser();

    if (exportedData) {
      await this.apiService.uploadFile(key, new File([exportedData], fileName), {
        uploadedBy: user?.getSub() || '',
      });
    }
  }

  constructor(
    public organizationQuery: OrganizationQuery,
    public categoryQuery: CategoryQuery,
    private overlayService: OverlayService,
    private categoryService: CategoryService,
    private organizationStore: OrganizationStore,
    private organizationService: OrganizationService,
    private viewportScroller: ViewportScroller,
    private launchDarklyService: LaunchDarklyService,
    private budgetService: BudgetService,
    private budgetQuery: BudgetQuery,
    private eventService: EventService,
    private approvalQuery: ApprovalQuery,
    private budgetStore: BudgetStore,
    private authService: AuthService,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private trialsQuery: TrialsQuery,
    private router: Router,
    public authQuery: AuthQuery,
    private apiService: ApiService,
    private mainStore: MainStore,
    private invoiceService: InvoiceService,
    private workflowQuery: WorkflowQuery
  ) {
    merge(
      this.eventService.select$(EventType.IN_MONTH_CLOSE_PROCESS_STARTED),
      this.eventService.select$(EventType.CLOSE_TRIAL_MONTH)
    ).subscribe(() => {
      if (!this.runningOnInit) {
        this.runningOnInit = true;
        setTimeout(() => {
          this.monthClosed$.next(false);
          this.refreshNgOnInit$.next();
          this.runningOnInit = false;
        }, 5000);
      }
    });

    this.mainQuery
      .select('trialKey')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.lastSelectedQuarterValue = null;
      });

    // this.gridData$.pipe(untilDestroyed(this)).subscribe((x) => console.log('gridData$', x));
  }

  // mapCategoryWithInvestigatorEstimates(
  //   investigatorEstimate: InvestigatorEstimate,
  //   category: FullCategory
  // ): FullCategory {
  //   const prim = category[BudgetType.BUDGET_PRIMARY];
  //   return {
  //     ...category,
  //     [BudgetType.BUDGET_PRIMARY]: {
  //       ...prim,
  //       adjustment:
  //         (prim?.adjustment || 0) + (prim?.total_forecast || 0) - investigatorEstimate.total_all,
  //     },
  //     activities: category.activities.map((activity) => {
  //       const a_prim = activity[BudgetType.BUDGET_PRIMARY];

  //       let correctValue = 0;
  //       switch (activity.name) {
  //         case 'Other':
  //           correctValue = investigatorEstimate.other;
  //           break;
  //         case 'Overhead':
  //           correctValue = investigatorEstimate.overhead;
  //           break;
  //         case 'Patient Visits':
  //           correctValue = investigatorEstimate.patient;
  //           break;
  //         default:
  //           break;
  //       }

  //       return {
  //         ...activity,
  //         [BudgetType.BUDGET_PRIMARY]: {
  //           ...a_prim,
  //           adjustment: Number(
  //             ((a_prim?.adjustment || 0) + (a_prim?.total_forecast || 0) - correctValue).toFixed(2)
  //           ),
  //         },
  //       };
  //     }),
  //   } as FullCategory;
  // }

  private parseBudgetHeaderDate(date: string): dayjs.Dayjs {
    const [month, year] = date.split('-');

    const monthNumber = this.upperShorNameMonths.indexOf(month) + 1;
    const strMonth = `${monthNumber}`.padStart(2, '0');

    return dayjs(`03/${strMonth}/${year}`, 'DD/MM/YYYY');
  }

  agCurrencyFormatter(val: ValueFormatterParams) {
    if (val.value) {
      if (!Number.isNaN(val.value)) {
        return Utils.currencyFormatter(val.value);
      }
    }

    return Utils.zeroHyphen;
  }

  initializeApproveColumns() {
    const approveColumns: ColDef[] = [];
    this.launchDarklyService.flags$
      .getValue()
      .approval_rule_in_month.rule.forEach(({ name, permission_type }) => {
        approveColumns.push({
          headerName: name,
          maxWidth: 90,
          resizable: false,
          field: `${name}::${permission_type}`,
          cellRendererSelector: (params: any) => {
            if (params.data) {
              if (params.data.cost_category === 'Total') {
                return { component: '' };
              }
            }
            return { component: 'approvalRenderer', params };
          },
        });
      });

    const options = this.gridOptions$.getValue();
    options.columnDefs = [...this.defaultColumns, ...approveColumns];
    this.gridOptions$.next(options);

    this.refreshGridWidth();
  }

  onGridReady({ api, columnApi }: GridReadyEvent) {
    this.gridAPI$.next(api);
    this.gridAPI = api;
    this.gridColumnApi$.next(columnApi);
    this.gridColumnApi = columnApi;

    const allColumnIds: string[] = [];
    (columnApi.getAllColumns() || []).forEach((column: Column) => {
      allColumnIds.push(column.getColId());
    });

    columnApi.autoSizeColumns(allColumnIds, false);
  }

  identify(index: number, item: FullCategory) {
    return item.id;
  }

  getCatType(cat: any) {
    return cat as FullCategory;
  }

  @memo()
  isCatOpen(id: string) {
    return this.categoryQuery.ui.selectEntity(id, 'isOpen') as Observable<boolean>;
  }

  onOrganizationSelected(orgId: string) {
    this.organizationStore.setActive(orgId);
  }

  @memo()
  isCatLoading(id: string) {
    return this.categoryQuery.ui.selectEntity(id, 'isLoading') as Observable<boolean>;
  }

  toggleCat(id: string) {
    this.categoryService.toggleInMonthCategory(
      id,
      dayjs(this.selectedMonth$.value).format('YYYY-MM-DD')
    );
  }

  onFirstDataRendered({ api }: FirstDataRenderedEvent) {
    const rowNodes: RowNode[] = [];
    api.forEachNode((rowNode) => rowNodes.push(rowNode));
    first(rowNodes)?.setSelected(true, true);

    // this.refreshGridWidth();
  }

  refreshGridWidth() {
    const div = document.querySelector('.ag-header-container') as HTMLDivElement;
    if (div) {
      const paddingOffset = 2;
      this.width$.next(div.getBoundingClientRect().width + paddingOffset);
    } else {
      this.width$.next(0);
    }
  }

  onSelectionChanged({ node: selectedRow, column }: CellClickedEvent) {
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor('inMonthAdjustmentTable');
    }, 0);

    console.log(column);
    const period = column.getColId().split('.')[1];
    const parsedDate = dayjs(`03/${period.replace('-', '/')}`);
    this.isSelectedMonthInPast = parsedDate.date(1) < dayjs(this.currentMonth).date(1);
    this.selectedMonth$.next(parsedDate.date(1).format('YYYY-MM-DD'));
    if (selectedRow.group) {
      this.organizationStore.setActive(selectedRow.key);
      this.selectedCategoryType$.next(CategoryType.CATEGORY_SERVICE);
    } else {
      // eslint-disable-next-line prefer-destructuring
      const data: BudgetData = selectedRow.data;
      this.organizationStore.setActive(data.vendor_id || '');
      this.selectedCategoryType$.next(
        `CATEGORY_${data.cost_category
          ?.toUpperCase()
          .replace('-', '')
          .replace('SERVICES', 'SERVICE')}` as CategoryType
      );
    }
  }

  async onOpenDialog(category: FullCategory, sub_activity: FullActivity, preview_mode = false) {
    const budget_info = await this.budgetInfo$.pipe(take(1)).toPromise();
    const resp = await this.overlayService
      .open({
        content: QuarterCloseManualOverrideComponent,
        data: { sub_activity, budget_info, preview_mode },
      })
      .afterClosed$.toPromise();

    if (resp.data) {
      this.refresh$.next();
    }
  }

  onAddVendorEstimateUploadClick = () => {
    const overlay = this.overlayService.open({ content: AddVendorEstimateUploadComponent });
    overlay.afterClosed$.subscribe(() => this.refreshDownload$.next());
  };

  onVendorEstimateUploadClick() {
    this.overlayService.open({ content: VendorEstimateUploadComponent });
  }

  getBudgetDataWithSummary(data: MonthCloseTableRowData[]) {
    const months = this.quartersObj[this.selectedQuarter.value];

    return data.map((row) => {
      return this.columnsForMonth.reduce<MonthCloseTableRowData>((accum, rowKey) => {
        return {
          ...accum,
          [rowKey]: {
            ...row[rowKey],
            total: months.reduce((sum, { date }) => sum + accum[rowKey][date] || sum, 0),
          },
        };
      }, row);
    });
  }

  private getMonthColumn(
    headerName: string,
    isSame: boolean,
    isFuture: boolean,
    rowKey: string,
    month: QuarterDate | null,
    hideTotal?: boolean,
    customAccrualBalanceName?: string,
    customTemplateAccrualBalance?: string,
    readOnlyAdjustment?: boolean
  ) {
    const adjustmentColumnProps = !readOnlyAdjustment
      ? {
          cellRenderer: AgQuarterCloseAdjustmentComponent,
          onCellClicked: (event: CellClickedEvent) => {
            this.onSelectionChanged(event);
          },
          cellRendererParams: {
            current_month: isSame,
            adjustmentWorkflow$: this.adjustmentWorkflow$,
            isWorkflowAvailable$: this.isWorkflowAvailable$,
            userHasAdjustPermission$: this.userHasAdjustPermission$,
          },
        }
      : {};

    return {
      headerName,
      headerClass: isSame ? 'open' : 'closed',
      headerGroupComponent: AgInMonthGroupHeaderComponent,
      headerGroupComponentParams: {
        processing$: this.iCloseMonthsProcessing$,
        isMonthCloseAvailable$: this.isCloseMonthAvailable$,
        isQuarterCloseEnabled$: this.isQuarterCloseEnabled$,
        isWorkflowAvailable$: this.isWorkflowAvailable$,
        userHasClosingPermission$: this.userHasClosingPermission$,
        bottomRowData$: this.bottomRowData$,
        budgetInfo$: this.budgetInfo$,
        // eslint-disable-next-line no-nested-ternary
        mode: isFuture ? 'future' : isSame ? 'open' : 'closed',
        month,
        hideTotal,
        headerName,
        monthButtonClick: () => {
          if (isSame) {
            this.onInMonthClose();
          }
        },
      },
      minWidth: 200,
      children: [
        {
          headerName: 'Initial WP Estimate',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          field: `initial_wp_obj.${rowKey}`,
          valueFormatter: Utils.agCurrencyFormatterAccounting,
          aggFunc: Utils.agSumFunc,
          cellClass: ['cost', 'ag-cell-align-right'],
          columnGroupShow: isSame ? undefined : 'open',
        },
        {
          ...adjustmentColumnProps,
          headerName: 'Adjustment',
          field: `adjustment_obj.${rowKey}`,
          valueFormatter: Utils.agCurrencyFormatterAccounting,
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          aggFunc: Utils.agSumFunc,
          columnGroupShow: isSame ? undefined : 'open',
          cellClass: (params: CellClassParams) => {
            const classes: string[] = ['cost'];
            const val = Number((params.value || 0).toFixed(2));
            if (val > 0) {
              classes.push('text-aux-error');
            } else if (val < 0) {
              classes.push('text-aux-green');
            }

            return classes;
          },
        },
        {
          headerName: 'WP Estimate',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          columnGroupShow: isSame ? undefined : 'open',
          field: `accrual_adjusted_obj.${rowKey}`,
          valueFormatter: Utils.agCurrencyFormatterAccounting,
          aggFunc: Utils.agSumFunc,
          cellClass: ['cost', 'ag-cell-align-right'],
        },
        {
          headerName: 'Invoices Received',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          columnGroupShow: isSame ? undefined : 'open',
          field: `invoice_amounts.${rowKey}`,
          valueFormatter: Utils.agCurrencyFormatterAccounting,
          aggFunc: Utils.agSumFunc,
          cellClass: ['invoice_amount', 'cost'],
        },
        {
          headerComponent: AgHtmlHeaderComponent,
          headerComponentParams: {
            template: `Net Change in <br/> Accruals / (Prepaid)`,
          },
          headerName: 'Net Change in Accruals / (Prepaid)',
          maxWidth: 210,
          minWidth: 210,
          resizable: false,
          field: `net_accruals.${rowKey}`,
          valueFormatter: Utils.agCurrencyFormatterAccounting,
          aggFunc: Utils.agSumFunc,
          cellClass: ['cost', 'ag-cell-align-right'],
          headerClass: 'net_accruals_header',
        },
        {
          headerComponent: AgHtmlHeaderComponent,
          headerComponentParams: {
            template: customTemplateAccrualBalance || `End of Month <br/> Accrual Balance`,
          },
          headerName: customAccrualBalanceName || 'End of Month Accrual Balance',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          columnGroupShow: isSame ? undefined : 'open',
          field: `eom_accruals.${rowKey}`,
          valueFormatter: Utils.agCurrencyFormatterAccounting,
          aggFunc: Utils.agSumFunc,
          cellClass: ['cost', 'ag-cell-align-right'],
        },
      ],
    };
  }

  ngOnInit(): void {
    this.refreshNgOnInit$
      .pipe(
        startWith(null),
        untilDestroyed(this),
        switchMap(() => {
          this.organizationStore.setActive(null);

          return merge(
            this.authQuery.adminUser$.pipe(
              tap((event) => {
                this.isAdminUser = event;
              })
            ),
            this.mainQuery.select('trialKey').pipe(
              switchMap(() => {
                this.loadingSPT$.next(true);
                return this.gqlService.listSitePatientTrackerMonthlyAmounts$();
              }),
              tap(({ data, success, errors }) => {
                if (success && data) {
                  this.listSitePatientTrackerMonthlyAmountsData$.next(data);
                } else {
                  this.listSitePatientTrackerMonthlyAmountsData$.next([]);
                  this.overlayService.error(errors);
                }
                this.loadingSPT$.next(false);
              })
            ),
            this.organizationService.get().pipe(
              switchMap(() => {
                this.invoicesLoading$.next(true);

                return combineLatest([
                  this.gqlService
                    .listInvoicesForReconciliation$({ invoice_statuses: this.invoice_statuses })
                    .pipe(
                      map(({ success, data, errors }) => {
                        this.invoices$.next(
                          groupBy(
                            (data || [])
                              .filter((invoice) => invoice.invoice_date)
                              .map(({ id, invoice_date, organization, expense_amounts }) => {
                                return {
                                  id,
                                  invoice_date: invoice_date || '',
                                  organization_id: organization.id,
                                  expense_amounts: this.invoiceService.mapExpenseAmountToObject(
                                    expense_amounts
                                  ),
                                };
                              }),
                            'organization_id'
                          ) as Record<
                            string,
                            {
                              id: string;
                              invoice_date: string;
                              organization_id: string;
                              expense_amounts: InvoiceAmounts;
                            }[]
                          >
                        );
                        if (!(success && data)) {
                          this.overlayService.error(errors);
                        }

                        this.invoicesLoading$.next(false);
                      })
                    ),
                ]);
              })
            ),
            this.refresh$.pipe(
              startWith(null),
              switchMap(() => {
                return combineLatest([
                  this.selectedMonth$.pipe(
                    distinctUntilChanged(),
                    switchMap((month) => {
                      return this.categoryService.getCategoriesForInMonth(
                        month ? dayjs(month).format('YYYY-MM-DD').toUpperCase() : null
                      );
                    }),
                    tap((resp) => {
                      this.doesSelectedMonthHasVendorEstimate$.next(false);
                      if (resp) {
                        const [, vendorEstimate] = resp;
                        this.doesSelectedMonthHasVendorEstimate$.next(
                          vendorEstimate.success && !!vendorEstimate.data
                        );
                      }
                    })
                  ),
                  this.selectedQuarter.valueChanges.pipe(
                    distinctUntilChanged(),
                    startWith(null),
                    switchMap((selectedQuarter) => {
                      this.lastSelectedQuarterValue = selectedQuarter;

                      if (selectedQuarter) {
                        this.isCurrentQuarterSelected$.next(
                          selectedQuarter === this.currentQuarter
                        );
                      }

                      return this.budgetService.getInMonthBudgetData(
                        selectedQuarter ? this.quartersObj[selectedQuarter][0].iso : null
                      );
                    })
                  ),
                  this.budgetService.getMonthCloseApprovals(),
                ]);
              })
            ),
            this.organizationQuery.selectActiveId().pipe(
              switchMap((org_id) => {
                if (org_id) {
                  return from(
                    this.gqlService.listBudgetVersions$([BudgetType.BUDGET_VENDOR_ESTIMATE], org_id)
                  );
                }
                return of({ data: [] });
              }),
              tap(({ data }) => {
                this.vendorEstimates$.next(data || []);
              })
            ),

            this.gridColumnApi$.pipe(
              switchMap(() => this.gridData$),
              tap((rows) => {
                if (this.gridOptions$.value.api) {
                  this.generatePinnedBottomData(rows as any);
                }
              })
            )
          );
        })
      )
      .subscribe();
  }

  onTabChange(i: number) {
    this.activeTabIndex = i;
  }

  updateGridOptions() {
    this.showGrid$.next(false);

    const columns = [...this.defaultColumns];

    const addEmptyColumn = () => {
      columns.push(TableConstants.SPACER_COLUMN);
    };
    this.quartersObj[this.selectedQuarter.value].forEach((month) => {
      const isAfter = dayjs(this.currentMonth).date(1).isAfter(dayjs(month.iso).date(1));
      const isSame = dayjs(this.currentMonth).date(1).isSame(dayjs(month.iso).date(1));
      const isFuture = !(isSame || isAfter);
      addEmptyColumn();

      const slicedDate = month.iso.slice(0, 7);

      const monthColumn = this.getMonthColumn(slicedDate, isSame, isFuture, month.date, month);

      columns.push(monthColumn);
    });
    addEmptyColumn();

    columns.push(
      this.getMonthColumn(
        `${this.selectedQuarter.value.replace('-', ' ')} Summary`,
        false,
        true,
        'total',
        null,
        true,
        'End of Quarter Accrual Balance',
        `End of Quarter <br/> Accrual Balance`,
        true
      )
    );

    columns.push(
      ...[
        TableConstants.SPACER_COLUMN,
        {
          headerName: 'Quarter End',
          headerClass: ['future', 'ag-header-align-center'],
          headerGroupComponent: AgQuarterExpandableGroupHeaderComponent,
          children: [
            {
              headerName: 'WP (LTD)',
              maxWidth: 150,
              minWidth: 150,
              resizable: false,
              field: `wp_ltd_end`,
              valueFormatter: Utils.agCurrencyFormatterAccounting,
              aggFunc: Utils.agSumFunc,
              cellClass: ['cost', 'ag-cell-align-right'],
            },
            {
              headerName: 'Invoiced (LTD)',
              maxWidth: 150,
              minWidth: 150,
              resizable: false,
              field: `invoice_ltd_end`,
              valueFormatter: Utils.agCurrencyFormatterAccounting,
              aggFunc: Utils.agSumFunc,
              cellClass: ['cost', 'ag-cell-align-right'],
            },
            {
              headerName: 'Ending Accruals  (Prepaid)',
              maxWidth: 150,
              minWidth: 150,
              resizable: false,
              field: `ending_accruals`,
              valueFormatter: Utils.agCurrencyFormatterAccounting,
              aggFunc: Utils.agSumFunc,
              cellClass: ['cost', 'ag-cell-align-right'],
            },
          ],
        },
      ]
    );

    this.gridOptions$.next({ ...this.gridOptions$.getValue(), columnDefs: columns });
    setTimeout(() => {
      this.showGrid$.next(true);
    }, 0);
  }

  ngOnDestroy() {
    this.mainStore.update({ fullPage: false });
  }

  removeBudgetVendorEstimate(vendor_id: string, vendor_name: string) {
    if (!vendor_id) {
      this.overlayService.error('No vendor found');
      return;
    }

    const formatted_selected_month = formatDate(this.selectedMonth$.getValue(), 'MMMM-y', 'en-US');
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Vendor Estimate',
      message: `Are you sure you want to remove the ${formatted_selected_month} vendor estimate for ${vendor_name}?`,
      okBtnText: 'Remove',
    });

    resp.afterClosed$.subscribe(async (value) => {
      if (!this.removeVendorEstimateLoading$.getValue() && value.data?.result) {
        this.removeVendorEstimateLoading$.next(true);
        let budget_version_id = '';
        const vendorEstimate = this.vendorEstimates$
          .getValue()
          .find((estimate) => estimate.target_month === this.selectedMonth$.getValue());
        if (vendorEstimate) {
          budget_version_id = vendorEstimate.budget_version_id;
        } else {
          this.removeVendorEstimateLoading$.next(false);
          this.overlayService.error('No vendor forecast found');
          return;
        }

        const success = await this.budgetService.removeVendorEstimate({
          vendor_id,
          id: budget_version_id,
          target_month: this.selectedMonth$.getValue(),
        });
        console.log(`success: ${success}`);
        if (resp && vendor_name) {
          this.overlayService.success(`Successfully removed vendor estimate`);
        }
        this.removeVendorEstimateLoading$.next(false);
      }
    });
  }

  async onCellValueChanged(event: CellValueChangedEvent) {
    const loading = this.overlayService.loading();
    const user = await this.authService.getLoggedInUser();
    const id = event.column.getColId();
    const [, permission_type] = id.split('::');
    const [node] = this.gridAPI.getSelectedNodes();

    const { success, errors } = await this.gqlService
      .approveRule$({
        approved: true,
        comments: '',
        permission: permission_type,
        approval_type: ApprovalType.APPROVAL_IN_MONTH,
        entity_id: node.data.vendor_id,
        entity_type: EntityType.TRIALEVENT,
        activity_details: JSON.stringify({
          category: node.data.activity_name,
        }),
      })
      .toPromise();

    if (success) {
      if (user?.IsSysAdmin()) {
        node.setData({ ...node.data, [`${id}::disabled`]: true });
      } else {
        const columnsToChange = ((this.gridColumnApi.getAllColumns() ||
          []) as Column[]).filter((x) => x.getColId().includes(permission_type));

        columnsToChange.forEach((col) => {
          node.setData({ ...node.data, [`${col.getColId()}::disabled`]: true });
        });
      }

      this.overlayService.success('Approved!');
    } else {
      node.setData({ ...node.data, [id]: false });
      this.overlayService.error(errors);
    }

    loading.close();
  }

  async onInMonthClose() {
    const info = this.budgetQuery.getValue().budget_info;

    const first_vendor_current_month = info[0]?.current_month;
    if (!first_vendor_current_month) {
      return;
    }
    const formatted_current_month = formatDate(first_vendor_current_month, 'MMMM y', 'en-US');

    const resp = await this.overlayService
      .open<{ note: string; sendToNetsuite?: boolean } | undefined, any>({
        content: QuarterCloseDialogComponent,
        data: {
          header: `Close ${formatted_current_month}`,
          useDesignSystemStyling: true,
          displayX: true,
          formatted_current_month,
        },
      })
      .afterClosed$.toPromise();

    if (!resp.data?.note) {
      return;
    }

    await this.overlayService
      .open({
        content: QuarterCloseProcessingConfirmationComponent,
        data: {
          customHeader: QuarterCloseProcessingConfirmationHeaderComponent,
          useDesignSystemStyling: true,
        },
      })
      .afterClosed$.toPromise();

    const ref = this.overlayService.loading();

    const promises: Promise<{
      success: boolean;
      data: processEventMutation | null;
      errors: string[];
    }>[] = [];

    await this.exportExcelToS3();

    const { current_month } = info[0];

    this.mainStore.setProcessingLoadingState(EventType.CLOSE_TRIAL_MONTH, true);

    promises.push(
      this.gqlService
        .processEvent$({
          entity_id: '',
          entity_type: EntityType.TRIAL,
          type: EventType.CLOSE_TRIAL_MONTH,
          payload: JSON.stringify({
            event_dates: [current_month],
            sendToNetsuite: resp.data?.sendToNetsuite,
          }),
        })
        .toPromise()
    );

    const responses = await Promise.all(promises);

    const result = responses.every((x) => x);

    ref.close();

    if (result) {
      this.monthClosed$.next(true);
      // this.overlayService.success('Month Successfully Closed');
      console.log('Month Successfully Closed');
    } else {
      const allErrors: string[] = [];
      responses.forEach(({ success, errors }) => {
        if (!success) {
          allErrors.push(...errors);
        }
      });
      this.overlayService.error(allErrors);
    }
  }

  getDynamicExcelParams = (): ExcelExportParams => {
    const trial = this.trialsQuery.getEntity(this.mainQuery.getValue().trialKey);
    if (!trial) {
      return {};
    }

    const totals = this.gridAPI.getPinnedBottomRow(0)?.data;

    const appendContent: any = [
      [
        {
          data: { value: `Total`, type: 'String' },
          mergeAcross: 1,
          styleId: 'total_row_header',
        },
      ],
    ];

    const quarter_start_columns: (keyof QuarterStartData)[] = [
      'wp_ltd',
      'invoice_ltd',
      'starting_accruals',
    ];
    for (const column of quarter_start_columns) {
      appendContent[0].push({
        data: { value: this.quarter_start_data[column], type: 'Number' },
        styleId: 'total_row',
      });
    }

    const quarter_end_columns: (keyof QuarterEndData)[] = [
      'wp_ltd',
      'invoice_ltd',
      'ending_accruals',
    ];

    const pushTotalValue = (field: string) => {
      appendContent[0].push({
        data: { value: get(totals, field) || 0, type: 'Number' },
        styleId: 'total_row',
      });
    };

    this.quartersObj[this.selectedQuarter.value].forEach((month) => {
      this.columnsForMonth.forEach((field) => {
        pushTotalValue(`${field}.${month.date}`);
      });
    });

    this.columnsForMonth.forEach((field) => {
      pushTotalValue(`${field}.total`);
    });

    for (const column of quarter_end_columns) {
      appendContent[0].push({
        data: { value: this.quarter_end_data[column], type: 'Number' },
        styleId: 'total_row',
      });
    }

    const selectQ = this.selectedQuarter.value || '';
    const exportOptions = {
      ...this.excelOptions,
      fileName: `${trial.short_name}_${selectQ}.xlsx`,
      columnKeys: this.getDashboardIDs(),
      appendContent,
    } as ExcelExportParams;
    this.gridAPI.setPinnedBottomRowData([]);
    this.gridColumnApi$.next(this.gridColumnApi);
    return exportOptions;
  };

  async onDownloadVendorEstimates() {
    const { trialKey } = this.mainQuery.getValue();
    const info = this.budgetQuery.getValue().budget_info;
    const first_vendor_current_month = info[0]?.current_month;
    const formatted_current_month = formatDate(first_vendor_current_month || '', 'MMMM-y', 'en-US');

    const { success, data } = await this.apiService.getS3ZipFile(
      `trials/${trialKey}/vendors/vendor-estimate/${formatted_current_month}`
    );
    if (success && data) {
      // [insert trial name]_[insert vendor name]_Change Order [insert Change Order #]_[insert date created YYYY.MM.DD]
      const fileName = `vendor-estimate`;
      await this.apiService.downloadZipOrFile(data, fileName);
    }
  }

  generatePinnedBottomData(
    rows: {
      forecast_obj: Record<string, number>;
      adjustment_obj: Record<string, number>;
      accrual_adjusted_obj: Record<string, number>;
      invoice_amounts: Record<string, number>;
      net_accruals: Record<string, number>;
      ending_accruals: number;
      starting_accruals: number;
      initial_wp_obj: Record<string, number>;
    }[]
  ) {
    const data = rows.reduce(
      (acc, row: Record<string, any>) => {
        this.closedMonthColumns.forEach((field) => {
          for (const period of Object.keys(row[field])) {
            acc[field][period] = (acc[field][period] || 0) + (row[field][period] || 0);
          }
        });
        return acc;
      },
      {
        forecast_obj: {},
        adjustment_obj: {},
        accrual_adjusted_obj: {},
        invoice_amounts: {},
        net_accruals: {},
        eom_accruals: {},
        initial_wp_obj: {},
      } as {
        forecast_obj: Record<string, number>;
        adjustment_obj: Record<string, number>;
        accrual_adjusted_obj: Record<string, number>;
        invoice_amounts: Record<string, number>;
        net_accruals: Record<string, number>;
        eom_accruals: Record<string, number>;
        initial_wp_obj: Record<string, number>;
        [k: string]: Record<string, number>;
      }
    );

    const { ending_accruals, starting_accrual_balance } = rows.reduce(
      (acc, row) => {
        acc.ending_accruals += row.ending_accruals;
        acc.starting_accrual_balance += row.starting_accruals;
        return acc;
      },
      {
        ending_accruals: 0,
        starting_accrual_balance: 0,
      }
    );

    const analytics = {
      initial_wp: 0,
      total_adjustments: 0,
      invoices_received: 0,
      ending_accruals,
      starting_accrual_balance,
    };

    (this.quartersObj[this.selectedQuarter.value] || []).forEach((month) => {
      analytics.initial_wp += data.initial_wp_obj[month.date] || 0;
      analytics.total_adjustments += data.adjustment_obj[month.date] || 0;
    });

    analytics.invoices_received = this.quarter_end_data.invoice_ltd;

    this.analytics$.next(analytics);

    const obj = {
      ...data,
      wp_ltd_start: this.quarter_start_data.wp_ltd,
      invoice_ltd_start: this.quarter_start_data.invoice_ltd,
      starting_accruals: this.quarter_start_data.starting_accruals,
      ending_accruals: this.quarter_end_data.ending_accruals,
      wp_ltd_end: this.quarter_end_data.wp_ltd,
      invoice_ltd_end: this.quarter_end_data.invoice_ltd,
      cost_category: 'Total',
    };
    this.bottomRowData$.next(obj);
    this.gridAPI.setPinnedBottomRowData([obj]);
  }

  refreshRows(): void {
    this.gridAPI?.redrawRows();
  }
}
