<div class="w-screen max-w-2xl max-h-158 overflow-auto">
  <div class="mb-8 text-xl font-bold">Add Trial Site</div>

  <form class="grid grid-cols-2 gap-5" (ngSubmit)="onAddTrialSite()" [formGroup]="fg">
    <div class="flex flex-col justify-between space-y-2">
      <aux-input formControlName="name" label="Site Name" validators="required"></aux-input>

      <aux-input formControlName="site_no" label="Site No" validators="required"></aux-input>
    </div>
    <div class="flex flex-col">
      <div class="mb-1 text-xs">Contracts & Payment Terms</div>
      <aux-file-manager class="h-24" #fileManager [eager]="false"></aux-file-manager>
    </div>
    <div class="space-y-2">
      <div class="grid grid-cols-2 gap-5">
        <aux-input formControlName="zip" label="Zip"></aux-input>
        <aux-input formControlName="city" label="City"></aux-input>
      </div>
      <div class="grid grid-cols-2 gap-5">
        <aux-input formControlName="state" label="State"></aux-input>
        <div>
          <div class="text-xs">Country</div>
          <ng-select
            class="select select__big"
            formControlName="country"
            [clearable]="false"
            appendTo="body"
            label="Country"
            auxFormError
            placeholder="Country"
          >
            <ng-option [value]="country.value" *ngFor="let country of countries">
              {{ country.label }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-5">
        <aux-input formControlName="target_patients" label="Target Patients"></aux-input>
        <div class="aux-select">
          <label for="vendor">Managed By</label>
          <ng-select
            class="select select__big"
            id="vendor"
            formControlName="managed_by_id"
            bindValue="id"
            bindLabel="name"
            [clearable]="false"
            [searchable]="false"
            label="Managed By"
            appendTo="body"
            auxFormError
            placeholder="—"
          >
            <ng-option
              [value]="organization"
              *ngFor="let organization of organizationQuery.selectAll() | async"
            >
              <span [title]="organization.name">{{ organization.name }}</span>
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-5">
        <aux-input
          formControlName="initiation_date"
          label="Initiation Date"
          [type]="'date'"
          placeholder="YYYY-MM-DD"
        ></aux-input>
        <div>
          <div class="text-xs">Currency</div>
          <ng-select
            class="select select__big"
            formControlName="currency"
            [items]="currencyOptions"
            [clearable]="false"
            [searchable]="true"
            [appendTo]="'body'"
            bindLabel="label"
            bindValue="key"
            [multiple]="false"
            label="Currency"
            auxFormError
            required
          >
          </ng-select>
        </div>
      </div>
      <div>
        <div class="font-semibold my-2">Primary Investigator</div>
        <div class="flex space-x-4">
          <aux-input
            [label]="'First Name'"
            formControlName="given_name"
            validators="required"
            class="flex-1"
          ></aux-input>
          <aux-input
            [label]="'Last Name'"
            formControlName="family_name"
            validators="required"
            class="flex-1"
          ></aux-input>
        </div>
      </div>
    </div>
    <div class="max-h-60 overflow-auto">
      <aux-file-viewer
        [fileManager]="fileManager"
        [onlyShowUploaded]="false"
        [disableFirstFileMargin]="true"
      ></aux-file-viewer>
    </div>
    <div class="ml-2 mt-6 mb-2">
      <button class="w-48 text-sm btn btn--blue" type="submit">Add Trial Site</button>
    </div>
  </form>
</div>
