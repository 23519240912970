import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomOverlayRef } from '../../overlay/custom-overlay-ref';

@Component({
  selector: 'aux-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styles: [],
})
export class ConfirmDialogComponent {
  textarea = new FormControl('');

  constructor(
    public ref: CustomOverlayRef<
      { result: boolean; textarea: string },
      {
        message: string;
        header: string;
        okBtnText: string;
        okBtnDisabled: boolean;
        okBtnDataId?: string;
        cancelBtnText: string;
        textarea?: { label: string; required: boolean };
      }
    >
  ) {}

  close(bool: boolean) {
    const textarea = this.textarea.value;
    if (bool && this.ref.data?.textarea?.required && !textarea) {
      this.textarea.markAsTouched();
      this.textarea.markAsDirty();
      this.textarea.updateValueAndValidity();
      return;
    }

    this.ref.close({
      result: bool,
      textarea,
    });
  }
}
