import { AmountType, Approval, InvoiceStatus } from '@services/gql.service';

export interface InvoiceCardLine {
  note?: string;
  type: string;
  label?: string;
  status?: string;
  text?: string;
}

export interface InvoiceCard {
  id: string;
  header: string;
  note: string;
  status: 'Complete' | 'In Process' | 'Up Next';
  lines: Array<InvoiceCardLine>;
}

export interface InvoiceAmounts {
  invoice_total: {
    value: number;
    type: AmountType | string;
  };
  investigator_total: {
    value: number;
    type: AmountType | string;
  };
  services_total: {
    value: number;
    type: AmountType | string;
  };
  discount_total: {
    value: number;
    type: AmountType | string;
  };
  pass_thru_total: {
    value: number;
    type: AmountType | string;
  };
}

export interface InvoiceModel {
  id: string;
  organization: {
    id: string;
    name: string;
  };
  invoice_date: string;
  invoice_no: string;
  invoice_status: InvoiceStatus;
  admin_review_reason?: string | null;
  decline_reason: string;
  delete_reason: string;
  po_reference: string | null;
  due_date: string;
  expense_amounts: InvoiceAmounts;
  approvals: Approval[];
  cards: InvoiceCard[];
}

export const INVOICE_STATUSES = {
  STATUS_IN_QUEUE: 'In Queue',
  STATUS_PENDING_REVIEW: 'Pending Review',
  STATUS_PENDING_APPROVAL: 'Pending Approval',
  STATUS_APPROVED: 'Approved',
  STATUS_DECLINED: 'Declined',
} as { [k in InvoiceStatus]: string };
