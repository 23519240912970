import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
  forwardRef,
  ChangeDetectorRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const CHECKBOX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true,
};
@Component({
  selector: 'aux-checkbox',
  templateUrl: './checkbox.component.html',
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
      }
    `,
  ],
  providers: [CHECKBOX_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() id = '';

  @Input() checked = false;

  @Input() disabled = false;

  @Input() indeterminate = false;

  @Output() customChange = new EventEmitter<boolean>();

  constructor(private cdr: ChangeDetectorRef) {}

  writeValue(value: any): void {
    this.checked = value;
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  public onChangeCallback(e: any) {
    console.log(e);
    // placeholder
  }

  private onTouchedCallback() {
    // placeholder
  }
}
