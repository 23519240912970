<ng-container>
  <div *ngIf="defaultChartOptions$ | async as ChartOptions">
    <div
      [ngClass]="{ 'flex items-center': ChartOptions.legend.right || ChartOptions.legend.left }"
      *ngIf="ChartOptions"
    >
      <div *ngIf="!ChartOptions.legend.top; else legendTopBottom"></div>
      <div *ngIf="!ChartOptions.legend.left; else legendLeftRight"></div>
      <div style="height: 250px" class="w-full">
        <canvas
          baseChart
          class="chart"
          [datasets]="ChartOptions.datasets"
          [options]="ChartOptions.options"
          [colors]="ChartOptions.colors"
          [legend]="false"
          [chartType]="ChartOptions.type"
          [labels]="ChartOptions.labels"
        >
        </canvas>
      </div>
      <div *ngIf="!ChartOptions.legend.bottom; else legendTopBottom"></div>
      <div *ngIf="!ChartOptions.legend.right; else legendLeftRight"></div>
    </div>
  </div>
</ng-container>

<ng-template #legendTopBottom
  ><div class="flex items-center justify-center space-x-8 mt-4">
    <div
      class="flex items-center text-sm"
      *ngFor="let color of colors"
      (click)="legendAction(color)"
    >
      <div
        class="w-14 h-4 mr-4 border-2"
        *ngIf="color.type === 'bar'"
        [ngStyle]="{
          'background-color': color.color,
          'border-color': color.color
        }"
      ></div>
      <div
        class="w-14 h-1 mr-4 rounded-full"
        *ngIf="color.type === 'line'"
        [ngStyle]="{
          'background-color': color.color,
          'border-color': color.color
        }"
      ></div>
      <div class="text-xs" [ngClass]="{ 'line-through text-aux-gray-dark': color.hide }">
        {{ color.label }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #legendLeftRight
  ><div class="grid justify-start mt-4 ml-1 font-bold">
    <div
      class="flex items-center text-sm mb-1"
      *ngFor="let color of colors"
      (click)="legendAction(color)"
    >
      <div
        class="w-14 h-4 mr-4 border-2"
        *ngIf="color.type === 'bar'"
        [ngStyle]="{
          'background-color': color.color,
          'border-color': color.color
        }"
      ></div>
      <div
        class="w-14 h-1 mr-4 rounded-full"
        *ngIf="color.type === 'line'"
        [ngStyle]="{
          'background-color': color.color,
          'border-color': color.color
        }"
      ></div>
      <div class="text-xs" [ngClass]="{ 'line-through text-aux-gray': color.hide }">
        {{ color.label }}
      </div>
    </div>
  </div></ng-template
>
