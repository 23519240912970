import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState, getEntityType } from '@datorama/akita';
import { ChangeOrder, EntityType } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface ChangeOrderState
  extends EntityState<
      Omit<
        RequireSome<Partial<ChangeOrder>, 'id'>,
        'organization' | 'workflow_details' | 'approved_budget_version' | 'merged_budget_version'
      > & {
        organization_id: string;
        verifications: ChangeOrderVerification[];
        workflow_id: string;
        approved_budget_version?: {
          __typename: 'BudgetVersion';
          budget_id: string;
          budget_version_id: string;
          bucket_key?: string | null;
          budget_name: string;
          version: string;
          budget_type: string;
          manual_forecast: boolean;
          create_date: string;
          entity_id: string;
          entity_type: EntityType;
        } | null;
        merged_budget_version?: {
          __typename: 'BudgetVersion';
          budget_id: string;
          budget_version_id: string;
          bucket_key?: string | null;
          budget_name: string;
          version: string;
          budget_type: string;
          manual_forecast: boolean;
          create_date: string;
          entity_id: string;
          entity_type: EntityType;
        } | null;
      }
    >,
    ActiveState {}

export type ChangeOrderModel = getEntityType<ChangeOrderState>;

export interface ChangeOrderVerification {
  name: string;
  status: 'exc-yellow' | 'exc-red' | 'exc-gray' | 'check';
  value: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'change-order' })
export class ChangeOrderStore extends EntityStore<ChangeOrderState> {
  constructor() {
    super({});
  }
}
