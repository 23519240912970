import { Injectable } from '@angular/core';
import { arrayAdd, arrayRemove, arrayUpdate } from '@datorama/akita';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import {
  ChangeLogItemStatus,
  GqlService,
  RequesterType,
  updateChangeLogItemMutation,
} from '@services/gql.service';
import { Utils } from '@services/utils';
import { ApiService } from '@services/api.service';
import { ChangeLogItemModel, ChangeLogModel, ChangeLogStore } from './change-log.store';

@Injectable({ providedIn: 'root' })
export class ChangeLogService {
  constructor(
    private changeLogStore: ChangeLogStore,
    private mainQuery: MainQuery,
    private vendorsQuery: OrganizationQuery,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private apiService: ApiService
  ) {}

  get() {
    return combineLatest([
      this.mainQuery.select('trialKey'),
      this.vendorsQuery.selectActive((x) => x.id),
    ]).pipe(
      switchMap(([trial_id, vendor_id]) => {
        this.changeLogStore.setLoading(true);
        if (trial_id && vendor_id) {
          return this.gqlService.listChangeLogs$(vendor_id);
        }
        return of({ success: false, data: [], errors: [] });
      }),
      map(({ success, data: rawData, errors }) => {
        let data: ChangeLogModel[] = [];

        if (success && rawData) {
          data = rawData.map((x) => ({
            ...x,
            change_log_items: [] as ChangeLogItemModel[],
            clis_total: '$0',
            services_total: '$0',
            investigator_fees_total: '$0',
            pass_thru_total: '$0',
            services_percent: 0,
            investigator_fee_percent: 0,
            pass_thru_percent: 0,
          }));
        }

        this.changeLogStore.set(data);

        data.forEach(({ id, change_log_status }) => {
          if (change_log_status === 'STATUS_OPEN') {
            this.changeLogStore.setActive(id);
          }
        });

        this.changeLogStore.setLoading(false);

        return { success, data, errors };
      })
    );
  }

  dateFormatter(dateStr: string | null | undefined) {
    try {
      return Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }).format(Utils.dateParse(dateStr));
    } catch (e) {
      return '-';
    }
  }

  async deleteLogItem(id: string, change_log_id: string) {
    this.changeLogStore.setLoading(true);

    const { success, data, errors } = await this.gqlService.removeChangeLogItem$(id).toPromise();

    if (success && data) {
      this.changeLogStore.update(change_log_id, ({ change_log_items }) => {
        const newArr = arrayRemove(change_log_items, id);
        const totals = this.getChangeLogStoreTotals(change_log_id, newArr);

        return {
          ...totals,
        };
      });
      this.overlayService.success('Successfully removed!');
    } else {
      this.overlayService.error(errors);
    }
    this.changeLogStore.setLoading(false);
  }

  getFilePath(changeLogId: string, cnf_no: number) {
    const trialId = this.mainQuery.getValue().trialKey;
    const vendorId = this.vendorsQuery.getActiveId() || '';
    return `trials/${trialId}/vendors/${vendorId}/changelogs/${changeLogId}/changelogitems/${cnf_no}`;
  }

  async updateLogItem({
    cnf_no,
    change_order_reference,
    change_log_item_status,
    change_log_item_id,
    change_log_id,
    request_date,
    start_date,
    description,
    organization_id,
    service_fee,
    passthrough_fee,
    investigator_fee,
    requester,
    cause,
    is_planned,
  }: {
    cnf_no: number;
    change_order_reference: string;
    change_log_item_status: ChangeLogItemStatus;
    change_log_item_id: string;
    change_log_id: string;
    request_date: string;
    start_date: string;
    description: string;
    organization_id: string;
    service_fee: number;
    passthrough_fee: number;
    investigator_fee: number;
    requester: RequesterType;
    cause: string;
    is_planned: boolean;
  }) {
    const { data, errors, success } = await this.gqlService
      .updateChangeLogItem$({
        cnf_no,
        change_order_reference,
        change_log_item_status,
        change_log_item_id,
        change_log_id,
        request_date,
        start_date,
        description,
        organization_id,
        service_fee,
        passthrough_fee,
        investigator_fee,
        requester,
        cause,
        is_planned,
      })
      .toPromise();

    let parsedData: ChangeLogItemModel | null = null;

    if (success && data) {
      this.changeLogStore.update(change_log_id, ({ change_log_items }) => {
        parsedData = this.parseLogItems(data);
        const new_log_items = arrayUpdate(change_log_items, change_log_item_id, parsedData);
        const updatedTotals = this.getChangeLogStoreTotals(change_log_id, new_log_items);
        return {
          ...updatedTotals,
        };
      });
    } else {
      this.overlayService.error(errors);
    }

    return { data: parsedData, errors, success };
  }

  async addLogItem({
    cnf_no,
    change_order_reference,
    change_log_item_status,
    change_log_id,
    request_date,
    start_date,
    description,
    organization_id,
    investigator_fee,
    passthrough_fee,
    service_fee,
    cause,
    requester,
    is_planned,
  }: {
    cnf_no: number;
    change_order_reference: string;
    change_log_item_status: ChangeLogItemStatus;
    change_log_id: string;
    request_date: string;
    start_date: string;
    description: string;
    organization_id: string;
    service_fee: number | null;
    passthrough_fee: number | null;
    investigator_fee: number | null;
    cause: string | null;
    requester: RequesterType;
    is_planned: boolean;
  }) {
    const { success, errors, data } = await this.gqlService
      .createChangeLogItem$({
        cnf_no,
        change_order_reference,
        change_log_item_status,
        change_log_id,
        request_date,
        start_date,
        description,
        organization_id,
        service_fee,
        passthrough_fee,
        investigator_fee,
        cause,
        requester,
        is_planned,
      })
      .toPromise();

    let parsedData: ChangeLogItemModel | null = null;

    if (success && data) {
      parsedData = this.parseLogItems(data);
      this.changeLogStore.update(change_log_id, ({ change_log_items }) => {
        const newArr = arrayAdd(change_log_items, parsedData);
        const totals = this.getChangeLogStoreTotals(change_log_id, newArr);

        return {
          ...totals,
        };
      });
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors, data: parsedData };
  }

  parseLogItems(item: updateChangeLogItemMutation) {
    const amounts = {
      AMOUNT_INVESTIGATOR: 0,
      AMOUNT_PASSTHROUGH: 0,
      AMOUNT_SERVICE: 0,
      AMOUNT_TOTAL: 0,
    } as { [k: string]: number };

    for (const amountObj of item.expense_amounts || []) {
      if (amountObj) {
        amounts[amountObj.amount_type] = amountObj.amount || 0;
      }
    }

    return {
      ...item,

      req_start_date:
        item.request_date && item.start_date
          ? `${this.dateFormatter(item.request_date)} / ${this.dateFormatter(item.start_date)}`
          : '',
      investigator_amount: amounts.AMOUNT_INVESTIGATOR || 0,
      passthrough_amount: amounts.AMOUNT_PASSTHROUGH || 0,
      service_amount: amounts.AMOUNT_SERVICE || 0,
      total_amount: amounts.AMOUNT_TOTAL || 0,
    } as ChangeLogItemModel;
  }

  getLogItems(change_log_id: string) {
    this.changeLogStore.setLoading(true);
    return this.gqlService.listChangeLogItems$(change_log_id).pipe(
      map(({ success, data, errors }) => {
        if (success && data) {
          const parsed_change_log_items = data.map((item) => {
            return {
              ...this.parseLogItems(item),
              file_link: this.getFilePath(change_log_id, item.cnf_no),
            };
          });

          this.changeLogStore.update(change_log_id, {
            ...this.getChangeLogStoreTotals(change_log_id, parsed_change_log_items),
          });
        }
        this.changeLogStore.setLoading(false);
        return { success, data, errors };
      })
    );
  }

  getChangeLogStoreTotals(change_log_id: string, change_log_items: ChangeLogItemModel[]) {
    let clis_total = 0;
    let services_total = 0;
    let investigator_fees_total = 0;
    let pass_thru_total = 0;
    change_log_items.forEach((item) => {
      clis_total += item.service_amount + item.passthrough_amount + item.investigator_amount;
      services_total += item.service_amount;
      investigator_fees_total += item.investigator_amount;
      pass_thru_total += item.passthrough_amount;
    });
    return {
      change_log_items,
      services_percent: Math.round((services_total / clis_total) * 100),
      investigator_fee_percent: Math.round((investigator_fees_total / clis_total) * 100),
      pass_thru_percent: Math.round((pass_thru_total / clis_total) * 100),
      clis_total: Utils.currencyFormatter(clis_total),
      services_total: Utils.currencyFormatter(services_total),
      investigator_fees_total: Utils.currencyFormatter(investigator_fees_total),
      pass_thru_total: Utils.currencyFormatter(pass_thru_total),
    };
  }

  add(changeLog: ChangeLogModel) {
    this.changeLogStore.add(changeLog);
  }

  update(id: string, changeLog: Partial<ChangeLogModel>) {
    this.changeLogStore.update(id, changeLog);
  }

  remove(id: string) {
    this.changeLogStore.remove(id);
  }
}
