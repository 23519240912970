import { Injectable } from '@angular/core';
import {
  BudgetType,
  BudgetViewInput,
  ExpenseType,
  GqlService,
  listBudgetGridQuery,
  listBudgetVersionsQuery,
  PeriodType,
} from '@services/gql.service';
import { map, switchMap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { difference, groupBy, pickBy, round, uniqBy } from 'lodash-es';
import { Utils } from '@services/utils';
import { Option } from '@components/components.type';
import { OrganizationQuery } from '@models/organization/organization.query';
import * as dayjs from 'dayjs';
import { MainQuery } from '../../../../../layouts/main-layout/state/main.query';
import { BudgetService } from '../../budget/state/budget.service';
import { BudgetDataType, BudgetGridService } from '../../budget/state/budget-grid.service';
import { BudgetStore } from '../../budget/state/budget.store';
import { ExtendedBudgetData } from '../../budget/state/budget.model';

const NOT_RESETTABLE_GRID_KEYS: (
  | keyof ExtendedBudgetData
  | 'display_group'
  | 'item_group'
  | 'vendor_name'
)[] = [
  'activity_name',
  'cost_category',
  'cost_category_ordering',
  'vendor_id',
  'group_index',
  'display_group',
  'item_group',
  'vendor_name',
];

@Injectable({
  providedIn: 'root',
})
export class SnapshotService {
  loading$ = new BehaviorSubject(false);

  budgetVersions$ = new BehaviorSubject<listBudgetVersionsQuery[]>([]);

  constructor(
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private vendorQuery: OrganizationQuery,
    private budgetService: BudgetService,
    private budgetGridService: BudgetGridService,
    private budgetStore: BudgetStore
  ) {}

  getSnapShotVersions() {
    return this.vendorQuery.selectActive().pipe(
      switchMap((vendorId) => {
        return this.budgetVersions$.pipe(
          map((versionList) => {
            const list = vendorId?.id ? versionList : uniqBy(versionList, 'budget_name');

            return list
              .filter((budgetVersion) => {
                return vendorId?.id ? budgetVersion.vendor_id === vendorId?.id : true;
              })
              .sort(({ create_date }, { create_date: create_date2 }) =>
                Utils.dateSort(create_date, create_date2)
              )
              .map<Option>(({ budget_name, create_date }) => ({
                label: budget_name,
                value: budget_name,
                create_date,
              }));
          })
        );
      })
    );
  }

  getSnapshotList() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.loading$.next(true);

        return this.gqlService.listBudgetVersions$([BudgetType.BUDGET_SNAPSHOT]);
      }),
      map(({ data, errors, success }) => {
        this.loading$.next(false);

        if (success && data) {
          this.budgetVersions$.next(data);

          return data;
        }

        if (errors.length) {
          this.overlayService.error(errors);
        }

        return [];
      })
    );
  }

  async removeBudgetSnapshots(snapshotName: string) {
    const snapshotIds = this.budgetVersions$
      .getValue()
      .filter(({ budget_name }) => budget_name === snapshotName)
      .map(({ budget_version_id }) => budget_version_id);

    const responses = await Promise.all(
      snapshotIds.map((id) => this.gqlService.removeBudgetSnapshot$(id).toPromise())
    );

    this.handleSnapshotResponse(
      responses,
      () => {
        this.budgetVersions$.next(
          this.budgetVersions$
            .getValue()
            .filter(({ budget_version_id }) => !snapshotIds.includes(budget_version_id))
        );
      },
      'Snapshot successfully removed!'
    );
  }

  async updateSnapshots(originalMame: string, updatedName: string) {
    const snapshotInputs = this.budgetVersions$
      .getValue()
      .filter(({ budget_name }) => budget_name === originalMame)
      .map(({ budget_version_id }) => ({ id: budget_version_id, budget_name: updatedName }));

    const responses = await Promise.all(
      snapshotInputs.map((input) => this.gqlService.updateBudgetSnapshot$(input).toPromise())
    );

    this.handleSnapshotResponse(
      responses,
      () => {
        this.budgetVersions$.next(
          this.budgetVersions$.getValue().map((budgetVersion) => {
            const { budget_name, ...restData } = budgetVersion;
            return budget_name === originalMame
              ? { ...restData, budget_name: updatedName }
              : budgetVersion;
          })
        );
      },
      'Snapshot successfully updated!'
    );
  }

  private handleSnapshotResponse(
    responses: GraphqlResponse<any>[],
    successCallback: VoidFunction,
    successMessage: string
  ) {
    const succeed = responses.every(({ success }) => success);

    if (succeed) {
      successCallback();

      this.overlayService.success(successMessage);
    } else {
      const listErrors = responses.reduce<string[]>((accum, { errors }) => {
        return accum.concat(errors);
      }, []);

      this.overlayService.error(listErrors);
    }
  }

  setOriginalBudgetData() {
    const budgetGridData = this.budgetStore.getValue().current_data;
    this.budgetStore.update({
      budget_data: budgetGridData,
    });
  }

  private getSnapshotInputs(snapshotName: string) {
    const selectedVendor = this.vendorQuery.getActive();

    const versions = this.budgetVersions$
      .getValue()
      .filter(
        ({ budget_name, vendor_id }) =>
          budget_name === snapshotName && (selectedVendor ? selectedVendor.id === vendor_id : true)
      )
      .sort((v1, v2) =>
        Utils.alphaNumSort(
          this.vendorQuery.getEntity(v1.vendor_id)?.name as string,
          this.vendorQuery.getEntity(v2.vendor_id)?.name as string
        )
      );

    const commonInputs = {
      period_type: PeriodType.PERIOD_MONTH,
      budget_type: BudgetType.BUDGET_SNAPSHOT,
      in_month: false,
    };

    return versions.map<BudgetViewInput>((budgetVersion) => {
      return {
        ...commonInputs,
        budget_version_id: budgetVersion.budget_version_id,
        vendor_id: selectedVendor ? budgetVersion.vendor_id : null,
      };
    });
  }

  private getVendorIdList(budgetGridData: ExtendedBudgetData[] | BudgetDataType) {
    return uniqBy(budgetGridData, 'vendor_id')
      .map(({ vendor_id }) => vendor_id)
      .filter((id) => !!id)
      .sort((vendor_id, vendor_id2) =>
        Utils.alphaNumSort(
          this.vendorQuery.getEntity(vendor_id)?.name as string,
          this.vendorQuery.getEntity(vendor_id2)?.name as string
        )
      ) as string[];
  }

  private resetGridRow(row: ExtendedBudgetData): ExtendedBudgetData {
    return Object.keys(row).reduce<ExtendedBudgetData>((accum, key) => {
      return {
        ...accum,
        [key]: NOT_RESETTABLE_GRID_KEYS.indexOf(key) !== -1 ? row[key] : 0,
        expenses: [],
      };
    }, row);
  }

  private resetInvalidBudgets(
    gridData: ExtendedBudgetData[],
    noDataVendorIds: string[]
  ): ExtendedBudgetData[] {
    return gridData.map((row) => {
      if (noDataVendorIds.includes(row.vendor_id || '')) {
        return this.resetGridRow(row);
      }

      return row;
    });
  }

  private mergeSnapshotDataWithCurrentBudget(
    snapshotGridData: ExtendedBudgetData[],
    vendorIds: string[]
  ): ExtendedBudgetData[] {
    const groupedBudgetData = groupBy(this.budgetStore.getValue().current_data, 'vendor_id');

    const groupedSnapshotData = groupBy(snapshotGridData, 'vendor_id');

    return vendorIds.reduce<ExtendedBudgetData[]>((accum, id) => {
      if (groupedSnapshotData[id]) {
        // eslint-disable-next-line no-param-reassign
        accum = accum.concat(groupedSnapshotData[id]);

        return accum;
      }

      if (groupedBudgetData[id]) {
        // eslint-disable-next-line no-param-reassign
        accum = accum.concat(groupedBudgetData[id]);

        return accum;
      }

      return accum;
    }, []);
  }

  getBudgetSnapshots(snapshotName: string) {
    const inputs = this.getSnapshotInputs(snapshotName);

    this.budgetStore.setLoading(true);

    return forkJoin(inputs.map((input) => this.gqlService.listBudgetGrid$(input))).pipe(
      this.budgetService.budgetsCacheMechanism(),
      map((gridDataList: listBudgetGridQuery[]) => {
        const isAllSuccess = gridDataList.length ? gridDataList.every((data) => !!data) : false;

        const tableData = gridDataList
          .map((data) => data?.budget_data || [])
          .reduce<BudgetDataType>((accum, budgetVendor) => {
            // eslint-disable-next-line no-param-reassign
            accum = accum?.concat(budgetVendor);

            return accum;
          }, []);

        if (isAllSuccess && tableData?.length) {
          const vendors = this.vendorQuery.getAllVendors();

          const budgetGridData = this.budgetStore.getValue().current_data;
          const sortedVendorIds = this.getVendorIdList(budgetGridData);
          const snapshotVendorIds = this.getVendorIdList(tableData);

          const mergedData = this.mergeSnapshotDataWithCurrentBudget(
            tableData as ExtendedBudgetData[],
            sortedVendorIds
          );

          const budget_data = this.budgetGridService.getBudgetGridWithBaseLineForVendors(
            mergedData,
            vendors,
            [],
            PeriodType.PERIOD_MONTH
          );

          this.budgetStore.update({
            budget_data: this.resetInvalidBudgets(
              this.getSnapshotBudget(budget_data, budgetGridData),
              difference(sortedVendorIds, snapshotVendorIds)
            ),
          });
        }

        this.budgetStore.setLoading(false);
      })
    );
  }

  private calculateActuals(budgetRow: ExtendedBudgetData, keyPrefix = ''): Record<string, number> {
    return Object.keys(budgetRow)
      .filter((key) => /^(EXPENSE_WP|EXPENSE_FORECAST)::\w*-\d{4}$/.test(key))
      .reduce<Record<string, number>>((accum, key) => {
        const date = dayjs(key);

        if (!date.isValid()) {
          return accum;
        }

        const isForecastKey = key.startsWith('EXPENSE_FORECAST::');
        const keyAffix = isForecastKey ? 'EXPENSE_FORECAST::' : 'EXPENSE_WP::';

        const yearKey = `${keyAffix}${date.year()}`;
        const quarter = Math.floor(date.month() / 3) + 1;
        const quarterKey = `${keyAffix}Q${quarter}-${date.year()}${keyPrefix}`;

        const yearActuals = (accum[`${yearKey}${keyPrefix}`] || 0) + budgetRow[key];

        return {
          ...accum,
          [`${yearKey}${keyPrefix}`]: yearActuals,
          [quarterKey]: (accum[quarterKey] || 0) + budgetRow[key],
        };
      }, {});
  }

  private getVariances = (
    budget: Record<string, number>,
    currentBudget: Record<string, number>,
    findKey: (key: string) => boolean
  ) => {
    const data = pickBy({ ...currentBudget, ...budget }, (_, key) => findKey(key));

    return Object.keys(data).reduce((accum, key) => {
      const safeValue = budget[key] || 0;
      const currentBudgetValue = currentBudget[key] || 0;

      const varCost = currentBudgetValue - safeValue;

      const varPerc = safeValue ? round(varCost / safeValue, 2) : 0;

      return {
        ...accum,
        [`${key}::SNAPSHOT`]: safeValue,
        [`${key}::VAR_COST::SNAPSHOT`]: varCost,
        [`${key}::VAR_PERC::SNAPSHOT`]: varPerc,
      };
    }, {});
  };

  private getMonthsSnapshotActuals(
    snapshotData: ExtendedBudgetData,
    budgetGridData: ExtendedBudgetData
  ) {
    return Object.keys(budgetGridData)
      .filter((key) => /^EXPENSE_WP::\w*-\d{4}$/.test(key))
      .reduce((accum, monthKey) => {
        const period = monthKey.replace('EXPENSE_WP::', '');

        const actulasPriority = [
          ExpenseType.EXPENSE_WP,
          ExpenseType.EXPENSE_ACCRUAL_ADJUSTED,
          ExpenseType.EXPENSE_ACCRUAL,
          ExpenseType.EXPENSE_FORECAST_AT_CLOSE,
          ExpenseType.EXPENSE_FORECAST,
        ];

        let snapshotActual = 0;

        for (const expenseType of actulasPriority) {
          snapshotActual =
            (snapshotData?.expenses || []).find(
              (expense) => expense.period === period && expense.expense_type === expenseType
            )?.amount || 0;

          if (snapshotActual) {
            break;
          }
        }

        return {
          ...accum,
          [monthKey]: snapshotActual,
        };
      }, {});
  }

  private getSnapshotBudget(
    snapshotData: ExtendedBudgetData[],
    budgetGridData: ExtendedBudgetData[]
  ): ExtendedBudgetData[] {
    const snapshotGroupedValues = groupBy(snapshotData, 'activity_name');

    const currentBudgetGroupedValues = groupBy(budgetGridData, 'activity_name');

    return Object.entries(currentBudgetGroupedValues)
      .map(([activityName, currentBudgetRow]) => {
        return snapshotGroupedValues[activityName]
          ? snapshotGroupedValues[activityName]
          : currentBudgetRow.map(this.resetGridRow);
      })
      .reduce<ExtendedBudgetData[]>((accum, row) => {
        // eslint-disable-next-line no-param-reassign
        accum = accum.concat(row);

        return accum;
      }, [])
      .map((budget) => {
        if (!currentBudgetGroupedValues[budget.activity_name || '']) {
          return budget;
        }

        const currentBudgetRow = groupBy(
          currentBudgetGroupedValues[budget.activity_name as string],
          'vendor_id'
        )[budget.vendor_id as string][0];

        const var_amount = round((currentBudgetRow?.current_lre || 0) - budget.current_lre, 2);

        const updatedSnapshotBudget: ExtendedBudgetData = {
          ...budget,
          ...this.getMonthsSnapshotActuals(budget, currentBudgetRow),
        };

        const quarterAndYearsVariances = this.getVariances(
          this.calculateActuals(updatedSnapshotBudget),
          this.calculateActuals(currentBudgetRow),
          () => true
        );

        const forecastVariances = this.getVariances(
          updatedSnapshotBudget,
          currentBudgetRow,
          (key) => key.startsWith('EXPENSE_FORECAST')
        );

        const monthsVariances = this.getVariances(
          updatedSnapshotBudget,
          currentBudgetRow,
          (key) => key.startsWith('EXPENSE_WP::') && key !== 'EXPENSE_WP::TO_DATE'
        );

        return {
          ...currentBudgetRow,
          ...forecastVariances,
          ...monthsVariances,
          ...quarterAndYearsVariances,
          snapshot_lre: updatedSnapshotBudget.current_lre,
          var_amount,
          var_percent: this.budgetGridService.getVarPercent(
            currentBudgetRow?.current_lre || 0,
            var_amount,
            updatedSnapshotBudget.current_lre || 0
          ),
        };
      });
  }
}
