import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { BudgetType } from '@services/gql.service';
import { AuthService } from '@models/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationService } from '@models/organization/organization.service';
import { TabGroupConfig } from '@components/tab-group/tab-group.component';

import { SettingsService } from '../settings/settings.service';
import { ROUTING_PATH } from '../../app-routing-path.const';

@Component({
  selector: 'aux-sites-page',
  templateUrl: './sites-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class SitesPageComponent {
  rightSideContainer = new BehaviorSubject<TemplateRef<any> | null>(null);

  tabs: TabGroupConfig[] = [
    {
      label: 'Investigator Detail',
      show: this.launchDarklyService.select$((flags) => flags.tab_forecast_investigator_detail),
      route: `/${ROUTING_PATH.SITES.INDEX}/${ROUTING_PATH.SITES.INVESTIGATOR_DETAIL}`,
    },
    {
      label: 'Investigator Forecast',
      show: this.launchDarklyService.select$((flags) => flags.tab_forecast_investigator_forecast),
      route: `/${ROUTING_PATH.SITES.INDEX}/${ROUTING_PATH.SITES.INVESTIGATOR_FORECAST}`,
    },
    { label: 'Sites', route: `/${ROUTING_PATH.SITES.INDEX}/${ROUTING_PATH.SITES.TRIAL_SITES}` },
  ];

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private authService: AuthService,
    private vendorsService: OrganizationService,
    public service: SettingsService
  ) {
    this.vendorsService
      .getListWithTotalBudgetAmount(BudgetType.BUDGET_PRIMARY)
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
