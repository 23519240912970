import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { PatientProtocolType, SitePaymentSchedule } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface PaymentSchedulesState
  extends EntityState<
    Omit<
      RequireSome<Partial<SitePaymentSchedule>, 'id'>,
      'patient_protocol' | 'site' | 'expense_amount'
    > & {
      patient_protocol_id: string;
      patient_protocol_type: PatientProtocolType;
      site_id: string;

      amount?: number | null;
      amount_contract?: number | null;

      sps_expense_currency?: string | null;
      sps_contract_expense_currency?: string | null;

      total_payment_schedule_amount?: number;
      completion_date?: string;

      patient_id?: string | null;
      external_patient_id?: string | null;
    }
  > {}

export type PaymentSchedulesModel = getEntityType<PaymentSchedulesState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'payment-schedules' })
export class PaymentSchedulesStore extends EntityStore<PaymentSchedulesState> {
  constructor() {
    super({});
  }
}
