import { Component, ChangeDetectionStrategy } from '@angular/core';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'aux-investigator-forecast',
  templateUrl: './investigator-forecast.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorForecastComponent {
  gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    suppressMenuHide: true,
    headerHeight: 40,
    suppressCellFocus: true,
    columnDefs: [
      {
        headerName: 'Site ID',
        field: 'site_id',
        hide: true,
      },
      {
        headerName: '',
        field: 'site_no',
      },
      {
        headerName: 'VISITS ($)',
        field: 'VISITS ($)',
      },
      {
        headerName: 'OVERHEAD ($)',
        field: 'OVERHEAD ($)',
      },
      {
        headerName: 'OTHER AS %',
        field: 'OTHER AS %',
      },
      {
        headerName: 'OTHER ($)',
        field: 'OTHER ($)',
      },
      {
        headerName: 'LOW/MID/HIGH',
        field: 'LOW/MID/HIGH',
      },
      {
        headerName: 'SCREEN FAIL',
        field: 'SCREEN FAIL',
      },
      {
        headerName: 'TOTAL AVERAGE COST PER PATIENT',
        field: 'TOTAL AVERAGE COST PER PATIENT',
      },
      {
        headerName: 'CONTRACTED PATIENTS',
        field: 'CONTRACTED PATIENTS',
      },
    ],
  } as GridOptions;

  editMode = false;

  modifications = {
    screen_fail: 12,
    other_payments: 15,
    site_mix: 'low',
    site_cost: 0,
    buffer: 0,
  };
}
