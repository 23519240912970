import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { RequireSome } from '@services/utils';
import { UserAuditLog } from '@services/gql.service';

export interface AuditHistoryState
  extends EntityState<RequireSome<Partial<UserAuditLog & { date: Date }>, 'id'>> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'audit-history' })
export class AuditHistoryStore extends EntityStore<AuditHistoryState> {
  constructor() {
    super({});
  }
}
