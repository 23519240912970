import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from '@services/app.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';

export interface TabGroupConfig {
  label: string;
  route: string;
  show?: Observable<boolean>;
}

@Component({
  selector: 'aux-tab-group',
  templateUrl: './tab-group.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabGroupComponent {
  tabs$ = new BehaviorSubject<TabGroupConfig[]>([]);

  activeIndex$ = combineLatest([this.tabs$, this.appService.activeRoute$]).pipe(
    map(([tabs, activeRoute]) => {
      let index = 0;
      tabs.forEach((tab, i) => {
        if (activeRoute.includes(tab.route)) {
          index = i;
        }
      });
      return index;
    })
  );

  @Input() set tabs(tabs: TabGroupConfig[]) {
    this.tabs$.next([...tabs.map((tab) => ({ ...tab, activated: false }))]);
  }

  constructor(public appService: AppService, public mainQuery: MainQuery) {}
}
