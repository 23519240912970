<div class="ml-1 w-72" *ngIf="availableGroups as items">
  <div class="text-lg font-bold mb-4">{{ labelTopic }} {{ title }}</div>

  <div class="text-xs font-bold mb-4">{{ subTitle }}</div>

  <div class="text-sm mb-4" *ngFor="let item of items; index as index">
    <aux-checkbox
      class="mb-3 text-sm"
      [checked]="item.check"
      (customChange)="setValue(item, $event)"
    >
      {{ item.name }}
    </aux-checkbox>
  </div>

  <div class="text-sm grid">
    <span class="mb-1">Name Custom Curve:</span>
    <aux-input [formControl]="blendedCurveName" auxFormError></aux-input>
  </div>

  <div class="mt-8 flex space-x-4 justify-between">
    <button class="text-sm font-normal focus:outline-none" (click)="ref.close()" type="button">
      Cancel
    </button>
    <button class="text-sm btn btn--blue" (click)="saveBlendedCurve()">
      <span class="spinner w-6 h-6 mr-3" *ngIf="(loading$ | async) === true"></span>
      <span>{{ labelButton }} Curve</span>
    </button>
  </div>
</div>
