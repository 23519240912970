import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartTooltipItem, ChartType } from 'chart.js';
import { Color } from 'ng2-charts/lib/color';
import { Utils } from '@services/utils';
import { AuxSelectConfig } from '@components/aux-card-select/aux-card-select.component';
import { OrganizationStore } from '@models/organization/organization.store';
import { FormControl } from '@angular/forms';
import { OrganizationQuery } from '@models/organization/organization.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationService } from '@models/organization/organization.service';
import { finalize, map, startWith, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PaymentScheduleService } from './state/payment-schedule.service';
import { PaymentScheduleQuery } from './state/payment-schedule.query';
import { MainQuery } from '../../../../layouts/main-layout/state/main.query';
import { VendorPaymentsPageComponent } from '../../vendor-payments-page.component';

@UntilDestroy()
@Component({
  selector: 'aux-payment-schedule',
  templateUrl: './payment-schedule.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentScheduleComponent implements AfterViewInit {
  @ViewChildren('paymentScheduleFilters') paymentScheduleFilters!: QueryList<TemplateRef<any>>;

  legends = [
    {
      color: '#BACAD0',
      header: 'Milestone Payments',
    },
    {
      color: '#f3f6f7',
      header: 'Unit-Based Payments',
    },
    {
      color: '#094673',
      header: 'WP',
    },
  ];

  chart$: Observable<{
    labels: Label[];
    type: ChartType;
    options: ChartOptions;
    datasets: ChartDataSets[];
    colors: Color[];
  }> = this.paymentScheduleQuery
    .select('PAYMENT_SCHEDULE_CUMULATIVE_PAYMENTS_VS_CUMULATIVE_WP')
    .pipe(
      map((data) => {
        const quarters = Object.keys(data);
        return {
          labels: quarters,
          datasets: [
            {
              data: Object.values(data).map((s) => s.payment),
              label: 'Payments',
              stack: 'stack 0',
              maxBarThickness: 40,
            },
            {
              data: [],
              label: 'Investigator Pre-payment (Forecast)',
              stack: 'stack 0',
              maxBarThickness: 40,
            },
            {
              data: Object.values(data).map((s) => s.wp),
              label: 'WP',
              stack: 'stack 1',
              maxBarThickness: 40,
            },
          ],
          options: {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              xAxes: [
                {
                  stacked: true,
                },
              ],
              yAxes: [
                {
                  stacked: true,
                  ticks: {
                    beginAtZero: true,
                    callback(value) {
                      return Utils.currencyFormatter(value as number, {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 1,
                        minimumFractionDigits: 0,
                        notation: 'compact',
                      });
                    },
                  },
                },
              ],
            },
            tooltips: {
              callbacks: {
                label(tooltipItem: ChartTooltipItem) {
                  if (tooltipItem.yLabel && typeof tooltipItem.yLabel === 'number') {
                    return Utils.currencyFormatter(tooltipItem.yLabel, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    });
                  }
                  return `${tooltipItem.yLabel || ''}`;
                },
              },
            },
            plugins: {
              datalabels: {
                display: false,
              },
            },
          },
          colors: ['#BACAD0', '#f3f6f7', '#094673'].map((color) => ({
            borderColor: color,
            backgroundColor: color,
          })),
          type: 'bar',
        };
      })
    );

  filter$: Observable<AuxSelectConfig> = this.paymentScheduleQuery
    .select('PAYMENT_SCHEDULE_CASH_REQUIREMENTS')
    .pipe(
      map((arr) => {
        return {
          options: arr.map(({ expense_months_back }) => expense_months_back),
          data: arr.map(({ pm_total }) => Utils.currencyFormatter(pm_total)),
          header: 'Cash Requirements',
        } as AuxSelectConfig;
      })
    );

  loading$ = this.paymentScheduleQuery.selectLoading();

  selectedVendor = new FormControl('');

  middle$ = this.paymentScheduleQuery.select('PAYMENT_SCHEDULE_PREPAID_BALANCE_IN_ESCROW');

  right$ = this.paymentScheduleQuery.select('PAYMENT_SCHEDULE_NEXT_PAYMENT_MILESTONE');

  table$ = this.paymentScheduleQuery.select('PAYMENT_SCHEDULE_MILESTONES');

  constructor(
    private paymentScheduleService: PaymentScheduleService,
    private paymentScheduleQuery: PaymentScheduleQuery,
    private organizationStore: OrganizationStore,
    public organizationQuery: OrganizationQuery,
    private organizationService: OrganizationService,
    private vendorPaymentsPageComponent: VendorPaymentsPageComponent,
    private mainQuery: MainQuery
  ) {
    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          return this.paymentScheduleService.getData();
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe();

    this.organizationService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const org = this.organizationQuery.getActive();

        if (org) {
          this.selectedVendor.setValue(org.id);
        } else {
          // const vendors = this.organizationQuery.getAllVendors();
          // if (vendors.length) {
          //   this.organizationStore.setActive(vendors[0].id);
          //   this.selectedVendor.setValue(vendors[0].id);
          // }
        }
      });
  }

  onOrganizationSelected(orgId: string) {
    this.organizationStore.setActive(orgId);
  }

  ngAfterViewInit(): void {
    this.paymentScheduleFilters.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        finalize(() => {
          setTimeout(() => {
            this.vendorPaymentsPageComponent.rightSideContainer.next(null);
          }, 0);
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.vendorPaymentsPageComponent.rightSideContainer.next(
            this.paymentScheduleFilters.first || null
          );
        }, 0);
      });
  }
}
