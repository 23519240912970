import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { PatientProtocol } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface PatientProtocolState
  extends EntityState<
    RequireSome<
      Partial<PatientProtocol>,
      'id' | 'patient_protocol_type' | 'target_date_days_out' | 'name' | 'order_by'
    >
  > {}

export type PatientProtocolModel = getEntityType<PatientProtocolState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patient-protocol' })
export class PatientProtocolStore extends EntityStore<PatientProtocolState> {
  constructor() {
    super({});
  }
}
