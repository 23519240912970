<div class="mb-24 mt-4">
  <ag-grid-angular
    style="max-width: 770px; min-width: 520px; height: 400px"
    class="ag-theme-alpine"
    id="patient-protocol-table"
    [gridOptions]="$any(gridOptions$ | async)"
    [rowData]="$any(gridData$ | async)"
    (gridReady)="onGridReady($event)"
    (rowDragEnd)="onRowDragEnd($event)"
    [enableFillHandle]="true"
    (cellValueChanged)="cellValueChanged($event)"
  >
  </ag-grid-angular>

  <div class="mt-8">
    <button type="button" class="btn btn--blue" (click)="onAddSiteGroups()">Add Site Group</button>
  </div>
</div>

<aux-save-changes
  [onSaveChanges]="onSaveAll"
  *ngIf="!!(editedRows.size || newRowAdded || removedRows.length || reorderedRows.size)"
>
</aux-save-changes>
