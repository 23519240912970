import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SitesQuery } from '@models/sites/sites.query';
import { SitesService } from '@models/sites/sites.service';
import { SiteModel, SitesStore } from '@models/sites/sites.store';
import { OverlayService } from '@services/overlay.service';
import { EventType, listSiteContactsQuery } from '@services/gql.service';
import { AddCustomSiteDialogComponent } from 'src/app/modules/onboarding/new-site/add-custom-site-dialog/add-custom-site-dialog.component';
import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';

import { AddBulkSitesDialogComponent } from 'src/app/modules/onboarding/new-site/add-bulk-sites-dialog/add-bulk-sites-dialog.component';
import { EventService } from '@services/event.service';
import { startWith, switchMap } from 'rxjs/operators';
import { SiteDialogComponent } from './site-dialog/site-dialog.component';

@UntilDestroy()
@Component({
  selector: 'aux-sites',
  templateUrl: './sites.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SitesComponent implements OnInit {
  activeSite$ = this.sitesQuery.selectActive();

  newSiteMode$ = new BehaviorSubject(false);

  constructor(
    public sitesQuery: SitesQuery,
    private sitesService: SitesService,
    private sitesStore: SitesStore,
    private eventService: EventService,
    private overlayService: OverlayService,
    private patientProtocolService: PatientProtocolService
  ) {
    this.patientProtocolService.get().pipe(untilDestroyed(this)).subscribe();
  }

  ngOnInit(): void {
    this.eventService
      .select$(EventType.REFRESH_SITES)
      .pipe(
        startWith(null),
        switchMap(() => {
          return this.sitesService.get();
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe((sites) => {
        const siteInvestigators: {
          [k: string]: Observable<{
            data: listSiteContactsQuery | null;
            success: boolean;
            errors: string[];
          }>;
        } = {};
        sites.data?.forEach((site) => {
          siteInvestigators[site.id] = this.sitesService.getContacts(site.id as string);
        });

        this.sitesStore.setLoading(!!sites.data?.length);
        forkJoin(siteInvestigators).subscribe(
          (value: {
            [k: string]: {
              data: listSiteContactsQuery | null;
              success: boolean;
              errors: string[];
            };
          }) => {
            for (const site_id of Object.keys(value)) {
              this.sitesStore.update(site_id as any, {
                investigator: value[site_id].data,
              });
            }
            this.sitesStore.setLoading(false);
          }
        );
      });
  }

  async onEdit(site: SiteModel) {
    const ref = this.overlayService.open({
      content: SiteDialogComponent,
      data: { site },
      overlayConfig: {
        hasBackdrop: false,
        panelClass: [
          'modal',
          'is-active',
          'cdk-global-overlay-wrapper',
          'cdk-overlay-dark-backdrop',
          'cdk-overlay-backdrop-showing',
          'justify-center',
          'items-center',
        ],
      },
      closeButton: false,
    });

    const event = await ref.afterClosed$.toPromise();
    console.log(event);
  }

  removeSite(site: SiteModel) {
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Site',
      message: `Are you sure that you want to remove ${site.name}?`,
      okBtnText: 'Remove',
    });

    resp.afterClosed$.subscribe(async (value) => {
      if (value.data?.result) {
        await this.sitesService.remove(site);
      }
    });
  }

  onNewSite() {
    this.overlayService.open({ content: AddCustomSiteDialogComponent });
  }

  async onAddMultipleSites() {
    this.overlayService.open({ content: AddBulkSitesDialogComponent });
  }
}
