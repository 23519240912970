import { Component, ChangeDetectionStrategy } from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationModel, OrganizationStore } from '@models/organization/organization.store';
import {
  CellValueChangedEvent,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowNode,
  ValueGetterParams,
} from 'ag-grid-community';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { EventService } from '@services/event.service';
import { CreatePaymentMilestoneInput, EventType } from '@services/gql.service';
import { RequireSome, Utils } from '@services/utils';
import { AgDatePickerComponent } from '@components/datepicker/ag-date-picker/ag-date-picker.component';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';

import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { PaymentMilestoneService } from './state/payment-milestone.service';
import { PaymentMilestoneQuery } from './state/payment-milestone.query';
import { PaymentMilestoneStore } from './state/payment-milestone.store';
import { TableConstants } from '../../../../constants/table.constants';
import { MessagesConstants } from '../../../../constants/messages.constants';

interface PaymentMilestoneGridData {
  organization: OrganizationModel | null;
  amount?: number | undefined;
  name?: string | undefined;
  target_date?: string | undefined;
  id: string;
}

@UntilDestroy()
@Component({
  selector: 'aux-payment-milestones',
  templateUrl: './payment-milestones.component.html',
  styles: [
    `
      ::ng-deep #paymentMilestonesGrid .has-error .ag-cell {
        background-color: #fee2e2 !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMilestonesComponent {
  gridOptions = {
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      cellRenderer: AgCellWrapperComponent,
      editable: () => {
        return this.editModeGrid$.getValue();
      },
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    enableRangeSelection: true,
    suppressCellFocus: false,
    suppressMenuHide: true,
    rowClassRules: {
      'has-error': (params) => params.data.showError,
    },
    getRowId: ({ data }) => data?.id || data?.randomID,
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        hide: true,
      },
      {
        headerName: 'RANDOM ID',
        field: 'randomID',
        hide: true,
      },
      {
        ...TableConstants.DEFAULT_GRID_OPTIONS.ACTIONS_COL_DEF,
        cellRendererParams: {
          deleteClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            if (rowNode.data.id) {
              this.removedRows.push(rowNode.data.id);
            }
            this.gridAPI.applyTransaction({ remove: [rowNode.data] });
            this.checkChanges();
          },
        },
        hide: true,
      },
      {
        headerName: 'Milestone',
        field: 'name',
        minWidth: 200,
        resizable: true,
        cellClass: 'text-left',
        tooltipField: 'name',
      },
      {
        headerName: 'Payment ($)',
        field: 'amount',
        minWidth: 120,
        valueFormatter: Utils.agCurrencyFormatter,
      },
      {
        headerName: 'Target Date',
        field: 'target_date',
        minWidth: 150,
        cellEditor: AgDatePickerComponent,
        sort: 'asc',
        valueFormatter: Utils.agDateFormatter,
      },
      {
        headerName: 'Vendor',
        field: 'organization',
        minWidth: 200,
        resizable: true,
        cellClass: 'text-left',
        tooltipField: 'organization.name',
        valueGetter: (val: ValueGetterParams) => {
          return val.data?.organization?.name || '';
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: () => {
          return {
            values: this.organizationQuery.getAllVendors(),
            formatValue: (val: OrganizationModel | undefined) => {
              return val?.name || '';
            },
          };
        },
        filterValueGetter: (params: ValueGetterParams) => params.data?.organization?.id,
      },
    ],
  } as GridOptions;

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  gridColumnApi$ = new ReplaySubject<ColumnApi>(1);

  gridColumnApi!: ColumnApi;

  gridData$: Observable<
    PaymentMilestoneGridData[]
  > = this.paymentMilestoneQuery.selectAllMilestones();

  editedRows = new Set<string>();

  removedRows: string[] = [];

  newRowAdded = false;

  initialValues: PaymentMilestoneGridData[] = [];

  hasChanges = false;

  editModeGrid$ = new BehaviorSubject(false);

  constructor(
    public organizationQuery: OrganizationQuery,
    private organizationService: OrganizationService,
    private organizationStore: OrganizationStore,
    private paymentMilestoneService: PaymentMilestoneService,
    private paymentMilestoneStore: PaymentMilestoneStore,
    public paymentMilestoneQuery: PaymentMilestoneQuery,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private eventService: EventService
  ) {
    combineLatest([this.paymentMilestoneService.get(), this.organizationService.get()])
      .pipe(untilDestroyed(this))
      .subscribe();

    this.gridData$.pipe(untilDestroyed(this)).subscribe((gridData) => {
      this.initialValues = gridData;
    });

    this.eventService
      .select$(EventType.TRIAL_CHANGED)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.initialValues = [];
        this.cancelEditMode();
      });
  }

  checkChanges() {
    const currentValues: PaymentMilestoneGridData[] = [];

    this.gridAPI?.forEachNode(({ data }) => {
      currentValues.push({ ...data, amount: Number(data.amount) });
    });

    this.hasChanges = !isEqual(this.initialValues, currentValues);
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await result.afterClosed$.toPromise();
      return !!event.data;
    }
    return true;
  }

  gridReady({ api, columnApi }: GridReadyEvent) {
    this.gridAPI$.next(api);
    this.gridAPI = api;
    this.gridColumnApi$.next(columnApi);
    this.gridColumnApi = columnApi;
    this.sizeColumnsToFit();
    Utils.updateGridLayout(this.gridAPI, 'paymentMilestonesGrid');
  }

  onAddPaymentMilestone() {
    this.gridAPI$.pipe(take(1)).subscribe((gridApi) => {
      const ss = gridApi.applyTransaction({ add: [{ randomID: Utils.uuid() }] });
      if (ss?.add.length && ss.add[0].rowIndex) {
        this.gridAPI.startEditingCell({ rowIndex: ss.add[0].rowIndex, colKey: 'name' });
      }
    });
    this.newRowAdded = true;
    this.checkChanges();
  }

  cellValueChanged(event: CellValueChangedEvent) {
    if (event.data.id) {
      this.editedRows.add(event.data.id);
      this.checkChanges();
    }
  }

  showErrors() {
    const rowNodes: RowNode[] = [];
    let isThereAnyInvalidRow = false;
    this.gridAPI.forEachNode((node) => {
      const { amount, name, organization, target_date } = node.data as PaymentMilestoneGridData;

      if (amount && name && organization && target_date) {
        if (node.data.showError) {
          // eslint-disable-next-line no-param-reassign
          node.data.showError = false;
          rowNodes.push(node);
        }
      } else {
        isThereAnyInvalidRow = true;
        // eslint-disable-next-line no-param-reassign
        node.data.showError = true;
        rowNodes.push(node);
      }
    });

    this.gridAPI.redrawRows({ rowNodes });
    return isThereAnyInvalidRow;
  }

  onSaveAll = async () => {
    if (this.showErrors()) {
      this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
      return;
    }

    this.paymentMilestoneStore.setLoading(true);

    const upsertData: (CreatePaymentMilestoneInput & { id: string | null })[] = [];

    this.gridAPI.forEachNode((node) => {
      const { id, target_date, name, organization, amount } = node.data as RequireSome<
        PaymentMilestoneGridData,
        'organization' | 'name' | 'target_date' | 'amount' | 'id'
      >;

      if ((!id || this.editedRows.has(id)) && organization) {
        upsertData.push({
          id,
          name,
          amount,
          target_date,
          organization_id: organization.id,
        });
      }
    });

    let hasError = true;

    if (upsertData) {
      hasError = await this.paymentMilestoneService.upsert(upsertData);
    }

    if (this.removedRows.length) {
      hasError = await this.paymentMilestoneService.remove(this.removedRows);
    }

    if (!hasError) {
      this.overlayService.success();
    }

    this.cancelEditMode();
    this.paymentMilestoneStore.setLoading(false);
  };

  sizeColumnsToFit(): void {
    this.gridAPI.sizeColumnsToFit();
  }

  editGrid(): void {
    this.editModeGrid$.next(true);
    this.gridOptions.columnApi?.setColumnsVisible([TableConstants.FIELDS.ACTIONS], true);
    this.sizeColumnsToFit();
    this.gridOptions.api?.redrawRows();
    this.gridAPI.startEditingCell({
      rowIndex: 0,
      colKey: 'name',
    });
  }

  cancelEditMode(): void {
    this.gridAPI.stopEditing();
    this.editModeGrid$.next(false);
    this.gridOptions.columnApi?.setColumnsVisible([TableConstants.FIELDS.ACTIONS], false);
    this.sizeColumnsToFit();
    this.gridData$ = this.paymentMilestoneQuery.selectAllMilestones();
    this.resetChangeIndicators();
    this.gridAPI.redrawRows();
  }

  private resetChangeIndicators(): void {
    this.newRowAdded = false;
    this.editedRows.clear();
    this.removedRows = [];
    this.hasChanges = false;
  }
}
