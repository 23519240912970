import { Injectable } from '@angular/core';
import { Column } from 'ag-grid-community';
import { each } from 'lodash-es';

interface HideOption {
  hide: boolean;
}

export type GroupColumnChildrenOptions = HideOption;

interface GroupColumnChildren {
  [key: string]: GroupColumnChildrenOptions;
}

export interface GroupColumnChanges {
  [key: string]: GroupColumnChildren;
}

// This service was created to support the AgHeaderDropdownComponent(s)
// that are used within the BudgetEnhancedComponent.

// Currently, group column visibility is not persisted to the BudgetEnhancedComponent,
// which results in expanding all columns by default when re-setting column defs
// (e.g. when exporting a snapshot to Excel).

@Injectable()
export class BudgetEnhancedHeaderDropdownService {
  private groupColumnChanges: GroupColumnChanges = {};

  getGroupColumnChange(columnName: string): GroupColumnChildren {
    return this.groupColumnChanges[columnName];
  }

  registerGroupColumnChange(
    columnName: string,
    children: Column[],
    change: GroupColumnChildrenOptions
  ): void {
    // Initialize group column change
    if (!this.groupColumnChanges[columnName]) {
      this.groupColumnChanges[columnName] = {};
    }

    // Register group column children changes
    each(children, (childColumn) => {
      const childColumnName = childColumn.getColId();

      this.groupColumnChanges[columnName][childColumnName] = {
        // Previously registered changes
        ...this.groupColumnChanges[columnName][childColumnName],
        // New changes
        ...change,
      };
    });
  }
}
