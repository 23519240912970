<input
  [id]="id"
  [name]="id"
  type="checkbox"
  [disabled]="disabled"
  [(ngModel)]="checked"
  class="w-4 h-4 text-indigo-600 border-gray-300 rounded cursor-pointer focus:ring-indigo-500"
  (ngModelChange)="customChange.emit($event); onChangeCallback($event)"
  [ngClass]="{ 'cursor-not-allowed bg-gray-100': disabled }"
  [indeterminate]="indeterminate"
/>
<label [for]="id" class="block ml-1.5 cursor-pointer"><ng-content></ng-content></label>
