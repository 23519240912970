import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { SpecificationSetting, SpecificationSettingType } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface SpecificationSettingState
  extends EntityState<
    Omit<RequireSome<Partial<SpecificationSetting>, 'id'>, 'specification_type'> & {
      specification_type: SpecificationSettingType;
    }
  > {}

export type SpecificationSettingModel = getEntityType<SpecificationSettingState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'specification-setting' })
export class SpecificationSettingStore extends EntityStore<SpecificationSettingState> {
  constructor() {
    super({});
  }
}
