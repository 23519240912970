<ng-container *ngIf="$any(budgetQuery.selectLoading() | async)">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="!$any(budgetQuery.selectLoading() | async)">
  <div class="mt-8 grid grid-cols-3 gap-7.5" *ngIf="showAnalyticsSection$ | async">
    <div class="border rounded border-aux-gray-dark h-28 py-3 flex justify-around items-center">
      <div class="flex flex-col justify-center rounded-l px-4 pr-1">
        <div class="font-bold text-aux-black">Actuals</div>
        <div class="flex items-center space-x-4">
          <div class="font-medium text-xs">
            % of Total<br />
            Current $
          </div>
        </div>
      </div>
      <div class="h-full bg-aux-gray-dark" style="width: 1px"></div>
      <div class="flex space-x-2 items-center justify-center rounded-r px-4 tabular-nums">
        <div *ngIf="(wpLoading | async) === false">
          <ng-container *ngIf="budgetQuery.select('work_performed') | async as wp">
            <div class="flex space-x-2 items-center justify-center rounded-r px-4">
              <div class="text-lg font-bold text-aux-gray-darkest">
                {{
                  selectedVendor && selectedVendor.value
                    ? wp[selectedVendor.value]
                      ? wp[selectedVendor.value].wp_percentage
                      : zeroHyphen
                    : wp.ALL
                    ? wp.ALL.wp_percentage
                    : zeroHyphen
                }}
                <br />
                {{
                  selectedVendor && selectedVendor.value
                    ? wp[selectedVendor.value]
                      ? wp[selectedVendor.value].wp_total
                      : zeroHyphen
                    : wp.ALL
                    ? wp.ALL.wp_total
                    : zeroHyphen
                }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="border rounded border-aux-gray-dark h-28 py-3 flex justify-around items-center">
      <div class="flex flex-col justify-center rounded-l px-4 pr-14">
        <div class="font-bold text-aux-black">Invoiced</div>
        <div class="flex items-center space-x-4">
          <div class="font-medium text-xs">
            % of Total<br />
            Current $
          </div>
        </div>
      </div>
      <div class="h-full bg-aux-gray-dark" style="width: 1px"></div>
      <div class="flex space-x-2 items-center justify-center rounded-r px-4">
        <div *ngIf="(invoicesTotalLoading | async) === false">
          <ng-container *ngIf="budgetQuery.select('invoices') | async as invoices">
            <div class="flex space-x-2 items-center justify-center rounded-r px-4 tabular-nums">
              <div class="text-lg font-bold text-aux-gray-darkest">
                {{
                  selectedVendor && selectedVendor.value
                    ? invoices[selectedVendor.value]
                      ? invoices[selectedVendor.value].invoice_percentage
                      : zeroHyphen
                    : invoices.ALL
                    ? invoices.ALL.invoice_percentage
                    : zeroHyphen
                }}
                <br />
                {{
                  selectedVendor && selectedVendor.value
                    ? invoices[selectedVendor.value]
                      ? invoices[selectedVendor.value].invoice_total
                      : zeroHyphen
                    : invoices.ALL
                    ? invoices.ALL.invoice_total
                    : zeroHyphen
                }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="border rounded border-aux-gray-dark h-28 py-3 flex justify-around items-center">
      <div class="flex flex-col justify-center rounded-l px-4 pr-1">
        <div class="font-bold text-aux-black">Pending Change</div>
        <div class="flex items-center space-x-4">
          <div class="font-medium text-xs">
            Change Log + Unsigned<br />
            Change Orders $
          </div>
        </div>
      </div>
      <div class="h-full bg-aux-gray-dark" style="width: 1px"></div>
      <div class="flex space-x-2 items-center justify-center rounded-r px-4">
        <div *ngIf="(pendingChangesLoading | async) === false">
          <ng-container *ngIf="budgetQuery.select('pendingChanges') | async as pendingChanges">
            <div class="flex space-x-2 items-center justify-center rounded-r px-4 tabular-nums">
              <div class="text-lg font-bold text-aux-gray-darkest">
                {{
                  selectedVendor && selectedVendor.value
                    ? pendingChanges[selectedVendor.value]
                      ? pendingChanges[selectedVendor.value]
                      : zeroHyphen
                    : pendingChanges.ALL
                    ? pendingChanges.ALL
                    : zeroHyphen
                }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <ng-template #budgetFilters>
    <div class="flex justify-between items-center space-x-4">
      <div class="flex space-x-4 -mt-3.5">
        <div>
          <div class="text-xs mb-1">Filter By</div>
          <ng-select
            class="w-64"
            [clearable]="false"
            (change)="onVendorSelected($event)"
            [formControl]="selectedVendor"
          >
            <ng-container *ngVar="organizationQuery.allVendors$ | async as vendors">
              <ng-option [value]="''" *ngIf="vendors.length > 1">All</ng-option>
              <ng-option [value]="vendor.id" *ngFor="let vendor of vendors">
                <span [title]="vendor.name">{{ vendor.name }}</span>
              </ng-option>
            </ng-container>
          </ng-select>
        </div>

        <div>
          <div class="text-xs mb-1">Period Type</div>
          <ng-select
            class="w-32"
            [formControl]="selectedPeriodType"
            [clearable]="false"
            [multiple]="false"
            [searchable]="false"
            [items]="periodTypes"
            bindValue="value"
          >
          </ng-select>
        </div>

        <div *ngIf="showBudgetTypeSelect$ | async">
          <div class="text-xs mb-1">Budget Type</div>
          <ng-select
            class="w-32"
            [formControl]="selectedBudgetType"
            [clearable]="false"
            [multiple]="false"
            [searchable]="false"
            [items]="budgetTypes"
            bindValue="value"
          >
          </ng-select>
        </div>
      </div>

      <button type="button" class="text-sm btn btn--blue" (click)="onBudgetExportClick()">
        <span inlineSVG="download.svg"></span>
        <span class="ml-2">Export</span>
      </button>

      <aux-menu #budget_menu>
        <button
          type="button"
          role="menuitem"
          class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 px-4 py-2 text-sm w-full flex items-center focus:outline-none"
          (click)="onBudgetUploadClick(); budget_menu.close()"
        >
          <span inlineSVG="file-upload.svg"></span>
          <span class="ml-2">Upload</span>
        </button>
      </aux-menu>
    </div>
  </ng-template>

  <ng-container *ngIf="budgetCanvas$ | async as budgetCanvas">
    <ng-container *ngIf="budgetCanvas.show">
      <div class="my-8" style="height: 250px">
        <canvas
          baseChart
          [labels]="budgetCanvas.labels"
          [datasets]="budgetCanvas.datasets"
          [options]="budgetCanvas.options"
          [legend]="budgetCanvas.legend"
          [chartType]="budgetCanvas.type"
          [colors]="budgetCanvas.colors"
        >
        </canvas>
      </div>

      <hr class="mb-8" />
    </ng-container>
  </ng-container>

  <div style="width: 100%; overflow: auto">
    <div class="flex mb-8 justify-between items-center" style="margin-top: 1px">
      <div class="flex">
        <div
          class="text-sm flex justify-between items-center px-3 h-11 w-60 border border-aux-gray-dark font-medium rounded-md shadow-sm"
          (click)="openCustomList()"
        >
          <ng-container
            class="flex justify-between"
            *ngIf="selectedCustom$ | async; else placeholderTemplate"
          >
            <span class="text-sm">{{ selectedCustom$ | async }}</span>
          </ng-container>

          <div>
            <chevron-down-solid-icon class="text-aux-gray-darkest"></chevron-down-solid-icon>
          </div>
        </div>
        <div>
          <div class="mt-11" cdkOverlayOrigin #customTrigger="cdkOverlayOrigin"></div>
        </div>

        <ng-template
          cdkConnectedOverlay
          class="border-solid border-aux-gray-dark-100"
          [cdkConnectedOverlayOrigin]="customTrigger"
          [cdkConnectedOverlayOpen]="isCustomOpen"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'ttt'"
          [cdkConnectedOverlayPositions]="positions"
          (backdropClick)="closeCustomList()"
          (detach)="closeCustomList()"
        >
          <ul
            tabindex="-1"
            class="bg-white border-solid border-aux-gray-light rounded-md py-1 text-base ring-1 overflow-auto focus:outline-none sm:text-sm mt-1 w-60 max-h-100"
          >
            <ng-container
              class="grid"
              *ngFor="let item of customValues$ | async; index as index; let first = first"
            >
              <li
                class="text-gray-900 cursor-default select-none relative flex flex-col"
                (click)="customChanges(item)"
              >
                <div
                  *ngIf="item.showLine && !first"
                  class="border-b h-1 border-aux-gray-lightest mb-2 mt-1 mr-2 ml-2"
                ></div>

                <div
                  (mouseenter)="highlightCustom(index)"
                  (mouseleave)="highlightedCustom.next(null)"
                  class="text-gray-900 cursor-default select-none relative py-2 pl-2 flex justify-between"
                  [ngClass]="{
                    'bg-aux-gray-light': index === (highlightedCustom | async)
                  }"
                >
                  {{ item.name }}
                  <div class="flex mr-2">
                    <button
                      *ngIf="item.is_custom"
                      class="w-3 text-aux-blue-dark mr-1"
                      (click)="editCustom(item)"
                    >
                      <span inlineSVG="edit.svg" [setSVGAttributes]="{ class: 'w-3 h-3' }"></span>
                    </button>
                    <button *ngIf="item.is_custom" class="w-3 text-aux-red-hover">
                      <span
                        inlineSVG="trash.svg"
                        [setSVGAttributes]="{ class: 'w-4 h-4' }"
                        (click)="removeCustom(item)"
                      ></span>
                    </button>
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </ng-template>
        <button class="btn--primary pl-3 ml-2 mr-2" (click)="onColumnChooser()">
          <span class="mr-2" inlineSVG="eye.svg"></span>Column Chooser
        </button>
        <button
          class="btn--secondary pl-3"
          (click)="saveCustomUserView()"
          [disabled]="!areUnsavedChanges"
        >
          <span class="mr-2" inlineSVG="device-floppy.svg"></span>Save View
        </button>
        <div class="flex items-center ml-4">
          <div class="text-sm">
            <span>Years</span>
          </div>
          <div
            class="text-sm flex justify-between items-center px-3 h-10 w-28 border border-aux-gray-dark font-medium rounded-md shadow-sm ml-1"
            (click)="openList()"
          >
            <ng-container *ngIf="selectedYear; else placeholderTemplate">
              <span class="text-sm">{{ selectedYear }}</span>
            </ng-container>

            <ng-template #placeholderTemplate>
              <span class="text-gray-500"></span>
            </ng-template>

            <div>
              <chevron-down-solid-icon class="text-aux-gray-darkest"></chevron-down-solid-icon>
            </div>
          </div>

          <div>
            <div class="mt-11" cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>
          </div>
          <ng-template
            cdkConnectedOverlay
            class="border-solid border-aux-gray-dark-100"
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="isYearsOpen"
            [cdkConnectedOverlayHasBackdrop]="true"
            [cdkConnectedOverlayBackdropClass]="'ttt'"
            [cdkConnectedOverlayPositions]="positions"
            (backdropClick)="closeList()"
            (detach)="closeList()"
          >
            <ul
              tabindex="-1"
              class="bg-white border-solid text-gray-900 border-aux-gray-light rounded-md py-1 text-base ring-1 overflow-auto focus:outline-none sm:text-sm w-28"
            >
              <ng-container class="grid" *ngFor="let item of years; index as index">
                <li class="cursor-default select-none relative flex py-2 pl-2">
                  <aux-checkbox
                    [checked]="item.enabled"
                    (customChange)="yearChanged($event, item.label)"
                  ></aux-checkbox>
                  {{ item.label }}
                </li>
              </ng-container>
            </ul>
          </ng-template>
        </div>
      </div>
      <div class="flex" *ngIf="showSnapshotSection$ | async">
        <aux-compare-dropdown
          class="mr-1.5"
          (valueChange)="compareDropdownChange($event)"
          [initialValue]="compareToValue"
          [refreshTable]="refreshTable">
        </aux-compare-dropdown>
        <aux-button
          *ngVar="organizationQuery.allVendors$ | async as vendors"
          [label]="'Snap'"
          [icon]="'camera-plus'"
          [variant]="'secondary'"
          [onClick]="openSnapshotModal"
          [disabled]="vendors.length === 1 ? false : selectedVendor.value">
        </aux-button>
      </div>
    </div>

    <ag-grid-angular
      *ngIf="showGrid"
      style="min-width: 1000px; max-width: 100%"
      auxGridDynamicHeight
      class="ag-theme-aux tabular-nums w-full"
      [gridOptions]="$any(gridOptions$ | async)"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [columnDefs]="columnDefs"
      [rowData]="gridData$ | async"
      [getGroupRowAgg]="getGroupRowAgg"
      (firstDataRendered)="onDataRendered($event)"
      (modelUpdated)="refreshRows()"
    >
    </ag-grid-angular>
  </div>
</ng-container>
