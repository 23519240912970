import { Injectable } from '@angular/core';
import { Utils } from '@services/utils';
import { InvoiceStatus, PaymentStatus } from '@services/gql.service';
import { get } from 'lodash-es';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { PurchaseOrdersQuery } from '../purchase-orders/state/purchase-orders.query';

@Injectable({
  providedIn: 'root',
})
export class InvoicesGridFormatterService {
  constructor(private purchaseOrdersQuery: PurchaseOrdersQuery) {}

  getFormattedPurchaseOrder = (value: string) => {
    const category = this.purchaseOrdersQuery.getEntity(value);

    return (
      (category?.po_number === '0' || category?.po_number === '-'
        ? Utils.zeroHyphen
        : category?.po_number) || Utils.zeroHyphen
    );
  };

  getFormattedPaymentStatus(paymentStatus: PaymentStatus): string {
    if (paymentStatus === PaymentStatus.PAYMENT_STATUS_PAID_IN_FULL) {
      return 'Paid';
    }

    return Utils.zeroHyphen;
  }

  getFormattedInvoiceStatus(invoiceStatus: InvoiceStatus): string {
    const mapInvoiceStatus = new Map<InvoiceStatus, string>([
      [InvoiceStatus.STATUS_APPROVED, 'Approved'],
      [InvoiceStatus.STATUS_DECLINED, 'Declined'],
      [InvoiceStatus.STATUS_PENDING_APPROVAL, 'Pending Approval'],
      [InvoiceStatus.STATUS_PENDING_REVIEW, 'Pending Review'],
      [InvoiceStatus.STATUS_IN_QUEUE, 'Pending In Queue'],
    ]);

    return mapInvoiceStatus.get(invoiceStatus) || Utils.zeroHyphen;
  }

  getFormatterCreateAuthor = (
    val: ValueFormatterParams,
    userFormatter: (value: string) => string
  ): string => {
    const data_source_id = get(val, 'data.data_source_id');

    if (data_source_id === 'DATA_SOURCE_AUXILIUS') {
      return userFormatter(val.value);
    }
    return `${Utils.readableDataSource(data_source_id)} Integration`;
  };
}
