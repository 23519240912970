import {
  Component,
  ChangeDetectionStrategy,
  ViewChildren,
  QueryList,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { Label, SingleOrMultiDataSet } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { PluginServiceGlobalRegistrationAndOptions } from 'ng2-charts/lib/base-chart.directive';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { sum } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, startWith } from 'rxjs/operators';
import { Column, GridOptions } from 'ag-grid-community';
import { Utils } from '@services/utils';
import { BudgetPageComponent } from '../../budget-page.component';

@UntilDestroy()
@Component({
  selector: 'aux-change-order-review',
  templateUrl: './change-order-review.component.html',
  styleUrls: ['./change-order-review.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderReviewComponent implements AfterViewInit {
  @ViewChildren('changeOrderFilters') changeOrderFilters!: QueryList<TemplateRef<any>>;

  cards = [
    {
      header: 'Dates Covered',
      data: 'Jan - May 2019',
    },
    {
      header: 'Change Order Effective Date',
      data: 'June 8, 2019',
    },
    {
      header: 'Total Cost Impact',
      data: '$250,000',
    },
    {
      header: 'Activities w/ Increased Cost',
      data: '12',
    },
    {
      header: 'Activities w/ Increased Units',
      data: '14',
    },
    {
      header: 'Full Trial Cumulative Change In Scope',
      data: '$945,050',
    },
  ];

  changeOrders = ['Change Order #3', 'Change Order #2', 'Change Order #1'];

  stats = [
    {
      header: 'Signed off',
      data: '60%',
    },
    {
      header: 'Started without sign-off',
      data: '40%',
    },
    {
      header: 'Planned/Expected',
      data: '30%',
    },
    {
      header: 'Unplanned',
      data: '70%',
    },
    {
      header: 'Sponsor decided',
      data: '70%',
    },
    {
      header: 'Parexel driven',
      data: '30%',
    },
  ];

  categoryPie: {
    labels: Label[];
    data: SingleOrMultiDataSet;
    type: ChartType;
    options: ChartOptions;
    datasets: ChartDataSets[];
    plugins: PluginServiceGlobalRegistrationAndOptions[];
    total: number;
  } = {
    labels: ['Services', 'Investigator', 'Pass-through'],
    data: [],
    type: 'pie',
    plugins: [ChartDataLabels],
    total: sum([2400, 500, 800]),
    options: {
      plugins: {
        datalabels: {
          color: '#FFF',
          // formatter(value, context) {
          //   return `${context.dataIndex}: ${Math.round(value * 100)}%`;
          // },
          formatter: (value: number) => {
            return `${((value / this.categoryPie.total) * 100).toFixed(0)}%`;
          },
        },
      },
    },
    datasets: [{ data: [2400, 500, 800], backgroundColor: ['#095B95', '#179ADA', '#BACAD0'] }],
  };

  costPie: {
    labels: Label[];
    data: SingleOrMultiDataSet;
    type: ChartType;
    options: ChartOptions;
    datasets: ChartDataSets[];
    plugins: PluginServiceGlobalRegistrationAndOptions[];
    total: number;
  } = {
    labels: ['Monitoring Visits', 'Project Calls', 'Statistics Services'],
    data: [],
    type: 'pie',
    plugins: [ChartDataLabels],
    total: sum([650, 120, 230]),
    options: {
      rotation: 90,
      plugins: {
        datalabels: {
          color: '#FFF',
          // formatter(value, context) {
          //   return `${context.dataIndex}: ${Math.round(value * 100)}%`;
          // },
          formatter: (value: number) => {
            return `${((value / this.costPie.total) * 100).toFixed(0)}%`;
          },
        },
      },
    },
    datasets: [{ data: [650, 120, 230], backgroundColor: ['#179ADA', '#BACAD0', '#095B95'] }],
  };

  gridOptions = {
    defaultColDef: {
      sortable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      autoHeight: true,
    },
    columnDefs: [
      {
        headerName: 'ACTIVITIES',
        field: 'activities_id',
        maxWidth: 200,
        minWidth: 120,
        valueFormatter: Utils.dashFormatter,
      },
      {
        headerName: 'UOM',
        field: 'uom',
        minWidth: 100,
        maxWidth: 200,
        valueFormatter: Utils.dashFormatter,
      },
      {
        headerName: 'VENDORS',
        field: 'vendors',
        minWidth: 100,
        maxWidth: 200,
        tooltipField: 'description',
        wrapText: true,
        valueFormatter: Utils.dashFormatter,
      },
      {
        headerName: 'Baseline Budget/Plan',
        headerClass: 'ag-header-align-center',
        children: [
          {
            headerName: 'UNITS',
            field: 'baseUnit',
            minWidth: 80,
            maxWidth: 80,
          },
          { headerName: 'DIRECT COST', field: 'baseCost', minWidth: 80, maxWidth: 80 },
        ],
      },
      {
        headerName: 'Current Budget Remaining',
        headerClass: 'ag-header-align-center',
        minWidth: 200,
        children: [
          {
            headerName: 'UNITS',
            minWidth: 100,
            maxWidth: 100,
            field: 'curUnit',
          },
          { headerName: 'DIRECT COST', field: 'curCost', minWidth: 100, maxWidth: 100 },
        ],
      },
      {
        headerName: 'Change Order 1',
        headerClass: 'ag-header-align-center',
        children: [
          {
            headerName: 'UNITS',
            field: 'changeUnit',
            minWidth: 80,
            maxWidth: 80,
          },
          { headerName: 'DIRECT COST', field: 'changeCost', minWidth: 80, maxWidth: 80 },
        ],
      },
      {
        headerName: 'Difference',
        headerClass: 'ag-header-align-center',
        children: [
          {
            headerName: '$',
            field: 'difference',
            minWidth: 80,
            maxWidth: 80,
          },
        ],
      },
      {
        headerName: 'Variance',
        headerClass: 'ag-header-align-center',
        children: [
          {
            headerName: '%',
            field: 'variance',
            minWidth: 80,
            maxWidth: 80,
          },
        ],
      },
    ],
  } as GridOptions;

  data = [
    {
      activities_id: 'Trial Docs Prepation',
      uom: null,
      vendors: '1 Unique',
      baseUnit: '-',
      baseCost: '$4,469',
      curUnit: '-',
      curCost: '',
      changeUnit: '-',
      changeCost: '$182,704',
      difference: '-',
      veriance: '0%',
    },
    {
      activities_id: 'Regulatory',
      uom: null,
      vendors: '2 Unique',
      baseUnit: '-',
      baseCost: '-',
      curUnit: '-',
      curCost: '$4,609',
      changeUnit: '-',
      changeCost: '$93,495',
      difference: '-',
      veriance: '0%',
    },
    {
      activities_id: 'Site / Investigator Identify',
      uom: null,
      vendors: 'None',
      baseUnit: '-',
      baseCost: '$172,051',
      curUnit: '-',
      curCost: '$4,609',
      changeUnit: '-',
      changeCost: '$17,394',
      difference: '($33,440)',
      veriance: '-19%',
    },
    {
      activities_id: 'Identify Sites',
      uom: null,
      vendors: '',
      baseUnit: '-',
      baseCost: '-',
      curUnit: '-',
      curCost: '-',
      changeUnit: '-',
      changeCost: '$72,303',
      difference: '-',
      veriance: '0%',
    },
    {
      activities_id: 'Identify Sites - NA Sites',
      uom: "Sites ID'd",
      vendors: '',
      baseUnit: '111',
      baseCost: '$11,877',
      curUnit: '111',
      curCost: '',
      changeUnit: '111',
      changeCost: '$28,392',
      difference: '-',
      veriance: '0%',
    },
  ];

  constructor(private budgetPageComponent: BudgetPageComponent) {}

  ngAfterViewInit(): void {
    this.changeOrderFilters.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        finalize(() => {
          setTimeout(() => {
            this.budgetPageComponent.filterViewContainer.next(null);
          }, 0);
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.budgetPageComponent.filterViewContainer.next(this.changeOrderFilters.first || null);
        }, 0);
      });
  }

  onGridReady(e: any) {
    const { columnApi } = e;
    const allColumnIds: string[] = [];
    columnApi.getAllColumns().forEach((column: Column) => {
      allColumnIds.push(column.getColId());
    });
    columnApi.autoSizeColumns(allColumnIds, false);
  }

  autoSize() {
    this.gridOptions.api?.sizeColumnsToFit();
  }
}
