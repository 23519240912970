import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { GqlService, RoleType } from '@services/gql.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OverlayService } from '@services/overlay.service';
import { AuthQuery } from '@models/auth/auth.query';
import { TrialsQuery } from '@models/trials/trials.query';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TrialsService } from '@models/trials/trials.service';
import { ScriptLoaderService } from '@services/script-loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@models/auth/auth.service';

import { MainStore } from './main.store';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class MainService {
  constructor(
    private mainStore: MainStore,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private authQuery: AuthQuery,
    private trialsQuery: TrialsQuery,
    private trialUserService: TrialUserService,
    private trialsService: TrialsService,
    private scriptLoaderService: ScriptLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {
    this.authQuery.isLoggedIn$
      .pipe(
        untilDestroyed(this),
        switchMap((x: boolean) => {
          if (x) {
            this.mainStore.setLoading(true);
            return this.trialsService.get().pipe(
              tap(async ({ data }) => {
                if (data?.length) {
                  let trialKey: string;
                  const { queryParams } = this.route.snapshot;
                  if (
                    queryParams.trial &&
                    data.filter((d) => d.id === queryParams.trial).length !== 0
                  ) {
                    trialKey = queryParams.trial;
                    this.router.navigate([], { queryParams: {}, replaceUrl: true });
                  } else if (
                    queryParams.trial &&
                    data.filter((d) => d.id === queryParams.trial).length === 0
                  ) {
                    this.overlayService.error('User does not belong to trial');
                    trialKey = this.authQuery.getValue().trial_id;
                    this.router.navigate([], { queryParams: {}, replaceUrl: true });
                  } else {
                    trialKey = this.authQuery.getValue().trial_id;
                  }
                  const selectedTrial = data.find((trial) => trial.id === trialKey) || data[0];

                  await this.setTrial(selectedTrial.id, false);

                  this.mainStore.update({ trialKey: selectedTrial.id });
                } else {
                  this.mainStore.update({ trialKey: '' });
                }
                this.mainStore.setLoading(false);
              })
            );
          }
          this.mainStore.setLoading(false);
          return of([]);
        })
      )
      .subscribe();

    this.authQuery.isLoggedIn$.pipe(untilDestroyed(this)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.scriptLoaderService.injectJiraWidget();
      }
    });
  }

  async setTrial(value: string, showLoading = true) {
    if (value) {
      const ref = showLoading && this.overlayService.loading();
      try {
        const { success, errors } = await this.gqlService.setTrial$(value).toPromise();
        if (!success) {
          this.overlayService.error(errors);
        } else {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const currentSession = await Auth.currentSession();
          await new Promise<void>((res) => {
            cognitoUser.refreshSession(currentSession.getRefreshToken(), () => {
              res();
            });
          });

          await this.authService.setUserAttributes();
          this.authService.updatePermissions$.next();
        }
      } catch (e) {
        console.log('Unable to refresh Token', e);
      }

      const { sub, given_name, family_name, is_admin } = this.authQuery.getValue();
      const trial = this.trialsQuery.getEntity(value);
      if (is_admin && trial) {
        const loggedInUser = await this.gqlService.loggedInUser$().toPromise();
        if (!loggedInUser.success) {
          this.overlayService.error(loggedInUser.errors);
        }
        const { success, errors } = await this.trialUserService.addToTrial({
          given_name: loggedInUser?.data?.given_name || given_name,
          role: RoleType.ROLE_ADMIN,
          organization_id:
            loggedInUser?.data?.organization_id || trial.sponsor_organization?.id || '',
          family_name: loggedInUser?.data?.family_name || family_name,
          title: loggedInUser?.data?.title || 'Administrator',
          department: loggedInUser?.data?.department || [],
          trial_id: value,
          user_id: sub,
        });
        if (!success) {
          this.overlayService.error(errors);
        }
      }

      if (ref) {
        ref.close();
      }
    }
  }
}
