import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  forwardRef,
  Output,
  EventEmitter,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { OverlayService } from '@services/overlay.service';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlComponent } from '@components/form-inputs/base-form-control';
import { DriverPatientGroup } from '@services/gql.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { PatientCurveGroupComponent } from '../../pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-curve-group/patient-curve-group.component';
import { PatientsService } from '../../pages/forecast-accruals-page/tabs/forecast/drivers/patients/state/patients.service';
import { PatientCurveService } from '../../pages/forecast-accruals-page/tabs/forecast/patient-curve/patient-curve.service';
import { PatientCurveQuery } from '../../pages/forecast-accruals-page/tabs/forecast/patient-curve/patient-curve.query';
import { FullSettings } from '../../pages/forecast-accruals-page/tabs/forecast/category/category.query';

@UntilDestroy()
@Component({
  selector: 'aux-patient-dropdown',
  templateUrl: './aux-patient-dropdown.component.html',
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => AuxPatientDropdownComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => AuxPatientDropdownComponent),
      multi: true,
    },
  ],
})
export class AuxPatientDropdownComponent
  extends BaseFormControlComponent
  implements AfterViewInit, OnInit {
  @Input() isCurve = false;

  @Input() label = '';

  @Input() placeholder = '';

  @Input() firstRender = false;

  @Input() disabled = false;

  @Input() selectedId?: string | null = '';

  @Input() showError = false;

  @Input() set categoryId(id: string) {
    this.getCategoryId = id;
  }

  @Input() set options(data: any) {
    this.data = data;
    if (this.firstRender) {
      this.selectCurve(data[0]);
    }
  }

  @Output() customChange = new EventEmitter<FullSettings | null>();

  getCategoryId = '';

  optionCount = 0;

  isOpen = false;

  getPx = 0;

  PatientCurveGroup: any[] = [];

  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  data: any[] = [];

  highlightedCurve: number | null = null;

  highlightedPatient: number | null = null;

  selected = '';

  selectedFirstIndex: number | null = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private overlayService: OverlayService,
    private patientService: PatientsService,
    private patientCurveService: PatientCurveService,
    private patientCurveQuery: PatientCurveQuery
  ) {
    super();
  }

  ngAfterViewInit() {
    this.getPx = document.getElementById('wOverlay')?.clientWidth || 0;
  }

  ngOnInit() {
    const index = this.data.findIndex((x) => {
      return x?.driver_setting_id === this.selectedId;
    });

    const el = index > -1 ? this.data[index] : null;

    if (el) {
      this.selected = el.name;
      this.selectedFirstIndex = index;
    }
  }

  openList() {
    this.isOpen = true;

    if (this.firstRender) {
      this.highlightedCurve = 0;
      this.highlightedPatient = 0;
    } else {
      this.highlightedPatient = this.data.findIndex((x) => x.name === this.placeholder);

      this.highlightedCurve = this.highlightedPatient;
    }

    if (this.selectedFirstIndex != null) {
      this.highlightedPatient = this.selectedFirstIndex;
      this.highlightedCurve = this.selectedFirstIndex;
    } else {
      this.highlightedPatient = 0;
    }
    this.cdr.detectChanges();
  }

  closeList() {
    this.isOpen = false;
    this.highlightedCurve = null;
    this.highlightedPatient = null;
    this.cdr.detectChanges();
  }

  highlightCurve(index: number): void {
    this.highlightedCurve = index;
  }

  editCurve(item: any = null) {
    let params;
    if (!item) {
      params = {
        PatientCurveGroup: this.data?.filter((x: any) => !x.is_blended),
      };
    } else {
      params = {
        PatientCurveGroup: this.data?.filter((x: any) => !x.is_blended),
        patientData: item,
      };
    }

    this.overlayService.open({ content: PatientCurveGroupComponent, data: params });
    this.selected = '';
    this.closeList();
  }

  async selectCurve(item: any) {
    const index = this.highlightedCurve;
    if (index != null || item) {
      this.selected = item.name;
      this.selectedFirstIndex = index;
    }
    const newData = { ...item, id: item.patient_group_id, categoryId: this.getCategoryId };
    this.customChange.next(newData);

    this.closeList();
  }

  async deleteCurve(driverPatientGroup: DriverPatientGroup) {
    if (!driverPatientGroup) {
      return;
    }
    let cannotDeleteMessage = `${driverPatientGroup.name} cannot be deleted because it is being used in other areas of the application.<br>Please first remove the dependencies below and then ${driverPatientGroup.name} can be successfully deleted.<br>`;
    const forecastedOrgMessage = `<br>Forecast Methodology:<br><ul class="list-disc pl-4">${driverPatientGroup.organizations_with_forecasts
      .map(
        ({ name }) =>
          `<li><a class="aux-link" href=/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}>${name}</a></li>`
      )
      .join('')}</ul>`;

    let blendedCurves: any[] = [];
    if (!driverPatientGroup.is_blended) {
      const patientCurves = this.patientCurveQuery.getAll();

      blendedCurves = patientCurves.filter((el) =>
        el.constituent_patient_groups.find((c) => c.id === driverPatientGroup.patient_group_id)
      );
    }

    if (
      driverPatientGroup.is_blended &&
      driverPatientGroup.organizations_with_forecasts.length > 0
    ) {
      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Patient Curve',
        message: (cannotDeleteMessage += forecastedOrgMessage),
        okBtnText: 'Remove',
        okBtnDisabled: true,
      });

      await resp.afterClosed$.toPromise();
    } else if (
      !driverPatientGroup.is_blended &&
      (driverPatientGroup.organizations_with_forecasts.length > 0 || blendedCurves.length > 0)
    ) {
      let list = `<br>Blended Curves:<br><ul class="list-disc pl-4">${blendedCurves
        .map(
          ({ name }) =>
            `<li><a class="aux-link" href="/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}">${name}</a></li>`
        )
        .join('')}</ul>`;
      if (driverPatientGroup.organizations_with_forecasts.length) {
        list += forecastedOrgMessage;
      }
      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Patient Curve',
        message: (cannotDeleteMessage += list),
        okBtnText: 'Remove',
        okBtnDisabled: true,
      });

      await resp.afterClosed$.toPromise();
    } else {
      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Patient Curve',
        message: `Are you sure you want to remove Patient Curve ${driverPatientGroup.name}?`,
        okBtnText: 'Remove',
      });
      const event = await resp.afterClosed$.toPromise();
      if (event.data?.result) {
        this.selected = '';
        await this.patientCurveService.remove(driverPatientGroup);
      }
    }
    this.selected = '';
  }

  onClear(e: Event) {
    e.preventDefault();
    this.selected = '';
    this.selectedFirstIndex = null;

    this.customChange.next(null);
  }
}
