import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '@services/can-deactivate.guard';
import { Flags, LaunchDarklyService } from '@services/launch-darkly.service';
import { LoginComponent } from './modules/auth/login/login.component';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/auth/reset-password/reset-password.component';
import { ConfirmationComponent } from './modules/auth/confirmation/confirmation.component';

import { AuthGuard } from './modules/auth/auth.guard';

import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { ScenarioComponent } from './pages/scenario/scenario.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { ScenarioManagerComponent } from './pages/scenario/scenario-manager/scenario-manager.component';
import { DesignSystemComponent } from './pages/design-system/design-system.component';
import { PortfolioDashboardComponent } from './pages/portfolio-dashboard/portfolio-dashboard.component';
import { DesignSystemRoutingModule } from './pages/design-system';
import { AuditHistoryComponent } from './pages/audit-history/audit-history.component';
import { buildBudgetRoutes } from './pages/budget-page/budget-page.routing';
import { buildPatientRoutes } from './pages/patients-page/patients-page.routing';
import { buildClosingPageRoutes } from './pages/closing-page/closing-page.routing';
import { buildVendorPaymentsRoutes } from './pages/vendor-payments-page/vendor-payments-page.routing';
import { buildSettingsRoutes } from './pages/settings/settings.routing';
import { ROUTING_PATH } from './app-routing-path.const';
import { buildSitesRoutes } from './pages/sites-page/sites-page.routing';
import { RiskAnalyticsComponent } from './pages/risk-analytics/risk-analytics.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { buildForecastRoutes } from './pages/forecast-accruals-page/forecast.routing';
import { buildAccountRoutes } from './pages/account/account.routing';

const buildRoutes = (featureFlags: Flags | null): Routes => [
  {
    path: ROUTING_PATH.LOGIN,
    component: LoginComponent,
  },
  {
    path: ROUTING_PATH.FORGOT_PASSWORD,
    component: ForgotPasswordComponent,
  },
  {
    path: ROUTING_PATH.CONFIRMATION,
    component: ConfirmationComponent,
  },
  {
    path: ROUTING_PATH.RESET_PASSWORD,
    component: ResetPasswordComponent,
  },
  {
    path: ROUTING_PATH.ONBOARDING.INDEX,
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('./modules/onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: ROUTING_PATH.HOME,
        component: PortfolioDashboardComponent,
      },
      buildBudgetRoutes(featureFlags),
      buildClosingPageRoutes(featureFlags),
      buildVendorPaymentsRoutes(featureFlags),
      buildForecastRoutes(featureFlags),
      {
        path: ROUTING_PATH.MANAGER,
        component: ScenarioComponent,
        children: [
          { path: '', component: ScenarioManagerComponent },
          { path: '**', redirectTo: '' },
        ],
      },
      {
        path: ROUTING_PATH.AUDIT_HISTORY,
        component: AuditHistoryComponent,
      },
      {
        path: ROUTING_PATH.DOCUMENTS,
        component: DocumentsComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      buildSitesRoutes(featureFlags),
      buildPatientRoutes(featureFlags),
      buildSettingsRoutes(featureFlags),
      {
        path: ROUTING_PATH.RISK_ANALYTICS,
        component: RiskAnalyticsComponent,
      },
      {
        path: ROUTING_PATH.DASHBOARD,
        component: DashboardComponent,
      },
      {
        path: 'design-system',
        component: DesignSystemComponent,
        loadChildren: () => DesignSystemRoutingModule,
      },
      buildAccountRoutes(featureFlags),
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot([])],
  exports: [RouterModule],
})
export class AppRoutingModule {
  private appRouteList: string[] = [];

  constructor(private router: Router, private launchDarklyService: LaunchDarklyService) {
    this.launchDarklyService.flags$.subscribe((flags: Flags) =>
      this.updateRoutesAndInitNavigation(buildRoutes(flags))
    );
  }

  private getAppRouteList(parent: String, config: Routes): void {
    for (let i = 0; i < config.length; i++) {
      const route = config[i];
      this.appRouteList = [...this.appRouteList, `${parent}/${route.path}`];
      if (route.children) {
        const currentPath = route.path ? `${parent}/${route.path}` : parent;
        this.getAppRouteList(currentPath, route.children);
      }
    }
  }

  private updateAppRouteList(): void {
    this.appRouteList = [];
    this.getAppRouteList('', this.router.config);
  }

  private updateRoutesAndInitNavigation(routes: Routes): void {
    this.router.resetConfig([...routes]);
    this.updateAppRouteList();

    if (this.router.url !== '/' && !this.appRouteList.includes(this.router.url)) {
      this.router.navigate([this.router.url]);
    }
  }
}
