import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgControlComponent } from './ag-control.component';

@Component({
  template: `<div *ngIf="formGroup$ | async" [formGroup]="$any(formGroup$ | async)">
    <aux-input
      class="w-full"
      *ngIf="isReady$ | async"
      [formControl]="formControl"
      [placeholder]="'Description'"
      (change)="changeHandler()"
    ></aux-input>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgInputComponent extends AgControlComponent {}
