<ng-template #specificationsTemp>
  <button
    type="button"
    class="text-sm btn btn--blue"
    *auxAuthorize="{ sysAdminsOnly: true }"
    (click)="onSpecUploadClick()"
  >
    <span inlineSVG="file-upload.svg"></span>
    <span class="ml-2">Upload Specification</span>
  </button>
</ng-template>

<div class="mt-4"></div>

<div
  *ngFor="let cat of specificationCategoryQuery.categories$ | async; first as first; last as last"
  class="max-w-3xl"
>
  <div
    class="bg-aux-gray-light px-5 py-3 border text-xxs font-bold flex justify-between items-center sticky top-0"
    [ngClass]="{
      'rounded-t-md': first,
      'rounded-b-md': last
    }"
  >
    <div>{{ cat.name }}</div>
    <aux-menu #cat_menu>
      <button
        class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm w-full flex items-center focus:outline-none"
        role="menuitem"
        (click)="onShowForm(cat.id, cat); cat_menu.close()"
      >
        <plus-outline-icon class="mr-2" [size]="20"></plus-outline-icon>
        <span>Add new specification</span>
      </button>
    </aux-menu>
  </div>

  <div class="text-sm">
    <ng-container *ngFor="let spec of cat.specifications">
      <!--
      <div
        *ngIf="spec.input_type === 'INPUT_SINGLEVALUE' && (showForm | async) !== spec.id"
        class="border grid grid-cols-12 px-5 py-3"
       >
        <div class="col-span-9">
          {{ spec.name }}
        </div>
        <div class="col-span-3 flex justify-between items-center">
          <div>
            {{ spec.settings[0]?.setting_value }}
          </div>
          <aux-menu #single_menu>
            <button
              type="button"
              class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm w-full flex items-center rounded-md"
              (click)="onShowForm(spec.id, cat); single_menu.close()"
            >
              <span
                inlineSVG="edit.svg"
                class="w-5 h-5 mr-2 flex items-center justify-center"
              ></span>
              Edit specification
            </button>
            <button
              type="button"
              class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm w-full flex items-center rounded-md"
              (click)="onRemoveSpec(spec.id, cat); single_menu.close()"
            >
              <trash-solid-icon [size]="20" class="mr-2 w-5 h-5"></trash-solid-icon>
              Remove specification
            </button>
          </aux-menu>
        </div>
      </div>
       -->

      <div
        *ngIf="spec.input_type === 'INPUT_MULTIVALUE' && (showForm | async) !== spec.id"
        class="border grid grid-cols-12 px-5 py-3"
      >
        <div class="col-span-12 font-medium flex items-center justify-between">
          <div>{{ spec.name }}:</div>
          <aux-menu #multi_menu>
            <button
              type="button"
              class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm w-full flex items-center rounded-md"
              (click)="onShowForm(spec.id, cat); multi_menu.close()"
            >
              <pencil-solid-icon [size]="20" class="w-5 h-5 text-aux-blue"></pencil-solid-icon>
            </button>
            <button
              type="button"
              class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm w-full flex items-center rounded-md"
              (click)="onRemoveSpec(spec.id, cat); multi_menu.close()"
            >
              <trash-solid-icon [size]="20" class="w-5 h-5 text-aux-error"></trash-solid-icon>
            </button>
          </aux-menu>
        </div>
        <hr class="col-span-12 my-2" />
        <ng-container *ngFor="let setting of spec.settings">
          <div class="col-span-9">{{ setting.setting_key || setting.setting_value }}</div>
          <div class="col-span-3 tabular-nums">
            {{ setting.setting_key ? setting.setting_value : '' }}
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="(showForm | async) === spec.id" [ngTemplateOutlet]="form"></ng-container>
    </ng-container>

    <ng-container *ngIf="(showForm | async) === cat.id" [ngTemplateOutlet]="form"></ng-container>
    <ng-template #form>
      <form
        [formGroup]="fg"
        (ngSubmit)="onSaveForm(cat)"
        class="grid grid-cols-12 px-5 py-3 gap-5 border"
      >
        <!--
         <ng-select
           [clearable]="false"
           [searchable]="false"
           [items]="specTypes"
           formControlName="specType"
           bindValue="value"
           class="w-32 col-span-3 select select__big"
           (change)="onSpecTypeChange($event)"
         ></ng-select>
         <div class="col-span-9"></div>


         <div
           *ngIf="$any(fg.get('specType')).value === specTypesEnum.INPUT_SINGLEVALUE"
           class="grid grid-cols-12 gap-5 col-span-12"
         >
           <div class="col-span-4">
             <aux-input placeholder="Spec Name" formControlName="specName" auxFormError></aux-input>
           </div>
           <div formArrayName="settings" class="col-span-8">
             <div [formGroupName]="0" class="grid grid-cols-12 gap-5">
               <div class="col-span-6">
                 <aux-input
                   placeholder="Spec Value"
                   formControlName="value"
                   auxFormError
                 ></aux-input>
               </div>
               <div class="col-span-6">
                 <ng-select
                   [clearable]="false"
                   [searchable]="false"
                   [items]="specSetTypes"
                   formControlName="type"
                   bindValue="value"
                   placeholder="Spec Type"
                   class="col-span-3 select select__big mt-0"
                 ></ng-select>
               </div>
             </div>
           </div>
         </div>
          -->
        <ng-container *ngIf="$any(fg.get('specType')).value === specTypesEnum.INPUT_MULTIVALUE">
          <div class="col-span-4">
            <aux-input placeholder="Spec Name" formControlName="specName" auxFormError></aux-input>
          </div>
          <div class="col-span-8"></div>
          <ng-container
            formArrayName="settings"
            *ngFor="let set of $any(fg.get('settings'))['controls']; index as i"
          >
            <div [formGroupName]="i" class="flex items-center col-span-12 grid grid-cols-12 gap-5">
              <div class="col-span-4">
                <aux-input
                  [placeholder]="'Set #' + (i + 1) + ' Name'"
                  formControlName="label"
                  auxFormError
                ></aux-input>
              </div>
              <div class="col-span-4">
                <aux-input
                  [placeholder]="'Set #' + (i + 1) + ' Value'"
                  formControlName="value"
                  auxFormError
                ></aux-input>
              </div>
              <div class="col-span-4">
                <ng-select
                  [clearable]="false"
                  [searchable]="false"
                  [items]="specSetTypes"
                  formControlName="type"
                  bindValue="value"
                  [placeholder]="'Set #' + (i + 1) + ' Type'"
                  class="col-span-3 select select__big mt-0"
                ></ng-select>
              </div>
            </div>
          </ng-container>

          <div class="col-span-12 flex space-x-4">
            <button
              type="button"
              class="text-sm font-normal aux-link focus:outline-none"
              (click)="onAddNewLine()"
            >
              Add new line
            </button>
            <button
              type="button"
              class="text-sm font-normal aux-link focus:outline-none"
              (click)="onRemoveLastLine()"
              *ngIf="$any(fg.get('settings')).value.length > 1"
            >
              Remove last line
            </button>
          </div>
        </ng-container>

        <div class="flex space-x-4 col-span-12">
          <button class="text-sm font-normal btn btn--blue w-32" type="submit">
            <span class="spinner w-6 h-6 mr-3" *ngIf="loading$ | async"></span>
            <span>Save</span>
          </button>
          <button
            class="text-sm font-normal aux-link focus:outline-none"
            type="button"
            (click)="hideForm()"
          >
            Cancel
          </button>
        </div>
      </form>
    </ng-template>
  </div>
</div>
