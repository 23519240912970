<div class="flex justify-between items-center my-4">
  <div class="flex items-center">
    <div class="mr-2">Site:</div>
    <ng-select
      class="w-72 tabular-nums"
      [multiple]="true"
      [clearable]="false"
      (change)="onSiteSelected($event)"
      [formControl]="selectedSites"
      placeholder="ALL"
    >
      <ng-option
        [value]="site.id"
        *ngFor="let site of sitesQuery.selectSortedListBy('site_no') | async"
      >
        {{ site.site_no }}
      </ng-option>
    </ng-select>
  </div>
  <aux-currency-checkboxes
    [selectedCurrencies$]="$any(selectedCurrencies$)"
  ></aux-currency-checkboxes>
</div>
<ag-grid-angular
  *ngIf="showGrid$ | async"
  id="patientBudgetGrid"
  class="ag-theme-aux"
  [gridOptions]="$any(gridOptions$ | async)"
  [rowData]="$any(gridData$ | async)"
  (gridReady)="onGridReady($event)"
  (firstDataRendered)="onDataRendered()"
  (gridSizeChanged)="sizeColumnsToFit()"
>
</ag-grid-angular>
