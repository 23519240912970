import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import {
  ApprovalType,
  EntityType,
  EventType,
  GqlService,
  InvoiceStatus,
  PermissionType,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroupDirective } from '@angular/forms';
import { AuthService } from '@models/auth/auth.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { AuthQuery } from '@models/auth/auth.query';
import { Utils } from '@services/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NewInvoiceDialogComponent } from '../new-invoice-dialog/new-invoice-dialog.component';
import { PurchaseOrdersQuery } from '../../purchase-orders/state/purchase-orders.query';
import { InvoiceModel } from '../state/invoice.model';
import { InvoiceService } from '../state/invoice.service';
import { InvoiceStore } from '../state/invoice.store';
import { WORKFLOW_NAMES } from '../../../../closing-page/tabs/quarter-close/close-quarter-check-list';
import { WorkflowQuery } from '../../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { MainQuery } from '../../../../../layouts/main-layout/state/main.query';

@UntilDestroy()
@Component({
  selector: 'aux-invoice',
  templateUrl: 'invoice.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceComponent implements OnInit {
  invoiceForm = this.formBuilder.group({
    vendor_id: '',
    invoice_no: '',
    invoice_date: '',
    invoice_total: '',
    investigator_total: '',
    services_total: '',
    discount_total: '',
    pass_thru_total: '',
  });

  @ViewChild('invoiceFormRef') invoiceFormRef!: FormGroupDirective;

  isInvoiceFinalized$ = this.workflowQuery.getLockStatusByWorkflowName(WORKFLOW_NAMES.INVOICES);

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  userHasCategorizeInvoicePermission = false;

  invoiceLockTooltip$ = this.workflowQuery.invoiceLockTooltip$;

  filteredPONumbers$ = this.purchaseOrdersQuery.selectAll().pipe(
    map((value) => {
      return value.filter((po) => {
        return po.organization_id === this.invoice.organization.id;
      });
    })
  );

  filteredOrganizations$ = this.vendorsQuery.allVendors$;

  editMode$ = new BehaviorSubject(false);

  @Input() set invoiceData(inv: InvoiceModel) {
    this.invoice = inv;
    this.invoiceForm.patchValue({
      vendor_id: this.invoice.organization.id,
      invoice_no: this.invoice.invoice_no,
      invoice_date: this.invoice.invoice_date,
      invoice_total: Utils.currencyFormatter(
        this.invoice.expense_amounts.invoice_total.value
      ).toString(),
      investigator_total: Utils.currencyFormatter(
        this.invoice.expense_amounts.investigator_total.value
      ).toString(),
      services_total: Utils.currencyFormatter(
        this.invoice.expense_amounts.services_total.value
      ).toString(),
      discount_total: Utils.currencyFormatter(
        this.invoice.expense_amounts.discount_total.value
      ).toString(),
      pass_thru_total: Utils.currencyFormatter(
        this.invoice.expense_amounts.pass_thru_total.value
      ).toString(),
    });
    this.cloneInvoice();
    if (this.editMode) {
      this.editMode$.next(true);
    }
  }

  invoice: InvoiceModel = {} as InvoiceModel;

  @Input() set editModeData(flag: boolean) {
    this.editMode = flag;
    this.editMode$.next(flag);
  }

  editMode = false;

  clonedInvoice: InvoiceModel = {} as InvoiceModel;

  invoiceStatusEnum = InvoiceStatus;

  update$ = new Subject();

  selectedPOReference = new FormControl(null);

  constructor(
    private formBuilder: FormBuilder,
    public vendorsQuery: OrganizationQuery,
    public purchaseOrdersQuery: PurchaseOrdersQuery,
    public authQuery: AuthQuery,
    private invoiceService: InvoiceService,
    private overlayService: OverlayService,
    private authService: AuthService,
    private ld: LaunchDarklyService,
    private gqlService: GqlService,
    private invoiceStore: InvoiceStore,
    private workflowQuery: WorkflowQuery,
    private mainQuery: MainQuery
  ) {}

  cloneInvoice() {
    this.clonedInvoice = JSON.parse(JSON.stringify(this.invoice));
  }

  onEditMode() {
    if (this.invoice) {
      this.editMode$.next(true);
      this.filteredPONumbers$ = this.purchaseOrdersQuery.selectAll().pipe(
        map((value) => {
          return value.filter((po) => {
            return po.organization_id === this.invoice.organization.id;
          });
        })
      );
      this.filteredOrganizations$ = this.vendorsQuery.allVendors$;
    }
  }

  onSubmit() {}

  async onEditSave() {
    const newExpenseAmounts = {
      invoice_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.invoice_total.toString()),
        type: this.clonedInvoice.expense_amounts.invoice_total.type,
      },
      pass_thru_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.pass_thru_total.toString()),
        type: this.clonedInvoice.expense_amounts.pass_thru_total.type,
      },
      services_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.services_total.toString()),
        type: this.clonedInvoice.expense_amounts.services_total.type,
      },
      discount_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.discount_total.toString()),
        type: this.clonedInvoice.expense_amounts.discount_total.type,
      },
      investigator_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.investigator_total.toString()),
        type: this.clonedInvoice.expense_amounts.investigator_total.type,
      },
    };
    // only use invoiceForm values if in edit mode
    if (this.editMode) {
      this.clonedInvoice.expense_amounts = newExpenseAmounts;
    }

    const invoiceTotal = this.clonedInvoice.expense_amounts.invoice_total;
    const otherTotals = Object.values(this.clonedInvoice.expense_amounts)
      .filter((x) => x.type !== invoiceTotal.type)
      .reduce((acc, val) => acc + +val.value, 0);

    if (+otherTotals.toFixed(2) !== +invoiceTotal.value.toFixed(2)) {
      this.overlayService.error([
        'sum of',
        '- Investigator',
        '- Services',
        '- Discount',
        '- Pass-thru',
        'must be equal to Invoice Total',
      ]);
      return false;
    }
    await this.invoiceService.update({
      ...this.clonedInvoice,
      expense_amounts: this.clonedInvoice.expense_amounts,
      invoice_date: this.invoiceForm.value.invoice_date,
      invoice_no: this.invoiceForm.value.invoice_no,
      due_date: this.clonedInvoice.due_date,
      organization: {
        id: this.invoiceForm.value.vendor_id,
        name: this.vendorsQuery.getEntity(this.invoiceForm.value.vendor_id)?.name as string,
      },
    });
    return true;
  }

  onEditCancel() {
    this.cloneInvoice();
    this.editMode$.next(false);
  }

  async onInvoiceAccept() {
    this.clonedInvoice.invoice_status = InvoiceStatus.STATUS_APPROVED;
    await this.invoiceService.update(this.clonedInvoice);
  }

  onInvoiceComplete(e: Event) {
    this.clonedInvoice.invoice_status = e
      ? InvoiceStatus.STATUS_PENDING_APPROVAL
      : InvoiceStatus.STATUS_PENDING_REVIEW;
  }

  onVendorChange() {
    if (this.clonedInvoice.po_reference) {
      this.selectedPOReference.setErrors({ message: 'error' });
    }
    this.filteredPONumbers$ = this.purchaseOrdersQuery.selectAll().pipe(
      map((value) => {
        return value.filter((po) => {
          return po.organization_id === this.invoiceForm.value.vendor_id;
        });
      })
    );
  }

  onPoChange() {
    this.selectedPOReference.setErrors({});
  }

  openInvoiceModal() {
    this.overlayService.open({
      content: NewInvoiceDialogComponent,
      data: {
        mode: 'edit',
        id: this.invoice.id,
      },
    });
  }

  async onDeleteInvoice() {
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Invoice',
      message: `Are you sure that you want to remove Invoice ${
        this.invoiceForm.value.invoice_no
      } for ${this.vendorsQuery.getEntity(this.invoiceForm.value.vendor_id)?.name}?`,
      okBtnText: 'Remove',
    });

    const event = await resp.afterClosed$.toPromise();
    if (event.data?.result) {
      await this.invoiceService.remove(this.clonedInvoice);
    }
  }

  async onApprove(permission: string) {
    const loading = this.overlayService.loading();
    const auth = await this.authService.getLoggedInUser();
    const { success, data, errors } = await this.gqlService
      .approveRule$({
        approved: true,
        comments: '',
        permission,
        approval_type: ApprovalType.APPROVAL_INVOICE,
        entity_id: this.invoice.id,
        entity_type: EntityType.INVOICE,
        activity_details: '{}',
      })
      .toPromise();
    if (success && data) {
      this.invoiceStore.update(this.invoice.id, (state) => ({
        approvals: [
          ...state.approvals,
          { __typename: 'Approval', permission, aux_user_id: auth?.getSub() },
        ],
      }));
      this.overlayService.success();

      this.cloneInvoice();
    } else {
      this.overlayService.error(errors);
    }
    loading.close();
  }

  ngOnInit(): void {
    combineLatest([this.isInvoiceFinalized$, this.iCloseMonthsProcessing$])
      .pipe(untilDestroyed(this))
      .subscribe(([isInvoiceFinalized, iCloseMonthsProcessing]) => {
        if (isInvoiceFinalized || iCloseMonthsProcessing) {
          Object.keys(this.invoiceForm.controls).forEach((fieldName) => {
            this.invoiceForm.get(fieldName)?.disable();
          });
          this.selectedPOReference.disable();
        }
      });

    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CATEGORIZE_INVOICE],
      })
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.userHasCategorizeInvoicePermission = x;
      });
  }
}
