<div *ngIf="(mainQuery.select('fullPage') | async) === false" class="border-b border-gray-200">
  <nav class="flex -mb-px space-x-8" aria-label="Tabs">
    <ng-container *ngIf="{ tabs: tabs$ | async, activeIndex: activeIndex$ | async } as obj">
      <ng-container *ngFor="let tab of obj.tabs; index as index">
        <button
          *ngIf="tab.show ? (tab.show | async) : true"
          type="button"
          [routerLink]="tab.route"
          class="px-1 py-4 text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
          [ngClass]="{
            'border-aux-blue-light text-aux-blue-light': index === obj.activeIndex,
            'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
              index !== obj.activeIndex
          }"
        >
          {{ tab.label }}
        </button>
      </ng-container>
    </ng-container>
  </nav>
</div>
<div *ngIf="(mainQuery.select('fullPage') | async) === true" class="border-r border-gray-200">
  <nav class="flex -mb-px divide-x" aria-label="Tabs">
    <ng-container *ngIf="{ tabs: tabs$ | async, activeIndex: activeIndex$ | async } as obj">
      <ng-container *ngFor="let tab of obj.tabs; index as index">
        <button
          *ngIf="tab.show ? (tab.show | async) : true"
          type="button"
          [routerLink]="tab.route"
          class="px-4 my-4 text-sm font-medium whitespace-nowrap focus:outline-none"
          [ngClass]="{
            'font-bold text-aux-black': index === obj.activeIndex,
            'underline text-aux-blue hover:text-gray-700 hover:border-gray-300':
              index !== obj.activeIndex
          }"
        >
          {{ tab.label }}
        </button>
      </ng-container>
    </ng-container>
  </nav>
</div>
