<div [ngClass]="{
  'py-7': isHorizontalView,
  'px-7': !isHorizontalView
  }">
  <div class="border-gray-300" [ngClass]="{
     'border-t w-4/5': isHorizontalView,
     'border-l h-screen': !isHorizontalView}">

  </div>
</div>
