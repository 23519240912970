<div
  class="min-h-screen bg-aux-gray-light flex flex-col justify-between py-12 lg:px-8 my-0 mx-auto font-condensed"
>
  <div class="flex flex-col justify-center">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="rounded shadow-md bg-white p-14">
        <img class="mb-10 mx-auto w-3/4" src="assets/img/logo-black.png" alt="" />

        <div *ngIf="errorMessage" class="my-5 p-5 font-medium bg-aux-error text-white">
          {{ errorMessage }}
        </div>

        <form
          *ngIf="showLoginForm"
          [formGroup]="signInForm"
          (ngSubmit)="submitSignInForm()"
          novalidate
          autocomplete="off"
        >
          <div>
            <label for="email">Email</label>
            <input
              type="text"
              class="input"
              id="email"
              placeholder="you@example.com"
              formControlName="email"
            />
          </div>

          <div class="mt-5">
            <label for="password">Password</label>
            <input
              type="password"
              class="input"
              id="password"
              placeholder="Password"
              formControlName="password"
            />
          </div>

          <div class="mt-3">
            <a [routerLink]="[forgotPasswordLink]" class="aux-link aux-link--black">
              Forgot password?
            </a>
          </div>

          <aux-checkbox [id]="'remember_me'" class="mt-3 text-sm"
            >Remember this device</aux-checkbox
          >

          <button class="btn btn--blue text-xl w-full my-8" type="submit">
            <span class="spinner w-6 h-6 mr-3" *ngIf="loading$ | async"></span>
            Log in <chevron-right-solid-icon></chevron-right-solid-icon>
          </button>
        </form>

        <form
          class="space-y-5"
          *ngIf="!showLoginForm"
          [formGroup]="newPasswordForm"
          (ngSubmit)="submitNewPasswordForm()"
          novalidate
          autocomplete="off"
        >
          <div>
            <label for="firstName">First Name</label>
            <input
              type="text"
              class="input"
              id="firstName"
              placeholder="First Name"
              formControlName="firstName"
            />
          </div>

          <div>
            <label for="lastName">Last Name</label>
            <input
              type="text"
              class="input"
              id="lastName"
              placeholder="Last Name"
              formControlName="lastName"
            />
          </div>

          <div>
            <label for="newPassword">New Password</label>
            <input
              type="password"
              class="input"
              id="newPassword"
              placeholder="New Password"
              formControlName="password"
            />
          </div>

          <button class="btn btn--blue w-full" type="submit">
            <span class="spinner w-6 h-6 mr-3" *ngIf="loading$ | async"></span>
            <span>Confirm</span>
          </button>
        </form>
      </div>

      <div class="mb-5 mt-10 space-x-2 text-center">
        <a href="#" class="aux-link no-underline">Privacy Policy</a>
        <a href="#" class="aux-link no-underline">Term of Use</a>
      </div>

      <p class="text-center">©️{{ year }} Auxilius Inc., All rights reserved.</p>
    </div>
  </div>
  <div class="bg-aux-gray-light text-aux-gray-dark-100 flex items-center justify-end py-2 pr-4">
    App Version {{ appInitService.APP_VERSION }}
  </div>
</div>
