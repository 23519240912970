<ng-container *ngIf="sitesQuery.selectLoading() | async">
  <div class="mt-10 text-xl font-semibold">Current Sites</div>

  <ng-template #loader>
    <div class="p-5 border border-blue-200 rounded-md shadow h-52">
      <div class="flex animate-pulse h-full flex-col justify-between">
        <div class="flex-1 py-1 space-y-2">
          <div class="w-1/6 h-2.5 bg-blue-200 rounded"></div>
          <div class="space-y-2">
            <div class="w-4/6 h-3 bg-blue-200 rounded"></div>
            <div class="w-2/6 h-2 bg-blue-200 rounded"></div>
            <div class="w-3/6 h-2 bg-blue-200 rounded"></div>
            <div class="w-1/6 h-2 bg-blue-200 rounded"></div>
          </div>
        </div>

        <div class="w-1/5 h-3 bg-blue-200 rounded"></div>
      </div>
    </div>
  </ng-template>

  <div class="grid gap-5 grid-cols-4 mt-8">
    <ng-container [ngTemplateOutlet]="loader"></ng-container>
    <ng-container [ngTemplateOutlet]="loader"></ng-container>
    <ng-container [ngTemplateOutlet]="loader"></ng-container>
    <ng-container [ngTemplateOutlet]="loader"></ng-container>
  </div>
</ng-container>

<ng-container *ngIf="!$any(sitesQuery.selectLoading() | async)">
  <ng-container *ngVar="activeSite$ | async as activeSite">
    <div class="flex items-center space-x-4">
      <div class="text-xl font-semibold">Current Sites</div>
      <button
        class="text-sm btn btn--blue"
        *auxAuthorize="{ roles: ['ROLE_ADMIN'] }"
        (click)="onNewSite()"
      >
        <plus-outline-icon></plus-outline-icon>
        Add New Site
      </button>
      <button
        class="text-sm btn btn--blue"
        *auxAuthorize="{ roles: ['ROLE_ADMIN'] }"
        (click)="onAddMultipleSites()"
      >
        <plus-outline-icon></plus-outline-icon>
        Add Multiple Sites
      </button>
    </div>

    <ng-container *ngVar="newSiteMode$ | async as newSiteMode"> </ng-container>

    <ng-container *ngVar="sitesQuery.selectAll() | async | groupBy: 'country' as sites">
      <ng-container *ngIf="sites.length == 0">
        <div class="mt-6">There are currently no sites associated with this study.</div>
      </ng-container>

      <ng-container *ngFor="let siteObj of sites">
        <div class="text-base mt-8">{{ siteObj.key | country }}</div>

        <hr class="my-4" />

        <div class="grid gap-5 grid-cols-4">
          <div
            *ngFor="let site of siteObj.value"
            class="rounded-md border p-5 flex flex-col justify-between h-52"
          >
            <div class="space-y-1 break-words">
              <div class="text-xxs font-medium tabular-nums"># {{ site.site_no }}</div>
              <div class="text-base font-bold text-aux-blue">{{ site.name }}</div>
              <div class="text-sm">
                {{ site.state }}{{ site.state && site.country ? ',' : '' }}
                {{ site.country | country }}
              </div>
              <div class="text-sm">
                {{ site.currency }}
              </div>
              <div
                *ngIf="
                  site.investigator &&
                  (site.investigator.given_name || site.investigator.family_name)
                "
                class="text-sm"
              >
                Investigator: {{ site.investigator.given_name }} {{ site.investigator.family_name }}
              </div>
              <div class="text-sm" *ngIf="site.target_patients">
                {{ site.target_patients }} Patients
              </div>
            </div>

            <div class="flex space-x-2 text-sm font-medium items-center">
              <button class="btn px-2 h-8" type="button" (click)="onEdit(site)">
                <pencil-solid-icon [size]="16" class="text-aux-blue"></pencil-solid-icon>
              </button>
              <button class="btn px-2 h-8" type="button" (click)="removeSite(site)">
                <trash-solid-icon [size]="16" class="text-aux-error"></trash-solid-icon>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
