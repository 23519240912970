import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { flatten, sortBy } from 'lodash-es';
import { TimelineQuery } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.query';

import { FullSettings } from '../category/category.query';

interface ITimelineMilestone {
  name: string;
  id: string;
  start_date: string;
  end_date: string;
}
interface Phase {
  name: string;
  cat_id: string;
  firstMilestone: ITimelineMilestone | null;
  lastMilestone: ITimelineMilestone | null;
  milestones: ITimelineMilestone[];
}

@Component({
  templateUrl: './forecast-timeline-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastTimelineDialogComponent {
  phases: Phase[] = [];

  selectedPhase: Phase | null = null;

  selectedCustomStartMilestone: ITimelineMilestone | null = null;

  selectedCustomEndMilestone: ITimelineMilestone | null = null;

  allMilestones: ITimelineMilestone[] = [];

  constructor(
    public ref: CustomOverlayRef<
      { phaseID: string | null; startMilID: string | null; endMilID: string | null } | null,
      { settings: FullSettings }
    >,
    private timelineQuery: TimelineQuery
  ) {
    const { items } = this.timelineQuery.getValue();

    const cats = items.reduce((acc, val) => {
      acc[val.milestone.milestone_category_id] ||= { name: '', milestones: [] };
      acc[val.milestone.milestone_category_id].milestones.push({
        ...val.milestone,
        start_date: val.actual_start_date || val.revised_start_date || val.contract_start_date,
        end_date: val.actual_end_date || val.revised_end_date || val.contract_end_date,
      });
      acc[val.milestone.milestone_category_id].name = val.milestone.milestone_category.name;
      return acc;
    }, {} as Record<string, { name: string; milestones: ITimelineMilestone[] }>);

    this.phases = Object.entries(cats)
      .filter(([, val]) => val.milestones.length >= 1)
      .map(([cat_id, { milestones, name }]) => {
        return {
          cat_id,
          firstMilestone: milestones[0],
          lastMilestone: milestones[milestones.length - 1],
          milestones,
          name,
        } as Phase;
      })
      .filter((phase) => phase.milestones.every((mil) => !!mil));

    this.allMilestones = sortBy(flatten([...this.phases.map((x) => x.milestones)]), [
      'start_date',
      'end_date',
      'name',
    ]);

    this.phases.push({
      name: 'Custom',
      cat_id: '',
      firstMilestone: null,
      lastMilestone: null,
      milestones: [],
    });

    if (this.ref.data?.settings) {
      const { milestone_category, start_milestone, end_milestone } = this.ref.data.settings;
      if (milestone_category) {
        const phase = this.phases.find((x) => x.cat_id === milestone_category.id);
        if (phase) {
          this.selectedPhase = phase;
        }
      } else {
        this.selectedPhase = this.phases[this.phases.length - 1];

        const sm = this.allMilestones.find((x) => x.id === start_milestone?.id);
        const em = this.allMilestones.find((x) => x.id === end_milestone?.id);

        if (start_milestone && sm) {
          this.selectedCustomStartMilestone = sm;
        }
        if (end_milestone && em) {
          this.selectedCustomEndMilestone = em;
        }
      }
    }
  }

  onSave() {
    if (this.selectedPhase) {
      if (this.selectedPhase.name !== 'Custom') {
        const { cat_id, firstMilestone, lastMilestone } = this.selectedPhase;
        if (firstMilestone && lastMilestone) {
          this.ref.close({
            phaseID: cat_id,
            startMilID: firstMilestone.id,
            endMilID: lastMilestone.id,
          });
          return;
        }
      }

      if (
        this.selectedPhase.name === 'Custom' &&
        this.selectedCustomStartMilestone &&
        this.selectedCustomEndMilestone
      ) {
        this.ref.close({
          phaseID: null,
          startMilID: this.selectedCustomStartMilestone.id,
          endMilID: this.selectedCustomEndMilestone.id,
        });
        return;
      }
    }

    this.ref.close(null);
  }
}
