import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { listWorkflowDetailsQuery } from '@services/gql.service';

export interface WorkflowProperty {
  route: string;
  locked: boolean;
}

export interface Workflow extends Omit<listWorkflowDetailsQuery, 'properties'> {
  properties: WorkflowProperty;
  updatedAuthorFullName: string;
}

export interface WorkflowState extends EntityState<Workflow> {}

export type WorkflowModel = getEntityType<WorkflowState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'workflow' })
export class WorkflowStore extends EntityStore<WorkflowState> {
  constructor() {
    super({});
  }
}
