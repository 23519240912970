import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import {
  CellClickedEvent,
  CellEditingStartedEvent,
  ColumnApi,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ProcessCellForExportParams,
  RowNode,
  SuppressKeyboardEventParams,
} from 'ag-grid-community';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { RowClassParams } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { v4 as uuidv4 } from 'uuid';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { OverlayService } from '@services/overlay.service';
import { finalize, map, startWith, take } from 'rxjs/operators';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import {
  DistributionMode,
  DriverPatientGroup,
  EntityType,
  EventType,
  GqlService,
  listDriverPatientDistributionsQuery,
  PermissionType,
} from '@services/gql.service';

import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { FormControl } from '@angular/forms';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationStore } from '@models/organization/organization.store';
import { TrialsQuery } from '@models/trials/trials.query';
import { Utils } from '@services/utils';
import { EventManager } from '@angular/platform-browser';
import { ExcelButtonVariant } from '@components/export-excel-button/export-excel-button.component';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { MilestoneQuery } from '@models/milestone-category/milestone/milestone.query';
import { TimelineQuery } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.query';
import { TimelineService } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.service';
import { ApiService } from '@services/api.service';
import { PatientGroupsQuery } from 'src/app/pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.query';
import { first, last } from 'lodash-es';
import { AuthService } from '@models/auth/auth.service';
import { CanvasChart } from '@components/canvas-chart/canvas-chart.model';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import {
  RemoveDialogComponent,
  RemoveDialogInput,
} from '@components/remove-dialog/remove-dialog.component';
import { PatientDriverUploadComponent } from './patient-driver-upload/patient-driver-upload.component';
import { ForecastAccrualsPageComponent } from '../../../../forecast-accruals-page.component';
import { PatientsService } from './state/patients.service';
import { PatientCurveGroupComponent } from './patient-curve-group/patient-curve-group.component';
import { PatientDriversComponent } from '../patient-drivers.component';
import { PatientCurveService } from '../../patient-curve/patient-curve.service';
import { PatientCurveQuery } from '../../patient-curve/patient-curve.query';
import { PatientGroupsService } from './patient-groups/state/patient-groups.service';
import { PatientCurveStore } from '../../patient-curve/patient-curve.store';
import { PatientsQuery } from './state/patients.query';
import { WorkflowQuery } from '../../../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { WORKFLOW_NAMES } from '../../../../../closing-page/tabs/quarter-close/close-quarter-check-list';
import { MessagesConstants } from '../../../../../../constants/messages.constants';

@UntilDestroy()
@Component({
  selector: 'aux-patient-curves',
  templateUrl: './patient-curves.component.html',
  styles: [
    `
      ::ng-deep .patient-table .ag-cell.editable-cell {
        border-width: 1px !important;
        justify-items: end;
      }

      ::ng-deep .patient-table .ag-header-row,
      ::ng-deep .patient-table .ag-header-cell {
        overflow: unset;
      }
      ::ng-deep .patient-table .ag-header-cell-text,
      ::ng-deep .patient-table .ag-row {
        font-size: 1rem;
      }
      ::ng-deep .patient-table .ag-header-cell {
        text-align: center;
      }
      ::ng-deep .patient-table .ag-header-cell-label {
        justify-content: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientCurvesComponent implements OnInit, AfterViewInit {
  readonly messagesConstants = MessagesConstants;

  @ViewChildren('patientDriverMenu') patientDriverMenu!: QueryList<TemplateRef<any>>;

  @ViewChildren('selectCurveMenu') selectCurveMenu!: QueryList<TemplateRef<any>>;

  gridData$ = new BehaviorSubject<listDriverPatientDistributionsQuery[]>([]);

  isPatientsFinalized$ = this.workflowQuery.getLockStatusByWorkflowName(
    WORKFLOW_NAMES.SITE_AND_PATIENTS_CURVES
  );

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  currentPatientGroupId = 0;

  exportButtonVariant = ExcelButtonVariant.OUTLINE_DOWNLOAD;

  multiChart$: Observable<CanvasChart> = this.gridData$.pipe(
    map((data) => {
      const labels = data.map(({ distribution_month }) =>
        Utils.dateFormatter(distribution_month, { day: undefined, year: '2-digit' })
      );
      return {
        datasets: [
          {
            data: data.map((x) => x.net_patients_enrolled),
            borderColor: '#E3B506',
            pointHoverBorderColor: '#E3B506',
            label: 'Net Patients Enrolled',
            type: 'line',
          },
          {
            data: data.map((x) => x.total_patients_enrolled),
            borderColor: '#236262',
            pointHoverBorderColor: '#236262',
            label: 'Total Patients Enrolled',
            type: 'line',
          },
          {
            data: data.map((x) => x.patients_enrolled),
            borderColor: '#095b95',
            cubicInterpolationMode: 'monotone',
            label: 'Enrolled',
            type: 'bar',
          },
          {
            data: data.map((x) => x.patients_discontinued),
            borderColor: '#6B9DBF',
            cubicInterpolationMode: 'monotone',
            label: 'Discontinued',
            type: 'bar',
          },
          {
            data: data.map((x) => x.patients_complete),
            borderColor: '#9dbdd5',
            cubicInterpolationMode: 'monotone',
            label: 'Completed',
            type: 'bar',
          },
        ],
        options: {
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              display: false,
            },
          },
          tooltips: {
            callbacks: {
              labelColor: (tooltipItem, chart) => {
                const color = (chart.data?.datasets?.[tooltipItem.datasetIndex || 0].borderColor ||
                  'red') as string;
                return {
                  borderColor: color,
                  backgroundColor: color,
                };
              },
            },
          },
        },
        colors: ['#E3B506', '#236262', '#095b95', '#6B9DBF', '#9dbdd5'].map((color) => ({
          pointStyle: 'rectRounded',
          borderColor: color,
          backgroundColor: color === '#E3B506' || color === '#236262' ? 'rgba(0,0,0,0)' : color,
          pointBorderColor: color === '#E3B506' || color === '#236262' ? 'rgba(0,0,0,0)' : color,
          pointBackgroundColor:
            color === '#E3B506' || color === '#236262' ? 'rgba(0,0,0,0)' : color,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: color,
          borderWidth: 3,
          pointHoverBorderWidth: 4,
          pointHoverRadius: 1,
          pointHitRadius: 1,
        })),
        legend: { bottom: true, right: false, left: false, top: false },
        type: 'bar',
        labels,
      } as CanvasChart;
    })
  );

  excelPercentFormat = '0.00;-0.00;—;—';

  editCell = false;

  gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (!params.editing) {
          switch (params.event.keyCode) {
            // delete button code 46 - backspace button code 8
            case 46:
            case 8:
              if (this.editMode$.getValue()) {
                // @ts-ignore
                params.api.getCellRanges().forEach((range) => {
                  // @ts-ignore
                  const colIds = range.columns.map((col) => col.colId);
                  // @ts-ignore
                  const startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                  // @ts-ignore
                  const endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

                  this.clearCells(startRowIndex, endRowIndex, colIds, params.api);
                  this.calculatePatientCurves();
                });
              }

              return true;
            default:
              return false;
          }
        }
        return false;
      },
      editable: () => {
        return this.editMode$.getValue();
      },
      cellClass: (params) => {
        if (params.data.distribution_month === 'TOTAL') {
          return params.colDef.field === 'distribution_month' ? '' : 'justify-end';
        }
        switch (params.colDef.field) {
          case 'patients_enrolled':
          case 'patients_discontinued':
          case 'patients_complete':
            return this.editCell ? ['editable-cell', 'justify-end'] : 'justify-end';
          case 'distribution_month':
            return this.editCell ? 'opacity-70' : '';
          case 'net_patients_enrolled':
          case 'total_patients_enrolled':
            return this.editCell ? ['opacity-70', 'justify-end'] : 'justify-end';
          default:
            return '';
        }
      },
      cellClassRules: {
        'bg-aux-error': (params: any) => {
          let ret = false;
          switch (params.colDef.field) {
            case 'patients_discontinued':
            case 'patients_enrolled':
            case 'patients_complete':
              if (params.data.distribution_month === 'TOTAL') {
                ret =
                  Number(params.data.patients_enrolled) -
                    Number(params.data.patients_discontinued) !==
                  Number(params.data.patients_complete);
                this.isEqual$.next(!ret);
              }
              break;
            default:
              break;
          }

          return ret;
        },
      },
      cellStyle: (params) => {
        if (params.colDef.field === 'cumulative_enrollment_percentage') {
          return this.editCell
            ? { opacity: 0.7, 'justify-item': 'end' }
            : { 'justify-item': 'end' };
        }
        return {};
      },
    },
    onCellClicked(event: CellClickedEvent) {
      const cellRange = event.api.getCellRanges();
      // @ts-ignore
      if (cellRange?.length > 1) {
        event.api.stopEditing();
      }
    },
    enableRangeSelection: true,
    undoRedoCellEditingLimit: 20,
    undoRedoCellEditing: true,
    suppressMenuHide: true,
    enterMovesDown: true,
    enterMovesDownAfterEdit: true,
    headerHeight: 40,
    pinnedBottomRowData: [],
    groupIncludeFooter: true,
    rowClassRules: {
      'has-error': (params) => params.data.showError,
    },
    getRowStyle: (params: RowClassParams) => {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold' };
      }
      return {};
    },
    columnDefs: [
      {
        headerName: '',
        field: 'id',
        valueFormatter: this.valueFormatter,
        hide: true,
      },
      {
        headerName: 'Month',
        field: 'distribution_month',
        editable: false,
        headerClass: 'ag-header-cell',
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value === 'TOTAL') {
            return 'TOTAL';
          }
          return params.value
            ? Utils.dateFormatter(params.value, { day: undefined, year: '2-digit' })
            : null;
        },
      },
      {
        headerName: 'Patients Enrolled',
        field: 'patients_enrolled',
        valueFormatter: this.valueFormatter,
      },
      {
        headerName: 'Patients Discontinued',
        field: 'patients_discontinued',
        valueFormatter: this.valueFormatter,
      },
      {
        headerName: 'Patients Complete',
        field: 'patients_complete',
        valueFormatter: this.valueFormatter,
      },
      {
        headerName: 'Net Patients Enrolled',
        field: 'net_patients_enrolled',
        valueFormatter: this.valueFormatter,
        editable: false,
      },
      {
        headerName: 'Accrual Patient Curve',
        field: 'cumulative_enrollment_percentage',
        cellClass: () => {
          return this.editCell
            ? ['opacity-70', 'cellPercent', 'justify-end']
            : ['justify-end', 'cellPercent'];
        },
        editable: false,
        valueFormatter: (val: ValueFormatterParams) => {
          return val.value ? `${(+val.value).toFixed(1)}%` : Utils.zeroHyphen;
        },
      },
      {
        headerName: 'Total Patients Enrolled',
        field: 'total_patients_enrolled',
        valueFormatter: this.valueFormatter,
        editable: false,
      },
    ],
    excelStyles: [
      {
        id: 'header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { patternColor: '#094673', color: '#094673', pattern: 'Solid' },
      },
      {
        id: 'text-aux-error',
        font: { color: '#D73C37' },
      },
      {
        id: 'text-aux-green',
        font: { color: '#437F7F' },
      },
      {
        id: 'cellPercent',
        font: { fontName: 'Arial', size: 11 },
        alignment: { horizontal: 'Right' },
        numberFormat: { format: this.excelPercentFormat },
      },
      {
        id: 'first_row',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
      },
      {
        id: 'total_row_header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      },
      {
        id: 'total_row',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
        dataType: 'Number',
        numberFormat: { format: '#,##0.00' },
      },
      {
        id: 'total_row_percent',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
        dataType: 'String',
        alignment: {
          horizontal: 'Right',
        },
      },
    ],
  } as GridOptions;

  excelOptions = {
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Patient Curve',
    fileName: 'auxilius-patient-curve.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.id;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'distribution_month':
          return 225;
        default:
          return 225;
      }
    },
    processCellCallback: (params: ProcessCellForExportParams): string => {
      const coldId = params.column.getColId();
      if (params.column.getColId() === 'id') {
        return this.organizationQuery.getEntity(params.value)?.name || '';
      }

      const isPercentColumn = ['cumulative_enrollment_percentage'].some((key) =>
        coldId.endsWith(key)
      );

      if (isPercentColumn) {
        return params.value ? (+params.value).toFixed(1) : Utils.zeroHyphen;
      }

      return params.value;
    },
  } as ExcelExportParams;

  gridColumnApi!: ColumnApi;

  gridApi!: GridApi;

  loading$ = new BehaviorSubject(false);

  isBlended$ = new BehaviorSubject(false);

  showEnrollmentSettings$: Observable<boolean>;

  PatientCurveGroup: any[] = [];

  patientCurveGroup$ = this.patientCurveQuery.selectAll();

  isOpen = false;

  editingGridCell$ = new BehaviorSubject(false);

  highlightedCurve: number | null = null;

  highlightedPatient: number | null = null;

  selected = '';

  selectedCurveIndex: number | null = null;

  selectedPatientIndex: number | null = null;

  selectedVendor = new FormControl('');

  clonedPatient: listDriverPatientDistributionsQuery[] = [];

  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  btnLoading$ = new BehaviorSubject(false);

  editMode$ = new BehaviorSubject(false);

  isEqual$ = new BehaviorSubject(true);

  isFromScratch = false;

  userHasModifyPermissions = false;

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private overlayService: OverlayService,
    private forecastPage: ForecastAccrualsPageComponent,
    private driversPage: PatientDriversComponent,
    private gqlService: GqlService,
    private patientGroupsService: PatientGroupsService,
    private mainQuery: MainQuery,
    private patientService: PatientsService,
    private cdr: ChangeDetectorRef,
    private vendorsService: OrganizationService,
    public organizationQuery: OrganizationQuery,
    private organizationStore: OrganizationStore,
    private patientCurveService: PatientCurveService,
    public patientCurveQuery: PatientCurveQuery,
    private milestoneQuery: MilestoneQuery,
    private timelineService: TimelineService,
    private timelineQuery: TimelineQuery,
    private apiService: ApiService,
    private patientCurveStore: PatientCurveStore,
    public patientGroupsQuery: PatientGroupsQuery,
    private patientQuery: PatientsQuery,
    private trialsQuery: TrialsQuery,
    private eventManager: EventManager,
    private authService: AuthService,
    private workflowQuery: WorkflowQuery
  ) {
    this.patientGroupsService.get().pipe(untilDestroyed(this)).subscribe();

    this.showEnrollmentSettings$ = this.launchDarklyService.select$(
      (flags) => flags.section_patient_driver_enrollment_settings
    );
    this.patientCurveService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(({ success, data, errors }) => {
        if (success && data?.[0]) {
          this.selectCurve(data[0]);
        } else if (success) {
          this.populateGridDataWithTimeline();
        } else {
          this.gridData$.next([]);
          this.overlayService.error(errors);
        }
        this.loading$.next(false);
      });
    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_MODIFY_PATIENT_CURVE],
      })
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.userHasModifyPermissions = x;
      });
  }

  ngOnInit() {
    this.vendorsService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length === 1) {
          this.organizationStore.setActive(vendors[0].id);
          this.selectedVendor.setValue(vendors[0].id);
        } else {
          // reset any older selected vendors.
          this.organizationStore.setActive(null);
          this.selectedVendor.setValue('');
        }
      });

    this.patientCurveService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.patientCurveGroup$ = combineLatest([
          this.patientCurveQuery.selectAll(),
          this.patientGroupsQuery.selectAll(),
        ]).pipe(
          map(([curves, patientGroups]) => {
            if (this.PatientCurveGroup.length < curves.length) {
              this.PatientCurveGroup.push(...curves);
            }
            const pgIds: String[] = [];
            curves.forEach((c) => {
              pgIds.push(c.patient_group_id);
            });
            const uniqPGs: any[] = [];
            patientGroups.forEach((pg) => {
              if (!pgIds.includes(pg.id)) {
                uniqPGs.push({
                  constituent_patient_groups: [],
                  driver_setting_id: '',
                  is_blended: false,
                  name: pg.name,
                  patient_group_ids: [],
                  showLine: false,
                  organizations_with_forecasts: [],
                  patient_group_id: pg.id,
                });
              }
            });
            return [...curves, ...uniqPGs];
          })
        );
      });

    this.eventManager.addEventListener(document.body, 'keydown', (event: any) => {
      switch (event.code) {
        case 'Tab':
          this.gridApi.stopEditing();
          setTimeout(() => {
            const getCellCor = this.gridApi.getFocusedCell();
            this.gridApi.startEditingCell({
              rowIndex: getCellCor?.rowIndex || 0,
              // @ts-ignore
              colKey: getCellCor?.column?.colId || '',
            });
          }, 0);
          break;
        default:
          break;
      }
    });
  }

  async resetPatientCurveGroup() {
    this.PatientCurveGroup = [];
  }

  ngAfterViewInit(): void {
    this.patientDriverMenu.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        finalize(() => {
          setTimeout(() => {
            this.forecastPage.rightSideContainer.next(null);
          }, 0);
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.forecastPage.rightSideContainer.next(this.patientDriverMenu.first || null);
        }, 0);
      });

    this.selectCurveMenu.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        finalize(() => {
          setTimeout(() => {
            this.driversPage.rightSideContainer.next(null);
          }, 0);
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.driversPage.rightSideContainer.next(this.selectCurveMenu.first || null);
        }, 0);
      });
  }

  async canDeactivate(): Promise<boolean> {
    if (this.editMode$.getValue()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await result.afterClosed$.toPromise();
      return !!event.data;
    }
    return true;
  }

  valueFormatter(val: ValueFormatterParams) {
    const newValue = val.value === '0' ? 0 : val.value;
    return newValue ? (+newValue).toFixed(1) : Utils.zeroHyphen;
  }

  onDataRendered({ api }: { api: GridApi }) {
    api.sizeColumnsToFit();
  }

  calculatePinnedBottomData() {
    const columnsWithAggregation = [
      'patients_enrolled',
      'patients_discontinued',
      'patients_complete',
      'total_patients_enrolled',
      'cumulative_enrollment_percentage',
    ];

    return this.gridData$.getValue().reduce(
      (acc, val) => {
        for (const key of columnsWithAggregation) {
          if (key === 'cumulative_enrollment_percentage') {
            // @ts-ignore
            acc[key] += Number(Number(val[key]).toFixed(1));
          } else {
            // @ts-ignore
            acc[key] += Number(val[key]);
          }
        }
        acc.total_patients_enrolled = 0;
        return acc;
      },
      {
        patients_enrolled: 0,
        patients_discontinued: 0,
        patients_complete: 0,
        total_patients_enrolled: 0,
        net_patients_enrolled: 0,
        cumulative_enrollment_percentage: 0,
      }
    );
  }

  onGridReady(e: GridReadyEvent) {
    this.gridColumnApi = e.columnApi;
    this.gridApi = e.api;
    this.gridData$.pipe(untilDestroyed(this)).subscribe(() => {
      const pinnedBottomData = { ...this.calculatePinnedBottomData(), distribution_month: 'TOTAL' };
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    });
  }

  onPasteStart() {
    if (!this.editMode$.getValue()) {
      if (!this.isBlended$.getValue()) {
        this.editMode$.next(true);
        this.editGrid();
      }
    }
  }

  onPatientDriverUploadClick() {
    this.overlayService.open({ content: PatientDriverUploadComponent });
  }

  highlightPatient(index: number): void {
    this.highlightedPatient = index;
  }

  highlightCurve(index: number): void {
    this.highlightedCurve = index;
  }

  async selectCurve(item: any) {
    this.loading$.next(true);
    this.currentPatientGroupId = item.patient_group_id;
    const index = this.highlightedCurve;
    if (index != null || item) {
      this.selected = item.name;
      this.selectedCurveIndex = index;
      this.gridApi.stopEditing();
      this.editMode$.next(false);
      this.editCell = false;
      if (item.driver_setting_id === '') {
        this.populateGridDataWithTimeline();
        this.isBlended$.next(false);
        this.isFromScratch = true;
      } else {
        this.isFromScratch = false;
        this.isBlended$.next(!!item?.is_blended);
        this.patientService
          .get(item.patient_group_id)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.clonePatient();
            const newData = JSON.parse(JSON.stringify(this.clonedPatient));
            this.gridData$.next(newData);
          });
      }
    }

    this.closeList();
    this.loading$.next(false);
  }

  populateGridDataWithTimeline() {
    this.timelineService.getTimelineItems().pipe(untilDestroyed(this)).subscribe();
    this.timelineQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe(({ items }) => {
        const start_date = first(items)?.contract_start_date;
        let end_date = Utils.dateParse(`${last(items)?.contract_end_date.slice(0, 8)}01`);
        items.forEach((i) => {
          const parsedDate = Utils.dateParse(`${i.contract_end_date.slice(0, 8)}01`);
          if (parsedDate > end_date) {
            end_date = parsedDate;
          }
        });

        const month_list: { label: string; value: string }[] = [];
        this.clonedPatient = [];
        if (start_date) {
          let date = Utils.dateParse(`${start_date.slice(0, 8)}01`);
          while (date <= end_date) {
            const value = new Intl.DateTimeFormat('fr-CA', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }).format(date);

            month_list.push({
              value,
              label: Utils.dateFormatter(value, { day: undefined, year: '2-digit' }),
            });
            date = Utils.addMonths(date, 1);
            const l: listDriverPatientDistributionsQuery = {
              distribution_month: value,
              patients_enrolled: 0,
              patients_discontinued: 0,
              patients_complete: 0,
              net_patients_enrolled: 0,
              cumulative_enrollment_percentage: 0,
              total_patients_enrolled: 0,
              distribution_mode: DistributionMode.DISTRIBUTION_MODE_FORECAST,
              __typename: 'DriverPatientDistribution',
              id: uuidv4(),
            };
            if (this.clonedPatient.length < month_list.length) {
              this.clonedPatient.push(l);
            }
          }
        }
        const newData = JSON.parse(JSON.stringify(this.clonedPatient));
        this.gridData$.next(newData);
        this.isFromScratch = true;
      });
  }

  closeList() {
    this.isOpen = false;
    this.highlightedCurve = null;
    this.highlightedPatient = null;
    this.editMode$.next(false);
    this.cdr.detectChanges();
  }

  openList(trigger: CdkOverlayOrigin) {
    console.log('trigger', trigger);
    this.isOpen = true;

    if (this.selectedPatientIndex != null) {
      this.highlightedPatient = this.selectedPatientIndex;
    } else {
      this.highlightedPatient = 0;
    }

    if (this.selectedCurveIndex != null) {
      this.highlightedCurve = this.selectedCurveIndex;
    } else {
      this.highlightedCurve = 0;
    }
    this.cdr.detectChanges();
  }

  editCurve(item: any = null) {
    let params;
    if (!item) {
      params = {
        PatientCurveGroup: this.PatientCurveGroup.filter((x) => !x.is_blended).filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.driver_setting_id === value.driver_setting_id)
        ),
      };
    } else {
      params = {
        PatientCurveGroup: this.PatientCurveGroup.filter((x) => !x.is_blended).filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.driver_setting_id === value.driver_setting_id)
        ),
        patientData: item,
      };
    }

    this.overlayService.open({ content: PatientCurveGroupComponent, data: params });
    this.selected = '';
    this.closeList();
  }

  async deleteCurve(driverPatientGroup: DriverPatientGroup) {
    if (!driverPatientGroup) {
      return;
    }
    const cannotDeleteMessage = `${driverPatientGroup.name} cannot be deleted because it is being used in other areas of the application.<br>Please first remove the dependencies below and then ${driverPatientGroup.name} can be successfully deleted.<br>`;

    let blendedCurves: any[] = [];
    if (!driverPatientGroup.is_blended) {
      const patientCurves = this.patientCurveQuery.getAll();

      blendedCurves = patientCurves.filter((el) =>
        el.constituent_patient_groups.find((c) => c.id === driverPatientGroup.patient_group_id)
      );
    }

    if (
      driverPatientGroup.is_blended &&
      driverPatientGroup.organizations_with_forecasts.length > 0
    ) {
      const routeInputs = [
        {
          componentName: 'Forecast Methodology:',
          name: driverPatientGroup.organizations_with_forecasts.map(({ name }) => name),
          link: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}`,
        },
      ];

      const removeDialogInput: RemoveDialogInput = {
        header: 'Remove Patient Curve',
        cannotDeleteMessage,
        routeInputs,
      };

      const resp = this.overlayService.open({
        content: RemoveDialogComponent,
        data: removeDialogInput,
      });

      await resp.afterClosed$.toPromise();
    } else if (
      !driverPatientGroup.is_blended &&
      (driverPatientGroup.organizations_with_forecasts.length > 0 || blendedCurves.length > 0)
    ) {
      const routeInputs = [
        {
          componentName: 'Blended Curves:',
          name: blendedCurves.map(({ name }) => name),
          link: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}`,
        },
      ];

      if (driverPatientGroup.organizations_with_forecasts.length) {
        const routeInputsPatientGroup = [
          {
            componentName: 'Forecast Methodology:',
            name: driverPatientGroup.organizations_with_forecasts.map(({ name }) => name),
            link: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}`,
          },
        ];
        routeInputs.push(...routeInputsPatientGroup);
      }

      const removeDialogInput: RemoveDialogInput = {
        header: 'Remove Patient Curve',
        cannotDeleteMessage,
        routeInputs,
      };

      const resp = this.overlayService.open({
        content: RemoveDialogComponent,
        data: removeDialogInput,
      });

      await resp.afterClosed$.toPromise();
    } else {
      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Patient Curve',
        message: `Are you sure you want to remove Patient Curve ${driverPatientGroup.name}?`,
        okBtnText: 'Remove',
      });
      const event = await resp.afterClosed$.toPromise();
      if (event.data?.result) {
        this.selected = '';
        await this.patientCurveService.remove(driverPatientGroup);
        const { data: patientCurveServiceData } = await this.patientCurveService
          .get()
          .pipe(take(1))
          .toPromise();
        if (patientCurveServiceData) {
          this.PatientCurveGroup = patientCurveServiceData;
        }
        this.gridData$.next([]);
        this.gridOptions.api?.refreshCells({ force: true });
      }
    }
    this.selected = '';
    this.populateGridDataWithTimeline();
  }

  getDynamicExcelParams(): ExcelExportParams {
    const trial = this.trialsQuery.getEntity(this.mainQuery.getValue().trialKey);
    const curveName = this.selected ? this.selected : '';
    if (!trial) {
      return {};
    }
    const fieldsToCalc = [
      'patients_enrolled',
      'patients_discontinued',
      'patients_complete',
      'cumulative_enrollment_percentage',
    ];
    const patientData = this.patientQuery.getAll();
    const totals = patientData.reduce(
      (acc, val) => {
        for (const key of fieldsToCalc) {
          if (key === 'cumulative_enrollment_percentage') {
            // @ts-ignore
            acc[key] += Number(Number(val[key]).toFixed(1));
          } else {
            // @ts-ignore
            acc[key] += Number(val[key]);
          }
        }
        acc.total_patients_enrolled = 0;

        return acc;
      },
      {
        patients_enrolled: 0,
        patients_discontinued: 0,
        patients_complete: 0,
        total_patients_enrolled: 0,
        cumulative_enrollment_percentage: 0,
      }
    );

    const exportOptions = {
      ...this.excelOptions,
      columnKeys: [
        'distribution_month',
        'patients_enrolled',
        'patients_discontinued',
        'patients_complete',
        'net_patients_enrolled',
        'cumulative_enrollment_percentage',
        'total_patients_enrolled',
      ],
      prependContent: [
        [
          {
            data: { value: `Trial: ${trial.short_name}`, type: 'String' },
            mergeAcross: 6,
            styleId: 'first_row',
          },
        ],
        [
          {
            data: { value: `Curve: ${curveName}`, type: 'String' },
            mergeAcross: 6,
            styleId: 'first_row',
          },
        ],
      ],
      appendContent: [
        [
          {
            data: { value: `Total`, type: 'String' },
            styleId: 'total_row_header',
          },
          {
            // patients enrrolled
            data: { value: `${totals.patients_enrolled}`, type: 'Number' },
            styleId: 'total_row',
          },
          {
            // patients discontinued
            data: { value: `${totals.patients_discontinued}`, type: 'Number' },
            styleId: 'total_row',
          },
          {
            // patients complete
            data: { value: `${totals.patients_complete}`, type: 'Number' },
            styleId: 'total_row',
          },
          {
            // net patients enrolled
            data: {
              value: `${
                Number(totals.patients_enrolled) -
                totals.patients_discontinued -
                totals.patients_complete
              }`,
              type: 'Number',
            },
            styleId: 'total_row',
          },
          {
            // total percent
            data: {
              value: `${totals.cumulative_enrollment_percentage.toFixed(1)}`,
              type: 'String',
            },
            styleId: 'total_row_percent',
          },
          {
            // total patients enrolled
            data: { value: `${totals.total_patients_enrolled}`, type: 'Number' },
            styleId: 'total_row',
          },
        ],
      ],
    } as ExcelExportParams;

    this.gridApi.setPinnedBottomRowData([]);
    this.gridData$.pipe(untilDestroyed(this)).subscribe(() => {
      const pinnedBottomData = { ...this.calculatePinnedBottomData(), distribution_month: 'TOTAL' };
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    });

    return exportOptions;
  }

  calculatePatientCurves() {
    const isNumber = (n: any) => {
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    };
    const negativeCheck = (n: any) => {
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(Number(n)) && String(n).indexOf('-') !== -1 ? String(n).replace('-', '') : n;
    };
    let totalNetPatientEnrolled = 0;
    let sumOfNetPatientEnrolled = 0;
    let patientEnrolledTotal = 0;

    this.gridData$.getValue().forEach((x: listDriverPatientDistributionsQuery) => {
      totalNetPatientEnrolled +=
        this.roundToNumber(negativeCheck(x.patients_enrolled) || '0') -
        this.roundToNumber(negativeCheck(x.patients_discontinued) || 0) -
        this.roundToNumber(negativeCheck(x.patients_complete) || 0);

      // eslint-disable-next-line no-param-reassign
      x.patients_enrolled = isNumber(x.patients_enrolled) ? negativeCheck(x.patients_enrolled) : 0;
      // eslint-disable-next-line no-param-reassign
      x.patients_discontinued = isNumber(x.patients_discontinued)
        ? negativeCheck(x.patients_discontinued)
        : 0;
      // eslint-disable-next-line no-param-reassign
      x.patients_complete = isNumber(x.patients_complete) ? negativeCheck(x.patients_complete) : 0;

      sumOfNetPatientEnrolled += this.roundToNumber(totalNetPatientEnrolled);
      // eslint-disable-next-line no-param-reassign
      x.net_patients_enrolled = this.roundToNumber(totalNetPatientEnrolled);

      patientEnrolledTotal += this.roundToNumber(x.patients_enrolled || 0, 2);
      // eslint-disable-next-line no-param-reassign
      x.total_patients_enrolled = patientEnrolledTotal;
    });

    this.gridData$.getValue().forEach((x: listDriverPatientDistributionsQuery) => {
      // eslint-disable-next-line no-param-reassign
      x.cumulative_enrollment_percentage = this.roundToNumber(
        (100 / sumOfNetPatientEnrolled) * (x.net_patients_enrolled || 0),
        2
      );
    });

    this.gridData$.next(this.gridData$.getValue());
    this.gridApi.refreshCells();
  }

  showErrors() {
    const rowNodes: RowNode[] = [];
    let isThereAnyInvalidRow = false;
    const isNumber = (n: any) => {
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    };
    this.gridApi.forEachNode((node) => {
      const {
        patients_enrolled,
        patients_discontinued,
        patients_complete,
      } = node.data as listDriverPatientDistributionsQuery;

      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      if (
        isNumber(patients_enrolled) &&
        isNumber(patients_discontinued) &&
        isNumber(patients_complete)
      ) {
        if (node.data.showError) {
          // eslint-disable-next-line no-param-reassign
          node.data.showError = false;
          rowNodes.push(node);
        }
      } else {
        isThereAnyInvalidRow = true;
        // eslint-disable-next-line no-param-reassign
        node.data.showError = true;
        rowNodes.push(node);
      }
    });

    this.gridApi.redrawRows({ rowNodes });
    return isThereAnyInvalidRow;
  }

  async onSaveAll() {
    this.btnLoading$.next(true);
    const isInvalid = this.showErrors();
    if (isInvalid) {
      this.btnLoading$.next(false);
      this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
      return;
    }

    if (!isInvalid) {
      if (this.isFromScratch) {
        this.processPatientsCreatedFromScratch();
      } else {
        this.patientCurveStore.setLoading(true);

        const gData = this.gridData$.getValue();

        const patientPromises = [];

        for (const x of gData) {
          // eslint-disable-next-line no-await-in-loop
          patientPromises.push(this.patientService.updatePatientDistribution(x));
        }
        const responses = await Promise.all(patientPromises);
        if (!responses.every((x) => x)) {
          this.btnLoading$.next(false);
          this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
          return;
        }
        this.overlayService.success('Patients Table updated successfully!');
        this.clonePatient();
        const newData = JSON.parse(JSON.stringify(this.clonedPatient));

        const { success, errors } = await this.gqlService
          .processEvent$({
            type: EventType.PATIENT_DRIVER_DISTRIBUTION_UPDATED,
            entity_type: EntityType.DRIVER,
            entity_id:
              this.currentPatientGroupId === null || this.currentPatientGroupId === 0
                ? ''
                : this.currentPatientGroupId.toString(),
          })
          .toPromise();

        if (success) {
          this.overlayService.success();
        } else {
          this.overlayService.error(errors);
        }
        this.gridData$.next(newData);
        await this.cancelEditMode();
      }
    }
    this.editMode$.next(false);
    this.btnLoading$.next(false);
  }

  async processPatientsCreatedFromScratch() {
    const thingsToUpdate: any[] = [];
    this.gridApi.forEachNode((node) => {
      thingsToUpdate.push(node.data);
    });
    const data = thingsToUpdate.map((row) => ({
      month: Utils.dateFormatter(row.distribution_month, {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      }),
      patients_enrolled: row.patients_enrolled,
      patients_discontinued: row.patients_discontinued,
      patients_complete: row.patients_complete,
    }));
    const key = `${this.getFilePath(
      this.selected !== '' ? this.currentPatientGroupId.toString() : 'Patient-Curve'
    )}patient-curve-from-scratch.csv`;
    const blob = new Blob([Utils.objectToCsv(data)], { type: 'text/csv' });
    const fileSuccess = await this.apiService.uploadFile(key, new File([blob], key));

    if (fileSuccess) {
      const { success, errors } = await this.gqlService
        .processEvent$({
          type: EventType.PATIENT_DRIVER_TEMPLATE_UPLOADED,
          entity_type: EntityType.DRIVER,
          entity_id: this.selected !== '' ? this.currentPatientGroupId.toString() : '',
          bucket_key: `public/${key}`,
          payload: JSON.stringify({
            should_show_on_document_library: false,
          }),
        })
        .toPromise();

      if (success) {
        this.loading$.next(true);
        // allows time for PATIENT_DRIVER_TEMPLATE_UPLOADED event to complete
        await new Promise((resolve) => setTimeout(resolve, 5000));
        this.overlayService.success();
        this.isFromScratch = false;
        this.resetPatientCurveGroup();
        await this.patientGroupsService.get().pipe(take(1)).toPromise();
        const { data: patientServiceData } = await this.patientCurveService
          .get()
          .pipe(take(1))
          .toPromise();
        await this.patientService
          .get(this.selected === '' ? undefined : this.currentPatientGroupId.toString())
          .pipe(take(1))
          .toPromise();
        if (this.selected === '') {
          const patientCurveIndex = patientServiceData?.findIndex(
            (x) => x.name === 'Patient Curve'
          );
          this.selected = 'Patient Curve';
          if (patientCurveIndex) {
            this.selectedCurveIndex = patientCurveIndex;
          }
        }
        this.clonePatient();
        const newData = JSON.parse(JSON.stringify(this.clonedPatient));
        this.gridData$.next(newData);
        this.gridApi.stopEditing();
        this.editMode$.next(false);
        this.editCell = false;
        this.gridOptions.api?.refreshCells({ force: true });
        this.loading$.next(false);
      } else {
        this.overlayService.error(errors);
      }
    } else {
      this.overlayService.error('There was an error uploading the file. Please try again');
    }
  }

  getFilePath(id: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/sites/${id}/patient-driver/`;
  }

  editGrid() {
    this.editCell = true;
    this.gridOptions.api?.refreshCells({ force: true });

    this.gridApi.startEditingCell({
      rowIndex: 0,
      colKey: 'patients_enrolled',
    });
  }

  clearCells(start: any, end: any, columns: any, gridApi: any) {
    const itemsToUpdate = [];

    for (let i = start; i <= end; i++) {
      const dData = gridApi.rowModel.rowsToDisplay[i].data;
      columns.forEach((column: string | number) => {
        dData[column] = 0;
      });
      itemsToUpdate.push(dData);
    }

    gridApi.applyTransaction({ update: itemsToUpdate });
  }

  async cancelEditMode() {
    if (this.isFromScratch) {
      this.populateGridDataWithTimeline();
    } else {
      this.clonePatient();
      this.gridData$.next(this.clonedPatient);
    }
    this.gridApi.stopEditing(true);
    this.editMode$.next(false);
    this.editCell = false;
    this.gridOptions.api?.refreshCells({ force: true });
  }

  async saveEditMode() {
    const isEqual = this.isEqual$.getValue();
    if (!isEqual) {
      this.overlayService.error(
        'This patient curve is unable to be saved until the following issue is resolved: Patients Enrolled must be equal to the sum of Patients Complete and Patients Discontinued.'
      );
      return;
    }
    await this.onSaveAll();
    this.gridApi.stopEditing(false);
  }

  cellValueChanged() {
    this.calculatePatientCurves();
  }

  cellEditingStopped() {
    this.editingGridCell$.next(false);
  }

  rowPinnedCheck(event: CellEditingStartedEvent) {
    if (event.node.rowPinned) {
      this.gridOptions.api?.stopEditing();
    } else {
      this.editingGridCell$.next(true);
    }
  }

  clonePatient() {
    const pData = this.patientQuery.getAll();
    this.clonedPatient = JSON.parse(JSON.stringify(pData));
  }

  roundToNumber(num: number | string, pow: number = 3) {
    const newNum = Number(num);
    // eslint-disable-next-line no-restricted-properties
    return Math.round(newNum * Math.pow(10, pow)) / Math.pow(10, pow);
  }

  onEditClick(): void {
    this.editMode$.next(true);
    this.editGrid();
  }
}
