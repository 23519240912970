import { OnInit, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { BehaviorSubject } from 'rxjs';
import {
  CreateDriverBlendedSiteDistributionInput,
  createDriverBlendedSiteDistributionMutation,
  PatientGroup,
  SiteGroup,
  UpdateDriverBlendedSiteDistributionInput,
  updateDriverBlendedSiteDistributionMutation,
} from '@services/gql.service';
import { CurveModel } from '../models/curve.model';

@Directive()
export abstract class BlendedCurveModalDirective implements OnInit {
  availableGroups: CurveModel[] = [];

  blendedCurveName = new FormControl('');

  selectedCurveIds: string[] = [];

  loading$ = new BehaviorSubject(false);

  labelTopic = 'Create';

  labelButton = 'Create';

  protected constructor(public ref: CustomOverlayRef) {}

  abstract constituentGroups: SiteGroup[] | PatientGroup[] = [];

  abstract updateData(): UpdateDriverBlendedSiteDistributionInput;

  abstract createData(): CreateDriverBlendedSiteDistributionInput;

  abstract updateDriverBlendedDistribution(): Promise<
    GraphqlResponse<updateDriverBlendedSiteDistributionMutation>
  >;

  abstract createDriverBlendedDistribution(): Promise<
    GraphqlResponse<createDriverBlendedSiteDistributionMutation>
  >;

  abstract getGroupId(item: SiteGroup): string;

  ngOnInit(): void {
    this.loading$.next(true);
    if (this.ref.data) {
      this.init();
    }
    this.loading$.next(false);
  }

  setValue(item: any, checked = false): void {
    const index = this.selectedCurveIds.findIndex((x: string) => {
      return x === this.getGroupId(item);
    });
    if (checked) {
      if (index === -1) {
        this.selectedCurveIds.push(this.getGroupId(item));
      }
    } else if (index !== -1) {
      this.selectedCurveIds.splice(index, 1);
    }
  }

  async saveBlendedCurve(): Promise<void> {
    this.loading$.next(true);
    if (this.ref.data?.blendedCurve) {
      await this.updateDriverBlendedDistribution();
    } else {
      await this.createDriverBlendedDistribution();
    }
    this.loading$.next(false);
    this.ref.close();
  }

  get title(): string {
    return this.ref.data?.text.title || '';
  }

  get subTitle(): string {
    return this.ref.data?.text.subTitle || '';
  }

  private init(): void {
    if (this.constituentGroups.length) {
      this.constituentGroups.forEach((x: any) => {
        this.selectedCurveIds.push(x.id);
      });
    }

    if (this.ref.data?.blendedCurve) {
      this.labelTopic = 'Edit';
      this.labelButton = 'Update';
      this.blendedCurveName.setValue(this.ref.data?.blendedCurve.name);
      this.ref.data.availableGroups.forEach((availableGroup: any) => {
        // @ts-ignore
        const curve = this.constituentGroups.find((constituentGroup: any) => {
          return constituentGroup.id === this.getGroupId(availableGroup);
        });
        this.availableGroups.push({ ...availableGroup, check: !!curve });
      });
    } else {
      this.ref.data.availableGroups.forEach((availableGroup: any) => {
        this.availableGroups.push({ ...availableGroup, check: false });
      });
    }
  }
}
