import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { PaymentMilestone } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface PaymentMilestoneState
  extends EntityState<
    Omit<RequireSome<Partial<PaymentMilestone>, 'id'>, 'organization'> & {
      organization: { id: string };
    }
  > {}

export type PaymentMilestoneModel = getEntityType<PaymentMilestoneState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'payment-milestone' })
export class PaymentMilestoneStore extends EntityStore<PaymentMilestoneState> {
  constructor() {
    super({});
  }
}
