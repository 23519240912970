import { WorkflowModel } from '../close-quarter-check-list/store';

export const getAuditText = (lockText: string, unlockText: string) => (
  workflow: WorkflowModel
): string => {
  if (!workflow.properties.locked) {
    return unlockText;
  }

  const secondPartLockText = `${workflow.updatedAuthorFullName} on ${workflow.update_date}`;

  return lockText ? `${lockText} ${secondPartLockText}` : secondPartLockText;
};
