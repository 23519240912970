<div class="bg-white p-4 border shadow-md rounded h-full flex flex-col" style="width: 400px">
  <ng-container *ngIf="(isLoadingList$ | async) === false">
    <ng-container
      *ngVar="{
        isWorkflowAvailable: (isWorkflowAvailable$ | async)!,
        isCurrentQuarter: isCurrentQuarterSelected$ | async
      } as obj"
    >
        <div class="flex justify-between mb-3">
          <div class="font-bold text-lg text-aux-gray-darkest">Closing
            Checklist
          </div>
        </div>
        <div class="space-y-2.5" *ngIf="obj.isWorkflowAvailable || !obj.isCurrentQuarter">
          <div
            *ngFor="let workflow of workflowList$ | async as workflowList"
            class="flex items-center h-10 opacity-30"
            [ngClass]="{ 'opacity-30': processing }"
          >
            <div>
              <span inlineSVG="lock.svg"
                    *ngIf="workflow.properties.locked"></span>
              <span inlineSVG="lock-open.svg"
                    *ngIf="!workflow.properties.locked"></span>
            </div>
            <div class="flex justify-between w-full ml-3">
              <div>
                <a class="aux-link"
                   [ngClass]="{ 'cursor-not-allowed': processing }"
                   [routerLink]="!processing ? '/' + workflow.properties.route : quarterClosePath">{{
                  workflow.name
                  }}</a>
                <p *ngIf="workflow.properties.locked"
                   class="text-xs text-aux-gray-darkest">
                  by {{ workflow.updatedAuthorFullName }}
                  on {{ workflow.update_date }}
                </p>
              </div>
              <ng-container
                *ngIf="{
                isMonthAdjustmentInvalid:
                  (isAdjustmentAvailable$ | async) === false &&
                  workflow.name === monthAdjustmentTitle
              } as state"
              >
                <aux-checkbox
                  [auxTooltip]="
                  !readonly && !processing && obj.isCurrentQuarter
                    ? closeQuarterService.getCloseQuarterTooltip(
                        !state.isMonthAdjustmentInvalid,
                        obj.isWorkflowAvailable
                      )
                    : ''
                "
                  [disabled]="
                  state.isMonthAdjustmentInvalid ||
                  !obj.isWorkflowAvailable ||
                  !obj.isCurrentQuarter ||
                  readonly ||
                  processing
                "
                  [checked]="
                  obj.isCurrentQuarter
                    ? obj.isWorkflowAvailable && workflow.properties.locked
                    : workflow.properties.locked
                "
                  (customChange)="changeLockStatus($event, workflow)"
                ></aux-checkbox>
              </ng-container>
            </div>
          </div>
        </div>

      <div *ngIf="!obj.isWorkflowAvailable && obj.isCurrentQuarter">
        <p class="italic">You are up to date. There are no tasks to complete for Period Close at this time.</p>
      </div>

    </ng-container>
  </ng-container>
  <div class="border-8 m-auto spinner h-16 w-16"
       *ngIf="isLoadingList$ | async"></div>
</div>
