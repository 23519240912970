import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';

type ChangeOrderParams = ICellRendererParams & {
  deleteClickFN: undefined | ((params: { rowNode: RowNode }) => void);
  downloadClickFN: undefined | ((params: { rowNode: RowNode }) => void);
};

@Component({
  selector: 'aux-change-order-actions',
  template: `
    <div class="flex items-center justify-center space-x-2">
      <button
        class="btn text-aux-blue rounded-full p-0 w-8 h-8"
        type="button"
        (click)="onDownloadClick()"
        auxTooltip="Download"
      >
        <span inlineSVG="download.svg" [setSVGAttributes]="{ class: 'w-4 h-4' }"></span>
      </button>

      <button
        class="btn rounded-full p-0 w-8 h-8"
        type="button"
        (click)="onDeleteClick()"
        auxTooltip="Delete"
      >
        <trash-solid-icon [size]="16" class="text-aux-error"></trash-solid-icon>
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderActionsComponent implements ICellRendererAngularComp {
  params!: ChangeOrderParams;

  refresh(): boolean {
    return false;
  }

  agInit(params: ChangeOrderParams): void {
    this.params = params;
  }

  onDownloadClick() {
    if (this.params.downloadClickFN) {
      this.params.downloadClickFN({ rowNode: this.params.node });
    }
  }

  onDeleteClick() {
    if (this.params.deleteClickFN) {
      this.params.deleteClickFN({ rowNode: this.params.node });
    }
  }
}
