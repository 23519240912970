import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import { FormGroupDirective, NgControl } from '@angular/forms';
import { BehaviorSubject, EMPTY, merge, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { get } from 'lodash-es';
import { FormControlConstants } from '../constants/form-control.constants';

@UntilDestroy()
@Directive({
  selector: '[auxFormError]',
})
export class FormErrorDirective implements OnInit, AfterViewInit {
  readonly formControlConstants = FormControlConstants;

  onBlur$ = new EventEmitter();

  showErrors = new BehaviorSubject(false);

  errorDiv: HTMLDivElement | undefined;

  isInvalid = new BehaviorSubject(false);

  isValid = new BehaviorSubject(false);

  @Input() label = '';

  @Input() labelForErrorMessage = '';

  errorMessages: { [k: string]: string } = {
    required: this.formControlConstants.VALIDATION_MESSAGE.REQUIRED,
    minlength: this.formControlConstants.VALIDATION_MESSAGE.MIN_LENGTH,
    email: this.formControlConstants.VALIDATION_MESSAGE.EMAIL,
    date: this.formControlConstants.VALIDATION_MESSAGE.DATE,
    emptyVendors: this.formControlConstants.VALIDATION_MESSAGE.EMPTY_VENDORS,
    duplicatedVendors: this.formControlConstants.VALIDATION_MESSAGE.DUPLICATED_VENDORS,
    duplicatedMilestoneNames: this.formControlConstants.VALIDATION_MESSAGE
      .DUPLICATED_MILESTONE_NAMES,
  };

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private fc: NgControl,
    // @Optional() private fc?: FormControlName,
    @Optional() private fg?: FormGroupDirective
  ) {
    this.showErrors.pipe(untilDestroyed(this)).subscribe((showErrors) => {
      if (showErrors && this.errorDiv) {
        this.removeErrorDiv();
        this.createErrorDiv();
        if (this.fc.errors) {
          this.renderer.addClass(this.errorDiv, 'text-aux-error');
          this.renderer.addClass(this.errorDiv, 'pl-3');
          this.renderer.addClass(this.errorDiv, 'text-sm');
          this.renderer.addClass(this.errorDiv, 'mt-0.5');
          for (const [key, val] of Object.entries(this.fc.errors)) {
            if (val) {
              const div = this.renderer.createElement('div');
              div.innerHTML = this.parse(this.errorMessages[key] || `${key} error`, {
                ...this.fc.errors,
                label: this.label || this.labelForErrorMessage || 'Field',
              });
              this.renderer.appendChild(this.errorDiv, div);
            }
          }
        }
      } else if (this.errorDiv) {
        this.removeErrorDiv();
      }
    });
  }

  protected _elementClass: string[] = [];

  @Input('class')
  @HostBinding('class')
  get elementClass(): string {
    return this._elementClass.join(' ');
  }

  set elementClass(val: string) {
    this._elementClass = val.split(' ');
  }

  parse(text: string, obj: { [k: string]: any } = {}) {
    return text.replace(/{{(.*?)}}/g, (match: string, firstMatch: string) => {
      try {
        return get(obj, firstMatch) || '';
      } catch (e) {
        return '';
      }
    });
  }

  removeErrorDiv(): void {
    this.renderer.removeChild(this.el.nativeElement.parentNode, this.errorDiv);
  }

  createErrorDiv() {
    this.errorDiv = this.renderer.createElement('div');
    this.renderer.appendChild(this.el.nativeElement.parentNode, this.errorDiv);
  }

  @HostListener('blur') onBlur() {
    this.onBlur$.emit();
  }

  ngOnInit(): void {
    merge(
      this.fg ? this.fg.ngSubmit.asObservable() : EMPTY,
      this.fc.statusChanges as Observable<any>,
      this.onBlur$
    )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isInvalid.next(
          !!((this.fg?.submitted || this.fc.control?.touched) && this.fc.control?.errors)
        );
        this.isValid.next(!!this.fc.control?.touched && !this.fc.control?.errors);

        this.showErrors.next(!!(this.fg?.submitted || this.fc.control?.touched));

        const classesObj = {
          'is-invalid': this.isInvalid.getValue(),
          'is-valid': this.isValid.getValue(),
        };

        const classes = this._elementClass.filter((x) => x !== 'is-invalid' && x !== 'is-valid');
        for (const [key, value] of Object.entries(classesObj)) {
          if (value) {
            classes.push(key);
          }
        }
        this.elementClass = classes.join(' ');
      });
  }

  ngAfterViewInit(): void {
    this.createErrorDiv();
    this.showErrors.next(this.showErrors.getValue());

    if (!this.label) {
      const input = this.el.nativeElement as HTMLInputElement;
      if (input.labels?.length) {
        this.label = input.labels[0].textContent || '';
      }
    }
  }
}
