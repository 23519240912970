export const WORKFLOW_NAMES = {
  ADJUSTMENT: 'Open Month Adjustments',
  TIMELINE: 'Trial Timeline',
  SITE_AND_PATIENTS_CURVES: 'Site and Patient Curves',
  PATIENT_TRACKER: 'Patient Tracker',
  FORECAST_METHODOLOGY: 'Forecast Methodology',
  INVOICES: 'Invoices',
};

export const MONTH_ADJUSTMENT_TOOLTIP =
  'Prior Closing Checklist entries must all be confirmed before adjustments can be locked';
