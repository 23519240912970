<form [formGroup]="filterForm" class="flex flex-col h-full bg-white border border-aux-gray-dark">
  <div class="flex items-center justify-between py-2 px-4">
    <div class="flex items-center">
      <span inlineSVG="task.svg" [setSVGAttributes]="{ class: 'w-4 h-4' }" class="mr-2"></span>
      <p class="font-bold text-lg">Tasks</p>
    </div>

    <div class="flex">
      <ng-select
        formControlName="trial"
        class="select w-40"
        [items]="trialOptions"
        [searchable]="false"
        [appendTo]="'body'"
        bindValue="value"
        placeholder="Trial"
      >
      </ng-select>

      <ng-select
        class="select w-40 ml-1.5"
        formControlName="type"
        [items]="taskTypeOptions"
        [searchable]="false"
        bindValue="value"
        [appendTo]="'body'"
        placeholder="Type"
      >
      </ng-select>
    </div>
  </div>
  <aux-portfolio-task-list
    *ngIf="{
      filteredUserListTasks: (filteredUserListTasks$ | async) || [],
      originalUserListTasks: (originalUserListTasks$ | async) || []
    } as obj"
    [tasks]="obj.filteredUserListTasks"
    [noTasksMessage]="
      !obj.filteredUserListTasks.length && obj.originalUserListTasks.length
        ? noTasksMessages.FILTER
        : noTasksMessages.NO_TASKS
    "
    class="grow overflow-y-auto h-full"
  ></aux-portfolio-task-list>
</form>
