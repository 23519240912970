<div
  *ngFor="let task of tasks; index as index"
  class="px-3 py-4 flex items-center justify-between hover:bg-aux-blue-light-50 cursor-pointer"
  [ngClass]="{
    'bg-aux-gray-light': index % 2 === 0
  }"
  (click)="navigateToTask(task)"
>
  <div>
    <p>{{ task.task_header }}</p>
    <p class="text-sm">{{ task.trial_name }}</p>
    <p class="text-sm" *ngIf="task.organization_name">Vendor: {{ task.organization_name }}</p>
  </div>
  <div class="flex items-center">
    <div>
      <p class="text-sm text-right">{{ task.create_date | timeago }}</p>
    </div>
    <span inlineSVG="chevron-right.svg" class="ml-3 text-aux-blue"></span>
  </div>
</div>

<div *ngIf="!tasks.length" class="flex flex-col items-center justify-center h-full">
  <span inlineSVG="big-check.svg" class="ml-3 text-aux-blue"></span>
  <p class="text-sm text-aux-gray-darkest mt-4">{{ noTasksMessage }}</p>
</div>
