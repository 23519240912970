import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'aux-ag-cis-log-user-approval',
  template: `
    <ng-container *ngIf="val.user_approval_required === 0"> - </ng-container>
    <ng-container *ngIf="val.user_approval_required > 0">
      <ng-container *ngIf="val.approved_by_user === 0">
        <button class="btn btn--blue">Verify</button>
      </ng-container>
      <ng-container *ngIf="val.approved_by_user === 1">
        <div class="w-5 h-5 bg-aux-green rounded-full text-white flex items-center justify-center">
          <check-outline-icon [size]="16"></check-outline-icon>
        </div>
      </ng-container>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgCisLogUserApprovalComponent implements ICellRendererAngularComp {
  params: ICellRendererParams | undefined;

  val = {
    approved_by_user: 0,
    user_approval_required: 0,
  };

  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.val = params.value;
  }
}
