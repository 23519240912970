import { Route, Routes } from '@angular/router';
import { buildRoutesByFeatureFlags, FeatureFlag } from '@models/feature-flag.model';
import { Flags } from '@services/launch-darkly.service';
import { SitesComponent } from './sites/sites.component';
import { InvestigatorForecastComponent } from './investigator-forecast/investigator-forecast.component';
import { ROUTING_PATH } from '../../app-routing-path.const';
import { InvestigatorDetailComponent } from './investigator-detail/investigator-detail.component';
import { SitesPageComponent } from './sites-page.component';

export const SITES_ROUTES_FF: Routes = [
  {
    path: ROUTING_PATH.SITES.INVESTIGATOR_DETAIL,
    component: InvestigatorDetailComponent,
    data: {
      feature: FeatureFlag.SITES_INVESTIGATOR_DETAIL,
    },
  },
  {
    path: ROUTING_PATH.SITES.INVESTIGATOR_FORECAST,
    component: InvestigatorForecastComponent,
    data: {
      feature: FeatureFlag.SITES_INVESTIGATOR_FORECAST,
    },
  },
];

export const buildSitesRoutes = (featureFlags: Flags | null): Route => {
  const enabledRoutes: Routes = [
    ...buildRoutesByFeatureFlags(featureFlags, SITES_ROUTES_FF),
    { path: ROUTING_PATH.SITES.TRIAL_SITES, component: SitesComponent },
  ];

  return {
    path: ROUTING_PATH.SITES.INDEX,
    component: SitesPageComponent,
    children: [
      ...enabledRoutes,
      {
        path: '**',
        redirectTo: (enabledRoutes.length && enabledRoutes[0].path) || '/',
      },
    ],
  };
};
