<section class="w-full flex bg-aux-gray-light border-l-4 border-aux-blue" [ngClass]="className">
  <ng-container *ngIf="(isLoading$ | async) === false">
    <div class="flex flex-col items-center justify-center border border-aux-gray-dark">
      <span inlineSVG="arrow-left-circle.svg" class="text-aux-blue m-1.5"></span>
      <a class="aux-link text-lg text-center" [routerLink]="[quarterCloseLink]"
        >Month/Quarter Close</a
      >
    </div>
    <div class="grid grid-cols-1 w-full">
      <aux-workflow-panel-general-item
        [workflowName]="workflowName"
        [isAdminUser]="isAdminUser"
        [auditTextCallback]="getTitleFn"
        [lockAvailable]="!processing"
      >
      </aux-workflow-panel-general-item>
      <div class="border border-aux-gray-dark grid grid-flow-col">
        <div
          *ngFor="let workflow of workflowList$ | async as workflowList; let i = index"
          class="flex items-center"
        >
          <div class="flex justify-between items-center pl-4 pr-4 pt-1.5 pb-1.5 w-full">
            <div>
              <a class="aux-link" [routerLink]="'/' + workflow.properties.route">{{
                workflow.name
              }}</a>
              <p class="text-sm">{{ getAuditTextFn(workflow) }}</p>
            </div>
            <span inlineSVG="lock.svg" *ngIf="workflow.properties.locked"></span>
            <span inlineSVG="lock-open.svg" *ngIf="!workflow.properties.locked"></span>
          </div>
          <div
            class="border-aux-gray-dark border-l h-6 mr-3 mr-3"
            *ngIf="i !== workflowList.length - 1"
          ></div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="border-8 m-auto spinner h-16 w-16" *ngIf="isLoading$ | async"></div>
</section>
