<div [ngSwitch]="variant">
  <ng-template [ngSwitchCase]="excelButtonVariants.OUTLINE">
    <div class="col-span-1">
      <button class="btn--secondary pl-3" [ngClass]="className" (click)="exportExcel()">
        <div inlineSVG="file-export.svg" class="w-4 pr-8" [setSVGAttributes]="svgAttributes"></div>
        {{ title }}
      </button>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="excelButtonVariants.OUTLINE_DOWNLOAD">
    <button class="btn--secondary" [ngClass]="className" (click)="exportExcel()">
      <span inlineSVG="download.svg" [setSVGAttributes]="svgAttributes"></span>
      <span class="ml-2">{{ title }}</span>
    </button>
  </ng-template>

  <ng-template [ngSwitchCase]="excelButtonVariants.FILLED">
    <button
      type="button"
      class="text-sm btn btn--blue"
      [ngClass]="className"
      (click)="exportExcel()"
    >
      <span inlineSVG="download.svg" [setSVGAttributes]="svgAttributes"></span>
      <span class="ml-2">{{ title }}</span>
    </button>
  </ng-template>
</div>
