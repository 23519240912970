<div
  id="wOverlay"
  class="w-full relative"
  *ngIf="data"
  [ngClass]="{
    'rounded-md shadow-sm ring-2 ring-aux-error border-aux-error': showError && !disabled
  }"
>
  <div *ngIf="label" class="text-sm grid items-center">
    <span>{{ label }}</span>
  </div>
  <div
    class="bg-white text-gray-500 text-sm flex justify-between items-center px-3 h-12 w-full font-medium rounded-md shadow-sm border-aux-gray-dark border"
    (click)="!disabled && openList()"
    [ngClass]="{
      'bg-aux-gray-light': disabled
    }"
  >
    <ng-container *ngIf="selected; else placeholderTemplate">
      <span class="text-aux-black text-xs" [ngClass]="{ 'text-aux-gray-dark-100': disabled }">{{
        selected
      }}</span>
    </ng-container>

    <ng-template #placeholderTemplate>
      <span class="text-aux-black text-xs" [ngClass]="{ 'text-aux-gray-dark-100': disabled }">{{
        placeholder
      }}</span>
    </ng-template>

    <div *ngIf="!disabled">
      <chevron-down-solid-icon class="text-aux-black"></chevron-down-solid-icon>
    </div>
  </div>

  <button
    *ngIf="!disabled && selected"
    (click)="onClear($event)"
    class="absolute right-9 top-3 p-1 cursor-pointer"
  >
    <x-solid-icon class="text-aux-gray-lightest" [size]="14"></x-solid-icon>
  </button>

  <div>
    <div cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>
  </div>
  <!-- <div (click)="isOpen = !isOpen" type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
      {{ selected }}
    </div> -->
  <ng-template
    cdkConnectedOverlay
    class="border-solid border-aux-gray-dark-100"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'ttt'"
    [cdkConnectedOverlayPositions]="positions"
    (backdropClick)="closeList()"
    (detach)="closeList()"
    [cdkConnectedOverlayOffsetY]="8"
  >
    <ul
      tabindex="-1"
      [style.width.px]="getPx"
      class="bg-white border-solid border-aux-gray-light rounded-md py-1 text-base ring-1 overflow-auto focus:outline-none sm:text-sm"
    >
      <ng-container class="grid" *ngFor="let item of data; index as index; let first = first">
        <li
          class="text-gray-900 cursor-default select-none relative flex flex-col"
          (click)="selectCurve(item)"
        >
          <div
            *ngIf="item.showLine && !first"
            class="border-b h-1 border-aux-gray-darkest mb-2 mt-1 mr-2 ml-2"
          ></div>

          <div
            (mouseenter)="highlightCurve(index)"
            (mouseleave)="highlightedCurve = null"
            class="text-aux-black text-sm cursor-pointer select-none relative py-2 pl-2 flex justify-between"
            [ngClass]="{
              'bg-aux-gray-light': index === highlightedCurve
            }"
          >
            {{ item.name }}
            <div *ngIf="isCurve" class="flex mr-2">
              <button
                *ngIf="item.is_blended"
                class="w-3 text-aux-blue-dark mr-1"
                (click)="editCurve(item)"
              >
                <span inlineSVG="edit.svg" [setSVGAttributes]="{ class: 'w-3 h-3' }"></span>
              </button>
              <button *ngIf="item.driver_setting_id.length > 1" class="w-3 text-aux-red-hover">
                <span
                  inlineSVG="trash.svg"
                  [setSVGAttributes]="{ class: 'w-4 h-4' }"
                  (click)="deleteCurve(item)"
                ></span>
              </button>
            </div>
          </div>
        </li>
      </ng-container>
      <div *ngIf="isCurve" class="border-b h-1 border-aux-gray-darkest mb-2 mt-1 mr-2 ml-2"></div>
      <div *ngIf="isCurve" class="aux-link pl-2 mb-2">
        <span class="cursor-pointer" (click)="editCurve()">+ Create Blended Curve</span>
      </div>
    </ul>
  </ng-template>
</div>
