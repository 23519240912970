import { ExpenseType } from '@services/gql.service';
import { Utils } from '@services/utils';
import { ColDef, ColGroupDef, ValueFormatterParams } from 'ag-grid-community';
import { round } from 'lodash-es';
import { VariationStatusComponent } from 'src/app/pages/design-system/tables/variation-status.component';
import { VisibleColumns } from './column-chooser-component/column-chooser.component';
import { TableConstants } from '../../../../constants/table.constants';

export enum cellSize {
  xLarge = 150,
  large = 125,
  medium = 100,
  small = 75,
}

export const period_sorting = [
  Utils.SHORT_MONTH_NAMES[0],
  Utils.SHORT_MONTH_NAMES[1],
  Utils.SHORT_MONTH_NAMES[2],
  'Q1',
  Utils.SHORT_MONTH_NAMES[3],
  Utils.SHORT_MONTH_NAMES[4],
  Utils.SHORT_MONTH_NAMES[5],
  'Q2',
  Utils.SHORT_MONTH_NAMES[6],
  Utils.SHORT_MONTH_NAMES[7],
  Utils.SHORT_MONTH_NAMES[8],
  'Q3',
  Utils.SHORT_MONTH_NAMES[9],
  Utils.SHORT_MONTH_NAMES[10],
  Utils.SHORT_MONTH_NAMES[11],
  'Q4',
];

const agCurrencyFormatter = (val: ValueFormatterParams) => {
  if (val?.data) {
    if (val.data.expense_note && (val.colDef.field || '').indexOf('direct_cost') >= 0) {
      return val.data.expense_note;
    }
  }
  if (val?.value) {
    if (!Number.isNaN(val.value)) {
      return Utils.currencyFormatter(val.value);
    }
  }
  return Utils.zeroHyphen;
};

const percentageFormatter = (val: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  let fVal = Utils.zeroHyphen;
  if (val) {
    fVal = `${formatter.format(val)}%`;
  }
  return fVal;
};

const shouldHideUnits = (params: ValueFormatterParams): boolean => {
  if (
    params?.node?.aggData ||
    params?.data?.activity_name === 'Total' ||
    params?.data?.cost_category === 'Discount'
  ) {
    return true;
  }

  return false;
};

export const actualsToDateColumnDef = (
  visible_columns: VisibleColumns['actuals_to_date']
): ColDef | ColGroupDef => ({
  headerName: 'Actuals to Date',
  headerClass: 'ag-header-align-center',
  colId: 'WP',
  children: [
    {
      headerName: 'Unit(s)',
      field: 'wp_unit_num',
      width: cellSize.medium,
      minWidth: cellSize.medium,
      hide: !visible_columns.units,
      columnGroupShow: 'closed',
      headerClass: 'ag-header-align-center',
      cellClass: ['ag-cell-align-right', 'budget-units'],
      valueFormatter: (params) => {
        const bool = shouldHideUnits(params);
        if (bool) {
          return Utils.zeroHyphen;
        }

        return Utils.decimalFormatter(params.data?.wp_unit_num);
      },
    },
    {
      headerName: '$',
      headerClass: 'ag-header-align-center',
      field: 'wp_cost',
      hide: !visible_columns.costs,
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      valueFormatter: agCurrencyFormatter,
      aggFunc: 'sum',
      columnGroupShow: 'closed',
      cellClass: ['ag-cell-align-right', 'budget-cost'],
    },
    {
      headerName: '%',
      columnGroupShow: 'closed',
      field: 'wp_percentage',
      width: cellSize.small,
      minWidth: cellSize.small,
      hide: !visible_columns.perc,
      headerClass: 'ag-header-align-center',
      cellClass: ['ag-cell-align-right', 'budget-percent'],
      valueFormatter: ({ value }) => Utils.percentageFormatter(value / 100),
    },
  ],
});

export const rowGroupsColumnDef: ((ColDef | ColGroupDef) & {
  hideForAllVendorSelection?: boolean;
  children?: (ColDef & { hideForAllVendorSelection?: boolean })[];
})[] = [
  {
    headerName: 'Vendor',
    field: 'vendor_name',
    rowGroup: true,
    hide: true,
  },
  {
    headerName: 'Cost Category',
    field: 'cost_category',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group0',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Label',
    field: 'display_label',
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Activities',
    field: 'activity_name_label',
    tooltipField: 'activity_name',
    valueFormatter: Utils.dashFormatter,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group1',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group2',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group3',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group4',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    hide: true,
    field: `${ExpenseType.EXPENSE_QUOTE}::LATEST`,
    aggFunc: 'sum',
  },
  {
    hide: true,
    field: `${ExpenseType.EXPENSE_WP}::TO_DATE`,
    aggFunc: 'sum',
  },
];

export const overallBudgetColumnDef = (
  visible_columns: VisibleColumns['overall_budget'],
  compareToSelected?: string
): ColDef | ColGroupDef => ({
  headerName: 'Overall Budget',
  headerClass: 'ag-header-align-center bg-aux-gray-dark aux-black border-0',
  children: [
    {
      headerName: 'Unit(s)',
      headerClass: 'ag-header-align-center',
      field: 'unit_num',
      width: cellSize.large,
      minWidth: cellSize.large,
      hide: !visible_columns.units,
      valueFormatter: (params) => {
        const bool = shouldHideUnits(params);
        if (bool) {
          return Utils.zeroHyphen;
        }

        return Utils.decimalFormatter(params.data?.unit_num);
      },
      cellClass: ['ag-cell-align-right', 'budget-units'],
    },
    {
      headerName: 'Unit Cost',
      headerClass: 'ag-header-align-center',
      field: 'unit_cost',
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      hide: !visible_columns.unit_cost,
      valueFormatter: (params) => {
        const bool = shouldHideUnits(params);
        if (bool) {
          return Utils.zeroHyphen;
        }

        return agCurrencyFormatter(params);
      },
      cellClass: ['ag-cell-align-right', 'budget-cost'],
    },
    {
      headerName: 'Current (LRE)',
      headerClass: 'ag-header-align-center',
      field: 'current_lre',
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      hide: !visible_columns.primary,
      valueFormatter: agCurrencyFormatter,
      aggFunc: 'sum',
      cellClass: ['ag-cell-align-right', 'budget-cost'],
    },
    {
      ...TableConstants.dynamicColumnProps(compareToSelected || ''),
      field: 'snapshot_lre',
      minWidth: cellSize.xLarge,
      hide: !compareToSelected,
      valueFormatter: agCurrencyFormatter,
      aggFunc: 'sum',
      cellClass: ['ag-cell-align-right', 'budget-cost'],
    },
    {
      headerName: 'Baseline',
      headerClass: 'ag-header-align-center',
      field: 'baseline',
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      hide: !!compareToSelected,
      valueFormatter: agCurrencyFormatter,
      aggFunc: 'sum',
      cellClass: ['ag-cell-align-right', 'budget-cost'],
    },
    {
      headerName: 'Var ($)',
      headerClass: 'ag-header-align-center',
      field: `var_amount`,
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      cellRenderer: VariationStatusComponent,
      valueFormatter: ({ value }) => Utils.currencyFormatter(value),
      aggFunc: 'sum',
      cellClass: ['ag-cell-align-right', 'budget-cost'],
    },
    {
      headerName: 'Var (%)',
      headerClass: 'ag-header-align-center',
      field: `var_percent`,
      width: cellSize.medium,
      minWidth: cellSize.medium,
      cellRenderer: VariationStatusComponent,
      valueFormatter: (params) => {
        let percent = params.data?.var_percent;
        if (params.node?.aggData) {
          const { aggData } = params.node;
          const budgetSum = (compareToSelected ? aggData.snapshot_lre : aggData.baseline) || 0;
          const current_lre = aggData.current_lre || 0;
          const var_amount = current_lre - budgetSum;
          let var_percent = 0;
          if (budgetSum) {
            var_percent = budgetSum ? (var_amount / Math.abs(budgetSum)) * 100 : -100;
          }
          percent = round(var_percent, 2);
        }
        return percentageFormatter(Math.abs(percent));
      },
      aggFunc: 'sum',
      cellClass: ['ag-cell-align-right', 'budget-percent-no-mult'],
    },
  ],
});

export const remainingBudgetColDef = (
  visible_columns: VisibleColumns['remaining_budget']
): ColDef | ColGroupDef => ({
  headerName: 'Remaining Budget',
  headerClass: 'ag-header-align-center bg-aux-gray-dark aux-black border-0',
  children: [
    {
      headerName: 'Unit(s)',
      field: 'remaining_unit_num',
      width: cellSize.medium,
      minWidth: cellSize.medium,
      headerClass: 'ag-header-align-center',
      hide: !visible_columns.units,
      cellClass: ['ag-cell-align-right', 'budget-units'],
      valueFormatter: (params) => {
        const bool = shouldHideUnits(params);
        if (bool) {
          return Utils.zeroHyphen;
        }

        return Utils.decimalFormatter(params.data?.remaining_unit_num);
      },
    },
    {
      headerName: '$',
      headerClass: 'ag-header-align-center',
      field: 'remaining_cost',
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      hide: !visible_columns.costs,
      valueFormatter: agCurrencyFormatter,
      aggFunc: 'sum',
      cellClass: ['ag-cell-align-right', 'budget-cost'],
    },
    {
      headerName: '%',
      headerClass: 'ag-header-align-center',
      valueFormatter: ({ value }) => Utils.percentageFormatter(value / 100),
      field: 'remaining_percentage',
      hide: !visible_columns.perc,
      cellClass: ['ag-cell-align-right', 'budget-percent'],
      width: cellSize.small,
      minWidth: cellSize.small,
    },
  ],
});
