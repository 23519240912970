<div class="text-xl font-bold mb-4">Create New Trial</div>
<form [formGroup]="fg" (ngSubmit)="onSubmit()" *ngIf="(mode$ | async) === 'search'">
  <div class="flex items-center grid grid-cols-3 gap-5">
    <aux-input
      class="flex-grow"
      label="Trial ID"
      formControlName="nct_id"
      validators="required"
      (blur)="onInputBlur()"
    ></aux-input>

    <aux-input
      label="Auxilius Start Date"
      class="flex-grow"
      formControlName="auxilius_start_date"
      [type]="'date'"
      placeholder="YYYY-MM-DD"
    ></aux-input>

    <div class="flex flex-col">
      <div class="text-xs mb-2">Skip Onboarding</div>
      <aux-toggle formControlName="onboarding_complete"></aux-toggle>
    </div>
  </div>

  <div class="mt-2" *ngVar="sponsor$ | async as sponsor">
    <ng-container *ngIf="sponsor?.success">
      <div class="text-xxs">Sponsor Name</div>
      <div class="font-semibold">{{ sponsor.data }}</div>
    </ng-container>

    <ng-container *ngIf="showSponsorError$ | async">
      <div class="text-aux-error inline-block">{{ sponsor.error }}</div>
      <button type="button" class="aux-link ml-4 no-underline" (click)="mode$.next('custom')">
        Create custom trial
      </button>
    </ng-container>
  </div>

  <div class="flex space-x-4 mt-8">
    <button
      class="text-sm font-normal btn btn--blue w-32"
      type="submit"
      [disabled]="!(sponsor$ | async)?.success"
    >
      Continue
    </button>

    <button
      class="text-sm font-normal aux-link focus:outline-none"
      (click)="ref.close()"
      type="button"
    >
      Cancel
    </button>
  </div>
</form>

<form [formGroup]="customFg" (ngSubmit)="createCustomTrial()" *ngIf="(mode$ | async) === 'custom'">
  <div class="grid grid-cols-2 gap-5">
    <aux-input label="Trial Name" formControlName="name" validators="required"></aux-input>
    <div class="flex flex-col">
      <div class="text-xs mb-2">Skip Onboarding</div>
      <aux-toggle formControlName="onboarding_complete" class="mt-2"></aux-toggle>
    </div>

    <aux-input label="Short Name" formControlName="short_name" validators="required"></aux-input>
    <aux-input
      label="Sponsor"
      formControlName="sponsor_organization_name"
      validators="required"
    ></aux-input>
    <aux-input label="Title" formControlName="title" validators="required"></aux-input>
    <aux-input
      label="Therapy area"
      formControlName="therapy_area"
      validators="required"
    ></aux-input>
    <aux-input
      label="Auxilius Start Date"
      class="flex-grow"
      formControlName="auxilius_start_date"
      [type]="'date'"
      placeholder="YYYY-MM-DD"
    ></aux-input>
  </div>

  <div class="flex space-x-4 mt-8">
    <button class="text-sm font-normal btn btn--blue w-32" type="submit">Continue</button>

    <button
      class="text-sm font-normal aux-link focus:outline-none"
      (click)="ref.close()"
      type="button"
    >
      Cancel
    </button>

    <button
      class="text-sm font-normal aux-link focus:outline-none"
      (click)="mode$.next('search')"
      type="button"
    >
      Back
    </button>
  </div>
</form>
