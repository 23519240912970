import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export function gql$<T>(
  fn: Promise<T>
): Observable<{ success: boolean; data: T | null; errors: string[] }> {
  return from(fn).pipe(
    map((response) => {
      if (response) {
        return {
          success: true,
          data: response as T,
          errors: [] as string[],
        };
      }

      if (((response as unknown) as any).errors) {
        return {
          success: false,
          data: null,
          errors: (((response as unknown) as any).errors || []).map(
            (res: { message: string }) => res.message
          ) as string[],
        };
      }

      return {
        success: false,
        data: null,
        errors: [] as string[],
      };
    }),
    catchError((err) => {
      return of({
        success: false,
        data: null,
        errors: (err.errors || []).map((res: { message: string }) => res.message) as string[],
      });
    })
  );
}
