<div class="font-inter w-screen max-w-2xl max-h-100 scrollbar-visible overflow-auto">
  <div class="text-xl font-bold">Confirm Manual Override</div>
  <div>Vendor: {{ getVendorName() }}</div>
  <div class="mb-8">Activity: {{ sub_activity.name }}</div>

  <ng-container *ngIf="!previewMode">
    <div class="text-xs mb-2">Select Override Type</div>

    <div class="flex space-x-4 text-sm mb-8">
      <div class="flex items-center">
        <input
          name="override_type"
          type="radio"
          value="units"
          id="units"
          [(ngModel)]="override_type"
          class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
          [disabled]="isUnitsDisable"
        />
        <label class="ml-2 block" for="units">Units (#)</label>
      </div>

      <div class="flex items-center">
        <input
          name="override_type"
          type="radio"
          value="costs"
          id="costs"
          [(ngModel)]="override_type"
          class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
        />
        <label class="ml-2 block" for="costs">Cost ($)</label>
      </div>
    </div>
  </ng-container>
  <div
    class="grid gap-y-4 text-sm mb-8 items-center"
    style="grid-template-columns: 1fr 1fr 1fr 2rem 1fr 1fr"
  >
    <div class="text-center border-b pb-1">Unit Cost</div>
    <div class="text-center border-b pb-1">Units (Fcst)</div>
    <div class="text-center border-b pb-1">Units (Modified)</div>
    <div></div>
    <div class="text-center border-b pb-1">Cost (Fcst)</div>
    <div class="text-center border-b pb-1">Cost (Modified)</div>

    <div class="text-center tabular-nums">
      {{ sub_activity.unit_cost | money }}
    </div>
    <div class="text-center tabular-nums">
      {{ units !== 0 ? (units | number: '1.00') : zeroHyphen }}
    </div>
    <div class="text-center">
      <aux-input
        [(ngModel)]="modified_units"
        type="number"
        *ngIf="!previewMode && override_type === 'units'"
        (ngModelChange)="onUnitChanged()"
      >
      </aux-input>
      <div *ngIf="previewMode || override_type === 'costs'" class="text-aux-blue tabular-nums">
        {{ modified_units !== 0 ? (modified_units_display | accounting) : zeroHyphen }}
      </div>
    </div>

    <div class="text-center"></div>
    <div class="text-center tabular-nums">
      {{ sub_activity[BudgetType.BUDGET_PRIMARY]?.total_forecast | money }}
    </div>
    <div class="text-center">
      <aux-input
        [(ngModel)]="modified_costs_display"
        [type]="'number'"
        *ngIf="!previewMode && override_type === 'costs'"
        (ngModelChange)="onCostChanged()"
      >
      </aux-input>
      <div *ngIf="previewMode || override_type === 'units'" class="text-aux-blue tabular-nums">
        {{ modified_costs_display | money }}
      </div>
    </div>
  </div>

  <div *ngIf="budget_override_notes.length > 0" class="max-h-32 overflow-y-auto">
    <div *ngFor="let note of budget_override_notes" class="mb-4">
      <span class="text-sm text-aux-blue font-bold mr-2">
        {{ note.created_by }}
      </span>
      <span class="text-sm tabular-nums">
        {{ note.create_date | timeago }}
      </span>
      <div class="text-sm border-l-2 mt-2 border-aux-blue w-4/5">
        <div class="break-words ml-2">{{ note.message }}</div>
      </div>
    </div>
  </div>
  <div class="mb-8" *ngIf="!previewMode">
    <div class="mb-1 text-xxs"><span class="text-aux-error">*</span>Notes</div>
    <aux-input [(ngModel)]="noteAddedByUser" validators="required" [textArea]="true"></aux-input>
  </div>

  <div class="max-w-lg" *ngIf="!previewMode">
    <div class="mb-1 text-xxs">Upload Support</div>

    <aux-file-manager
      class="h-32"
      #fileManager
      [fetchFilesOnInit]="false"
      [pathFn]="pathFn"
      [eager]="false"
      [metadata]="metadata"
      [insertDocument]="true"
    ></aux-file-manager>
    <div class="max-h-60 overflow-auto mt-4">
      <aux-file-viewer
        [fileManager]="fileManager"
        [disableFirstFileMargin]="true"
        [onlyShowUploaded]="false"
      >
      </aux-file-viewer>
    </div>
  </div>

  <div *ngIf="error$ | async as error" class="-mb-4 bg-aux-error mt-4 p-4 rounded-md text-white">
    <div>{{ error }}</div>
  </div>
</div>
<div class="mt-2 sticky bottom-0">
  <div class="flex space-x-4">
    <aux-button
      variant="primary"
      label="Save"
      classList="w-48"
      [disabled]="noteAddedByUser.length < 1"
      [auxTooltip]="saveButtonTooltipText"
      [loading]="loading$ | async"
      [onClick]="this.onSave.bind(this)"
    ></aux-button>

    <button
      class="text-sm font-normal aux-link focus:outline-none"
      (click)="ref.close()"
      type="button"
    >
      Cancel
    </button>
  </div>
</div>
