import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DriverSiteGroup } from '@services/gql.service';

export interface SiteCurveState
  extends EntityState<
    DriverSiteGroup & {
      showLine: boolean;
      site_group_ids: string[];
      site_group_id: string;
    }
  > {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'site-curve', idKey: 'driver_setting_id' })
export class SiteCurveStore extends EntityStore<SiteCurveState> {
  constructor() {
    super({});
  }
}
