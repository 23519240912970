import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import {
  CellValueChangedEvent,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDragEndEvent,
  RowNode,
} from 'ag-grid-community';
import { isEqual } from 'lodash-es';
import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';
import { PatientProtocolQuery } from '@models/patient-protocol/patient-protocol.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventService } from '@services/event.service';
import {
  CreatePatientProtocolInput,
  EventType,
  PatientGroupType,
  PatientProtocolType,
} from '@services/gql.service';
import { map } from 'rxjs/operators';
import { RequireSome, Utils } from '@services/utils';
import { OverlayService } from '@services/overlay.service';
import { PatientProtocolStore } from '@models/patient-protocol/patient-protocol.store';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { MessagesConstants } from '../../../constants/messages.constants';
import { TableConstants } from '../../../constants/table.constants';
import { PatientGroupsService } from '../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.service';
import { PatientGroupsQuery } from '../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.query';

interface PatientProtocolGridData {
  target_tolerance_days_out?: number | undefined;
  target_date_days_out?: number | undefined;
  patient_protocol_name?: string | undefined;
  patient_group_id?: string | undefined;
  patient_protocol_id: string;
  patient_protocol_type?: PatientProtocolType | undefined;
  order_by: number;
}

enum PatientProtocolForVisits {
  PATIENT_PROTOCOL_PATIENT_VISIT = 'PATIENT_PROTOCOL_PATIENT_VISIT',
}

enum PatientProtocolForOthers {
  PATIENT_PROTOCOL_DISCONTINUED = 'PATIENT_PROTOCOL_DISCONTINUED',
  PATIENT_PROTOCOL_OTHER = 'PATIENT_PROTOCOL_OTHER',
  PATIENT_PROTOCOL_OVERHEAD = 'PATIENT_PROTOCOL_OVERHEAD',
  PATIENT_PROTOCOL_SCREEN_FAIL = 'PATIENT_PROTOCOL_SCREEN_FAIL',
}
@UntilDestroy()
@Component({
  selector: 'aux-patient-protocol-edit',
  templateUrl: './patient-protocol-edit.component.html',
  styles: [
    `
      ::ng-deep #patientBudgetEntryGrid .has-error .ag-cell {
        background-color: #fee2e2 !important;
      }

      ::ng-deep #patientBudgetEntryGrid .ag-rich-select {
        width: 270px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientProtocolEditComponent {
  tabs: { id: string; label: string; show: Observable<boolean>; isGroup: boolean }[] = [
    { id: '', label: 'Visit Costs', isGroup: false, show: of(true) },
    {
      id: '',
      label: 'Other Costs',
      isGroup: false,
      show: of(true),
    },
  ];

  activeTabIndex$ = new BehaviorSubject<number>(0);

  gridOptions$: BehaviorSubject<GridOptions> = new BehaviorSubject({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      cellRenderer: AgCellWrapperComponent,
      editable: () => {
        return this.editModeGrid$.getValue();
      },
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    enableRangeSelection: true,
    suppressMenuHide: true,
    rowClassRules: {
      'has-error': (params) => params.data.showError,
    },
    rowDragManaged: true,
    getRowId: ({ data }) => data?.patient_protocol_id || data?.randomID,
    columnDefs: [
      {
        headerName: 'patient_protocol_id',
        field: 'patient_protocol_id',
        hide: true,
      },
      {
        ...TableConstants.DEFAULT_GRID_OPTIONS.ACTIONS_COL_DEF,
        cellRendererParams: {
          deleteClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            if (rowNode.data.patient_protocol_id) {
              this.removedRows.push(rowNode.data.patient_protocol_id);
            } else if (this.addedRowCounter === 1) {
              this.reorderedRows.clear();
              this.removedRows = [];
              this.newRowAdded = false;
            } else {
              this.removedRows.pop();
            }
            this.addedRowCounter -= 1;
            this.gridAPI.applyTransaction({ remove: [rowNode.data] });
            this.checkChanges();
          },
        },
        rowDrag: true,
        hide: true,
      },
      {
        headerName: 'RANDOM ID',
        field: 'randomID',
        hide: true,
      },
      {
        headerName: 'Name',
        field: 'patient_protocol_name',
        resizable: true,
        cellClass: 'text-left',
        tooltipField: 'patient_protocol_name',
      },
      {
        headerName: 'Target Date',
        field: 'target_date_days_out',
        minWidth: 150,
        valueFormatter: Utils.dashFormatter,
      },
      {
        headerName: 'Target Date [+/-]',
        field: 'target_tolerance_days_out',
        minWidth: 150,
        valueFormatter: Utils.dashFormatter,
      },
      {
        headerName: 'patient_group_id',
        field: 'patient_group_id',
        hide: true,
      },
      {
        headerName: 'Type',
        field: 'patient_protocol_type',
        cellEditor: 'agRichSelectCellEditor',
        minWidth: 300,
        cellClass: 'text-left',
        cellEditorParams: () => {
          return {
            values: Object.keys(
              this.patientGroupId$.getValue() || this.isVisit$.getValue()
                ? PatientProtocolForVisits
                : PatientProtocolForOthers
            ),
          };
        },
      },
    ],
  } as GridOptions);

  loading$ = this.patientProtocolQuery.selectLoading();

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  gridColumnApi$ = new ReplaySubject<ColumnApi>(1);

  gridColumnApi!: ColumnApi;

  gridData$: Observable<PatientProtocolGridData[]> = this.patientProtocolQuery.selectAll().pipe(
    map((value) => {
      return value.map(
        ({
          id,
          name,
          patient_protocol_type,
          target_tolerance_days_out,
          target_date_days_out,
          order_by,
        }) => ({
          patient_protocol_id: id,
          patient_protocol_name: name,
          patient_protocol_type,
          target_tolerance_days_out,
          target_date_days_out,
          order_by,
        })
      );
    })
  );

  editedRows = new Set<string>();

  removedRows: string[] = [];

  reorderQueue: { id: string; new_index: number }[] = [];

  reorderedRows = new Set<string>();

  addedRowCounter = 0;

  newRowAdded = false;

  initialValues: PatientProtocolGridData[] = [];

  hasChanges = false;

  editModeGrid$ = new BehaviorSubject(false);

  patientGroupId$ = new BehaviorSubject<string | null>(null);

  isVisit$ = new BehaviorSubject(false);

  constructor(
    private patientProtocolService: PatientProtocolService,
    private patientProtocolQuery: PatientProtocolQuery,
    private patientProtocolStore: PatientProtocolStore,
    private patientGroupService: PatientGroupsService,
    private patientGroupQuery: PatientGroupsQuery,
    private overlayService: OverlayService,
    private eventService: EventService
  ) {
    this.patientGroupService
      .get([PatientGroupType.PATIENT_GROUP_STANDARD])
      .pipe(untilDestroyed(this))
      .subscribe();

    this.patientGroupQuery
      .selectAll()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        const pGroups = x.map((y) => {
          return { id: y.id, label: y.name, show: of(true), isGroup: true };
        });
        this.tabs = [];
        if (pGroups.length > 0) {
          this.patientProtocolService
            .get([PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT], pGroups[0].id)
            .pipe(untilDestroyed(this))
            .subscribe();
          this.patientGroupId$.next(pGroups[0].id);
          this.tabs.unshift(
            ...pGroups,
            { id: '', label: 'Visit Costs', isGroup: false, show: of(true) },
            {
              id: '',
              label: 'Other Costs',
              isGroup: false,
              show: of(true),
            }
          );
        } else {
          this.patientGroupId$.next(null);
          this.patientProtocolService.get().pipe(untilDestroyed(this)).subscribe();
          this.tabs.push(
            { id: '', label: 'Visit Costs', isGroup: false, show: of(true) },
            { id: '', label: 'Other Costs', isGroup: false, show: of(true) }
          );
        }
      });

    this.gridData$.pipe(untilDestroyed(this)).subscribe((gridData) => {
      this.initialValues = gridData;
    });

    this.eventService
      .select$(EventType.TRIAL_CHANGED)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.initialValues = [];
        this.cancelEditMode();
      });
  }

  checkChanges() {
    const currentValues: PatientProtocolGridData[] = [];

    this.gridAPI?.forEachNode(({ data }) => {
      currentValues.push({
        ...data,
        target_date_days_out: data.target_date_days_out === '' ? 0 : data.target_date_days_out,
        target_tolerance_days_out:
          data.target_tolerance_days_out === '' ? 0 : data.target_tolerance_days_out,
      });
    });

    this.hasChanges = !isEqual(this.initialValues, currentValues);
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await result.afterClosed$.toPromise();
      return !!event.data;
    }
    return true;
  }

  onGridReady({ api, columnApi }: GridReadyEvent) {
    this.gridAPI$.next(api);
    this.gridAPI = api;
    this.gridColumnApi$.next(columnApi);
    this.gridColumnApi = columnApi;
    api.sizeColumnsToFit();
    Utils.updateGridLayout(this.gridAPI, 'patientBudgetEntryGrid');
  }

  onAddPatientProtocol() {
    const patientGroupId = this.patientGroupId$.getValue();
    let ss;
    if (patientGroupId) {
      ss = this.gridAPI.applyTransaction({
        add: [
          {
            randomID: Utils.uuid(),
            patient_protocol_type:
              this.patientGroupId$.getValue() || this.isVisit$.getValue()
                ? PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT
                : null,
            patient_group_id: patientGroupId || null,
          },
        ],
      });
    } else {
      ss = this.gridAPI.applyTransaction({
        add: [{ randomID: Utils.uuid() }],
      });
    }

    if (ss?.add.length && ss.add[0].rowIndex) {
      this.gridAPI.startEditingCell({
        rowIndex: ss.add[0].rowIndex,
        colKey: 'patient_protocol_name',
      });
    }
    this.addedRowCounter += 1;
    this.newRowAdded = true;
    this.checkChanges();
  }

  onRowDragEnd(event: RowDragEndEvent) {
    if (event.node.data?.patient_protocol_id && event.node.rowIndex !== null) {
      this.reorderQueue.push({
        id: event.node.data.patient_protocol_id,
        new_index: event.node.rowIndex + 1,
      });
      this.reorderedRows.add(event.node.data.patient_protocol_id);
      this.checkChanges();
    }
  }

  cellValueChanged(event: CellValueChangedEvent) {
    if (event.data.patient_protocol_id) {
      this.editedRows.add(event.data.patient_protocol_id);
      this.checkChanges();
    }
  }

  showErrors() {
    const rowNodes: RowNode[] = [];
    let isThereAnyInvalidRow = false;
    this.gridAPI.forEachNode((node) => {
      const { patient_protocol_name, patient_protocol_type } = node.data as PatientProtocolGridData;

      if (patient_protocol_name && patient_protocol_type) {
        if (node.data.showError) {
          // eslint-disable-next-line no-param-reassign
          node.data.showError = false;
          rowNodes.push(node);
        }
      } else {
        isThereAnyInvalidRow = true;
        // eslint-disable-next-line no-param-reassign
        node.data.showError = true;
        rowNodes.push(node);
      }
    });

    this.gridAPI.redrawRows({ rowNodes });
    return isThereAnyInvalidRow;
  }

  onSaveAll = async () => {
    const isInvalid = this.showErrors();
    if (isInvalid) {
      this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
    }

    if (!isInvalid) {
      this.patientProtocolStore.setLoading(true);

      const upsertData: (CreatePatientProtocolInput & {
        patient_protocol_id: string | null;
      })[] = [];

      const reorderData: {
        patient_protocol_id: string;
        order_by: number;
      }[] = [];

      if (
        this.reorderQueue.length < this.gridAPI.getDisplayedRowCount() &&
        !this.removedRows.length
      ) {
        this.reorderQueue.forEach(({ new_index, id }) => {
          const pp = this.patientProtocolQuery.getEntity(id);
          if (pp) {
            const {
              patient_protocol_type,
              name,
              patient_group_id,
              target_date_days_out,
              target_tolerance_days_out,
            } = pp;

            upsertData.push({
              patient_protocol_id: id,
              patient_protocol_type,
              patient_group_id,
              name,
              target_date_days_out,
              target_tolerance_days_out,
              order_by: new_index,
            });
          }
        });
        this.gridAPI.forEachNode((node) => {
          const rowIndex = (node.rowIndex || 0) + 1;

          const {
            patient_protocol_id,
            patient_protocol_type,
            patient_protocol_name,
            patient_group_id,
            target_date_days_out,
            target_tolerance_days_out,
          } = node.data as RequireSome<
            PatientProtocolGridData,
            | 'patient_protocol_id'
            | 'target_date_days_out'
            | 'patient_protocol_type'
            | 'patient_protocol_name'
            | 'patient_group_id'
          >;

          if (!patient_protocol_id || this.editedRows.has(patient_protocol_id)) {
            upsertData.push({
              patient_protocol_id,
              patient_protocol_type,
              name: patient_protocol_name,
              target_date_days_out,
              patient_group_id,
              target_tolerance_days_out,
              order_by: rowIndex,
            });
          }

          if (patient_protocol_id) {
            reorderData.push({ patient_protocol_id, order_by: rowIndex });
          }
        });
      } else {
        this.gridAPI.forEachNode((node) => {
          const rowIndex = (node.rowIndex || 0) + 1;

          const {
            patient_protocol_id,
            patient_protocol_type,
            patient_protocol_name,
            patient_group_id,
            target_date_days_out,
            target_tolerance_days_out,
          } = node.data as RequireSome<
            PatientProtocolGridData,
            | 'patient_protocol_id'
            | 'patient_protocol_type'
            | 'patient_protocol_name'
            | 'patient_group_id'
            | 'target_date_days_out'
          >;

          if (
            this.reorderQueue.length ||
            !patient_protocol_id ||
            this.editedRows.has(patient_protocol_id) ||
            this.removedRows.length
          ) {
            upsertData.push({
              patient_protocol_id,
              patient_protocol_type,
              patient_group_id,
              name: patient_protocol_name,
              target_date_days_out,
              target_tolerance_days_out,
              order_by: rowIndex,
            });
          }

          if (patient_protocol_id) {
            reorderData.push({ patient_protocol_id, order_by: rowIndex });
          }
        });
      }

      let hasError = true;

      if (upsertData.length) {
        for (const { patient_protocol_id, order_by } of reorderData) {
          this.patientProtocolStore.update(patient_protocol_id, { order_by });
        }
        hasError = await this.patientProtocolService.upsert(upsertData);
      }

      if (this.removedRows.length) {
        hasError = await this.patientProtocolService.remove(this.removedRows);
      }

      if (!hasError) {
        this.overlayService.success();
      }

      this.cancelEditMode();
      this.patientProtocolStore.setLoading(false);
    }
  };

  sizeColumnsToFit(): void {
    this.gridAPI.sizeColumnsToFit();
  }

  editGrid(): void {
    this.editModeGrid$.next(true);
    this.gridOptions$
      .getValue()
      .columnApi?.setColumnsVisible([TableConstants.FIELDS.ACTIONS], true);
    this.sizeColumnsToFit();
    this.gridOptions$.getValue().api?.redrawRows();
    this.gridAPI.startEditingCell({
      rowIndex: 0,
      colKey: 'patient_protocol_name',
    });
  }

  cancelEditMode(): void {
    this.gridAPI.stopEditing();
    this.editModeGrid$.next(false);
    this.gridOptions$
      .getValue()
      .columnApi?.setColumnsVisible([TableConstants.FIELDS.ACTIONS], false);
    this.sizeColumnsToFit();
    this.gridData$ = this.patientProtocolQuery.selectAll().pipe(
      map((value) => {
        return value.map(
          ({
            id,
            name,
            patient_protocol_type,
            target_tolerance_days_out,
            target_date_days_out,
            order_by,
          }) => ({
            patient_protocol_id: id,
            patient_protocol_name: name,
            patient_protocol_type,
            target_tolerance_days_out,
            target_date_days_out,
            order_by,
          })
        );
      })
    );
    this.resetChangeIndicators();
    this.gridAPI.redrawRows();
  }

  private resetChangeIndicators(): void {
    this.reorderedRows.clear();
    this.editedRows.clear();
    this.removedRows = [];
    this.reorderQueue = [];
    this.newRowAdded = false;
    this.addedRowCounter = 0;
    this.hasChanges = false;
  }

  changeTab(index: number, label: string = '') {
    this.activeTabIndex$.next(index);
    const gData = this.patientGroupQuery.getAll()[index];
    if (gData) {
      this.patientGroupId$.next(gData.id);
      this.patientProtocolService
        .get([PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT], gData.id)
        .pipe(untilDestroyed(this))
        .subscribe();
    } else {
      this.patientGroupId$.next(null);
      if (label === 'Visit Costs') {
        this.isVisit$.next(true);
        this.patientProtocolService
          .get([PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT])
          .pipe(untilDestroyed(this))
          .subscribe();
      } else if (label === 'Other Costs') {
        this.isVisit$.next(false);
        this.patientProtocolService
          .get([
            PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED,
            PatientProtocolType.PATIENT_PROTOCOL_OTHER,
            PatientProtocolType.PATIENT_PROTOCOL_OVERHEAD,
            PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL,
          ])
          .pipe(untilDestroyed(this))
          .subscribe();
      }
    }
    if (this.editModeGrid$.getValue()) {
      this.cancelEditMode();
    }
  }
}
