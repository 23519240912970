<ng-template #patientDriverMenu>
  <div class="flex justify-between items-center">
    <aux-menu #patient_driver_menu>
      <button
        type="button"
        role="menuitem"
        class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 flex block px-4 py-2 text-sm w-full items-center focus:outline-none"
        (click)="onPatientDriverUploadClick(); patient_driver_menu.close()"
      >
        <span inlineSVG="file-upload.svg"></span>
        <span class="ml-2">Upload</span>
      </button>
    </aux-menu>
  </div>
</ng-template>

<ng-template #selectCurveMenu>
  <div *ngIf="patientCurveGroup$ | async as patientCurveGroup">
    <div class="text-sm grid items-center">
      <span>Select a Curve:</span>
    </div>
    <div
      class="text-sm flex justify-between items-center px-3 h-10 w-60 border border-aux-gray-dark font-medium rounded-md shadow-sm ml-1"
      (click)="openList(trigger)"
    >
      <ng-container *ngIf="selected; else placeholderTemplate">
        <span class="text-sm">{{ selected }}</span>
      </ng-container>

      <ng-template #placeholderTemplate>
        <span class="text-gray-500">Select a Curve</span>
      </ng-template>

      <div>
        <chevron-down-solid-icon class="text-aux-gray-darkest"></chevron-down-solid-icon>
      </div>
    </div>

    <div>
      <div class="mt-11" cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>
    </div>
    <!-- <div (click)="isOpen = !isOpen" type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
      {{ selected }}
    </div> -->
    <ng-template
      cdkConnectedOverlay
      class="border-solid border-aux-gray-dark-100"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'ttt'"
      [cdkConnectedOverlayPositions]="positions"
      (backdropClick)="closeList()"
      (detach)="closeList()"
    >
      <ul
        tabindex="-1"
        class="bg-white border-solid border-aux-gray-light rounded-md py-1 text-base ring-1 overflow-auto focus:outline-none sm:text-sm w-60 -mt-10"
      >
        <ng-container
          class="grid"
          *ngFor="let item of patientCurveGroup; index as index; let first = first"
        >
          <li
            class="text-gray-900 cursor-default select-none relative flex flex-col"
            (click)="selectCurve(item)"
          >
            <div
              *ngIf="item.showLine && !first"
              class="border-b h-1 border-aux-gray-darkest mb-2 mt-1 mr-2 ml-2"
            ></div>

            <div
              (mouseenter)="highlightCurve(index)"
              (mouseleave)="highlightedCurve = null"
              class="text-gray-900 cursor-default select-none relative py-2 pl-2 flex justify-between"
              [ngClass]="{
                'bg-aux-gray-light': index === highlightedCurve
              }"
            >
              {{ item.name }}
              <div class="flex mr-2">
                <button
                  *ngIf="item.is_blended"
                  class="w-3 text-aux-blue-dark mr-1"
                  (click)="editCurve(item)"
                >
                  <span inlineSVG="edit.svg" [setSVGAttributes]="{ class: 'w-3 h-3' }"></span>
                </button>
                <button *ngIf="item.driver_setting_id.length > 1" class="w-3 text-aux-red-hover">
                  <span
                    inlineSVG="trash.svg"
                    [setSVGAttributes]="{ class: 'w-4 h-4' }"
                    (click)="deleteCurve(item)"
                  ></span>
                </button>
              </div>
            </div>
          </li>
        </ng-container>
        <div class="border-b h-1 border-aux-gray-darkest mb-2 mt-1 mr-2 ml-2"></div>
        <div class="aux-link pl-2 mb-2">
          <span class="cursor-pointer" (click)="editCurve()">+ Create Blended Curve</span>
        </div>
      </ul>
    </ng-template>
  </div>
</ng-template>

<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>
<ng-container *ngIf="(loading$ | async) === false">
  <div
    class="border rounded border-aux-gray-dark bg-aux-gray-light py-4 px-8 w-10/12"
    *ngIf="(showEnrollmentSettings$ | async) === true"
  >
    <div
      *ngIf="(patientCurveQuery.selectLoading() | async) === false; else loading"
      class="font-bold text-lg text-black mb-4"
    >
      Enrollment Settings
    </div>
    <div class="grid grid-cols-5 gap-10 whitespace-nowrap text-xs">
      <div>
        <div class="mb-3">Patients Enrolled (pspm)</div>
        <aux-input [type]="'number'"></aux-input>
      </div>
      <div>
        <div class="mb-3">Total Patients Enrolled</div>
        <aux-input [type]="'number'"></aux-input>
      </div>
      <div>
        <div class="mb-3">First Patient Enrolled</div>
        <aux-input [type]="'date'"></aux-input>
      </div>
      <div>
        <div class="mb-3">Average Months Per Patient</div>
        <aux-input [type]="'number'"></aux-input>
      </div>
      <div>
        <div class="mb-3">Total Enrolled + Dropped</div>
        <aux-input [type]="'number'"></aux-input>
      </div>
    </div>

    <div class="flex space-x-4 my-5 text-xs">
      <div class="flex items-center space-x-2">
        <aux-radio-button [checked]="true" name="radioGroup" tabIndex="1"></aux-radio-button>
        <span>Apply monthly Δ to following month</span>
      </div>
      <div class="flex items-center space-x-2">
        <aux-radio-button name="radioGroup" tabIndex="2"></aux-radio-button>
        <span>Spread monthly Δ over enrollment period</span>
      </div>
      <div class="flex items-center space-x-2">
        <aux-radio-button name="radioGroup" tabIndex="3"></aux-radio-button>
        <span>Modify enrollment period (+/- 1 month)</span>
      </div>
    </div>

    <div class="flex space-x-4 items-center text-xs mb-5">
      <button type="button" class="btn btn--blue">Save Edits</button>
      <button class="aux-link focus:outline-none" type="button">Cancel</button>
    </div>
  </div>

  <div class="flex item-center justify-between">
    <div class="font-bold text-xl text-aux-black mt-10 mb-4">Estimated Enrollment Curve</div>
  </div>

  <div *ngIf="multiChart$ | async as multiChart">
    <aux-canvas-chart [chartOptions]="multiChart"></aux-canvas-chart>
  </div>

  <div class="mb-5 mt-12 flex items-center space-x-4 justify-between">
    <div class="text-lg font-bold text-black">Enrollment</div>
    <div *ngIf="(this.gridData$ | async)?.length !== 0" class="flex justify-end">
      <aux-button
        *ngIf="
          (editMode$ | async) === false &&
          (isBlended$ | async) === false &&
          userHasModifyPermissions
        "
        [disabled]="(isPatientsFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
        [auxTooltip]="
          (isPatientsFinalized$ | async) ? messagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE : ''
        "
        label="Edit"
        icon="pencil"
        variant="secondary"
        classList="h-8"
        [svgAttributes]="{ class: 'w-4 h-4' }"
        [onClick]="this.onEditClick.bind(this)"
      ></aux-button>

      <button
        *ngIf="(editMode$ | async) === true && (isBlended$ | async) === false"
        type="button"
        class="ml-1 btn btn--secondary h-8"
        (click)="cancelEditMode()"
      >
        <span inlineSVG="x.svg" [setSVGAttributes]="{ class: 'w-4 h-4' }"></span>
        <span class="ml-0.5">Cancel</span>
      </button>

      <button
        *ngIf="(editMode$ | async) === true && (isEqual$ | async) === false"
        [disabled]="(isEqual$ | async) === false"
        class="ml-1 h-8 btn btn--success"
        data-toggle="tooltip"
        data-placement="top"
        title="This patient curve is unable to be saved until the following issue is resolved: Patients Enrolled must be equal to the sum of Patients Complete and Patients Discontinued."
      >
        <span
          *ngIf="(btnLoading$ | async) === false && (isBlended$ | async) === false"
          inlineSVG="check.svg"
          [setSVGAttributes]="{ class: 'w-4 h-4' }"
        ></span>
        <span class="ml-2">Save</span>
      </button>
      <button
        *ngIf="(editMode$ | async) === true && (isEqual$ | async) === true"
        class="ml-1 h-8 btn btn--success"
        [disabled]="(isEqual$ | async) === false || (editingGridCell$ | async) === true"
        (click)="saveEditMode()"
      >
        <span class="spinner w-5 h-5" *ngIf="(btnLoading$ | async) === true"></span>
        <span
          *ngIf="(btnLoading$ | async) === false && (isBlended$ | async) === false"
          inlineSVG="check.svg"
          [setSVGAttributes]="{ class: 'w-4 h-4' }"
        ></span>
        <span class="ml-2">Save</span>
      </button>
      <aux-export-excel-button
        [className]="'ml-1 h-8'"
        [svgAttributes]="{ class: 'w-4 h-4' }"
        [variant]="exportButtonVariant"
        [gridAPI]="gridApi"
        [excelOptions]="excelOptions"
        [getDynamicExcelParamsCallback]="getDynamicExcelParams.bind(this)"
      >
      </aux-export-excel-button>
    </div>
  </div>

  <ag-grid-angular
    style="height: 400px"
    class="ag-theme-aux tabular-nums patient-table mb-8 w-full"
    id="patient-table"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    (cellValueChanged)="cellValueChanged()"
    (cellEditingStopped)="cellEditingStopped()"
    (firstDataRendered)="onDataRendered($event)"
    [enableFillHandle]="(editMode$ | async) === true"
    (pasteStart)="onPasteStart()"
    (cellEditingStarted)="rowPinnedCheck($event)"
  >
  </ag-grid-angular>
</ng-container>

<ng-template #loading>
  <!-- TODO create a skeleton loading for invoice -->
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-template>

<!--<button-->
<!--  class="text-sm font-normal btn btn&#45;&#45;blue w-32 fixed right-4 bottom-4 whitespace-nowrap"-->
<!--  type="button"-->
<!--  *ngIf="editedRows.size && (editMode$ | async) === false"-->
<!--  (click)="onSaveAll()"-->
<!--&gt;-->
<!--  Save Changes-->
<!--</button>-->

<!--*ngIf="editedRows.size || newRowAdded || removedRows.length || reorderedRows.size"-->
