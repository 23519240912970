<ng-container *ngIf="$any(loading$ | async)">
  <div class="mt-10 text-xl font-semibold">Current Users</div>

  <ng-template #loader>
    <div class="w-full max-w-sm p-4 mt-8 border border-blue-200 rounded-md shadow">
      <div class="flex space-x-4 animate-pulse">
        <div class="flex-1 py-1 space-y-4">
          <div class="flex justify-between">
            <div class="w-1/6 h-4 bg-blue-200 rounded"></div>
            <div class="flex-grow"></div>
            <div class="w-1/6 h-4 bg-blue-200 rounded"></div>
            <div class="w-1/6 h-4 ml-2 bg-blue-200 rounded"></div>
          </div>
          <div class="space-y-2">
            <div class="w-3/6 h-3 bg-blue-200 rounded"></div>
            <div class="w-2/6 h-3 bg-blue-200 rounded"></div>
            <div class="w-4/6 h-3 bg-blue-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-container [ngTemplateOutlet]="loader"></ng-container>
  <ng-container [ngTemplateOutlet]="loader"></ng-container>
</ng-container>
<ng-container *ngIf="!$any(loading$ | async)">
  <div class="flex items-center space-x-4">
    <div class="text-xl font-semibold">Current Users</div>
    <button
      class="text-sm btn btn--blue"
      *auxAuthorize="{ roles: ['ROLE_ADMIN'] }"
      (click)="onAddUser()"
    >
      <plus-outline-icon></plus-outline-icon>
      Add New User
    </button>
  </div>

  <div>
    <aux-input
      class="w-72 mt-4"
      placeholder="Search"
      [(ngModel)]="filterValue"
      (change)="filterChanges()"
      (keyup)="updateSearch($event?.target)"
      icon="search.svg"
    ></aux-input>
  </div>

  <ng-container *ngFor="let userGroup of users$ | async | groupBy: 'organization.name'">
    <div class="mt-4 mb-2 border-b">
      {{ userGroup.key }}
    </div>

    <div class="flex mt-4 flex-wrap">
      <ng-container *ngFor="let user of userGroup.value">
        <div class="w-64 p-4 mt-4 mr-4 border rounded-md flex flex-col justify-between">
          <div class="flex justify-between mb-2">
            <div
              class="py-0.5 px-1 text-xxs rounded-md font-medium"
              [ngClass]="{
                'bg-aux-green text-white': user.role === 'ROLE_ADMIN',
                'bg-aux-gray-dark': user.role === 'ROLE_USER'
              }"
            >
              {{ user.role === 'ROLE_ADMIN' ? 'Admin' : 'User' }}
            </div>
          </div>

          <div class="text-sm break-words">
            <div class="font-bold">{{ user.given_name }} {{ user.family_name }}</div>
            <div>
              {{ user.title }}, {{ getDepartmentString(user.department) }}
              {{ user.organization?.name }}
            </div>
            <a href="#" class="font-medium">{{ user.email }}</a>
          </div>

          <div
            *auxAuthorize="{ roles: ['ROLE_ADMIN'] }"
            class="flex space-x-2 text-sm font-medium items-center mt-4"
          >
            <button class="btn px-2 h-8" type="button" (click)="onEditUser(user)">
              <pencil-solid-icon [size]="16" class="text-aux-blue"></pencil-solid-icon>
            </button>
            <button class="btn px-2 h-8" type="button" (click)="onRemoveUser(user)">
              <trash-solid-icon [size]="16" class="text-aux-error"></trash-solid-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div *ngIf="(auxUsers$ | async)?.length">
    <div class="mt-4 mb-2 border-b">
      Auxilius Support
      <button (click)="toggleAuxSupport()" type="button" class="align-top">
        <chevron-down-solid-icon *ngIf="!showAuxSupport"></chevron-down-solid-icon>
        <chevron-up-solid-icon *ngIf="showAuxSupport"></chevron-up-solid-icon>
      </button>
    </div>
    <div *ngIf="showAuxSupport">
      <div class="flex mt-4 flex-wrap">
        <ng-container *ngFor="let user of auxUsers$ | async">
          <div class="w-64 p-4 mt-4 mr-4 border rounded-md flex flex-col justify-between">
            <div class="flex justify-between mb-2">
              <div
                class="py-0.5 px-1 text-xxs rounded-md font-medium"
                [ngClass]="{
                  'bg-aux-green text-white': user.role === 'ROLE_ADMIN',
                  'bg-aux-gray-dark': user.role === 'ROLE_USER'
                }"
              >
                {{ user.role === 'ROLE_ADMIN' ? 'Admin' : 'User' }}
              </div>
            </div>

            <div class="text-sm break-words">
              <div class="font-bold">{{ user.given_name }} {{ user.family_name }}</div>
              <div>
                {{ user.title }}{{ user.title ? ', ' : ''
                }}{{ getDepartmentString(user.department) }}
                Auxilius Support
              </div>
              <a href="#" class="font-medium">{{ user.email }}</a>
            </div>

            <div
              *auxAuthorize="{ roles: ['ROLE_ADMIN'] }"
              class="flex space-x-2 text-sm font-medium items-center mt-4"
            >
              <button class="btn px-2 h-8" type="button" (click)="onEditUser(user)">
                <pencil-solid-icon [size]="16" class="text-aux-blue"></pencil-solid-icon>
              </button>
              <button class="btn px-2 h-8" type="button" (click)="onRemoveUser(user)">
                <trash-solid-icon [size]="16" class="text-aux-error"></trash-solid-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
