<div class="min-h-screen bg-aux-gray-light flex flex-col justify-center py-12 lg:px-8 my-0 mx-auto">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <div class="rounded shadow-md bg-white p-14">
      <img class="mb-10 mx-auto w-3/4" src="assets/img/logo-black.png" alt="" />

      <div class="mt-2 mb-4 text-lg font-semibold">Confirm your account</div>

      <form action="" novalidate (ngSubmit)="onSubmit()" [formGroup]="fg" class="space-y-4">
        <aux-input
          formControlName="code"
          validators="required"
          label="Confirmation Code"
        ></aux-input>

        <button class="btn btn--blue w-full" type="submit">
          Confirm <chevron-right-solid-icon></chevron-right-solid-icon>
        </button>
      </form>

      <div class="text-center mt-4">
        Return to
        <a [routerLink]="[loginLink]" class="aux-link">login</a>
      </div>

      <div class="mb-5 mt-10 space-x-2 text-center">
        <a href="#" class="aux-link no-underline">Privacy Policy</a>
        <a href="#" class="aux-link no-underline">Term of Use</a>
      </div>

      <p class="text-center">©️{{ year }} Auxilius Inc., All rights reserved.</p>
    </div>
  </div>
  <div class="bg-aux-gray-light text-aux-gray-dark-100 flex items-center justify-end py-2 pr-4">
    App Version {{ appInitService.APP_VERSION }}
  </div>
</div>
