import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ColumnApi, ICellEditorParams } from 'ag-grid-community';
import { FormControl } from '@angular/forms';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import { FormControlConstants } from '../../../constants/form-control.constants';

@Component({
  selector: 'aux-ag-date-picker',
  templateUrl: './ag-date-picker.component.html',
  styles: [
    `
      :host,
      :host ::ng-deep input,
      :host ::ng-deep label,
      .aux-ag-date-picker {
        height: 100%;
      }

      :host ::ng-deep input {
        font-size: 14px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgDatePickerComponent implements ICellEditorAngularComp {
  readonly formControlConstants = FormControlConstants;

  params!: ICellEditorParams;

  fc = new FormControl();

  api!: GridApi;

  columnApi!: ColumnApi;

  stopEditing!: (suppressNavigateAfterEdit?: boolean | undefined) => void;

  agInit(params: ICellEditorParams): void {
    this.params = params;
    if (params.columnApi) {
      this.columnApi = params.columnApi;
    }
    if (params.api) {
      this.api = params.api;
    }
    this.stopEditing = params.stopEditing;
    this.fc.setValue(params.value);
  }

  getValue(): any {
    return this.fc.value;
  }
}
