import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';
import { IHeaderGroupParams } from 'ag-grid-community';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map, pluck } from 'rxjs/operators';
import { Utils } from '@services/utils';
import * as dayjs from 'dayjs';
import { CloseQuarterService } from './close-quarter-check-list';

type InMonthCloseHeaderParams = IHeaderGroupParams & {
  bottomRowData$: BehaviorSubject<{ [k: string]: Record<string, number> }>;
  monthButtonClick: () => void;
  isMonthCloseAvailable$: BehaviorSubject<boolean>;
  isWorkflowAvailable$: BehaviorSubject<boolean>;
  isQuarterCloseEnabled$: BehaviorSubject<boolean>;
  processing$: BehaviorSubject<boolean>;
  userHasClosingPermission$: BehaviorSubject<boolean>;
  mode: 'closed' | 'open' | 'future';
  headerName?: string;
  hideTotal?: boolean;
  month: {
    parsedDate: dayjs.Dayjs;
    date: string;
    iso: string;
  };
};

@UntilDestroy()
@Component({
  template: `
    <div class="flex items-center justify-between w-full text-base">
      <div class="flex items-center">
        <div *ngIf="params.month">
          {{ params.month.iso | date: 'MMMM y' }}
          {{ params.mode === 'future' ? '' : params.mode === 'open' ? '(Open)' : '(Closed)' }}
        </div>
        <div *ngIf="params.headerName && !params.month">
          {{ params.headerName }}
        </div>

        <button
          class="flex items-center no-underline aux-link ml-1 text-white"
          (click)="onShowClick()"
          *ngIf="params.mode === 'closed' || params.mode === 'future'"
        >
          <span
            [ngClass]="{
              'transform rotate-180': (showChild$ | async)
            }"
            inlineSVG="chevron-right.svg"
            [setSVGAttributes]="{ class: 'w-4 h-4' }"
          ></span>
        </button>
      </div>
      <!-- <div *ngIf="(params.mode === 'open' || (showChild$ | async) === true) && !params.hideTotal">
        Total: {{ total$ | async }}
      </div> -->
      <ng-container
        *ngVar="{
          isMonthCloseAvailable: (params.isMonthCloseAvailable$ | async)!,
          isWorkflowAvailable: (params.isWorkflowAvailable$ | async)!,
          isQuarterCloseEnabled: (params.isQuarterCloseEnabled$ | async)!,
          userHasClosingPermission: (params.userHasClosingPermission$ | async)!,
          processing: (params.processing$ | async)!
        } as obj"
      >
        <div
          [auxTooltip]="
            !obj.processing
              ? obj.isQuarterCloseEnabled &&
                closeQuarterService.getCloseQuarterTooltip(
                  obj.isMonthCloseAvailable,
                  obj.isWorkflowAvailable,
                  'All Closing Checklist entries must be confirmed before period can be closed'
                )
              : ''
          "
        >
          <button
            class="btn btn--success text-sm font-normal"
            (click)="params.monthButtonClick()"
            [disabled]="!obj.isMonthCloseAvailable || obj.processing"
            *ngIf="params.mode === 'open' && obj.userHasClosingPermission"
          >
            <span
              inlineSVG="check.svg"
              class="mr-1"
              [setSVGAttributes]="{ class: 'w-5 h-5' }"
            ></span>
            <span>Close Month</span>
          </button>
        </div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgInMonthGroupHeaderComponent implements IHeaderGroupAngularComp {
  public params!: InMonthCloseHeaderParams;

  total$!: Observable<string>;

  showChild$ = new BehaviorSubject(false);

  constructor(public closeQuarterService: CloseQuarterService) {}

  agInit(params: InMonthCloseHeaderParams): void {
    this.params = params;

    this.total$ = params.bottomRowData$.pipe(
      pluck('net_accruals', params.month?.date),
      map((n) => Utils.currencyFormatter(n))
    );
  }

  onShowClick() {
    const bool = !this.showChild$.getValue();
    this.params.setExpanded(bool);
    this.showChild$.next(bool);
  }
}
