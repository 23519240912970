<form class="font-inter" [formGroup]="fg" (ngSubmit)="onUpload()">
  <div class="w-screen max-w-2xl"></div>
  <div class="mb-8 text-xl font-bold">{{ mode === 'add' ? 'Add' : 'Edit' }} Purchase Order</div>

  <div class="grid grid-cols-2 gap-5">
    <div class="flex flex-col justify-between">
      <aux-input
        class="tabular-nums"
        formControlName="po_number"
        label="PO #"
        placeholder="#########"
        validators="required"
      ></aux-input>

      <aux-input
        formControlName="po_amount"
        class="tabular-nums"
        label="PO Amount"
        placeholder="$"
        validators="required"
        [type]="'number'"
        class="mt-4"
      ></aux-input>

      <div class="mt-4">
        <div class="text-xs">Vendor</div>
        <ng-select
          placeholder="Select"
          id="vendors"
          [formControl]="selectedVendor"
          [appendTo]="'body'"
          [searchable]="true"
          [clearable]="false"
          class="select select__big"
          auxFormError
        >
          <ng-option [value]="vendor.id" *ngFor="let vendor of vendorQuery.allVendors$ | async">
            <span [title]="vendor.name">{{ vendor.name }}</span>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="mb-1 text-xs">Upload Purchase Order</div>
      <aux-file-manager
        class="h-48"
        #fileManager
        [eager]="false"
        [pathFn]="pathFn"
      ></aux-file-manager>
    </div>
    <div></div>
    <div class="max-h-60 overflow-auto mt-4">
      <aux-file-viewer
        [fileManager]="fileManager"
        [disableFirstFileMargin]="true"
        [onlyShowUploaded]="false"
      ></aux-file-viewer>
    </div>
  </div>

  <div *ngIf="showError" class="-mb-4 bg-aux-error mt-4 p-4 rounded-md text-white">
    {{ showError }}
  </div>

  <div class="mt-8">
    <button class="w-48 text-sm btn btn--blue" type="submit">
      <span class="spinner w-6 h-6 mr-3" *ngIf="loading$ | async"></span>
      <span>Save</span>
    </button>
  </div>
</form>
