<ng-container *ngIf="$any(loading$ | async)">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="!$any(loading$ | async)">
  <div class="flex items-center mb-4 relative">
    <aux-input
      class="w-48"
      placeholder="Search"
      [(ngModel)]="nameFilterValue"
      icon="search.svg"
    ></aux-input>
    <div class="flex items-center ml-4 mr-auto">
      <div class="text-sm mr-2">Vendor</div>
      <ng-select
        class="w-64 text-sm mt-0"
        [clearable]="false"
        (change)="onOrganizationSelected($event)"
        [formControl]="selectedVendor"
      >
        <ng-container *ngVar="organizationQuery.allVendors$ | async as vendors">
          <ng-option [value]="''" *ngIf="vendors.length > 1">All</ng-option>
          <ng-option [value]="vendor.id" *ngFor="let vendor of vendors">
            <span [title]="vendor.name">{{ vendor.name }}</span>
          </ng-option>
        </ng-container>
      </ng-select>
    </div>

    <div class="justify-end">
      <!-- -top-6 absolute right-0 -->
      <button type="button" class="text-sm btn btn--blue h-12" (click)="openChangerOrderUpload()">
        <span inlineSVG="circle-plus.svg"></span>
        <span class="ml-2">Create New</span>
      </button>
    </div>
  </div>

  <div *ngIf="showCOChart$ | async">
    <div
      class="border-8 m-auto mt-15 mb-10 spinner h-16 w-16"
      *ngIf="coChartLoading$ | async"
    >
    </div>
    <div 
      class="justify-center"
      *ngIf="(coChartLoading$ | async) === false"
    >
      <h1 class="text-right text-aux-gray-darkest h-11 flex items-center">Budget Changes over Time</h1>
    </div>
    <canvas
      class="pb-3 px-2 ml-auto"
      [hidden]="(coChartLoading$ | async) === false"
      width="5"
      height="1"
      style="height: 400px"
      id="changeOrderLineChart"
    ></canvas>
  </div>

  <div class="mb-8 w-full">
    <ag-grid-angular
      style="max-width: 100%; height: 400px"
      [ngStyle]="{
        width: (width$ | async) === 0 ? '100%' : (width$ | async) + 'px'
      }"
      class="ag-theme-alpine tabular-nums"
      [rowData]="gridData$ | async"
      [gridOptions]="$any(gridOptions$ | async)"
      (gridReady)="onGridReady($event)"
      (firstDataRendered)="onFirstDataRendered()"
      [quickFilterText]="nameFilterValue"
    >
    </ag-grid-angular>
  </div>
</ng-container>
