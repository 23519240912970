import { loggedInUserQuery } from '@services/gql.service';

export class LoggedInUser {
  private sub = '';

  private is_sys_admin = false;

  private trial_id = '';

  private roles: Array<string> = [];

  private permissions: Array<string> = [];

  constructor(fromQuery: loggedInUserQuery | null) {
    if (fromQuery && fromQuery.sub) {
      this.sub = fromQuery.sub;
      this.is_sys_admin = fromQuery.is_sys_admin;
      this.trial_id = fromQuery.trial_id ?? '';
      if (Array.isArray(fromQuery.roles)) {
        fromQuery.roles.forEach((r) => {
          if (typeof r === 'string' && r) {
            this.roles.push(r.toUpperCase());
          }
        });
      }
      if (Array.isArray(fromQuery.permissions)) {
        fromQuery.permissions.forEach((p) => {
          if (typeof p === 'string' && p) {
            this.permissions.push(p.toUpperCase());
          }
        });
      }
    }
    Object.freeze(this.roles);
    Object.freeze(this.permissions);
  }

  getSub() {
    return this.sub;
  }

  IsSysAdmin() {
    return this.is_sys_admin;
  }

  getTrialId() {
    return this.trial_id;
  }

  getPermissions() {
    return this.permissions;
  }

  getRoles() {
    return this.roles;
  }

  hasPermission(...permissionsToCheck: string[]): boolean {
    if (Array.isArray(permissionsToCheck)) {
      for (const p of permissionsToCheck) {
        if (typeof p === 'string' && p) {
          if (this.permissions.indexOf(p) >= 0) {
            return true;
          }
        }
      }
    }
    return false;
  }

  hasRole(...rolesToCheck: string[]): boolean {
    if (Array.isArray(rolesToCheck)) {
      for (const r of rolesToCheck) {
        if (typeof r === 'string' && r) {
          if (this.roles.indexOf(r) >= 0) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
