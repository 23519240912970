import { Route, Routes } from '@angular/router';
import { CanDeactivateGuard } from '@services/can-deactivate.guard';
import { buildRoutesByFeatureFlags, FeatureFlag } from '@models/feature-flag.model';
import { Flags } from '@services/launch-darkly.service';
import { VendorPaymentsPageComponent } from './vendor-payments-page.component';
import { InvoicesComponent } from './tabs/invoices/invoices.component';
import { PurchaseOrdersComponent } from './tabs/purchase-orders/purchase-orders.component';
import { InvoicesDetailComponent } from './tabs/invoices/invoices-detail/invoices-detail.component';
import { VendorsComponent } from './tabs/vendors/vendors.component';
import { PaymentScheduleComponent } from './tabs/payment-schedule/payment-schedule.component';
import { ROUTING_PATH } from '../../app-routing-path.const';
import { PaymentMilestonesComponent } from './tabs/payment-milestones/payment-milestones.component';

export const VENDOR_PAYMENTS_ROUTES_FF: Routes = [
  {
    path: ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_SCHEDULE,
    component: PaymentScheduleComponent,
    data: {
      feature: FeatureFlag.VENDOR_PAYMENTS_PAYMENT_SCHEDULE,
    },
  },
];

export const buildVendorPaymentsRoutes = (featureFlags: Flags | null): Route => {
  const enabledRoutes: Routes = [
    {
      path: ROUTING_PATH.VENDOR_PAYMENTS.INVOICES,
      component: InvoicesComponent,
    },
    {
      path: ROUTING_PATH.VENDOR_PAYMENTS.PURCHASE_ORDERS,
      component: PurchaseOrdersComponent,
    },
    {
      path: ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_MILESTONES,
      component: PaymentMilestonesComponent,
      canDeactivate: [CanDeactivateGuard],
    },
    {
      path: ROUTING_PATH.VENDOR_PAYMENTS.VENDORS,
      component: VendorsComponent,
    },
    {
      path: `${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}/:id`,
      component: InvoicesDetailComponent,
      canDeactivate: [CanDeactivateGuard],
    },
    ...buildRoutesByFeatureFlags(featureFlags, VENDOR_PAYMENTS_ROUTES_FF),
  ];

  return {
    path: ROUTING_PATH.VENDOR_PAYMENTS.INDEX,
    component: VendorPaymentsPageComponent,
    children: [...enabledRoutes, { path: '**', redirectTo: ROUTING_PATH.VENDOR_PAYMENTS.INVOICES }],
  };
};
