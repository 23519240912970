import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Currency, GqlService, listMonthlyExchangeRatesQuery } from '@services/gql.service';
import { map, tap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { groupBy } from 'lodash-es';
import { Utils, ValuesType } from '@services/utils';
import { MainQuery } from '../../../layouts/main-layout/state/main.query';

export interface ListMonthlyExchangeRates
  extends Omit<listMonthlyExchangeRatesQuery, '__typename'> {}

type Exchange = ValuesType<ListMonthlyExchangeRates['exchange_rates']>;

const DEFAULT_EXCHANGE_RATES: ListMonthlyExchangeRates = {
  exchange_rates: [] as listMonthlyExchangeRatesQuery['exchange_rates'],
  currencies: [] as listMonthlyExchangeRatesQuery['currencies'],
  months: [],
  trial_currency: Currency.USD,
  update_date: '',
};

@Injectable({
  providedIn: 'root',
})
export class ExchangeRatesService {
  isLoading$ = new BehaviorSubject(false);

  monthlyExchangeRates$ = new BehaviorSubject<ListMonthlyExchangeRates>(DEFAULT_EXCHANGE_RATES);

  constructor(
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService
  ) {}

  getCurrenciesList$() {
    this.isLoading$.next(true);

    return this.gqlService.listMonthlyExchangeRates$([]).pipe(
      tap(({ data, errors, success }) => {
        if (success && data) {
          this.monthlyExchangeRates$.next(data);
        } else {
          this.overlayService.error(errors);
        }

        this.isLoading$.next(false);
      })
    );
  }

  private getGridRowsData(monthlyRates: ListMonthlyExchangeRates) {
    const groupedExchanges = groupBy(monthlyRates.exchange_rates, 'month') || {};

    return Object.entries<Exchange[]>(groupedExchanges).map(([month, exchanges]) => {
      return {
        date: month,
        ...exchanges.reduce<Record<string, number>>(
          (accum, exchange) => ({
            ...accum,
            [exchange.base_currency]: exchange.rate,
          }),
          {}
        ),
      };
    });
  }

  getCurrenciesGridData$() {
    return this.getCurrenciesList$().pipe(
      map(() => {
        const monthlyRates = this.monthlyExchangeRates$.getValue();

        return {
          columns: monthlyRates.currencies.sort(Utils.alphaNumSort),
          rows: this.getGridRowsData(monthlyRates),
        };
      })
    );
  }
}
