<div class="font-inter w-screen max-w-2xl">
  <div class="mb-4 text-xl font-bold">Invoice Upload</div>
  <div class="border-b border-gray-200 mb-2">
    <nav class="flex -mb-px space-x-8" aria-label="Tabs">
      <ng-container *ngIf="{ tabs: tabs, activeIndex: activeIndex } as obj">
        <ng-container *ngFor="let tab of obj.tabs; index as index">
          <button
            type="button"
            class="px-1 py-4 text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
            (click)="onTabChange(index)"
            [ngClass]="{
              'border-aux-blue-light text-aux-blue-light': index === obj.activeIndex,
              'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                index !== obj.activeIndex
            }"
          >
            {{ tab.label }}
          </button>
        </ng-container>
      </ng-container>
    </nav>
  </div>
  <div [hidden]="activeIndex !== 0">
    <div class="grid grid-cols-2 gap-5">
      <div class="flex flex-col justify-between">
        <div class="mb-4">
          <div class="mb-2 text-xs">Vendor</div>
          <ng-select
            placeholder="Select"
            id="vendors"
            [formControl]="selectedVendor"
            [appendTo]="'body'"
            [searchable]="true"
            [clearable]="false"
            (change)="onChangeVendor($event)"
            auxFormError
          >
            <ng-option [value]="vendor.id" *ngFor="let vendor of vendorQuery.allVendors$ | async">
              <span [title]="vendor.name">{{ vendor.name }}</span>
            </ng-option>
          </ng-select>
        </div>

        <div>
          <label class="mb-2 text-xs block" for="po_reference">PO Number</label>
          <ng-select
            class="tabular-nums"
            id="po_reference"
            [appendTo]="'body'"
            [searchable]="true"
            [multiple]="false"
            [formControl]="selectedPOReference"
            [clearable]="true"
          >
            <ng-option [value]="po.id" *ngFor="let po of filteredPONumbers$ | async">
              {{ po.po_number }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="mb-2 text-xs">Upload invoice and supporting docs</div>
        <aux-file-manager
          class="h-24"
          #singleFileManager
          [eager]="false"
          [path]="path"
        ></aux-file-manager>
      </div>
    </div>

    <ng-container *ngIf="files$ | async as files">
      <ng-container *ngIf="files?.length">
        <div class="grid grid-cols-12 mt-4">
          <div class="col-span-7 pb-2 text-xs border-b-2">Uploaded Files</div>
          <div class="col-span-5 pb-2 text-xs border-b-2">Designation</div>

          <ng-container *ngFor="let file of files" class="border-b">
            <div class="flex items-center col-span-7 p-2 border-b break-all">
              {{ file.fileName }}
            </div>

            <div class="flex items-center col-span-4 py-2 border-b">
              <div
                class="flex items-center flex-1"
                [auxTooltip]="file.type !== pdf ? 'Invoice file type must be PDF' : ''"
              >
                <input
                  [id]="file.id + 'inc'"
                  [name]="file.id"
                  type="radio"
                  value="invoice"
                  [(ngModel)]="obj[file.id]"
                  [disabled]="file.type !== pdf || oneInvoiceSelected"
                  (ngModelChange)="updateValidation.next()"
                  class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                />
                <label [for]="file.id + 'inc'" class="ml-3">
                  <span class="block text-sm font-medium text-gray-700">Invoice</span>
                </label>
              </div>

              <div class="flex items-center flex-1">
                <input
                  [id]="file.id + 'sup'"
                  [name]="file.id"
                  type="radio"
                  value="supporting"
                  [(ngModel)]="obj[file.id]"
                  (ngModelChange)="updateValidation.next()"
                  class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                />
                <label [for]="file.id + 'sup'" class="ml-3">
                  <span class="block text-sm font-medium text-gray-700">Supporting Document</span>
                </label>
              </div>
            </div>

            <div class="flex justify-end border-b">
              <button class="p-2" (click)="removeFile(file)">
                <trash-solid-icon class="text-aux-error"></trash-solid-icon>
              </button>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

    <div class="flex justify-between mt-8">
      <div class="">
        <button class="w-48 text-sm" (click)="ref.close()" type="button">Cancel</button>
      </div>
      <div class="">
        <button class="w-48 text-sm btn btn--blue" (click)="onUpload()">
          <span class="spinner w-6 h-6 mr-3" *ngIf="loading$ | async"></span>
          <span>Upload</span>
        </button>
      </div>
    </div>
  </div>

  <div [hidden]="activeIndex !== 1">
    <div class="grid grid-cols-1 gap-5 mb-2">
      <div class="flex flex-col">
        <div
          class="aux-link cursor-pointer flex justify-start mb-4"
          (click)="downloadBulkInvoiceTemplate()"
        >
          <span inlineSVG="download.svg"></span>
          <div class="self-end">Download Bulk Invoice Template</div>
        </div>
        <div class="mb-2 text-xs">Upload Bulk Invoice Template</div>
        <aux-file-manager
          class="h-24"
          #bulkFileManager
          [fetchFilesOnInit]="false"
          [path]="path"
          [eager]="false"
          [showSuccessOnUpload]="true"
        ></aux-file-manager>
      </div>
    </div>

    <ng-container *ngIf="bulkFiles$ | async as bulkFiles">
      <ng-container *ngIf="bulkFiles?.length">
        <div class="grid grid-cols-12 mt-4">
          <div class="col-span-12 pb-2 text-xs">Uploaded Files</div>

          <ng-container *ngFor="let bulkFile of bulkFiles" class="border-b">
            <div class="flex items-center col-span-11 p-2 border-b break-all">
              {{ bulkFile.fileName }}
            </div>

            <div class="flex justify-end border-b">
              <button class="p-2" (click)="removeBulkFile(bulkFile)">
                <trash-solid-icon class="text-aux-error"></trash-solid-icon>
              </button>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

    <div *ngIf="errorMessage" class="mt-4 p-5 font-medium bg-aux-error text-white rounded-md">
      {{ errorMessage }}
    </div>
    <div class="flex justify-between mt-8">
      <div class="">
        <button class="w-48 text-sm" (click)="ref.close()" type="button">Cancel</button>
      </div>
      <div class="">
        <button class="w-48 text-sm btn btn--blue" (click)="onUploadBulk()">
          <span class="spinner w-6 h-6 mr-3" *ngIf="loading$ | async"></span>
          <span>Upload</span>
        </button>
      </div>
    </div>
  </div>
</div>
