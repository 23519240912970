import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { ColumnApi, ExcelExportParams, GridApi } from 'ag-grid-community';
import { SitesService } from '@models/sites/sites.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';
import { PaymentSchedulesService } from '@models/payment-schedules/payment-schedules.service';
import { PatientGroupType, PatientProtocolType } from '@services/gql.service';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { CurrencyToggle } from '@components/toggle-currency/toggle-currency.component';
import { RowNode } from 'ag-grid-community/dist/lib/entities/rowNode';
import { TableService } from '@services/table-service';
import { toArray } from 'lodash-es';
import { PatientTableType } from './patient-budget-table/patient-budget-table.component';
import {
  AMOUNT_PATTERN,
  CURRENCY_PATTERN,
} from './patient-budget-table/patient-budget-table-data.service';
import { PatientGroupsQuery } from '../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.query';
import { PatientGroupsService } from '../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.service';
import { PatientGroupsModel } from '../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.model';
@UntilDestroy()
@Component({
  selector: 'aux-patient-protocol',
  templateUrl: './patient-protocol.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientProtocolComponent {
  patientTableTypes = PatientTableType;

  gridAPI!: GridApi;

  gridColumnApi!: ColumnApi;

  activeTabIndex$ = new BehaviorSubject<number>(0);

  patientGroups: PatientGroupsModel[] = [];

  tabs: { label: string; show: Observable<boolean> }[] = [];

  loading$ = new BehaviorSubject(false);

  patientGroupsLoading$ = new BehaviorSubject(false);

  showGrid$ = new BehaviorSubject(true);

  constructor(
    private patientProtocolService: PatientProtocolService,
    private paymentSchedulesService: PaymentSchedulesService,
    private sitesService: SitesService,
    private patientGroupsQuery: PatientGroupsQuery,
    private patientGroupsService: PatientGroupsService,
    private mainQuery: MainQuery
  ) {
    combineLatest([
      this.mainQuery.select('trialKey').pipe(tap(() => this.loading$.next(true))),
      this.patientProtocolService.getForPatientBudgetTable(toArray(PatientProtocolType)),
      this.paymentSchedulesService.get([
        PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED,
        PatientProtocolType.PATIENT_PROTOCOL_OTHER,
        PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL,
        PatientProtocolType.PATIENT_PROTOCOL_OVERHEAD,
        PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT,
      ]),
      this.sitesService.get(),
      this.activeTabIndex$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        setTimeout(() => {
          this.loading$.next(false);
          this.showGrid$.next(true);
        }, 0);
      });
    combineLatest([
      this.mainQuery.select('trialKey').pipe(tap(() => this.patientGroupsLoading$.next(true))),
      this.patientGroupsService.get([PatientGroupType.PATIENT_GROUP_STANDARD]),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        setTimeout(() => {
          this.patientGroupsLoading$.next(false);
          this.tabs = [];
          this.patientGroups = [];
          this.patientGroupsQuery
            .getAll()
            .forEach((patientGroup) => this.patientGroups.push(patientGroup));
          this.tabs.push(...this.iteratableTabs(this.patientGroups));
        }, 0);
      });
  }

  iteratableTabs(patientGroups: PatientGroupsModel[]) {
    const allTabs: { label: string; show: Observable<boolean> }[] = [];
    patientGroups.forEach((group) => {
      allTabs.push({ label: group.name, show: of(true) });
    });
    allTabs.push({ label: 'Visits Cost', show: of(true) });
    allTabs.push({ label: 'Other Costs', show: of(true) });
    return allTabs;
  }

  handleGridApi({ gridApi, columnApi }: { gridApi: GridApi; columnApi: ColumnApi }) {
    this.gridAPI = gridApi;
    this.gridColumnApi = columnApi;
  }

  private isTableHasDateCols(): boolean {
    const ignoreCols = ['target_tolerance_days_out', 'target_date_days_out'];

    const columnDefs = this.gridAPI.getColumnDefs() || [];

    return columnDefs.some((col: ColDef) => ignoreCols.indexOf(col.field as string) === 1);
  }

  getDynamicExcelParams(): ExcelExportParams {
    if (!this.gridAPI) {
      return {};
    }
    const name = this.mainQuery.getSelectedTrial()?.short_name;

    const patientGroupName =
      this.activeTabIndex$.getValue() > this.patientGroups.length
        ? 'Visits Costs'
        : `Patient Group : ${this.patientGroups[this.activeTabIndex$.getValue()].name}`;

    return {
      fileName: 'auxilius-patient-budget.xlsx',
      sheetName: 'Patient Budget',
      prependContent: [
        [
          {
            data: { value: `Trial: ${name} - ${patientGroupName}`, type: 'String' },
            mergeAcross: 1,
            styleId: 'first_row',
          },
        ],
      ],
      processCellCallback: (params) => {
        const colId = params.column.getColId();

        const isAmountContractCell = colId.endsWith(AMOUNT_PATTERN.CONTRACT);

        const amountKey = isAmountContractCell ? AMOUNT_PATTERN.CONTRACT : AMOUNT_PATTERN.PRIMARY;

        const siteId = colId.replace(amountKey, '');

        const rowData = params.node?.data || {};
        const currency = rowData[`${siteId}${CURRENCY_PATTERN.PRIMARY}`];
        const contractCurrency = rowData[`${siteId}${CURRENCY_PATTERN.CONTRACT}`];

        return TableService.processCellForExcel(
          new BehaviorSubject<CurrencyToggle>(
            isAmountContractCell ? CurrencyToggle.CONTRACTED : CurrencyToggle.PRIMARY
          ),
          amountKey,
          []
        )({
          ...params,
          node: {
            ...params.node,
            data: {
              ...(params.node?.data || {}),
              currency,
              contractCurrency,
            },
          } as RowNode,
        });
      },
    };
  }
}
