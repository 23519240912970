<div class="col-span-1">
  <ng-select
    class="select select__big mt-0"
    [formControl]="siteGroupControl"
    [clearable]="true"
    [searchable]="false"
    [placeholder]="formControlConstants.PLACEHOLDER.SELECT"
    [ngClass]="{
      'is-invalid': isInvalid
    }"
    [auxTooltip]="tooltip"
    (change)="onSiteGroupControlChange($event)"
  >
    <ng-option
      *ngFor="let siteCurveGroup of unfilteredSiteCurveGroups | filter: 'curve_type' : curveTypeControl.value"
      [value]="siteCurveGroup.driver_setting_id">
      <span [title]="siteCurveGroup.name">{{ siteCurveGroup.name }}</span>
    </ng-option>
  </ng-select>
</div>

<div class="col-span-1">
  <ng-select
    class="select select__big mt-0"
    [formControl]="curveTypeControl"
    [clearable]="false"
    [searchable]="false"
    [placeholder]="formControlConstants.PLACEHOLDER.SELECT"
    [ngClass]="{
      'is-invalid': isInvalid
    }"
    [auxTooltip]="tooltip"
    (change)="onCurveTypeControlChange($event)"
  >
    <ng-option *ngFor="let curveType of siteGroupsPeriodOptions" [value]="curveType.value">
      <span [title]="curveType.label">{{ curveType.label }}</span>
    </ng-option>
  </ng-select>
</div>
