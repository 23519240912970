import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { MilestoneCategory } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface MilestoneCategoryState
  extends EntityState<
    Omit<RequireSome<Partial<MilestoneCategory>, 'id'>, 'milestones'> & {
      milestone_ids: string[];
    }
  > {}

export type MilestoneCategoryModel = getEntityType<MilestoneCategoryState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'milestone-category' })
export class MilestoneCategoryStore extends EntityStore<MilestoneCategoryState> {
  constructor() {
    super({});
  }
}
