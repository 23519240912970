import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import {
  CellClickedEvent,
  CellEditingStartedEvent,
  ColumnApi,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ProcessCellForExportParams,
  RowNode,
  SuppressKeyboardEventParams,
} from 'ag-grid-community';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OverlayService } from '@services/overlay.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, EMPTY, merge, Observable, Subscription } from 'rxjs';
import { debounceTime, finalize, map, startWith, switchMap, take } from 'rxjs/operators';
import { first, last, mean, sum } from 'lodash-es';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { RowClassParams } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { LaunchDarklyService } from '@services/launch-darkly.service';

import {
  DistributionMode,
  DriverSiteGroup,
  EntityType,
  EventType,
  GqlService,
  PermissionType,
} from '@services/gql.service';
import { Utils } from '@services/utils';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { TrialsQuery } from '@models/trials/trials.query';
import { EventManager } from '@angular/platform-browser';
import { ExcelButtonVariant } from '@components/export-excel-button/export-excel-button.component';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { AuthService } from '@models/auth/auth.service';
import { MilestoneQuery } from '@models/milestone-category/milestone/milestone.query';
import { ApiService } from '@services/api.service';
import { TimelineQuery } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.query';
import { TimelineService } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.service';
import { CanvasChart } from '@components/canvas-chart/canvas-chart.model';
import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationStore } from '@models/organization/organization.store';
import {
  RemoveDialogComponent,
  RemoveDialogInput,
} from '@components/remove-dialog/remove-dialog.component';
import { DriverSiteService } from './state/driver-site.service';
import { DriverSiteQuery } from './state/driver-site.query';
import { DriverSiteSettingModel } from './state/driver-site.store';
import { SiteDriverUploadComponent } from './site-driver-upload/site-driver-upload.component';
import { ForecastAccrualsPageComponent } from '../../../../forecast-accruals-page.component';
import { SiteDistributionQuery } from './state/site-distribution.query';
import { SiteDistributionService } from './state/site-distribution.service';
import { WorkflowQuery } from '../../../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { WORKFLOW_NAMES } from '../../../../../closing-page/tabs/quarter-close/close-quarter-check-list';
import { ForecastSiteDriversComponent } from '../forecast-site-drivers.component';
import { SiteBlendedCurveModalComponent } from './site-blended-curve-modal/site-blended-curve-modal.component';
import { SiteGroupsQuery } from './state/site-groups.query';
import { SiteGroupsService } from './state/site-groups.service';
import { SiteCurveService } from './site-curve/site-curve.service';
import { SiteCurveQuery } from './site-curve/site-curve.query';
import { MessagesConstants } from '../../../../../../constants/messages.constants';
import { ROUTING_PATH } from '../../../../../../app-routing-path.const';
import { BlendedCurveModalDataModel } from '../models/blended-curve-modal-data.model';

export type TotalDriverSiteDistribution = {
  __typename: 'DriverSiteDistribution';
  distribution_mode: DistributionMode;
  distribution_month: string;
  id: string;
  net_sites_per_month?: number | null;
  net_sites_per_month_percentage?: number | null;
  sites_activated?: number | null;
  sites_activated_percentage?: number | null;
  sites_closed?: number | null;
  sites_closed_percentage?: number | null;
  total_sites_activated?: number | null;
};

@UntilDestroy()
@Component({
  selector: 'aux-forecast-site-curves',
  templateUrl: './forecast-site-curves.component.html',
  styles: [
    `
      ::ng-deep .site-table .ag-cell.editable-cell {
        border-width: 1px !important;
        justify-items: end;
        text-align: end;
      }

      ::ng-deep .site-table .ag-header-row,
      ::ng-deep .site-table .ag-header-cell {
        overflow: unset;
      }
      ::ng-deep .site-table .ag-header-cell-text,
      ::ng-deep .site-table .ag-row {
        font-size: 1rem;
      }
      ::ng-deep .site-table .ag-header-cell {
        text-align: center;
      }
      ::ng-deep .site-table .ag-header-cell-label {
        justify-content: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastSiteCurvesComponent implements OnInit, OnDestroy, AfterViewInit {
  static readonly DISABLED_BUTTON_TITLE =
    'The site curve is unable to be saved until the following issue is resolved: Sites Closed cannot be greater than Sites Activated';

  readonly messagesConstants = MessagesConstants;

  @ViewChildren('siteDriverMenu') siteDriverMenu!: QueryList<TemplateRef<any>>;

  @ViewChildren('selectCurveMenu') selectCurveMenu!: QueryList<TemplateRef<any>>;

  gridData$ = new BehaviorSubject<TotalDriverSiteDistribution[]>([]);

  isSitesFinalized$ = this.workflowQuery.getLockStatusByWorkflowName(
    WORKFLOW_NAMES.SITE_AND_PATIENTS_CURVES
  );

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  currentSiteGroupId = 0;

  isFirstTimeInitialization = true;

  exportButtonVariant = ExcelButtonVariant.OUTLINE_DOWNLOAD;

  multiChart$: Observable<CanvasChart> = this.gridData$.pipe(
    map((data) => {
      const labels = data.map(({ distribution_month }) =>
        Utils.dateFormatter(distribution_month, { day: undefined, year: '2-digit' })
      );
      return {
        datasets: [
          {
            data: data.map((x) => x.net_sites_per_month),
            label: 'Sites Per Month',
            borderColor: '#E3B506',
            pointHoverBorderColor: '#E3B506',
            type: 'line',
          },
          {
            data: data.map((x) => x.total_sites_activated),
            label: 'Total Sites Activated',
            borderColor: '#236262',
            pointHoverBorderColor: '#236262',
            type: 'line',
          },
          {
            data: data.map((x) => x.sites_activated),
            label: 'Sites Activated',
            borderColor: '#095b95',
            pointHoverBorderColor: '#095b95',
            type: 'bar',
          },
          {
            data: data.map((x) => x.sites_closed),
            label: 'Sites Closed',
            borderColor: '#6B9DBF',
            pointHoverBorderColor: '#6B9DBF',
            type: 'bar',
          },
        ],
        options: {
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              display: false,
            },
          },
          tooltips: {
            callbacks: {
              labelColor: (tooltipItem, chart) => {
                const color = (chart.data?.datasets?.[tooltipItem.datasetIndex || 0].borderColor ||
                  'red') as string;
                return {
                  borderColor: color,
                  backgroundColor: color,
                };
              },
            },
          },
        },
        colors: ['#E3B506', '#236262', '#095b95', '#6B9DBF', '#BACAD0'].map((color) => ({
          pointStyle: 'rectRounded',
          borderColor: color,
          backgroundColor: color === '#E3B506' || color === '#236262' ? 'rgba(0,0,0,0)' : color,
          pointBorderColor: color === '#E3B506' || color === '#236262' ? 'rgba(0,0,0,0)' : color,
          pointBackgroundColor:
            color === '#E3B506' || color === '#236262' ? 'rgba(0,0,0,0)' : color,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: color,
          borderWidth: 3,
          pointHoverBorderWidth: 4,
          pointHoverRadius: 1,
          pointHitRadius: 1,
        })),
        legend: { bottom: true, right: false, left: false, top: false },
        labels,
        type: 'bar',
      } as CanvasChart;
    })
  );

  excelPercentFormat = '0.00;-0.00;—;—';

  editCell = false;

  gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (!params.editing) {
          switch (params.event.keyCode) {
            // delete and backspace key number
            case 46:
            case 8:
              if (this.editMode$.getValue()) {
                // @ts-ignore
                params.api.getCellRanges().forEach((range) => {
                  // @ts-ignore
                  const colIds = range.columns.map((col) => col.colId);
                  // @ts-ignore
                  const startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                  // @ts-ignore
                  const endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

                  this.clearCells(startRowIndex, endRowIndex, colIds, params.api);
                  this.calculateSiteCurves();
                });
              }

              return true;
            default:
              return false;
          }
        }
        return false;
      },
      editable: () => {
        return this.editMode$.getValue();
      },
      cellClass: (params) => {
        if (params.data.distribution_month === 'TOTAL') {
          return params.colDef.field === 'distribution_month' ? '' : 'justify-end';
        }
        switch (params.colDef.field) {
          case 'distribution_month':
            return this.editCell ? 'opacity-70' : '';
          case 'sites_activated':
          case 'sites_closed':
          case 'net_sites_per_month_percentage':
          case 'sites_activated_percentage':
          case 'sites_closed_percentage':
            return this.editCell ? ['editable-cell', 'justify-end'] : 'justify-end';
          case 'net_sites_per_month':
            return this.editCell ? ['opacity-70', 'justify-end'] : 'justify-end';
          default:
            return '';
        }
      },
      cellClassRules: {
        'bg-aux-error': (params: any) => {
          let ret = false;
          switch (params.colDef.field) {
            case 'sites_activated':
            case 'sites_closed':
              if (params.data.distribution_month === 'TOTAL') {
                ret = Number(params.data.sites_closed) > Number(params.data.sites_activated);
                this.isSaveBtnDisabled = ret;
                this.saveBtnTitle = ret ? ForecastSiteCurvesComponent.DISABLED_BUTTON_TITLE : '';
              }
              break;
            default:
              break;
          }

          return ret;
        },
      },
      cellStyle: (params) => {
        switch (params.colDef.field) {
          case 'net_sites_per_month_percentage':
          case 'sites_activated_percentage':
          case 'sites_closed_percentage':
            return this.editCell
              ? { opacity: 0.7, 'justify-items': 'end' }
              : { 'justify-item': 'end' };
          default:
            return undefined;
        }
      },
    },
    onCellClicked(event: CellClickedEvent) {
      const cellRange = event.api.getCellRanges();
      // @ts-ignore
      if (cellRange?.length > 1) {
        event.api.stopEditing();
      }
    },
    enableRangeSelection: true,
    undoRedoCellEditingLimit: 20,
    undoRedoCellEditing: true,
    suppressMenuHide: true,
    headerHeight: 40,
    groupIncludeFooter: true,
    enterMovesDown: true,
    enterMovesDownAfterEdit: true,
    pinnedBottomRowData: [],
    rowClassRules: {
      'has-error': (params) => params.data.showError,
    },
    getRowStyle: (params: RowClassParams) => {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold', 'justify-item': 'end' };
      }
      return {};
    },
    columnDefs: [
      {
        headerName: 'Month',
        field: 'distribution_month',
        headerClass: 'ag-header-align-center',
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value === 'TOTAL') {
            return 'TOTAL';
          }
          return params.value
            ? Utils.dateFormatter(params.value, { day: undefined, year: '2-digit' })
            : null;
        },
        editable: false,
      },
      {
        headerName: 'Sites Activated',
        headerClass: 'ag-header-align-center',
        field: 'sites_activated',
        aggFunc: 'sum',
        valueFormatter: this.valueFormatter,
      },
      {
        headerName: 'Sites Closed',
        field: 'sites_closed',
        headerClass: 'ag-header-align-center',
        aggFunc: 'sum',
        valueFormatter: this.valueFormatter,
      },
      {
        headerName: 'Net Sites Per Month',
        field: 'net_sites_per_month',
        headerClass: 'ag-header-align-center',
        aggFunc: 'sum',
        valueFormatter: this.valueFormatter,
        editable: false,
      },
      {
        headerName: 'Net Sites Per Month %',
        field: 'net_sites_per_month_percentage',
        headerClass: 'ag-header-align-center',
        aggFunc: 'sum',
        cellClass: () => {
          return this.editCell
            ? ['opacity-70', 'cellPercent', 'justify-end']
            : ['justify-end', 'cellPercent'];
        },
        editable: false,
        valueFormatter: (val: ValueFormatterParams) => {
          return val.value ? `${(+val.value).toFixed(1)}%` : '0.0%';
        },
      },
      {
        headerName: 'Total Sites Activated',
        field: 'total_sites_activated',
        headerClass: 'ag-header-align-center',
        editable: false,
        aggFunc: 'sum',
        valueFormatter: this.valueFormatter,
      },
      {
        headerName: 'Sites Activated %',
        field: 'sites_activated_percentage',
        headerClass: 'ag-header-align-center',
        aggFunc: 'sum',
        cellClass: () => {
          return this.editCell
            ? ['opacity-70', 'cellPercent', 'justify-end']
            : ['justify-end', 'cellPercent'];
        },
        editable: false,
        valueFormatter: (val: ValueFormatterParams) => {
          return val.value ? `${(+val.value).toFixed(1)}%` : '0.0%';
        },
      },
      {
        headerName: 'Sites Closed %',
        field: 'sites_closed_percentage',
        headerClass: 'ag-header-align-center',
        aggFunc: 'sum',
        cellClass: () => {
          return this.editCell
            ? ['opacity-70', 'cellPercent', 'justify-end']
            : ['justify-end', 'cellPercent'];
        },
        editable: false,
        valueFormatter: (val: ValueFormatterParams) => {
          return val.value ? `${(+val.value).toFixed(1)}%` : '0.0%';
        },
      },
    ],
    excelStyles: [
      {
        id: 'header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { patternColor: '#094673', color: '#094673', pattern: 'Solid' },
      },
      {
        id: 'text-aux-error',
        font: { color: '#D73C37' },
      },
      {
        id: 'text-aux-green',
        font: { color: '#437F7F' },
      },
      {
        id: 'cellPercent',
        font: { fontName: 'Arial', size: 11 },
        alignment: { horizontal: 'Right' },
        numberFormat: { format: this.excelPercentFormat },
      },
      {
        id: 'first_row',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
      },
      {
        id: 'total_row_header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      },
      {
        id: 'total_row',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
        dataType: 'Number',
        numberFormat: { format: '#,##0.00' },
      },
      {
        id: 'total_row_percent',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
        dataType: 'String',
        alignment: {
          horizontal: 'Right',
        },
      },
    ],
  } as GridOptions;

  excelOptions = {
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Site Curve',
    fileName: 'auxilius-site-curve.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.id;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'distribution_month':
          return 225;
        default:
          return 225;
      }
    },
    processCellCallback: (params: ProcessCellForExportParams): string => {
      const coldId = params.column.getColId();
      if (coldId === 'id') {
        return this.organizationQuery.getEntity(params.value)?.name || '';
      }

      const isPercentColumn = [
        'net_sites_per_month_percentage',
        'sites_activated_percentage',
        'sites_closed_percentage',
      ].some((key) => coldId.endsWith(key));

      if (isPercentColumn) {
        return params.value ? (+params.value).toFixed(1) : Utils.zeroHyphen;
      }

      return params.value;
    },
  } as ExcelExportParams;

  gridColumnApi!: ColumnApi;

  gridApi!: GridApi;

  loading$ = new BehaviorSubject(false);

  isBlended$ = new BehaviorSubject(false);

  showEnrollmentSettings$: Observable<boolean>;

  siteCurveGroups: any[] = [];

  siteCurveGroups$ = new BehaviorSubject<any[] | undefined>([]);

  isOpen = false;

  highlightedCurve: number | null = null;

  highlightedSite: number | null = null;

  selected = '';

  selectedCurveIndex: number | null = null;

  selectedSiteIndex: number | null = null;

  selectedSite = new FormControl('');

  clonedSite: TotalDriverSiteDistribution[] = [];

  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  btnLoading$ = new BehaviorSubject(false);

  editMode$ = new BehaviorSubject(false);

  isFromScratch = false;

  userHasModifyPermissions = false;

  isSaveBtnDisabled = false;

  saveBtnTitle = '';

  fg = new FormGroup({
    settings: new FormArray([]),
  });

  initialValue: TotalDriverSiteDistribution[] = [];

  editedRows = new Set<string>();

  removedRows: string[] = [];

  fgListener: Subscription | undefined;

  setSiteGroupSubscription!: Subscription;

  avg$ = this.driverSiteQuery.selectAll().pipe(
    debounceTime(200),
    map((siteSettings) => {
      const total_number_of_sites = sum(siteSettings.map((ss) => ss.number_of_sites));
      const first_initiated_date = siteSettings
        .map((ss) => ss.first_initiated_date)
        .sort((a, b) => Date.parse(a || '') - Date.parse(b || ''))[0];
      const discontinued_dates = siteSettings
        .map((ss) => ss.discontinued_date)
        .sort((a, b) => Date.parse(a || '') - Date.parse(b || ''));
      const discontinued_date = discontinued_dates[discontinued_dates.length - 1];
      const ramp_time_in_weeks = mean(siteSettings.map((ss) => ss.ramp_time_in_weeks));
      const target_patient_count = mean(siteSettings.map((ss) => ss.target_patient_count));

      return {
        total_number_of_sites,
        first_initiated_date,
        discontinued_date,
        ramp_time_in_weeks,
        target_patient_count,
      };
    })
  );

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private overlayService: OverlayService,
    private forecastPage: ForecastAccrualsPageComponent,
    private driversPage: ForecastSiteDriversComponent,
    private gqlService: GqlService,
    private siteGroupService: SiteGroupsService,
    private mainQuery: MainQuery,
    private siteDistributionService: SiteDistributionService,
    private cdr: ChangeDetectorRef,
    private vendorsService: OrganizationService,
    public organizationQuery: OrganizationQuery,
    private organizationStore: OrganizationStore,
    private siteCurveService: SiteCurveService,
    private siteCurveQuery: SiteCurveQuery,
    private milestoneQuery: MilestoneQuery,
    private siteGroupQuery: SiteGroupsQuery,
    private driverSiteService: DriverSiteService,
    public driverSiteQuery: DriverSiteQuery,
    private fb: FormBuilder,
    private timelineService: TimelineService,
    private timelineQuery: TimelineQuery,
    private apiService: ApiService,
    private siteQuery: SiteDistributionQuery,
    private trialsQuery: TrialsQuery,
    private eventManager: EventManager,
    private authService: AuthService,
    private workflowQuery: WorkflowQuery
  ) {
    this.showEnrollmentSettings$ = this.launchDarklyService.select$(
      (flags) => flags.section_site_driver_assumptions
    );

    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_MODIFY_SITE_CURVE],
      })
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.userHasModifyPermissions = x;
      });
  }

  ngOnInit() {
    this.vendorsService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length === 1) {
          this.organizationStore.setActive(vendors[0].id);
        } else {
          // reset any older selected vendors.
          this.organizationStore.setActive(null);
        }
      });

    this.mainQuery
      .select('trialKey')
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          this.loading$.next(true);
          return combineLatest([this.siteCurveService.get(), this.siteGroupService.get()]);
        })
      )
      .subscribe(() => {
        this.selected = '';
        this.setSiteGroups();
        this.loading$.next(false);
      });

    this.eventManager.addEventListener(document.body, 'keydown', (event: any) => {
      switch (event.code) {
        case 'Tab':
          this.gridApi.stopEditing();
          setTimeout(() => {
            const getCellCor = this.gridApi.getFocusedCell();
            this.gridApi.startEditingCell({
              rowIndex: getCellCor?.rowIndex || 0,
              // @ts-ignore
              colKey: getCellCor?.column?.colId || '',
            });
          }, 0);
          break;
        default:
          break;
      }
    });

    this.showEnrollmentSettings$
      .pipe(
        switchMap((flag) => {
          if (flag) {
            return this.driverSiteService.get();
          }
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {});

    this.driverSiteQuery
      .selectAll()
      .pipe(untilDestroyed(this), debounceTime(200))
      .subscribe((siteSettings) => {
        const fa = this.fg.get('settings') as FormArray;
        fa.clear();
        siteSettings.map((ss) => this.createSiteSettingFB(ss)).forEach((x) => fa.push(x));
        this.listenForChanges();
        this.cdr.markForCheck();
      });
  }

  ngAfterViewInit(): void {
    this.siteDriverMenu.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        finalize(() => {
          setTimeout(() => {
            this.forecastPage.rightSideContainer.next(null);
          }, 0);
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.forecastPage.rightSideContainer.next(this.siteDriverMenu.first || null);
        }, 0);
      });

    this.selectCurveMenu.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        finalize(() => {
          setTimeout(() => {
            this.driversPage.rightSideContainer.next(null);
          }, 0);
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.driversPage.rightSideContainer.next(this.selectCurveMenu.first || null);
        }, 0);
      });
  }

  async canDeactivate(): Promise<boolean> {
    if (this.editMode$.getValue()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await result.afterClosed$.toPromise();
      return !!event.data;
    }
    return true;
  }

  valueFormatter(val: ValueFormatterParams) {
    const newValue = val.value === '0' ? 0 : val.value;
    return newValue ? (+newValue).toFixed(1) : Utils.zeroHyphen;
  }

  onDataRendered({ api }: { api: GridApi }) {
    api.sizeColumnsToFit();
  }

  calculatePinnedBottomData() {
    const columnsWithAggregation = [
      'sites_activated',
      'sites_closed',
      'net_sites_per_month',
      'net_sites_per_month_percentage',
      'total_sites_activated',
      'sites_activated_percentage',
      'sites_closed_percentage',
    ];

    return this.gridData$.getValue().reduce(
      (acc, val) => {
        for (const key of columnsWithAggregation) {
          if (
            [
              'net_sites_per_month_percentage',
              'sites_activated_percentage',
              'sites_closed_percentage',
            ].includes(key)
          ) {
            // @ts-ignore
            acc[key] += Number(Number(val[key]).toFixed(1));
          } else {
            // @ts-ignore
            acc[key] += Number(val[key]);
          }
        }
        acc.net_sites_per_month = 0;
        acc.total_sites_activated = acc.sites_activated;
        return acc;
      },
      {
        sites_activated: 0,
        sites_closed: 0,
        net_sites_per_month: 0,
        net_sites_per_month_percentage: 0,
        total_sites_activated: 0,
        sites_activated_percentage: 0,
        sites_closed_percentage: 0,
      }
    );
  }

  onGridReady(e: GridReadyEvent) {
    this.gridColumnApi = e.columnApi;
    this.gridApi = e.api;
    this.gridData$.pipe(untilDestroyed(this)).subscribe(() => {
      const pinnedBottomData = { ...this.calculatePinnedBottomData(), distribution_month: 'TOTAL' };
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    });
  }

  onPasteStart() {
    if (!this.editMode$.getValue()) {
      this.editMode$.next(true);
      this.editGrid();
    }
  }

  onSiteDriverUploadClick() {
    this.overlayService.open({ content: SiteDriverUploadComponent });
  }

  highlightCurve(index: number): void {
    this.highlightedCurve = index;
  }

  async selectCurve(item: any, isNewCreate = false) {
    this.loading$.next(true);
    this.currentSiteGroupId = item?.site_group_id;
    const index = this.highlightedCurve;
    if (index != null || item) {
      this.selectedCurveIndex = index;
      if (this.editMode$.getValue()) {
        this.gridApi.stopEditing();
      }
      this.editMode$.next(false);
      this.editCell = false;
      if (!item?.driver_setting_id || item.driver_setting_id === '') {
        this.populateGridDataWithTimeline();
        this.isBlended$.next(false);
        this.isFromScratch = true;
        this.selected = item.name;
      } else {
        this.isFromScratch = false;
        this.isBlended$.next(!!item?.is_blended);
        if (this.selected !== item.name || isNewCreate) {
          this.selected = item.name;
          this.siteDistributionService
            .getSiteCurveDistributions(item?.site_group_id)
            .pipe(take(1))
            .subscribe(() => {
              this.cloneSite();
              if (this.clonedSite.length > 0) {
                const newData = JSON.parse(JSON.stringify(this.clonedSite));
                this.gridData$.next(newData);
              } else {
                this.isFromScratch = true;
                this.populateGridDataWithTimeline();
              }
            });
        } else if (this.gridData$.getValue().length === 0) {
          this.isFromScratch = true;
          this.populateGridDataWithTimeline();
        }
      }
    }

    this.closeList();
    this.loading$.next(false);
  }

  populateGridDataWithTimeline() {
    this.timelineService.getTimelineItems().pipe(untilDestroyed(this)).subscribe();
    this.timelineQuery
      .select()
      .pipe(take(2))
      .subscribe(({ items }) => {
        const start_date = first(items)?.contract_start_date;
        let end_date = Utils.dateParse(`${last(items)?.contract_end_date.slice(0, 8)}01`);
        items.forEach((i) => {
          const parsedDate = Utils.dateParse(`${i.contract_end_date.slice(0, 8)}01`);
          if (parsedDate > end_date) {
            end_date = parsedDate;
          }
        });

        const month_list: { label: string; value: string }[] = [];
        this.clonedSite = [];
        if (start_date) {
          let date = Utils.dateParse(`${start_date.slice(0, 8)}01`);
          while (date <= end_date) {
            const value = new Intl.DateTimeFormat('fr-CA', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }).format(date);

            month_list.push({
              value,
              label: Utils.dateFormatter(value, { day: undefined, year: '2-digit' }),
            });
            date = Utils.addMonths(date, 1);
            const l: TotalDriverSiteDistribution = {
              distribution_month: value,
              sites_activated: 0,
              sites_closed: 0,
              net_sites_per_month: 0,
              total_sites_activated: 0,
              distribution_mode: DistributionMode.DISTRIBUTION_MODE_FORECAST,
              __typename: 'DriverSiteDistribution',
              id: '',
            };
            if (this.clonedSite.length < month_list.length) {
              this.clonedSite.push(l);
            }
          }
        }
        const newData = Utils.clone(this.clonedSite);
        this.gridData$.next(newData);
      });
  }

  closeList() {
    this.isOpen = false;
    this.highlightedCurve = null;
    this.highlightedSite = null;
    this.editMode$.next(false);
    this.cdr.detectChanges();
  }

  openList(trigger: CdkOverlayOrigin) {
    console.log('trigger', trigger);
    this.isOpen = true;

    if (this.selectedSiteIndex != null) {
      this.highlightedSite = this.selectedSiteIndex;
    } else {
      this.highlightedSite = 0;
    }

    if (this.selectedCurveIndex != null) {
      this.highlightedCurve = this.selectedCurveIndex;
    } else {
      this.highlightedCurve = 0;
    }
    this.cdr.detectChanges();
  }

  editCurve(item: any = null) {
    this.overlayService.open({
      content: SiteBlendedCurveModalComponent,
      data: this.blendedCurveModalParams(item),
    });
    this.unselectCurveAndResetView();
    this.closeList();
  }

  async deleteCurve(driverSiteGroup: any) {
    if (!driverSiteGroup) {
      return;
    }

    if (driverSiteGroup.organizations_with_forecasts.length > 0) {
      const removeDialogInput: RemoveDialogInput = {
        header: 'Remove Site Curve',
        cannotDeleteMessage: `${driverSiteGroup.name} cannot be deleted because it is being used in other areas of the application.<br>Please first remove the dependencies below and then ${driverSiteGroup.name} can be successfully deleted.<br>`,

        routeInputs: [
          {
            componentName: 'Forecast Methodology:',
            name: [driverSiteGroup.name] || [],
            link: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}`,
          },
        ],
      };

      const resp = this.overlayService.open({
        content: RemoveDialogComponent,
        data: removeDialogInput,
      });

      await resp.afterClosed$.toPromise();
    } else {
      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Site Curve',
        message: `Are you sure you want to remove Site Curve ${driverSiteGroup.name}?`,
        okBtnText: 'Remove',
      });
      const event = await resp.afterClosed$.toPromise();
      if (event.data?.result) {
        await this.siteCurveService.removeSiteCurve(driverSiteGroup);
      }
    }
    this.unselectCurveAndResetView();
  }

  setSiteGroups() {
    if (this.setSiteGroupSubscription) {
      this.setSiteGroupSubscription.unsubscribe();
    }

    this.setSiteGroupSubscription = combineLatest([
      this.siteCurveQuery.selectAll(),
      this.siteGroupQuery.selectAll(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([curves, siteGroups]) => {
        if (this.siteCurveGroups?.length < curves?.length) {
          this.siteCurveGroups.push(...curves);
        }
        const siteGroupIds: String[] = [];
        curves?.forEach((c) => {
          siteGroupIds.push(c?.site_group_id);
        });
        const uniqSiteGroups: any[] = [];
        siteGroups.forEach((siteGroup) => {
          if (!siteGroupIds.includes(siteGroup.id)) {
            uniqSiteGroups.push({
              driver_setting_id: '',
              is_blended: false,
              name: siteGroup.name,
              site_group_id: siteGroup.id,
              patient_group_ids: [],
              showLine: false,
              organizations_with_forecasts: [],
            });
          }
        });
        const siteCurveGroupList = [...curves, ...uniqSiteGroups];

        if (this.isFirstTimeInitialization) {
          if (siteCurveGroupList?.length > 1 && this.selected !== '') {
            const selectedItem = siteCurveGroupList.find((y) => y.name === this.selected);
            if (selectedItem) {
              this.highlightCurve(siteCurveGroupList.findIndex((y) => y.name === this.selected));
              this.isFromScratch = false;
              this.selectCurve(selectedItem, true);
            } else if (siteCurveGroupList?.[0]) {
              this.isFromScratch = false;
              this.selectCurve(siteCurveGroupList[0]);
            } else {
              this.isFromScratch = true;
              this.unselectCurveAndResetView();
            }
          } else if (siteCurveGroupList?.[0]) {
            this.isFromScratch = false;
            this.selectCurve(siteCurveGroupList[0]);
          } else {
            this.isFromScratch = true;
            this.unselectCurveAndResetView();
          }
        }

        this.isFirstTimeInitialization = false;
        this.siteCurveGroups$.next(siteCurveGroupList);
      });
  }

  getDynamicExcelParams(): ExcelExportParams {
    const trial = this.trialsQuery.getEntity(this.mainQuery.getValue().trialKey);
    if (!trial) {
      return {};
    }
    const fieldsToCalc = [
      'sites_activated',
      'sites_closed',
      'net_sites_per_month_percentage',
      'sites_activated_percentage',
      'sites_closed_percentage',
    ];

    const totals = this.gridData$.getValue().reduce(
      (acc, val) => {
        for (const key of fieldsToCalc) {
          if (
            [
              'net_sites_per_month_percentage',
              'sites_activated_percentage',
              'sites_closed_percentage',
            ].includes(key)
          ) {
            // @ts-ignore
            acc[key] += Number(Number(val[key]).toFixed(1));
          } else {
            // @ts-ignore
            acc[key] += Number(val[key]);
          }
        }
        acc.total_sites_activated = acc.sites_activated;
        return acc;
      },
      {
        sites_activated: 0,
        sites_closed: 0,
        net_sites_per_month: 0,
        net_sites_per_month_percentage: 0,
        sites_activated_percentage: 0,
        sites_closed_percentage: 0,
        total_sites_activated: 0,
      }
    );

    const exportOptions = {
      ...this.excelOptions,
      columnKeys: [
        'distribution_month',
        'sites_activated',
        'sites_closed',
        'net_sites_per_month',
        'net_sites_per_month_percentage',
        'total_sites_activated',
        'sites_activated_percentage',
        'sites_closed_percentage',
      ],
      prependContent: [
        [
          {
            data: { value: `Trial: ${trial.short_name}`, type: 'String' },
            mergeAcross: 4,
            styleId: 'first_row',
          },
        ],
      ],
      appendContent: [
        [
          {
            data: { value: `Total`, type: 'String' },
            styleId: 'total_row_header',
          },
          {
            // sites activated
            data: { value: `${totals.sites_activated}`, type: 'Number' },
            styleId: 'total_row',
          },
          {
            // sites closed
            data: { value: `${totals.sites_closed}`, type: 'Number' },
            styleId: 'total_row',
          },
          {
            // net sites per month
            data: { value: `${totals.sites_activated - totals.sites_closed}`, type: 'Number' },
            styleId: 'total_row',
          },
          {
            // net sites per month %
            data: { value: `${totals.net_sites_per_month_percentage.toFixed(1)}`, type: 'String' },
            styleId: 'total_row_percent',
          },
          {
            // sites activated
            data: { value: `${totals.total_sites_activated}`, type: 'Number' },
            styleId: 'total_row',
          },
          {
            // sites activated %
            data: { value: `${totals.sites_activated_percentage.toFixed(1)}`, type: 'String' },
            styleId: 'total_row_percent',
          },
          {
            // sites closed %
            data: { value: `${totals.sites_closed_percentage.toFixed(1)}`, type: 'String' },
            styleId: 'total_row_percent',
          },
        ],
      ],
    } as ExcelExportParams;

    this.gridApi.setPinnedBottomRowData([]);
    // this.gridApi?.exportDataAsExcel(exportOptions);
    this.gridData$.pipe(untilDestroyed(this)).subscribe(() => {
      const pinnedBottomData = { ...this.calculatePinnedBottomData(), distribution_month: 'TOTAL' };
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    });

    return exportOptions;
  }

  calculatedGridData(uncalculatedSite: TotalDriverSiteDistribution[]) {
    const isNumber = (n: any) => {
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    };

    const negativeCheck = (n: any) => {
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(Number(n)) && String(n).indexOf('-') !== -1 ? String(n).replace('-', '') : n;
    };
    let totalNetSiteEnrolled = 0;
    let totalSiteActivated = 0;
    let totalSiteClosed = 0;
    let percentTotal = 0;
    const nData: TotalDriverSiteDistribution[] = [];
    uncalculatedSite.forEach((x: TotalDriverSiteDistribution) => {
      const newRow: TotalDriverSiteDistribution = x;
      totalNetSiteEnrolled +=
        this.roundToNumber(negativeCheck(x.sites_activated) || 0) -
        this.roundToNumber(negativeCheck(x.sites_closed) || 0);
      totalSiteActivated += this.roundToNumber(negativeCheck(x.sites_activated) || 0);
      totalSiteClosed += this.roundToNumber(negativeCheck(x.sites_closed) || 0);
      newRow.sites_activated = isNumber(negativeCheck(newRow.sites_activated))
        ? negativeCheck(newRow.sites_activated)
        : 0;
      newRow.sites_closed = isNumber(negativeCheck(newRow.sites_closed))
        ? negativeCheck(newRow.sites_closed)
        : 0;
      newRow.net_sites_per_month = totalNetSiteEnrolled;

      newRow.total_sites_activated = totalSiteActivated;

      percentTotal += this.roundToNumber(newRow.net_sites_per_month, 2);

      nData.push(newRow);
    });

    nData.forEach((x: TotalDriverSiteDistribution) => {
      // eslint-disable-next-line no-param-reassign
      x.net_sites_per_month_percentage = this.roundToNumber(
        (100 / percentTotal) * (x.net_sites_per_month || 0),
        2
      );
      // eslint-disable-next-line no-param-reassign
      x.sites_activated_percentage = this.roundToNumber(
        (100 / totalSiteActivated) * (x.sites_activated || 0),
        2
      );
      // eslint-disable-next-line no-param-reassign
      x.sites_closed_percentage = this.roundToNumber(
        (100 / totalSiteClosed) * (x.sites_closed || 0),
        2
      );
    });

    return nData;
  }

  calculateSiteCurves() {
    const isNumber = (n: any) => {
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    };

    const negativeCheck = (n: any) => {
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(Number(n)) && String(n).indexOf('-') !== -1 ? String(n).replace('-', '') : n;
    };
    let totalNetSiteEnrolled = 0;
    let totalSiteActivated = 0;
    let totalSiteClosed = 0;
    let percentTotal = 0;

    this.gridData$.getValue().forEach((x: TotalDriverSiteDistribution) => {
      totalNetSiteEnrolled +=
        this.roundToNumber(negativeCheck(x.sites_activated) || 0) -
        this.roundToNumber(negativeCheck(x.sites_closed) || 0);
      totalSiteActivated += this.roundToNumber(negativeCheck(x.sites_activated) || 0);
      totalSiteClosed += this.roundToNumber(negativeCheck(x.sites_closed) || 0);

      // eslint-disable-next-line no-param-reassign
      x.sites_activated = isNumber(negativeCheck(x.sites_activated))
        ? negativeCheck(x.sites_activated)
        : 0;
      // eslint-disable-next-line no-param-reassign
      x.sites_closed = isNumber(negativeCheck(x.sites_closed)) ? negativeCheck(x.sites_closed) : 0;
      // eslint-disable-next-line no-param-reassign
      x.net_sites_per_month = totalNetSiteEnrolled;
      // eslint-disable-next-line no-param-reassign
      x.total_sites_activated = totalSiteActivated;

      percentTotal += this.roundToNumber(x.net_sites_per_month, 2);
    });

    this.gridData$.getValue().forEach((x: TotalDriverSiteDistribution) => {
      // eslint-disable-next-line no-param-reassign
      x.net_sites_per_month_percentage = this.roundToNumber(
        (100 / percentTotal) * (x.net_sites_per_month || 0),
        2
      );
      // eslint-disable-next-line no-param-reassign
      x.sites_activated_percentage = this.roundToNumber(
        (100 / totalSiteActivated) * (x.sites_activated || 0),
        2
      );
      // eslint-disable-next-line no-param-reassign
      x.sites_closed_percentage = this.roundToNumber(
        (100 / totalSiteClosed) * (x.sites_closed || 0),
        2
      );
    });

    this.gridData$.next(this.gridData$.getValue());
    this.gridApi.refreshCells();
  }

  showErrors() {
    const rowNodes: RowNode[] = [];
    let isThereAnyInvalidRow = false;
    const isNumber = (n: any) => {
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    };
    this.gridApi.forEachNode((node) => {
      const { sites_activated, sites_closed } = node.data as TotalDriverSiteDistribution;

      if (isNumber(sites_activated) && isNumber(sites_closed)) {
        if (node.data.showError) {
          // eslint-disable-next-line no-param-reassign
          node.data.showError = false;
          rowNodes.push(node);
        }
      } else {
        isThereAnyInvalidRow = true;
        // eslint-disable-next-line no-param-reassign
        node.data.showError = true;
        rowNodes.push(node);
      }
    });

    this.gridApi.redrawRows({ rowNodes });
    return isThereAnyInvalidRow;
  }

  async onSaveAll() {
    this.btnLoading$.next(true);
    const isInvalid = this.showErrors();
    if (isInvalid) {
      this.btnLoading$.next(false);
      this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
      return;
    }

    if (this.isFromScratch) {
      this.processSitesCreatedFromScratch();
    } else if (this.fg.valid && !this.loading$.getValue()) {
      this.loading$.next(true);

      const { settings } = this.fg.value as {
        settings: any[];
      };

      const thingsToUpdate = settings.filter((x) => !x.id || this.editedRows.has(x.id));

      const allErrors: string[][] = [];

      const gData = this.gridData$.getValue();

      if (thingsToUpdate.length > 0) {
        const sitePromises = [];
        for (const x of gData) {
          // eslint-disable-next-line no-await-in-loop
          sitePromises.push(this.siteDistributionService.updateSiteDistribution(x));
        }
        const responses = await Promise.all(sitePromises);
        if (!responses.every((x) => x)) {
          this.btnLoading$.next(false);
          this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
          await this.cancelEditMode();
          this.btnLoading$.next(false);
          return;
        }
        this.editedRows.clear();
        this.overlayService.success('Sites Table updated successfully!');
      }

      if (this.removedRows.length) {
        const errors = await this.driverSiteService.remove(this.removedRows);
        allErrors.push(...errors);
        this.removedRows = [];
        this.gridData$.next([]);
        this.gridOptions.api?.refreshCells({ force: true });
      }

      if (allErrors.length) {
        this.overlayService.error(...allErrors);
      } else {
        this.overlayService.success();
      }

      this.loading$.next(false);
      await this.cancelEditMode();
    }
    this.btnLoading$.next(false);
  }

  getFilePath(id: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/sites${id}/site-driver/`;
  }

  editGrid() {
    this.editCell = true;
    this.gridOptions.api?.refreshCells({ force: true });
    this.gridApi.startEditingCell({
      rowIndex: 0,
      colKey: 'sites_activated',
    });
  }

  clearCells(start: any, end: any, columns: any, gridApi: any) {
    const itemsToUpdate = [];

    for (let i = start; i <= end; i++) {
      const dData = gridApi.rowModel.rowsToDisplay[i].data;
      columns.forEach((column: string | number) => {
        dData[column] = 0;
      });
      itemsToUpdate.push(dData);
    }

    gridApi.applyTransaction({ update: itemsToUpdate });
  }

  async cancelEditMode() {
    let isNewData = false;
    this.gridData$.next(this.initialValue);
    this.gridData$.getValue().forEach((row) => {
      isNewData ||=
        row.id === '' && (!!row.sites_activated || !!row.sites_closed || !!row.net_sites_per_month);
    });
    if (isNewData && this.isFromScratch) {
      this.populateGridDataWithTimeline();
    } else {
      this.setSiteGroups();
    }
    if (this.editMode$.getValue()) {
      this.gridApi.stopEditing(true);
    }
    this.editMode$.next(false);
    this.editCell = false;
    this.gridOptions.api?.refreshCells({ force: true });
  }

  saveEditMode = async () => {
    this.btnLoading$.next(true);
    const isInvalid = this.showErrors();
    if (isInvalid) {
      this.btnLoading$.next(false);
      this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
      return;
    }

    let isNewData = false;
    this.gridData$.getValue().forEach((row) => {
      isNewData ||=
        row.id === '' && (!!row.sites_activated || !!row.sites_closed || !!row.net_sites_per_month);
    });

    if (!isInvalid) {
      if (this.isFromScratch || isNewData) {
        this.processSitesCreatedFromScratch();
      } else {
        const sData = this.gridData$.getValue();
        const sitePromises = [];
        for (const x of sData) {
          if (x.id) {
            sitePromises.push(this.siteDistributionService.updateSiteDistribution(x));
          }
        }
        const responses = await Promise.all(sitePromises);
        if (!responses.every((x) => x)) {
          this.btnLoading$.next(false);
          this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
          return;
        }
        this.overlayService.success('Sites Table updated successfully!');

        const { success, errors } = await this.gqlService
          .processEvent$({
            type: EventType.SITE_DRIVER_DISTRIBUTION_UPDATED,
            entity_type: EntityType.DRIVER,
            entity_id:
              this.currentSiteGroupId === null || this.currentSiteGroupId === 0
                ? ''
                : this.currentSiteGroupId.toString(),
          })
          .toPromise();

        if (success) {
          this.overlayService.success();
        } else {
          this.overlayService.error(errors);
        }
        this.setSiteGroups();
        await this.cancelEditMode();
      }
      this.editMode$.next(false);
      this.btnLoading$.next(false);
    }
  };

  cellValueChanged() {
    this.calculateSiteCurves();
  }

  rowPinnedCheck(event: CellEditingStartedEvent) {
    if (event.node.rowPinned) {
      this.gridOptions.api?.stopEditing();
    }
  }

  cloneSite() {
    const sData = this.siteQuery.getAll();
    this.clonedSite = this.calculatedGridData(JSON.parse(JSON.stringify(sData)));
  }

  roundToNumber(num: number | string, pow: number = 3) {
    const newNum = Number(num);
    // eslint-disable-next-line no-restricted-properties
    return Math.round(newNum * Math.pow(10, pow)) / Math.pow(10, pow);
  }

  ngOnDestroy() {
    if (this.fgListener) {
      this.fgListener.unsubscribe();
    }
  }

  listenForChanges() {
    if (this.fgListener) {
      this.fgListener.unsubscribe();
    }
    const fa = this.fg.get('settings') as FormArray | undefined;
    if (fa) {
      this.fgListener = merge(...fa.controls.map((x) => x.valueChanges)).subscribe((value) => {
        if (value.id) {
          this.editedRows.add(value.id);
        }
      });
    }
  }

  createSiteSettingFB({
    id,
    cohort_name,
    number_of_sites,
    first_initiated_date,
    discontinued_date,
    ramp_time_in_weeks,
    target_patient_count,
  }: Partial<DriverSiteSettingModel>) {
    return this.fb.group({
      id,
      cohort_name,
      number_of_sites,
      first_initiated_date,
      discontinued_date,
      ramp_time_in_weeks,
      target_patient_count,
    });
  }

  onAddCohort() {
    const fa = this.fg.get('settings') as FormArray;
    fa.push(this.createSiteSettingFB({}));
  }

  onRemoveCohort(i: number, set: FormGroup) {
    const { id } = set.value;
    if (id) {
      this.removedRows.push(id);
    }

    const fa = this.fg.get('settings') as FormArray;
    fa.removeAt(i);
  }

  async processSitesCreatedFromScratch() {
    this.loading$.next(true);
    const thingsToUpdate: TotalDriverSiteDistribution[] = [];
    this.gridApi.forEachNode((node) => {
      thingsToUpdate.push(node.data);
    });
    const data = thingsToUpdate.map((row) => ({
      month: Utils.dateFormatter(row.distribution_month, {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      }),
      sites_activated: row.sites_activated,
      sites_closed: row.sites_closed,
    }));
    const key = `${this.getFilePath(
      this.selected !== '' ? this.currentSiteGroupId.toString() : 'Site-Curve'
    )}site-curve-from-scratch.csv`;
    const blob = new Blob([Utils.objectToCsv(data)], { type: 'text/csv' });
    const fileSuccess = await this.apiService.uploadFile(key, new File([blob], key));

    if (fileSuccess) {
      const { success, errors } = await this.gqlService
        .processEvent$({
          type: EventType.SITE_DRIVER_TEMPLATE_UPLOADED,
          entity_type: EntityType.SITE,
          entity_id: this.selected !== '' ? this.currentSiteGroupId.toString() : '',
          bucket_key: `public/${key}`,
          payload: JSON.stringify({
            should_show_on_document_library: false,
          }),
        })
        .toPromise();

      if (success) {
        // allows time for SITE_DRIVER_TEMPLATE_UPLOADED event to complete
        await new Promise((resolve) => setTimeout(resolve, 5000));
        await this.siteCurveService.get().pipe(take(1)).toPromise();
        await this.siteGroupService.get().pipe(take(1)).toPromise();
        this.isFromScratch = false;
        this.setSiteGroups();
        if (this.editMode$.getValue()) {
          this.gridApi.stopEditing();
          this.editMode$.next(false);
        }
        this.editCell = false;
        this.gridOptions.api?.refreshCells({ force: true });
        this.overlayService.success();
      } else {
        this.overlayService.error(errors);
      }
    } else {
      this.overlayService.error('There was an error uploading the file. Please try again');
    }
    this.loading$.next(false);
  }

  autoSize() {
    this.gridOptions.api?.sizeColumnsToFit();
  }

  onEditClick(): void {
    this.initialValue = [];
    this.gridData$.value.forEach((data) => {
      this.initialValue.push({ ...data });
    });
    this.editMode$.next(true);
    this.editGrid();
  }

  private blendedCurveModalParams(item: DriverSiteGroup): BlendedCurveModalDataModel {
    return {
      availableGroups: this.siteCurveGroups
        .filter((group) => !group.is_blended)
        .filter(
          (value, index, self) =>
            index === self.findIndex((group) => group.driver_setting_id === value.driver_setting_id)
        ),
      text: {
        title: 'Site Curve',
        subTitle: 'Select Site Groups',
      },
      blendedCurve: item,
    };
  }

  private unselectCurveAndResetView(): void {
    if (this.selected) {
      this.selected = '';
      this.populateGridDataWithTimeline();
    } else {
      this.isFromScratch = true;
      this.populateGridDataWithTimeline();
    }
  }
}
