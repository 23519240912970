<div class="flex items-center compare-dropdown" *ngVar="loading$ | async as loading">
  <span class="mr-1 text-sm">Compare to</span>
  <ng-select
    *ngVar="$any(snapshots$ | async) as snapshots"
    class="w-60 text-sm"
    placeholder="Select a budget snapshot"
    [clearable]="true"
    [searchable]="false"
    bindValue="value"
    [loading]="loading"
    [formControl]="formControl"
    [items]="snapshots"
  >
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <div>
        <div class="flex justify-between">
          <p class="w-40 overflow-hidden overflow-ellipsis" [auxTooltip]="item.label">
            {{ item.label }}
          </p>
          <div class="flex items-center" *ngIf="item.value">
            <span
              inlineSVG="edit.svg"
              class="text-aux-blue mr-1"
              [setSVGAttributes]="{ class: 'w-3 h-3' }"
              (click)="editSnapshot($event, item.label)"
            ></span>
            <span
              inlineSVG="trash.svg"
              class="text-aux-red-dark"
              [setSVGAttributes]="{ class: 'w-4 h-4' }"
              (click)="deleteSnapshot($event, item.label)"
            ></span>
          </div>
        </div>
        <p class="text-aux-gray-darkest">{{ item.create_date | date: 'YYYY-MM-dd' }}</p>
      </div>
      <div
        class="border border-aux-gray-dark w-full mt-3"
        *ngIf="index + 1 !== snapshots.length"
      ></div>
    </ng-template>
  </ng-select>
</div>
