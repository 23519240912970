import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { GqlService } from '@services/gql.service';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { AllowedRolesPermissions } from '@directives/authorize.directive';
import { sha256 as Sha256 } from 'sha.js';
import { from, Observable, Subject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { AuthState, AuthStore } from './auth.store';
import { LoggedInUser } from './LoggedInUser';

export type UserChallenge =
  | 'CUSTOM_CHALLENGE'
  | 'NEW_PASSWORD_REQUIRED'
  | 'SMS_MFA'
  | 'SOFTWARE_TOKEN_MFA'
  | 'MFA_SETUP'
  | undefined;

export interface ISignInResult extends CognitoUser {
  challengeName: UserChallenge;
  challengeParam: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  latestUserParams: undefined | { email: string; password: string };

  private tokenLookup = new Map<string, LoggedInUser>();

  private tokenPromiseLookup = new Map<string, Promise<LoggedInUser>>();

  updatePermissions$ = new Subject();

  constructor(
    private authStore: AuthStore,
    private gqlService: GqlService,
    private ld: LaunchDarklyService
  ) {}

  async isAuthenticated() {
    let isAuthenticated = true;

    try {
      await Auth.currentAuthenticatedUser();
    } catch (err) {
      isAuthenticated = false;
    }

    return isAuthenticated;
  }

  // eslint-disable-next-line consistent-return
  async signIn(email: string, password: string): Promise<ISignInResult> {
    return Auth.signIn(email, password);
  }

  async signOut() {
    try {
      await Auth.signOut();
    } catch (e) {
      console.log(e);
    }

    this.authStore.reset();
    this.tokenLookup.clear();
    this.tokenPromiseLookup.clear();
  }

  async confirmSignUp({ username, code }: { username: string; code: string }) {
    let result = true;
    try {
      const resp = await Auth.confirmSignUp(username, code);
      console.log('resp', resp);
    } catch (e) {
      result = false;
    }
    return result;
  }

  async signUp({
    email,
    password,
    given_name,
    family_name,
  }: {
    email: string;
    password: string;
    given_name: string;
    family_name: string;
  }) {
    try {
      return await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name,
          family_name,
        },
      });
    } catch (e) {
      if (e.message) {
        return e.message as string;
      }
      console.log(e);
    }
    return undefined;
  }

  async setUserAttributes() {
    const user = await Auth.currentAuthenticatedUser();
    const userAttributes = await Auth.userAttributes(user);
    const session = await Auth.currentSession();
    const payload = session.getIdToken().decodePayload();

    const getAttribute = (str: string) => {
      const attribute = userAttributes.find((i) => i.getName() === str);
      if (attribute) {
        return attribute.getValue();
      }
      return '';
    };

    const attributes: AuthState = {
      sub: getAttribute('sub'),
      given_name: getAttribute('given_name'),
      family_name: getAttribute('family_name'),
      email: getAttribute('email'),
      is_admin: (payload['cognito:groups'] || []).includes('Admin'),
      trial_id: payload.trial_id,
    };

    // console.log('attributes', attributes);

    this.authStore.update(attributes);

    await this.ld.changeUser({
      key: attributes.sub,
      firstName: attributes.given_name,
      lastName: attributes.family_name,
      email: attributes.email,
      custom: {
        clientName: environment.launchDarkly.clientName,
      },
    });

    try {
      const loggedInUser = await this.getLoggedInUser();

      if (loggedInUser) {
        let role = loggedInUser.getRoles().indexOf('Admin') > -1 ? 'Admin' : 'Read Only';
        role = attributes.email.indexOf('@auxili.us') > -1 ? 'Auxilius Admin' : role;
        (<any>window)?.pendo?.initialize({
          visitor: {
            id: attributes.sub, // 'VISITOR-UNIQUE-ID', // Required if user is logged in
            email: attributes.email, // Recommended if using Pendo Feedback, or NPS Email
            full_name: `${attributes.given_name} ${attributes.family_name}`,
            role, // Optional
            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },
          account: {
            id: environment.analytics.Pendo.accountId, // 'ACCOUNT-UNIQUE-ID', // Required if using Pendo Feedback
            // name:         // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional
            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
          },
        });
      }
    } catch (e) {
      console.error(e);
    }

    return attributes;
  }

  async completeNewPassword(
    user: ISignInResult,
    userParams: { password: string; firstName: string; lastName: string }
  ) {
    await Auth.completeNewPassword(user, userParams.password, {
      given_name: userParams.firstName,
      family_name: userParams.lastName,
    });
  }

  async forgotPassword(username: string) {
    try {
      await Auth.forgotPassword(username);
      return true;
    } catch (err) {
      return err.message as string;
    }
  }

  async resetPassword(username: string, verificationCode: string, newPassword: string) {
    try {
      await Auth.forgotPasswordSubmit(username, verificationCode, newPassword);
      return true;
    } catch (err) {
      return err.message as string;
    }
  }

  async changePassword(currentPassword: string, newPassword: string) {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.changePassword(user, currentPassword, newPassword);
  }

  async updateUser(attributes: object) {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, attributes);
  }

  async getUserSession() {
    try {
      return await Auth.currentSession();
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  private getTokenPromise(k: string): Promise<LoggedInUser> {
    // this internal/helper method is for preventing concurrent requests to the backend
    // while the information about the same token is still being retrieved
    const promiseInProgress = this.tokenPromiseLookup.get(k);
    if (promiseInProgress) {
      return promiseInProgress;
    }
    // eslint-disable-next-line no-async-promise-executor
    const result = new Promise<LoggedInUser>(async (resolve, reject) => {
      try {
        const response = await this.gqlService.loggedInUser$().toPromise();
        if (response.success) {
          resolve(new LoggedInUser(response.data));
        } else {
          reject(new Error(JSON.stringify(response.errors)));
        }
      } catch (e) {
        reject(e);
      }
    });
    this.tokenPromiseLookup.set(k, result);
    return result;
  }

  async getLoggedInUser() {
    try {
      const session = await this.getUserSession();
      const jwtToken = session?.getIdToken()?.getJwtToken();
      if (jwtToken) {
        const k = new Sha256().update(jwtToken).digest('hex');
        let loggedInUser = this.tokenLookup.get(k);
        if (!loggedInUser) {
          try {
            loggedInUser = await this.getTokenPromise(k);
          } finally {
            if (loggedInUser) {
              this.tokenLookup.set(k, loggedInUser);
            }
            this.tokenPromiseLookup.delete(k);
          }
        }
        return loggedInUser;
      }
    } catch (err) {
      console.log('Failed to get the logged in user', err);
    }
    return null;
  }

  isAuthorizedSync(user: LoggedInUser, allowedRolesPermissions: AllowedRolesPermissions) {
    if (user.IsSysAdmin()) {
      return true;
    }
    if (allowedRolesPermissions?.sysAdminsOnly) {
      return false;
    }
    const allowedRoles: Array<string> = [];
    if (Array.isArray(allowedRolesPermissions?.roles)) {
      allowedRolesPermissions.roles.forEach((r) => {
        if (r) {
          allowedRoles.push(r.toUpperCase());
        }
      });
    }
    if (user.hasRole(...allowedRoles)) {
      return true;
    }
    const allowedPermissions: Array<string> = [];
    if (Array.isArray(allowedRolesPermissions?.permissions)) {
      allowedRolesPermissions.permissions.forEach((p) => {
        if (p) {
          allowedPermissions.push(p.toUpperCase());
        }
      });
    }
    return user.hasPermission(...allowedPermissions);
  }

  isAuthorized$(allowedRolesPermissions: AllowedRolesPermissions): Observable<boolean> {
    return this.updatePermissions$.pipe(
      startWith(null),
      switchMap(() => {
        return from(this.getLoggedInUser()).pipe(
          map((loggedInUser) => {
            if (!loggedInUser) {
              return false;
            }
            return this.isAuthorizedSync(loggedInUser, allowedRolesPermissions);
          })
        );
      })
    );
  }
}
