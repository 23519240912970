export class FormControlConstants {
  static readonly PLACEHOLDER = {
    DATE: 'mm/dd/yyyy',
    SELECT: 'Select',
  };

  static readonly MIN_VALUE = {
    DATE: '2000-01-01',
  };

  static readonly VALIDATION_MESSAGE = {
    DATE: 'Dates prior to 01/01/2000 are not accepted.',
    EMAIL: 'Invalid email',
    MIN_LENGTH: 'Minimum {{minlength.requiredLength}} character required.',
    REQUIRED: '{{label}} is required',
    EMPTY_VENDORS: 'Please add vendor names to the field above',
    DUPLICATED_VENDORS: 'Duplicate vendor name not allowed',
    DUPLICATED_MILESTONE_NAMES: `Duplicate milestone name not allowed`,
  };
}
