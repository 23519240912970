import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PatientTrackerStore, PatientTrackerState } from './patient-tracker.store';

@Injectable({ providedIn: 'root' })
export class PatientTrackerQuery extends QueryEntity<PatientTrackerState> {
  constructor(protected store: PatientTrackerStore) {
    super(store);
  }
}
