import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { User } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface TrialUserState
  extends EntityState<
    Omit<RequireSome<Partial<User>, 'sub'>, 'permissions' | 'organization'> & {
      permissions: { [k: string]: 'E' | 'U' };
      organization: {
        id: string;
        name: string;
      };
    },
    string
  > {}

export type UserModel = getEntityType<TrialUserState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'trial-user', idKey: 'sub' })
export class TrialUserStore extends EntityStore<TrialUserState> {
  constructor() {
    super({});
  }
}
