import { Component, ChangeDetectionStrategy, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { OverlayService } from '@services/overlay.service';
import { TabGroupConfig } from '@components/tab-group/tab-group.component';

import { BehaviorSubject, combineLatest } from 'rxjs';
import { AuthQuery } from '@models/auth/auth.query';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationStore } from '@models/organization/organization.store';
import { AppService } from '@services/app.service';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { listUserNamesWithEmailQuery } from '@services/gql.service';
import { ROUTING_PATH } from '../../app-routing-path.const';
import { ChangeOrderService } from './tabs/change-order/state/change-order.service';
import { ChangeOrderSharedService } from './tabs/change-order/state/change-order-shared.service';

@UntilDestroy()
@Component({
  templateUrl: './budget-page.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetPageComponent {
  tabs: TabGroupConfig[] = [
    {
      label: 'Budget',
      route: ROUTING_PATH.BUDGET.INDEX,
    },
    {
      label: 'Budget (Legacy)',
      show: this.launchDarklyService.select$((flags) => flags.tab_budget_budget_enhanced),
      route: ROUTING_PATH.BUDGET.ENHANCED,
    },
    {
      label: 'Budget Library',
      show: this.launchDarklyService.select$((flags) => flags.tab_budget_budget_library),
      route: ROUTING_PATH.BUDGET.LIBRARY,
    },
    { label: 'Change Log', route: ROUTING_PATH.BUDGET.CHANGE_LOG },
    {
      label: 'Change Orders',
      route: ROUTING_PATH.BUDGET.CHANGE_ORDER,
      show: this.launchDarklyService.select$((flags) => flags.tab_change_order_review),
    },
    { label: 'Compare', route: ROUTING_PATH.BUDGET.COMPARE },
  ];

  filterViewContainer = new BehaviorSubject<TemplateRef<any> | null>(null);

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private overlayService: OverlayService,
    public cdr: ChangeDetectorRef,
    public authQuery: AuthQuery,
    private changeOrderService: ChangeOrderService,
    private organizationService: OrganizationService,
    private organizationQuery: OrganizationQuery,
    private organizationStore: OrganizationStore,
    private appService: AppService,
    private trialUserService: TrialUserService,
    private changeOrderSharedService: ChangeOrderSharedService
  ) {
    this.changeOrderService.get().pipe(untilDestroyed(this)).subscribe();

    this.organizationService.getListWithTotalBudgetAmount().pipe(untilDestroyed(this)).subscribe();

    combineLatest([this.trialUserService.listUserNamesWithEmail()])
      .pipe(untilDestroyed(this))
      .subscribe(([_users]) => {
        _users?.data?.forEach((user: listUserNamesWithEmailQuery) => {
          this.changeOrderSharedService.users.set(user.sub, user);
        });
      });
  }
}
