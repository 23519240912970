<div class="text-lg font-bold mb-4">Discounts</div>
<ag-grid-angular
  style="width: 502px; height: 350px"
  class="ag-theme-alpine mb-4 w-full tabular-nums forecast-discount-dialog-table"
  [rowData]="gridData$ | async"
  [gridOptions]="gridOptions"
  (rowSelected)="onRowSelected($event)"
  (firstDataRendered)="onDataRendered($event)"
  [enableBrowserTooltips]="true"
>
</ag-grid-angular>

<div class="flex justify-between items-center">
  <button
    class="text-sm font-normal aux-link focus:outline-none"
    (click)="ref.close()"
    type="button"
  >
    Cancel
  </button>

  <button class="text-sm btn btn--blue" (click)="onSave()">
    <span>Save</span>
  </button>
</div>
