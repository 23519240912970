<ng-container *ngIf="{ activeIndex: activeTabIndex$ | async } as obj">
    <div class="flex justify-between items-center mb-8">
      <h1 class="text-2xl text-aux-gray-darkest font-bold h-11 flex items-center">
        Design System
      </h1>
      <div class="flex space-x-2">
        <ng-container [ngTemplateOutlet]="rightSideContainer | async"></ng-container>
      </div>
    </div>
    <div>
      <div class="mb-8">
        <aux-tab-group [tabs]="tabs"></aux-tab-group>
      </div>

      <router-outlet></router-outlet>

    </div>
  </ng-container>
  