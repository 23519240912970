import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { BudgetType } from '@services/gql.service';
import { AuthService } from '@models/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationService } from '@models/organization/organization.service';
import { TabGroupConfig } from '@components/tab-group/tab-group.component';

import { SettingsService } from '../settings/settings.service';
import { ROUTING_PATH } from '../../app-routing-path.const';

@Component({
  selector: 'aux-patients-page',
  templateUrl: './patients-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class PatientsPageComponent {
  rightSideContainer = new BehaviorSubject<TemplateRef<any> | null>(null);

  tabs: TabGroupConfig[] = [
    {
      label: 'Patient Tracker',
      route: `/${ROUTING_PATH.PATIENTS.INDEX}/${ROUTING_PATH.PATIENTS.PATIENT_TRACKER}`,
      show: this.launchDarklyService.select$((flags) => flags.nav_patient),
    },
    {
      label: 'Patient Budget',
      show: this.launchDarklyService.select$((flags) => flags.tab_trial_settings_patient_protocol),
      route: `/${ROUTING_PATH.PATIENTS.INDEX}/${ROUTING_PATH.PATIENTS.PATIENT_BUDGET.INDEX}`,
    },
    {
      label: 'Patient Budget Entry',
      show: from(this.authService.isAuthorized$({ sysAdminsOnly: true })),
      route: `/${ROUTING_PATH.PATIENTS.INDEX}/${ROUTING_PATH.PATIENTS.PATIENT_BUDGET_ENTRY}`,
    },
  ];

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private authService: AuthService,
    private vendorsService: OrganizationService,
    public service: SettingsService
  ) {
    this.vendorsService
      .getListWithTotalBudgetAmount(BudgetType.BUDGET_PRIMARY)
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
