import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { EntityType, EventType, GqlService, UserAuditLog } from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { AuditHistoryStore } from './audit-history.store';
import { MainQuery } from '../../../layouts/main-layout/state/main.query';

@Injectable({ providedIn: 'root' })
export class AuditHistoryService {
  constructor(
    private auditHistoryStore: AuditHistoryStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService
  ) {}

  async triggerFetchAuditLog() {
    await this.gqlService
      .processEvent$({
        type: EventType.USER_AUDIT_LOG,
        entity_type: EntityType.TRIAL,
        entity_id: '',
        payload: JSON.stringify({
          triggered_by_user: true,
        }),
      })
      .toPromise();
    this.overlayService.success('Retrieving audit history...');
  }

  add(auditHistory: UserAuditLog) {
    this.auditHistoryStore.add(auditHistory);
  }

  update(id: string, auditHistory: UserAuditLog) {
    this.auditHistoryStore.update(id, auditHistory);
  }

  remove(id: ID) {
    this.auditHistoryStore.remove(id);
  }
}
