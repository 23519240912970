import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AuthQuery } from '@models/auth/auth.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  EventType,
  GqlService,
  listNotificationPreferencesQuery,
  updateNotificationPreferenceMutation,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash-es';

@UntilDestroy()
@Component({
  selector: 'aux-account-notifications',
  templateUrl: './account-notifications.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountNotificationsComponent {
  notifications: {
    [key: string]: { [key: string]: { event_type?: EventType; enabled: boolean } };
  } = {
    forecast: {
      forecastUpdated: { enabled: false },
    },
    periodClose: {
      monthCloseAvailable: { enabled: false },
      monthClosed: { enabled: false },
      quarterClosed: { enabled: false },
      uploadVendorEstimatesReminder: { enabled: false },
    },
    invoices: {
      uploadInvoicesReminder: { enabled: false },
      pendingApproval: { enabled: false },
    },
    changeOrder: {
      pendingReview: { enabled: false },
      pendingApproval: { enabled: false },
      approved: { enabled: false },
      declined: { enabled: false },
      backToPendingReview: { enabled: false },
      deleted: { enabled: false },
    },
    support: {
      invoicesPendingReview: { enabled: false },
      invoiceCategorizationReminder: { enabled: false },
      sendEmailVendorDocumentUploaded: { enabled: false },
      sendEmailDocumentsUploaded: { enabled: false },
      vendorEstimateUploaded: { enabled: false },
    },
  };

  edits = new Set<EventType>();

  saveCheck = new BehaviorSubject(false);

  initialValues$ = new BehaviorSubject(this.notifications);

  constructor(
    private gqlService: GqlService,
    private cdr: ChangeDetectorRef,
    private overlayService: OverlayService,
    public authQuery: AuthQuery
  ) {
    this.gqlService
      .listNotificationPreferences$()
      .pipe(untilDestroyed(this))
      .subscribe(({ data }) => {
        this.notificationMapper(data);
        this.initialValues$.next(cloneDeep(this.notifications));
      });
  }

  isFormEqual() {
    return isEqual(this.notifications, this.initialValues$.getValue());
  }

  notificationMapper(data: listNotificationPreferencesQuery[] | null) {
    (data || []).forEach(({ notification, enabled }) => {
      const event_type = notification.event_type.replace('EVENT_TYPE_', '') as EventType;
      switch (notification.event_type_group) {
        case 'EVENT_TYPE_GROUP_DOCUMENT':
          if (event_type === 'DOCUMENT_UPLOADED_NOTIFICATION') {
            this.notifications.support.sendEmailDocumentsUploaded.event_type =
              EventType[event_type];
            this.notifications.support.sendEmailDocumentsUploaded.enabled = enabled;
          }
          break;
        case 'EVENT_TYPE_GROUP_BUDGET':
          if (event_type === 'MONTH_AVAILABLE_TO_CLOSE') {
            this.notifications.periodClose.monthCloseAvailable.event_type = EventType[event_type];
            this.notifications.periodClose.monthCloseAvailable.enabled = enabled;
          } else if (event_type === 'TRIAL_MONTH_CLOSED') {
            this.notifications.periodClose.monthClosed.event_type = EventType[event_type];
            this.notifications.periodClose.monthClosed.enabled = enabled;
          } else if (event_type === 'VENDOR_ESTIMATE_SUPPORTING_DOCUMENT_UPLOADED') {
            this.notifications.support.vendorEstimateUploaded.event_type = EventType[event_type];
            this.notifications.support.vendorEstimateUploaded.enabled = enabled;
          } else if (event_type === 'QUARTER_CLOSED') {
            this.notifications.periodClose.quarterClosed.event_type = EventType[event_type];
            this.notifications.periodClose.quarterClosed.enabled = enabled;
          } else if (event_type === 'UPLOAD_VENDOR_ESTIMATE_REMINDER') {
            this.notifications.periodClose.uploadVendorEstimatesReminder.event_type =
              EventType[event_type];
            this.notifications.periodClose.uploadVendorEstimatesReminder.enabled = enabled;
          } else if (event_type === 'CATEGORIZE_INVOICES_REMINDER') {
            this.notifications.support.invoiceCategorizationReminder.event_type =
              EventType[event_type];
            this.notifications.support.invoiceCategorizationReminder.enabled = enabled;
          }
          break;
        case 'EVENT_TYPE_GROUP_INVOICE':
          if (event_type === 'INVOICE_PENDING_APPROVAL') {
            this.notifications.invoices.pendingApproval.event_type = EventType[event_type];
            this.notifications.invoices.pendingApproval.enabled = enabled;
          } else if (event_type === 'UPLOAD_INVOICE_REMINDER') {
            this.notifications.invoices.uploadInvoicesReminder.event_type = EventType[event_type];
            this.notifications.invoices.uploadInvoicesReminder.enabled = enabled;
          } else if (event_type === 'INVOICE_PENDING_REVIEW') {
            this.notifications.support.invoicesPendingReview.event_type = EventType[event_type];
            this.notifications.support.invoicesPendingReview.enabled = enabled;
          }
          break;
        case 'EVENT_TYPE_GROUP_CHANGE_ORDER':
          if (event_type === 'CHANGE_ORDER_APPROVED') {
            this.notifications.changeOrder.approved.event_type = EventType[event_type];
            this.notifications.changeOrder.approved.enabled = enabled;
          } else if (event_type === 'CHANGE_ORDER_DECLINED') {
            this.notifications.changeOrder.declined.event_type = EventType[event_type];
            this.notifications.changeOrder.declined.enabled = enabled;
          } else if (event_type === 'CHANGE_ORDER_DELETED') {
            this.notifications.changeOrder.deleted.event_type = EventType[event_type];
            this.notifications.changeOrder.deleted.enabled = enabled;
          } else if (event_type === 'CHANGE_ORDER_PENDING_APPROVAL') {
            this.notifications.changeOrder.pendingApproval.event_type = EventType[event_type];
            this.notifications.changeOrder.pendingApproval.enabled = enabled;
          } else if (
            event_type === 'CHANGE_ORDER_PENDING_REVIEW' ||
            event_type === 'CHANGE_ORDER_BACK_TO_PENDING_REVIEW'
          ) {
            this.notifications.changeOrder.pendingReview.event_type =
              EventType.CHANGE_ORDER_PENDING_REVIEW;
            this.notifications.changeOrder.pendingReview.enabled = enabled;
            this.notifications.changeOrder.backToPendingReview.event_type =
              EventType.CHANGE_ORDER_BACK_TO_PENDING_REVIEW;
            this.notifications.changeOrder.backToPendingReview.enabled = enabled;
          }
          break;
        case 'EVENT_TYPE_GROUP_VENDOR':
          if (event_type === 'VENDOR_DOCUMENT_UPLOADED_NOTIFICATION') {
            this.notifications.support.sendEmailVendorDocumentUploaded.event_type =
              EventType[event_type];
            this.notifications.support.sendEmailVendorDocumentUploaded.enabled = enabled;
          }
          break;
        default:
          break;
      }
    });
    this.cdr.detectChanges();
  }

  isGroupChecked(obj: Object) {
    return Object.values(obj).every(({ enabled }) => enabled);
  }

  isGroupIndeterminate(obj: Object) {
    return (
      Object.values(obj).some(({ enabled }) => enabled) &&
      Object.values(obj).some(({ enabled }) => !enabled)
    );
  }

  onChange(event_types: (EventType | undefined)[]) {
    event_types?.forEach((event_type) => {
      if (!event_type) {
        return;
      }
      this.edits.add(event_type);

      setTimeout(() => {
        this.saveCheck.next(!this.isFormEqual());
      }, 0);
    });
  }

  onGroupChange(group: string) {
    const notificationGroup = this.notifications[group];
    const newValue = !this.isGroupChecked(notificationGroup);
    Object.values(notificationGroup).forEach((notification) => {
      if (notification.event_type) {
        Object.assign(notification, { enabled: newValue });
        this.onChange([notification.event_type]);
      }
    });
    this.cdr.detectChanges();
  }

  saveChanges = async () => {
    const promises: Promise<{
      success: boolean;
      data: updateNotificationPreferenceMutation | null;
      errors: string[];
    } | null>[] = [];
    const flat: {
      event_type?: EventType;
      enabled: boolean;
    }[] = Object.values(this.notifications).reduce(
      (acc: any[], notificationGroup) => acc.concat(Object.values(notificationGroup)),
      []
    );

    for (const event_type of this.edits) {
      const checkbox = flat.find((el) => event_type === el.event_type);
      if (checkbox) {
        promises.push(
          this.gqlService
            .updateNotificationPreference$({ event_type, enabled: checkbox.enabled })
            .toPromise()
        );
      }
    }
    const responses = await Promise.all(promises);
    if (responses.every((r) => r?.success)) {
      this.saveCheck.next(false);
      this.overlayService.success('Changes successfully saved!');
      this.edits.clear();
      this.initialValues$.next(cloneDeep(this.notifications));
    } else {
      this.overlayService.error('Something went wrong');
    }
  };
}
