import { Component, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabGroupConfig } from '@components/tab-group/tab-group.component';
import { map } from 'rxjs/operators';
import { AppService } from '@services/app.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { ROUTING_PATH } from '../../app-routing-path.const';

@Component({
  selector: 'aux-vendor-payments-page',
  template: `
    <div class="flex justify-between items-center mb-8" *ngIf="showTabs$ | async">
      <h1 class="text-2xl text-aux-gray-darkest font-bold h-11 flex items-center">
        Vendor Payments
      </h1>
      <div class="flex space-x-2">
        <ng-container [ngTemplateOutlet]="rightSideContainer | async"></ng-container>
      </div>
    </div>

    <div>
      <div class="mb-4" *ngIf="showTabs$ | async">
        <aux-tab-group [tabs]="tabs"></aux-tab-group>
      </div>

      <router-outlet></router-outlet>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorPaymentsPageComponent {
  tabs: TabGroupConfig[] = [
    { label: 'Invoices', route: ROUTING_PATH.VENDOR_PAYMENTS.INVOICES },
    { label: 'Purchase Orders', route: ROUTING_PATH.VENDOR_PAYMENTS.PURCHASE_ORDERS },
    { label: 'Vendors', route: ROUTING_PATH.VENDOR_PAYMENTS.VENDORS },
    {
      label: 'Payment Milestones',
      route: ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_MILESTONES,
    },
    {
      label: 'Payment Schedule',
      show: this.launchDarklyService.select$((flags) => flags.tab_budget_payment_schedule),
      route: ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_SCHEDULE,
    },
  ];

  showTabs$ = this.appService.paramMap$.pipe(map((p) => !p?.has('id')));

  constructor(private appService: AppService, private launchDarklyService: LaunchDarklyService) {}

  rightSideContainer = new BehaviorSubject<TemplateRef<any> | null>(null);
}
