import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import {
  GqlService,
  listUserNamesWithEmailQuery,
  listWorkflowDetailsQuery,
  WorkflowDetailType,
} from '@services/gql.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from '../../../../../../layouts/main-layout/state/main.query';
import { Workflow, WorkflowModel, WorkflowProperty, WorkflowStore } from './workflow.store';

@Injectable({ providedIn: 'root' })
export class WorkflowService {
  listUsers = new BehaviorSubject<listUserNamesWithEmailQuery[]>([]);

  trialMonthClose$ = new BehaviorSubject<string>('');

  constructor(
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private workflowStore: WorkflowStore,
    private userService: TrialUserService,
    private overlayService: OverlayService
  ) {}

  getFullNameAuthor(userId: string, users: listUserNamesWithEmailQuery[], isAdminUser: boolean) {
    const user = users.find(({ sub }) => sub === userId);

    if (!user) {
      return '';
    }

    return user.email.includes('@auxili.us') && !isAdminUser
      ? 'Auxilius Expert'
      : `${user.given_name} ${user.family_name}`;
  }

  private transformEntities(
    workflowList: listWorkflowDetailsQuery[],
    userList: listUserNamesWithEmailQuery[],
    isAdminUser: boolean
  ): Workflow[] {
    return workflowList.map((workflow) => {
      return {
        ...workflow,
        properties: JSON.parse(workflow.properties)?.properties as WorkflowProperty,
        updatedAuthorFullName: this.getFullNameAuthor(workflow.updated_by, userList, isAdminUser),
      };
    });
  }

  getWorkflowList(isAdminUser: boolean, workflowDate?: string) {
    return combineLatest([
      this.mainQuery.select('trialKey'),
      this.gqlService.getTrialInformation$(),
    ]).pipe(
      switchMap(([trialKey, { data: listTrials, errors: listTrialsErrors }]) => {
        this.workflowStore.setLoading(true);

        let workflowCurrentTrialDate = '';

        if (listTrials) {
          const trialMonthCloseDate = listTrials.length ? listTrials[0].trial_month_close : '';
          this.trialMonthClose$.next(trialMonthCloseDate);

          workflowCurrentTrialDate = dayjs(trialMonthCloseDate).format('MMM-YYYY').toUpperCase();
        }

        return combineLatest([
          this.gqlService.listWorkflowDetails$({
            entity_id: trialKey,
            type: WorkflowDetailType.WF_MONTH_CLOSE_LOCK,
            properties: JSON.stringify({
              month: workflowDate || workflowCurrentTrialDate,
            }),
          }),
          this.userService.listUserNamesWithEmail(),
        ]).pipe(
          tap(
            ([
              { data, errors: listWorkflowErrors },
              { data: userList, errors: userListErrors },
            ]) => {
              if (data && userList && listTrials) {
                this.workflowStore.set(
                  this.transformEntities(
                    data,
                    userList as listUserNamesWithEmailQuery[],
                    isAdminUser
                  )
                );

                this.listUsers.next(userList);
              }

              const errors = listWorkflowErrors || userListErrors || listTrialsErrors;
              if (errors) {
                this.overlayService.error(errors);
              }

              this.workflowStore.setLoading(false);
            }
          )
        );
      })
    );
  }

  getWorkflowListFromStore(
    isAdminUser: boolean
  ): Observable<GraphqlResponse<listWorkflowDetailsQuery[] | Workflow[]>> {
    const workflowData = Object.values(this.workflowStore.getValue().entities || {});
    const isStoreEmpty = !!workflowData.length;

    if (!isStoreEmpty) {
      return this.getWorkflowList(isAdminUser).pipe(
        map(([workflowList]) => {
          return workflowList;
        })
      );
    }

    return of({ success: true, errors: [], data: workflowData });
  }

  async updateWorkflowLockStatus(locked: boolean, workflow: WorkflowModel, isAdminUser: boolean) {
    const { success, data } = await this.gqlService
      .updateWorkflowDetail$({
        id: workflow.id,
        properties: JSON.stringify({ properties: { ...workflow.properties, locked } }),
      })
      .toPromise();

    if (success && data) {
      this.workflowStore.update(workflow.id, {
        properties: { ...workflow.properties, locked },
        updatedAuthorFullName: this.getFullNameAuthor(
          data.updated_by,
          this.listUsers.getValue(),
          isAdminUser
        ),
        update_date: data.update_date,
      });
    }
  }
}
