<div
  class="save-changes bg-aux-gray-light border border-aux-gray-dark flex justify-between items-center p-6 py-7 shadow fixed bottom-0 left-0 w-full"
  @upDownAnimation
>
  <span class="text-lg font-bold">You have unsaved changes.</span>
  <button
    class="text-sm font-normal btn btn--success whitespace-nowrap"
    type="button"
    (click)="onSaveChanges()"
  >
    <span inlineSVG="check.svg"></span>
    Save Changes
  </button>
</div>
