import { Injectable } from '@angular/core';
import { QueryEntity, QueryConfig, Order } from '@datorama/akita';
import { BudgetType, EntityType } from '@services/gql.service';
import { OrganizationStore, VendorsState } from './organization.store';

@QueryConfig({
  sortBy: 'name',
  sortByOrder: Order.ASC,
})
@Injectable({ providedIn: 'root' })
export class OrganizationQuery extends QueryEntity<VendorsState> {
  allVendors$ = this.selectAll({
    filterBy: (entity) => entity.organization_type === 'ORGANIZATION_VENDOR',
  });

  allVendorsObj$ = this.selectAll({
    asObject: true,
    filterBy: (entity) => entity.organization_type === 'ORGANIZATION_VENDOR',
  });

  constructor(protected store: OrganizationStore) {
    super(store);
  }

  selectAllVendors() {
    return this.selectAll({
      filterBy: (entity) => entity.organization_type === 'ORGANIZATION_VENDOR',
    });
  }

  getAllVendors() {
    return this.getAll({
      filterBy: (entity) => entity.organization_type === 'ORGANIZATION_VENDOR',
    });
  }

  getBudgetVersion(
    org_id: string,
    budget_type: BudgetType,
    entity_id: string,
    entity_type: EntityType
  ) {
    const bv = this.getEntity(org_id)?.budget_version;

    return bv?.[`${budget_type}-${entity_id}-${entity_type}`];
  }

  getPrimaryBudgetVersion(org_id: string) {
    const bv = this.getEntity(org_id)?.budget_version;

    return bv?.[`${BudgetType.BUDGET_PRIMARY}-${org_id}-${EntityType.ORGANIZATION}`];
  }
}
