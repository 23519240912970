import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';

@Component({
  template: `
    <div class="flex w-96" *ngIf="!!params?.data">
      <div *ngIf="params?.data?.is_connected" class="flex justify-between">
        <div class="flex items-center whitespace-nowrap">
          <div class="w-3 h-3 rounded-full mr-1 bg-aux-green-dark"></div>
          <span class="text-aux-black">Connected</span>
        </div>
        <button
          class="ml-10 w-8 h-8 aux-link cursor-pointer"
          type="button"
          (click)="onLogOutClick()"
          *ngIf="params?.data?.is_oauth"
        >
          Logout
        </button>
      </div>
      <div *ngIf="!params?.data?.is_connected" class="flex justify-between">
        <div class="flex items-center whitespace-nowrap">
          <div class="w-3 h-3 rounded-full mr-1 bg-aux-red-dark"></div>
          <span class="text-aux-black">Not Connected</span>
        </div>
        <button
          class="ml-10 w-8 h-8 aux-link cursor-pointer"
          type="button"
          (click)="onLogInClick()"
          *ngIf="params?.data?.is_oauth"
        >
          Login
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgIntegrationsConnectComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams & {
    logInClickFN: undefined | ((params: { rowNode: RowNode }) => void);
    logOutClickFN: undefined | ((params: { rowNode: RowNode }) => void);
    hideButtons?: boolean;
  };

  refresh(): boolean {
    return false;
  }

  async agInit(
    params: ICellRendererParams & {
      logInClickFN: undefined | (() => void);
      logOutClickFN: undefined | (() => void);
    }
  ) {
    this.params = params;
  }

  onLogInClick() {
    if (this.params.logInClickFN) {
      this.params.logInClickFN({ rowNode: this.params.node });
    }
  }

  onLogOutClick() {
    if (this.params.logOutClickFN) {
      this.params.logOutClickFN({ rowNode: this.params.node });
    }
  }
}
