<p>All icons from the Tabler Icon Set - <a class="aux-link" href="https://github.com/tabler/tabler-icons">https://github.com/tabler/tabler-icons</a></p>
<div class="mt-4" *ngFor="let icon of icons">
   <div class="inline-block mr-2 align-middle" inlineSVG="{{icon}}.svg"></div>
   <div class="inline-block align-middle">{{ icon }}</div>
   <div class="flex items-center mt-1">
      <div class="text-black bg-aux-gray-dark">
         <div class="m-2">1</div>
   </div>

   <div class="pr-2 text-white bg-aux-gray-darkest">
      <div class="m-2">&lt;span inlineSVG="{{icon}}.svg"&gt;&lt;/span&gt;</div>
   </div>    
</div>
