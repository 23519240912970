import { Injectable } from '@angular/core';
import {
  GqlService,
  PageInput,
  PatientProtocolType,
  UpdateSitePaymentScheduleInput,
} from '@services/gql.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { expand, reduce, switchMap, tap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { EMPTY } from 'rxjs';
import {
  PaymentSchedulesModel,
  PaymentSchedulesState,
  PaymentSchedulesStore,
} from './payment-schedules.store';

@Injectable({ providedIn: 'root' })
export class PaymentSchedulesService {
  constructor(
    private paymentSchedulesStore: PaymentSchedulesStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService
  ) {}

  get(patient_protocol_types: PatientProtocolType[] = []) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.paymentSchedulesStore.setLoading(true);
        this.paymentSchedulesStore.remove(() => true);

        const page_input: PageInput = {
          offset: 0,
          limit: 500,
        };
        return this.gqlService
          .fetchTrialSitePaymentSchedules$(patient_protocol_types, page_input)
          .pipe(
            expand(({ success, data }) => {
              if (success && data) {
                if (Number.isInteger(data.next_offset) && data.next_offset >= 0) {
                  page_input.offset = data.next_offset;
                  return this.gqlService.fetchTrialSitePaymentSchedules$(
                    patient_protocol_types,
                    page_input
                  );
                }
              }
              return EMPTY;
            }),
            reduce(
              (acc, curr) => {
                if (acc.data && curr.success && curr.data) {
                  acc.data = acc.data.concat(curr.data.items);
                  return acc;
                }
                return {
                  success: false,
                  data: null,
                  errors: curr.errors,
                };
              },
              {
                success: true,
                data: [] as Array<PaymentSchedulesState> | null,
                errors: [] as string[],
              }
            ),
            tap(({ data, success }) => {
              if (success && data) {
                this.paymentSchedulesStore.set(
                  data.map(({ id, site_id, expense_amount, patient_protocol, note }) => ({
                    id,
                    amount: expense_amount.amount,
                    amount_contract: expense_amount.contract_amount,
                    sps_expense_currency: expense_amount.amount_curr,
                    sps_contract_expense_currency: expense_amount.contract_curr,
                    note,
                    site_id,
                    patient_protocol_id: patient_protocol.id,
                    patient_protocol_type: patient_protocol.patient_protocol_type,
                  }))
                );
              }
              this.paymentSchedulesStore.setLoading(false);
            })
          );
      })
    );
  }

  async getSiteSpecificPaymentSchedules(
    site_id: string,
    patient_protocol_types: PatientProtocolType[]
  ) {
    const { success, data, errors } = await this.gqlService
      .listSitePaymentSchedules$(patient_protocol_types, site_id)
      .toPromise();
    this.paymentSchedulesStore.remove(() => true);

    if (success && data) {
      this.paymentSchedulesStore.add(
        data.map(({ id, expense_amount, patient_protocol, note }) => ({
          id,
          amount: expense_amount.amount,
          site_id,
          note,
          patient_protocol_id: patient_protocol.id,
          patient_protocol_type: patient_protocol.patient_protocol_type,
        }))
      );
    }

    return {
      success,
      data,
      errors,
    };
  }

  add(paymentSchedule: PaymentSchedulesModel) {
    this.paymentSchedulesStore.add(paymentSchedule);
  }

  remove(id: string) {
    this.paymentSchedulesStore.remove(id);
  }

  async update(upsertData: UpdateSitePaymentScheduleInput[], site_id: string) {
    const allErrors: string[][] = [];
    const proms: Promise<any>[] = [];

    for (const mil of upsertData) {
      const { patient_protocol_id, note, amount, id } = mil;
      if (id) {
        proms.push(
          this.gqlService
            .updateSitePaymentSchedule$({
              id,
              patient_protocol_id,
              note,
              amount,
            })
            .pipe(
              tap(({ success, errors, data }) => {
                if (success && data) {
                  this.paymentSchedulesStore.update(data.id, {
                    ...data,
                    amount: data.expense_amount.amount,
                  });
                } else {
                  allErrors.push(errors);
                }
              })
            )
            .toPromise()
        );
      } else {
        const isNumber = (n: any) => {
          // eslint-disable-next-line no-restricted-globals
          return !isNaN(parseFloat(n)) && !isNaN(n - 0);
        };
        if (amount && isNumber(amount)) {
          proms.push(
            this.gqlService
              .createSitePaymentSchedule$({
                site_id,
                note,
                amount: amount || 0,
                patient_protocol_id: patient_protocol_id || '',
              })
              .pipe(
                tap(({ success, errors, data }) => {
                  if (success && data) {
                    const { id: payment_schedule_id, expense_amount, patient_protocol } = data;
                    this.paymentSchedulesStore.add({
                      id: payment_schedule_id,
                      amount: expense_amount.amount,
                      site_id,
                      note,
                      patient_protocol_id: patient_protocol.id,
                      patient_protocol_type: patient_protocol.patient_protocol_type,
                    });
                  } else {
                    allErrors.push(errors);
                  }
                })
              )
              .toPromise()
          );
        }
      }
    }

    await Promise.all(proms);

    if (allErrors.length) {
      this.overlayService.error(...allErrors);
    }
    return !!allErrors.length;
  }
}
