<div class="flex flex-wrap gap-8">
   <div *ngFor="let color of colors" >
      <div>
         <div class="w-48 h-56 border {{ color.bg_color }}"></div>
         <div class="w-48 h-16 border">
            <p class="text-sm ml-2 mt-2">
               @{{ color.name }}
            <span *ngIf="color.css_variable">
               ({{ color.css_variable }})
            </span>
            </p>
            <p class="text-sm ml-2 mt-1">
               {{ color.hex_value }}
            </p>
         </div>
      </div>
   </div>
</div> 
