import Amplify from '@aws-amplify/core';
import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  restApiKey: '60BD3RC7v22s6CCCxTEXE65iPQZBuqpp9rdaAsqx',
  restApiEndpoint: 'https://dx003sqkj3.execute-api.us-east-1.amazonaws.com/matt',
  cognito: {
    identityPoolId: 'us-east-1:62ac558d-49af-4e8b-bd2f-ad84b98ff24d',
    region: 'us-east-1',
    userPoolId: 'us-east-1_JQ5ieMhiZ',
    userPoolWebClientId: '1kj0sdta1mkk2613g4dtlqdgi',
  },
  appSyncApiId: 'h2dajijjwja55nksh6yo3bd5q4',
  appSync: {
    aws_appsync_graphqlEndpoint:
      'https://tn6pgy7cujcupeb2lumnn67jsm.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    graphql_headers: async () => {
      return { Authorization: (await Amplify.Auth.currentSession()).idToken.jwtToken };
    },
  },
  s3: {
    AWSS3: {
      bucket: 'matt-auxilius-documents',
      identityPoolId: 'us-east-1:62ac558d-49af-4e8b-bd2f-ad84b98ff24d',
      region: 'us-east-1',
    },
  },
  launchDarkly: {
    clientId: '62f41b9d67be3b1153482f1a',
    clientName: 'dev',
  },
  analytics: {
    Pendo: {
      accountId: 'matt-local',
    },
  },
};
