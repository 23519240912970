import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  createScriptElementWithSrc(src: string) {
    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('type', 'text/javascript');
    scriptEl.setAttribute('src', src);
    return {
      el: scriptEl,
      load: async () =>
        new Promise((resolve) => {
          // IE
          // @ts-ignore
          if (scriptEl.readyState) {
            // @ts-ignore
            scriptEl.onreadystatechange = () => {
              // @ts-ignore
              if (scriptEl.readyState === 'loaded' || scriptEl.readyState === 'complete') {
                // @ts-ignore
                scriptEl.onreadystatechange = null;
                resolve({ loaded: true });
              }
            };
          } else {
            scriptEl.onload = () => {
              resolve({ loaded: true });
            };
          }
          scriptEl.onerror = () => resolve({ loaded: false });
          document.head.appendChild(scriptEl);
        }),
    };
  }

  async injectJiraWidget() {
    const { el, load } = this.createScriptElementWithSrc(
      'https://jsd-widget.atlassian.com/assets/embed.js'
    );
    el.setAttribute('data-jsd-embedded', '');
    el.setAttribute('data-key', '586ed737-f468-4b5a-b105-abb8c3b5d72d');
    el.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
    await load();

    window.document.dispatchEvent(
      new Event('DOMContentLoaded', {
        bubbles: true,
        cancelable: true,
      })
    );

    setTimeout(() => {
      const iframe = document.getElementById('jsd-widget') as HTMLIFrameElement;
      if (iframe) {
        // @ts-ignore
        import('src/assets/css/jsd-widget.css').then((css) => {
          const doc = iframe.contentDocument;
          if (doc) {
            const styleTag = doc.createElement('style');
            styleTag.setAttribute('type', 'text/css');
            styleTag.innerHTML = css.default;
            doc.head.appendChild(styleTag);
          }
        });
      }
    }, 0);
  }
}
