<ng-container *ngIf="$any(budgetQuery.selectLoading() | async)">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="!$any(budgetQuery.selectLoading() | async)">
  <div class="mb-12 mt-8 grid grid-cols-3 gap-7.5" *ngIf="showAnalyticsSection$ | async">
    <div class="border rounded border-aux-gray-dark h-28 py-3 flex justify-around items-center">
      <div class="flex flex-col justify-center rounded-l px-4 pr-1">
        <div class="font-bold text-aux-black">Actuals</div>
        <div class="flex items-center space-x-4">
          <div class="font-medium text-xs">
            % of Total<br />
            Current $
          </div>
        </div>
      </div>
      <div class="h-full bg-aux-gray-dark" style="width: 1px"></div>
      <div class="flex space-x-2 items-center justify-center rounded-r px-4 tabular-nums">
        <div *ngIf="(wpLoading | async) === false">
          <ng-container *ngIf="budgetQuery.select('work_performed') | async as wp">
            <div class="flex space-x-2 items-center justify-center rounded-r px-4">
              <div class="text-lg font-bold text-aux-gray-darkest">
                {{
                  selectedVendor && selectedVendor.value
                    ? wp[selectedVendor.value]
                      ? wp[selectedVendor.value].wp_percentage
                      : zeroHyphen
                    : wp.ALL
                    ? wp.ALL.wp_percentage
                    : zeroHyphen
                }}
                <br />
                {{
                  selectedVendor && selectedVendor.value
                    ? wp[selectedVendor.value]
                      ? wp[selectedVendor.value].wp_total
                      : zeroHyphen
                    : wp.ALL
                    ? wp.ALL.wp_total
                    : zeroHyphen
                }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="border rounded border-aux-gray-dark h-28 py-3 flex justify-around items-center">
      <div class="flex flex-col justify-center rounded-l px-4 pr-14">
        <div class="font-bold text-aux-black">Invoiced</div>
        <div class="flex items-center space-x-4">
          <div class="font-medium text-xs">
            % of Total<br />
            Current $
          </div>
        </div>
      </div>
      <div class="h-full bg-aux-gray-dark" style="width: 1px"></div>
      <div class="flex space-x-2 items-center justify-center rounded-r px-4">
        <div *ngIf="(invoicesTotalLoading | async) === false">
          <ng-container *ngIf="budgetQuery.select('invoices') | async as invoices">
            <div class="flex space-x-2 items-center justify-center rounded-r px-4 tabular-nums">
              <div class="text-lg font-bold text-aux-gray-darkest">
                {{
                  selectedVendor && selectedVendor.value
                    ? invoices[selectedVendor.value]
                      ? invoices[selectedVendor.value].invoice_percentage
                      : zeroHyphen
                    : invoices.ALL
                    ? invoices.ALL.invoice_percentage
                    : zeroHyphen
                }}
                <br />
                {{
                  selectedVendor && selectedVendor.value
                    ? invoices[selectedVendor.value]
                      ? invoices[selectedVendor.value].invoice_total
                      : zeroHyphen
                    : invoices.ALL
                    ? invoices.ALL.invoice_total
                    : zeroHyphen
                }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="border rounded border-aux-gray-dark h-28 py-3 flex justify-around items-center">
      <div class="flex flex-col justify-center rounded-l px-4 pr-1">
        <div class="font-bold text-aux-black">Pending Change</div>
        <div class="flex items-center space-x-4">
          <div class="font-medium text-xs">
            Change Log + Unsigned<br />
            Change Orders $
          </div>
        </div>
      </div>
      <div class="h-full bg-aux-gray-dark" style="width: 1px"></div>
      <div class="flex space-x-2 items-center justify-center rounded-r px-4">
        <div *ngIf="(pendingChangesLoading | async) === false">
          <ng-container *ngIf="budgetQuery.select('pendingChanges') | async as pendingChanges">
            <div class="flex space-x-2 items-center justify-center rounded-r px-4 tabular-nums">
              <div class="text-lg font-bold text-aux-gray-darkest">
                {{
                  selectedVendor && selectedVendor.value
                    ? pendingChanges[selectedVendor.value]
                      ? pendingChanges[selectedVendor.value]
                      : zeroHyphen
                    : pendingChanges.ALL
                    ? pendingChanges.ALL
                    : zeroHyphen
                }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <ng-template #budgetFilters>
    <div class="flex justify-between items-center space-x-4">
      <div class="flex space-x-4 -mt-3.5">
        <div>
          <div class="text-xs mb-1">Filter By</div>
          <ng-select
            class="w-64"
            [clearable]="false"
            (change)="onVendorSelected($event)"
            [formControl]="selectedVendor"
          >
            <ng-container *ngVar="organizationQuery.allVendors$ | async as vendors">
              <ng-option [value]="''" *ngIf="vendors.length > 1">All</ng-option>
              <ng-option [value]="vendor.id" *ngFor="let vendor of vendors">
                <span [title]="vendor.name">{{ vendor.name }}</span>
              </ng-option>
            </ng-container>
          </ng-select>
        </div>

        <div>
          <div class="text-xs mb-1">Period Type</div>
          <ng-select
            class="w-32"
            [formControl]="selectedPeriodType"
            [clearable]="false"
            [multiple]="false"
            [searchable]="false"
            [items]="periodTypes"
            bindValue="value"
          >
          </ng-select>
        </div>

        <div *ngIf="showBudgetTypeSelect$ | async">
          <div class="text-xs mb-1">Budget Type</div>
          <ng-select
            class="w-40"
            [formControl]="selectedBudgetType"
            [clearable]="false"
            [multiple]="false"
            [searchable]="false"
            [items]="budgetTypes"
            bindValue="value"
          >
          </ng-select>
        </div>
      </div>
      <aux-export-excel-button
        [excelOptions]="excelOptions"
        [gridAPI]="gridAPI"
        [customColFilter]="customColFilter"
        [ignoreColsId]="['EXPENSE_WP::TO_DATE', 'group1', 'group2', 'group3', 'group4', 'group5']"
        [variant]="exportButtonVariant"
      >
      </aux-export-excel-button>

      <aux-menu #budget_menu>
        <button
          type="button"
          role="menuitem"
          class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 px-4 py-2 text-sm w-full flex items-center focus:outline-none"
          (click)="onBudgetUploadClick(); budget_menu.close()"
        >
          <span inlineSVG="file-upload.svg"></span>
          <span class="ml-2">Upload</span>
        </button>
      </aux-menu>
    </div>
  </ng-template>

  <ng-container *ngIf="budgetCanvas$ | async as budgetCanvas">
    <div class="my-8" style="height: 250px" *ngIf="budgetCanvas.show">
      <canvas
        baseChart
        [labels]="budgetCanvas.labels"
        [datasets]="budgetCanvas.datasets"
        [options]="budgetCanvas.options"
        [legend]="budgetCanvas.legend"
        [chartType]="budgetCanvas.type"
        [colors]="budgetCanvas.colors"
      >
      </canvas>
    </div>
  </ng-container>
  <div style="width: 100%; overflow: auto">
    <div class="mb-8 mt-8 grid grid-cols-3 gap-7.5">
      <div class="flex col-end-5">
        <div class="text-sm grid items-center">
          <span>Years</span>
        </div>
        <div
          class="text-sm flex justify-between items-center px-3 h-10 w-60 border border-aux-gray-dark font-medium rounded-md shadow-sm ml-1"
          (click)="openList()"
        >
          <ng-container *ngIf="selectedYear; else placeholderTemplate">
            <span class="text-sm">{{ selectedYear }}</span>
          </ng-container>

          <ng-template #placeholderTemplate>
            <span class="text-gray-500">All</span>
          </ng-template>

          <div>
            <chevron-down-solid-icon class="text-aux-gray-darkest"></chevron-down-solid-icon>
          </div>
        </div>

        <div>
          <div class="mt-11" cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>
        </div>
        <!-- <div (click)="isOpen = !isOpen" type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        {{ selected }}
      </div> -->
        <ng-template
          cdkConnectedOverlay
          class="border-solid border-aux-gray-dark-100"
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="isYearsOpen"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'ttt'"
          [cdkConnectedOverlayPositions]="positions"
          (backdropClick)="closeList()"
          (detach)="closeList()"
        >
          <ul
            tabindex="-1"
            class="bg-white border-solid text-gray-900 border-aux-gray-light rounded-md py-1 text-base ring-1 overflow-auto focus:outline-none sm:text-sm w-60"
          >
            <ng-container class="grid" *ngFor="let item of years; index as index">
              <li class="cursor-default select-none relative flex py-2 pl-2">
                <aux-checkbox
                  [checked]="item.enabled"
                  (customChange)="yearChanged($event, item.label)"
                ></aux-checkbox>
                {{ item.label }}
              </li>
            </ng-container>
          </ul>
        </ng-template>
      </div>
    </div>

    <ag-grid-angular
      #budgetGrid
      domLayout="autoHeight"
      class="ag-theme-alpine budget-grid tabular-nums w-full"
      [gridOptions]="$any(gridOptions$ | async)"
      [columnDefs]="columnDefs"
      [rowData]="gridData$ | async"
      (firstDataRendered)="onDataRendered($event)"
      (columnResized)="autoSize()"
    >
    </ag-grid-angular>
  </div>
</ng-container>
