<div>
  <aux-workflow-panel
    [workflowName]="workflowName"
    [isAdminUser]="isAdminUser"
    *ngIf="isQuarterCloseEnabled$ | async"
    className="mb-6"
    [processing]="(iCloseMonthsProcessing$ | async)!"
  ></aux-workflow-panel>
  <div class="mb-8 flex justify-between">
    <div>
      <nav class="flex -mb-px space-x-4" aria-label="Tabs">
        <ng-container *ngFor="let tab of tabs; index as i">
          <button
            type="button"
            [routerLink]="tab.route"
            *ngIf="tab.show ? (tab.show | async) : true"
            routerLinkActive
            #rla="routerLinkActive"
            [routerLinkActiveOptions]="{ exact: true }"
            class="px-4 py-2 rounded text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
            [ngClass]="{
              'border-aux-blue-light bg-aux-blue-light text-white': rla.isActive,
              'border-transparent text-gray-500 hover:text-gray-700': !rla.isActive
            }"
          >
            {{ tab.label }}
          </button>
        </ng-container>
      </nav>
    </div>
    <div class="flex space-x-2">
      <ng-container [ngTemplateOutlet]="rightSideContainer | async"></ng-container>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>
