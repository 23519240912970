import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Utils } from '@services/utils';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';

@Component({
  template: `
    <div
      [ngStyle]="{ width: '100%' }"
      class="flex items-center justify-items-start space-x-2"
      *ngIf="percent > 0"
    >
      <div
        *ngIf="percent > 0"
        class="h-5  flex items-center pr-8 justify-end"
        [ngClass]="{
          'bg-aux-blue': percent <= 100,
          'bg-aux-error': percent > 100
        }"
        [ngStyle]="{ width: percent + '%' }"
      ></div>
      <span class="text-aux-black tabular-nums ml-1" [attr.auto-qa]="autoTestAttribute">
        {{ percent }}%
      </span>
    </div>
    <div
      *ngIf="percent === 0"
      class="flex items-center justify-items-start space-x-2"
      [attr.auto-qa]="autoTestAttribute"
    >
      {{ dash }}
    </div>
    <div *ngIf="percent < 0" [attr.auto-qa]="autoTestAttribute"></div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioCompleteComponent
  extends AgCellWrapperComponent
  implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  percent!: number;

  bgClass!: string;

  dash = Utils.zeroHyphen;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.percent = params.data.complete || 0;

    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }
}
