import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ArrayDataSource } from '@angular/cdk/collections';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Flags, LaunchDarklyService } from '@services/launch-darkly.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTING_PATH } from '../../app-routing-path.const';

interface NavNode {
  name?: string;

  svg?: string;
  activeSvg?: string;

  url?: string;
  activeUrls?: string[];
  children?: NavNode[];

  divider?: boolean;
  className?: string;
  flagKey?: keyof Flags;
}

const TREE_DATA: NavNode[] = [
  {
    name: 'Trial Dashboard',
    url: `/${ROUTING_PATH.DASHBOARD}`,
    flagKey: 'nav_home',
  },
  {
    name: 'Budget',
    url: `/${ROUTING_PATH.BUDGET.INDEX}`,
    flagKey: 'nav_budget',
  },
  {
    name: 'Forecast',
    url: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}`,
    flagKey: 'nav_forecast',
  },
  {
    name: 'Period Close',
    url: `/${ROUTING_PATH.CLOSING.INDEX}`,
    flagKey: 'tab_forecast_in_month',
  },
  {
    name: 'Sites',
    url: `/${ROUTING_PATH.SITES.INDEX}`,
  },
  {
    name: 'Patients',
    url: `/${ROUTING_PATH.PATIENTS.INDEX}`,
  },
  {
    name: 'Vendor Payments',
    url: `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}`,
    flagKey: 'nav_invoices',
  },
  {
    name: 'Scenario Manager',
    url: `/${ROUTING_PATH.MANAGER}`,
    flagKey: 'tab_compare_scenario_manager',
  },
  {
    name: 'Risk Analytics',
    url: `/${ROUTING_PATH.RISK_ANALYTICS}`,
    flagKey: 'nav_risk',
  },
  {
    name: 'Audit History',
    url: `/${ROUTING_PATH.AUDIT_HISTORY}`,
    flagKey: 'nav_audit_history',
  },
  { divider: true },
  {
    name: 'Document Library',
    url: `/${ROUTING_PATH.DOCUMENTS}`,
    svg: 'documents.svg',
    className: 'w-5',
    activeSvg: 'documents-active.svg',
  },
  {
    name: 'Settings',
    url: `/${ROUTING_PATH.SETTINGS.INDEX}`,
    svg: 'cog.svg',
    activeSvg: 'cog-active.svg',
  },
  {
    name: 'Design System',
    url: '/design-system',
    svg: 'rocket.svg',
    flagKey: 'nav_design_system',
  },
];

@UntilDestroy()
@Component({
  selector: 'aux-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideVertical', [
      state(
        '*',
        style({
          height: 0,
        })
      ),
      state(
        'show',
        style({
          height: '*',
        })
      ),
      transition('* => *', [animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')]),
    ]),
  ],
})
export class SidebarNavComponent {
  isAdminUser = false;

  treeControl = new NestedTreeControl<NavNode>((node) => node.children);

  dataSource = new ArrayDataSource(
    this.ld.flags$.pipe(
      distinctUntilChanged((oldFlags, newFlags) => {
        // get all the flags that we are using in navigation
        const usedFlags = TREE_DATA.map((navNode) => navNode.flagKey).filter(
          (x) => x
        ) as (keyof Flags)[];

        // check if any of the flags changed
        return usedFlags.every((flag) => oldFlags[flag] === newFlags[flag]);
      }),
      map((flags) => {
        return TREE_DATA.filter((navNode) => (navNode.flagKey ? flags[navNode.flagKey] : true));
      })
    )
  );

  constructor(
    private ld: LaunchDarklyService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  hasChild = (_: number, node: NavNode) => !!node.children && node.children.length > 0;

  isDivider = (_: number, node: NavNode) => !!node.divider;

  isActiveLink(routerLink: string, isActiveLink: boolean, activeUrls: string[]) {
    const parentRoute = this.activatedRoute.snapshot?.firstChild?.url[0].path;

    if (parentRoute && !isActiveLink) {
      const isActive = activeUrls.indexOf(`/${parentRoute}`) !== -1;

      const isLinkMath = activeUrls.indexOf(routerLink) !== -1;

      return isLinkMath ? isActive : isActiveLink;
    }

    return isActiveLink;
  }
}
