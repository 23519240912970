import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { FormControl } from '@angular/forms';

@Component({
  template: `
    <div class="grid bg-white" style="width: 660px">
      <span class="font-bold text-aux-black text-3xl mb-4"> Replace Baseline Budget?</span>
      <span class="text-xl text-aux-black mb-4">
        This will replace your baseline budget and have an impact on budget variances.</span
      >
      <span class="text-xl text-aux-black mb-6">
        Type “Replace” in the field below to replace your baseline budget</span
      >
      <div class="grid grid-cols-5 gap-5 mb-3">
        <aux-input
          style="width: 330px;"
          [formControl]="replaceName"
          validators="required"
          (keydown.enter)="replaceBudget()"
        ></aux-input>
      </div>
      <div class="border-b-2 bg-aux-black mt-2 mb-2"></div>
      <div class="flex justify-between">
        <button
          class="text-sm font-normal focus:outline-none"
          (click)="ref.close(false)"
          type="button"
        >
          Cancel
        </button>
        <button
          [disabled]="replaceName.value !== 'Replace'"
          class="btn--primary text-xl p-3 pl-5 pr-5"
          (click)="this.ref.close(true)"
        >
          Replace Budget
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetLibraryUploadBaselineComponent {
  replaceName = new FormControl();

  constructor(public ref: CustomOverlayRef<any, { flag: boolean }>) {}

  replaceBudget() {
    if (this.replaceName.value === 'Replace') {
      this.ref.close(true);
    }
  }
}
