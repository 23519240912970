import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IHeaderGroupParams, IProvidedColumn } from 'ag-grid-community';
import { AgHeaderExpandComponent } from '../../../budget-page/tabs/budget/ag-header-expand.component';

@Component({
  template: `
    <div class="flex items-center">
      <span>{{ params.displayName }}</span>
      <span
        inlineSVG="chevron-right.svg"
        class="ml-1 cursor-pointer"
        (click)="toggleExpand()"
        [setSVGAttributes]="{
          class: 'w-4 h-4',
          transform: 'rotate(180)' + (!visible ? ' scale(-1, 1)' : '')
        }"
      ></span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgQuarterExpandableGroupHeaderComponent extends AgHeaderExpandComponent {
  params!: IHeaderGroupParams;

  visible = true;

  agInit(params: IHeaderGroupParams): void {
    this.params = params;

    this.toggleExpand();
  }

  filterCols(column: IProvidedColumn, index: number, columns: IProvidedColumn[]) {
    return index !== columns.length - 1;
  }
}
