import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ApiService } from '@services/api.service';
import { AppInitService } from '@services/app-init.service';
import { AppService } from '@services/app.service';

@UntilDestroy()
@Component({
  selector: 'aux-root',
  template: ` <router-outlet></router-outlet> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private appInitService: AppInitService,
    private appService: AppService
  ) {
    this.changePx(14);
    // this.breakpointObserver
    //   .observe([
    //     '(min-width: 768px)',
    //     '(min-width: 1024px)',
    //     '(min-width: 1280px)',
    //     '(min-width: 1536px)',
    //   ])
    //   .pipe(untilDestroyed(this))
    //   .subscribe((s) => {
    //     const {
    //       '(min-width: 768px)': md,
    //       '(min-width: 1024px)': lg,
    //       '(min-width: 1280px)': xl,
    //       '(min-width: 1536px)': xxl,
    //     } = s.breakpoints;
    //     const pxs = {
    //       xxl: 16,
    //       xl: 14,
    //       lg: 12,
    //       md: 10,
    //     };
    //     if (xxl) {
    //       this.changePx(pxs.xxl);
    //     } else if (xl) {
    //       this.changePx(pxs.xl);
    //     } else if (lg) {
    //       this.changePx(pxs.lg);
    //     } else if (md) {
    //       this.changePx(pxs.md);
    //     }
    //   });
  }

  changePx(px: number) {
    const html = document.querySelector('html');
    if (html) {
      html.style.fontSize = `${px}px`;
    }
  }
}
