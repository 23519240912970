<div class="font-semibold text-lg text-black mb-4">Email Notifications</div>
<div class="text-xs text-aux-black mb-4">
  Choose the type of email alerts you would like to receive:
</div>

<div class="grid grid-cols-3 gap-10 whitespace-nowrap text-xs">
  <div class="space-y-6">
    <div class="rounded border p-6 space-y-3">
      <div class="flex justify-between">
        <strong>Period Close</strong>
        <aux-checkbox
          (customChange)="onGroupChange('periodClose')"
          [checked]="isGroupChecked(notifications.periodClose)"
          [indeterminate]="isGroupIndeterminate(notifications.periodClose)"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Reminder: Upload Vendor Estimates
        <aux-checkbox
          (customChange)="
            onChange([notifications.periodClose.uploadVendorEstimatesReminder.event_type])
          "
          [(ngModel)]="notifications.periodClose.uploadVendorEstimatesReminder.enabled"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Month Available to Close
        <aux-checkbox
          (customChange)="onChange([notifications.periodClose.monthCloseAvailable.event_type])"
          [(ngModel)]="notifications.periodClose.monthCloseAvailable.enabled"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Month Closed
        <aux-checkbox
          (customChange)="onChange([notifications.periodClose.monthClosed.event_type])"
          [(ngModel)]="notifications.periodClose.monthClosed.enabled"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Quarter Closed
        <aux-checkbox
          (customChange)="onChange([notifications.periodClose.quarterClosed.event_type])"
          [(ngModel)]="notifications.periodClose.quarterClosed.enabled"
        ></aux-checkbox>
      </div>
    </div>
  </div>

  <div class="space-y-4">
    <div class="rounded border p-6 space-y-3">
      <div class="flex justify-between">
        <strong>Invoices</strong>
        <aux-checkbox
          (customChange)="onGroupChange('invoices')"
          [checked]="isGroupChecked(notifications.invoices)"
          [indeterminate]="isGroupIndeterminate(notifications.invoices)"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Reminder: Upload Invoices
        <aux-checkbox
          (customChange)="onChange([notifications.invoices.uploadInvoicesReminder.event_type])"
          [(ngModel)]="notifications.invoices.uploadInvoicesReminder.enabled"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Pending Approval
        <aux-checkbox
          (customChange)="onChange([notifications.invoices.pendingApproval.event_type])"
          [(ngModel)]="notifications.invoices.pendingApproval.enabled"
        ></aux-checkbox>
      </div>
    </div>

    <div class="rounded border p-6 space-y-3">
      <div class="flex justify-between">
        <strong>Change Orders</strong>
        <aux-checkbox
          (customChange)="onGroupChange('changeOrder')"
          [checked]="isGroupChecked(notifications.changeOrder)"
          [indeterminate]="isGroupIndeterminate(notifications.changeOrder)"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Pending Review
        <aux-checkbox
          (customChange)="
            onChange([
              notifications.changeOrder.pendingReview.event_type,
              notifications.changeOrder.backToPendingReview.event_type
            ])
          "
          [(ngModel)]="notifications.changeOrder.pendingReview.enabled"
          [(ngModel)]="notifications.changeOrder.backToPendingReview.enabled"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Pending Approval
        <aux-checkbox
          (customChange)="onChange([notifications.changeOrder.pendingApproval.event_type])"
          [(ngModel)]="notifications.changeOrder.pendingApproval.enabled"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Approved
        <aux-checkbox
          (customChange)="onChange([notifications.changeOrder.approved.event_type])"
          [(ngModel)]="notifications.changeOrder.approved.enabled"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Declined
        <aux-checkbox
          (customChange)="onChange([notifications.changeOrder.declined.event_type])"
          [(ngModel)]="notifications.changeOrder.declined.enabled"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Deleted
        <aux-checkbox
          (customChange)="onChange([notifications.changeOrder.deleted.event_type])"
          [(ngModel)]="notifications.changeOrder.deleted.enabled"
        ></aux-checkbox>
      </div>
    </div>

    <div class="rounded border p-6 space-y-3" *ngIf="authQuery.adminUser$ | async">
      <div class="flex justify-between">
        <strong>Support</strong>
        <aux-checkbox
          (customChange)="onGroupChange('support')"
          [checked]="isGroupChecked(notifications.support)"
          [indeterminate]="isGroupIndeterminate(notifications.support)"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Document(s) Uploaded
        <aux-checkbox
          (customChange)="onChange([notifications.support.sendEmailDocumentsUploaded.event_type])"
          [(ngModel)]="notifications.support.sendEmailDocumentsUploaded.enabled"
        ></aux-checkbox> 
      </div>
      <div class="flex justify-between">
        Vendor Document(s) Uploaded
        <aux-checkbox
          (customChange)="onChange([notifications.support.sendEmailVendorDocumentUploaded.event_type])"
          [(ngModel)]="notifications.support.sendEmailVendorDocumentUploaded.enabled"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Invoices Pending Review
        <aux-checkbox
          (customChange)="onChange([notifications.support.invoicesPendingReview.event_type])"
          [(ngModel)]="notifications.support.invoicesPendingReview.enabled"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Reminder: Categorize Invoices
        <aux-checkbox
          (customChange)="
            onChange([notifications.support.invoiceCategorizationReminder.event_type])
          "
          [(ngModel)]="notifications.support.invoiceCategorizationReminder.enabled"
        ></aux-checkbox>
      </div>
      <div class="flex justify-between">
        Vendor Estimate(s) Uploaded
        <aux-checkbox
          (customChange)="onChange([notifications.support.vendorEstimateUploaded.event_type])"
          [(ngModel)]="notifications.support.vendorEstimateUploaded.enabled"
        ></aux-checkbox>
      </div>
    </div>
  </div>
</div>

<aux-save-changes [onSaveChanges]="saveChanges" *ngIf="(saveCheck | async)!"> </aux-save-changes>
