import { Injectable } from '@angular/core';
import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { mapValues } from 'lodash-es';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PeriodType } from '@services/gql.service';
import { PermissionType } from '@directives/authorize.directive';

export interface Flags {
  cronjob_bill_com_integration: string;
  cronjob_quickbooks_online_integration: string;
  design_system: boolean;
  nav_home: boolean;
  nav_audit_history: boolean;
  nav_budget: boolean;
  nav_change: boolean;
  nav_design_system: boolean;
  nav_forecast: boolean;
  nav_invoices: boolean;
  nav_patient: boolean;
  nav_risk: boolean;
  nav_scenarios: boolean;
  nav_portfolio: boolean;
  section_budget_analytics: boolean;
  section_compare_analytics: boolean;
  section_budget_snapshots: boolean;
  section_footer_roadmap: boolean;
  section_reconciliation_analytics: boolean;
  section_budget_type: boolean;
  section_profile: boolean;
  section_forecast_cost_through_eot: boolean;
  tab_profile_settings: boolean;
  tab_budget_payment_schedule: boolean;
  tab_budget_cash_management: boolean;
  tab_forecast_investigator_forecast: boolean;
  tab_forecast_in_month: boolean;
  tab_forecast_investigator_detail: boolean;
  tab_forecast_drivers: boolean;
  tab_forecast_passthrough: boolean;
  tab_patient_groups: boolean;
  tab_forecast_site_groups: boolean;
  client_preference_quarter_close_workflow_enabled: boolean;
  tab_trial_settings_patient_protocol: boolean;
  section_forecast_analytics: boolean;
  section_patient_tracker_analytics: boolean;
  section_task_list: boolean;
  netsuite_integration_month_close: boolean;
  section_change_analytics: boolean;
  section_change_order_chart: boolean;
  client_preference_budget_period_type: PeriodType;
  client_preference_session_timeout: number;
  section_investigator_details_analytics: boolean;
  section_patient_driver_enrollment_settings: boolean;
  section_portfolio_analytics_avg_month_close: boolean;
  section_site_driver_assumptions: boolean;
  tab_change_order_review: boolean;
  tab_compare_scenario_manager: boolean;
  tab_budget_budget_enhanced: boolean;
  tab_budget_budget_library: boolean;
  tab_exchange_rates: boolean;
  tab_integrations: boolean;
  approval_rule_invoice: { rule: { name: string; permission_type: PermissionType }[] };
  approval_rule_in_month: { rule: { name: string; permission_type: PermissionType }[] };
  approval_rule_change_order: { rule: { name: string; permission_type: PermissionType }[] };
  patient_groups_curve_only: boolean;
}

@Injectable()
export class LaunchDarklyService {
  ldClient!: LDClient;

  flags$ = new BehaviorSubject<Flags>({
    cronjob_bill_com_integration: '',
    cronjob_quickbooks_online_integration: '',
    nav_home: false,
    nav_audit_history: false,
    nav_budget: false,
    nav_change: false,
    nav_design_system: false,
    nav_forecast: false,
    nav_invoices: false,
    nav_patient: false,
    nav_risk: false,
    nav_scenarios: false,
    nav_portfolio: false,
    netsuite_integration_month_close: false,
    client_preference_quarter_close_workflow_enabled: false,
    design_system: false,
    section_forecast_cost_through_eot: false,
    section_budget_analytics: false,
    section_compare_analytics: false,
    section_budget_snapshots: false,
    section_change_order_chart: false,
    section_reconciliation_analytics: false,
    section_profile: false,
    tab_profile_settings: false,
    tab_budget_payment_schedule: false,
    tab_budget_cash_management: false,
    tab_forecast_investigator_forecast: false,
    tab_forecast_in_month: false,
    tab_forecast_investigator_detail: false,
    tab_forecast_drivers: false,
    tab_forecast_passthrough: false,
    tab_patient_groups: false,
    tab_forecast_site_groups: false,
    tab_trial_settings_patient_protocol: false,
    tab_exchange_rates: false,
    tab_integrations: false,
    section_forecast_analytics: false,
    section_change_analytics: false,
    section_patient_tracker_analytics: false,
    section_task_list: false,
    client_preference_budget_period_type: PeriodType.PERIOD_QUARTER,
    client_preference_session_timeout: 1800,
    section_investigator_details_analytics: false,
    section_budget_type: false,
    section_footer_roadmap: false,
    section_patient_driver_enrollment_settings: false,
    section_portfolio_analytics_avg_month_close: false,
    section_site_driver_assumptions: false,
    tab_change_order_review: false,
    tab_compare_scenario_manager: false,
    tab_budget_budget_enhanced: false,
    tab_budget_budget_library: false,
    approval_rule_invoice: { rule: [] },
    approval_rule_in_month: { rule: [] },
    approval_rule_change_order: { rule: [] },
    patient_groups_curve_only: false,
  });

  loaded$ = new BehaviorSubject(false);

  async initLaunchDarkly() {
    this.ldClient = initialize(environment.launchDarkly.clientId, {
      anonymous: true,
      custom: {
        clientName: environment.launchDarkly.clientName,
      },
    });

    await this.ldClient.waitForInitialization();

    this.flags$.next({ ...this.flags$.getValue(), ...this.ldClient.allFlags() });
    console.log('FLAGS ::: ', this.flags$.getValue());

    this.ldClient.on('change', (changedFlags) => {
      const flags = this.flags$.getValue();

      console.log('changedFlags:: ', changedFlags);
      const newFlags = {
        ...flags,
        ...mapValues(changedFlags, 'current'),
      };

      this.flags$.next(newFlags);
      console.log('FLAGS ::: ', this.flags$.getValue());
    });
    this.loaded$.next(true);
  }

  select$<R>(fn: (flags: Flags) => R): Observable<R> {
    return this.flags$.pipe(map(fn));
  }

  changeUser(user: any) {
    this.ldClient.identify(user);
  }
}
