import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { File } from '@components/file-manager/state/file.model';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OverlayService } from '@services/overlay.service';
import { FormControl, Validators } from '@angular/forms';
import { map, switchMap } from 'rxjs/operators';
import {
  EntityType,
  EventType,
  GqlService,
  InvoiceStatus,
  TemplateType,
} from '@services/gql.service';
import { ApiService } from '@services/api.service';
import { Utils } from '@services/utils';
import { InvoiceService } from '../state/invoice.service';
import { INVOICE_STATUSES, InvoiceModel } from '../state/invoice.model';
import { PurchaseOrdersService } from '../../purchase-orders/state/purchase-orders.service';
import { PurchaseOrdersQuery } from '../../purchase-orders/state/purchase-orders.query';
import { InvoiceQuery } from '../state/invoice.query';

@UntilDestroy()
@Component({
  selector: 'aux-new-invoice-dialog',
  templateUrl: './new-invoice-dialog.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewInvoiceDialogComponent implements AfterViewInit {
  @ViewChild('singleFileManager') singleFileManager: FileManagerComponent | undefined;

  @ViewChild('bulkFileManager') bulkFileManager: FileManagerComponent | undefined;

  filteredPONumbers$ = this.purchaseOrdersQuery.selectAll();

  pdf = 'application/pdf';

  path = '';

  files$: Observable<File[]> | undefined;

  bulkFiles$: Observable<File[]> | undefined;

  obj: { [k: string]: any } = {};

  selectedVendor = new FormControl('', [Validators.required]);

  selectedPOReference = new FormControl(null);

  loading$ = new BehaviorSubject(false);

  oneInvoiceSelected = false;

  updateValidation = new Subject();

  mode: 'edit' | 'new' = 'new';

  invoice?: InvoiceModel;

  tabs = [{ label: 'Single Invoice' }, { label: 'Bulk Upload' }];

  activeIndex = 0;

  errorMessage = '';

  constructor(
    public ref: CustomOverlayRef<any, { mode: 'edit' | 'new'; id: string }>,
    public vendorQuery: OrganizationQuery,
    public vendorsService: OrganizationService,
    public purchaseOrdersQuery: PurchaseOrdersQuery,
    private invoiceService: InvoiceService,
    private mainQuery: MainQuery,
    private apiService: ApiService,
    private overlayService: OverlayService,
    private purchaseOrdersService: PurchaseOrdersService,
    private gqlService: GqlService,
    private invoiceQuery: InvoiceQuery
  ) {
    this.vendorsService.get().pipe(untilDestroyed(this)).subscribe();

    if (this.ref.data) {
      this.mode = this.ref.data.mode;
      const invoice = this.invoiceQuery.getEntity(this.ref.data.id);
      if (invoice) {
        this.invoice = invoice;
        this.selectedVendor.setValue(invoice.organization.id);
        this.selectedPOReference.setValue(invoice.po_reference);
        this.selectedVendor.disable();
        this.selectedPOReference.disable();
      }
    }
  }

  getFilePath(vendor_sub: string, invoice_sub: string, type: 'invoice' | 'supporting') {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/vendors/${vendor_sub}/invoices/${invoice_sub}/${type}/`;
  }

  getBulkFilePath() {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/bulk-invoice-templates/`;
  }

  async onUpload() {
    this.selectedVendor.markAsDirty();
    this.selectedVendor.markAsTouched();
    this.selectedVendor.updateValueAndValidity();
    if (
      this.singleFileManager &&
      !this.loading$.getValue() &&
      (this.mode === 'new' ? this.selectedVendor.valid : true)
    ) {
      this.loading$.next(true);
      let success = false;
      let errors: string[] = [];
      let data = null;
      const files = this.singleFileManager.fileQuery.getAll();
      const filesWithUpdatedBucketKey = [];

      if (this.mode === 'new') {
        const invoiceId = Utils.uuid();
        const bucket_keys = [];

        for (const file of files) {
          const bucket_key = `${this.getFilePath(
            this.selectedVendor.value,
            invoiceId as string,
            this.obj[file.id]
          )}${file.key}`;

          bucket_keys.push(bucket_key);
          filesWithUpdatedBucketKey.push({
            ...file,
            key: bucket_key,
          });
        }
        const resp = await this.invoiceService.add({
          id: invoiceId,
          organization_id: this.selectedVendor.value,
          po_reference: this.selectedPOReference.value,
          bucket_keys,
        });
        success = resp.success;
        errors = resp.errors;
        data = resp.data;
      } else if (this.invoice) {
        success = true;
        data = { id: this.invoice.id };
      }

      if (success && data) {
        for (const file of filesWithUpdatedBucketKey) {
          this.singleFileManager.fileStore.update(file.id, file);
        }

        const filesSuccess = await this.singleFileManager.fileService.uploadFiles({
          status: INVOICE_STATUSES.STATUS_IN_QUEUE,
        });

        if (filesSuccess) {
          if (this.mode === 'new' && this.oneInvoiceSelected) {
            const invoice = files.find((file) => this.obj[file.id] === 'invoice') || files[0];
            const { success: eventSuccess, errors: eventErrors } = await this.gqlService
              .processEvent$({
                type: EventType.INVOICE_CREATED,
                entity_type: EntityType.INVOICE,
                entity_id: data.id,
                bucket_key: `public/${this.getFilePath(
                  this.selectedVendor.value,
                  data.id as string,
                  this.obj[invoice.id]
                )}${invoice.key}`,
              })
              .toPromise();

            if (eventSuccess) {
              this.overlayService.success(`Invoice successfully created!`);
            } else {
              for (const file of files) {
                this.apiService.removeFile(
                  `${this.getFilePath(
                    this.selectedVendor.value,
                    data.id as string,
                    this.obj[file.id]
                  )}${file.key}`
                );
              }
              this.overlayService.error(eventErrors);
            }
          } else if (this.mode === 'new' && !this.oneInvoiceSelected) {
            await this.invoiceService.update({
              ...data,
              invoice_status: InvoiceStatus.STATUS_PENDING_REVIEW,
            } as InvoiceModel);

            this.overlayService.success(`Invoice successfully created!`);
          } else {
            this.overlayService.success(`Invoice successfully updated!`);
          }

          this.ref.close(true);
        }
      } else {
        this.overlayService.error(errors);
      }
    }
    this.loading$.next(false);
  }

  onChangeVendor(selectedVendorId: String) {
    this.filteredPONumbers$ = this.purchaseOrdersQuery.selectAll().pipe(
      map((value) => {
        return value.filter((po) => {
          if (selectedVendorId) {
            return po.organization_id === selectedVendorId;
          }
          return po;
        });
      })
    );
  }

  removeFile(file: File) {
    if (this.singleFileManager) {
      this.singleFileManager.removeFile(file);
    }
  }

  removeBulkFile(file: File) {
    if (this.bulkFileManager) {
      this.bulkFileManager.removeFile(file);
    }
  }

  ngAfterViewInit() {
    if (this.singleFileManager) {
      this.files$ = this.singleFileManager.fileQuery.selectAll();
      this.updateValidation
        .pipe(
          switchMap(() => this.files$ as Observable<File[]>),
          untilDestroyed(this)
        )
        .subscribe((files) => {
          files.forEach((file) => {
            if (this.obj[file.id] === undefined) {
              this.obj[file.id] = 'supporting';
            }
          });

          this.oneInvoiceSelected = files.some((file) => this.obj[file.id] === 'invoice');
        });

      this.updateValidation.next();
    }

    if (this.bulkFileManager) {
      this.bulkFiles$ = this.bulkFileManager.fileQuery.selectAll();
    }
  }

  onTabChange(index: number) {
    this.activeIndex = index;
  }

  async downloadBulkInvoiceTemplate() {
    const { success, data } = await this.apiService.getTemplatePath(
      null,
      TemplateType.BULK_INVOICE_TEMPLATE
    );
    if (!(success && data)) {
      this.overlayService.error('There was a problem downloading the template');
    } else {
      await this.apiService.downloadFileFromPath(data.id);
    }
  }

  async onUploadBulk() {
    this.errorMessage = '';

    if (this.bulkFileManager && !this.loading$.getValue()) {
      const files = this.bulkFileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage = 'You need to upload a file!';
        return;
      }

      if (files.length > 1) {
        this.errorMessage = 'Maximum one file allowed!';
        return;
      }

      this.loading$.next(true);

      const file = files[0];
      const key = `${this.getBulkFilePath()}${file.key}`;

      this.bulkFileManager.fileStore.update(file.id, {
        ...file,
        key,
      });

      const fileSuccess = await this.bulkFileManager.fileService.uploadFiles();

      if (fileSuccess) {
        const { success, errors } = await this.gqlService
          .processEvent$({
            type: EventType.BULK_INVOICE_TEMPLATE_UPLOADED,
            entity_type: EntityType.INVOICE,
            entity_id: this.mainQuery.getValue().trialKey,
            bucket_key: `public/${key}`,
          })
          .toPromise();

        if (success) {
          this.overlayService.success();
        } else {
          this.apiService.removeFile(key);
          this.overlayService.error(errors);
        }

        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  }
}
