import { ColumnApi, ProcessCellForExportParams } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { CurrencyToggle } from '@components/toggle-currency/toggle-currency.component';
import { Utils } from '@services/utils';

export class TableService {
  static getTotalRowForExcel = (
    totalData: Record<string, number>,
    columnApi: ColumnApi,
    excludeColumns: string[],
    aggregateColumns: string[],
    aggregateColumnsPatterns: string[] = [],
    amountColumnKey = '::count'
  ) => {
    const totalRow = columnApi
      .getAllDisplayedColumns()
      .filter((key) => excludeColumns.indexOf(key.getColId()) === -1)
      .reduce<Record<string, null | number>>(
        (accum, col) => ({
          ...accum,
          [col.getColId()]: null,
        }),
        {}
      );

    Object.keys(totalRow).forEach((key) => {
      if (
        aggregateColumns.indexOf(key) !== -1 ||
        aggregateColumnsPatterns.some((field) => key.includes(field))
      ) {
        totalRow[key] = totalData[key];
      }
    });

    return Object.entries(totalRow).reduce<any[]>((accum, [key, value]) => {
      const totalValue = value || 0;

      accum.push({
        data: { value: `${totalValue}`, type: 'Number' },
        styleId: key.endsWith(amountColumnKey) ? 'total_row_amount' : 'total_row',
      });

      return accum;
    }, []);
  };

  static processCellForExcel = (
    selectedCurrency: BehaviorSubject<CurrencyToggle>,
    costKeyName: string,
    currencyColNames: string[]
  ) => (params: ProcessCellForExportParams): string => {
    const colId = params.column.getColId();
    const isCurrencyCell = colId.endsWith(costKeyName) || currencyColNames.indexOf(colId) !== -1;

    return isCurrencyCell
      ? Utils.agMultipleCurrencyFormatter(selectedCurrency)({
          value: params.value,
          data: params.node?.data,
        })
      : params.value;
  };
}
