import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { FormControl } from '@angular/forms';

@Component({
  template: `<div class="grid w-96">
    <span class="text-lg font-bold mb-4">Name Custom View</span>
    <aux-input
      class="w-full mb-2"
      [placeholder]="placeText"
      [formControl]="selectedText"
      required
    ></aux-input>
    <div class="flex justify-between">
      <button class="focus:outline-none text-aux-black" type="button" (click)="ref.close()">
        Cancel
      </button>
      <button class="btn--primary" (click)="saveText()" type="submit">Save</button>
    </div>
  </div>`,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetCustomCreateComponent {
  placeText = `Name your view, e.g. "Forecast View"`;

  public selectedText = new FormControl('');

  constructor(public ref: CustomOverlayRef<any>) {
    if (ref?.data?.textName) {
      this.selectedText.setValue(ref.data.textName);
    }
  }

  saveText() {
    return this.ref.close({ label: this.selectedText.value });
  }
}
