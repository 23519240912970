import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@models/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export type RoleType = 'ROLE_ADMIN' | 'ROLE_USER';

export type PermissionType =
  | 'PERMISSION_FORECAST_LOGIC'
  | 'PERMISSION_ACCRUAL_MONTHLY'
  | 'PERMISSION_INVOICE_UPLOAD'
  | 'PERMISSION_APPROVE_INVOICE'
  | 'PERMISSION_CIS_LOGADD'
  | 'PERMISSION_CIS_LOGACCEPT'
  | 'PERMISSION_APPROVE_CHANGE_ORDER'
  | 'PERMISSION_APPROVE_IN_MONTH'
  | 'PERMISSION_RISK_ALERTS'
  | 'PERMISSION_MODIFY_FORECAST_METHODOLOGY'
  | 'PERMISSION_MODIFY_PATIENT_CURVE'
  | 'PERMISSION_MODIFY_SITE_CURVE'
  | 'PERMISSION_MODIFY_TRIAL_TIMELINE'
  | 'PERMISSION_MODIFY_OPEN_MONTH_ADJUSTMENTS'
  | 'PERMISSION_CATEGORIZE_INVOICE';

export interface AllowedRolesPermissions {
  sysAdminsOnly?: boolean;
  roles?: Array<RoleType>;
  permissions?: Array<PermissionType>;
}

@UntilDestroy()
@Directive({
  selector: '[auxAuthorize]',
})
export class AuthorizeDirective {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
    private ref: ChangeDetectorRef
  ) {}

  private adjustView(visible: boolean) {
    if (visible) {
      if (!this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      }
    } else if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }

    this.ref.detectChanges();
  }

  @Input() set auxAuthorize(allowedRolesPermissions: AllowedRolesPermissions) {
    this.authService
      .isAuthorized$(allowedRolesPermissions)
      .pipe(untilDestroyed(this))
      .subscribe((authorized) => this.adjustView(authorized));
  }
}
