import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  GridOptions,
  GridReadyEvent,
  ModelUpdatedEvent,
  RowNode,
  RowSelectedEvent,
} from 'ag-grid-community';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import { Document } from '@services/gql.service';
import { Utils } from '@services/utils';
import { AuthQuery } from '@models/auth/auth.query';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AgActionsComponent } from '@components/ag-actions/ag-actions.component';
import { ApiService, AwsFile } from '@services/api.service';
import { DatePipe } from '@angular/common';
import { OverlayService } from '@services/overlay.service';
import { TableConstants } from '../../../constants/table.constants';
import { AgDropdownComponent, AgInputComponent } from './controls';
import { DocumentLibraryService } from '../document-library.service';

@Component({
  selector: 'aux-document-library',
  templateUrl: './document-library.component.html',
  styleUrls: ['./document-library.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentLibraryComponent implements OnInit {
  gridAPI!: GridApi;

  @Input() formGroup!: FormGroup;

  @Input() gridData$!: BehaviorSubject<Document[]>;

  @Input() inputChangeHandler!: () => void;

  @Input() rowSelectedHandler!: (event: RowSelectedEvent) => void;

  @Input() isExternalFilterPresent: (() => boolean) | undefined;

  @Input() doesExternalFilterPass: ((node: RowNode) => boolean) | undefined;

  @Output() deleteRowEvent = new EventEmitter<string>();

  @Output() rowDataChangedEvent = new EventEmitter();

  private controlCellClassNames = 'flex flex-col justify-center items-stretch';

  gridOptions$ = new BehaviorSubject<GridOptions>({});

  overlayNoRowsTemplate = TableConstants.NO_ROWS_MESSAGE;

  constructor(
    private authQuery: AuthQuery,
    private fb: FormBuilder,
    private apiService: ApiService,
    private overlayService: OverlayService,
    private documentLibraryService: DocumentLibraryService
  ) {}

  ngOnInit() {
    this.gridOptions$.next({
      ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
      rowHeight: 45,
      isRowSelectable: (node) => node.data.is_metadata_editable,
      isExternalFilterPresent: this.isExternalFilterPresent,
      doesExternalFilterPass: this.doesExternalFilterPass,
      onModelUpdated: (event: ModelUpdatedEvent) => {
        if (event.api.getDisplayedRowCount() === 0) {
          event.api.showNoRowsOverlay();
        } else {
          event.api.hideOverlay();
        }

        if (this.gridData$.getValue().length) {
          this.overlayNoRowsTemplate = TableConstants.NO_ROWS_ON_FILTERING_MESSAGE;
        }
      },
      defaultColDef: {
        ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
        cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_CENTER,
        resizable: true,
      },
      columnDefs: [
        {
          checkboxSelection: true,
          headerCheckboxSelection: true,
          maxWidth: 35,
          width: 35,
          minWidth: 35,
        },
        {
          headerName: '',
          field: 'actions',
          suppressMenu: true,
          cellClass: 'cell-justify-center',
          cellRenderer: AgActionsComponent,
          cellRendererParams: {
            hideDownloadButton: false,
            hideEditButton: true,
            downloadClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
              const ref = this.overlayService.loading();

              await this.apiService.downloadFile({
                key: rowNode.data.bucket_key.replace('public/', ''),
                fileName: rowNode.data.name,
              } as AwsFile);

              ref.close();
            },
            deleteClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
              (this.formGroup.controls.table as FormArray).removeAt(rowNode.rowIndex || 0);
              this.deleteRowEvent.emit(rowNode.data.id);
              this.gridAPI.applyTransaction({ remove: [rowNode.data] });
            },
          },
          maxWidth: 75,
        },
        {
          headerName: 'Document Short Name',
          headerClass: 'ag-header-align-center font-bold',
          field: 'description',
          tooltipField: 'description',
          cellClass: this.controlCellClassNames,
          cellRenderer: AgInputComponent,
          cellRendererParams: {
            formGroup: this.formGroup,
            changeHandler: this.inputChangeHandler,
          },
        },
        {
          headerName: 'File Name',
          headerClass: 'ag-header-align-center font-bold',
          field: 'name',
          tooltipField: 'name',
          cellClass: 'text-left cell-align-center',
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'value'),
        },
        {
          headerName: 'Document Type',
          headerClass: 'ag-header-align-center font-bold',
          field: 'document_type_id',
          cellClass: this.controlCellClassNames,
          cellRenderer: AgDropdownComponent,
          cellRendererParams: {
            options: this.documentLibraryService.documentTypeOptions,
            changeHandler: this.inputChangeHandler,
          },
          tooltipValueGetter: (params) =>
            Utils.getOptionLabel(
              this.documentLibraryService.documentTypeOptions,
              params.data.document_type_id
            ),
          getQuickFilterText: (params) =>
            Utils.getOptionLabel(
              this.documentLibraryService.documentTypeOptions,
              params.data.document_type_id
            ),
        },
        {
          headerName: 'Vendor',
          headerClass: 'ag-header-align-center font-bold',
          field: 'vendor_id',
          cellClass: this.controlCellClassNames,
          cellRenderer: AgDropdownComponent,
          cellRendererParams: {
            options: this.documentLibraryService.vendors,
            changeHandler: this.inputChangeHandler,
          },
          tooltipValueGetter: (params) =>
            Utils.getOptionLabel(this.documentLibraryService.vendors, params.data.vendor_id),
          getQuickFilterText: (params) =>
            Utils.getOptionLabel(this.documentLibraryService.vendors, params.data.vendor_id),
        },
        {
          headerName: 'Site',
          headerClass: 'ag-header-align-center font-bold',
          field: 'site_id',
          cellClass: this.controlCellClassNames,
          cellRenderer: AgDropdownComponent,
          cellRendererParams: {
            options: this.documentLibraryService.sites,
            changeHandler: this.inputChangeHandler,
          },
          tooltipValueGetter: (params) =>
            Utils.getOptionLabel(this.documentLibraryService.sites, params.data.site_id),
          getQuickFilterText: (params) =>
            Utils.getOptionLabel(this.documentLibraryService.sites, params.data.site_id),
        },
        {
          headerName: 'Date Uploaded',
          headerClass: 'ag-header-align-center font-bold',
          field: 'create_date',
          cellClass: 'text-right cell-align-center',
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          maxWidth: 180,
          tooltipValueGetter: (params) =>
            new DatePipe('en-US').transform(params.data.create_date, 'MM/dd/YYYY, h:mm a'),
          valueFormatter: (val: ValueFormatterParams) => {
            return new DatePipe('en-US').transform(val.value, 'MM/dd/YYYY, h:mm a') || '';
          },
        },
        {
          headerName: 'Uploaded by',
          headerClass: 'ag-header-align-center font-bold',
          cellClass: 'text-left cell-align-center',
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          field: 'created_by',
          tooltipValueGetter: (params) =>
            Utils.agUserFormatter(params.data.updatedByName, this.authQuery.isAuxAdmin()),
          valueFormatter: (params) =>
            Utils.agUserFormatter(params.data.updatedByName, this.authQuery.isAuxAdmin()),
        },
      ],
      excelStyles: [
        ...Utils.auxExcelStyle.filter(({ id }) => id === 'header' || id === 'first_row'),
        {
          id: 'cell',
          alignment: { horizontal: 'Left' },
          font: { fontName: 'Arial', size: 11 },
        },
      ],
    });
  }

  getGridContext() {
    return {
      formGroup: this.formGroup,
    };
  }

  refreshFormControls(isEvent?: boolean) {
    if (this.gridAPI) {
      this.createFormControls();
      this.gridAPI.refreshCells({ force: true });

      if (isEvent) {
        this.rowDataChangedEvent.emit();
      }
    }
  }

  private createFormControls() {
    const formArray = new FormArray([]);

    this.formGroup.removeControl('table');

    this.gridData$
      .getValue()
      .forEach(
        ({ description, document_type_id, vendor_id, site_id, id, is_metadata_editable }) => {
          formArray.push(
            this.fb.group({
              description: new FormControl({
                value: description || '',
                disabled: false,
              }),
              document_type_id: new FormControl({
                value: document_type_id,
                disabled: !is_metadata_editable,
              }),
              vendor_id: new FormControl({ value: vendor_id, disabled: !is_metadata_editable }),
              site_id: new FormControl({ value: site_id, disabled: !is_metadata_editable }),
              id,
            })
          );
        }
      );

    if (this.gridData$.getValue().length) {
      this.formGroup.addControl('table', formArray);
    }
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;

    this.refreshFormControls();
    this.gridAPI.sizeColumnsToFit();
  }
}
