import { Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OverlayComponent } from '@components/overlay/overlay.component';
import { ConfirmDialogComponent } from '@components/dialogs/confirm-dialog/confirm-dialog.component';
import { NotificationToastComponent } from '@components/dialogs/notification-toast/notification-toast.component';
import { SpinnerDialogComponent } from '@components/dialogs/spinner-dialog/spinner-dialog.component';

@Injectable()
export class OverlayService {
  constructor(public overlay: Overlay, private injector: Injector) {}

  open<R = any, T = any>({
    content,
    data = null,
    baseComponent,
    overlayConfig,
    closeButton = true,
  }: {
    content?: string | TemplateRef<any> | Type<any>;
    data?: T | null;
    baseComponent?: Type<any>;
    overlayConfig?: OverlayConfig;
    closeButton?: boolean;
  }): CustomOverlayRef<R> {
    const configs = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['modal', 'is-active'],
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      ...overlayConfig,
    });

    const overlayRef = this.overlay.create(configs);

    const customOverlayRef = new CustomOverlayRef<R, T>(overlayRef, closeButton, content, data);

    overlayRef.attach(
      new ComponentPortal(
        baseComponent || OverlayComponent,
        null,
        Injector.create({
          parent: this.injector,
          providers: [{ provide: CustomOverlayRef, useValue: customOverlayRef }],
        })
      )
    );

    return customOverlayRef;
  }

  notification<R = any, T = any>({
    content,
    data = null,
    baseComponent,
    overlayConfig,
    ms = 3000,
  }: {
    content?: string | TemplateRef<any> | Type<any>;
    data?: T | null;
    baseComponent?: Type<any>;
    overlayConfig?: OverlayConfig;
    ms?: number;
  }) {
    const ref = this.open<R, T>({
      content,
      data,
      baseComponent,
      overlayConfig: {
        ...overlayConfig,
        ...{
          hasBackdrop: true,
          backdropClass: 'ttt',
          positionStrategy: this.overlay.position().global().top('10px').right('10px'),
          panelClass: ['toast'],
        },
      },
    });
    const timeout = setTimeout(() => {
      if (ref) {
        ref.close();
      }
      clearTimeout(timeout);
    }, ms);
    return ref;
  }

  success(messages: string[] | string = 'Successfully saved!') {
    return this.notification<null, { messages?: string[]; mode: string }>({
      baseComponent: NotificationToastComponent,
      data: { messages: typeof messages === 'string' ? [messages] : messages, mode: 'success' },
    });
  }

  // ms: 6e4 1 minute
  error(messages: string[] | string = '', ms = 6e4): CustomOverlayRef<null, any> | undefined {
    let messagesAry = messages;
    if (typeof messages === 'string') {
      messagesAry = messages.split('<br/>');
    } else {
      const ary: string[] = [];
      messages.forEach((x) => {
        x.split('<br/>').forEach((y) => {
          ary.push(y);
        });
      });
      messagesAry = ary;
    }
    if (!messagesAry.length) {
      return undefined;
    }
    return this.notification<null, { messages?: string[]; mode: string }>({
      baseComponent: NotificationToastComponent,
      data: {
        messages: messagesAry,
        mode: 'error',
      },
      ms,
    });
  }

  openConfirmDialog({
    message,
    cancelBtnText = 'Cancel',
    header,
    okBtnText = 'Confirm',
    textarea,
    okBtnDataId = '',
    okBtnDisabled,
  }: {
    message: string;
    header: string;
    okBtnText?: string;
    cancelBtnText?: string;
    textarea?: { label: string; required: boolean };
    okBtnDataId?: string;
    okBtnDisabled?: boolean;
  }) {
    return this.open<
      { result: boolean; textarea: string },
      {
        message: string;
        header: string;
        okBtnText: string;
        okBtnDataId: string;
        cancelBtnText: string;
        textarea?: { label: string; required: boolean };
        okBtnDisabled?: boolean;
      }
    >({
      content: ConfirmDialogComponent,
      data: {
        header,
        message,
        cancelBtnText,
        okBtnText,
        okBtnDataId,
        okBtnDisabled,
        textarea,
      },
      overlayConfig: {
        scrollStrategy: this.overlay.scrollStrategies.block(),
      },
    });
  }

  loading() {
    return this.open({ baseComponent: SpinnerDialogComponent });
  }
}
