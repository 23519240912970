import { Injectable } from '@angular/core';
import { DriverPatientGroup, GqlService } from '@services/gql.service';
import { first, switchMap, take, tap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';

import { PatientCurveStore } from './patient-curve.store';
import { PatientCurveQuery } from './patient-curve.query';

@Injectable({ providedIn: 'root' })
export class PatientCurveService {
  constructor(
    private patientCurveStore: PatientCurveStore,
    private patientCurveQuery: PatientCurveQuery,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private mainQuery: MainQuery
  ) {}

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.patientCurveStore.remove(() => true);
        return this.gqlService.listDriverPatientGroups$().pipe(
          tap(({ success, data, errors }) => {
            let flag = false;
            if (success && data && data?.length > 0) {
              this.patientCurveStore.set(
                data.map((x: any) => {
                  if (!x.is_blended && !flag) {
                    flag = true;
                    return { ...x, showLine: true };
                  }
                  return { ...x, showLine: false };
                })
              );
            } else if (!success) {
              this.overlayService.error(errors);
            }
          })
        );
      })
    );
  }

  async add(patient: any) {
    const { success, errors } = await this.gqlService
      .createDriverBlendedPatientDistribution$({
        name: patient.name,
        patient_group_ids: patient.patient_group_ids as string[],
      })
      .toPromise();
    if (success) {
      await this.get().pipe(first()).toPromise();
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors };
  }

  async update(patient: any) {
    const { success, errors } = await this.gqlService
      .updateDriverBlendedPatientDistribution$({
        name: patient.name,
        blended_group_id: patient.patient_group_id as string,
        patient_group_ids: patient.patient_group_ids as string[],
      })
      .toPromise();
    if (success) {
      this.overlayService.success(`${patient.name} successfully updated!`);
      this.get().toPromise();
    } else {
      this.overlayService.error(errors);
    }
  }

  async remove(patient: DriverPatientGroup) {
    let successOverall = false;
    let errorsOverall: string | string[] | undefined = [];
    if (patient.is_blended && patient.patient_group_id) {
      const { success, errors } = await this.gqlService
        .removeDriverBlendedPatientDistribution$(patient.patient_group_id)
        .toPromise();
      successOverall = success;
      errorsOverall = errors;
    } else if (!patient.is_blended) {
      const { success, errors } = await this.gqlService
        .removePatientGroupDriver$(patient.driver_setting_id)
        .toPromise();
      successOverall = success;
      errorsOverall = errors;
    }
    if (successOverall) {
      this.overlayService.success(`${patient.name} successfully removed!`);
      await this.get().pipe(take(1)).toPromise();
    } else {
      this.overlayService.error(errorsOverall);
    }

    return { successOverall, errorsOverall };
  }
}
