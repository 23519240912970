import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';

@Component({
  template: `
    <div class="flex items-center space-x-2">
      <span style="font-size: 1rem; letter-spacing: 0.14px;" class="text-aux-gray-darkest"
        >Invoice Total</span
      >
      <button class="flex  items-center no-underline aux-link" (click)="onChangeClick()">
        <span
          class="text-aux-gray-darkest"
          *ngIf="(change$ | async) === false"
          inlineSVG="chevron-right.svg"
          [setSVGAttributes]="{ class: 'w-4 h-4' }"
        ></span>
        <span
          class="text-aux-gray-darkest"
          style="transform: rotate(180deg)"
          *ngIf="(change$ | async) === true"
          inlineSVG="chevron-right.svg"
          [setSVGAttributes]="{ class: 'w-4 h-4' }"
        ></span>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgHeaderActionsComponent {
  params!: ICellRendererParams;

  change$ = new BehaviorSubject(true);

  agInit(params: any): void {
    this.params = params;
  }

  onChangeClick() {
    this.params?.columnApi.setColumnsVisible(
      [
        'expense_amounts.investigator_total.value',
        'expense_amounts.pass_thru_total.value',
        'expense_amounts.services_total.value',
        'expense_amounts.discount_total.value',
      ],
      !this.change$.getValue()
    );
    this.change$.next(!this.change$.getValue());
  }
}
