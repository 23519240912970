import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';

@Component({
  template: `
    <div class="flex items-center justify-center space-x-2">
      <button
        class="btn rounded-full p-0 w-8 h-8"
        [ngClass]="{ 'opacity-30 cursor-not-allowed': !params.data.file }"
        [disabled]="!params.data.file"
        type="button"
        (click)="onDownloadClick()"
        *ngIf="!params.hideDownloadButton && params.downloadClickFN"
      >
        <span inlineSVG="download.svg" [setSVGAttributes]="{ class: 'w-4 h-4' }"></span>
      </button>

      <button
        class="btn text-aux-blue rounded-full p-0 w-8 h-8"
        type="button"
        (click)="onEditClick()"
        *ngIf="!params.hideEditButton && params.editClickFN"
      >
        <pencil-solid-icon [size]="16"></pencil-solid-icon>
      </button>

      <button
        class="btn rounded-full p-0 w-8 h-8"
        type="button"
        (click)="onDeleteClick()"
        *ngIf="!params.hideDeleteButton && params.deleteClickFN"
      >
        <trash-solid-icon [size]="16" class="text-aux-error"></trash-solid-icon>
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgPoActionsComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams & {
    deleteClickFN: undefined | ((params: { rowNode: RowNode }) => void);
    editClickFN: undefined | ((params: { rowNode: RowNode }) => void);
    downloadClickFN: undefined | ((params: { rowNode: RowNode }) => void);

    hideEditButton?: boolean;
    hideDeleteButton?: boolean;
    hideDownloadButton?: boolean;
  };

  refresh(): boolean {
    return false;
  }

  onDeleteClick() {
    if (this.params.deleteClickFN) {
      this.params.deleteClickFN({ rowNode: this.params.node });
    }
  }

  onEditClick() {
    if (this.params.editClickFN) {
      this.params.editClickFN({ rowNode: this.params.node });
    }
  }

  onDownloadClick() {
    if (this.params.downloadClickFN) {
      this.params.downloadClickFN({ rowNode: this.params.node });
    }
  }

  agInit(
    params: ICellRendererParams & {
      deleteClickFN: undefined | (() => void);
      editClickFN: undefined | (() => void);
      downloadClickFN: undefined | (() => void);
    }
  ) {
    this.params = params;
  }
}
