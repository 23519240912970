import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'aux-account-settings',
  template: `
    <div class="font-bold text-lg text-black mb-4">Contact Info</div>
    <div class="grid grid-cols-4 gap-10 whitespace-nowrap text-xs">
      <div>
        <div class="mb-3">First Name</div>
        <aux-input [type]="'text'"></aux-input>
      </div>
      <div>
        <div class="mb-3">Last Name</div>
        <aux-input [type]="'text'"></aux-input>
      </div>
      <div>
        <div class="mb-3">Title</div>
        <aux-input [type]="'text'"></aux-input>
      </div>
      <div>
        <div class="mb-3">Company</div>
        <aux-input [type]="'text'"></aux-input>
      </div>

      <div>
        <div class="mb-3">Email Address</div>
        <aux-input [type]="'email'"></aux-input>
      </div>

      <div>
        <div class="mb-3">Mobile Phone Number</div>
        <aux-input [type]="'number'"></aux-input>
      </div>
    </div>

    <div class="font-bold text-lg text-black my-4">Password</div>
    <div class="grid grid-cols-4 gap-10 whitespace-nowrap text-xs items-end">
      <div>
        <div class="mb-3">Password</div>
        <aux-input [type]="'password'"></aux-input>
      </div>
      <button class="btn bg-aux-gray-dark text-sm p-3 w-40">Change Password</button>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSettingsComponent {}
