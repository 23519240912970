import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ColDef,
  ColGroupDef,
  Column,
  ExcelExportParams,
  GridApi,
  GridOptions,
  RowNode,
} from 'ag-grid-community';
import { Utils } from '@services/utils';
import { AgPulseMinusComponent } from '@components/ag-actions/ag-pulse-minus.component';
import { BehaviorSubject, combineLatest, EMPTY, ReplaySubject } from 'rxjs';
import { BudgetData, BudgetType, GqlService } from '@services/gql.service';
import { OrganizationModel, OrganizationStore } from '@models/organization/organization.store';
import { OrganizationQuery } from '@models/organization/organization.query';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { TrialsQuery } from '@models/trials/trials.query';
import { OrganizationService } from '@models/organization/organization.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { BudgetService } from 'src/app/pages/budget-page/tabs/budget/state/budget.service';
import { switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge } from 'lodash-es';
import { BudgetQuery } from 'src/app/pages/budget-page/tabs/budget/state/budget.query';

export interface CompareGridData extends BudgetData {
  from_unit: number;
  from_unit_cost: number;
  from_total_cost: number;
  to_unit: number;
  to_unit_cost: number;
  to_total_cost: number;
  variance_unit: number;
  variance_unit_cost: number;
  variance_total_cost: number;
  variance_total_percent: number;
}

export interface CompareBudgetVersion {
  budget_version_id: string;
  budget_type: BudgetType;
  budget_name: string;
}

@UntilDestroy()
@Component({
  selector: 'aux-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareComponent {
  fromBudgetVersion$ = new BehaviorSubject<CompareBudgetVersion | null>(null);

  toBudgetVersion$ = new BehaviorSubject<CompareBudgetVersion | null>(null);

  @Output() budgetData = new EventEmitter<CompareGridData[]>();

  @Input()
  set fromBudgetVersion(v: CompareBudgetVersion | null) {
    if (v) {
      this.fromBudgetVersion$.next(v);
    }
  }

  @Input()
  set toBudgetVersion(v: CompareBudgetVersion | null) {
    if (v) {
      this.toBudgetVersion$.next(v);
    }
  }

  @Input()
  domLayout: 'normal' | 'autoHeight' = 'normal';

  defaultColumns: (ColDef | ColGroupDef)[] = [
    { headerName: 'Vendor', field: 'vendor_name', rowGroup: true, hide: true },
    {
      headerName: 'trial-vend-name',
      colId: 'trial-vend-name',
      headerClass: 'ag-header-align-left',
      hide: false,
      children: [
        {
          headerClass: 'ag-header-align-left',
          headerName: 'Cost Category',
          field: 'cost_category',
          rowGroup: true,
          hide: true,
        },
        {
          headerClass: 'ag-header-align-left',
          headerName: 'Category',
          field: 'group0',
          rowGroup: true,
          hide: true,
        },
        {
          headerClass: 'ag-header-align-left',
          headerName: 'Category',
          field: 'group1',
          rowGroup: true,
          hide: true,
        },
        {
          headerClass: 'ag-header-align-left',
          headerName: 'Category',
          field: 'group2',
          rowGroup: true,
          hide: true,
        },
        {
          headerClass: 'ag-header-align-left',
          headerName: 'Category',
          field: 'group3',
          rowGroup: true,
          hide: true,
        },
        {
          headerClass: 'ag-header-align-left',
          headerName: 'Category',
          field: 'group4',
          rowGroup: true,
          hide: true,
        },
      ],
    },
    {
      headerClass: 'ag-header-align-left',
      headerName: 'Label',
      field: 'display_label',
      rowGroup: false,
      hide: true,
    },
    {
      headerClass: 'ag-header-align-left',
      headerName: 'Activities',
      field: 'activity_name_label',
      rowGroup: false,
      hide: true,
    },
    {
      headerName: 'Current Budget (Baseline)',
      colId: 'Baseline',
      headerClass: 'ag-header-align-center',
      children: [
        {
          headerName: 'UNITS',
          field: 'from_unit',
          colId: 'from_unit',
          maxWidth: 85,
          headerClass: 'ag-header-align-center',
          cellClass: ['ag-cell-align-right', 'budget-unit'],
          valueFormatter: Utils.dashFormatter,
        },
        {
          headerName: 'UNIT COST',
          field: 'from_unit_cost',
          colId: 'from_unit_cost',
          maxWidth: 135,
          headerClass: 'ag-header-align-center',
          valueFormatter: Utils.agCurrencyFormatter,
          cellClass: ['ag-cell-align-right', 'budget-cost'],
        },
        {
          headerName: 'TOTAL',
          field: 'from_total_cost',
          colId: 'from_total_cost',
          maxWidth: 135,
          headerClass: 'ag-header-align-center',
          valueFormatter: Utils.agCurrencyFormatter,
          aggFunc: 'sum',
          cellClass: ['ag-cell-align-right', 'budget-cost'],
        },
      ],
    },
    {
      headerName: 'To Budget',
      headerClass: 'ag-header-align-center',
      colId: 'ToBudget',
      children: [
        {
          headerName: 'UNITS',
          field: 'to_unit',
          colId: 'to_unit',
          headerClass: 'ag-header-align-center',
          maxWidth: 85,
          valueFormatter: Utils.dashFormatter,
          cellClass: ['ag-cell-align-right', 'budget-unit'],
        },
        {
          headerName: 'UNIT COST',
          field: 'to_unit_cost',
          colId: 'to_unit_cost',
          headerClass: 'ag-header-align-center',
          maxWidth: 135,
          valueFormatter: Utils.agCurrencyFormatter,
          cellClass: ['ag-cell-align-right', 'budget-cost'],
        },
        {
          headerName: 'TOTAL',
          field: 'to_total_cost',
          colId: 'to_total_cost',
          headerClass: 'ag-header-align-center',
          maxWidth: 135,
          valueFormatter: Utils.agCurrencyFormatter,
          aggFunc: 'sum',
          cellClass: ['ag-cell-align-right', 'budget-cost'],
        },
      ],
    },
    {
      headerName: 'Variance',
      headerClass: 'ag-header-align-center',
      children: [
        {
          headerName: 'UNITS',
          field: 'variance_unit',
          colId: 'variance_unit',
          headerClass: 'ag-header-align-center',
          maxWidth: 85,
          cellClass: ['ag-cell-align-right', 'budget-unit'],
          cellRenderer: AgPulseMinusComponent,
          editable: false,
        },
        {
          headerName: 'UNIT COST',
          field: 'variance_unit_cost',
          colId: 'variance_unit_cost',
          headerClass: 'ag-header-align-center',
          maxWidth: 135,
          cellRenderer: AgPulseMinusComponent,
          cellClass: ['ag-cell-align-right', 'budget-cost'],
        },
        {
          headerName: 'TOTAL ($)',
          field: 'variance_total_cost',
          colId: 'variance_total_cost',
          headerClass: 'ag-header-align-center',
          maxWidth: 135,
          cellRenderer: AgPulseMinusComponent,
          aggFunc: 'sum',
          cellClass: ['ag-cell-align-right', 'budget-cost'],
        },
        {
          headerName: 'TOTAL (%)',
          field: 'variance_total_percent',
          colId: 'variance_total_percent',
          headerClass: 'ag-header-align-center',
          maxWidth: 105,
          cellRenderer: AgPulseMinusComponent,
          cellClass: ['ag-cell-align-right', 'budget-percent'],
          aggFunc: (params) => {
            let var_total = 0;
            let from_total = 0;
            for (const childRow of params.rowNode.allLeafChildren) {
              var_total += childRow.data.variance_total_cost || 0;
              from_total += childRow.data.from_total_cost || 0;
            }
            return from_total ? (var_total || 0) / (from_total || 1) : 0;
          },
        },
      ],
    },
    {
      headerName: 'Changed',
      field: 'changed',
      hide: true,
      filter: true,
    },
  ];

  groupDefaultExpanded = 1;

  gridOptions = {
    defaultColDef: {
      sortable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    autoGroupColumnDef: {
      headerName: 'Activities ',
      minWidth: 180,
      headerClass: 'ag-header-align-left',
      resizable: true,
      field: 'activity_name',
      tooltipField: 'activity_name',
      pinned: 'left',
      cellRendererParams: {
        suppressCount: true,
      },
    },
    groupIncludeTotalFooter: true,
    suppressCellFocus: true,
    suppressAggFuncInHeader: true,
    groupDefaultExpanded: this.groupDefaultExpanded,
    suppressColumnVirtualisation: true,
    columnDefs: this.defaultColumns,
    excelStyles: [
      {
        id: 'budget-cost',
        dataType: 'Number',
        numberFormat: { format: Utils.excelCostFormat },
      },
      {
        id: 'budget-percent',
        dataType: 'Number',
        numberFormat: { format: Utils.excelPercentFormat },
      },
      {
        id: 'first_row',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        alignment: { horizontal: 'Left' },
        interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
      },
      {
        id: 'trial-vend-name',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        alignment: { horizontal: 'Left' },
        interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
      },
      {
        id: 'budget-unit',
        dataType: 'Number',
        numberFormat: { format: '#;(#);—' },
      },
      {
        id: 'header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { color: '#094673', pattern: 'Solid' },
      },
      {
        id: 'headerGroup',
        font: {
          fontName: 'Arial',
          size: 11,
          bold: true,
          color: '#FFFFFF',
        },
        interior: { color: '#999999', pattern: 'Solid' },
      },
      {
        id: 'cell',
        font: { fontName: 'Arial', size: 11 },
      },
      {
        id: 'total_row_header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      },
      {
        id: 'total_row',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
        dataType: 'Number',
        numberFormat: { format: Utils.excelCostFormat },
      },
      {
        id: 'total_row_percent',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
        dataType: 'Number',
        numberFormat: { format: Utils.excelPercentFormat },
      },
    ],
    getRowStyle(params: { node: RowNode }) {
      if (params.node.data) {
        // activity sub category
        if (params.node.data.group_index === 1) {
          return {
            color: '#17202A',
            'font-size': '0.75rem',
            'font-weight': 'bold',
          };
        }
      }

      // vendor name row
      if (params.node.field === 'vendor_name') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
        };
      }
      // cost categories
      if (params.node.field === 'cost_category') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
        };
      }
      // activity category
      if (params.node.field === 'group0') {
        return {
          background: '#F6F8FA',
          color: '#17202A',
          'font-size': '0.75rem',
        };
      }
      if (params.node.field === 'group1') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
        };
      }
      if (params.node.field === 'group2') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
        };
      }
      if (params.node.field === 'group3') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
        };
      }
      if (params.node.field === 'group4') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
        };
      }
      // total row
      if (params.node.level < 0) {
        return {
          display: 'none',
        };
      }
      // pinned total row
      if (params.node.rowPinned === 'bottom') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
          'font-weight': 'bold',
        };
      }
      return {};
    },
  } as GridOptions;

  excelOptions = {
    sheetName: 'Compare Budgets',
    fileName: 'auxilius-budget-compare.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.cost_category;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'group0':
          return 280;
        case 'activity_name_label':
          return 490;
        default:
          return 105;
      }
    },
  } as ExcelExportParams;

  gridAPI!: GridApi;

  gridAPI$: ReplaySubject<GridApi> = new ReplaySubject<GridApi>(1);

  gridOptions$ = new BehaviorSubject<GridOptions>(this.gridOptions);

  gridData$ = new BehaviorSubject<CompareGridData[]>([]);

  loading$ = new BehaviorSubject(false);

  budget$ = combineLatest([
    this.fromBudgetVersion$,
    this.toBudgetVersion$,
    this.organizationQuery.selectActive(),
  ]).pipe(
    switchMap(([fromBudgetVersion, toBudgetVersion, organization]) => {
      if (!fromBudgetVersion) {
        return EMPTY;
      }

      if (!toBudgetVersion) {
        return EMPTY;
      }

      if (!organization) {
        return EMPTY;
      }

      this.loading$.next(true);

      return combineLatest([
        this.gqlService
          .listBudgetGrid$({
            budget_type: fromBudgetVersion.budget_type,
            budget_version_id: fromBudgetVersion.budget_version_id,
            in_month: false,
            period_type: null,
            vendor_id: organization.id,
          })
          .pipe(this.budgetService.budgetCacheMechanism()),
        this.gqlService
          .listBudgetGrid$({
            budget_type: toBudgetVersion.budget_type,
            budget_version_id: toBudgetVersion.budget_version_id,
            in_month: false,
            period_type: null,
            vendor_id: organization.id,
          })
          .pipe(this.budgetService.budgetCacheMechanism()),
      ]).pipe(
        tap(
          ([
            { success: from_success, data: from_data },
            { success: to_success, data: to_data },
          ]) => {
            const budget_data: any = [];
            const added_activities: Set<string> = new Set<string>();
            if (from_success && to_success && from_data?.budget_data && to_data?.budget_data) {
              for (let i = 0; i < from_data.budget_data.length; i++) {
                const from_current_row = from_data.budget_data[i];
                let current_item_no = '';
                let current_cost_cat = '';
                let current_group_index = '';
                if (
                  from_current_row?.activity_name &&
                  from_current_row?.cost_category &&
                  from_current_row?.item_no &&
                  from_current_row?.group_index !== null // group index can be 0
                ) {
                  current_item_no = from_current_row?.item_no;
                  current_cost_cat = from_current_row?.cost_category;
                  current_group_index = from_current_row?.group_index?.toString() || '';
                  added_activities.add(
                    `${current_cost_cat}.${current_item_no}.${current_group_index}`
                  );
                }

                const to_row = to_data.budget_data.filter(
                  (bdi) =>
                    bdi.cost_category === current_cost_cat &&
                    bdi.item_no === current_item_no &&
                    bdi.group_index === from_current_row.group_index
                );
                const to_current_row = to_row && to_row[0] ? to_row[0] : null;
                const variance_unit =
                  (to_current_row?.unit_num || 0) - (from_current_row?.unit_num || 0) || 0;
                const variance_unit_cost =
                  (to_current_row?.unit_cost || 0) - (from_current_row?.unit_cost || 0) || 0;
                const variance_total_cost =
                  (to_current_row?.direct_cost || 0) - (from_current_row?.direct_cost || 0) || 0;
                const variance_total_percent = !from_current_row?.direct_cost
                  ? 0
                  : (variance_total_cost || 0) / (from_current_row?.direct_cost || 1);

                budget_data.push({
                  ...from_current_row,
                  from_unit: from_current_row?.unit_num || 0,
                  from_unit_cost: from_current_row?.unit_cost || 0,
                  from_total_cost: from_current_row?.direct_cost || 0,
                  to_unit: to_current_row?.unit_num || 0,
                  to_unit_cost: to_current_row?.unit_cost || 0,
                  to_total_cost: to_current_row?.direct_cost || 0,
                  variance_unit,
                  variance_unit_cost,
                  variance_total_cost,
                  variance_total_percent,
                  changed: !!variance_unit || !!variance_unit_cost || !!variance_total_cost,
                });
              }
              for (let i = 0; i < to_data.budget_data.length; i++) {
                const to_row = to_data.budget_data[i];
                if (
                  to_row &&
                  to_row.item_no &&
                  to_row.group_index !== null &&
                  !added_activities.has(
                    `${to_row.cost_category}.${to_row.item_no}.${to_row.group_index}`
                  )
                ) {
                  budget_data.push({
                    ...to_row,
                    from_unit: 0,
                    from_unit_cost: 0,
                    from_total_cost: 0,
                    to_unit: to_row?.unit_num || 0,
                    to_unit_cost: to_row?.unit_cost || 0,
                    to_total_cost: to_row?.direct_cost || 0,
                    variance_unit: to_row.unit_num || 0,
                    variance_unit_cost: to_row.unit_cost || 0,
                    variance_total_cost: to_row.direct_cost || 0,
                    variance_total_percent: 0,
                    changed: !!to_row.unit_num || !!to_row.unit_cost || !!to_row.direct_cost,
                  });
                }
              }
            }

            const budgetData = budget_data.map((row: CompareGridData) =>
              row.cost_category === 'Discount'
                ? {
                    ...row,
                    from_unit: 0,
                    from_unit_cost: 0,
                    to_unit: 0,
                    to_unit_cost: 0,
                    variance_unit: 0,
                    variance_unit_cost: 0,
                  }
                : row
            );

            this.gridOptions.columnDefs = this.updateColumnHeaders(
              fromBudgetVersion,
              toBudgetVersion,
              organization
            );
            this.gridOptions$.next(this.gridOptions);
            this.gridData$.next(budgetData || []);
            this.budgetData.next(budgetData || []);

            this.loading$.next(false);
          }
        )
      );
    })
  );

  constructor(
    public organizationQuery: OrganizationQuery,
    private gqlService: GqlService,
    private organizationStore: OrganizationStore,
    private budgetQuery: BudgetQuery,
    private mainQuery: MainQuery,
    private trialsQuery: TrialsQuery,
    private organizationService: OrganizationService,
    private launchDarklyService: LaunchDarklyService,
    private budgetService: BudgetService
  ) {
    this.budget$.pipe(untilDestroyed(this)).subscribe();
  }

  updateColumnHeaders(
    from: CompareBudgetVersion,
    to: CompareBudgetVersion,
    org: OrganizationModel
  ) {
    return this.defaultColumns.map((col) => {
      if ('colId' in col) {
        switch (col.colId) {
          case 'Baseline':
            return {
              ...col,
              headerName: from.budget_name,
            };
          case 'ToBudget':
            return {
              ...col,
              headerName: to.budget_name,
            };
          case 'trial-vend-name':
            return {
              ...col,
              headerName: `Vendor: ${org.name}`,
            };
          default:
            return col;
        }
      }
      return col;
    });
  }

  onDataRendered(e: any) {
    this.gridAPI = e.api;
    this.gridAPI$.next(e.api);
    const { columnApi } = e;
    const allColumnIds: string[] = [];
    columnApi.getAllColumns().forEach((column: Column) => {
      allColumnIds.push(column.getColId());
    });
    columnApi.autoSizeColumns(allColumnIds, false);
    this.gridAPI?.setPinnedBottomRowData([
      merge(
        {
          activity_name: 'Total',
        },
        this.gridAPI?.getDisplayedRowAtIndex(this.gridAPI?.getDisplayedRowCount() - 1)?.aggData
      ),
    ]);
  }

  getTotalCost() {
    return this.gridData$.getValue().reduce(
      (acc, val) => {
        acc.from_total_cost += val.from_total_cost || 0;
        acc.to_total_cost += val.to_total_cost || 0;
        acc.variance_total_cost += val.variance_total_cost;
        return acc;
      },
      {
        from_total_cost: 0,
        to_total_cost: 0,
        variance_total_cost: 0,
      }
    );
  }

  getDynamicExcelParamsCallback(columns: string[]) {
    const columnKeys = [...columns];

    columnKeys.splice(1, 0, 'cost_category', 'group0', 'display_label', 'activity_name_label');

    return (): ExcelExportParams => {
      if (!this.gridAPI) {
        return {};
      }

      const name = this.mainQuery.getSelectedTrial()?.short_name;
      const { variance_total_cost, to_total_cost, from_total_cost } = this.getTotalCost();

      return {
        prependContent: [
          [
            {
              data: { value: `Trial: ${name}`, type: 'String' },
              mergeAcross: 1,
              styleId: 'first_row',
            },
          ],
        ],
        appendContent: [
          [
            {
              data: { value: `Total`, type: 'String' },
              mergeAcross: 3,
              styleId: 'total_row_header',
            },
            {
              // initial monthly accrual total
              data: { value: `${from_total_cost}`, type: 'Number' },
              mergeAcross: 2,
              styleId: 'total_row',
            },
            {
              // adjustment total
              data: { value: `${to_total_cost}`, type: 'Number' },
              mergeAcross: 2,
              styleId: 'total_row',
            },
            {
              // total monthly accrual total
              data: { value: `${variance_total_cost}`, type: 'Number' },
              mergeAcross: 2,
              styleId: 'total_row',
            },
            {
              data: {
                value: `${(variance_total_cost || 0) / (from_total_cost || 1)}`,
                type: 'Number',
              },
              styleId: 'total_row_percent',
            },
          ],
        ],
        columnKeys,
      };
    };
  }

  autoSize() {
    this.gridOptions$.getValue().api?.sizeColumnsToFit();
  }

  checkChange(hide: boolean) {
    if (!this.gridAPI) {
      return;
    }
    const filterInstance = this.gridAPI.getFilterInstance('changed');
    if (filterInstance) {
      filterInstance.setModel(hide ? { values: ['true'] } : null);
    }

    this.gridOptions$.getValue().api?.forEachNode((rowNode) => {
      if (hide) {
        if (rowNode.allChildrenCount && rowNode.allChildrenCount > 0) {
          rowNode.setRowHeight(0);
        }
        // eslint-disable-next-line no-param-reassign
        rowNode.expanded = true;
      } else {
        rowNode.setRowHeight(42);
        // eslint-disable-next-line no-param-reassign
        rowNode.expanded = rowNode?.level === this.groupDefaultExpanded - 1;
      }
    });

    this.gridAPI.onFilterChanged();
    this.gridAPI.onRowHeightChanged();
  }
}
