<ng-container *ngIf="$any(loading$ | async)">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<div *ngIf="!$any(loading$ | async) && !$any(patientGroupsLoading$ | async)">
  <ng-container *ngIf="{ activeIndex: activeTabIndex$ | async } as obj">
    <div>
      <div class="flex justify-between mb-4">
        <div>
          <ng-container *ngFor="let tab of tabs; index as i">
            <button
              *ngIf="tab.show | async"
              type="button"
              (click)="activeTabIndex$.next(i)"
              class="px-4 py-2 rounded text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
              [ngClass]="{
                'border-aux-blue-light bg-aux-blue-light text-white': obj.activeIndex === i,
                'border-transparent text-gray-500 hover:text-gray-700': obj.activeIndex !== i
              }"
            >
              {{ tab.label }}
            </button>
          </ng-container>
        </div>

        <div class="flex items-center">
          <aux-export-excel-button
            [gridAPI]="gridAPI"
            [ignoreColsId]="['patient_protocol_id']"
            [getDynamicExcelParamsCallback]="getDynamicExcelParams.bind(this)"
          >
          </aux-export-excel-button>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <ng-container *ngFor="let patientGroup of this.patientGroups">
        <aux-sites-table
          *ngIf="
            (activeTabIndex$ | async) !== this.patientGroups.length &&
            patientGroup.rank_order - 1 === (activeTabIndex$ | async)
          "
          [showGrid$]="showGrid$"
          [tableType]="patientTableTypes.VISITS_COSTS"
          [patientGroupId]="patientGroup.id"
          (gridApiChanged)="handleGridApi($event)"
        >
        </aux-sites-table>
      </ng-container>
      <aux-sites-table
        *ngIf="(activeTabIndex$ | async) === this.patientGroups.length"
        [showGrid$]="showGrid$"
        [tableType]="patientTableTypes.VISITS_COSTS"
        [patientGroupId]="'visits_costs'"
        (gridApiChanged)="handleGridApi($event)"
      >
      </aux-sites-table>
      <aux-sites-table
        *ngIf="(activeTabIndex$ | async) === this.patientGroups.length + 1"
        [showGrid$]="showGrid$"
        [tableType]="patientTableTypes.OTHER_COSTS"
        [patientGroupId]="''"
        (gridApiChanged)="handleGridApi($event)"
      >
      </aux-sites-table>
    </div>
  </ng-container>
</div>
