import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Option } from '@components/components.type';
import { CurveType, listDriverSiteGroupsQuery } from '@services/gql.service';
import { FormControl } from '@angular/forms';
import { FormControlConstants } from '../../../../../constants/form-control.constants';

@Component({
  selector: 'aux-forecast-site-curve-settings',
  templateUrl: './forecast-site-curve-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastSiteCurveSettingsComponent implements OnInit, OnChanges {
  readonly formControlConstants = FormControlConstants;

  @Input() driverSettingId = '';

  @Input() unfilteredSiteCurveGroups: listDriverSiteGroupsQuery[] = [];

  @Input() disabled = false;

  @Input() isInvalid = false;

  @Input() tooltip = '';

  @Output() siteGroupChange = new EventEmitter<string>();

  siteGroupControl = new FormControl();

  curveTypeControl = new FormControl();

  siteGroupsPeriodOptions: Option<CurveType>[] = [
    {
      value: CurveType.NET,
      label: 'Net Sites',
    },
    {
      value: CurveType.ACTIVATION,
      label: 'Sites Activated',
    },
    {
      value: CurveType.CLOSE_OUT,
      label: 'Sites Closed',
    },
  ];

  ngOnInit(): void {
    this.initSiteCurveGroupControlValue();
    this.initCurveTypeControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        this.siteGroupControl.disable();
        this.curveTypeControl.disable();
      } else {
        this.siteGroupControl.enable();
        this.curveTypeControl.enable();
      }
    }
  }

  onSiteGroupControlChange(value: string) {
    this.siteGroupControl.setValue(value);
    this.siteGroupChange.emit(this.siteGroupControl.value);
  }

  onCurveTypeControlChange(value: CurveType) {
    this.curveTypeControl.setValue(value);
    this.setSiteCurveGroupControlValue(value);
  }

  private initSiteCurveGroupControlValue(): void {
    if (!this.driverSettingId) {
      const filteredByNetSites = this.unfilteredSiteCurveGroups.filter(
        (siteCurveGroup) => siteCurveGroup.curve_type === CurveType.NET
      );

      this.siteGroupControl.setValue(
        filteredByNetSites?.length === 1 ? filteredByNetSites[0].driver_setting_id : undefined
      );

      if (this.siteGroupControl.value) {
        this.siteGroupChange.emit(this.siteGroupControl.value);
      }
      return;
    }

    const pickedItem = this.unfilteredSiteCurveGroups.find(
      (siteCurveGroup) => siteCurveGroup.driver_setting_id === this.driverSettingId
    );

    this.siteGroupControl.setValue(pickedItem?.driver_setting_id);
  }

  private initCurveTypeControl(): void {
    if (!this.driverSettingId) {
      this.curveTypeControl.setValue(CurveType.NET);
      return;
    }

    const curveType = this.unfilteredSiteCurveGroups.find(
      (siteCurveGroup) => siteCurveGroup.driver_setting_id === this.driverSettingId
    )?.curve_type;

    this.curveTypeControl.setValue(curveType || CurveType.NET);
  }

  private setSiteCurveGroupControlValue(value: CurveType): void {
    if (this.siteGroupControl.value) {
      const siteCurveGroupName = this.unfilteredSiteCurveGroups.find(
        (siteCurveGroup) => siteCurveGroup.driver_setting_id === this.siteGroupControl.value
      )?.name;

      const siteCurveGroupId = this.unfilteredSiteCurveGroups.find(
        (siteCurveGroup) =>
          siteCurveGroup.name === siteCurveGroupName && siteCurveGroup.curve_type === value
      )?.driver_setting_id;

      this.siteGroupControl.setValue(siteCurveGroupId);
      this.siteGroupChange.emit(this.siteGroupControl.value);
    }
  }
}
