<div style="width: 600px" class="font-inter">
  <form [formGroup]="documentForm" (ngSubmit)="onUpload()">
    <div class="text-lg font-bold mb-4">Upload documents</div>
    <div class="grid grid-cols-3 gap-3 mb-4">
      <div>
        <div class="mb-2 text-sm">Document Type:</div>
        <ng-select
          placeholder="Select"
          id="documentType"
          formControlName="documentType"
          [appendTo]="'body'"
        >
          <ng-option [value]="data.value" *ngFor="let data of documentTypeList">{{
            data.label
          }}</ng-option>
        </ng-select>
      </div>
      <div>
        <div class="mb-2 text-sm">Vendor:</div>
        <ng-select placeholder="Select" id="vendor" formControlName="vendor" [appendTo]="'body'">
          <ng-option [value]="entity.value" *ngFor="let entity of vendorsList">{{
            entity.label
          }}</ng-option>
        </ng-select>
      </div>
      <div>
        <div class="mb-2 text-sm">Site:</div>
        <ng-select placeholder="Select" id="site" formControlName="site" [appendTo]="'body'">
          <ng-option [value]="entity.value" *ngFor="let entity of siteList">{{
            entity.label
          }}</ng-option>
        </ng-select>
      </div>
    </div>
    <aux-file-manager
      class="h-28 bg-aux-gray-light"
      [fetchFilesOnInit]="false"
      [pathFn]="pathFn"
      [eager]="false"
      [metadata]="metadata"
      [showSuccessOnUpload]="true"
    >
      <div class="flex items-center justify-center text-aux-gray-dark-100">
        <span inlineSVG="upload.svg" [setSVGAttributes]="{ class: 'w-12 h-12' }"></span>
        <div class="ml-3">
          <p class="text-lg">Drag & Drop Documents Here or <span class="aux-link">Browse</span></p>
        </div>
      </div>
    </aux-file-manager>

    <ng-container *ngVar="uploadedFiles$ | async as uploadedFiles">
      <div *ngIf="uploadedFiles.length" style="max-height: 25vh" class="mt-4 text-sm overflow-auto">
        <p>Uploaded files</p>
        <div
          *ngFor="let file of uploadedFiles; let i = index"
          class="flex justify-between items-center py-3"
          [ngClass]="{ 'border-t border-aux-gray-dark': i !== 0 }"
        >
          <p>{{ file.fileName }}</p>
          <span class="text-aux-error" inlineSVG="trash.svg" (click)="onRemoveFile(file)"></span>
        </div>
      </div>
    </ng-container>

    <div
      class="mt-11 pt-3 flex justify-between border-t border-aux-gray-dark"
      *ngVar="uploadedFiles$ | async as uploadedFiles"
    >
      <button (click)="ref.close()" type="button">Cancel</button>
      <button [disabled]="!uploadedFiles.length" class="btn btn--blue" type="submit">
        <span class="spinner w-6 h-6 mr-3" *ngIf="loading$ | async"></span>
        <span class="text-sm">Upload</span>
      </button>
    </div>
  </form>
</div>
