import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { Utils } from '@services/utils';
import { TrialUserQuery } from '@models/trial-users/trial-user.query';
import { BehaviorSubject } from 'rxjs';
import { GqlService, Permission, RoleType } from '@services/gql.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { groupBy } from 'lodash-es';
import { AuthService } from '@models/auth/auth.service';
import { UserModel } from '@models/trial-users/trial-user.store';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { MainQuery } from '../../../layouts/main-layout/state/main.query';

@UntilDestroy()
@Component({
  selector: 'aux-user-permissions',
  templateUrl: './user-permissions.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPermissionsComponent implements OnInit {
  permissions$ = new BehaviorSubject<{ header: string; children: Permission[] }[]>([]);

  matrixReadOnly$ = new BehaviorSubject<boolean>(true);

  matrixUsers$ = this.trialUserQuery.selectAll().pipe(
    map((users) => {
      return users
        .filter((user) => {
          // todo: remove this when we get superAdmin flag
          return user.email ? !user.email.includes('@auxili.us') : true;
        })
        .sort((x, y) => {
          return Utils.alphaNumSort(
            `${x.given_name} ${x.family_name}` as string,
            `${y.given_name} ${y.family_name}` as string
          );
        });
    })
  );

  constructor(
    private trialUserQuery: TrialUserQuery,
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private authService: AuthService,
    private trialUserService: TrialUserService
  ) {}

  ngOnInit(): void {
    this.getUser();

    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => this.gqlService.listPermissions$()),
        untilDestroyed(this)
      )
      .subscribe((x) => {
        const groupedData = groupBy(x.data, 'permission_group');

        const arr = [];

        for (const [key, val] of Object.entries(groupedData)) {
          arr.push({ header: key, children: val });
        }
        this.permissions$.next(arr);
      });
  }

  async getUser() {
    const canChangePermissions = await this.authService
      .isAuthorized$({
        roles: [RoleType.ROLE_ADMIN],
      })
      .pipe(take(1))
      .toPromise();

    this.matrixReadOnly$.next(!canChangePermissions);
  }

  async onCheckboxChange(val: any, perm: Permission, user: UserModel) {
    await this.trialUserService.updatePermission(user, perm.id || '', val ? 'E' : 'U');
  }

  async onCheckboxGroupChange(val: boolean, obj: Permission[], user: UserModel) {
    await this.trialUserService.updateGroupPermission(user, obj, val ? 'E' : 'U');
  }

  isGroupChecked(obj: Permission[], user: UserModel) {
    return obj.every((x: Permission) => user.permissions[x.id] !== 'U');
  }

  isGroupIndeterminate(obj: Permission[], user: UserModel) {
    return (
      obj.some((x: Permission) => user.permissions[x.id] !== 'U') &&
      obj.some((x: Permission) => user.permissions[x.id] === 'U')
    );
  }
}
