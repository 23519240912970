import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, getEntityType } from '@datorama/akita';
import { Specification } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface SpecificationState
  extends EntityState<
    Omit<RequireSome<Partial<Specification>, 'id'>, 'settings'> & { settings: string[] }
  > {}

export type SpecificationModel = getEntityType<SpecificationState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'specification' })
export class SpecificationStore extends EntityStore<SpecificationState> {
  constructor() {
    super({});
  }
}
