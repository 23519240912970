import { AfterViewInit, Component, forwardRef, Input, Renderer2, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as dayjs from 'dayjs';
import { OverlayService } from '@services/overlay.service';
import { BaseFormControlComponent } from '../base-form-control';
import { FormControlConstants } from '../../../constants/form-control.constants';

@Component({
  selector: 'aux-input',
  templateUrl: './input.component.html',
  styles: [
    `
      :host {
        display: block;
      }

      ::-webkit-input-placeholder {
        font-style: italic;
      }

      :-moz-placeholder {
        font-style: italic;
      }

      ::-moz-placeholder {
        font-style: italic;
      }

      :-ms-input-placeholder {
        font-style: italic;
      }
    `,
  ],
  styleUrls: ['input.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent extends BaseFormControlComponent implements AfterViewInit, OnInit {
  readonly formControlConstants = FormControlConstants;

  @Input() type = 'text';

  @Input() autocomplete = 'on';

  @Input() textArea = false;

  @Input() rows = 3;

  @Input() icon = '';

  @Input() showRequiredAsterisk = false;

  @Input() autofocus = false;

  min = '';

  constructor(private renderer: Renderer2, private overlayService: OverlayService) {
    super();
  }

  ngOnInit() {
    if (this.type === 'date') {
      this.min = this.formControlConstants.MIN_VALUE.DATE;
    }
  }

  ngAfterViewInit() {
    // This solution is implemented in this way to focus element inside ag-grid cell
    if (this.autofocus) {
      setTimeout(() => {
        const element = this.renderer.selectRootElement(`#${this.uid}`);
        element.focus();
      });
    }
  }

  handleMinDate() {
    if (this.type !== 'date') {
      return;
    }

    if (!dayjs(this.fc.value).isValid()) {
      this.fc.setValue(null);

      return;
    }

    if (dayjs(this.fc.value).isBefore(this.min)) {
      if (this.autofocus) {
        this.onChange(undefined);
        this.overlayService.error(this.formControlConstants.VALIDATION_MESSAGE.DATE);
      } else {
        this.fc.setValue(null);
        this.fc.setErrors({ date: true });
      }
    } else {
      this.fc.setErrors(null);
    }
  }

  onKeyDown({ key }: KeyboardEvent) {
    if (key === 'Enter' || (key === 'Tab' && this.autofocus)) {
      this.handleMinDate();
    }
  }

  onBlur() {
    if (this.fc.value) {
      this.fc.setValue(this.fc.value.trim());
    }

    this.handleMinDate();

    super.onBlur();
  }
}
