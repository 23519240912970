import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CanvasChart } from '@components/canvas-chart/canvas-chart.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'aux-canvas-chart',
  templateUrl: './canvas-chart.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanvasChartComponent {
  @Input() set chartOptions(data: CanvasChart) {
    this.colors = [];
    const datasets = data?.datasets?.map((x, index) => {
      if (x.borderColor) {
        this.colors.push({
          color: x.borderColor || '',
          hide: false,
          type: x.type || '',
          label: x.label || '',
          id: index,
        });
      }
      return { ...x, id: index };
    });
    if (this.colors.length === 0) {
      data?.colors.forEach((x, index) => {
        if (x.borderColor) {
          this.colors.push({
            color: x.borderColor || '',
            hide: false,
            type: data?.datasets[index].type || '',
            label: data?.datasets[index].label || '',
            id: index,
          });
        }
      });
    } else {
      this.colors = this.colors.sort((x, y) => {
        // eslint-disable-next-line no-nested-ternary
        return x.type === 'bar' && y.type === 'line'
          ? -1
          : y.type === 'bar' && x.type === 'line'
          ? 1
          : 0;
      });
    }
    this.defaultChartOptions$.next({ ...data, datasets });
  }

  colors: {
    id: number;
    color?: any;
    hide: boolean;
    type: string;
    label: string;
  }[] = [];

  defaultChartOptions$ = new BehaviorSubject<CanvasChart>({
    options: {},
    datasets: [],
    colors: [],
    legend: { bottom: true, right: false, left: false, top: false },
    labels: [],
    type: 'line',
  });

  legendAction(color: any) {
    this.colors = this.colors.map((x) => {
      if (x.color === color.color) {
        return { ...x, hide: !color.hide };
      }
      return x;
    });
    const data = this.defaultChartOptions$?.getValue().datasets?.map((x) => {
      if (x.id === color.id) {
        return { ...x, hidden: !color.hide };
      }
      return x;
    });
    this.defaultChartOptions$.next({ ...this.defaultChartOptions$?.getValue(), datasets: data });
  }
}
