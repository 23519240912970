import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { TabGroupConfig } from '@components/tab-group/tab-group.component';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { BehaviorSubject } from 'rxjs';
import { ROUTING_PATH } from '../../app-routing-path.const';

@Component({
  selector: 'aux-closing-page',
  templateUrl: './closing-page.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClosingPageComponent {
  tabs: TabGroupConfig[] = [
    {
      label: 'Month/Quarter Close',
      route: ROUTING_PATH.CLOSING.QUARTER_CLOSE,
    },
    {
      label: 'Reconciliation',
      route: ROUTING_PATH.CLOSING.RECONCILIATION,
      show: this.launchDarklyService.select$((flags) => flags.tab_budget_cash_management),
    },
  ];

  rightSideContainer = new BehaviorSubject<TemplateRef<any> | null>(null);

  constructor(private launchDarklyService: LaunchDarklyService) {}
}
