import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthQuery } from '@models/auth/auth.query';
import { AuthService } from '@models/auth/auth.service';
import { ROUTING_PATH } from '../../app-routing-path.const';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthService,
    private authQuery: AuthQuery
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuthenticated();
  }

  async isAuthenticated() {
    const isLoggedIn = await this.authQuery.isLoggedIn$.pipe(take(1)).toPromise();

    if (isLoggedIn) {
      return true;
    }

    if (await this.authService.isAuthenticated()) {
      await this.authService.setUserAttributes();
      return true;
    }

    return this.router.createUrlTree([`/${ROUTING_PATH.LOGIN}`]);
  }

  canActivateChild():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate();
  }
}
