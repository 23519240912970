import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  EntityType,
  EventType,
  GqlService,
  listOrganizationNamesQuery,
  listUserNamesWithEmailQuery,
  SortField,
  User,
  UserAuditLogAction,
  UserAuditLogCategory,
} from '@services/gql.service';
import {
  CellClickedEvent,
  Column,
  ColumnApi,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IGetRowsParams,
  ProcessCellForExportParams,
} from 'ag-grid-community';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { Utils } from '@services/utils';
import { AuthQuery } from '@models/auth/auth.query';
import {
  GetQuickFilterTextParams,
  ValueFormatterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { debounceTime, switchMap } from 'rxjs/operators';
import { EventService } from '@services/event.service';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationStore } from '@models/organization/organization.store';
import { OrganizationQuery } from '@models/organization/organization.query';
import { Router } from '@angular/router';
import { OverlayService } from '@services/overlay.service';
import { FormControl } from '@angular/forms';
import { AuditHistoryService } from './state/audit-history.service';
import { AuditHistoryCategoryComponent } from './state/actions/audit-history-category.component';
import { MainStore } from '../../layouts/main-layout/state/main.store';
import { MainQuery } from '../../layouts/main-layout/state/main.query';
import { ROUTING_PATH } from '../../app-routing-path.const';

@UntilDestroy()
@Component({
  selector: 'aux-audit-history',
  templateUrl: './audit-history.component.html',
  styles: [
    `
      ::ng-deep .audit-history-table .ag-cell.grid-cell {
        border-top-width: 0 !important;
        border-left-width: 0 !important;
        border-right-width: 0 !important;
        border-bottom-width: 1px !important;
        border-color: var(--aux-gray-dark) !important;
      }

      ::ng-deep .audit-history-table .ag-header {
        border: 1px solid var(--aux-gray-dark) !important;
        border-radius: 0 0 0 0 !important;
        border-left-width: 0 !important;
      }
      ::ng-deep .audit-history-table .ag-header-row,
      ::ng-deep .audit-history-table .ag-header-cell {
        overflow: unset;
      }
      ::ng-deep .audit-history-table .ag-header-cell-text,
      ::ng-deep .audit-history-table .ag-row {
        font-size: 1rem;
      }
      ::ng-deep .audit-history-table .ag-header-cell {
        border-left: 1px solid var(--aux-gray-dark) !important;
        text-align: center;
      }
      ::ng-deep .audit-history-table .ag-header-cell-label {
        justify-content: center;
      }
      ::ng-deep .audit-history-table .ag-icon-filter {
        width: 0 !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditHistoryComponent implements OnDestroy {
  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  selectedVendor = new FormControl('');

  selectedUser = new FormControl('');

  selectedAction = new FormControl('');

  selectedCategory = new FormControl('');

  fromDate = new FormControl('');

  toDate = new FormControl('');

  users$ = new BehaviorSubject<listUserNamesWithEmailQuery[]>([]);

  vendors$ = new BehaviorSubject<any[] | null>([]);

  category$ = new BehaviorSubject<any[]>([]);

  actions$ = new BehaviorSubject<any[]>([]);

  nameFilterValue = '';

  gridAPI?: GridApi;

  gridAPI$ = new ReplaySubject<GridApi>(1);

  userExpertIds: string[] = [];

  gridColumnApi!: ColumnApi;

  width$ = new BehaviorSubject(0);

  vendorNames = new Map<string, string>();

  processorMessage = 'This process can take up to a minute. You can navigate away from this page.';

  mapAuditCategoryStatus = new Map<UserAuditLogCategory, string>([
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_BUDGET, 'Budget'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_CHANGE_ORDER, 'Change Order'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_DRIVER, 'Drivers'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_FORECAST, 'Forecast'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_INVOICE, 'Invoice'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_MANUAL_OVERRIDE, 'Manual Override'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_BUDGET, 'Patient Budget'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_DRIVER, 'Patient Driver'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_GROUP, 'Patient Group'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_TRACKER, 'Patient Tracker'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PAYMENT_MILESTONE, 'Payment Milestone'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PURCHASE_ORDER, 'Purchase Order'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE, 'Site'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE_DRIVER, 'Site Driver'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_VENDOR, 'Vendor'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TIMELINE_EVENT, 'Period Close'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TRIAL_TIMELINE, 'Trial Timeline'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_USER, 'User'],
    [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_VENDOR_ESTIMATE, 'Vendor Estimate'],
  ]);

  mapActionStatus = new Map<UserAuditLogAction, string>([
    [
      UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_CREATED,
      'Manual Override Added',
    ],
    [
      UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_UPDATED,
      'Manual Override Modified',
    ],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_REMOVED, 'Budget Removed'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_UPLOADED, 'Budget Uploaded'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_APPROVED, 'Change Order Approved'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_CREATED, 'Change Order Created'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_DECLINED, 'Change Order Declined'],
    [
      UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_PENDING_APPROVAL,
      'Change Order Pending Approval',
    ],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_REMOVED, 'Change Order Removed'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_DRIVER_LOCKED, 'Site and Patient Drivers Locked'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_DRIVER_UNLOCKED, 'Site and Patient Drivers Unlocked'],
    [
      UserAuditLogAction.USER_AUDIT_LOG_ACTION_FORECAST_METHODOLOGY_LOCKED,
      'Forecast Methodology Locked',
    ],
    [
      UserAuditLogAction.USER_AUDIT_LOG_ACTION_FORECAST_METHODOLOGY_UNLOCKED,
      'Forecast Methodology Unlocked',
    ],
    [
      UserAuditLogAction.USER_AUDIT_LOG_ACTION_FORECAST_METHODOLOGY_UPDATED,
      'Forecast Methodology Updated',
    ],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVOICE_APPROVED, `Invoice Approved`],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVOICE_CREATED, `Invoice Created`],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVOICE_DECLINED, `Invoice Declined`],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVOICE_PENDING_APPROVAL, `Invoice Pending Approval`],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVOICE_PENDING_REVIEW, `Invoice Pending Review`],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVOICE_REMOVED, `Invoice Removed`],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVOICES_LOCKED, 'Invoices Locked'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVOICES_UNLOCKED, 'Invoices Unlocked'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_BUDGET_UPDATED, 'Patient Budget Updated'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_DRIVER_CREATED, 'Patient Curve Created'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_DRIVER_REMOVED, 'Patient Curve Removed'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_DRIVER_UPDATED, 'Patient Curve Updated'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_GROUP_ADDED, 'Patient Group Added'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_GROUP_REMOVED, 'Patient Group Removed'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_GROUP_UPDATED, 'Patient Group Updated'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_TRACKER_LOCKED, 'Patient Tracker Locked'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_TRACKER_UNLOCKED, 'Patient Tracker Unlocked'],
    [
      UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_TRACKER_UPDATED,
      'Patient Tracker Data Updated',
    ],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PAYMENT_MILESTONE_ADDED, 'Payment Milestone Added'],
    [
      UserAuditLogAction.USER_AUDIT_LOG_ACTION_PAYMENT_MILESTONE_REMOVED,
      'Payment Milestone Removed',
    ],
    [
      UserAuditLogAction.USER_AUDIT_LOG_ACTION_PAYMENT_MILESTONE_UPDATED,
      'Payment Milestone Updated',
    ],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PURCHASE_ORDER_ADDED, 'Purchase Order Created'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PURCHASE_ORDER_REMOVED, 'Purchase Order Removed'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PURCHASE_ORDER_UPDATED, 'Purchase Order Updated'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SCENARIO_BUDGET_REMOVED, 'Scenario Budget Removed'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SCENARIO_BUDGET_UPLOAD, 'Scenario Budget Uploaded'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_ADDED, 'Site Added'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_UPDATED, 'Site Budget Updated'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_DRIVER_UPDATED, 'Site Curve Updated'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_REMOVED, 'Site Removed'],
    [
      UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSE_LOCKED,
      'Open Month Adjustments Locked',
    ],
    [
      UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSE_UNLOCKED,
      'Open Month Adjustments Unlocked',
    ],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_QUARTER_CLOSED, `Quarter Closed`],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_LOCKED, 'Trial Timeline Locked'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_UNLOCKED, 'Trial Timeline Unlocked'],
    [
      UserAuditLogAction.USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_MILESTONE_ADDED,
      'Trial Timeline Milestone Added',
    ],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSED, 'Month Closed'],
    [
      UserAuditLogAction.USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_MILESTONE_REMOVED,
      'Trial Timeline Milestone Removed',
    ],
    [
      UserAuditLogAction.USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_MILESTONE_UPDATED,
      'Trial Timeline Milestone Updated',
    ],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_USER_ADDED, 'User Added'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_USER_REMOVED, 'User Removed'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_ADDED, 'Vendor Added'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATE_ADDED, 'Vendor Estimate Added'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATE_REMOVED, 'Vendor Estimate Removed'],
    [UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_REMOVED, 'Vendor Removed'],
  ]);

  gridOptions$ = new BehaviorSubject({
    defaultColDef: {
      sortable: true,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      minWidth: 40,
      headerClass: ['bg-aux-dark-light', 'justify-center'],
      cellClass: 'grid-cell',
    },
    rowModelType: 'infinite',
    rowHeight: 50,
    groupDefaultExpanded: -1,
    overlayNoRowsTemplate:
      '<div class="text-xl">No results found, please adjust your filters</div>',
    columnDefs: [
      {
        headerName: 'Date',
        field: 'date',
        filter: 'agDateColumnFilter',
        maxWidth: 120,
        valueFormatter: (x) => Utils.dateFormatter(x.value),
        getQuickFilterText: (params: GetQuickFilterTextParams) => Utils.dateFormatter(params.value),
        sort: 'desc',
        sortable: false,
        filterParams: {
          readOnly: true,
          comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            if (cellValue < filterLocalDateAtMidnight) {
              return -1;
            }
            if (cellValue > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: (params: ICellRendererParams) => {
          if (!params?.value) {
            this.gridAPI?.hideOverlay();
            return '<div class="h-3 m-auto spinner w-3"></div>';
          }
          return params.valueFormatted;
        },
      },
      {
        headerName: 'Time',
        field: 'create_date',
        sort: 'desc',
        sortable: false,
        maxWidth: 110,
        valueFormatter: (x) => `${Utils.timeFormatter(x.value)} UTC`,
        getQuickFilterText: (params: GetQuickFilterTextParams) =>
          `${Utils.timeFormatter(params.value)} UTC`,
        cellRenderer: (params: ICellRendererParams) => {
          if (!params?.value) {
            return null;
          }
          return params.valueFormatted;
        },
      },
      {
        headerName: 'Category',
        sortable: false,
        field: 'category',
        cellClass: 'grid-cell justify-center ',
        maxWidth: 170,
        minWidth: 170,
        cellRenderer: AuditHistoryCategoryComponent,
      },
      {
        headerName: 'Action',
        field: 'action_text',
        minWidth: 300,
        tooltipField: 'action_text',
        cellClass: 'grid-cell underline aux-link cursor-pointer',
        onCellClicked: (event: CellClickedEvent) => this.goToDetail(event),
      },
      {
        field: 'action',
        hide: true,
      },
      {
        headerName: 'Vendor',
        field: 'vendor_id',
        valueFormatter: (x) => this.vendorNames.get(x.value) || Utils.zeroHyphen,
        getQuickFilterText: (params: GetQuickFilterTextParams) =>
          this.vendorNames.get(params.value) || null,
        minWidth: 170,
        cellRenderer: (params: ICellRendererParams) => {
          if (!params?.data?.id) {
            return null;
          }
          return params.valueFormatted;
        },
      },
      {
        headerName: 'User',
        field: 'created_by',

        valueFormatter: (x: ValueFormatterParams) => {
          return this.userFormatter(x.value);
        },
        getQuickFilterText: (params: GetQuickFilterTextParams) => this.userFormatter(params.value),
        minWidth: 170,
        cellRenderer: (params: ICellRendererParams) => {
          if (!params?.value) {
            return null;
          }
          return params.valueFormatted;
        },
      },
      // { headerName: 'Department', minWidth: 170 },
    ],
    excelStyles: [
      {
        id: 'header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { patternColor: '#094673', color: '#094673', pattern: 'Solid' },
      },
      {
        id: 'text-aux-error',
        font: { color: '#D73C37' },
      },
      {
        id: 'text-aux-green',
        font: { color: '#437F7F' },
      },
      {
        id: 'first_row',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
      },
    ],
  } as GridOptions);

  excelOptions = {
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Audit History',
    fileName: 'auxilius-audit-history.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.id;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'distribution_month':
          return 225;
        default:
          return 225;
      }
    },
    processCellCallback: (params: ProcessCellForExportParams): string => {
      if (params.column.getColId() === 'id') {
        return this.organizationQuery.getEntity(params.value)?.name || '';
      }
      const colId = params.column.getColId();
      const mapColFormatterById = new Map<string, () => string>([
        ['date', () => Utils.dateFormatter(params.value)],
        ['create_date', () => Utils.timeFormatter(params.value)],
        ['category', () => this.mapAuditCategoryStatus.get(params.value) || ''],
        ['action', () => this.mapActionStatus.get(params.value) || ''],
        ['vendor_id', () => this.vendorNames.get(params.value) || ''],
        ['created_by', () => this.userFormatter(params.value) || ''],
      ]);

      const getFormatterCol = mapColFormatterById.get(colId);

      return getFormatterCol ? getFormatterCol() : params.value;
    },
    excelStyles: [
      {
        id: 'header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { patternColor: '#094673', color: '#094673', pattern: 'Solid' },
      },
      {
        id: 'text-aux-error',
        font: { color: '#D73C37' },
      },
      {
        id: 'text-aux-green',
        font: { color: '#437F7F' },
      },
      {
        id: 'cellPercent',
        font: { fontName: 'Arial', size: 11 },
      },
      {
        id: 'first_row',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
      },
    ],
  } as ExcelExportParams;

  loading$ = new BehaviorSubject(true);

  loadedOnce$ = new BehaviorSubject(false);

  _searchSubject: Subject<string> = new Subject();

  constructor(
    private auditHistoryService: AuditHistoryService,
    private organizationStore: OrganizationStore,
    public organizationQuery: OrganizationQuery,
    private trialUserService: TrialUserService,
    private mainQuery: MainQuery,
    private authQuery: AuthQuery,
    private eventService: EventService,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private vendorsService: OrganizationService,
    private router: Router,
    private mainStore: MainStore
  ) {
    this.mainStore.update({ fullPage: true });
    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          this.loading$.next(true);
          return combineLatest([
            this.trialUserService.listUserNamesWithEmail(),
            this.vendorsService.listOrganizationNames(),
            this.vendorsService.get(),
          ]);
        }),
        untilDestroyed(this)
      )
      .subscribe(([_users, _vendors]) => {
        this.resetFilter();
        const listAction: any[] = [];
        const listCategory: any[] = [];
        this.mapActionStatus.forEach((value, i) => {
          listAction.push({ id: i, name: value });
        });
        this.mapAuditCategoryStatus.forEach((value, i) => {
          listCategory.push({ id: i, name: value });
        });
        _vendors?.data?.forEach((organization: listOrganizationNamesQuery) => {
          this.vendorNames.set(organization.id, organization.name);
        });

        this.actions$.next(listAction.sort((x, y) => Utils.alphaNumSort(x.name, y.name)));
        this.category$.next(listCategory.sort((x, y) => Utils.alphaNumSort(x.name, y.name)));
        this.organizationStore.setActive(null);
        this.userExpertIds = [];
        _users?.data?.forEach((user: listUserNamesWithEmailQuery) => {
          this.users.set(user.sub, user);
        });
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length > 1) {
          this.organizationStore.setActive(null);
        }
        const sortVendors = vendors?.sort((x, y) =>
          Utils.alphaNumSort(x.name as string, y.name as string)
        );
        this.vendors$.next(sortVendors || null);

        if (_users.data) {
          let isExternal = false;
          const userData = _users.data.filter((x) => {
            if (this.userFormatter(x.sub) === 'Auxilius Expert') {
              isExternal = true;
              this.userExpertIds.push(x.sub);
            }
            return this.userFormatter(x.sub) !== 'Auxilius Expert';
          });
          const sortUsers = userData.sort((x, y) =>
            Utils.alphaNumSort(
              `${x.given_name} ${x.family_name}`,
              `${y.given_name} ${y.family_name}`
            )
          );
          if (isExternal) {
            sortUsers.unshift({
              __typename: 'User',
              sub: 'Auxilius',
              given_name: 'Auxilius',
              family_name: 'Expert',
              email: '',
            });
          }

          this.users$.next(sortUsers as listUserNamesWithEmailQuery[]);
        }

        this.loadedOnce$.next(true);
        this.loading$.next(false);
      });
    this._searchSubject.pipe(debounceTime(500)).subscribe(() => {
      this.gridAPI?.onFilterChanged();
    });

    this.eventService
      .select$(EventType.REFRESH_USER_AUDIT_LOG)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.gridAPI?.onFilterChanged();
        this.loading$.next(false);
      });
  }

  ngOnDestroy() {
    this.resetFilter();
    this.mainStore.update({ fullPage: false });
  }

  async triggerFetchAuditHistory() {
    this.loading$.next(true);
    await this.gqlService
      .processEvent$({
        type: EventType.USER_AUDIT_LOG,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getValue().trialKey,
        payload: JSON.stringify({}),
      })
      .toPromise()
      .finally(() => {
        setTimeout(() => {
          // if we are still showing the loading spinner after 5 minutes (300000 milliseconds),
          // set loading to false. If the user audit log process fails for whatever reason,
          // we don't want this to spin forever
          if (this.loading$.getValue()) {
            this.loading$.next(false);
          }
        }, 300000);
      });
  }

  onDataRendered(e: any) {
    this.gridAPI = e.api;
    this.gridAPI$.next(e.api);
    const { columnApi } = e;
    const allColumnIds: string[] = [];
    columnApi.getAllColumns().forEach((column: Column) => {
      allColumnIds.push(column.getColId());
    });
    columnApi.autoSizeColumns(allColumnIds, false);
    this.gridOptions$.getValue()?.api?.sizeColumnsToFit();

    this.refreshGridWidth();
  }

  getTextFieldFilterModel(v: any) {
    let t = v;
    if (Array.isArray(v)) {
      const arrayIds = [];
      for (let i = 0; i < v.length; i++) {
        if (v[i] === 'Auxilius') {
          arrayIds.push(...this.userExpertIds);
        } else {
          arrayIds.push(v[i]);
        }
      }
      if (arrayIds.length > 1) {
        return {
          filterType: 'set',
          values: arrayIds,
        };
      }
      t = v[0] || '';
    }
    if (t) {
      return {
        filterType: 'text',
        type: 'equals',
        filter: t,
      };
    }
    return {};
  }

  getDateFieldFilterModel(from: any, to: any) {
    const dt1 = from;
    let dt2 = '';
    if (to) {
      const d = new Date(to);
      d.setDate(d.getDate() + 1);
      [dt2] = d.toISOString().split('T');
    }
    if (dt1 && dt2) {
      return {
        filterType: 'date',
        type: 'inRange',
        dateFrom: dt1,
        dateTo: dt2,
      };
    }
    if (dt1) {
      return {
        filterType: 'date',
        type: 'greaterThanOrEqual',
        dateFrom: dt1,
      };
    }
    if (dt2) {
      return {
        filterType: 'date',
        type: 'lessThan',
        dateFrom: dt2,
      };
    }
    return {};
  }

  getFilterModel(): any {
    const filterModel: Record<string, any> = {};
    const check = (fc: FormControl, field: string) => {
      if (fc.value) {
        if (Array.isArray(fc.value) && fc.value.some((x: any) => !x)) {
          fc.setValue('');
        }
        filterModel[field] = this.getTextFieldFilterModel(fc.value);
      }
    };
    check(this.selectedUser, 'created_by');
    check(this.selectedVendor, 'vendor_id');
    check(this.selectedCategory, 'category');
    check(this.selectedAction, 'action');
    if (this.fromDate.value || this.toDate.value) {
      filterModel.create_date = this.getDateFieldFilterModel(
        this.fromDate.value,
        this.toDate.value
      );
    }
    return filterModel;
  }

  onGridReady({ api }: GridReadyEvent) {
    api.setDatasource({
      getRows: (params: IGetRowsParams) => {
        let sortingById = false;
        const sortModel: SortField[] = params.sortModel.map((smi: { colId: any; sort: string }) => {
          const sortField = {
            field: smi.colId,
            descending: smi.sort === 'desc',
          } as SortField;
          if (sortField.field === 'id') {
            sortingById = true;
          }
          return sortField;
        });
        if (sortModel.length && !sortingById) {
          sortModel.push({ field: 'id' });
        }
        const filterModel = this.getFilterModel();
        const input = {
          search_text: this.nameFilterValue,
          filter_model: JSON.stringify(filterModel),
          sort_model: sortModel,
          start_row: params.startRow,
          end_row: params.endRow,
        };
        this.gqlService.fetchUserAuditLogs$(input).subscribe(({ data, success, errors }) => {
          if (data && success) {
            const rows = data?.items?.map((x) => {
              return { ...x, date: Utils.dateParse(x.create_date) };
            });
            if (rows?.length > 0) {
              params.successCallback(rows, data.last_row);
              this.gridAPI?.hideOverlay();
            } else {
              params.successCallback([], 0);
              this.gridAPI?.showNoRowsOverlay();
            }
          } else {
            params.failCallback();
            this.overlayService.error(errors);
          }
        });
      },
    });
    api.sizeColumnsToFit();
    this.refreshGridWidth();
  }

  refreshGridWidth() {
    const div = document.querySelector('.ag-header-container') as HTMLDivElement;
    if (div) {
      const paddingOffset = 2;
      this.width$.next(div.getBoundingClientRect().width + paddingOffset);
    } else {
      this.width$.next(0);
    }
  }

  goToDetail(item: any) {
    const mapBudgetStatus = new Map<UserAuditLogCategory, string>([
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_BUDGET,
        `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.INDEX}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_CHANGE_ORDER,
        `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_DRIVER,
        `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_INVOICE,
        `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_BUDGET,
        `/${ROUTING_PATH.PATIENTS.INDEX}/${ROUTING_PATH.PATIENTS.PATIENT_BUDGET.INDEX}/${ROUTING_PATH.PATIENTS.PATIENT_BUDGET.VISIT_COSTS}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_DRIVER,
        `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_GROUP,
        `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.GROUPS}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_FORECAST,
        `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_MANUAL_OVERRIDE,
        `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_TRACKER,
        `/${ROUTING_PATH.PATIENTS.INDEX}/${ROUTING_PATH.PATIENTS.PATIENT_TRACKER}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PAYMENT_MILESTONE,
        `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_MILESTONES}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PURCHASE_ORDER,
        `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PURCHASE_ORDERS}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE,
        `/${ROUTING_PATH.SITES.INDEX}/${ROUTING_PATH.SITES.TRIAL_SITES}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE_BUDGET,
        `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE_DRIVER,
        `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TIMELINE_EVENT,
        `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.TRIAL_TIMELINE}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TRIAL_TIMELINE,
        `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.TRIAL_TIMELINE}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TRIAL_TIMELINE_MILESTONE,
        `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_MILESTONES}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_USER,
        `/${ROUTING_PATH.SETTINGS.INDEX}/${ROUTING_PATH.SETTINGS.USERS}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_VENDOR,
        `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.VENDORS}`,
      ],
      [
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_VENDOR_ESTIMATE,
        `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`,
      ],
    ]);
    const id = item.data?.id;
    if (id) {
      console.log(`category ${item.data.category}`);
      if (
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_BUDGET === item.data.category ||
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_FORECAST === item.data.category ||
        UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_VENDOR_ESTIMATE === item.data.category
      ) {
        this.organizationStore.setActive(item.data.vendor_id);
      } else if (
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_QUARTER_CLOSED ===
          item.data.action ||
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSED === item.data.action ||
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSE_LOCKED ===
          item.data.action ||
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSE_UNLOCKED ===
          item.data.action
      ) {
        this.router.navigateByUrl(
          `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`
        );
        return;
      } else if (
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_DRIVER_LOCKED === item.data.action ||
        UserAuditLogAction.USER_AUDIT_LOG_ACTION_DRIVER_UNLOCKED === item.data.action
      ) {
        this.router.navigateByUrl(
          `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}`
        );
        return;
      }
      this.router.navigateByUrl(mapBudgetStatus.get(item.data.category) as string);
    }
  }

  filterChanges() {
    this.gridAPI?.onFilterChanged();
  }

  resetFilter() {
    this.selectedCategory.setValue('');
    this.selectedVendor.setValue('');
    this.toDate.setValue('');
    this.fromDate.setValue('');
    this.selectedUser.setValue('');
    this.selectedAction.setValue('');
    this.fromDate.setValue('');
    this.toDate.setValue('');
    this.nameFilterValue = '';
    this.gridAPI?.onFilterChanged();
  }

  getDynamicExcelParams = (): ExcelExportParams => {
    if (!this.gridAPI) {
      return {};
    }

    const name = this.mainQuery.getSelectedTrial()?.short_name;
    return {
      ...this.excelOptions,
      columnKeys: ['date', 'create_date', 'category', 'action_text', 'vendor_id', 'created_by'],
      prependContent: [
        [
          {
            data: { value: `Trial: ${name}`, type: 'String' },
            mergeAcross: 5,
            styleId: 'first_row',
          },
        ],
      ],
    } as ExcelExportParams;
  };

  updateSearch(searchTextValue: any) {
    if (searchTextValue.value.length > 2 || searchTextValue.value.length === 0) {
      this._searchSubject.next(searchTextValue.value.key);
    }
  }

  autoSize() {
    this.gridOptions$.getValue()?.api?.sizeColumnsToFit();
  }

  userFormatter(sub: string | undefined) {
    const user = this.users.get(sub || '');
    if (user) {
      const isUserAuxAdmin = user.email.includes('@auxili.us');
      if (this.authQuery.isAuxAdmin() || !isUserAuxAdmin) {
        return `${user.given_name} ${user.family_name}`;
      }
      return 'Auxilius Expert';
    }
    return Utils.zeroHyphen;
  }
}
