import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  Type,
  TemplateRef,
} from '@angular/core';
import { CdkPortalOutlet, PortalOutlet } from '@angular/cdk/portal';
import { UntilDestroy } from '@ngneat/until-destroy';
import { animate, style, transition, trigger } from '@angular/animations';
import { CustomOverlayRef } from './custom-overlay-ref';

@UntilDestroy()
@Component({
  selector: 'aux-overlay',
  templateUrl: './overlay.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('enterLeave', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.95)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: 0, transform: 'scale(0.95)' })),
      ]),
    ]),
  ],
})
export class OverlayComponent implements OnInit {
  @ViewChild(CdkPortalOutlet, { static: false }) portalOutlet: PortalOutlet | undefined = undefined;

  contentType: 'template' | 'string' | 'component' | undefined;

  headerType: 'custom' | 'default' = 'default';

  content: string | TemplateRef<any> | Type<any> | null = null;

  context: null | { [k: string]: any } = null;

  customHeader: Type<any> | null = null;

  closeButton = true;

  constructor(public ref: CustomOverlayRef) {
    if (this.ref.data?.customHeader) {
      this.customHeader = this.ref.data.customHeader;
      this.headerType = 'custom';
    }
  }

  ngOnInit() {
    this.content = this.ref.content || null;
    this.closeButton = this.ref.closeButton;
    if (typeof this.content === 'string') {
      this.contentType = 'string';
    } else if (this.content instanceof TemplateRef) {
      this.contentType = 'template';
      this.context = {
        close: this.ref.close.bind(this.ref),
      };
    } else {
      this.contentType = 'component';
    }
  }

  close() {
    if (this.closeButton) {
      this.ref.close();
    }
  }
}
