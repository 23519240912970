import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Utils } from '@services/utils';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationStore } from '@models/organization/organization.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import {
  ColDef,
  ColGroupDef,
  Column,
  ExcelCell,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowNode,
  RowStyle,
  ValueFormatterParams,
} from 'ag-grid-community';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { first, groupBy, last, merge } from 'lodash-es';
import { Router } from '@angular/router';
import { ExpenseType, GqlService, InvoiceStatus, PeriodType } from '@services/gql.service';
import { InvoiceQuery } from 'src/app/pages/vendor-payments-page/tabs/invoices/state/invoice.query';
import { InvoiceService } from 'src/app/pages/vendor-payments-page/tabs/invoices/state/invoice.service';
import { OverlayService } from '@services/overlay.service';
import { InvoiceAmounts } from 'src/app/pages/vendor-payments-page/tabs/invoices/state/invoice.model';

import { ExcelButtonVariant } from '@components/export-excel-button/export-excel-button.component';
import { BudgetQuery } from '../../../budget-page/tabs/budget/state/budget.query';
import { BudgetService } from '../../../budget-page/tabs/budget/state/budget.service';
import {
  BudgetState,
  createInitialState,
} from '../../../budget-page/tabs/budget/state/budget.model';
import { BudgetStore } from '../../../budget-page/tabs/budget/state/budget.store';
import { ClosingPageComponent } from '../../closing-page.component';
import { ROUTING_PATH } from '../../../../app-routing-path.const';

@UntilDestroy()
@Component({
  selector: 'aux-reconciliation',
  templateUrl: './reconciliation.component.html',
  styleUrls: ['./reconciliation.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReconciliationComponent implements AfterViewInit {
  exportButtonVariant = ExcelButtonVariant.FILLED;

  defaultColumns: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Vendor',
      field: 'vendor_name',
      rowGroup: true,
      hide: true,
    },
    {
      headerName: 'Vendor',
      field: 'vendor_id',
      hide: true,
    },
    {
      headerName: 'Cost Category',
      field: 'cost_category',
      hide: true,
    },
  ];

  gridAPI: GridApi | undefined;

  gridAPI$: ReplaySubject<GridApi> = new ReplaySubject<GridApi>(1);

  gridOptions$ = new BehaviorSubject<GridOptions | null>(null);

  selectedVendor = new FormControl('');

  gridData$ = new BehaviorSubject<any[]>([]);

  filteredGridData$ = combineLatest([this.gridData$, this.organizationQuery.selectActiveId()]).pipe(
    map(([data, vendorId]) => {
      if (!vendorId) {
        return data;
      }

      return data.filter((row) => row.vendor_id === vendorId);
    })
  );

  startingMonths$ = this.budgetQuery.select().pipe(
    map(({ header_data, budget_info }) => {
      const months: { label: string; value: string }[] = [];

      const current_month = first(budget_info)?.current_month;

      if (!current_month) {
        return months;
      }

      const current_month_as_date = Utils.dateParse(current_month);

      header_data.forEach((data) => {
        if (data.expense_type == null && data.group_name === 'Timeline') {
          for (const value of data.date_headers) {
            // day doesn't matter for this case so in order to pass timezone issues I'm just selecting 3 as a day.
            // 03/JAN/2021
            const parseableDate = `03/${value.replace('-', '/')}`;

            // January
            const getMonthLabel = (date: Date) => {
              return Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(date);
            };

            const date = new Date(parseableDate);

            // if the timeline month passes the current month don't add this and next months as options
            if ((current_month_as_date.getTime() - date.getTime()) / (1000 * 60 * 60 * 24) < -5) {
              break;
            }

            months.push({
              label: getMonthLabel(date),
              value,
            });
          }
        }
      });

      // if (!months.length) {
      // todo no timeline error?
      // }

      setTimeout(() => {
        this.selectedStartingMonth.setValue(last(months)?.value);
      }, 0);

      return months;
    })
  );

  selectedStartingMonth = new FormControl('');

  excelOptions = {
    sheetName: 'Budget',
    fileName: 'auxilius-budget.xlsx',
    shouldRowBeSkipped(params) {
      return !params?.node?.data?.cost_category;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'vendor_name':
          return 280;
        case 'activity_name':
          return 490;
        default:
          return 105;
      }
    },
  } as ExcelExportParams;

  showAnalyticsSection$: Observable<boolean>;

  @ViewChildren('reconciliationExport') reconciliationExport!: QueryList<TemplateRef<any>>;

  invoices$ = new BehaviorSubject<
    Record<
      string,
      {
        id: string;
        invoice_date: string;
        organization_id: string;
        expense_amounts: InvoiceAmounts;
      }[]
    >
  >({});

  restartLoading$ = new BehaviorSubject(false);

  invoicesLoading$ = new BehaviorSubject(false);

  inMonthBudgetDataLoading$ = new BehaviorSubject(false);

  inMonthBudgetData$ = new BehaviorSubject<Partial<BudgetState>>(createInitialState());

  width$ = new BehaviorSubject(0);

  showGrid$ = new BehaviorSubject(true);

  invoice_statuses: Array<InvoiceStatus> = [
    InvoiceStatus.STATUS_APPROVED,
    InvoiceStatus.STATUS_IN_QUEUE,
    InvoiceStatus.STATUS_PENDING_REVIEW,
    InvoiceStatus.STATUS_PENDING_APPROVAL,
  ];

  loading$ = combineLatest([
    this.organizationQuery.selectLoading(),
    this.budgetQuery.selectLoading(),
    this.invoicesLoading$,
    this.restartLoading$,
    this.inMonthBudgetDataLoading$,
  ]).pipe(map((arr) => arr.some((x) => x)));

  constructor(
    public budgetQuery: BudgetQuery,
    private organizationStore: OrganizationStore,
    public organizationQuery: OrganizationQuery,
    private organizationService: OrganizationService,
    private launchDarklyService: LaunchDarklyService,
    private closingPageComponent: ClosingPageComponent,
    private router: Router,
    private budgetService: BudgetService,
    private budgetStore: BudgetStore,
    private invoiceQuery: InvoiceQuery,
    private invoiceService: InvoiceService,
    private gqlService: GqlService,
    private overlayService: OverlayService
  ) {
    this.budgetStore.update(createInitialState());

    this.showAnalyticsSection$ = launchDarklyService.select$(
      (flags) => flags.section_reconciliation_analytics
    );

    this.organizationService
      .get()
      .pipe(
        switchMap(() => {
          this.invoicesLoading$.next(true);
          this.inMonthBudgetDataLoading$.next(true);
          return combineLatest([
            this.budgetService.getInMonthBudgetDataForReconciliation(true).pipe(
              tap((inMonthBudgetData) => {
                this.inMonthBudgetData$.next(inMonthBudgetData);
                this.inMonthBudgetDataLoading$.next(false);
              })
            ),
            this.budgetService.getBudgetData(PeriodType.PERIOD_MONTH, true),
            this.gqlService
              .listInvoicesForReconciliation$({ invoice_statuses: this.invoice_statuses })
              .pipe(
                tap(({ success, data, errors }) => {
                  this.invoices$.next(
                    groupBy(
                      (data || [])
                        .filter((invoice) => invoice.invoice_date)
                        .map(({ id, invoice_date, organization, expense_amounts }) => {
                          return {
                            id,
                            invoice_date: invoice_date || '',
                            organization_id: organization.id,
                            expense_amounts: this.invoiceService.mapExpenseAmountToObject(
                              expense_amounts
                            ),
                          };
                        }),
                      'organization_id'
                    )
                  );

                  if (!(success && data)) {
                    this.overlayService.error(errors);
                  }

                  this.invoicesLoading$.next(false);
                })
              ),
          ]);
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length === 1) {
          this.organizationStore.setActive(vendors[0].id);
          this.selectedVendor.setValue(vendors[0].id);
        } else {
          // reset any older selected vendors.
          this.organizationStore.setActive(null);
          this.selectedVendor.setValue('');
        }
      });

    combineLatest([
      this.selectedStartingMonth.valueChanges.pipe(
        startWith(this.selectedStartingMonth.value as string)
      ),
      this.budgetQuery.select(),
      this.invoices$,
      this.startingMonths$,
      this.inMonthBudgetData$,
    ])
      .pipe(
        map(
          ([
            // startingMonth: MAR-2021
            startingMonth,
            { budget_info, budget_data },
            groupedInvoices,
            startingMonthOptions,
            inMonthBudgetData,
          ]) => {
            this.restartLoading$.next(true);
            // MAR-2021 --> 2021-03-01
            const convertFormattedDateToDate = (str: string) => {
              const d = new Date(`03/${str.replace('-', '/')}`);
              const month = (d.getMonth() + 1).toString().padStart(2, '0');
              const year = d.getFullYear();
              return `${year}-${month}-01`;
            };

            const index = startingMonthOptions.findIndex((x) => x.value === startingMonth);

            const current_month = budget_info[0]?.current_month;

            if (!startingMonthOptions.length || index === -1 || !current_month) {
              return [];
            }

            const options = startingMonthOptions.map((v) => {
              const iso = convertFormattedDateToDate(v.value);
              return {
                ...v,
                iso,
                month: iso.slice(0, 7),
              };
            });

            // const { startIndex, value: months } = this.takeLeft(options, index);
            const { value: months } = this.takeLeft(options, index);

            // const columnsToBeUsedInCalculatingEndingBalance = options.slice(0, startIndex);

            // [JAN, FEB, MAR]
            const monthLabels = months.map(({ value }) => value.split('-')[0]);

            // const startingBalanceColumn = {
            //   headerName: 'STARTING BALANCE',
            //   headerClass: 'ag-header-align-center',
            //   children: [
            //     {
            //       headerName: '',
            //       width: 100,
            //       minWidth: 100,
            //       field: 'startingBalance',
            //       tooltipField: 'startingBalance',
            //       suppressSizeToFit: true,
            //       cellClass: ['ag-cell-align-right'],
            //       aggFunc: 'sum',
            //       valueFormatter: Utils.agCurrencyFormatter,
            //     },
            //   ],
            // };

            const endingBalanceColumn = {
              headerName: 'ENDING BALANCE',
              headerClass: 'ag-header-align-center balance',
              field: 'endingBalance',
              valueFormatter: Utils.agCurrencyFormatter,
              children: [
                {
                  headerName: ' ',
                  field: 'endingBalance',
                  tooltipField: 'endingBalance',
                  width: 100,
                  minWidth: 100,
                  suppressSizeToFit: true,
                  aggFunc: 'sum',
                  valueFormatter: Utils.agCurrencyFormatterAccounting,
                  cellClass: ['ag-cell-align-right', 'cost'],
                },
              ],
            };

            const columns = [
              // startingBalanceColumn,
              {
                headerName: 'WORK PERFORMED ESTIMATE',
                headerClass: 'ag-header-align-center in-month-accrual',
                maxWidth: 240,
                minWidth: 180,
                children: monthLabels.map((month) => {
                  return {
                    headerName: month,
                    field: `WPE::${month}`,
                    headerClass: 'ag-header-align-center',
                    suppressSizeToFit: true,
                    width: 100,
                    minWidth: 100,
                    aggFunc: 'sum',
                    valueFormatter: Utils.agCurrencyFormatter,
                    cellClass: ['ag-cell-align-right', 'cost'],
                  };
                }),
              },
              {
                headerName: 'INVOICES RECEIVED',
                headerClass: 'ag-header-align-center invoiced',
                maxWidth: 240,
                children: monthLabels.map((month) => {
                  return {
                    headerName: month,
                    field: `INVOICE::${month}`,
                    headerClass: 'ag-header-align-center',
                    width: 100,
                    minWidth: 100,
                    aggFunc: 'sum',
                    suppressSizeToFit: true,
                    valueFormatter: Utils.agCurrencyFormatter,
                    cellClass: ['ag-cell-align-right', 'cost'],
                  };
                }),
              },
              {
                headerName: 'NET CHANGE IN ACCRUALS / (PREPAID)',
                headerClass: 'ag-header-align-center in-month-accrual-net',
                maxWidth: 240,
                minWidth: 180,
                children: monthLabels.map((month) => {
                  return {
                    headerName: month,
                    field: `NIMA::${month}`,
                    headerClass: 'ag-header-align-center',
                    width: 100,
                    minWidth: 100,
                    aggFunc: 'sum',
                    suppressSizeToFit: true,
                    valueFormatter: Utils.agCurrencyFormatterAccounting,
                    cellClass: ['ag-cell-align-right', 'cost'],
                  };
                }),
              },
              endingBalanceColumn,
            ];
            this.showGrid$.next(false);
            this.gridOptions$.next({
              ...this.gridOptions(),
              columnDefs: [...this.defaultColumns, ...columns],
              excelStyles: [...Utils.auxExcelStyle],
            });

            return budget_data.map((row, row_index) => {
              // const startingBalance = columnsToBeUsedInCalculatingEndingBalance.reduce(
              //   (acc, { value }) => {
              //     return acc + (row[`${ExpenseType.EXPENSE_WP}::${value}`] || 0);
              //   },
              //   0
              // );

              const wpes = months.reduce((acc, { value, iso }, i) => {
                let amount = 0;
                if (iso === current_month) {
                  amount =
                    (inMonthBudgetData.budget_data || [])[row_index]?.total_monthly_accrual || 0;
                } else {
                  amount = row[`${ExpenseType.EXPENSE_WP}::${value}`] || 0;
                }

                acc[`WPE::${monthLabels[i]}`] = amount;
                return acc;
              }, {} as Record<string, number>);

              const invoices = groupedInvoices[row.vendor_id || ''] || [];

              const invoiceAmounts = months.reduce((acc, { month }, i) => {
                const amount = invoices
                  .filter((invoice) => month === invoice.invoice_date.slice(0, 7))
                  .reduce((i_acc, val) => {
                    if (row.cost_category === 'Services') {
                      return i_acc + val.expense_amounts.services_total.value;
                    }
                    if (row.cost_category === 'Discount') {
                      return i_acc + val.expense_amounts.discount_total.value;
                    }
                    if (row.cost_category === 'Investigator') {
                      return i_acc + val.expense_amounts.investigator_total.value;
                    }
                    if (row.cost_category === 'Pass-through') {
                      return i_acc + val.expense_amounts.pass_thru_total.value;
                    }
                    return i_acc;
                  }, 0);

                acc[`INVOICE::${monthLabels[i]}`] = amount;
                return acc;
              }, {} as Record<string, number>);

              const nima = months.reduce((acc, _, i) => {
                acc[`NIMA::${monthLabels[i]}`] =
                  (wpes[`WPE::${monthLabels[i]}`] || 0) -
                  (invoiceAmounts[`INVOICE::${monthLabels[i]}`] || 0);
                return acc;
              }, {} as Record<string, number>);

              const endingBalance =
                // startingBalance +
                Object.keys(nima).reduce((acc, val) => {
                  return acc + nima[val];
                }, 0);

              return {
                ...row,
                // startingBalance,
                ...wpes,
                ...invoiceAmounts,
                ...nima,
                endingBalance,
              };
            });
          }
        ),
        untilDestroyed(this)
      )
      .subscribe((arr) => {
        this.gridData$.next(arr);
        this.width$.next(0);
        setTimeout(() => {
          this.showGrid$.next(true);
          this.restartLoading$.next(false);
          this.refreshGridWidth();
        }, 0);
      });
  }

  gridOptions: () => GridOptions = () => ({
    defaultColDef: {
      sortable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    autoGroupColumnDef: {
      headerName: 'VENDOR / COST TYPE',
      maxWidth: 270,
      minWidth: 120,
      field: 'activity_name',
      colId: 'activity_name',
      tooltipField: 'activity_name',
      cellRendererParams: {
        suppressCount: true,
      },
    },
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    suppressCellFocus: true,
    groupDefaultExpanded: 1,
    suppressColumnVirtualisation: true,
    columnDefs: [],
    excelStyles: [
      ...Utils.auxExcelStyle,
      {
        id: 'header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000' },
        interior: { color: '#98aebd', pattern: 'Solid', patternColor: '#999999' },
      },
      {
        id: 'headerGroup',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { color: '#999999', pattern: 'Solid', patternColor: '#999999' },
      },
      {
        id: 'cell',
        font: { fontName: 'Arial', size: 11 },
      },
    ],
    getRowStyle(params: { node: RowNode }) {
      if (params.node.data) {
        // activity sub category
        if (params.node.data.group_index === 1) {
          return {
            color: '#17202A',
            'font-size': '0.75rem',
            'font-weight': 'bold',
          } as RowStyle;
        }
      }

      // vendor name row
      if (params.node.field === 'vendor_name') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
        } as RowStyle;
      }
      // cost categories
      if (params.node.field === 'cost_category') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
        } as RowStyle;
      }
      // total row
      if (params.node.level < 0) {
        return {
          display: 'none',
        } as RowStyle;
      }
      // pinned total row
      if (params.node.rowPinned === 'bottom') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
          'font-weight': 'bold',
        } as RowStyle;
      }
      return undefined;
    },
  });

  // This function return current index with given size
  //
  // arr = [0, 1, 2, 3, 4, 5]
  // takeLeft(arr, 6, 3) => []
  // takeLeft(arr, 5, 3) => [3, 4, 5]
  // takeLeft(arr, 2, 3) => [0, 1, 2]
  // takeLeft(arr, 1, 3) => [0, 1]
  // takeLeft(arr, 0, 3) => [0]
  takeLeft<T>(arr: T[], index: number, size = 3) {
    if (arr.length <= index) {
      return {
        value: [] as T[],
        startIndex: 0,
        endIndex: 0,
      };
    }
    const delta = index < size ? 0 : 1;
    const startIndex = Math.max(index - size, 0) + delta;
    const endIndex = Math.max(index + 1, startIndex);
    return {
      value: arr.slice(startIndex, endIndex),
      startIndex,
      endIndex,
    };
  }

  agHeaderValueFormatter(val: ValueFormatterParams) {
    if (
      val.node?.childIndex === 0 &&
      val.node?.allChildrenCount &&
      val.node?.allChildrenCount > 0
    ) {
      if (val.colDef.field === 'starting_balance') {
        return '$1,863,487.00';
      }
      return '$1,722,690.00';
    }
    return Utils.zeroHyphen;
  }

  ngAfterViewInit(): void {
    this.reconciliationExport.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        finalize(() => {
          setTimeout(() => {
            this.closingPageComponent.rightSideContainer.next(null);
          }, 0);
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.closingPageComponent.rightSideContainer.next(
            this.reconciliationExport.first || null
          );
        }, 0);
      });
  }

  firstDataRendered({ api }: { api: GridApi }) {
    api.sizeColumnsToFit();
  }

  onVendorSelected(vendorId: string) {
    this.organizationStore.setActive(vendorId || null);

    setTimeout(() => {
      this.setBottomData();
    }, 0);
  }

  getTotalRowData() {
    return this.gridAPI?.getDisplayedRowAtIndex(this.gridAPI?.getDisplayedRowCount() - 1)?.aggData;
  }

  setBottomData() {
    this.gridAPI?.setPinnedBottomRowData([
      merge(
        {
          activity_name: 'Total',
        },
        this.getTotalRowData()
      ),
    ]);
  }

  getDynamicExcelParams = (): ExcelExportParams => {
    const totalData = this.getTotalRowData();

    const rows = Object.values(totalData).map<ExcelCell>((val) => ({
      data: { value: `${val}`, type: 'Number' },
      styleId: 'total_row',
    }));

    return {
      appendContent: [
        [
          {
            data: { value: `Total`, type: 'String' },
            styleId: 'total_row_header',
            mergeAcross: 1,
          },
          ...rows,
        ],
      ],
    };
  };

  customColFilter = (cel: ColDef | ColGroupDef) => !!cel.headerName;

  navigateToInMonth() {
    this.router.navigate([`${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`]);
  }

  onDataRendered(e: any) {
    this.gridAPI = e.api;
    this.gridAPI$.next(e.api);
    const { columnApi } = e;
    const allColumnIds: string[] = [];
    columnApi.getAllColumns().forEach((column: Column) => {
      allColumnIds.push(column.getColId());
    });
    columnApi.autoSizeColumns(allColumnIds, false);

    this.setBottomData();
    this.gridOptions$.getValue()?.api?.sizeColumnsToFit();

    this.refreshGridWidth();
  }

  onGridReady({ api }: GridReadyEvent) {
    api.sizeColumnsToFit();
    this.refreshGridWidth();
  }

  autoSize() {
    this.gridOptions$.getValue()?.api?.sizeColumnsToFit();
  }

  refreshGridWidth() {
    const div = document.querySelector('.ag-header-container') as HTMLDivElement;
    if (div) {
      const paddingOffset = 2;
      this.width$.next(div.getBoundingClientRect().width + paddingOffset);
    } else {
      this.width$.next(0);
    }
  }
}
