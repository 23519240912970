<div
  class="w-screen h-screen sm:max-w-2xl md:max-w-3xl lg:max-w-4xl sm:max-h-100 md:max-h-130 lg:max-h-158"
></div>

<div class="pt-5 bg-white">
  <hr />
</div>

<div class="bg-white pt-5 flex justify-between">
  <button class="focus:outline-none" (click)="onCancel()" type="button">Cancel</button>
  <ng-container *ngIf="this.ref.data.header === '2 Buttons'">
    <button class="ml-auto mr-2 btn--secondary" (click)="onSubmit()">Secondary</button>
  </ng-container>
  <button class="btn--primary" (click)="onSecondarySubmit()">Primary</button>
</div>
