import { Injectable } from '@angular/core';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { switchMap, tap } from 'rxjs/operators';
import {
  createDriverPatientDistributionMutation,
  GqlService,
  updateDriverPatientDistributionMutation,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { PatientsStore } from './patients.store';

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  constructor(
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private patientStore: PatientsStore,
    private overlayService: OverlayService
  ) {}

  get(id?: string) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.patientStore.setLoading(true);
        return this.gqlService.listDriverPatientDistributions$([], id);
      }),
      tap(({ success, data, errors }) => {
        if (success && data) {
          this.patientStore.set(data);
        } else {
          this.overlayService.error(errors);
        }
        this.patientStore.setLoading(false);
      })
    );
  }

  async createPatient(patient: createDriverPatientDistributionMutation) {
    const { success, errors, data } = await this.gqlService
      .createDriverPatientDistribution$({ ...patient, driver_setting_id: patient.id })
      .toPromise();
    if (success && data) {
      this.patientStore.set([]);
    } else {
      this.overlayService.error(errors);
    }
    return { success, errors, data };
  }

  async updatePatientDistribution(x: updateDriverPatientDistributionMutation) {
    this.patientStore.setLoading(true);
    const { success, errors, data } = await this.gqlService
      .updateDriverPatientDistribution$({
        id: x.id,
        distribution_mode: x.distribution_mode,
        distribution_month: x.distribution_month,
        patients_enrolled: x.patients_enrolled,
        patients_discontinued: x.patients_discontinued,
        patients_complete: x.patients_complete,
        net_patients_enrolled: x.net_patients_enrolled,
        cumulative_enrollment_percentage: x.cumulative_enrollment_percentage,
        total_patients_enrolled: x.total_patients_enrolled,
      })
      .toPromise();
    if (success && data) {
      this.patientStore.update(data.id, {
        id: data.id,
        distribution_mode: data.distribution_mode,
        distribution_month: data.distribution_month,
        patients_enrolled: data.patients_enrolled,
        patients_discontinued: data.patients_discontinued,
        patients_complete: data.patients_complete,
        net_patients_enrolled: data.net_patients_enrolled,
        cumulative_enrollment_percentage: data.cumulative_enrollment_percentage,
        total_patients_enrolled: data.total_patients_enrolled,
      });
      this.patientStore.setLoading(false);
      return true;
    }
    this.overlayService.error(errors);

    this.patientStore.setLoading(false);
    return false;
  }
}
