import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { TableConstants } from '../constants/table.constants';

const LG_BREAKPOINT = 2000;

@UntilDestroy()
@Directive({
  selector: '[auxGridDynamicHeight]',
})
export class GridDynamicHeightDirective implements OnInit {
  innerWidth$ = new BehaviorSubject(0);

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth$.next(window.innerWidth);
  }

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.innerWidth$.next(window.innerWidth);

    this.innerWidth$.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
      this.updateGridLayout();
    });
  }

  updateGridLayout() {
    this.elementRef.nativeElement.style.height =
      this.innerWidth$.getValue() > LG_BREAKPOINT
        ? TableConstants.SCROLL.MAX_TABLE_HEIGHT_LG
        : TableConstants.SCROLL.MAX_TABLE_HEIGHT;
  }
}
