<button [class]="classNames" [type]="type" [disabled]="(isHandlingClick$ | async) || disabled" (click)="handleClick()">
  <span
    *ngIf="(isHandlingClick$ | async) || loading"
    [ngClass]="{ 'flex mr-2': true, 'absolute left-1/2 transform -translate-x-1/2': !icon && ((isHandlingClick$ | async) || loading) }"
  >
    <span [class]="spinnerClassNames"></span>
  </span>
  <span
    *ngIf="icon && (isHandlingClick$ | async) === false && !loading"
    inlineSVG="{{icon}}.svg"
    class="mr-2"
    [setSVGAttributes]="svgAttributes"></span>
  <span [class]="{ 'button-label': true, invisible: !icon && ((isHandlingClick$ | async) || loading) }">{{label}}</span>
</button>
