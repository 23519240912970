import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService, ISignInResult } from '@models/auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { AppInitService } from '@services/app-init.service';
import { EventService } from '@services/event.service';
import { EntityType, EventType, GqlService } from '@services/gql.service';
import { ROUTING_PATH } from '../../../app-routing-path.const';

@Component({
  selector: 'aux-login',
  templateUrl: './login.component.html',
  styles: [],
})
export class LoginComponent {
  forgotPasswordLink = `/${ROUTING_PATH.FORGOT_PASSWORD}`;

  public year = new Date().getFullYear();

  loading$ = new BehaviorSubject(false);

  showLoginForm = true;

  errorMessage = '';

  signInForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  newPasswordForm = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  user: ISignInResult | undefined = undefined;

  errorCasesToRetryLogin = [
    'PreTokenGeneration invocation failed due to error Socket timeout while invoking Lambda function.',
    'PreTokenGeneration invocation failed due to error TooManyRequestsException.',
  ];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    public appInitService: AppInitService,
    public eventService: EventService,
    private gqlService: GqlService
  ) {}

  async submitSignInForm() {
    if (this.signInForm.valid && !this.loading$.getValue()) {
      this.loading$.next(true);
      this.errorMessage = '';
      try {
        this.user = await this.authService.signIn(
          this.signInForm.value.email,
          this.signInForm.value.password
        );

        if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          console.log('USER challengeParam', this.user.challengeParam);
          this.showLoginForm = false;
        } else {
          await this.authService.setUserAttributes();
          await this.router.navigateByUrl('/');
        }
      } catch (e) {
        if (e.code === 'UserNotConfirmedException') {
          this.authService.latestUserParams = {
            email: this.signInForm.value.email,
            password: this.signInForm.value.password,
          };
          // 1
          this.router.navigate([`/${ROUTING_PATH.CONFIRMATION}`]);
        } else if (this.errorCasesToRetryLogin.includes(e.message)) {
          setTimeout(() => {
            this.loading$.next(false);
            this.submitSignInForm();
          }, 200);
          return;
        } else {
          console.log(e);
          this.errorMessage = e.message;
        }
      }
      this.loading$.next(false);
    }
  }

  async submitNewPasswordForm() {
    this.loading$.next(true);
    if (this.newPasswordForm.valid && this.user) {
      this.errorMessage = '';
      try {
        await this.authService.completeNewPassword(this.user, this.newPasswordForm.value);

        await this.authService.signIn(
          this.signInForm.value.email,
          this.newPasswordForm.value.password
        );
        await this.router.navigateByUrl('/');

        await this.gqlService
          .processEvent$({
            type: EventType.USER_PASSWORD_UPDATED,
            entity_type: EntityType.PROFILE,
            entity_id: '',
          })
          .toPromise();
      } catch (e) {
        console.log(e);
        this.errorMessage = e.message;
      }
    }
    this.loading$.next(false);
  }
}
