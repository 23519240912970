import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  WorkflowModel,
  WorkflowQuery,
  WorkflowService,
} from '../../close-quarter-check-list/store';

@Component({
  selector: 'aux-workflow-panel-general-item',
  templateUrl: './workflow-panel-general-item.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPanelGeneralItemComponent implements OnInit {
  @Input() workflowName!: string;

  @Input() auditTextCallback!: (workflow: WorkflowModel) => string;

  @Input() lockAvailable = true;

  @Input() tooltipButton = '';

  @Input() isWorkflowNameVisible = true;

  @Input() isAdminUser = false;

  currentWorkflow$?: Observable<WorkflowModel | null>;

  constructor(private workflowService: WorkflowService, private workflowQuery: WorkflowQuery) {}

  ngOnInit(): void {
    this.currentWorkflow$ = this.workflowQuery.getWorkflowByName(this.workflowName);
  }

  onToggleLockWorkflow(currentWorkflow: WorkflowModel) {
    this.workflowService.updateWorkflowLockStatus(
      !currentWorkflow.properties.locked,
      currentWorkflow,
      this.isAdminUser
    );
  }
}
