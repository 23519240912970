import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import * as dayjs from 'dayjs';
import { WorkflowState, WorkflowStore } from './workflow.store';
import { WORKFLOW_NAMES } from '../workflow.const';
import { WorkflowService } from './workflow.service';

const ONE_MONTH = 1;

@Injectable({ providedIn: 'root' })
export class WorkflowQuery extends QueryEntity<WorkflowState> {
  isQuarterCloseEnabled$ = this.launchDarklyService.select$(
    ({ client_preference_quarter_close_workflow_enabled }) =>
      client_preference_quarter_close_workflow_enabled
  );

  isWorkflowAvailable$ = combineLatest([
    this.isQuarterCloseEnabled$,
    this.workflowService.trialMonthClose$,
  ]).pipe(
    map(([isQuarterCloseEnabled, trialMonthClose]) => {
      if (!isQuarterCloseEnabled) {
        return false;
      }

      if (trialMonthClose) {
        const trialMonthCloseDate = dayjs(trialMonthClose);
        const currentDate = dayjs();

        return currentDate.diff(trialMonthCloseDate, 'month') >= ONE_MONTH;
      }

      return true;
    })
  );

  invoiceLockTooltip$ = this.getLockStatusByWorkflowName(WORKFLOW_NAMES.INVOICES).pipe(
    map((isInvoiceFinalized) => {
      return isInvoiceFinalized ? 'Invoices are Locked for Period Close' : '';
    })
  );

  constructor(
    protected store: WorkflowStore,
    private launchDarklyService: LaunchDarklyService,
    private workflowService: WorkflowService
  ) {
    super(store);
  }

  selectWorkflowList() {
    return this.selectAll();
  }

  getWorkflowByName(workflowName: string) {
    return this.selectAll({
      filterBy: [(workFlow) => workFlow.name === workflowName],
    }).pipe(map((workflowList) => (workflowList.length ? workflowList[0] : null)));
  }

  getInMonthAdjustmentAvailability() {
    return this.selectWorkflowList().pipe(
      map((workflowList) => {
        return workflowList
          .filter(({ name }) => name !== WORKFLOW_NAMES.ADJUSTMENT)
          .every(({ properties: { locked } }) => locked);
      })
    );
  }

  getMonthCloseButtonAvailability() {
    return combineLatest([
      this.isQuarterCloseEnabled$,
      this.isWorkflowAvailable$,
      this.selectWorkflowList(),
    ]).pipe(
      map(([isQuarterCloseEnabled, isWorkflowAvailable, workflowList]) => {
        if (!isQuarterCloseEnabled) {
          return true;
        }

        return isWorkflowAvailable
          ? workflowList.every(({ properties: { locked } }) => locked)
          : isWorkflowAvailable;
      })
    );
  }

  getLockStatusByWorkflowName(workflowName: string): Observable<boolean> {
    return combineLatest([this.isWorkflowAvailable$, this.getWorkflowByName(workflowName)]).pipe(
      map(([isWorkflowPanelAvailable, workflow]) => {
        if (!isWorkflowPanelAvailable) {
          return false;
        }

        return workflow ? workflow.properties.locked : false;
      })
    );
  }
}
