<ng-container *ngIf="{ activeIndex: activeTabIndex$ | async } as obj">
  <div>
    <div class="mb-8">
      <div>
        <button
          *ngFor="let tab of tabs; index as i"
          type="button"
          [routerLink]="tab.route"
          routerLinkActive
          #rla="routerLinkActive"
          [routerLinkActiveOptions]="{ exact: true }"
          class="px-4 py-2 rounded text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
          [ngClass]="{
            'border-aux-blue-light bg-aux-blue-light text-white': rla.isActive,
            'border-transparent text-gray-500 hover:text-gray-700': !rla.isActive
          }"
        >
          {{ tab.label }}
        </button>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</ng-container>
