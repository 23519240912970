import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, EMPTY, merge as rxMerge, Observable, ReplaySubject } from 'rxjs';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  ColDef,
  ColGroupDef,
  ExcelExportParams,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  ProcessCellForExportParams,
  RowNode,
} from 'ag-grid-community';
import { Utils } from '@services/utils';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { Color, Label } from 'ng2-charts';
import { ChartData, ChartDataSets, ChartOptions, ChartTooltipItem, ChartType } from 'chart.js';
import { FormControl } from '@angular/forms';
import { filter, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { OrganizationStore } from '@models/organization/organization.store';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { OverlayService } from '@services/overlay.service';
import { groupBy, merge, round, sumBy, uniqBy } from 'lodash-es';
import {
  BudgetType,
  ExpenseType,
  GqlService,
  PeriodType,
  TrialPreferenceType,
} from '@services/gql.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { ExcelButtonVariant } from '@components/export-excel-button/export-excel-button.component';
import { BudgetStore } from './state/budget.store';
import { BudgetQuery } from './state/budget.query';
import { BudgetService } from './state/budget.service';
import { BudgetPageComponent } from '../../budget-page.component';
import { BudgetUploadComponent } from './budget-upload/budget-upload.component';
import { AgHeaderExpandComponent } from './ag-header-expand.component';

dayjs.extend(isSameOrAfter);
// TODO: remove this old budget component after we successfully start to use EBG
@UntilDestroy()
@Component({
  selector: 'aux-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.css'],
  animations: [],
})
export class BudgetComponent implements OnInit, AfterViewInit {
  @ViewChild('budgetGrid', { read: ElementRef }) budgetGrid: ElementRef | undefined;

  exportButtonVariant = ExcelButtonVariant.FILLED;

  defaultColumns: ((ColDef | ColGroupDef) & {
    hideForAllVendorSelection?: boolean;
    children?: (ColDef & { hideForAllVendorSelection?: boolean })[];
  })[] = [
    {
      headerName: 'Vendor',
      field: 'vendor_name',
      rowGroup: true,
      hide: true,
    },
    {
      headerName: 'Cost Category',
      field: 'cost_category',
      rowGroup: true,
      hide: true,
      hideForAllVendorSelection: true,
    },
    {
      headerName: 'Category',
      field: 'group0',
      rowGroup: true,
      hide: true,
      hideForAllVendorSelection: true,
    },
    {
      headerName: 'Category',
      field: 'group1',
      rowGroup: true,
      hide: true,
      hideForAllVendorSelection: true,
    },
    {
      headerName: 'Category',
      field: 'group2',
      rowGroup: true,
      hide: true,
      hideForAllVendorSelection: true,
    },
    {
      headerName: 'Category',
      field: 'group3',
      rowGroup: true,
      hide: true,
      hideForAllVendorSelection: true,
    },
    {
      headerName: 'Category',
      field: 'group4',
      rowGroup: true,
      hide: true,
      hideForAllVendorSelection: true,
    },
    {
      headerName: 'Baseline Budget',
      headerClass: 'ag-header-align-center',
      width: 130,
      minWidth: 130,
      children: [
        {
          headerName: 'Activities',
          field: 'activity_name',
          tooltipField: 'activity_name',
          valueFormatter: Utils.dashFormatter,
          hide: true,
          minWidth: 150,
          width: 150,
        },
        {
          headerName: 'UoM',
          field: 'uom',
          width: 100,
          minWidth: 100,
          tooltipField: 'uom',
          valueFormatter: Utils.dashFormatter,
          hide: true,
        },
        {
          headerName: 'Units',
          field: 'unit_num',
          width: 100,
          minWidth: 100,
          valueFormatter: Utils.dashFormatter,
          hide: true,
        },
        {
          headerName: 'Unit Cost',
          headerClass: 'ag-header-align-center',
          field: 'unit_cost',
          width: 100,
          minWidth: 100,
          valueFormatter: BudgetComponent.agCurrencyFormatter,
          cellClass: ['ag-cell-align-right', 'budget-cost'],
          hide: true,
        },
        {
          headerName: 'Direct Costs',
          headerClass: 'ag-header-align-center',
          field: `${ExpenseType.EXPENSE_QUOTE}`,
          width: 125,
          minWidth: 125,
          valueFormatter: BudgetComponent.agCurrencyFormatter,
          aggFunc: 'sum',
          cellClass: ['ag-cell-align-right', 'budget-cost'],
        },
      ],
    },
  ];

  columnDefs: (ColDef | ColGroupDef)[] = [];

  zeroHyphen = Utils.zeroHyphen;

  remainingBudgetColDef: ColDef | ColGroupDef = {
    headerName: 'Remaining Budget',
    headerClass: 'ag-header-align-center',
    children: [
      {
        headerName: '%',
        headerClass: 'ag-header-align-center',
        valueFormatter: (params) => {
          if (params.node?.aggData) {
            const direct_cost = params.node.aggData[`${ExpenseType.EXPENSE_QUOTE}`] || 0;
            const wp_cost = params.node.aggData[`${ExpenseType.EXPENSE_WP}::TO_DATE`] || 0;

            const wp_percentage = (wp_cost / (direct_cost || 1)) * 100;
            return this.percentageFormatter(100 - wp_percentage);
          }
          return this.percentageFormatter(params.data?.remaining_percentage || 0);
        },
        field: 'remaining_percentage',
        cellClass: ['ag-cell-align-right', 'budget-percent'],
        width: 100,
        minWidth: 100,
      },
      {
        headerName: 'Units',
        field: 'remaining_unit_num',
        cellClass: ['ag-cell-align-right', 'budget-units'],
        valueFormatter: (params) => {
          if (params.node?.aggData) {
            const { unit_cost } = params.node.aggData;
            if (unit_cost) {
              const direct_cost = params.node.aggData[`${ExpenseType.EXPENSE_QUOTE}`] || 0;
              const wp_cost = params.node.aggData[`${ExpenseType.EXPENSE_WP}::TO_DATE`] || 0;
              const remaining_cost = direct_cost - wp_cost;
              return Utils.decimalFormatter(remaining_cost / (unit_cost || 1));
            }
            return Utils.zeroHyphen;
          }

          return Utils.decimalFormatter(params.data?.remaining_unit_num);
        },
        hide: true,
        width: 100,
        minWidth: 100,
      },
      {
        headerName: 'Costs',
        headerClass: 'ag-header-align-center',
        field: 'remaining_cost',
        width: 100,
        minWidth: 100,
        valueFormatter: BudgetComponent.agCurrencyFormatter,
        aggFunc: 'sum',
        cellClass: ['ag-cell-align-right', 'budget-cost'],
      },
    ],
  };

  gridOptions = {
    suppressPropertyNamesCheck: true,
    defaultColDef: {
      sortable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    autoGroupColumnDef: {
      headerName: 'Activities',
      minWidth: 250,
      width: 250,
      suppressSizeToFit: true,
      field: 'activity_name',
      tooltipField: 'activity_name',
      pinned: 'left',
      resizable: true,
      cellRendererParams: {
        suppressCount: true,
      },
    },
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    groupDefaultExpanded: 1,
    suppressColumnVirtualisation: true,
    columnDefs: [],
    initialGroupOrderComparator: (params) => {
      const a = this.getLowestRowNumFromNode(params.nodeA);
      const b = this.getLowestRowNumFromNode(params.nodeB);
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }

      return 0;
    },
    excelStyles: [
      {
        id: 'header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { color: '#094673', pattern: 'Solid' },
        alignment: { horizontal: 'Center' },
      },
      {
        id: 'headerGroup',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { color: '#999999', pattern: 'Solid' },
        alignment: { horizontal: 'Center' },
      },
      {
        id: 'budget-cost',
        dataType: 'Number',
        numberFormat: { format: Utils.excelCostFormat },
      },
      {
        id: 'cell',
        font: { fontName: 'Arial', size: 11 },
      },
      {
        id: 'budget-percent',
        alignment: { horizontal: 'Right' },
        numberFormat: { format: Utils.excelPercentFormat },
      },
      {
        id: 'budget-units',
        alignment: { horizontal: 'Right' },
        numberFormat: { format: Utils.excelUnitsFormat },
      },
    ],
    getRowStyle(params: { node: RowNode }) {
      if (params.node.data) {
        // activity sub category
        if (params.node.data.group_index === 1) {
          return {
            color: '#17202A',
            'font-size': '0.75rem',
            'font-weight': 'bold',
          };
        }
      }

      // vendor name row
      if (params.node.field === 'vendor_name') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
        };
      }
      // cost categories
      if (params.node.field === 'cost_category') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
        };
      }
      // activity category
      if (params.node.field === 'group0') {
        return {
          background: '#F6F8FA',
          color: '#17202A',
          'font-size': '0.75rem',
        };
      }
      if (params.node.field === 'group1') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
        };
      }
      if (params.node.field === 'group2') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
        };
      }
      if (params.node.field === 'group3') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
        };
      }
      if (params.node.field === 'group4') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
        };
      }
      // total row
      if (params.node.level < 0) {
        return {
          display: 'none',
        };
      }
      // pinned total row
      if (params.node.rowPinned === 'bottom') {
        return {
          color: '#17202A',
          'font-size': '0.75rem',
          'font-weight': 'bold',
        };
      }
      return undefined;
    },
  } as GridOptions;

  pendingChangesLoading = new BehaviorSubject(false);

  invoicesTotalLoading = new BehaviorSubject(false);

  wpLoading = new BehaviorSubject(false);

  excelOptions = {
    sheetName: 'Budget',
    fileName: 'auxilius-budget.xlsx',
    processCellCallback: (params: ProcessCellForExportParams): string => {
      const coldId = params.column.getColId();

      if (['wp_percentage', 'remaining_percentage'].indexOf(coldId) !== -1) {
        return `${params.value / 100}`;
      }

      return params.value;
    },
    shouldRowBeSkipped(params) {
      return !params.node?.data?.cost_category;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'vendor_name':
          return 280;
        case 'activity_name':
          return 490;
        case 'group0':
          return 280;
        default:
          return 105;
      }
    },
  } as ExcelExportParams;

  periodTypes = [
    { label: 'Month', value: PeriodType.PERIOD_MONTH },
    { label: 'Quarter', value: PeriodType.PERIOD_QUARTER },
    { label: 'Year', value: PeriodType.PERIOD_YEAR },
  ];

  selectedPeriodType = new FormControl(
    this.launchDarklyService.flags$.getValue().client_preference_budget_period_type
  );

  selectedBudgetType = new FormControl(BudgetType.BUDGET_PRIMARY);

  budgetTypes = [
    { label: 'Budget Upload', value: BudgetType.BUDGET_PRIMARY },
    { label: 'Scenario Budget', value: BudgetType.BUDGET_SECONDARY },
  ];

  gridAPI: GridApi | undefined;

  gridAPI$: ReplaySubject<GridApi> = new ReplaySubject<GridApi>(1);

  gridOptions$ = new BehaviorSubject<GridOptions>(this.gridOptions);

  gridData$ = new BehaviorSubject<any[]>([]);

  @ViewChildren('budgetFilters') budgetFilters!: QueryList<TemplateRef<any>>;

  showAnalyticsSection$: Observable<boolean>;

  showBudgetTypeSelect$: Observable<boolean>;

  selectedVendor = new FormControl('');

  isYearsOpen = false;

  highlightedYear!: any;

  selectedYear!: string;

  years: { enabled: boolean; label: number }[] = [];

  budgetGridYears: number[] | null = null;

  budgetCanvas$: Observable<{
    labels: Label[];
    type: ChartType;
    options: ChartOptions;
    datasets: ChartDataSets[];
    colors: Color[];
    legend: boolean;
    show: boolean;
  }> = this.budgetQuery.select().pipe(
    map((state) => {
      const auxilius_start_date = this.mainQuery.getAuxiliusStartDate();
      const columns = ['Services', 'Investigator', 'Pass-through'];

      const groupedData = groupBy(state.budget_data, 'cost_category');

      const headers = state.header_data
        .filter((x) => x.expense_type === 'EXPENSE_FORECAST' || x.expense_type === 'EXPENSE_WP')
        .map((x) => x.date_headers)
        .reduce((accum, item) => accum.concat(item), [])
        .filter((v, i, a) => a.indexOf(v) === i)
        .filter((str) => {
          if (!auxilius_start_date) {
            return true;
          }

          return dayjs(new Date(`01/${str.replace('-', '/').toUpperCase()}`)).isSameOrAfter(
            dayjs(auxilius_start_date).date(1)
          );
        });

      const datasets: { data: number[]; label: string }[] = [];
      columns.map((column) => {
        if (groupedData[column]) {
          const obj = {
            data: [] as number[],
            label: column,
          };
          for (const header of headers) {
            const n = round(sumBy(groupedData[column], `EXPENSE_FORECAST::${header}`) || 0, 2);
            const wp = round(sumBy(groupedData[column], `EXPENSE_WP::${header}`) || 0, 2);
            obj.data.push(n + wp);
          }
          datasets.push(obj);
        }

        return null;
      });

      return {
        show: !datasets.every((dataset) => dataset.data.every((x) => x === 0)),
        type: 'bar',
        options: {
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            tooltipFormat: '',
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  // Include a dollar sign in the ticks
                  callback(value) {
                    return Utils.currencyFormatter(value as number, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    });
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              title(item: ChartTooltipItem[], chartData: ChartData) {
                return item
                  .map((x) => x.datasetIndex || 0)
                  .map((x) => chartData?.datasets?.[x]?.label || '');
              },
              label(tooltipItem: ChartTooltipItem) {
                if (tooltipItem.yLabel && typeof tooltipItem.yLabel === 'number') {
                  return Utils.currencyFormatter(tooltipItem.yLabel, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });
                }
                return `${tooltipItem.yLabel || ''}`;
              },
            },
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
        },
        datasets,
        labels: headers,
        legend: true,
        colors: [
          {
            backgroundColor: 'rgba(9, 91, 149, 1)',
          },
          {
            backgroundColor: '#6B9DBF',
          },
          {
            backgroundColor: '#9DBDD5',
          },
          {
            backgroundColor: 'rgba(25,100,230,0.75)',
          },
        ],
      };
    })
  );

  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  constructor(
    private budgetStore: BudgetStore,
    private budgetService: BudgetService,
    public budgetQuery: BudgetQuery,
    public organizationQuery: OrganizationQuery,
    private organizationStore: OrganizationStore,
    private organizationService: OrganizationService,
    private vendorsService: OrganizationService,
    private budgetPageComponent: BudgetPageComponent,
    private cdr: ChangeDetectorRef,
    private launchDarklyService: LaunchDarklyService,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private renderer: Renderer2
  ) {
    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          return this.gqlService.getTrialPreference$(TrialPreferenceType.BUDGET_GRID_YEARS).pipe(
            tap((prefBudgetGridYears) => {
              this.budgetGridYears = prefBudgetGridYears?.data?.value
                ? (JSON.parse(prefBudgetGridYears?.data?.value) as Array<number>)
                : null;
            })
          );
        }),
        switchMap(() => {
          return this.budgetQuery.selectLoading().pipe(filter((loading) => !loading));
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadBudgetGridData();
      });

    this.showAnalyticsSection$ = launchDarklyService.select$(
      (flags) => flags.section_budget_analytics
    );

    this.showBudgetTypeSelect$ = launchDarklyService.select$((flags) => flags.section_budget_type);

    this.showAnalyticsSection$
      .pipe(
        switchMap((flag) => {
          if (flag) {
            this.pendingChangesLoading.next(true);
            this.wpLoading.next(true);
            this.invoicesTotalLoading.next(true);

            return rxMerge(
              this.budgetService.getPendingChanges().pipe(
                tap(() => {
                  this.pendingChangesLoading.next(false);
                })
              ),
              this.budgetService.getBudgetWorkPerformed().pipe(
                tap(() => {
                  this.wpLoading.next(false);
                })
              ),
              this.budgetService.getInvoicesTotal().pipe(
                tap(() => {
                  this.invoicesTotalLoading.next(false);
                })
              )
            );
          }
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.selectedBudgetType.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((budget_type: BudgetType) => {
        this.budgetStore.update({ budget_type });
      });
  }

  static agCurrencyFormatter(val: ValueFormatterParams) {
    if (val.data) {
      if (val.data.expense_note && (val.colDef.field || '').indexOf('direct_cost') >= 0) {
        return val.data.expense_note;
      }
    }

    if (val.value) {
      if (!Number.isNaN(val.value)) {
        return Utils.currencyFormatter(val.value);
      }
    }

    return Utils.zeroHyphen;
  }

  private getLowestRowNumFromNode(row: RowNode) {
    if (row.childrenAfterGroup) {
      let lowestVal: any;
      if (row.childrenAfterGroup) {
        row.childrenAfterGroup.forEach((child) => {
          const lowestId = this.getLowestRowNumFromNode(child);
          if (lowestVal === undefined || lowestId < lowestVal) {
            lowestVal = lowestId;
          }
        });
        return lowestVal;
      }
    }

    return row.data.rnm;
  }

  private loadBudgetGridData(refresh: boolean = false) {
    const { budget_data, header_data } = this.budgetQuery.getValue();
    const { auxilius_start_date } = this.mainQuery.getSelectedTrial() || {};
    const { budgetGridYears } = this;

    this.gridData$.next(budget_data || []);
    const currentPeriod = this.selectedPeriodType.value as PeriodType;
    const defs: (ColDef | ColGroupDef)[] = [];

    const el = header_data.find((x) => x.group_name === 'Work Performed');
    if (!refresh) {
      const hYears = (el?.date_headers || []).reduce((acc: number[], col_header) => {
        let headerName = '';
        const arr = col_header.split('-');
        switch (currentPeriod) {
          case PeriodType.PERIOD_MONTH:
            headerName = `${arr[1]}`;
            break;
          case PeriodType.PERIOD_YEAR:
          case PeriodType.PERIOD_QUARTER:
            // eslint-disable-next-line prefer-destructuring
            headerName = `${arr[0]}`;
            break;
          default:
            break;
        }
        if (headerName !== '' && !acc.includes(Number(headerName))) {
          acc.push(Number(headerName));
        }
        return acc;
      }, []);
      if (Array.isArray(budgetGridYears)) {
        this.years = hYears.map((year) => {
          // eslint-disable-next-line no-param-reassign
          return { label: year, enabled: !!budgetGridYears.includes(year) };
        });
      } else {
        this.years = hYears.map((year) => ({ label: year, enabled: true }));
      }
      this.setSelectedYear();
    }
    if (el) {
      let childColumns = el.date_headers
        .filter((col_header) => {
          let year = '';
          const arr = col_header.split('-');
          switch (currentPeriod) {
            case PeriodType.PERIOD_MONTH:
              year = `${arr[1]}`;
              break;
            case PeriodType.PERIOD_YEAR:
            case PeriodType.PERIOD_QUARTER:
              // eslint-disable-next-line prefer-destructuring
              year = `${arr[0]}`;
              break;
            default:
              break;
          }
          const enabled = this.years.find((h) => h.label === Number(year))?.enabled;
          return enabled;
        })
        .map((col_header, i, self) => {
          let headerName: string = col_header;
          const arr = col_header.split('-');
          switch (currentPeriod) {
            case PeriodType.PERIOD_MONTH:
              headerName = `${Utils.MONTH_NAMES[new Date(`01-${col_header}`).getMonth()]} ${
                arr[1]
              }`;
              break;
            case PeriodType.PERIOD_QUARTER:
              // eslint-disable-next-line prefer-destructuring
              headerName = `${arr[1]} ${arr[0]}`;
              break;
            default:
              break;
          }

          return {
            headerName,
            headerClass: 'ag-header-align-center',
            field: `${el.expense_type}::${col_header}`,
            aggFunc: 'sum',
            valueFormatter: BudgetComponent.agCurrencyFormatter,
            width: 120,
            minWidth: 120,
            hide: !!i && i !== self.length - 1,
            cellClass: ['ag-cell-align-right', 'budget-cost'],
          } as ColDef;
        });

      if (auxilius_start_date && currentPeriod === PeriodType.PERIOD_MONTH) {
        childColumns = [
          {
            headerName: 'Trial to Date',
            headerClass: 'ag-header-align-center',
            field: 'trial_to_date',
            aggFunc: 'sum',
            valueFormatter: BudgetComponent.agCurrencyFormatter,
            width: 120,
            minWidth: 120,
            cellClass: ['ag-cell-align-right', 'budget-cost'],
          },
          ...childColumns.filter((col) => {
            const col_header = col.field?.split('::')[1] || '';
            return dayjs(new Date(`01/${col_header.replace('-', '/')}`)).isSameOrAfter(
              dayjs(auxilius_start_date).date(1)
            );
          }),
        ];
      }

      defs.push({
        headerName: 'Actuals',
        headerClass: 'ag-header-align-center justify-center',
        colId: 'actuals',
        headerGroupComponent: AgHeaderExpandComponent,
        children: [...childColumns],
        headerGroupComponentParams: {
          dataId: 'actuals-column',
        },
      });

      defs.push({
        headerName: 'Actuals to Date',
        headerClass: 'ag-header-align-center',
        colId: 'WP',
        children: [
          {
            headerName: '',
            hide: true,
            field: `${ExpenseType.EXPENSE_WP}::TO_DATE`,
            aggFunc: 'sum',
          },
          {
            headerName: '% Complete',
            columnGroupShow: 'closed',
            field: 'wp_percentage',
            width: 100,
            minWidth: 100,
            // aggFunc: 'sum',
            headerClass: 'ag-header-align-center',
            cellClass: ['ag-cell-align-right', 'budget-percent'],
            valueFormatter: (params: ValueFormatterParams) => {
              if (params.node?.aggData) {
                const direct_cost = params.node.aggData[`${ExpenseType.EXPENSE_QUOTE}`] || 0;
                const wp_cost = params.node.aggData[`${ExpenseType.EXPENSE_WP}::TO_DATE`] || 0;

                const wp_percentage = (wp_cost / (direct_cost || 1)) * 100;
                return this.percentageFormatter(wp_percentage);
              }
              return this.percentageFormatter(params.data?.wp_percentage || 0);
            },
          },
          {
            headerName: 'Units',
            field: 'wp_unit_num',
            width: 60,
            minWidth: 60,
            columnGroupShow: 'closed',
            headerClass: 'ag-header-align-center',
            cellClass: ['ag-cell-align-right', 'budget-units'],
            valueFormatter: (params) => {
              if (params.node?.aggData) {
                const { unit_cost } = params.node.aggData;
                if (unit_cost) {
                  const wp_cost = params.node.aggData[`${ExpenseType.EXPENSE_WP}::TO_DATE`] || 0;
                  return Utils.decimalFormatter(wp_cost / unit_cost);
                }
                return Utils.zeroHyphen;
              }

              return Utils.decimalFormatter(params.data?.wp_unit_num);
            },
          },
          {
            headerName: 'Costs',
            headerClass: 'ag-header-align-center',
            field: 'wp_cost',
            width: 100,
            minWidth: 100,
            valueFormatter: BudgetComponent.agCurrencyFormatter,
            aggFunc: 'sum',
            columnGroupShow: 'closed',
            cellClass: ['ag-cell-align-right', 'budget-cost'],
          },
        ],
      });
    }

    defs.push(this.remainingBudgetColDef);

    const forecastHeader = header_data.find((x) => x.group_name === 'Forecast');
    if (forecastHeader) {
      const currentForecast = forecastHeader.date_headers[0];

      let gHeaderName = '';
      let cHeaderName = '';
      switch (currentPeriod) {
        case PeriodType.PERIOD_MONTH:
          gHeaderName = 'Current Month';
          cHeaderName = `${Utils.MONTH_NAMES[new Date(`01-${currentForecast}`).getMonth()]} ${
            currentForecast.split('-')[1]
          }`;
          break;
        case PeriodType.PERIOD_QUARTER:
          gHeaderName = 'Current Quarter';
          cHeaderName = currentForecast.split('-').reverse().join(' ');
          break;
        case PeriodType.PERIOD_YEAR:
          gHeaderName = 'Current Year';
          cHeaderName = currentForecast;
          break;
        default:
          break;
      }

      defs.push({
        headerName: gHeaderName,
        headerClass: 'ag-header-align-center',
        colId: 'current',
        width: 125,
        minWidth: 125,
        children: [
          {
            headerName: cHeaderName,
            field: `${forecastHeader.expense_type}::${currentForecast}`,
            aggFunc: 'sum',
            headerClass: 'ag-header-align-center',
            width: 125,
            minWidth: 125,
            valueFormatter: BudgetComponent.agCurrencyFormatter,
            cellClass: ['ag-cell-align-right', 'budget-cost'],
          },
        ],
      });
      if (!refresh) {
        const fYears = forecastHeader.date_headers.slice(1).reduce((acc: number[], col_header) => {
          let headerName = '';
          switch (currentPeriod) {
            case PeriodType.PERIOD_MONTH:
              headerName = `${col_header.split('-')[1]}`;
              break;
            case PeriodType.PERIOD_QUARTER:
              headerName = `${col_header.split('-')[0]}`;
              break;
            case PeriodType.PERIOD_YEAR:
              headerName = col_header;
              break;
            default:
              break;
          }
          if (headerName !== '' && !acc.includes(Number(headerName))) {
            acc.push(Number(headerName));
          }
          return acc;
        }, []);
        if (Array.isArray(budgetGridYears)) {
          const arr = fYears.map((year) => {
            // eslint-disable-next-line no-param-reassign
            return { label: year, enabled: !!budgetGridYears.includes(year) };
            // year.enabled = !!budgetYears.data?.years.includes(year.label);
          });
          this.years = uniqBy([...this.years, ...arr], 'label');
        } else {
          const arr = fYears.map((year) => ({ label: year, enabled: true }));
          this.years = uniqBy([...this.years, ...arr], 'label');
        }
      }
      defs.push({
        headerName: 'Forecast',
        width: 100,
        minWidth: 100,
        children: forecastHeader.date_headers
          .slice(1)
          .filter((col_header) => {
            let headerName = '';
            switch (currentPeriod) {
              case PeriodType.PERIOD_MONTH:
                headerName = `${col_header.split('-')[1]}`;
                break;
              case PeriodType.PERIOD_QUARTER:
                headerName = `${col_header.split('-')[0]}`;
                break;
              case PeriodType.PERIOD_YEAR:
                headerName = col_header;
                break;
              default:
                break;
            }
            const enabled = this.years.find((e) => e.label === Number(headerName))?.enabled;
            return enabled;
          })
          .map((col_header) => {
            let headerName = '';
            switch (currentPeriod) {
              case PeriodType.PERIOD_MONTH:
                headerName = `${Utils.MONTH_NAMES[new Date(`01-${col_header}`).getMonth()]} ${
                  col_header.split('-')[1]
                }`;
                break;
              case PeriodType.PERIOD_QUARTER:
                headerName = col_header.split('-').reverse().join(' ');
                break;
              case PeriodType.PERIOD_YEAR:
                headerName = col_header;
                break;
              default:
                break;
            }

            return {
              headerName,
              headerClass: 'ag-header-align-center',
              field: `${forecastHeader.expense_type}::${col_header}`,
              width: 120,
              minWidth: 120,
              aggFunc: 'sum',
              valueFormatter: BudgetComponent.agCurrencyFormatter,
              cellClass: ['ag-cell-align-right', 'budget-cost'],
            } as ColDef | ColGroupDef;
          }),
      } as ColDef | ColGroupDef);
    }

    // if vendor drop down is set to ALL, then filter some columns from the table (i.e. columns w/ hideForAllVendorSelection property set to true).
    // Due to the grouping, this also removes the redudant rows on the "ALL" table (i.e. Services > Services now just shows as Services)
    let filteredColumns = this.defaultColumns;
    if (this.selectedVendor.value === '') {
      filteredColumns = this.defaultColumns
        .map((column) => {
          if ((column as ColGroupDef).children?.length) {
            return {
              ...column,
              children: column.children?.filter(
                (childColumn) =>
                  !(childColumn as { hideForAllVendorSelection?: boolean })
                    .hideForAllVendorSelection
              ),
            };
          }
          return column;
        })
        .filter((column) => !column.hideForAllVendorSelection);
    }

    // Set ColumnDefs to [] first, to enable the ag-grid to respect the order given to the cols (will queue the new columns otherwise)
    this.gridAPI?.setColumnDefs([]);
    this.gridOptions$.next({
      ...this.gridOptions$.getValue(),
    });
    this.columnDefs = [...filteredColumns, ...defs];
    this.gridAPI?.refreshHeader();
    this.setSelectedYear();
  }

  percentageFormatter(val: number) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    let fVal = Utils.zeroHyphen;
    if (val) {
      fVal = `${formatter.format(val)}%`;
    }
    return fVal;
  }

  ngOnInit() {
    this.selectedPeriodType.valueChanges
      .pipe(
        startWith(this.selectedPeriodType.value as PeriodType),
        switchMap((periodType) => this.budgetService.getBudgetData(periodType)),
        untilDestroyed(this)
      )
      .subscribe();

    this.organizationService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length === 1) {
          this.organizationStore.setActive(vendors[0].id);
          this.selectedVendor.setValue(vendors[0].id);
        } else {
          // reset any older selected vendors.
          const activeVendor = this.organizationQuery.getActive();
          const findedVendor = vendors.find((x) => x.id === activeVendor?.id);
          if (findedVendor) {
            this.selectedVendor.setValue(findedVendor.id);
          } else {
            this.organizationStore.setActive(null);
            this.selectedVendor.setValue('');
          }
        }
      });
  }

  onVendorSelected(vendorId: string) {
    if (vendorId) {
      this.organizationStore.setActive(vendorId);
    } else {
      this.organizationStore.setActive(null);
    }
  }

  onDataRendered(e: FirstDataRenderedEvent) {
    this.gridAPI = e.api;
    this.gridAPI$.next(e.api);
    const { columnApi } = e;
    columnApi.autoSizeAllColumns(false);

    if (this.budgetGrid?.nativeElement.clientHeight > document.documentElement.clientHeight * 0.6) {
      this.gridAPI.setDomLayout('normal');
      this.renderer.setStyle(this.budgetGrid?.nativeElement, 'height', '60vh');
      // the grid is higher than the wanted height
    } else {
      this.gridAPI.setDomLayout('autoHeight');
      this.renderer.removeStyle(this.budgetGrid?.nativeElement, 'height');
    }
    this.gridAPI?.setPinnedBottomRowData([
      merge(
        {
          activity_name: 'Total',
        },
        this.gridAPI?.getDisplayedRowAtIndex(this.gridAPI?.getDisplayedRowCount() - 1)?.aggData
      ),
    ]);
  }

  ngAfterViewInit(): void {
    this.budgetFilters.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        finalize(() => {
          setTimeout(() => {
            this.budgetPageComponent.filterViewContainer.next(null);
          }, 0);
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.budgetPageComponent.filterViewContainer.next(this.budgetFilters.first || null);
        }, 0);
      });
  }

  onBudgetUploadClick() {
    this.overlayService.open({ content: BudgetUploadComponent });
  }

  autoSize() {
    this.gridOptions$.getValue().api?.sizeColumnsToFit();
  }

  closeList() {
    this.isYearsOpen = false;
  }

  openList() {
    this.isYearsOpen = true;
  }

  setSelectedYear() {
    let numberOfYearsEnabled = 0;
    this.years.forEach((year) => {
      if (year.enabled) {
        numberOfYearsEnabled += 1;
      }
    });
    if (numberOfYearsEnabled === 0) {
      this.selectedYear = 'None';
    } else if (numberOfYearsEnabled < this.years.length) {
      this.selectedYear = `${numberOfYearsEnabled} Selected`;
    } else {
      this.selectedYear = 'All';
    }
  }

  yearChanged($event: boolean, label: number) {
    const year = this.years.find((el) => el.label === label);
    if (year) {
      year.enabled = $event;
    }
    this.saveBudgetYears();
    this.setSelectedYear();
  }

  customColFilter = (cel: ColDef | ColGroupDef) => !!cel.headerName;

  async saveBudgetYears() {
    const years = [...this.years].reduce((acc: number[], el) => {
      if (!acc.includes(el.label) && el.enabled) {
        acc.push(el.label);
      }
      return acc;
    }, []);

    await this.gqlService
      .setTrialPreference$({
        preference_type: TrialPreferenceType.BUDGET_GRID_YEARS,
        value: JSON.stringify(years),
      })
      .toPromise();

    this.budgetGridYears = years;
    this.loadBudgetGridData(true);
  }
}
