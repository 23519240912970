import { Injectable } from '@angular/core';
import { EntityState, EntityStore, guid, StoreConfig } from '@datorama/akita';
import { File } from './file.model';

export interface FileState extends EntityState<File> {}

@Injectable()
@StoreConfig({ name: 'file' })
export class FileStore extends EntityStore<FileState> {
  constructor() {
    super({}, { name: `file-manager-${guid()}` });
  }
}
