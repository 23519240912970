import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FirstDataRenderedEvent } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { compactGridOptions } from './compact-grid-options';
import { checkboxGridOptions } from './checkbox-grid-option';
import { gridOptions } from './grid-options';
import { compactGridData, checkboxGridData, gridData } from './grid-data';
@Component({
  selector: 'aux-tables',
  templateUrl: './tables.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablesComponent {
  compactGridOptions = compactGridOptions;

  compactGridData = compactGridData;

  indecisive$ = new BehaviorSubject(false);

  status$ = new BehaviorSubject(true);

  checkboxGridOptions = checkboxGridOptions;

  checkboxGridData = checkboxGridData;

  gridOptions = gridOptions;

  gridData = gridData;

  onDataRendered(e: FirstDataRenderedEvent) {
    const gridAPI = e.api;
    gridAPI?.setPinnedBottomRowData([e.lastRow]);
  }

  getLineNumbers(upperBound: number) {
    return Array(upperBound)
      .fill(1)
      .map((x, i) => i + 1);
  }
}
