import { coerceNumberProperty, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const RADIO_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => RadioButtonComponent),
  multi: true,
};

let nextId = 0;

@Component({
  selector: 'aux-radio-button',
  template: `<label class="flex items-center">
    <input
      type="radio"
      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
      [id]="id"
      [checked]="checked"
      [disabled]="disabled"
      [name]="name"
      [tabIndex]="tabindex"
      (focus)="onFocus($event)"
      (blur)="blur.emit($event)"
      (change)="_onInputChange($event)"
    />
    <!-- <span class="checkmark"></span> -->
    <ng-content></ng-content>
  </label>`,
  styles: [],
  providers: [RADIO_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonComponent implements ControlValueAccessor {
  // eslint-disable-next-line no-plusplus
  readonly UNIQUE_ID = `ngx-radio-${++nextId}`;

  @Input() id: string = this.UNIQUE_ID;

  @Input() name: string = this.UNIQUE_ID;

  @Input()
  get tabindex() {
    return this._tabindex;
  }

  set tabindex(tabindex: number) {
    this._tabindex = coerceNumberProperty(tabindex);
  }

  @Input()
  get checked() {
    return this._checked;
  }

  set checked(rawChecked: boolean) {
    const checked = coerceBooleanProperty(rawChecked);

    if (this._checked !== checked) {
      this._checked = checked;
      this.onChangeCallback(this._value);
    }

    this.cdr.markForCheck();
  }

  @Input()
  get value() {
    return this._value;
  }

  set value(value: boolean) {
    if (this._value !== value) {
      this._value = value;
      this.onChangeCallback(this._value);
    }
  }

  @Input()
  get disabled() {
    return this._disabled || this.groupDisabled;
  }

  set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
  }

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<boolean>();

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<Event>();

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus = new EventEmitter<FocusEvent>();

  public groupDisabled = false;

  private _checked = false;

  private _value = false;

  private _disabled = false;

  private _tabindex = 0;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  _onInputChange(event: Event) {
    event.stopPropagation();
    this.checked = true;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  onFocus(e: FocusEvent) {
    this.focus.emit(e);
    this.onTouchedCallback();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  private onChangeCallback(value: boolean) {
    if (this.checked) {
      this.change.emit(value);
    }
  }

  private onTouchedCallback() {
    // placeholder
  }
}
