import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map, switchMap } from 'rxjs/operators';
import { Utils } from '@services/utils';
import { UserTasksStore, UserTasksState } from './user-tasks.store';
import { MainQuery } from '../../layouts/main-layout/state/main.query';

@Injectable({ providedIn: 'root' })
export class UserTasksQuery extends QueryEntity<UserTasksState> {
  constructor(protected store: UserTasksStore, private mainQuery: MainQuery) {
    super(store);
  }

  getCurrentTrialTasks() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap((trialId) => this.selectAll({ filterBy: (entity) => entity.trial_id === trialId })),
      map((tasks) =>
        tasks.sort((task1, task2) => Utils.dateSort(task1.create_date, task2.create_date))
      )
    );
  }
}
