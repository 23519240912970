import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { EventType } from '@services/gql.service';

export interface MainState {
  trialKey: string;
  fullPage: boolean;
  sideBar: boolean;
  processingEvents: Record<EventType, boolean> | {};
}

export function createInitialState(): MainState {
  return {
    trialKey: '',
    fullPage: false,
    sideBar: true,
    processingEvents: {},
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'main' })
export class MainStore extends Store<MainState> {
  constructor() {
    super(createInitialState());
  }

  setProcessingLoadingState(eventType: EventType, isLoading: boolean) {
    this.update((state) => ({
      processingEvents: { ...state.processingEvents, [eventType]: isLoading },
    }));
  }
}
