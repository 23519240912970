import { Injectable } from '@angular/core';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { switchMap, tap } from 'rxjs/operators';
import {
  CreateTimelineMilestoneInput,
  EntityType,
  EventType,
  GqlService,
  listTimelineMilestonesQuery,
  UpdateTimelineMilestoneInput,
  createTimelineMilestoneMutation,
  updateTimelineMilestoneMutation,
} from '@services/gql.service';
import { Observable, of } from 'rxjs';
import { OverlayService } from '@services/overlay.service';
import {
  MilestoneModel,
  MilestoneStore,
} from '@models/milestone-category/milestone/milestone.store';
import { MilestoneCategoryStore } from '@models/milestone-category/milestone-category.store';
import { arrayAdd, arrayRemove, arrayUpdate } from '@datorama/akita';
import { Utils } from '@services/utils';
import { MilestoneCategoryQuery } from '@models/milestone-category/milestone-category.query';
import { createInitialState, TimelineStore } from './timeline.store';

export type UpdateDependencyInput = {
  id: string;
  field_name: string;
  contract_end_date: string;
  contract_start_date: string;
  track_from_milestone_id: string | null;
};

export type UpdateTimelineMilestone = Omit<
  UpdateTimelineMilestoneInput,
  'name' | 'milestone_category_id' | 'description'
> & {
  name: string;
  milestone_category_id: string;
  description: string | null;
  milestone_id: string;
};

type GetMilestones = (
  timeline_id: string
) => Observable<{ success: boolean; data: listTimelineMilestonesQuery[] | null; errors: string[] }>;

@Injectable({ providedIn: 'root' })
export class TimelineService {
  constructor(
    private timelineStore: TimelineStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private milestoneStore: MilestoneStore,
    private milestoneCategoryStore: MilestoneCategoryStore,
    private milestoneCategoryQuery: MilestoneCategoryQuery
  ) {}

  getTimelineItems(getMilestones: GetMilestones = this.gqlService.listTimelineMilestones$) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.timelineStore.setLoading(true);
        this.timelineStore.update(createInitialState());
        return this.gqlService.getTimeline$().pipe(
          switchMap(({ data, success, errors }) => {
            if (success && data) {
              return getMilestones(data.id).pipe(
                tap((x) => {
                  if (x.data) {
                    this.timelineStore.update({
                      items: x.data,
                      selectedTimeline: data.id,
                      timelineExists: true,
                    });
                  }
                  this.timelineStore.setLoading(false);
                })
              );
            }

            this.overlayService.error(errors);
            this.timelineStore.setLoading(false);
            return of([]);
          })
        );
      })
    );
  }

  checkIfTimelineMilestoneExist() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.timelineStore.setLoading(true);

        return this.gqlService.getTimeline$();
      }),
      switchMap(({ data: timeline, success, errors }) => {
        if (timeline && success) {
          return this.gqlService.listTimelineMilestoneIds$(timeline.id).pipe(
            tap(({ data: milestones }) => {
              this.timelineStore.update({
                timelineExists: !!milestones?.length,
              });
            })
          );
        }

        this.overlayService.error(errors);
        this.timelineStore.setLoading(false);

        return of();
      })
    );
  }

  async createTimelineMilestone(input: Omit<CreateTimelineMilestoneInput, 'timeline_id'>) {
    const { items } = this.timelineStore.getValue();
    const timelineMilestoneId = Utils.uuid();
    const milestoneId = Utils.uuid();
    this.milestoneStore.add({
      id: milestoneId,
      milestone_category_id: input.milestone_category_id,
      name: input.name,
      description: input.description,
      __typename: 'Milestone',
    } as MilestoneModel);
    const data: createTimelineMilestoneMutation = {
      __typename: 'TimelineMilestone',
      actual_end_date: input.actual_end_date,
      actual_start_date: input.actual_end_date,
      contract_end_date: input.contract_end_date,
      contract_start_date: input.contract_start_date,
      id: timelineMilestoneId,
      revised_end_date: input.revised_end_date,
      revised_start_date: input.revised_start_date,
      track_from_milestone: input.track_from_milestone_id
        ? {
            __typename: 'Milestone',
            id: input.track_from_milestone_id,
          }
        : null,
      milestone: {
        __typename: 'Milestone',
        id: milestoneId,
        name: input.name,
        milestone_category_id: input.milestone_category_id,
        milestone_category: {
          __typename: 'MilestoneCategory',
          name: this.milestoneCategoryQuery.getEntity(input.milestone_category_id)?.name || '',
        },
      },
    };
    this.timelineStore.update({ items: [...items, data] });

    return { success: true, createdMilestoneId: data.id, milestoneId };
  }

  async updateTimelineForDependency({
    id,
    contract_end_date,
    contract_start_date,
    track_from_milestone_id,
  }: UpdateDependencyInput) {
    const { items } = this.timelineStore.getValue();
    const timelineMilestone = items.find((tm) => tm.id === id);
    if (timelineMilestone) {
      const data: updateTimelineMilestoneMutation = {
        __typename: 'TimelineMilestone',
        contract_end_date,
        contract_start_date,
        id,
        track_from_milestone: track_from_milestone_id
          ? {
              __typename: 'Milestone',
              id: track_from_milestone_id,
            }
          : null,
        milestone: timelineMilestone.milestone,
      };

      this.timelineStore.update((x) => {
        return {
          items: arrayUpdate(x.items, id, data),
        };
      });
    }

    return {
      success: true,
    };
  }

  async updateTimelineMilestone(input: UpdateTimelineMilestone) {
    this.timelineStore.setLoading(true);
    const {
      milestone_category_id,
      milestone_id,
      name,
      description,
      id,
      track_from_milestone_id,
      contract_start_date,
      contract_end_date,
    } = input;

    this.milestoneStore.update(milestone_id, (state) => {
      // if the milestone_category_id changed
      if (state.milestone_category_id !== milestone_category_id) {
        // remove the milestone_id from old category
        this.milestoneCategoryStore.update(state.milestone_category_id, ({ milestone_ids }) => {
          return { milestone_ids: arrayRemove(milestone_ids, milestone_id) };
        });

        // add milestone_id to new category
        this.milestoneCategoryStore.update(milestone_category_id, ({ milestone_ids }) => {
          return { milestone_ids: arrayAdd(milestone_ids, milestone_id) };
        });
      }
      return {
        name,
        milestone_category_id,
        description,
      };
    });

    const { items } = this.timelineStore.getValue();
    const timelineMilestone = items.find((tm) => tm.id === id);
    if (timelineMilestone) {
      const data: updateTimelineMilestoneMutation = {
        __typename: 'TimelineMilestone',
        contract_end_date: contract_end_date || '',
        contract_start_date: contract_start_date || '',
        id,
        track_from_milestone: track_from_milestone_id
          ? {
              __typename: 'Milestone',
              id: track_from_milestone_id,
            }
          : null,
        milestone: {
          __typename: 'Milestone',
          id: milestone_id,
          name,
          milestone_category_id,
          milestone_category: {
            __typename: 'MilestoneCategory',
            name: this.milestoneCategoryQuery.getEntity(input.milestone_category_id)?.name || '',
          },
        },
      };

      this.timelineStore.update((x) => {
        return {
          items: arrayUpdate(x.items, id, data),
        };
      });
    }

    this.timelineStore.setLoading(false);

    return {
      success: true,
    };
  }

  async triggerTimelineUpdatedEvent() {
    return this.gqlService
      .processEvent$({
        type: EventType.TIMELINE_UPDATED,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getValue().trialKey,
      })
      .toPromise();
  }

  async deleteTimelineMilestone(id: string) {
    this.timelineStore.setLoading(true);

    this.timelineStore.update((x) => {
      const milestone_id = x.items.find((ti) => {
        return ti.id === id;
      })?.milestone?.id;

      const ids: string[] = [];
      x.items.forEach((ti) => {
        if (ti.track_from_milestone?.id === milestone_id) {
          ids.push(ti.id);
        }
      });
      return {
        items: arrayUpdate(arrayRemove(x.items, [id]), ids, {
          track_from_milestone: null,
        }),
      };
    });
    this.timelineStore.setLoading(false);
  }

  async saveChanges(
    createInputs: Omit<CreateTimelineMilestoneInput, 'timeline_id'>[],
    updateInputs: UpdateTimelineMilestone[],
    updateDependencyInputs: UpdateDependencyInput[],
    deleteInputs: string[]
  ) {
    const proms: Promise<any>[] = [];
    const createProms: Promise<any>[] = [];
    const { selectedTimeline } = this.timelineStore.getValue();
    // do track_from_milestone_id updates for new milestones after creating all new milestones
    const updateAfterCreate: any = {};
    createInputs.forEach((createInput) => {
      if (createInput.id && createInput.track_from_milestone_id) {
        updateAfterCreate[createInput.id] = createInput.track_from_milestone_id;
      }
      createProms.push(
        this.gqlService
          .createTimelineMilestone$({
            ...createInput,
            timeline_id: selectedTimeline,
            track_from_milestone_id: null,
          })
          .toPromise()
      );
    });
    const results: any = await Promise.all(createProms);
    const updates = [...updateInputs];
    results.forEach((res: any) => {
      if (updateAfterCreate[res.data.id]) {
        updates.push({ ...res.data, track_from_milestone_id: updateAfterCreate[res.data.id] });
      }
    });
    updates.forEach((updateInput) => {
      const {
        milestone_category_id,
        name,
        description,
        id,
        track_from_milestone_id,
        contract_start_date,
        contract_end_date,
      } = updateInput;
      proms.push(
        this.gqlService
          .updateTimelineMilestone$({
            id,
            milestone_category_id,
            track_from_milestone_id:
              track_from_milestone_id || '00000000-0000-0000-0000-000000000000',
            contract_start_date,
            contract_end_date,
            name,
            description,
          })
          .toPromise()
      );
    });
    updateDependencyInputs.forEach((updateDependencyInput) => {
      const {
        id,
        contract_end_date,
        contract_start_date,
        track_from_milestone_id,
      } = updateDependencyInput;
      proms.push(
        this.gqlService
          .updateTimelineMilestone$({
            id,
            track_from_milestone_id,
            contract_start_date,
            contract_end_date,
          })
          .toPromise()
      );
    });
    deleteInputs.forEach((deleteInput) => {
      proms.push(this.gqlService.removeTimelineItem$(deleteInput).toPromise());
    });
    await Promise.all(proms);
    return this.triggerTimelineUpdatedEvent();
  }
}
