<div class="font-inter w-screen max-w-2xl">
  <div class="mb-2 text-xl font-bold mb-4">Apply Data</div>
  <div class="mb-4">
    Apply Data to <span class="font-bold">{{selectedRows.length}} Selected Documents</span>
  </div>

  <div class="flex border-b pb-4">
    <div class="w-56">
      <span class="text-xs mb-1">Document Type:</span>
      <ng-select
        [multiple]="false"
        [appendTo]="'body'"
        [searchable]="false"
        [clearable]="true"
        [(ngModel)]="selectedType"
        placeholder="Select"
      >
        <ng-option [value]="option.value" *ngFor="let option of documentTypeOptions">
          <span [title]="option.label">{{ option.label }}</span>
        </ng-option>
      </ng-select>
    </div>
    <div class="w-56 pl-4">
      <span class="text-xs mb-1">Vendor:</span>
      <ng-select
        [multiple]="false"
        [appendTo]="'body'"
        [searchable]="false"
        [clearable]="true"
        [(ngModel)]="selectedVendor"
        placeholder="Select"
      >
        <ng-option [value]="option.value" *ngFor="let option of documentLibrary.vendors">
          <span [title]="option.label">{{ option.label }}</span>
        </ng-option>
      </ng-select>
    </div>
    <div class="w-56 pl-4">
      <span class="text-xs mb-1">Site:</span>
      <ng-select
        [multiple]="false"
        [appendTo]="'body'"
        [searchable]="false"
        [clearable]="true"
        [(ngModel)]="selectedSite"
        placeholder="Select"
      >
        <ng-option [value]="option.value" *ngFor="let option of documentLibrary.sites">
          <span [title]="option.label">{{ option.label }}</span>
        </ng-option>
      </ng-select>
    </div>
  </div>

  <div class="flex justify-between mt-4">
    <aux-button
      variant="custom"
      classList="text-sm h-full"
      (click)="ref.close()"
      label="Cancel"
    ></aux-button>
    <aux-button variant="primary" [spinnerSize]="6" label="Apply" [onClick]="onApply"></aux-button>
  </div>
</div>
