<div
  class="min-h-screen bg-aux-gray-light flex flex-col justify-center py-12 lg:px-8 my-0 mx-auto text-sm"
>
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <div class="rounded shadow-md bg-white p-14">
      <img class="mb-10 mx-auto w-3/4" src="assets/img/logo-black.png" alt="" />

      <div class="text-2xl font-medium">Forgot Password?</div>

      <div class="mb-4">Enter your email address to reset your password.</div>

      <div *ngIf="errorMessage" class="my-5 p-5 font-medium bg-aux-error text-white">
        {{ errorMessage }}
      </div>

      <div *ngIf="successMessage" class="my-5 p-5 font-medium bg-aux-green text-white">
        {{ successMessage }}
      </div>

      <form
        *ngIf="!showSecondForm"
        [formGroup]="forgotForm"
        (ngSubmit)="forgotPassword()"
        novalidate
        autocomplete="off"
      >
        <aux-input
          formControlName="email"
          validators="required|email"
          label="Email"
          placeholder="you@example.com"
          [type]="'email'"
        ></aux-input>

        <div class="grid grid-cols-4 gap-5 my-8">
          <button class="btn btn--blue col-span-3" type="submit">Reset Password</button>
          <a [routerLink]="[loginLink]" class="btn btn--white"> Back </a>
        </div>
      </form>

      <form
        *ngIf="showSecondForm"
        [formGroup]="newPasswordForm"
        (ngSubmit)="newPasswordSubmit()"
        novalidate
        autocomplete="off"
        class="space-y-2"
      >
        <aux-input
          formControlName="email"
          validators="required|email"
          label="Email"
          placeholder="you@example.com"
          [type]="'email'"
        ></aux-input>

        <aux-input
          formControlName="code"
          validators="required|minLength:6"
          label="Verification Code"
        ></aux-input>

        <div *ngIf="{ type: 'password' } as obj" class="relative">
          <button
            type="button"
            class="-top-1 absolute focus:outline-none right-1"
            (click)="obj.type = obj.type == 'text' ? 'password' : 'text'"
          >
            <eye-outline-icon *ngIf="obj.type === 'password'"></eye-outline-icon>
            <eye-off-outline-icon *ngIf="obj.type === 'text'"></eye-off-outline-icon>
          </button>
          <aux-input
            autocomplete="off"
            formControlName="password"
            label="Password"
            [type]="obj.type"
            validators="required|minLength:8"
          ></aux-input>

          <div class="text-xs mt-2">
            Must include minimum of 8 characters and <br />
            contain an uppercase character, lowercase <br />
            character and number
          </div>
        </div>

        <div class="grid grid-cols-4 gap-5 my-8">
          <button class="btn btn--blue col-span-3" type="submit">Submit</button>

          <a [routerLink]="[loginLink]" class="btn btn--white">Back</a>
        </div>
      </form>
    </div>

    <div class="mb-5 mt-10 space-x-2 text-center">
      <a href="#" class="aux-link no-underline">Privacy Policy</a>
      <a href="#" class="aux-link no-underline">Term of Use</a>
    </div>

    <p class="text-center">©️{{ year }} Auxilius Inc., All rights reserved.</p>
  </div>

  <div class="bg-aux-gray-light text-aux-gray-dark-100 flex items-center justify-end py-2 pr-4">
    App Version {{ appInitService.APP_VERSION }}
  </div>
</div>
