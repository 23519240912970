import { CanDeactivateGuard } from '@services/can-deactivate.guard';
import { Route, Routes } from '@angular/router';
import { Flags } from '@services/launch-darkly.service';
import { buildRoutesByFeatureFlags, FeatureFlag } from '@models/feature-flag.model';
import { PatientsPageComponent } from './patients-page.component';
import { PatientProtocolComponent } from './patient-protocol';
import { PatientProtocolEditComponent } from './patient-protocol-edit/patient-protocol-edit.component';
import { ROUTING_PATH } from '../../app-routing-path.const';
import { PatientTrackerComponent } from './patient-tracker/patient-tracker.component';

export const PATIENTS_ROUTES_FF: Routes = [
  {
    path: ROUTING_PATH.PATIENTS.PATIENT_TRACKER,
    component: PatientTrackerComponent,
    data: {
      feature: FeatureFlag.PATIENT_TRACKER,
    },
  },
  {
    path: ROUTING_PATH.PATIENTS.PATIENT_BUDGET.INDEX,
    component: PatientProtocolComponent,
    data: {
      feature: FeatureFlag.PATIENT_BUDGET,
    },
  },
];

export const buildPatientRoutes = (featureFlags: Flags | null): Route => {
  const enabledRoutes: Routes = [
    ...buildRoutesByFeatureFlags(featureFlags, PATIENTS_ROUTES_FF),
    {
      path: ROUTING_PATH.PATIENTS.PATIENT_BUDGET_ENTRY,
      component: PatientProtocolEditComponent,
      canDeactivate: [CanDeactivateGuard],
    },
  ];

  return {
    path: ROUTING_PATH.PATIENTS.INDEX,
    component: PatientsPageComponent,
    children: [
      ...enabledRoutes,
      {
        path: '**',
        redirectTo: (enabledRoutes.length && enabledRoutes[0].path) || '/',
      },
    ],
  };
};
