import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { SiteModel, SitesState, SitesStore } from './sites.store';

@QueryConfig({
  sortBy: 'name',
  sortByOrder: Order.ASC,
})
@Injectable({ providedIn: 'root' })
export class SitesQuery extends QueryEntity<SitesState> {
  constructor(protected store: SitesStore) {
    super(store);
  }

  selectSortedListBy(sortBy: keyof SiteModel) {
    return this.selectAll({
      sortBy,
      sortByOrder: Order.ASC,
    });
  }

  getSortedListBy(sortBy: keyof SiteModel) {
    return this.getAll({
      sortBy,
      sortByOrder: Order.ASC,
    });
  }
}
