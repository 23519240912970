<ng-container *ngIf="!editMode">
  <div class="p-5 2xl:p-7.5 h-full" [ngClass]="{ 'bg-aux-gray-light': card.status === 'Up Next' }">
    <div class="flex items-baseline justify-between">
      <div class="text-base font-bold">{{ card.header }}</div>

      <div
        class="px-2 py-0.5 font-medium rounded text-xxs tracking-tight text-white whitespace-nowrap"
        [ngClass]="{
          'bg-aux-green': card.status === 'Complete',
          'bg-aux-blue-light': card.status === 'In Process',
          'bg-aux-gray-dark text-aux-black': card.status === 'Up Next'
        }"
      >
        {{ card.status }}
      </div>
    </div>
    <ng-container *ngFor="let child of card.lines; index as i; last as last">
      <ng-container [ngSwitch]="child.type">
        <div
          *ngSwitchCase="'label'"
          class="flex items-center justify-between h-10 py-4"
          [ngClass]="{ 'border-b': !last }"
        >
          <div class="text-xs w-5/12">{{ child.label }}</div>
          <div class="flex items-center space-x-4 w-2/12">
            <ng-container *ngIf="child.status as status">
              <ng-container [ngSwitch]="status">
                <div
                  *ngSwitchCase="'irrelevant'"
                  class="w-5 h-5 bg-aux-gray-dark rounded-full flex items-center justify-center"
                >
                  <div class="w-2 border border-aux-gray-light"></div>
                </div>
                <div
                  *ngSwitchCase="'complete'"
                  class="p-0.5 flex items-center justify-center bg-aux-green rounded-full text-white"
                >
                  <svg
                    class="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div *ngSwitchDefault>
                  <svg
                    [ngClass]="{
                      'text-aux-warn': status === 'warn',
                      'text-aux-error': status === 'error'
                    }"
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="2 1 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </ng-container>
            </ng-container>
            <div *ngIf="!child.status" class="w-5 h-5"></div>
            <div class="text-sm text-aux-black">
              {{ child.text == '0' || child.text == '-' ? zeroHyphen : child.text }}
            </div>
          </div>
          <div class="text-xs w-5/12">{{ child.note }}</div>
        </div>
        <div *ngSwitchCase="'textarea'" class="w-2/3">
          {{ child.text == '0' || child.text == '-' ? zeroHyphen : child.text }}
        </div>
        <div *ngSwitchDefault>😱</div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="editMode">
  <div class="p-5 2xl:p-7.5 border rounded h-full">
    <div class="flex items-baseline justify-between">
      <div class="text-base font-bold">{{ card.header }}</div>

      <div class="w-40">
        <ng-select
          class="mt-0 text-sm select h-auto"
          [items]="cardStatus"
          [ngModel]="card.status"
          [disabled]="isInvoiceDisabled"
          [auxTooltip]="invoiceLockTooltip"
          [clearable]="false"
          (ngModelChange)="onStatusChange($event, card.status); card.status = $event"
        ></ng-select>
      </div>
    </div>
    <ng-container [ngSwitch]="card.status">
      <div *ngSwitchDefault>
        <ng-container *ngFor="let child of card.lines; index as i; last as last">
          <ng-container [ngSwitch]="child.type">
            <div
              *ngSwitchCase="'label'"
              class="flex items-center justify-between py-4"
              [ngClass]="{ 'border-b': !last }"
            >
              <aux-input
                [(ngModel)]="child.label"
                class="flex-1"
                placeholder="Enter Text"
                [disabled]="isInvoiceDisabled"
                [auxTooltip]="invoiceLockTooltip"
              ></aux-input>

              <div class="flex ml-8 mr-4 space-x-2">
                <div class="flex items-center" [auxTooltip]="invoiceLockTooltip">
                  <input
                    [name]="child.label! + i"
                    type="radio"
                    value="complete"
                    [(ngModel)]="child.status"
                    [disabled]="isInvoiceDisabled"
                    class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                  />
                  <check-circle-solid-icon class="ml-0.5 text-aux-green"></check-circle-solid-icon>
                </div>

                <div class="flex items-center" [auxTooltip]="invoiceLockTooltip">
                  <input
                    [name]="child.label! + i"
                    type="radio"
                    value="warn"
                    [(ngModel)]="child.status"
                    [disabled]="isInvoiceDisabled"
                    class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                  />
                  <exclamation-solid-icon class="ml-0.5 text-aux-warn"></exclamation-solid-icon>
                </div>

                <div class="flex items-center">
                  <input
                    [name]="child.label! + i"
                    type="radio"
                    value="error"
                    [(ngModel)]="child.status"
                    [disabled]="isInvoiceDisabled"
                    [auxTooltip]="invoiceLockTooltip"
                    class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                  />
                  <exclamation-solid-icon class="ml-0.5 text-aux-error"></exclamation-solid-icon>
                </div>

                <div class="flex items-center" [auxTooltip]="invoiceLockTooltip">
                  <input
                    [name]="child.label! + i"
                    type="radio"
                    value="irrelevant"
                    [disabled]="isInvoiceDisabled"
                    [(ngModel)]="child.status"
                    class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                  />
                  <div
                    class="mx-1 w-4 h-4 bg-aux-gray-dark rounded-full flex items-center justify-center"
                  >
                    <div class="w-2 border border-aux-gray-light"></div>
                  </div>
                </div>

                <div class="flex items-center" [auxTooltip]="invoiceLockTooltip">
                  <input
                    [name]="child.label! + i"
                    type="radio"
                    [value]="undefined"
                    [disabled]="isInvoiceDisabled"
                    [(ngModel)]="child.status"
                    class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                  />
                </div>
              </div>

              <aux-input
                class="mr-2 w-20"
                [(ngModel)]="child.text"
                placeholder="Enter Text"
                [disabled]="isInvoiceDisabled"
                [auxTooltip]="invoiceLockTooltip"
              ></aux-input>

              <aux-input
                class="flex-1 mr-4"
                [(ngModel)]="child.note"
                placeholder="Enter Note"
                [disabled]="isInvoiceDisabled"
                [auxTooltip]="invoiceLockTooltip"
              ></aux-input>

              <div [auxTooltip]="invoiceLockTooltip">
                <button class="text-sm aux-link" (click)="removeChild(i)" [disabled]="isInvoiceDisabled">Remove</button>
              </div>
            </div>

            <div *ngSwitchDefault>😱</div>
          </ng-container>
        </ng-container>

        <div [auxTooltip]="invoiceLockTooltip" style="width: fit-content;">
          <button class="btn btn--blue" (click)="onAddNewLine()" [disabled]="isInvoiceDisabled">
            <plus-outline-icon></plus-outline-icon>
            <span class="text-sm">Add new line</span>
          </button>
        </div>
      </div>
      <div *ngSwitchCase="'Up Next'">
        <ng-container *ngFor="let child of card.lines">
          <aux-input
            [(ngModel)]="child.text"
            [textArea]="true"
            [rows]="10"
            placeholder="Enter Text"
            class="mt-4"
            [disabled]="isInvoiceDisabled"
          ></aux-input>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
