import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { Utils } from '@services/utils';
import { WorkflowModel, WorkflowQuery, WorkflowService, WorkflowStore } from './store';
import { MONTH_ADJUSTMENT_TOOLTIP, WORKFLOW_NAMES } from './workflow.const';
import { CloseQuarterService } from './close-quarter.service';
import { ROUTING_PATH } from '../../../../../app-routing-path.const';

const QUARTER_MONTHS_COUNT = 3;

@UntilDestroy()
@Component({
  selector: 'aux-close-quarter-check-list',
  templateUrl: './close-quarter-check-list.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseQuarterCheckListComponent implements OnInit, OnChanges {
  @Input() readonly = false;

  @Input() isCurrentQuarterSelected$!: BehaviorSubject<boolean>;

  @Input() isAdminUser = false;

  @Input() processing!: boolean;

  @Input() quarter = '';

  monthAdjustmentTitle = WORKFLOW_NAMES.ADJUSTMENT;

  monthAdjustmentTooltipText = MONTH_ADJUSTMENT_TOOLTIP;

  quarterClosePath = `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`;

  workflowList$ = this.workflowQuery.selectWorkflowList();

  isLoadingList$ = this.workflowQuery.selectLoading();

  isAdjustmentAvailable$ = this.workflowQuery.getInMonthAdjustmentAvailability();

  isWorkflowAvailable$ = this.workflowQuery.isWorkflowAvailable$;

  constructor(
    private workflowService: WorkflowService,
    private workflowQuery: WorkflowQuery,
    private overlayService: OverlayService,
    private workflowStore: WorkflowStore,
    public closeQuarterService: CloseQuarterService
  ) {}

  ngOnInit(): void {
    combineLatest([this.isAdjustmentAvailable$])
      .pipe(
        map(async ([isAdjustmentAvailable]) => {
          if (!isAdjustmentAvailable) {
            this.resetMonthAdjustmentWorkflow();
          }
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  getWorkflowDateByQuarter(quarterValue: string) {
    if (!quarterValue) {
      return '';
    }

    const [quarter, ...year] = quarterValue.match(/\d/gi) || [];

    if (quarter && year) {
      const yearString = year?.join('');
      const month = +quarter * QUARTER_MONTHS_COUNT;

      return `${Utils.SHORT_MONTH_NAMES[month - 1].toUpperCase()}-${yearString}`;
    }

    return '';
  }

  resetMonthAdjustmentWorkflow() {
    const workflowList = this.workflowQuery.getAll();

    const monthInAdjustmentWorkflow = workflowList.find(
      ({ name }) => name === this.monthAdjustmentTitle
    ) as WorkflowModel;

    if (monthInAdjustmentWorkflow?.properties?.locked) {
      this.changeLockStatus(false, monthInAdjustmentWorkflow);
    }
  }

  showWarningModal(workflow: WorkflowModel) {
    const modal = this.overlayService.openConfirmDialog({
      header: `Unlock ${workflow.name}?`,
      message: `Unlocking this item will automatically unlock ${this.monthAdjustmentTitle}. All items in the checklist must be locked in order to close the period`,
      okBtnText: 'Continue',
    });

    return modal.afterClosed$.toPromise();
  }

  async shouldUpdateLockStatus(workflow: WorkflowModel) {
    const isAllWorkflowLocked = this.workflowQuery
      .getAll()
      .every(({ properties: { locked } }) => locked);

    if (isAllWorkflowLocked && workflow.name !== this.monthAdjustmentTitle) {
      const modalResult = await this.showWarningModal(workflow);

      return !!modalResult.data?.result;
    }

    return true;
  }

  async changeLockStatus(locked: boolean, workflow: WorkflowModel) {
    const validationStatus = await this.shouldUpdateLockStatus(workflow);

    if (validationStatus) {
      this.workflowService.updateWorkflowLockStatus(locked, workflow, this.isAdminUser);
    } else {
      this.workflowStore.update(workflow.id, {
        properties: { ...workflow.properties, locked: !locked },
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.quarter?.currentValue) {
      const workflowDate = !this.isCurrentQuarterSelected$.getValue()
        ? this.getWorkflowDateByQuarter(changes.quarter.currentValue)
        : '';

      this.workflowService.getWorkflowList(this.isAdminUser, workflowDate).toPromise();
    }
  }
}
