import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { BehaviorSubject } from 'rxjs';
import { OverlayService } from '@services/overlay.service';
import { CurveModel } from '../../models/curve.model';
import { PatientCurveService } from '../../../patient-curve/patient-curve.service';

@Component({
  selector: 'aux-patient-curve-group',
  template: `<div class="ml-1 w-72" *ngIf="curveData as items">
    <div class="text-lg font-bold mb-4">{{ labelTopic }} Patient Curve</div>

    <div class="text-xs font-bold mb-4">Select Patient Groups</div>

    <div class="text-sm mb-4" *ngFor="let item of items; index as index">
      <aux-checkbox
        class="mb-3 text-sm"
        [checked]="item.check"
        (customChange)="setValue(item, $event)"
      >
        {{ item.name }}
      </aux-checkbox>
    </div>

    <div class="text-sm grid">
      <span class="mb-1">Name Custom Curve:</span>
      <aux-input [formControl]="patientName" auxFormError></aux-input>
    </div>

    <div class="mt-8 flex space-x-4 justify-between">
      <button class="text-sm font-normal focus:outline-none" (click)="ref.close()" type="button">
        Cancel
      </button>
      <button class="text-sm btn btn--blue" (click)="editPatientDriver()">
        <span class="spinner w-6 h-6 mr-3" *ngIf="(loading$ | async) === true"></span>
        <span>{{ labelButton }} Curve</span>
      </button>
    </div>
  </div> `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientCurveGroupComponent implements OnInit {
  // ToDo: refactor this file. Extend from BlendedCurveModalDirective like SiteBlendedCurveModalComponent
  curveData: CurveModel[] = [];

  patientName = new FormControl('');

  patientData: any | undefined;

  curveId: string[] = [];

  loading$ = new BehaviorSubject(false);

  labelTopic = 'Create';

  labelButton = 'Create';

  constructor(
    public ref: CustomOverlayRef,
    public patientCurveService: PatientCurveService,
    private overlayService: OverlayService
  ) {
    if (this.ref.data.patientData) {
      this.patientData = this.ref.data.patientData;
      // @ts-ignore
      if (this.patientData?.constituent_patient_groups?.length > 0) {
        this.patientData?.constituent_patient_groups?.forEach((x: any) => {
          this.curveId.push(x.id);
        });
      }
    }
    if (this.ref.data.PatientCurveGroup) {
      this.curveData = this.ref.data.PatientCurveGroup;
    }
  }

  ngOnInit() {
    this.loading$.next(true);
    if (this.ref.data) {
      const newData: CurveModel[] = [];

      if (this.patientData) {
        this.labelTopic = 'Edit';
        this.labelButton = 'Update';
        this.patientName.setValue(this.patientData.name);
        // @ts-ignore
        this.curveData.forEach((x: any) => {
          // @ts-ignore
          const curve = this.patientData.constituent_patient_groups.find((y: any) => {
            return y.id === x.patient_group_id;
          });
          newData.push({ ...x, check: !!curve } as CurveModel);
        });
      } else {
        this.ref.data.PatientCurveGroup.forEach((x: any) => {
          newData.push({ ...x, check: false });
        });
      }

      this.curveData = newData;
    }
    this.loading$.next(false);
  }

  setValue(item: any, checked: boolean = false) {
    const index = this.curveId.findIndex((x: string) => {
      return x === item.patient_group_id;
    });
    if (checked) {
      if (index === -1) {
        this.curveId.push(item.patient_group_id);
      }
    } else if (index !== -1) {
      // @ts-ignore
      this.curveId.splice(index, 1);
    }
  }

  async editPatientDriver() {
    this.loading$.next(true);
    if (this.patientData) {
      const uptData = {
        ...this.patientData,
        name: this.patientName.value,
        blended_group_id: this.patientData.driver_setting_id,
        patient_group_ids: this.curveId,
      } as any;
      await this.patientCurveService.update(uptData);
    } else {
      const newData: any = {
        ...(this.patientData as any),
        name: this.patientName.value,
        patient_group_ids: this.curveId,
      };
      const { success, errors } = await this.patientCurveService.add(newData);
      if (success) {
        this.overlayService.success('Patient Curve Create success');
      } else {
        this.overlayService.error(errors);
        this.loading$.next(false);
        return;
      }
    }
    this.loading$.next(false);
    this.ref.close();
  }
}
