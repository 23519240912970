<div class="w-96">
  <div class="text-lg font-bold mb-4">Upload Vendor Estimate</div>

  <div class="grid">
    <div class="flex justify-between flex-col">
      <div *ngIf="errorMessage" class="mt-4 p-5 font-medium bg-aux-error text-white rounded-md">
        {{ errorMessage }}
      </div>

      <div>
        <aux-file-manager
          class="h-48"
          #manager
          [fetchFilesOnInit]="false"
          [pathFn]="pathFn"
          [eager]="false"
          [metadata]="metadata"
          [showSuccessOnUpload]="true"
        ></aux-file-manager>
        <div class="mt-2 text-xs">Uploaded Files</div>
        <ng-container *ngIf="fileManager?.fileQuery?.selectAll() | async as files">
          <ng-container *ngIf="files.length">
            <ng-container *ngFor="let file of files; let last = last" class="border-b">
              <div class="flex justify-between items-center py-2" [ngClass]="{ 'border-b': !last }">
                <div class="items-start text-xs break-all">
                  {{ file.fileName }}
                </div>

                <button
                  class="btn rounded-full border-0 shadow-none p-0 w-6 h-6"
                  (click)="removeFile(file)"
                >
                  <trash-solid-icon [size]="16" class="text-aux-error"></trash-solid-icon>
                </button>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <div class="mt-4 flex justify-between space-x-4">
        <button
          class="text-sm font-normal aux-link focus:outline-none"
          (click)="ref.close()"
          type="button"
        >
          Cancel
        </button>

        <button class="text-sm btn btn--blue" (click)="onUpload()">
          <span class="spinner w-6 h-6 mr-3" *ngIf="loading$ | async"></span>
          <span>Upload</span>
        </button>
      </div>
    </div>
  </div>
</div>
