import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { GqlService, SiteGroupType } from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from '../../../../../../../layouts/main-layout/state/main.query';
import { SiteGroupsStore } from './site-groups.store';
import { SiteGroupModel } from '../../models/site-group.model';

@Injectable({ providedIn: 'root' })
export class SiteGroupsService {
  constructor(
    private siteGroupsStore: SiteGroupsStore,
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private overlayService: OverlayService
  ) {}

  get(siteGroupTypes?: SiteGroupType[]) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.siteGroupsStore.setLoading(true);
        this.siteGroupsStore.set([]);
        return this.gqlService
          .listSiteGroups$(siteGroupTypes || [SiteGroupType.SITE_GROUP_STANDARD])
          .pipe(
            map(({ data, success, errors }) => {
              let siteGroups = [] as SiteGroupModel[];
              if (data) {
                siteGroups = data.map((siteGroup) => {
                  return {
                    ...siteGroup,
                  } as SiteGroupModel;
                });
                this.siteGroupsStore.set(siteGroups);
              }

              this.siteGroupsStore.setLoading(false);
              return { success, errors, data: siteGroups };
            })
          );
      })
    );
  }

  async add({
    name,
    description,
    rank_order,
  }: {
    name: string;
    description: string | null;
    rank_order: number | null;
  }) {
    const { errors, success, data } = await this.gqlService
      .createSiteGroup$({ name, description, rank_order })
      .toPromise();

    let siteGroup: SiteGroupModel | null = null;
    if (success && data) {
      siteGroup = {
        ...data,
      } as SiteGroupModel;

      this.siteGroupsStore.add(siteGroup);
    }

    return { errors, success, data: siteGroup };
  }

  async update(siteGroup: SiteGroupModel) {
    this.siteGroupsStore.setLoading(true);

    const { success, errors, data } = await this.gqlService
      .updateSiteGroup$({
        id: siteGroup.id,
        name: siteGroup.name,
        description: siteGroup.description,
        rank_order: siteGroup.rank_order,
      })
      .toPromise();

    if (!success && errors) {
      this.overlayService.error(errors);
    }

    return { success, errors, data };
  }

  async remove(siteGroup: SiteGroupModel) {
    const { success, errors } = await this.gqlService.removeSiteGroup$(siteGroup.id).toPromise();
    if (success) {
      this.overlayService.success(`${siteGroup.name} successfully removed!`);
      this.siteGroupsStore.remove(siteGroup.id);
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors };
  }
}
