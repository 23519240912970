<div class="w-screen max-w-6xl">
  <div class="grid grid-cols-12">
    <ng-container *ngIf="(editMode | async) === false">
      <div
        class="pr-4 border-r col-span-4 tabular-nums"
        style="height: 80vh"
        *ngIf="site$ | async as site"
      >
        <div># {{ site.site_no }}</div>
        <div class="text-xl font-bold text-black break-words">{{ site.name }}</div>
        <div class="mt-2 mb-4 break-words">
          {{ site.city }}{{ site.city && site.state ? ', ' : '' }}{{ site.state }} {{ site.zip
          }}{{ site.zip && site.country ? ' - ' : '' }}{{ site.country | country }}
        </div>

        <div class="text-sm font-bold">Currency</div>
        <div class="mb-4">
          {{ site.currency }}
        </div>

        <div
          class="mt-4"
          *ngIf="
            site.investigator && (site.investigator.given_name || site.investigator.family_name)
          "
        >
          <div class="text-sm font-bold">Investigator</div>
          <div class="break-words">
            {{ site.investigator.given_name }} {{ site.investigator.family_name }}
          </div>
        </div>

        <div class="mt-4">
          <div class="text-sm font-bold">Target Patients</div>
          <div>{{ site.target_patients }}</div>
        </div>

        <div class="mt-4">
          <div class="text-sm font-bold">Managed By</div>
          <div class="break-words">
            {{ (organizationQuery.selectEntity(site.managed_by_id) | async)?.name || zeroHyphen }}
          </div>
        </div>

        <ng-container *ngIf="site.initiation_date != null">
          <div class="mt-4">
            <div class="text-sm font-bold">Initiation Date</div>
            <div>{{ site.initiation_date }}</div>
          </div>
        </ng-container>

        <div class="space-y-2 mt-4 text-sm">
          <div class="hidden">
            <button class="aux-link no-underline flex items-center">
              <span class="w-4 mr-2" inlineSVG="file-download.svg"></span>
              <span>View Contract</span>
            </button>
            <button class="aux-link no-underline flex items-center">
              <span class="w-4 mr-2" inlineSVG="file-download.svg"></span>
              <span>Upload/Replace Contract</span>
            </button>
          </div>
          <button class="aux-link no-underline flex items-center" (click)="editMode.next(true)">
            <pencil-outline-icon [size]="20" class="w-4 mr-2"></pencil-outline-icon>
            <span>Edit</span>
          </button>
          <div class="hidden">
            <button class="aux-link no-underline flex items-center">
              <span inlineSVG="link.svg" class="mr-2"></span>
              <span>Admin</span>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="(editMode | async) === true">
      <form
        [formGroup]="fg"
        (ngSubmit)="onSaveEdits()"
        class="pr-4 border-r col-span-4"
        style="height: 80vh"
      >
        <div class="space-y-4">
          <aux-input formControlName="site_no" label="No" validators="required"></aux-input>
          <aux-input formControlName="name" label="Name" validators="required"></aux-input>
          <div class="grid grid-cols-2 gap-5">
            <aux-input formControlName="zip" label="Zip"></aux-input>
            <aux-input formControlName="city" label="City"></aux-input>
          </div>
          <div class="grid grid-cols-2 gap-5">
            <aux-input formControlName="state" label="State"></aux-input>
            <div>
              <div class="text-xs">Country</div>
              <ng-select
                class="select select__big"
                formControlName="country"
                [clearable]="false"
                appendTo="body"
                placeholder="Country"
              >
                <ng-option [value]="country.value" *ngFor="let country of countries">
                  {{ country.label }}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-5">
            <aux-input formControlName="target_patients" label="Target Patients"></aux-input>
            <div class="aux-select">
              <label for="vendor">Managed By</label>
              <ng-select
                class="select select__big"
                id="vendor"
                formControlName="managed_by_id"
                bindValue="id"
                bindLabel="name"
                [clearable]="false"
                [searchable]="false"
                appendTo="body"
                auxFormError
                placeholder="—"
              >
                <ng-option
                  [value]="organization"
                  *ngFor="let organization of organizationQuery.selectAll() | async"
                >
                  <span [title]="organization.name">{{ organization.name }}</span>
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-5">
            <aux-input
              formControlName="initiation_date"
              label="Initiation Date"
              [type]="'date'"
              placeholder="YYYY-MM-DD"
            ></aux-input>
            <div>
              <div class="text-xs">Currency</div>
              <ng-select
                class="select select__big"
                formControlName="currency"
                [items]="currencyOptions"
                [clearable]="false"
                [searchable]="true"
                [appendTo]="'body'"
                bindLabel="label"
                bindValue="key"
                [multiple]="false"
                label="Currency"
                auxFormError
                required
              >
              </ng-select>
            </div>
          </div>
          <div>
            <div class="font-semibold my-2">Primary Investigator</div>
            <div class="grid grid-cols-2 gap-5">
              <aux-input
                [label]="'First Name'"
                formControlName="given_name"
                validators="required"
                class="flex-1"
              ></aux-input>
              <aux-input
                [label]="'Last Name'"
                formControlName="family_name"
                validators="required"
                class="flex-1"
              ></aux-input>
            </div>
          </div>
        </div>

        <div class="flex mt-10 space-x-4">
          <button class="w-48 text-sm btn btn--blue" type="submit">Save Edits</button>
          <button
            class="text-sm font-normal aux-link focus:outline-none"
            (click)="editModeCancel()"
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </ng-container>

    <div class="col-span-8 pl-8">
      <div class="mb-8">
        <div class="border-b border-gray-200">
          <nav class="flex -mb-px space-x-8" aria-label="Tabs">
            <ng-container *ngFor="let tab of tabs; index as i">
              <button
                type="button"
                *ngIf="tab.show | async"
                (click)="onTabChange(i)"
                class="px-1 py-4 text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
                [ngClass]="{
                  'border-aux-blue-light text-aux-blue-light': i == activeTabIndex,
                  'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                    i != activeTabIndex
                }"
              >
                {{ tab.label }}
              </button>
            </ng-container>
          </nav>
        </div>
      </div>
      <div *ngIf="activeTabIndex === 0">
        <div class="flex flex-wrap -mb-px space-x-4">
          <ng-container *ngIf="(this.patientGroups$ | async)?.length !== 0">
            <ng-container *ngFor="let patientGroup of $any(patientGroups$ | async)">
              <button
                type="button"
                class="px-4 py-2 rounded text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
                [ngClass]="{
                  'border-aux-blue-light bg-aux-blue-light text-white':
                    patientGroup.id === selectedPatientGroupId,
                  'border-transparent text-gray-500 hover:text-gray-700':
                    patientGroup.id !== selectedPatientGroupId
                }"
                (click)="onTabChange(visitCostsTabIndex, patientGroup.id)"
              >
                {{ patientGroup.name }}
              </button>
            </ng-container>
          </ng-container>
          <button
            type="button"
            class="px-4 py-2 rounded text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
            [ngClass]="{
              'border-aux-blue-light bg-aux-blue-light text-white':
                selectedPatientGroupId === commonConstants.PATIENT_VISITS_GROUP_ID,
              'border-transparent text-gray-500 hover:text-gray-700':
                selectedPatientGroupId !== commonConstants.PATIENT_VISITS_GROUP_ID
            }"
            (click)="onTabChange(visitCostsTabIndex, commonConstants.PATIENT_VISITS_GROUP_ID)"
          >
            Visit Costs
          </button>
        </div>

        <ng-container *ngIf="$any(loading$ | async)">
          <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
        </ng-container>

        <ng-container class="mr-2" *ngIf="!$any(loading$ | async)">
          <div class="flex justify-end mb-1">
            <span class="text-sm text-aux-warn">{{ currencyWarning }}</span>
            <button
              *ngIf="(editModeGrid$ | async) === false"
              type="button"
              class="text-xxs btn btn--secondary h-8"
              [disabled]="(this.gridData$ | async)?.length === 0"
              (click)="editModeGrid$.next(true); editGrid()"
            >
              <span inlineSVG="pencil.svg" [setSVGAttributes]="{ class: 'w-4 h-4' }"></span>
              <span class="ml-0.5">Edit</span>
            </button>
            <button
              *ngIf="(editModeGrid$ | async) === true"
              type="button"
              class="text-xxs btn btn--secondary h-8"
              (click)="cancelEditMode()"
            >
              <span inlineSVG="x.svg" [setSVGAttributes]="{ class: 'w-4 h-4' }"></span>
              <span class="ml-0.5">Cancel</span>
            </button>
            <button
              *ngIf="(editModeGrid$ | async) === true"
              class="ml-1 text-xxs h-8 btn btn--success"
              (click)="onSaveAll()"
              [disabled]="btnDisabled$ | async"
            >
              <span class="spinner w-5 h-5" *ngIf="(btnLoading$ | async) === true"></span>
              <span
                *ngIf="(btnLoading$ | async) === false"
                inlineSVG="check.svg"
                [setSVGAttributes]="{ class: 'w-4 h-4' }"
              ></span>
              <span class="ml-2">Save</span>
            </button>
          </div>
          <ag-grid-angular
            style="max-width: 718px; height: 400px"
            class="ag-theme-alpine mb-8 w-full tabular-nums site-dialog-table"
            [rowData]="gridData$ | async"
            [gridOptions]="gridOptions"
            (cellValueChanged)="cellValueChanged($event)"
            [enableFillHandle]="(editModeGrid$ | async) === true"
            (gridReady)="onGridReady($event)"
            (firstDataRendered)="onDataRendered($event)"
            (columnResized)="autoSize()"
            [enableBrowserTooltips]="true"
          >
          </ag-grid-angular>
        </ng-container>
      </div>
      <div *ngIf="activeTabIndex === 1">
        <ng-container *ngIf="$any(loading$ | async)">
          <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
        </ng-container>

        <ng-container class="mr-2" *ngIf="!$any(loading$ | async)">
          <div *ngIf="(this.gridData$ | async)?.length !== 0" class="flex justify-end mb-1">
            <span class="text-sm text-aux-warn">{{ currencyWarning }}</span>
            <button
              *ngIf="(editModeGrid$ | async) === false"
              type="button"
              class="text-xxs btn btn--secondary h-8"
              (click)="editModeGrid$.next(true); editGrid()"
            >
              <span inlineSVG="pencil.svg" [setSVGAttributes]="{ class: 'w-4 h-4' }"></span>
              <span class="ml-0.5">Edit</span>
            </button>
            <button
              *ngIf="(editModeGrid$ | async) === true"
              type="button"
              class="text-xxs btn btn--secondary h-8"
              (click)="cancelEditMode()"
            >
              <span inlineSVG="x.svg" [setSVGAttributes]="{ class: 'w-4 h-4' }"></span>
              <span class="ml-0.5">Cancel</span>
            </button>
            <button
              *ngIf="(editModeGrid$ | async) === true"
              class="ml-1 text-xxs h-8 btn btn--success"
              (click)="onSaveAll()"
              [disabled]="btnDisabled$ | async"
            >
              <span class="spinner w-5 h-5" *ngIf="(btnLoading$ | async) === true"></span>
              <span
                *ngIf="(btnLoading$ | async) === false"
                inlineSVG="check.svg"
                [setSVGAttributes]="{ class: 'w-4 h-4' }"
              ></span>
              <span class="ml-2">Save</span>
            </button>
          </div>
          <ag-grid-angular
            style="max-width: 718px; height: 400px"
            class="ag-theme-alpine mb-8 w-full tabular-nums site-dialog-table"
            [rowData]="gridData$ | async"
            [gridOptions]="gridOptions"
            (cellValueChanged)="cellValueChanged($event)"
            (gridReady)="onGridReady($event)"
            (firstDataRendered)="onDataRendered($event)"
            [enableFillHandle]="(editModeGrid$ | async) === true"
            (columnResized)="autoSize()"
            [enableBrowserTooltips]="true"
          >
          </ag-grid-angular>
        </ng-container>
      </div>
      <div *ngIf="activeTabIndex === 2">
        <ng-container *ngIf="site$ | async as site">
          <div class="mb-8">
            <div class="border-b border-gray-200">
              <nav class="flex -mb-px space-x-8" aria-label="Tabs">
                <ng-container *ngFor="let tab of otherTabs; index as i">
                  <button
                    *ngIf="tab.show | async"
                    type="button"
                    (click)="activeOtherTabIndex = i"
                    class="px-1 py-4 text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
                    [ngClass]="{
                      'border-aux-blue-light text-aux-blue-light': i == activeOtherTabIndex,
                      'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                        i != activeOtherTabIndex
                    }"
                  >
                    {{ tab.label }}
                  </button>
                </ng-container>
              </nav>
            </div>
          </div>
          <ng-container *ngIf="activeOtherTabIndex === 0">
            <aux-file-manager
              #manager
              [fetchFilesOnInit]="true"
              [pathFn]="getFilePaths(site, 'contracts')"
              [metadataFn]="getMetadata('contracts')"
              [insertDocument]="true"
              [document_entity_id]="siteId"
              [document_entity_type_id]="SITE"
              [document_type_id]="DOCUMENT_SITE_AGREEMENTS"
              class="h-24"
            ></aux-file-manager>
            <aux-file-viewer
              [fileManager]="manager"
              class="max-h-60 overflow-auto mt-4"
              [disableFirstFileMargin]="true"
            ></aux-file-viewer>
          </ng-container>
          <ng-container *ngIf="activeOtherTabIndex === 1">
            <div
              class="aux-link cursor-pointer flex justify-center mb-8"
              (click)="downloadPatientTemplate()"
            >
              <span class="spinner w-6 h-6 mr-3" *ngIf="templateLoading$ | async"></span>
              Download the template
            </div>

            <aux-file-manager
              #manager
              [fetchFilesOnInit]="true"
              [pathFn]="getFilePaths(site, 'template')"
              [metadataFn]="getMetadata()"
              (onUploadSuccess)="onUploadSuccess()"
              [document_entity_id]="siteId"
              [document_entity_type_id]="SITE"
              [document_type_id]="DOCUMENT_SITE_BUDGET"
              class="h-24"
            ></aux-file-manager>
            <aux-file-viewer
              [fileManager]="manager"
              class="max-h-60 overflow-auto mt-4"
              [disableFirstFileMargin]="true"
            ></aux-file-viewer>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
