import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';

@Component({
  template: `
    <div class="flex justify-end items-center whitespace-nowrap" [attr.auto-qa]="autoTestAttribute">
      <div>{{ value !== 0 ? value : '—' }}</div>
      <span
        class="ml-1.5"
        [setSVGAttributes]="{ class: 'w-3.5 h-3.5' }"
        *ngIf="icon"
        [inlineSVG]="icon"
        [ngClass]="colorClass"
      ></span>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VariationStatusComponent
  extends AgCellWrapperComponent
  implements ICellRendererAngularComp {
  icon!: string;

  value!: number;

  colorClass!: string;

  textClass!: string;

  agInit(
    params: ICellRendererParams & {
      reverse?: boolean;
    }
  ): void {
    const variation = params.value || 0;

    const showUp = params.reverse ? variation < 0 : variation > 0;
    const showDown = params.reverse ? variation > 0 : variation < 0;

    if (showUp) {
      this.colorClass = 'text-aux-red-dark';
      this.icon = 'arrow-up-circle.svg';
    } else if (showDown) {
      this.colorClass = 'text-aux-green-dark';
      this.icon = 'arrow-down-circle.svg';
    }

    this.value = params.formatValue ? params.formatValue(Math.abs(variation)) : Math.abs(variation);

    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }
}
