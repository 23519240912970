import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Color, Label, SingleOrMultiDataSet } from 'ng2-charts';
import * as Chart from 'chart.js';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import {
  ApprovalType,
  BudgetType,
  ChangeOrderStatus,
  EntityType,
  EventType,
  GqlService,
} from '@services/gql.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { OverlayService } from '@services/overlay.service';
import {
  CompareBudgetVersion,
  CompareComponent,
  CompareGridData,
} from '@components/compare/compare.component';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { OrganizationStore } from '@models/organization/organization.store';
import { AuthQuery } from '@models/auth/auth.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { Utils } from '@services/utils';
import { memo } from 'helpful-decorators';
import { TrialUserQuery } from '@models/trial-users/trial-user.query';
import { ApiService } from '@services/api.service';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { last } from 'lodash-es';

import { ExcelExportParams } from 'ag-grid-community';
import { EventService } from '@services/event.service';
import { ChangeOrderModel } from '../change-order/state/change-order.store';
import { ChangeOrderQuery } from '../change-order/state/change-order.query';
import { ChangeOrderService } from '../change-order/state/change-order.service';
import { ChangeOrderSharedService } from '../change-order/state/change-order-shared.service';
import { ChangeOrderUploadComponent } from '../change-order/change-order-upload/change-order-upload.component';
import { ChangeOrderBudgetUploadComponent } from './change-order-budget-upload.component';
import { ROUTING_PATH } from '../../../../app-routing-path.const';

@UntilDestroy()
@Component({
  templateUrl: './change-order-detail.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderDetailComponent {
  @ViewChild(FileManagerComponent) fileManager?: FileManagerComponent;

  @ViewChild(CompareComponent) compare!: CompareComponent;

  budgetLoading$ = new BehaviorSubject(false);

  myChart: Chart | null = null;

  legendColors = ['#095b95', '#009ada', '#f3f6f7', '#e9f6ff'];

  changeOrdersLink = `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}`;

  costTotalsChart$ = new BehaviorSubject<{
    labels: Label[];
    type: ChartType;
    data: SingleOrMultiDataSet;
    options: ChartOptions;
    datasets: ChartDataSets[];
    colors: Color[];
    legend: boolean;
    show: boolean;
  } | null>(null);

  categoryDrivingChart$ = new BehaviorSubject<{
    labels: Label[];
    type: ChartType;
    data: SingleOrMultiDataSet;
    options: ChartOptions;
    datasets: ChartDataSets[];
    colors: Color[];
    legend: boolean;
    show: boolean;
  } | null>(null);

  actDiffsChart$ = new BehaviorSubject<{
    [key: string]: any;
  } | null>(null);

  analyticsCard = {
    co_count_activities_with_increased_units: 0,
    co_count_activities_with_increased_cost: 0,
    variance_total_cost_AC: '',
    // co_pie_cost_type_totals_data: '',
    services_total_AC: '',
    services_total_percent_AC_Formatted: '',
    investigator_total_AC: '',
    investigator_total_percent_AC_Formatted: '',
    pass_through_total_AC: '',
    pass_through_total_percent_AC_Formatted: '',
    co_category_amount_differences: [
      {
        amount: 0,
        amount_f: Utils.currencyFormatter(0),
        percent: Utils.percentageFormatter(0),
        name: 'All Other',
      },
    ],
  };

  loading$ = combineLatest([
    this.changeOrderQuery.selectLoading(),
    this.budgetLoading$,
    this.trialUserQuery.selectLoading(),
  ]).pipe(map((arr) => arr.some((x) => x)));

  changeOrder$ = new BehaviorSubject<ChangeOrderModel | null>(null);

  zeroHyphen = Utils.zeroHyphen;

  show_preview$ = combineLatest([this.changeOrder$, this.authQuery.isUser$]).pipe(
    map(([change_order, is_user]) => {
      return (
        change_order?.change_order_status === ChangeOrderStatus.STATUS_PENDING_REVIEW && is_user
      );
    })
  );

  organization$ = this.changeOrder$.pipe(
    switchMap((co) => {
      return this.organizationQuery.selectEntity(co?.organization_id);
    })
  );

  primaryBudgetId$ = combineLatest([this.changeOrder$, this.organization$]).pipe(
    map(([co, org]) => {
      return (
        co?.approved_budget_version?.budget_version_id ||
        this.organizationQuery.getPrimaryBudgetVersion(org?.id || '')?.budget_version_id
      );
    })
  );

  changeOrderBudgetId$ = combineLatest([this.changeOrder$, this.organization$]).pipe(
    map(([co]) => {
      return this.organizationQuery.getBudgetVersion(
        co?.organization_id || '',
        BudgetType.BUDGET_CHANGE_ORDER,
        co?.id || '',
        EntityType.CHANGE_ORDER
      )?.budget_version_id;
    })
  );

  status$ = this.changeOrder$.pipe(
    map((changeOrder) => {
      let status = '';
      if (changeOrder) {
        switch (changeOrder.change_order_status) {
          case ChangeOrderStatus.STATUS_PENDING_REVIEW:
            status = 'Pending Review';
            break;
          case ChangeOrderStatus.STATUS_PENDING_APPROVAL:
            status = 'Pending Approval';
            break;
          case ChangeOrderStatus.STATUS_APPROVED:
            status = 'Approved';
            break;
          case ChangeOrderStatus.STATUS_DECLINED:
            status = 'Declined';
            break;
          default:
            break;
        }
      }
      return status;
    })
  );

  approvedBy$ = this.changeOrder$.pipe(
    map((co) => {
      if (
        !co ||
        (co.change_order_status !== ChangeOrderStatus.STATUS_DECLINED &&
          co.change_order_status !== ChangeOrderStatus.STATUS_APPROVED)
      ) {
        return '';
      }

      const approval = co.approvals?.[0];

      const userFormat =
        this.changeOrderSharedService.userFormatter(approval?.aux_user_id || '') || '';
      const time = Utils.dateFormatter(approval?.approval_time || '');
      return `by ${userFormat} on ${time}`;
    })
  );

  isPendingReview$ = this.statusCheck(ChangeOrderStatus.STATUS_PENDING_REVIEW);

  isPendingApproval$ = this.statusCheck(ChangeOrderStatus.STATUS_PENDING_APPROVAL);

  isApproved$ = this.statusCheck(ChangeOrderStatus.STATUS_APPROVED);

  isDeclined$ = this.statusCheck(ChangeOrderStatus.STATUS_DECLINED);

  btnLoading$ = new BehaviorSubject<
    | 'approval'
    | 'delete'
    | 'download'
    | 'approve'
    | 'decline'
    | 'admin-review'
    | 'upload'
    | 'replace'
    | 'lre-download'
    | false
  >(false);

  fromBudgetVersion$ = this.primaryBudgetId$.pipe(
    filter((id) => !!id),
    map((id) => {
      return {
        budget_version_id: id,
        budget_name: 'Current Budget',
        budget_type: BudgetType.BUDGET_PRIMARY,
      } as CompareBudgetVersion;
    })
  );

  toBudgetVersion$ = combineLatest([this.changeOrderBudgetId$, this.changeOrder$]).pipe(
    map(([id, co]) => {
      if (!(!!id && !!co)) {
        return null;
      }

      return {
        budget_version_id: id,
        budget_name: `Change Order ${co?.change_order_no}`,
        budget_type: BudgetType.BUDGET_CHANGE_ORDER,
      } as CompareBudgetVersion;
    })
  );

  constructor(
    private changeOrderQuery: ChangeOrderQuery,
    private changeOrderService: ChangeOrderService,
    private router: Router,
    private route: ActivatedRoute,
    public authQuery: AuthQuery,
    private mainQuery: MainQuery,
    private organizationQuery: OrganizationQuery,
    private organizationStore: OrganizationStore,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private trialUserQuery: TrialUserQuery,
    private changeOrderSharedService: ChangeOrderSharedService,
    private apiService: ApiService,
    private eventService: EventService
  ) {
    this.route.paramMap
      .pipe(
        map((params) => params.get('id')),
        switchMap((id) => {
          const co = this.changeOrderQuery.getEntity(id);
          if (co) {
            return this.changeOrderQuery.selectEntity(id);
          }

          return this.changeOrderService.getOne(id || '').pipe(
            switchMap(({ success, data }) => {
              if (success && data) {
                return this.changeOrderQuery.selectEntity(id);
              }
              return of(null);
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe((changeOrder) => {
        this.organizationStore.setActive(changeOrder?.organization_id || '');
        this.changeOrder$.next(changeOrder || null);
      });

    this.eventService
      .select$(EventType.TRIAL_CHANGED)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigateByUrl(
          `${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}`
        );
      });
  }

  isApproveButtonDisableManually$ = new BehaviorSubject(false);

  isApproveButtonDisable$ = this.toBudgetVersion$.pipe(map((toBudgetVersion) => !toBudgetVersion));

  pathFn: () => string = () => '';

  @memo()
  isBtnLoading(str: string) {
    return this.btnLoading$.pipe(map((x) => x === str));
  }

  statusCheck(status: ChangeOrderStatus) {
    return this.changeOrder$.pipe(map((x) => x?.change_order_status === status));
  }

  async onDelete() {
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('delete');
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Change Order',
      message: `Are you sure you want to remove Change Order ${co.change_order_no}?`,
      okBtnText: 'Remove',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });
    const event = await resp.afterClosed$.toPromise();
    if (event.data?.result) {
      const { success } = await this.changeOrderService.remove(co, event.data.textarea);

      if (success) {
        this.router.navigateByUrl(
          `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}`
        );
      }
    }
    this.btnLoading$.next(false);
  }

  async onDownloadCO() {
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('download');
    const { success, data, errors } = await this.changeOrderQuery.downloadCO(co.id);

    if (success && data) {
      this.overlayService.success();
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  }

  async onReturnToAdminReview() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const co = this.changeOrder$.getValue()!;

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('admin-review');
    const resp = this.overlayService.openConfirmDialog({
      header: `Return to Pending Review?`,
      message: `Are you sure you want to return Change Order ${co.change_order_no} to Pending Review?`,
      okBtnText: 'Return to Admin Review',
      textarea: {
        label: 'Reason for return to Admin Review',
        required: true,
      },
    });
    const event = await resp.afterClosed$.toPromise();
    if (event.data?.result) {
      const { success, data, errors } = await this.changeOrderService.update({
        id: co.id,
        organization_id: co.organization_id,
        change_order_status: ChangeOrderStatus.STATUS_PENDING_REVIEW,
        admin_review_reason: event.data.textarea,
      });

      if (success && data) {
        this.overlayService.success();
      } else {
        this.overlayService.error(errors);
      }
    }

    await this.gqlService
      .processEvent$({
        type: EventType.CHANGE_ORDER_BACK_TO_PENDING_REVIEW,
        entity_type: EntityType.CHANGE_ORDER,
        entity_id: co.id,
      })
      .toPromise();

    this.btnLoading$.next(false);
  }

  async onDownloadLREBudget() {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const trial = this.mainQuery.getSelectedTrial()!;
    const co = this.changeOrder$.getValue();
    const org = (await this.organization$.pipe(first()).toPromise())!;
    const bv =
      co?.approved_budget_version || this.organizationQuery.getPrimaryBudgetVersion(org.id);
    let key = bv?.bucket_key!;
    key = key.replace('public/', '');
    const budget_name = bv?.budget_name!;
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('lre-download');

    const [year, month, date] = last(key.split('/'))?.slice(0, 10)?.split('-') || [];
    const ex = last(key.split('.'));

    const filename = `${trial.name}_${org.name}_Budget ${budget_name}_${year}.${month}.${date}.${ex}`;

    await this.apiService.downloadFileFromPath(key, filename);

    this.btnLoading$.next(false);
  }

  getDynamicExcelParams(): ExcelExportParams {
    const org = this.organizationQuery.getEntity(this.changeOrder$.value?.organization_id);

    const currentTrial = this.mainQuery.getSelectedTrial();

    const changeOrderNo = this.changeOrder$.value?.change_order_no;

    const coPendingApprovalDate = this.changeOrder$.value?.create_date?.slice(0, 10);

    return currentTrial && org
      ? {
          fileName: `${currentTrial.short_name}_${org.name}_Change Order ${changeOrderNo}_Compare_${coPendingApprovalDate}.xlsx`,
        }
      : {};
  }

  async onSendApproval() {
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('approval');

    const { success, data, errors } = await this.changeOrderService.update({
      id: co.id,
      organization_id: co.organization_id,
      change_order_status: ChangeOrderStatus.STATUS_PENDING_APPROVAL,
    });

    if (success && data) {
      this.overlayService.success();
    } else {
      this.overlayService.error(errors);
    }

    await this.gqlService
      .processEvent$({
        type: EventType.CHANGE_ORDER_PENDING_APPROVAL,
        entity_type: EntityType.CHANGE_ORDER,
        entity_id: co.id,
      })
      .toPromise();

    this.btnLoading$.next(false);
  }

  async onDecline() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const co = this.changeOrder$.getValue()!;
    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('decline');

    const resp = this.overlayService.openConfirmDialog({
      header: 'Decline Change Order?',
      message: `Are you sure you want to decline Change Order ${co.change_order_no}?`,
      okBtnText: 'Decline',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });
    const event = await resp.afterClosed$.toPromise();

    if (!event.data?.result) {
      this.btnLoading$.next(false);
      return;
    }

    const { success: approveSuccess, errors: approveErrors } = await this.gqlService
      .approveRule$({
        approved: false,
        comments: '',
        permission: 'PERMISSION_APPROVE_CHANGE_ORDER',
        approval_type: ApprovalType.APPROVAL_CHANGE_ORDER,
        entity_id: co.id,
        entity_type: EntityType.CHANGE_ORDER,
        activity_details: '{}',
      })
      .toPromise();

    if (!approveSuccess) {
      this.overlayService.error(approveErrors);
      this.btnLoading$.next(false);
      return;
    }

    const { success, data, errors } = await this.changeOrderService.update({
      id: co.id,
      organization_id: co.organization_id,
      change_order_status: ChangeOrderStatus.STATUS_DECLINED,
      decline_reason: event.data.textarea,
    });

    await this.gqlService
      .processEvent$({
        type: EventType.CHANGE_ORDER_DECLINED,
        entity_type: EntityType.CHANGE_ORDER,
        entity_id: co.id,
      })
      .toPromise();

    if (!(success && data)) {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  }

  async onApprove(force = false) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const co = this.changeOrder$.getValue()!;
    const org = await this.organization$.pipe(first()).toPromise();
    if (!org) {
      return;
    }

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('approve');

    let resp!: CustomOverlayRef<{ result: boolean; textarea: string }, any>;

    if (force) {
      resp = this.overlayService.openConfirmDialog({
        header: `Force Approve Change Order?`,
        message: `Selecting Force Approve will replace the current ${org.name} budget with Change Order ${co.change_order_no}.  Do you want to proceed?`,
        okBtnText: 'Force Approve',
      });
    } else {
      resp = this.overlayService.openConfirmDialog({
        header: `Approve Change Order?`,
        message: `Selecting Approve will replace the current ${org.name} budget with Change Order ${co.change_order_no}. Do you want to proceed?`,
        okBtnText: 'Approve',
      });
    }

    const event = await resp.afterClosed$.toPromise();

    if (!event.data?.result) {
      this.btnLoading$.next(false);
      return;
    }

    this.isApproveButtonDisableManually$.next(true);

    const { success: approveSuccess, errors: approveErrors } = await this.gqlService
      .approveRule$({
        approved: true,
        comments: '',
        permission: 'PERMISSION_APPROVE_CHANGE_ORDER',
        approval_type: ApprovalType.APPROVAL_CHANGE_ORDER,
        entity_id: co.id,
        entity_type: EntityType.CHANGE_ORDER,
        activity_details: '{}',
      })
      .toPromise();

    if (!approveSuccess) {
      this.overlayService.error(approveErrors);
      this.btnLoading$.next(false);
      return;
    }

    const { success, data, errors } = await this.changeOrderService.approveChangeOrder({
      id: co.id,
      organization_id: co.organization_id,
    });

    await this.gqlService
      .processEvent$({
        type: EventType.CHANGE_ORDER_APPROVED,
        entity_type: EntityType.CHANGE_ORDER,
        entity_id: co.id,
      })
      .toPromise();

    if (!(success && data)) {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  }

  getFilePath(org_id: string, co_id: string) {
    const trial_id = this.mainQuery.getValue().trialKey;
    return `trials/${trial_id}/vendors/${org_id}/changeorders/${co_id}/`;
  }

  onChangeOrderBudgetUpload = async () => {
    if (this.btnLoading$.getValue()) {
      return;
    }

    const org = await this.organization$.pipe(first()).toPromise();
    if (!org) {
      return;
    }

    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }

    if (this.fileManager) {
      const files = this.fileManager.fileQuery.getAll();

      const match = files[0].key.match(/\.([^.]+)$/);
      if (match?.[1] !== 'csv') {
        this.overlayService.error('File type must be a .csv!');
        return;
      }

      this.btnLoading$.next('upload');

      const file = files[0];
      const key = `${this.getFilePath(org.id, co.id)}${file.key}`;

      this.fileManager.fileStore.update(file.id, {
        ...file,
        key,
      });

      const fileSuccess = await this.fileManager.fileService.uploadFiles({ admin: '1' });

      if (fileSuccess) {
        const { success, errors } = await this.gqlService
          .processEvent$({
            type: EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED,
            entity_type: EntityType.ORGANIZATION,
            entity_id: org.id,
            bucket_key: `public/${key}`,
            payload: JSON.stringify({
              change_order_id: co.id,
              change_order_status: co.change_order_status,
            }),
          })
          .toPromise();

        if (success) {
          this.overlayService.success(`Budget is processing. Please wait...`);
        } else {
          this.apiService.removeFile(key);
          this.overlayService.error(errors);
        }
      }
    }
    this.btnLoading$.next(false);
  };

  async onReplaceBudget() {
    if (this.btnLoading$.getValue()) {
      return;
    }
    const org = await this.organization$.pipe(first()).toPromise();
    if (!org) {
      return;
    }
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    this.btnLoading$.next('replace');
    const modalResult = this.overlayService.open<
      any,
      {
        change_order_id: string;
        change_order_status?: ChangeOrderStatus;
        organization_id: string;
      }
    >({
      content: ChangeOrderBudgetUploadComponent,
      data: {
        change_order_id: co.id,
        change_order_status: co.change_order_status,
        organization_id: org.id,
      },
    });

    await modalResult.afterClosed$.toPromise();
    this.btnLoading$.next(false);
  }

  async onEditChangeOrder() {
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    const result = this.overlayService.open({
      content: ChangeOrderUploadComponent,
      data: {
        id: co.id,
      },
      overlayConfig: {
        hasBackdrop: false,
        panelClass: [
          'modal',
          'is-active',
          'cdk-global-overlay-wrapper',
          'cdk-overlay-dark-backdrop',
          'cdk-overlay-backdrop-showing',
          'justify-center',
          'items-center',
        ],
      },
      closeButton: false,
    });
    await result.afterClosed$.toPromise();
  }

  acPercentFormat(v: number) {
    return Utils.agPercentageFormatterAbsolute(
      {
        value: v,
      },
      { maximumFractionDigits: 0, minimumFractionDigits: 0 }
    );
  }

  onBudgetData(arr: CompareGridData[]) {
    // do some calculations with array and update the totals in this component
    // this.totals.pass_through_total_percent_AC = 5;
    const diffs = Utils.getCompareDiffs(arr);

    this.analyticsCard.co_count_activities_with_increased_units =
      diffs.count_activities_with_increased_units;
    this.analyticsCard.co_count_activities_with_increased_cost =
      diffs.count_activities_with_increased_cost;
    this.analyticsCard.variance_total_cost_AC = Utils.currencyFormatter(diffs.variance_total_cost);
    // this.analyticsCard.co_pie_cost_type_totals_data = []
    this.analyticsCard.services_total_AC = Utils.currencyFormatter(diffs.delta_services);
    this.analyticsCard.services_total_percent_AC_Formatted = this.acPercentFormat(
      diffs.delta_services / diffs.variance_total_cost
    );
    this.analyticsCard.investigator_total_AC = Utils.currencyFormatter(diffs.delta_investigator);
    this.analyticsCard.investigator_total_percent_AC_Formatted = this.acPercentFormat(
      diffs.delta_investigator / diffs.variance_total_cost
    );
    this.analyticsCard.pass_through_total_AC = Utils.currencyFormatter(diffs.delta_pass_through);
    this.analyticsCard.pass_through_total_percent_AC_Formatted = this.acPercentFormat(
      diffs.delta_pass_through / diffs.variance_total_cost
    );

    let total_remaining_diff = Object.values(diffs.category_amount_differences).reduce(
      (value, accumulator) => {
        return value + accumulator;
      },
      0
    );

    // Categories driviing increase
    const total_cat_diff = total_remaining_diff;
    const category_amount_differences = { ...diffs.category_amount_differences };
    const cat_amt_len = Object.keys(category_amount_differences).length;
    const categories_required = cat_amt_len >= 3 ? 3 : cat_amt_len;
    this.analyticsCard.co_category_amount_differences = [];

    for (let i = 0; i < categories_required; i++) {
      // grab highest one
      const highest_obj = Object.entries(category_amount_differences).sort(
        (a, b) => b[1] - a[1]
      )[0];
      if (highest_obj) {
        // subtract total_cat_diff
        total_remaining_diff -= highest_obj[1];
        this.analyticsCard.co_category_amount_differences.push({
          name: highest_obj[0],
          amount: highest_obj[1],
          amount_f: Utils.currencyToAccounting(
            Utils.currencyFormatter(highest_obj[1], {
              currencySign: 'accounting',
            })
          ),
          percent: Utils.percentageFormatter(highest_obj[1] / total_cat_diff),
        });
        // remove from list
        delete category_amount_differences[highest_obj[0]];
      }
    }
    this.analyticsCard.co_category_amount_differences.push({
      name: 'All Other',
      amount: total_remaining_diff,
      amount_f: Utils.currencyToAccounting(
        Utils.currencyFormatter(total_remaining_diff, {
          currencySign: 'accounting',
        })
      ),
      percent: Utils.percentageFormatter(total_remaining_diff / total_cat_diff),
    });
    this.createDiffChart(diffs);
  }

  createDiffChart(diffs: {
    delta_services_positive: number;
    delta_pass_through_positive: number;
    delta_investigator_positive: number;
    delta_services_negative: number;
    delta_pass_through_negative: number;
    delta_investigator_negative: number;
    category_activity_amount_differences: {
      services_positive: { [key: string]: number };
      services_negative: { [key: string]: number };
      pass_through_positive: { [key: string]: number };
      pass_through_negative: { [key: string]: number };
      investigator_positive: { [key: string]: number };
      investigator_negative: { [key: string]: number };
    };
  }) {
    const barOptions_stacked = {
      tooltips: {
        enabled: true,
        mode: 'single',
        displayColors: false,
        callbacks: {
          label: function label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData) {
            console.log(data);
            console.log(tooltipItem);
            const amt = parseFloat(tooltipItem.value || '');
            const tooltipStrArr = [
              `Total: ${Utils.currencyFormatter(parseFloat(tooltipItem.value || '0'))}`,
              '',
            ];
            let iteritableValues = {} as { [key: string]: number };
            if (tooltipItem.yLabel === 'Services' && amt > 0) {
              iteritableValues = {
                ...diffs.category_activity_amount_differences.services_positive,
              };
            } else if (tooltipItem.yLabel === 'Services' && amt < 0) {
              iteritableValues = {
                ...diffs.category_activity_amount_differences.services_negative,
              };
            } else if (tooltipItem.yLabel === 'Pass-Through' && amt > 0) {
              iteritableValues = {
                ...diffs.category_activity_amount_differences.pass_through_positive,
              };
            } else if (tooltipItem.yLabel === 'Pass-Through' && amt < 0) {
              iteritableValues = {
                ...diffs.category_activity_amount_differences.pass_through_negative,
              };
            } else if (tooltipItem.yLabel === 'Investigator' && amt > 0) {
              iteritableValues = {
                ...diffs.category_activity_amount_differences.investigator_positive,
              };
            } else if (tooltipItem.yLabel === 'Investigator' && amt < 0) {
              iteritableValues = {
                ...diffs.category_activity_amount_differences.investigator_negative,
              };
            }
            const total = Object.values(iteritableValues).reduce((x, v) => x + v, 0);
            let total_remaining = total;
            console.log(total);
            const iterations =
              Object.keys(iteritableValues).length > 3 ? 3 : Object.keys(iteritableValues).length;

            for (let i = 0; i < iterations; i += 1) {
              let max_abs = -1;
              let max_value = 0;
              let max_activity = '';
              for (const z of Object.keys(iteritableValues)) {
                const amt2 = iteritableValues[z];
                if (Math.abs(amt2) > max_abs) {
                  max_abs = Math.abs(amt2);
                  max_value = amt2;
                  max_activity = z;
                }
              }
              const currVal = Utils.currencyFormatter(max_value);
              if (currVal !== Utils.zeroHyphen) {
                tooltipStrArr.push(`${max_activity}: ${currVal}`);
              }
              total_remaining -= max_value;
              delete iteritableValues[max_activity];
            }
            if (total_remaining !== 0) {
              tooltipStrArr.push(`All Others: ${Utils.currencyFormatter(total_remaining)}`);
            }
            return tooltipStrArr;
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Change by Cost Type',
        fontStyle: 'bold',
      },
      hover: {
        animationDuration: 0,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize: 11,
              callback: (value: number) => {
                try {
                  if (value === 0) {
                    return '0';
                  }
                  return Utils.currencyFormatter(value);
                } catch (err) {
                  console.log(`Error parsing chart axis: ${err}`);
                }
                return value;
              },
            },
            scaleLabel: {
              display: false,
            },
            gridLines: {},
            stacked: true,
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
              color: '#fff',
              zeroLineColor: '#fff',
              zeroLineWidth: 0,
            },
            ticks: {
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize: 11,
            },
            stacked: true,
          },
        ],
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      pointLabelFontFamily: 'Quadon Extra Bold',
      scaleFontFamily: 'Quadon Extra Bold',
    };

    const ctx = document.getElementById('barChart123');

    if (ctx) {
      if (this.myChart) {
        this.myChart.destroy();
      }
      // eslint-disable-next-line no-new
      this.myChart = new Chart(ctx as HTMLCanvasElement, {
        type: 'horizontalBar',
        data: {
          labels: ['Services', 'Pass-Through', 'Investigator'],
          datasets: [
            {
              data: [
                diffs.delta_services_positive,
                diffs.delta_pass_through_positive,
                diffs.delta_investigator_positive,
              ],
              backgroundColor: ['#094673', '#4f6d79', '#bacad0'],
            },
            {
              data: [
                diffs.delta_services_negative,
                diffs.delta_pass_through_negative,
                diffs.delta_investigator_negative,
              ],
              backgroundColor: ['#094673', '#4f6d79', '#bacad0'],
            },
          ],
        },
        options: barOptions_stacked as ChartOptions,
      });
    }
    // this.actDiffsChart$.next(options);
  }
}
