<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>
<ng-container *ngIf="(loading$ | async) === false">
  <div class="px-4 py-10 bg-white">
    <div class="flex justify-between" *ngIf="authQuery.name$ | async as name">
      <div class="grid" *ngIf="cardUsers$ | async as user">
        <div class="aux-black text-3xl font-bold mb-1">Welcome, {{ name }}</div>
        <div class="aux-gray-dark-100 text-lg" *ngIf="user[0]?.sponsor_organization?.name">
          {{ user[0].sponsor_organization.name }}
        </div>
      </div>
      <div *ngIf="trials$ | async as trials" class="flex items-center">
        <aux-export-excel-button
          [gridAPI]="gridAPI"
          [excelOptions]="excelOptions"
          [getDynamicExcelParamsCallback]="getDynamicExcelParams"
          [className]="'mr-2 h-auto'"
        >
        </aux-export-excel-button>
        <div class="bg-white w-64 h-16 relative rounded-md border-2 border-aux-gray-dark">
          <label for="trial-select" class="absolute text-sm pl-5 pt-2">Go To Trial</label>

          <ng-select
            id="trial-select"
            bindValue="id"
            bindLabel="short_name"
            [clearable]="false"
            class="trial-select text-aux-black portfolio h-full"
            [searchable]="false"
            [formControl]="trialFc"
            placeholder="Select Trial"
            [ngClass]="{
              filled: trialFc.value
            }"
          >
            <ng-option [value]="trial" *ngFor="let trial of trials">
              {{ !trial.onboarding_complete ? '* ' : '' }}
              {{ trial.short_name }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-aux-gray-light">
    <div class="xl:border-b"></div>

    <section class="grid grid-cols-3 gap-4 my-4 mx-7">
      <div
        [ngClass]="{
          'col-span-2': (showTaskSection$ | async) === true,
          'col-span-3': (showTaskSection$ | async) === false
        }"
      >
        <div class="bg-white border-aux-blue-dark border-l-8 flex justify-items-stretch mb-4">
          <div
            class="border-aux-gray-dark xl:border-t xl:border-b xl:border-r p-2 w-full"
            *ngIf="cardValues | async as val"
          >
            <div class="grid grid-cols-7 gap-y-4 bg-white pt-4 pb-4 w-full">
              <div class="flex">
                <div class="grid w-full">
                  <div class="text-sm text-aux-black">Total Current (LRE)</div>
                  <div class="text-xl text-aux-black">{{ val.clre | money }}</div>
                </div>
              </div>

              <div class="flex justify-center">
                <div class="border-r"></div>
              </div>

              <div class="flex">
                <div class="grid w-full">
                  <div class="text-sm text-aux-black">Total Baseline</div>
                  <div class="text-xl text-aux-black">{{ val.base | money }}</div>
                </div>
              </div>

              <div class="flex justify-center">
                <div class="border-r"></div>
              </div>

              <div class="flex">
                <div class="grid items-center w-full">
                  <div class="text-sm text-aux-black">Variance ($)</div>
                  <div class="text-xl text-aux-black flex items-center" *ngIf="val.var_cost > 0">
                    {{ val.var_cost | money }}
                    <span
                      inlineSVG="arrow-circle-up-solid.svg"
                      class="w-4 ml-1 text-aux-error"
                    ></span>
                  </div>
                  <div class="text-xl text-aux-black flex items-center" *ngIf="val.var_cost < 0">
                    {{ val.var_cost * -1 | money }}
                    <span
                      inlineSVG="arrow-circle-down-solid.svg"
                      class="w-4 ml-1 text-aux-green"
                    ></span>
                  </div>
                  <div
                    class="items-center text-xl text-aux-black text-center"
                    *ngIf="val.var_cost === 0"
                  >
                    —
                  </div>
                </div>
              </div>

              <div class="flex justify-center">
                <div class="border-r"></div>
              </div>

              <div class="flex">
                <div class="grid items-center w-full">
                  <div class="text-sm text-aux-black">Variance (%)</div>
                  <div class="text-xl text-aux-black flex items-center" *ngIf="val.var_cost > 0">
                    {{ (val.var_cost / val.base) * 100 | number: '1.2-2' }}%
                    <span
                      inlineSVG="arrow-circle-up-solid.svg"
                      class="w-4 ml-1 text-aux-error"
                    ></span>
                  </div>
                  <div class="text-xl text-aux-black flex items-center" *ngIf="val.var_cost < 0">
                    {{ (val.var_cost / val.base) * -100 | number: '1.2-2' }}%
                    <span
                      inlineSVG="arrow-circle-down-solid.svg"
                      class="w-4 ml-1 text-aux-green"
                    ></span>
                  </div>
                  <div
                    class="items-center text-xl text-aux-black text-center"
                    *ngIf="val.var_cost === 0"
                  >
                    —
                  </div>
                </div>
              </div>

              <div class="flex">
                <div class="grid w-full">
                  <div class="text-sm text-aux-black">Work Performed</div>
                  <div class="text-xl text-aux-black">{{ val.wpltd | money }}</div>
                </div>
              </div>

              <div class="flex justify-center">
                <div class="border-r"></div>
              </div>

              <div class="flex">
                <div class="grid w-full">
                  <div class="text-sm text-aux-black">Invoiced Total</div>
                  <div class="text-xl text-aux-black">{{ val.invoiced | money }}</div>
                </div>
              </div>

              <div
                class="flex justify-center"
                *ngIf="(section_portfolio_analytics_avg_month_close$ | async) === true"
              >
                <div class="border-r"></div>
              </div>

              <div
                *ngIf="(section_portfolio_analytics_avg_month_close$ | async) === true"
                class="flex"
              >
                <div class="grid w-full">
                  <div class="text-sm text-aux-black">Avg. Month Close</div>
                  <div class="text-xl text-aux-black">12 Days</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white border-l-8 border-aux-blue-dark flex justify-items-stretch">
          <div
            class="w-full border-aux-gray-dark xl:border-t xl:border-b xl:border-r"
            *ngIf="lineChart as lineChart"
          >
            <div class="grid mt-2">
              <canvas
                style="max-height: 600px; min-height: 400px"
                baseChart
                class="chart"
                [datasets]="lineChart.datasets"
                [options]="lineChart.options"
                [colors]="lineChart.colors"
                [chartType]="lineChart.type"
                [labels]="lineChart.labels"
              >
              </canvas>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-span-1 relative border-l-8 border-aux-blue-dark"
        *ngIf="showTaskSection$ | async"
      >
        <aux-portfolio-tasks class="absolute inset-0"></aux-portfolio-tasks>
      </div>
    </section>

    <div class="bg-aux-gray-light border-aux-gray-dark mt-4 mx-7 mb-16">
      <ag-grid-angular
        class="ag-theme-aux portfolio-grid tabular-nums w-full"
        [gridOptions]="$any(gridOptions$ | async)"
        [rowData]="$any(gridData$ | async)"
        (gridReady)="onGridReady($event)"
        (window:resize)="autoSize()"
        [domLayout]="'autoHeight'"
        [enableBrowserTooltips]="true"
      >
      </ag-grid-angular>
    </div>
  </div>
</ng-container>
