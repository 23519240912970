import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@services/utils';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {
  transform(value?: number | null | undefined): unknown {
    return Utils.currencyFormatter(value || 0);
  }
}
