import { Injectable } from '@angular/core';
import { getEntityType, QueryEntity } from '@datorama/akita';
import { groupBy } from 'lodash-es';
import { PaymentSchedulesStore, PaymentSchedulesState } from './payment-schedules.store';

@Injectable({ providedIn: 'root' })
export class PaymentSchedulesQuery extends QueryEntity<PaymentSchedulesState> {
  constructor(protected store: PaymentSchedulesStore) {
    super(store);
  }

  getGroupedPaymentSchedules(): Record<string, getEntityType<PaymentSchedulesState[]>> {
    return groupBy(this.getAll(), 'patient_protocol_id');
  }
}
