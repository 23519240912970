import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { ApiService, FileMetadata } from '@services/api.service';
import { AuthQuery } from '@models/auth/auth.query';
import { OverlayService } from '@services/overlay.service';
import { DocumentLibraryService } from 'src/app/pages/documents/document-library.service';
import { FileState, FileStore } from './file.store';
import { File } from './file.model';
import { FileQuery } from './file.query';

@Injectable()
export class FileService {
  constructor(
    private fileStore: FileStore,
    private fileQuery: FileQuery,
    private apiService: ApiService,
    private authQuery: AuthQuery,
    private documentLibraryService: DocumentLibraryService,
    private overlayService: OverlayService
  ) {}

  async fetchFiles(
    path: string,
    shouldAddToStore = false,
    document_entity_id?: string,
    document_entity_type_id?: string,
    document_type_id?: string
  ) {
    this.fileStore.setLoading(true);
    const resp = await this.apiService.listFiles(
      path,
      document_entity_id,
      document_entity_type_id,
      document_type_id
    );
    const fileArr = resp.map(
      (x) =>
        ({
          ...x,
          fileName: x.key.split('/').reverse()[0].slice(25),
          uploaded: true,
        } as File)
    );

    if (shouldAddToStore) {
      this.fileStore.add(fileArr);
    } else {
      this.fileStore.set(fileArr);
    }
    this.fileStore.setLoading(false);
  }

  add(files: FileList, path: string) {
    for (let i = 0; i < files.length; i++) {
      const rawFile = files.item(i);

      if (
        rawFile &&
        !this.fileQuery.hasEntity((entity: FileState) => entity.fileName === rawFile.name)
      ) {
        const id = uuid();
        const file: File = {
          uploaded: false,
          eTag: id,
          id,
          key: `${path}${new Date().toISOString()}_${rawFile.name}`,
          fileName: rawFile.name,
          lastModified: rawFile.lastModified,
          size: rawFile.size,
          rawFile,
          type: rawFile.type,
        };

        this.fileStore.add(file);
      }
    }
  }

  async removeFromStore(file: File) {
    this.fileStore.remove(file.id);
  }

  async remove(file: File) {
    if (file.uploaded) {
      const resp = await this.documentLibraryService.removeDocument(file.id);
      if (resp) {
        this.fileStore.remove(file.id);
      }
      return resp;
    }

    this.fileStore.remove(file.id);
    return true;
  }

  async removeFromS3(file: File) {
    if (file.uploaded) {
      const resp = await this.apiService.removeFile(file.id);
      if (resp) {
        this.fileStore.remove(file.id);
      }
      return resp;
    }

    this.fileStore.remove(file.id);
    return true;
  }

  uploadFile(file: File, metadata: FileMetadata = {}, insertDocument = false): Promise<boolean> {
    // this should be default metadata
    const uploadedBy = this.authQuery.getValue().sub;

    return this.apiService
      .uploadFile(file.key, file.rawFile as globalThis.File, { ...{ uploadedBy }, ...metadata })
      .then((x) => {
        if (x) {
          this.fileStore.update(file.id, { ...file, uploaded: true });

          if (insertDocument) {
            this.documentLibraryService.uploadDocument(file, metadata, file.key);
          }
        }
        return x;
      });
  }

  async uploadFiles(metadata: FileMetadata = {}, showSuccess = false, insertDocument = false) {
    const promises: Promise<boolean>[] = [];

    const files = this.fileQuery.getAll({
      filterBy: (entity) => !entity.uploaded && !!entity.rawFile,
    });

    files.forEach((file) => {
      promises.push(this.uploadFile(file, metadata, insertDocument));
    });

    this.fileStore.setLoading(true);
    try {
      const ss = await Promise.all(promises);
      this.fileStore.setLoading(false);
      const result = ss.every((x) => x);
      if (showSuccess && result && ss.length > 0) {
        this.overlayService.success(
          `${ss.length} file${ss.length > 1 ? 's' : ''} uploaded successfully!`
        );
      }
      return result;
    } catch (e) {
      this.overlayService.error(e);
      this.fileStore.setLoading(false);
    }

    return false;
  }
}
