import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { flatten } from 'lodash-es';
import {
  AmountType,
  EntityType,
  GqlService,
  NoteType,
  WorkflowDetail,
} from '@services/gql.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { Utils } from '@services/utils';
import { InvoiceCard, InvoiceModel } from './invoice.model';
import { InvoiceStore } from './invoice.store';

@Injectable({ providedIn: 'root' })
export class InvoiceService {
  constructor(
    private invoiceStore: InvoiceStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService
  ) {}

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.invoiceStore.setLoading(true);

        return this.gqlService.listInvoices$().pipe(
          map(({ data, success, errors }) => {
            let invoices = [] as InvoiceModel[];
            if (data) {
              invoices = data.map((invoice) => {
                return ({
                  ...invoice,
                  ...{
                    cards: [],
                    po_reference: invoice.purchase_order_id,
                    expense_amounts: this.mapExpenseAmountToObject(invoice.expense_amounts),
                  },
                } as unknown) as InvoiceModel;
              });
              this.invoiceStore.set(invoices);
            }

            this.invoiceStore.setLoading(false);
            return { success, errors, data: invoices };
          })
        );
      })
    );
  }

  getOne(id: string) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.invoiceStore.setLoading(true);

        return this.gqlService.getInvoice$(id);
      }),
      tap(({ success, data, errors }) => {
        if (success && data) {
          const { workflow_details } = data;

          const invReq = ({
            ...data,
            ...{
              cards: this.workflowToCards(workflow_details as WorkflowDetail[]),
              po_reference: data.purchase_order_id,
              expense_amounts: this.mapExpenseAmountToObject(data.expense_amounts),
            },
          } as unknown) as InvoiceModel;
          if (data.reasons && data.reasons.length > 0) {
            invReq.decline_reason = Utils.unscrubUserInput(
              data.reasons.find((reason) => reason.note_type === NoteType.NOTE_TYPE_DECLINE_REASON)
                ?.message || ''
            );
            invReq.delete_reason = Utils.unscrubUserInput(
              data.reasons.find((reason) => reason.note_type === NoteType.NOTE_TYPE_DELETE_REASON)
                ?.message || ''
            );
          }
          this.invoiceStore.upsert(invReq.id, invReq);
        } else {
          this.overlayService.error(errors);
        }
        this.invoiceStore.setLoading(false);
      })
    );
  }

  workflowToCards(invoice_workflow: Array<WorkflowDetail>): InvoiceCard[] {
    return invoice_workflow.map((workflow) => {
      const obj = JSON.parse(workflow.properties || '') as { properties: InvoiceCard };

      return { ...obj.properties, id: workflow.id || '' };
    });
  }

  mapExpenseAmountToObject(
    expense_amounts: {
      amount?: number | null | undefined;
      amount_type: string;
    }[]
  ) {
    const getAmount = (amountType: AmountType | string) => {
      return {
        value: expense_amounts.filter((x) => x.amount_type === amountType)[0]?.amount || 0,
        type: amountType,
      };
    };

    return {
      invoice_total: getAmount('AMOUNT_TOTAL'),
      pass_thru_total: getAmount(AmountType.AMOUNT_PASSTHROUGH),
      services_total: getAmount(AmountType.AMOUNT_SERVICE),
      discount_total: getAmount(AmountType.AMOUNT_DISCOUNT),
      investigator_total: getAmount(AmountType.AMOUNT_INVESTIGATOR),
    };
  }

  listInvoiceNumbers() {
    return this.gqlService.listInvoiceNumbers$().pipe(
      map((x) => {
        return { ...x, data: x.data ? x.data : null };
      })
    );
  }

  async add({
    id,
    organization_id,
    po_reference,
    bucket_keys,
  }: {
    id: string;
    organization_id: string;
    po_reference: string | null;
    bucket_keys: string[];
  }) {
    const { errors, success, data } = await this.gqlService
      .createInvoice$({ id, organization_id, po_reference, bucket_keys })
      .toPromise();

    let invoice: InvoiceModel | null = null;
    if (success && data) {
      const { workflow_details } = data;

      invoice = ({
        ...data,
        ...{
          cards: this.workflowToCards(workflow_details as WorkflowDetail[]),
          po_reference: data.purchase_order_id,
          expense_amounts: this.mapExpenseAmountToObject(data.expense_amounts),
        },
      } as unknown) as InvoiceModel;

      this.invoiceStore.add(invoice);
    }

    return { errors, success, data: invoice };
  }

  async update(invoice: InvoiceModel) {
    const cardPromises = [];

    this.invoiceStore.setLoading(true);

    for (const { id: cardId, lines, status, header, note } of invoice.cards) {
      // update each card
      cardPromises.push(
        this.gqlService
          .updateWorkflowDetail$({
            id: cardId,
            properties: JSON.stringify({ properties: { lines, status, header, note } }),
          })
          .toPromise()
      );
    }

    // send all the request in parallel
    const responses = await Promise.all(cardPromises);
    // check if everything is ok
    if (responses.every((x) => x.success)) {
      const expenseAmountsPromises: Promise<any>[] = [];
      for (const { value, type } of Object.values(invoice.expense_amounts)) {
        const prom = this.gqlService
          .updateExpenseAmount$({
            entity_id: invoice.id,
            amount_type: type,
            amount_value: value,
            amount_curr: 'CURRENCY_USD',
            entity_type_id: EntityType.INVOICE,
          })
          .toPromise();
        expenseAmountsPromises.push(prom);
      }
      const expenseAmountsResponses = await Promise.all(expenseAmountsPromises);

      const { success, data, errors } = await this.gqlService
        .updateInvoice$({
          id: invoice.id,
          invoice_no: invoice.invoice_no,
          invoice_date: invoice.invoice_date,
          po_reference: invoice.po_reference,
          invoice_status: invoice.invoice_status,
          due_date: invoice.due_date,
          organization_id: invoice.organization.id,
        })
        .toPromise();

      if (success && data) {
        if (invoice.decline_reason && invoice.decline_reason.length > 0) {
          const { errors: cErrors, success: cSuccess, data: cData } = await this.gqlService
            .createNote$({
              entity_ids: [invoice.id],
              entity_type: EntityType.INVOICE,
              note_type: NoteType.NOTE_TYPE_DECLINE_REASON,
              message: Utils.scrubUserInput(invoice.decline_reason),
            })
            .toPromise();

          if (cSuccess && cData) {
            this.overlayService.success();
          } else {
            this.overlayService.error(cErrors);
          }
        }

        if (invoice.admin_review_reason && invoice.admin_review_reason.length > 0) {
          const { errors: cErrors, success: cSuccess, data: cData } = await this.gqlService
            .createNote$({
              entity_ids: [invoice.id],
              entity_type: EntityType.INVOICE,
              note_type: NoteType.NOTE_TYPE_ADMIN_REVIEW_REASON,
              message: Utils.scrubUserInput(invoice.admin_review_reason),
            })
            .toPromise();

          if (cSuccess && cData) {
            this.overlayService.success();
          } else {
            this.overlayService.error(cErrors);
          }
        }
      }

      if (expenseAmountsResponses.every(({ success: eaSuccess }) => eaSuccess) && success && data) {
        this.overlayService.success('Invoice successfully updated!');
        this.invoiceStore.update(
          invoice.id,
          () =>
            (({
              ...data,
              decline_reason: invoice.decline_reason,
              cards: invoice.cards,
              po_reference: data.purchase_order_id,
              expense_amounts: invoice.expense_amounts,
            } as unknown) as InvoiceModel)
        );
        this.invoiceStore.setLoading(false);
        return true;
      }

      const messages = flatten(
        expenseAmountsResponses.filter((x) => !x.success).map((x) => x.errors)
      );
      this.overlayService.error([...messages, ...errors]);
    }
    const messages = flatten(responses.filter((x) => !x.success).map((x) => x.errors));
    this.overlayService.error(messages);

    this.invoiceStore.setLoading(false);
    return false;
  }

  async remove(invoice: InvoiceModel, delete_reason: string = '') {
    const { success, errors } = await this.gqlService
      .removeInvoice$({ id: invoice.id })
      .toPromise();
    if (success) {
      if (delete_reason.length > 0) {
        const { errors: cErrors, success: cSuccess, data: cData } = await this.gqlService
          .createNote$({
            entity_ids: [invoice.id],
            entity_type: EntityType.INVOICE,
            note_type: NoteType.NOTE_TYPE_DELETE_REASON,
            message: Utils.scrubUserInput(delete_reason),
          })
          .toPromise();

        if (cSuccess && cData) {
          this.overlayService.success();
        } else {
          this.overlayService.error(cErrors);
        }
      }
      this.overlayService.success(`${invoice.invoice_no} successfully removed!`);
      this.invoiceStore.remove(invoice.id);
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors };
  }
}
