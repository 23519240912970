import { GridOptions, RowClassParams } from 'ag-grid-community';

export const gridOptions: GridOptions = {
  rowHeight: 35,
  headerHeight: 45,
  rowClassRules: {
    'is-even': (params: RowClassParams) => {
      return !!((params.node.rowIndex || 0) % 2);
    },
    'is-odd': (params: RowClassParams) => {
      return !((params.node.rowIndex || 0) % 2);
    },
  },
  columnDefs: [
    {
      headerName: 'Field 1',
      headerClass: 'ag-header-align-center font-bold',
      cellClass: 'text-left',
      field: 'field1',
      width: 250,
      sortable: true,
      suppressMenu: true,
    },
    {
      headerName: 'Field 2',
      headerClass: 'ag-header-align-center font-bold',
      cellClass: 'text-left',
      field: 'field2',
      width: 250,
      sortable: true,
      suppressMenu: true,
    },
    {
      headerName: 'Field 3',
      headerClass: 'ag-header-align-center font-bold',
      cellClass: 'text-left',
      field: 'field3',
      width: 300,
      sortable: true,
      suppressMenu: true,
    },
  ],
};
