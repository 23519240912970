import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, EMPTY, merge as rxMerge, Observable, of } from 'rxjs';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  ExcelCell,
  ExcelExportParams,
  FirstDataRenderedEvent,
  GetGroupRowAggParams,
  GridApi,
  GridOptions,
  ProcessCellForExportParams,
  RowClassParams,
} from 'ag-grid-community';
import { RequireSome, Utils } from '@services/utils';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { Color, Label } from 'ng2-charts';
import { ChartData, ChartDataSets, ChartOptions, ChartTooltipItem, ChartType } from 'chart.js';
import { FormControl } from '@angular/forms';
import { debounceTime, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { OrganizationStore } from '@models/organization/organization.store';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { OverlayService } from '@services/overlay.service';
import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { groupBy, merge, round, sumBy, uniqBy } from 'lodash-es';
import {
  BudgetHeader,
  BudgetType,
  CreateUserCustomViewInput,
  EventType,
  GqlService,
  PeriodType,
  TrialPreferenceType,
  UpdateUserCustomViewInput,
  UserCustomView,
  ViewLocation,
} from '@services/gql.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { VariationStatusComponent } from 'src/app/pages/design-system/tables/variation-status.component';
import { AgHeaderDropdownComponent } from '@components/ag-header-dropdown.component';

import { AuthService } from '@models/auth/auth.service';
import {
  AgCellWrapperComponent,
  getWrapperCellOptions,
} from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { EventService } from '@services/event.service';
import { BudgetStore } from '../budget/state/budget.store';
import { BudgetQuery } from '../budget/state/budget.query';
import { BudgetService } from '../budget/state/budget.service';
import { BudgetPageComponent } from '../../budget-page.component';
import { BudgetUploadComponent } from '../budget/budget-upload/budget-upload.component';
import {
  actualsToDateColumnDef,
  cellSize,
  overallBudgetColumnDef,
  period_sorting,
  remainingBudgetColDef,
  rowGroupsColumnDef,
} from './column-defs';
import {
  ColumnChooserComponent,
  VisibleColumns,
} from './column-chooser-component/column-chooser.component';
import { ExtendedBudgetData } from '../budget/state/budget.model';
import { BudgetCustomCreateComponent } from '../budget/state/budget-custom-create.component';
import { BudgetCustomUpdateComponent } from '../budget/state/budget-custom-update.component';
import { TableConstants } from '../../../../constants/table.constants';
import { SnapshotModalComponent } from './snapshot-modal/snapshot-modal.component';
import { SnapshotService } from './compare-dropdown/snapshot.service';
import { BudgetEnhancedHeaderDropdownService } from './budget-enhanced-header-dropdown.service';

dayjs.extend(quarterOfYear);
dayjs.extend(isSameOrAfter);

@UntilDestroy()
@Component({
  selector: 'aux-budget-enhanced',
  templateUrl: './budget-enhanced.component.html',
  styleUrls: ['./budget-enhanced.component.css'],
  providers: [BudgetEnhancedHeaderDropdownService],
})
export class BudgetEnhancedComponent implements OnInit, AfterViewInit {
  visibleColumns: VisibleColumns = {
    overall_budget: {
      primary: true,
      units: true,
      unit_cost: true,
    },
    remaining_budget: {
      perc: true,
      costs: true,
      units: true,
    },
    actuals_to_date: {
      perc: true,
      costs: true,
      units: true,
    },
    current_period: {
      months: true,
      quarters: true,
    },
    historicals: {
      months: true,
      quarters: true,
      years: true,
    },
    forecast: {
      months: true,
      quarters: true,
      years: true,
    },
  };

  defaultColumns: ((ColDef | ColGroupDef) & {
    hideForAllVendorSelection?: boolean;
    children?: ColDef[];
  })[] = [...rowGroupsColumnDef, TableConstants.SPACER_COLUMN];

  columnDefs: (ColDef | ColGroupDef)[] = [];

  zeroHyphen = Utils.zeroHyphen;

  showSnapshotSection$ = this.launchDarklyService.select$(
    (flags) => flags.section_budget_snapshots
  );

  autoGroupColumnDef: ColDef = {
    headerName: 'Activities',
    headerClass: 'ag-header-align-center',
    minWidth: 250,
    width: 250,
    field: 'activity_name',
    tooltipField: 'activity_name',
    ...getWrapperCellOptions(),
    pinned: 'left',
    resizable: true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    comparator: (_, __, nodeA, nodeB) => {
      if (!nodeA.aggData) {
        return 0;
      }
      return nodeA.aggData.current_lre - nodeB.aggData.current_lre;
    },
    cellRendererParams: {
      suppressCount: true,
    },
  };

  gridOptions = {
    suppressPropertyNamesCheck: true,
    defaultColDef: {
      sortable: false,
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
      cellRenderer: AgCellWrapperComponent,
    },
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    groupDefaultExpanded: 1,
    suppressColumnVirtualisation: true,
    suppressCellFocus: true,
    columnDefs: [],
    excelStyles: [
      {
        id: 'header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { color: '#094673', pattern: 'Solid' },
        alignment: { horizontal: 'Center' },
      },
      {
        id: 'headerGroup',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { color: '#999999', pattern: 'Solid' },
        alignment: { horizontal: 'Center' },
      },
      {
        id: 'budget-cost',
        dataType: 'Number',
        numberFormat: { format: Utils.excelCostFormat },
      },
      {
        id: 'cell',
        font: { fontName: 'Arial', size: 11 },
      },
      {
        id: 'budget-percent',
        alignment: { horizontal: 'Right' },
        numberFormat: { format: Utils.excelPercentFormat },
      },
      {
        id: 'budget-percent-no-mult',
        dataType: 'Number',
        numberFormat: { format: Utils.excelPercentFormatWithout100Mult },
      },
      {
        id: 'budget-units',
        alignment: { horizontal: 'Right' },
        numberFormat: { format: Utils.excelUnitsFormat },
      },
      {
        id: 'total_row_header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      },
      {
        id: 'total_row',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
        numberFormat: { format: Utils.excelCostFormat },
      },
      {
        id: 'total_row_percent',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
        dataType: 'Number',
        numberFormat: { format: Utils.excelPercentFormat },
      },
    ],
    getRowClass: Utils.oddEvenRowClass,
    getRowStyle: (params: RowClassParams): any => {
      // total row
      if (params.node.level < 0) {
        return {
          display: 'none',
        };
      }
      return {};
    },
  } as GridOptions;

  compareToValue?: string = undefined;

  pendingChangesLoading = new BehaviorSubject(false);

  invoicesTotalLoading = new BehaviorSubject(false);

  wpLoading = new BehaviorSubject(false);

  excelOptions = {
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Budget',
    fileName: 'auxilius-budget.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.cost_category;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'vendor_name':
          return 280;
        case 'activity_name_label':
          return 490;
        case 'group0':
          return 280;
        default:
          return 105;
      }
    },
  } as ExcelExportParams;

  periodTypes = [
    { label: 'Month', value: PeriodType.PERIOD_MONTH },
    { label: 'Quarter', value: PeriodType.PERIOD_QUARTER },
    { label: 'Year', value: PeriodType.PERIOD_YEAR },
  ];

  selectedPeriodType = new FormControl(
    this.launchDarklyService.flags$.getValue().client_preference_budget_period_type
  );

  selectedBudgetType = new FormControl();

  budgetTypes = [
    { label: 'Primary', value: BudgetType.BUDGET_PRIMARY },
    { label: 'Secondary', value: BudgetType.BUDGET_SECONDARY },
  ];

  gridAPI?: GridApi;

  columnAPI?: ColumnApi;

  gridOptions$ = new BehaviorSubject<GridOptions>(this.gridOptions);

  gridData$ = new BehaviorSubject<any[]>([]);

  @ViewChildren('budgetFilters') budgetFilters!: QueryList<TemplateRef<any>>;

  showAnalyticsSection$: Observable<boolean>;

  showBudgetTypeSelect$: Observable<boolean>;

  selectedVendor = new FormControl('');

  isYearsOpen = false;

  isCustomOpen = false;

  areUnsavedChanges = false;

  highlightedCustom = new BehaviorSubject<number | null>(null);

  selectedYear!: string;

  selectedCustom$ = new BehaviorSubject('');

  selectedCustomIndex: number | null = null;

  customValues$ = new BehaviorSubject<(UserCustomView & { showLine: boolean })[] | null>(null);

  years: { enabled: boolean; label: number }[] = [];

  budgetGridYears: number[] | null = null;

  budgetCanvas$: Observable<{
    labels: Label[];
    type: ChartType;
    options: ChartOptions;
    datasets: ChartDataSets[];
    colors: Color[];
    legend: boolean;
    show: boolean;
  }> = this.budgetQuery.select().pipe(
    map((state) => {
      const auxilius_start_date = this.mainQuery.getAuxiliusStartDate();
      const columns = ['Services', 'Investigator', 'Pass-through'];

      const groupedData = groupBy(state.budget_data, 'cost_category');
      const timelineHeaders = this.getTimelineHeaders(
        state.header_data.find((x) => x.group_name === 'Timeline')?.date_headers
      );

      const monthLabels = timelineHeaders.months.filter((str) => {
        if (!auxilius_start_date) {
          return true;
        }

        return dayjs(new Date(`01/${str.replace('-', '/').toUpperCase()}`)).isSameOrAfter(
          dayjs(auxilius_start_date).date(1)
        );
      });

      let xAxisPeriodLabels = monthLabels;
      const selectedPeriod = this.selectedPeriodType.value as PeriodType;
      if (selectedPeriod === PeriodType.PERIOD_QUARTER) {
        xAxisPeriodLabels = timelineHeaders.quarters;
      } else if (selectedPeriod === PeriodType.PERIOD_YEAR) {
        xAxisPeriodLabels = timelineHeaders.years;
      }
      const datasets: { data: number[]; label: string }[] = [
        {
          label: 'Services',
          data: xAxisPeriodLabels.map(() => 0),
        },
        {
          label: 'Investigator',
          data: xAxisPeriodLabels.map(() => 0),
        },
        {
          label: 'Pass-through',
          data: xAxisPeriodLabels.map(() => 0),
        },
      ];
      columns.map((amtType) => {
        if (groupedData[amtType]) {
          let amtTypeIndex = -1;
          datasets.forEach((item, i) => {
            if (item.label === amtType) {
              amtTypeIndex = i;
            }
          });
          for (const month of monthLabels) {
            let headerStrConversion = month;
            let timelinePeriods = monthLabels;
            if (selectedPeriod === PeriodType.PERIOD_QUARTER) {
              headerStrConversion = `Q${dayjs(
                this.parseBudgetMonthToDate(month)
              ).quarter()} ${dayjs(this.parseBudgetMonthToDate(month)).format('YYYY')}`;
              timelinePeriods = timelineHeaders.quarters;
            } else if (selectedPeriod === PeriodType.PERIOD_YEAR) {
              headerStrConversion = dayjs(this.parseBudgetMonthToDate(month)).format('YYYY');
              timelinePeriods = timelineHeaders.years;
            }
            const periodIndex = timelinePeriods.indexOf(headerStrConversion);
            const n = round(sumBy(groupedData[amtType], `EXPENSE_FORECAST::${month}`) || 0, 2);
            const wp = round(sumBy(groupedData[amtType], `EXPENSE_WP::${month}`) || 0, 2);
            if (periodIndex !== -1 && amtTypeIndex !== -1) {
              datasets[amtTypeIndex].data[periodIndex] += n + wp;
            }
          }
        }

        return null;
      });

      return {
        show: !datasets.every((dataset) => dataset.data.every((x) => x === 0)),
        type: 'bar',
        options: {
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            tooltipFormat: '',
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  // Include a dollar sign in the ticks
                  callback(value) {
                    return Utils.currencyFormatter(value as number, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    });
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              title(item: ChartTooltipItem[], chartData: ChartData) {
                return item
                  .map((x) => x.datasetIndex || 0)
                  .map((x) => chartData?.datasets?.[x]?.label || '');
              },
              label(tooltipItem: ChartTooltipItem) {
                if (tooltipItem.yLabel && typeof tooltipItem.yLabel === 'number') {
                  return Utils.currencyFormatter(tooltipItem.yLabel, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });
                }
                return `${tooltipItem.yLabel || ''}`;
              },
            },
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
        },
        datasets,
        labels: xAxisPeriodLabels,
        legend: true,
        colors: [
          {
            backgroundColor: 'rgba(9, 91, 149, 1)',
          },
          {
            backgroundColor: '#6B9DBF',
          },
          {
            backgroundColor: '#9DBDD5',
          },
          {
            backgroundColor: 'rgba(25,100,230,0.75)',
          },
        ],
      };
    })
  );

  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  showGrid = false;

  constructor(
    private budgetStore: BudgetStore,
    private budgetService: BudgetService,
    public budgetQuery: BudgetQuery,
    public organizationQuery: OrganizationQuery,
    private organizationStore: OrganizationStore,
    private organizationService: OrganizationService,
    private budgetPageComponent: BudgetPageComponent,
    private cdr: ChangeDetectorRef,
    private launchDarklyService: LaunchDarklyService,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private eventService: EventService,
    private mainQuery: MainQuery,
    private snapshotService: SnapshotService,
    public authService: AuthService
  ) {
    this.snapshotService.getSnapshotList().pipe(untilDestroyed(this)).subscribe();
    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          this.compareToValue = undefined;

          return this.gqlService.getTrialPreference$(TrialPreferenceType.BUDGET_GRID_YEARS).pipe(
            tap((prefBudgetGridYears) => {
              this.budgetGridYears = prefBudgetGridYears?.data?.value
                ? (JSON.parse(prefBudgetGridYears?.data?.value) as Array<number>)
                : null;
            })
          );
        }),
        switchMap(() => {
          this.selectedCustom$.next('');
          return combineLatest([
            this.listCustomUserView(),
            this.budgetQuery.select(['header_data', 'budget_data']).pipe(debounceTime(100)),
          ]);
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadBudgetGridData();
      });
    this.selectedBudgetType.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((budget_type: BudgetType) => {
        this.budgetStore.update({ budget_type });
      });
    this.selectedBudgetType.setValue(BudgetType.BUDGET_PRIMARY);

    this.showAnalyticsSection$ = launchDarklyService.select$(
      (flags) => flags.section_budget_analytics
    );

    this.showBudgetTypeSelect$ = launchDarklyService.select$((flags) => flags.section_budget_type);

    this.showAnalyticsSection$
      .pipe(
        switchMap((flag) => {
          if (flag) {
            this.pendingChangesLoading.next(true);
            this.wpLoading.next(true);
            this.invoicesTotalLoading.next(true);

            return rxMerge(
              this.budgetService.getPendingChanges().pipe(
                tap(() => {
                  this.pendingChangesLoading.next(false);
                })
              ),
              this.budgetService.getBudgetWorkPerformed().pipe(
                tap(() => {
                  this.wpLoading.next(false);
                })
              ),
              this.budgetService.getInvoicesTotal().pipe(
                tap(() => {
                  this.invoicesTotalLoading.next(false);
                })
              )
            );
          }
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe();

    // reset any older selected vendors.
    this.organizationStore.setActive(null);

    this.listCustomUserView();
  }

  static agCurrencyFormatter(val: ValueFormatterParams) {
    if (val.data) {
      if (val.data.expense_note && (val.colDef.field || '').indexOf('direct_cost') >= 0) {
        return val.data.expense_note;
      }
    }

    if (val.value) {
      if (!Number.isNaN(val.value)) {
        return Utils.currencyFormatter(val.value);
      }
    }

    return Utils.zeroHyphen;
  }

  ngOnInit() {
    combineLatest([
      this.selectedPeriodType.valueChanges.pipe(startWith(this.selectedPeriodType.value)),
      this.organizationQuery.selectActive(),
      this.budgetQuery.select('budget_type'),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(untilDestroyed(this), debounceTime(0))
      .subscribe(() => {
        // each of the observables above will trigger a budget reloading,
        // so we need to hard refresh the ag-grid to fix the total row issue
        // if we keep the showGrid as true when we call the loadBudgetGridData method
        // we can still see the old grid in the html. Method will refresh the grid itself but
        // this will cause the grid to blink so to fix that
        // I'm setting this variable false here
        this.showGrid = false;
      });

    this.selectedPeriodType.patchValue(PeriodType.PERIOD_MONTH);
    rxMerge(
      this.selectedPeriodType.valueChanges.pipe(
        startWith(this.selectedPeriodType.value as PeriodType)
      ),
      this.eventService.select$(EventType.REFRESH_BUDGET).pipe(
        switchMap(() => {
          return this.organizationService.getListWithTotalBudgetAmount();
        })
      )
    )
      .pipe(
        switchMap(() => this.budgetService.getBudgetDataForEBG(PeriodType.PERIOD_MONTH)),
        switchMap(() => {
          if (this.compareToValue) {
            return this.snapshotService.getBudgetSnapshots(this.compareToValue);
          }

          return of();
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.organizationService
      .getListWithTotalBudgetAmount()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length === 1) {
          this.organizationStore.setActive(vendors[0].id);
          this.selectedVendor.setValue(vendors[0].id);
        } else {
          // reset any older selected vendors.
          this.organizationStore.setActive(null);
          this.selectedVendor.setValue('');
        }
      });
  }

  onVendorSelected(vendorId: string) {
    this.organizationStore.setActive(vendorId || null);
  }

  onDataRendered(e: FirstDataRenderedEvent) {
    this.gridAPI = e.api;
    this.columnAPI = e.columnApi;
    this.gridAPI?.setPinnedBottomRowData([
      merge(
        {
          activity_name: 'Total',
        },
        this.gridAPI?.getDisplayedRowAtIndex(this.gridAPI?.getDisplayedRowCount() - 1)?.aggData
      ),
    ]);
  }

  ngAfterViewInit(): void {
    this.budgetFilters.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        finalize(() => setTimeout(() => this.budgetPageComponent.filterViewContainer.next(null), 0))
      )
      .subscribe(() =>
        setTimeout(
          () => this.budgetPageComponent.filterViewContainer.next(this.budgetFilters.first || null),
          0
        )
      );
  }

  onBudgetUploadClick() {
    this.overlayService.open({ content: BudgetUploadComponent });
  }

  getGroupRowAgg = (params: GetGroupRowAggParams) => {
    let result: Record<string, number> = {};

    params.nodes.forEach((node) => {
      const data = node.group ? node.aggData : node.data;

      result = Object.entries(data)
        .filter((_, value) => typeof value === 'number')
        .reduce<Record<string, number>>((accum, [key, value]) => {
          return {
            ...accum,
            [key]: (accum[key] || 0) + (Number(value) || 0),
          };
        }, result);

      result.wp_percentage = round((result.wp_cost / result.current_lre) * 100, 2);

      result.remaining_percentage = result.wp_percentage ? round(100 - result.wp_percentage) : 100;

      result.var_percent =
        result.var_amount && result.baseline
          ? round(
              (result.var_amount /
                Math.abs(this.compareToValue ? result.snapshot_lre : result.baseline)) *
                100,
              2
            )
          : 0;
    });

    const prefix = this.compareToValue ? '::SNAPSHOT' : '';

    const getAggregateKeyAffix = (isForecast: boolean, defaultKey = '') => {
      if (!this.compareToValue) {
        return defaultKey;
      }

      return isForecast ? 'EXPENSE_FORECAST::' : 'EXPENSE_WP::';
    };

    Object.keys(result)
      .filter((key) =>
        this.compareToValue
          ? /^(EXPENSE_WP|EXPENSE_FORECAST)::(\w*-)?\d{4}::SNAPSHOT$/.test(key)
          : key.endsWith(`::PLAN`)
      )
      .forEach((planKey) => {
        const [period] = planKey.match(/(\w*-)?\d{4}/) || [];
        const isForecastKey = planKey.startsWith('EXPENSE_FORECAST::');
        const actualKeyAffix = isForecastKey ? 'EXPENSE_FORECAST::' : 'EXPENSE_WP::';
        const affix = getAggregateKeyAffix(isForecastKey);

        if (period) {
          const plan = result[planKey] || 0;
          const actuals = result[`${actualKeyAffix}${period}`] || 0;
          const varCost = this.getVarCost(actuals, plan);
          result[`${affix}${period}::VAR_COST${prefix}`] = varCost;
          result[`${affix}${period}::VAR_PERC${prefix}`] = this.getVarPerc(varCost, plan);
        }
      });

    return {
      ...result,
      activity_name: 'Total',
    };
  };

  onBudgetExportClick() {
    const vendorName = this.organizationQuery.getActive()?.name;

    if (!vendorName) {
      this.columnDefs.splice(1, 0, {
        headerName: 'Cost Category',
        field: 'cost_category',
        rowGroup: true,
        hide: true,
      });
      this.gridAPI?.setColumnDefs(this.columnDefs);
    }

    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const hasView = false;
    const viewName = hasView ? '_VIEWHERE' : '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');
    const fileName = vendorName
      ? `${trialName}_${vendorName}${viewName}_Total Budget_${dateStr}.xlsx`
      : `${trialName}${viewName}_Total Budget_${dateStr}.xlsx`;

    const totalData = this.gridAPI?.getDisplayedRowAtIndex(this.gridAPI?.getDisplayedRowCount() - 1)
      ?.aggData;

    const columnKeys = this.budgetExportColumnIDs().filter(
      (key) => key !== 'EXPENSE_QUOTE::LATEST' && !key.startsWith('spacerColumn')
    );

    const appendContent: ExcelCell[][] = [
      [
        {
          data: {
            value: 'Total',
            type: 'String',
          },
          styleId: 'total_row_header',
        },
      ],
    ];

    const vendorsColumns = this.selectedVendor.value
      ? ['display_label', 'group0', 'activity_name_label']
      : [];

    [
      'cost_category',
      ...vendorsColumns,
      ...(this.columnAPI
        ? this.columnAPI
            ?.getAllDisplayedColumns()
            .map((col) => col.getColId())
            .filter(
              (colId) =>
                !colId.startsWith('ag-Grid-AutoColumn') && !colId.startsWith('spacerColumn')
            )
        : []),
    ].forEach((colId) => {
      const isPercentCol = [
        'var_percent',
        'wp_percentage',
        'remaining_percentage',
        '::VAR_PERC::SNAPSHOT',
        '::VAR_PERC',
      ].some((col) => !!colId.match(col));
      const safeValue = !['unit_num', 'unit_cost', 'remaining_unit_num', 'wp_unit_num'].includes(
        colId
      )
        ? totalData[colId] || 0
        : 0;

      const divider = colId.includes('::VAR_PERC') ? 1 : 100;
      const value = isPercentCol ? safeValue / divider : safeValue;

      appendContent[0].push({
        data: { value: `${value}`, type: 'Number' },
        styleId: isPercentCol ? 'total_row_percent' : 'total_row',
      });
    });

    const exportOptions = {
      ...this.excelOptions,
      columnKeys,
      fileName,
      processCellCallback: (params: ProcessCellForExportParams): string => {
        const coldId = params.column.getColId();
        const costCategory = params.node?.data.cost_category;

        if (coldId.includes('unit') && costCategory === 'Discount') {
          return '0';
        }

        const isPercentColumn = ['wp_percentage', 'remaining_percentage'].some((key) =>
          coldId.endsWith(key)
        );

        if (isPercentColumn) {
          return `${params.value / 100}`;
        }

        // eslint-disable-next-line no-restricted-globals
        if (coldId.endsWith('VAR_COST') && isNaN(params.value)) {
          return '0';
        }

        return params.value;
      },
      appendContent,
    } as ExcelExportParams;
    this.gridAPI?.exportDataAsExcel(exportOptions);

    if (!vendorName) {
      this.columnDefs.splice(1, 1);
      this.gridAPI?.setColumnDefs(this.columnDefs);
    }
  }

  onColumnChooser() {
    const overlay = this.overlayService.open<any, { columns?: VisibleColumns }>({
      content: ColumnChooserComponent,
      data: { columns: JSON.parse(JSON.stringify(this.visibleColumns)) },
    });
    overlay.afterClosed$.subscribe((data) => {
      if (data.data) {
        this.visibleColumns = data.data;
        this.loadBudgetGridData(true);
        this.areUnsavedChanges = true;
        this.selectedCustom$.next('');
      }
    });
  }

  closeList() {
    this.isYearsOpen = false;
  }

  closeCustomList() {
    this.isCustomOpen = false;
  }

  openList() {
    this.isYearsOpen = true;
  }

  highlightCustom(index: number): void {
    this.highlightedCustom.next(index);
  }

  openCustomList() {
    this.isCustomOpen = true;

    if (this.selectedCustom$.getValue() != null) {
      this.highlightedCustom.next(this.selectedCustomIndex);
    } else {
      this.highlightedCustom.next(0);
    }

    if (this.selectedCustomIndex != null) {
      this.highlightedCustom.next(this.selectedCustomIndex);
    } else {
      this.highlightedCustom.next(0);
    }
    this.cdr.detectChanges();
  }

  yearChanged($event: boolean, label: number) {
    const year = this.years.find((el) => el.label === label);
    if (year) {
      year.enabled = $event;
    }
    this.saveBudgetYears();
    this.loadBudgetGridData(true);
    this.setSelectedYear();
  }

  customChanges(item: any) {
    const index = this.highlightedCustom.getValue();
    if (index != null || item) {
      if (this.selectedCustom$.getValue() !== item.name) {
        this.selectedCustom$.next(item.name);
        this.gridData$.next([]);
        this.gridAPI?.showLoadingOverlay();
        const selData = this.customValues$.getValue()?.find((x) => x.name === item.name);
        if (selData) {
          const activeTrial = this.mainQuery.getSelectedTrial()?.id;
          try {
            const localItem = localStorage.getItem(`customView`);
            // @ts-ignore
            const letItem = { ...JSON.parse(localItem) };
            // @ts-ignore
            letItem[activeTrial] = { id: selData.id, name: selData.name };
            localStorage.setItem(`customView`, JSON.stringify(letItem));
          } catch (e) {
            console.error(e);
          }
          this.selectedCustomIndex =
            this.customValues$.getValue()?.findIndex((x) => x.id === item.id) || 0;
          const visCol = JSON.parse(JSON.parse(selData?.metadata));
          // eslint-disable-next-line no-prototype-builtins
          if (visCol.hasOwnProperty('overall_budget')) {
            this.visibleColumns = visCol;
            this.gridAPI?.showLoadingOverlay();
            setTimeout(() => {
              this.loadBudgetGridData(true);
              setTimeout(() => {
                this.gridAPI?.hideOverlay();
              }, 500);
            }, 0);
          }
        }
      } else {
        this.selectedCustomIndex = index;
      }
    }
    this.closeCustomList();
    this.gridAPI?.hideOverlay();
  }

  async editCustom(item: any) {
    const respOverlay = await this.overlayService.open<any, { columns?: VisibleColumns }>({
      content: ColumnChooserComponent,
      data: { columns: JSON.parse(JSON.parse(item.metadata)) },
    });

    const overlay = await respOverlay.afterClosed$.toPromise();
    const resp = this.overlayService.open({
      content: BudgetCustomUpdateComponent,
      data: {
        useDesignSystemStyling: true,
        textName: item.name,
      },
    });
    const event = await resp.afterClosed$.toPromise();
    if (event.data?.label && overlay.data) {
      const flag = await this.budgetService.updateUserCustomView({
        id: item.id,
        name: event.data.label,
        metadata: overlay.data !== null ? JSON.stringify(overlay.data) : JSON.parse(item.metadata),
      } as UpdateUserCustomViewInput);
      if (flag) {
        await this.listCustomUserView();
        this.customChanges({ ...item, name: event.data.label });
        setTimeout(() => {
          this.loadBudgetGridData(true);
        }, 0);
        this.overlayService.success();
      }
    }
  }

  refreshTable = () => {
    this.loadBudgetGridData(true);
  };

  compareDropdownChange(value: string) {
    this.compareToValue = value;
  }

  async saveCustomUserView() {
    const user = await this.authService.getLoggedInUser();
    const resp = this.overlayService.open({
      content: BudgetCustomCreateComponent,
      data: {
        useDesignSystemStyling: true,
      },
    });

    const event = await resp.afterClosed$.toPromise();
    if (event.data?.label) {
      const data: CreateUserCustomViewInput = {
        name: event.data.label,
        user_id: user?.getSub() || '',
        metadata: JSON.stringify(this.visibleColumns),
        view_location: ViewLocation.VIEW_LOCATION_BUDGET_GRID,
      };
      const flag = await this.budgetService.saveUserCustomView(data);
      if (flag) {
        await this.listCustomUserView();
        this.customChanges({ ...data, name: event.data.label });
        setTimeout(() => {
          this.loadBudgetGridData(true);
        }, 0);
        this.areUnsavedChanges = false;
        this.overlayService.success();
      }
    }
  }

  async removeCustom(item: any) {
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Custom View',
      message: `Are you sure you want to remove ${item?.name}?`,
      okBtnText: 'Remove',
    });

    const event = await resp.afterClosed$.toPromise();
    if (event.data?.result) {
      const response = await this.budgetService.removeUserCustomView(item.id);
      if (response) {
        await this.listCustomUserView();
        setTimeout(() => {
          this.loadBudgetGridData(true);
        }, 0);
        this.overlayService.success();
      }
    }
  }

  refreshRows(): void {
    this.gridAPI?.redrawRows();
  }

  private parseBudgetMonthToDate(period: string) {
    return dayjs(`01/${period.replace('-', '/')}`);
  }

  private currentQuarter(current_period: string): string {
    const date = this.parseBudgetMonthToDate(current_period);
    return `Q${Math.floor(date.month() / 3) + 1}-${date.year()}`;
  }

  private currentYear(current_period: string): string {
    return `${this.parseBudgetMonthToDate(current_period).year()}`;
  }

  private sortingForDefault(customValues: any[]) {
    const data: (UserCustomView & {
      showLine: boolean;
    })[] = [];
    const nCustomValues = customValues.filter((x) => x.is_custom);
    customValues.forEach((x) => {
      if (x.is_custom) {
        return;
      }
      switch (x.name) {
        case 'Monthly':
          data[0] = { ...x, showLine: true };
          break;
        case 'Quarterly':
          data[1] = x;
          break;
        case 'Yearly':
          data[2] = x;
          break;
        case 'Historical Only - Months':
          data[3] = x;
          break;
        case 'Historical Only - Quarters':
          data[4] = x;
          break;
        case 'Historical Only - Years':
          data[5] = x;
          break;
        case 'Forecast Only - Months':
          data[6] = x;
          break;
        case 'Forecast Only - Quarters':
          data[7] = x;
          break;
        case 'Forecast Only - Years':
          data[8] = x;
          break;
        default:
          break;
      }
    });
    return [...nCustomValues, ...data];
  }

  private async listCustomUserView() {
    const data = (await this.budgetService.listUserCustomView()) as (UserCustomView & {
      showLine: boolean;
    })[];
    if (data) {
      this.customValues$.next([]);
      const sortingForDefault = this.sortingForDefault(data);
      const sortData = sortingForDefault.sort((x, y) => {
        if (x.is_custom && y.is_custom) {
          return Utils.alphaNumSort(x.name.toUpperCase(), y.name.toUpperCase());
        }
        return 0;
      });
      this.customValues$.next(sortData);
      try {
        const activeTrial = this.mainQuery.getSelectedTrial()?.id;
        const localItem = localStorage.getItem(`customView`);
        // @ts-ignore
        const localView = JSON.parse(localItem);
        // @ts-ignore
        if (localView && localView[activeTrial]) {
          // @ts-ignore
          const localIndex = sortData.findIndex((x) => x.name === localView[activeTrial].name);
          if (localIndex !== -1) {
            this.highlightedCustom.next(localIndex);
            this.selectedCustomIndex = localIndex;
            this.selectedCustom$.next(sortData[localIndex].name);
            this.visibleColumns = JSON.parse(JSON.parse(sortData[localIndex].metadata));
          } else {
            if (localItem != null) {
              const remItem = JSON.parse(localItem);
              // @ts-ignore
              delete remItem[activeTrial];
              localStorage.setItem(`customView`, JSON.stringify(remItem));
            }

            this.defaultChooserSelection(sortData);
          }
        } else {
          this.defaultChooserSelection(sortData);
        }
      } catch (e) {
        this.defaultChooserSelection(sortData);
      }
    }
  }

  private defaultChooserSelection(data: any[]) {
    const indexV = data.findIndex((x) => x.name === 'Monthly');
    if (indexV !== -1) {
      this.selectedCustom$.next(data[indexV].name);
      this.visibleColumns = JSON.parse(JSON.parse(data[indexV].metadata));
      this.highlightedCustom.next(indexV);
      this.selectedCustomIndex = indexV;
    } else {
      this.selectedCustom$.next(data[0].name);
      this.visibleColumns = JSON.parse(JSON.parse(data[0].metadata));
      this.highlightedCustom.next(0);
      this.selectedCustomIndex = 0;
    }
  }

  private async saveBudgetYears() {
    const years = [...this.years].reduce((acc: number[], el) => {
      if (!acc.includes(el.label) && el.enabled) {
        acc.push(el.label);
      }
      return acc;
    }, []);

    await this.gqlService
      .setTrialPreference$({
        preference_type: TrialPreferenceType.BUDGET_GRID_YEARS,
        value: JSON.stringify(years),
      })
      .toPromise();

    this.budgetGridYears = years;
  }

  private setSelectedYear() {
    let numberOfYearsEnabled = 0;
    this.years.forEach((year) => {
      if (year.enabled) {
        numberOfYearsEnabled += 1;
      }
    });
    if (numberOfYearsEnabled === 0) {
      this.selectedYear = 'None';
    } else if (numberOfYearsEnabled < this.years.length) {
      this.selectedYear = `${numberOfYearsEnabled} Selected`;
    } else {
      this.selectedYear = 'All';
    }
  }

  private isColDef(col: ColDef | ColGroupDef): col is ColDef {
    return (col as ColDef).colId !== undefined;
  }

  private budgetExportColumnIDs() {
    let colIds = [] as string[];

    (this.gridAPI?.getColumnDefs() || []).forEach((columnDef) =>
      colIds.push(...this.getColumnIds(columnDef))
    );
    colIds = colIds.filter(
      (ci) =>
        ci !== 'group1' &&
        ci !== 'group2' &&
        ci !== 'group3' &&
        ci !== 'group4' &&
        ci !== 'group5' &&
        // eslint-disable-next-line no-restricted-globals
        isNaN(Number(ci)) // Filter spacing rows
    );
    return colIds;
  }

  private getColumnIds(def: ColDef | ColGroupDef) {
    const colIds = [] as string[];
    let str = '';
    const disallowed_types = ['EXPENSE_WP::TO_DATE'];
    if (this.isColDef(def)) {
      str = def.colId || '';
      if (disallowed_types.indexOf(str) === -1) {
        colIds.push(str);
      }
    }

    if ((def as ColGroupDef).children !== undefined && disallowed_types.indexOf(str) === -1) {
      (def as ColGroupDef).children.forEach((child) => {
        if (!this.isColDef(child) || !child.hide) {
          colIds.push(...this.getColumnIds(child));
        }
      });
    }
    return colIds;
  }

  private getVarCost(actuals: number, plan: number): number {
    return actuals - plan;
  }

  private getVarPerc(varCost: number, plan: number): number {
    return plan ? round(varCost / plan, 2) : 0;
  }

  private loadBudgetGridData(refresh: boolean = false) {
    this.gridAPI = undefined;
    this.showGrid = false;
    const { budget_data, header_data } = this.budgetQuery.getValue();
    const auxilius_start_date = this.mainQuery.getAuxiliusStartDate();
    const { budgetGridYears } = this;
    const [aggregated_budget_data, aggregated_header_data] = this.aggregateQuartersAndYears(
      budget_data || [],
      header_data
    );
    this.gridData$.next(aggregated_budget_data);
    const defs: (ColDef | ColGroupDef)[] = [
      overallBudgetColumnDef(this.visibleColumns.overall_budget, this.compareToValue),
    ];
    const el = (aggregated_header_data as RequireSome<BudgetHeader, 'date_headers'>[]).find(
      (x) => x.group_name === 'Work Performed'
    );
    const forecastHeader = (aggregated_header_data as RequireSome<
      BudgetHeader,
      'date_headers'
    >[]).find((x) => x.group_name === 'Forecast');

    if (!refresh) {
      const hYears = (el?.date_headers || []).reduce((acc: number[], col_header) => {
        const headerName = col_header.split('-').pop();
        if (headerName !== '' && !acc.includes(Number(headerName))) {
          acc.push(Number(headerName));
        }
        return acc;
      }, []);
      if (Array.isArray(budgetGridYears)) {
        this.years = hYears.map((year) => {
          return { label: year, enabled: budgetGridYears.includes(year) };
        });
      } else {
        this.years = hYears.map((year) => ({ label: year, enabled: true }));
      }
      this.setSelectedYear();
    }
    if (el) {
      let actualsColDefs: (ColDef | ColGroupDef)[] = el.date_headers
        .filter((col_header) => {
          const year = col_header.split('-').pop();
          const enabled = this.years.find((h) => h.label === Number(year))?.enabled;
          if (!forecastHeader) {
            return enabled;
          }
          const currentForecast = forecastHeader.date_headers[0];
          return (
            enabled &&
            col_header !== this.currentQuarter(currentForecast) &&
            col_header !== this.currentYear(currentForecast)
          );
        })
        .filter((col_header) => {
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(Number(col_header))) {
            return this.visibleColumns.historicals.years;
          }
          if (col_header.startsWith('Q')) {
            return this.visibleColumns.historicals.quarters;
          }
          return this.visibleColumns.historicals.months;
        })
        .map((col_header) => {
          let headerName = '';
          if (col_header.startsWith('Q')) {
            headerName = col_header.replace('-', ' ');
            // eslint-disable-next-line no-restricted-globals
          } else if (!isNaN(Number(col_header))) {
            headerName = col_header;
          } else {
            const date = this.parseBudgetMonthToDate(col_header);
            headerName = `${Utils.SHORT_MONTH_NAMES[date.month()]} ${date.year()}`;
          }

          const fieldNamePrefix = this.compareToValue ? '::SNAPSHOT' : '';
          const filedNameAffix = this.compareToValue ? 'EXPENSE_WP::' : '';

          const snapshotColumnParams = this.compareToValue
            ? {
                headerGroupComponent: AgHeaderDropdownComponent,
                headerGroupComponentParams: {
                  expandAll: this.compareToValue,
                },
              }
            : {};

          return {
            ...snapshotColumnParams,
            headerName,
            headerClass: 'ag-header-align-center justify-center',
            children: [
              {
                headerName: 'Actuals',
                headerClass: 'ag-header-align-center',
                field: `${el.expense_type}::${col_header}`,
                aggFunc: 'sum',
                valueFormatter: BudgetEnhancedComponent.agCurrencyFormatter,
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                cellClass: ['ag-cell-align-right', 'budget-cost'],
              },
              {
                ...TableConstants.dynamicColumnProps(this.compareToValue || 'Plan'),
                field: this.compareToValue
                  ? `${el.expense_type}::${col_header}::SNAPSHOT`
                  : `${col_header}::PLAN`,
                aggFunc: 'sum',
                valueFormatter: BudgetEnhancedComponent.agCurrencyFormatter,
                minWidth: cellSize.xLarge,
                hide: true,
                cellClass: ['ag-cell-align-right', 'budget-cost'],
              },
              {
                headerName: 'Var ($)',
                field: `${filedNameAffix}${col_header}::VAR_COST${fieldNamePrefix}`,
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                aggFunc: 'sum',
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: ['ag-cell-align-right', 'budget-cost'],
                hide: true,
                valueFormatter: BudgetEnhancedComponent.agCurrencyFormatter,
              },
              {
                headerName: 'Var (%)',
                field: `${filedNameAffix}${col_header}::VAR_PERC${fieldNamePrefix}`,
                width: cellSize.large,
                minWidth: cellSize.large,
                aggFunc: 'sum',
                valueFormatter: (params: ValueFormatterParams) =>
                  Utils.percentageFormatter(params.value || 0),
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: ['ag-cell-align-right', 'budget-percent'],
                hide: true,
              },
            ].filter((col) => (this.compareToValue ? true : col.headerName === 'Actuals')),
          } as ColGroupDef;
        });

      if (
        auxilius_start_date &&
        (this.visibleColumns.historicals.months || this.visibleColumns.historicals.quarters)
      ) {
        actualsColDefs = [
          {
            headerName: 'Auxilius Start',
            headerClass: 'ag-header-align-center',
            field: 'trial_to_date',
            aggFunc: 'sum',
            valueFormatter: BudgetEnhancedComponent.agCurrencyFormatter,
            cellClass: ['ag-cell-align-right', 'budget-cost'],
          },
          TableConstants.SPACER_COLUMN,
          ...actualsColDefs.filter((col) => {
            if (col.headerName?.startsWith('Q')) {
              const [month, year] = col.headerName?.split(' ');
              const monthStr = `${+month.replace('Q', '') * 3}`.padStart(2, '0');
              return dayjs(new Date(`01/${monthStr}/${year}`)).isSameOrAfter(
                dayjs(auxilius_start_date).date(1)
              );
            }
            if (!Number.isNaN(Number(col.headerName))) {
              return true;
            }

            return dayjs(
              new Date(`01/${col.headerName?.toUpperCase().replace(' ', '/')}`)
            ).isSameOrAfter(dayjs(auxilius_start_date).date(1));
          }),
        ];
      }

      if (forecastHeader) {
        const currentForecast = forecastHeader.date_headers[0];
        // set current period closed months + QTD + YTD (spacers around)
        if (
          this.parseBudgetMonthToDate(currentForecast).month() % 3 &&
          actualsColDefs.length &&
          this.visibleColumns.historicals.months
        ) {
          const currentPeriodClosedMonths: (ColDef | ColGroupDef)[] = [
            actualsColDefs.pop() as ColDef | ColGroupDef,
          ];
          while (
            currentPeriodClosedMonths[0]?.headerName &&
            this.parseBudgetMonthToDate(currentPeriodClosedMonths[0]?.headerName).month() % 3 &&
            actualsColDefs.length
          ) {
            currentPeriodClosedMonths.unshift(actualsColDefs.pop() as ColDef | ColGroupDef);
          }
          actualsColDefs.push(...currentPeriodClosedMonths);
        }

        const snaphotPrefix = this.compareToValue ? '::SNAPSHOT' : '';
        const filedNameAffix = this.compareToValue ? 'EXPENSE_WP::' : '';

        const snapshotColumnParams = this.compareToValue
          ? {
              headerGroupComponent: AgHeaderDropdownComponent,
              headerGroupComponentParams: {
                expandAll: this.compareToValue,
              },
            }
          : {};

        if (this.visibleColumns.historicals.quarters) {
          const qtd = {
            headerName: `${this.currentQuarter(currentForecast).replace('-', ' ')} (QTD)`,
            headerClass: 'ag-header-align-center justify-center',
            ...snapshotColumnParams,
            children: [
              {
                headerName: 'Actuals',
                headerClass: 'ag-header-align-center',
                field: `${el.expense_type}::${this.currentQuarter(currentForecast)}`,
                aggFunc: 'sum',
                valueFormatter: BudgetEnhancedComponent.agCurrencyFormatter,
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                cellClass: ['ag-cell-align-right', 'budget-cost'],
              },
              {
                ...TableConstants.dynamicColumnProps(this.compareToValue || 'Plan'),
                field: this.compareToValue
                  ? `${el.expense_type}::${this.currentQuarter(currentForecast)}${snaphotPrefix}`
                  : `${this.currentQuarter(currentForecast)}::PLAN`,
                aggFunc: 'sum',
                valueFormatter: BudgetEnhancedComponent.agCurrencyFormatter,
                minWidth: cellSize.xLarge,
                hide: true,
                cellClass: ['ag-cell-align-right', 'budget-cost'],
              },
              {
                headerName: '$',
                field: `${filedNameAffix}${this.currentQuarter(
                  currentForecast
                )}::VAR_COST${snaphotPrefix}`,
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                aggFunc: 'sum',
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: ['ag-cell-align-right', 'budget-cost'],
                hide: true,
                valueFormatter: BudgetEnhancedComponent.agCurrencyFormatter,
              },
              {
                headerName: '%',
                field: `${filedNameAffix}${this.currentQuarter(
                  currentForecast
                )}::VAR_PERC${snaphotPrefix}`,
                width: cellSize.large,
                minWidth: cellSize.large,
                valueFormatter: Utils.agPercentageFormatter,
                aggFunc: 'sum',
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: ['ag-cell-align-right', 'budget-percent'],
                hide: true,
              },
            ].filter((col) => (this.compareToValue ? true : col.headerName === 'Actuals')),
          } as ColGroupDef;
          actualsColDefs.push(qtd);
        }
        const year = this.parseBudgetMonthToDate(currentForecast).year();
        if (this.visibleColumns.historicals.years) {
          const ytd = {
            headerName: `${year} (YTD)`,
            headerClass: 'ag-header-align-center justify-center',
            ...snapshotColumnParams,
            children: [
              {
                headerName: 'Actuals',
                headerClass: 'ag-header-align-center',
                field: `${el.expense_type}::${this.currentYear(currentForecast)}`,
                aggFunc: 'sum',
                valueFormatter: BudgetEnhancedComponent.agCurrencyFormatter,
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                cellClass: ['ag-cell-align-right', 'budget-cost'],
              },
              {
                ...TableConstants.dynamicColumnProps(this.compareToValue || 'Plan'),
                field: this.compareToValue
                  ? `${el.expense_type}::${this.currentYear(currentForecast)}${snaphotPrefix}`
                  : `${this.currentYear(currentForecast)}::PLAN`,
                aggFunc: 'sum',
                valueFormatter: BudgetEnhancedComponent.agCurrencyFormatter,
                minWidth: cellSize.xLarge,
                hide: true,
                cellClass: ['ag-cell-align-right', 'budget-cost'],
              },
              {
                headerName: '$',
                field: this.compareToValue
                  ? `${el.expense_type}::${this.currentYear(
                      currentForecast
                    )}::VAR_COST${snaphotPrefix}`
                  : `${this.currentYear(currentForecast)}::VAR_COST`,
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                aggFunc: 'sum',
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: ['ag-cell-align-right', 'budget-cost'],
                hide: true,
                valueFormatter: BudgetEnhancedComponent.agCurrencyFormatter,
              },
              {
                headerName: '%',
                field: this.compareToValue
                  ? `${el.expense_type}::${this.currentYear(
                      currentForecast
                    )}::VAR_PERC${snaphotPrefix}`
                  : `${this.currentYear(currentForecast)}::VAR_PERC`,
                width: cellSize.large,
                minWidth: cellSize.large,
                valueFormatter: Utils.agPercentageFormatter,
                aggFunc: 'sum',
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: ['ag-cell-align-right', 'budget-percent'],
                hide: true,
              },
            ].filter((col) => (this.compareToValue ? true : col.headerName === 'Actuals')),
          } as ColGroupDef;
          actualsColDefs.push(ytd);
        }
      }

      if (
        this.visibleColumns.historicals.months ||
        this.visibleColumns.historicals.quarters ||
        this.visibleColumns.historicals.years
      ) {
        defs.push(TableConstants.SPACER_COLUMN);
      }
      defs.push(...actualsColDefs);

      defs.push(TableConstants.SPACER_COLUMN);
      defs.push(
        actualsToDateColumnDef(this.visibleColumns.actuals_to_date),
        TableConstants.SPACER_COLUMN
      );
      // @TODO: verify why if put after the if the columns get ordered wrong
      defs.push(remainingBudgetColDef(this.visibleColumns.remaining_budget));
      if (
        this.visibleColumns.remaining_budget.costs ||
        this.visibleColumns.remaining_budget.perc ||
        this.visibleColumns.remaining_budget.units
      ) {
        defs.push(TableConstants.SPACER_COLUMN);
      }
    }

    if (forecastHeader) {
      const currentForecast = forecastHeader.date_headers[0];

      const currentPeriodChildren = [currentForecast];
      let slice = 1;
      while (
        currentPeriodChildren[currentPeriodChildren.length - 1] &&
        (this.parseBudgetMonthToDate(
          currentPeriodChildren[currentPeriodChildren.length - 1]
        ).month() +
          1) %
          3
      ) {
        currentPeriodChildren.push(forecastHeader.date_headers[slice]);
        slice += 1;
      }

      if (
        (this.visibleColumns.current_period.quarters || this.visibleColumns.forecast.quarters) &&
        forecastHeader.date_headers[slice].startsWith('Q')
      ) {
        currentPeriodChildren.push(forecastHeader.date_headers[slice]);
        slice += 1;
      }

      const currentPeriodView = this.getCurrentPeriodView(currentPeriodChildren);

      currentPeriodView.forEach((forecastMonth) => {
        defs.push(forecastMonth);
      });

      // if we are showing the current period add space
      if (currentPeriodView.some((col: ColDef) => !col.hide)) {
        defs.push(TableConstants.SPACER_COLUMN);
      }

      if (!refresh) {
        const fYears = forecastHeader.date_headers.slice(1).reduce((acc: number[], col_header) => {
          const headerName = col_header.split('-').pop();
          // eslint-disable-next-line no-restricted-globals
          if (headerName !== '' && !acc.includes(Number(headerName))) {
            acc.push(Number(headerName));
          }
          return acc;
        }, []);

        if (Array.isArray(budgetGridYears)) {
          const arr = fYears.map((year) => {
            // eslint-disable-next-line no-param-reassign
            return { label: year, enabled: !!budgetGridYears.includes(year) };
          });
          this.years = uniqBy([...this.years, ...arr], 'label');
        } else {
          const arr = fYears.map((year) => ({ label: year, enabled: true }));
          this.years = uniqBy([...this.years, ...arr], 'label');
        }
      }

      defs.push(...this.renderSnapshotForecast(forecastHeader.date_headers, slice));
    }

    // if vendor drop down is set to ALL, then filter some columns from the table (i.e. columns w/ hideForAllVendorSelection property set to true).
    // Due to the grouping, this also removes the redudant rows on the "ALL" table (i.e. Services > Services now just shows as Services)
    let filteredColumns = this.defaultColumns;
    if (!this.selectedVendor.value) {
      filteredColumns = this.defaultColumns
        .map((column) => {
          if ((column as ColGroupDef).children?.length) {
            return {
              ...column,
              children: column.children?.filter(
                (childColumn) =>
                  !(childColumn as { hideForAllVendorSelection?: boolean })
                    .hideForAllVendorSelection
              ),
            };
          }
          return column;
        })
        .filter((column) => !column.hideForAllVendorSelection);
    }

    const colSize = this.selectedVendor.value ? 350 : 250;
    this.autoGroupColumnDef = {
      ...this.autoGroupColumnDef,
      width: colSize,
      minWidth: colSize,
    };
    this.gridOptions$.next({
      ...this.gridOptions$.getValue(),
    });
    this.columnDefs = [...filteredColumns, ...defs];
    this.setSelectedYear();

    setTimeout(() => {
      this.showGrid = true;
      this.cdr.detectChanges();
    }, 0);
  }

  private getCurrentPeriodView(currentPeriod: string[]) {
    return currentPeriod
      .filter((period) =>
        period.startsWith('Q')
          ? this.visibleColumns.current_period.quarters
          : this.visibleColumns.current_period.months
      )
      .map<ColDef | ColGroupDef>((child) => {
        let cHeaderName = '';

        if (child.startsWith('Q')) {
          cHeaderName = child.split('-').join(' ');
        } else {
          cHeaderName = `${Utils.SHORT_MONTH_NAMES[this.parseBudgetMonthToDate(child).month()]} ${
            child.split('-')[1]
          }`;
        }

        const columnParams = this.compareToValue
          ? {
              headerGroupComponent: AgHeaderDropdownComponent,
              headerGroupComponentParams: {
                expandAll: true,
              },
            }
          : {};

        return {
          ...columnParams,
          headerName: cHeaderName,
          headerClass: this.compareToValue
            ? 'flex items-center justify-center'
            : 'ag-header-align-center',
          colId: 'currentPeriod',
          children: this.getForecastSubColumns(child),
        };
      });
  }

  private renderSnapshotForecast(date_headers: string[], slice: number) {
    return date_headers
      .slice(slice)
      .filter((col_header) => {
        const year = col_header.split('-').pop();
        const enabled = this.years.find((e) => e.label === Number(year))?.enabled;
        return enabled;
      })
      .filter((col_header) => {
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(Number(col_header))) {
          return this.visibleColumns.forecast.years;
        }
        if (col_header.startsWith('Q')) {
          return this.visibleColumns.forecast.quarters;
        }
        return this.visibleColumns.forecast.months;
      })
      .map((forecastMonth) => {
        let headerName = '';
        if (forecastMonth.startsWith('Q')) {
          headerName = forecastMonth.replace('-', ' ');
          // eslint-disable-next-line no-restricted-globals
        } else if (!isNaN(Number(forecastMonth))) {
          headerName = forecastMonth;
        } else {
          const date = this.parseBudgetMonthToDate(forecastMonth);
          headerName = `${Utils.SHORT_MONTH_NAMES[date.month()]} ${date.year()}`;
        }

        const forecastHeaderParams = this.compareToValue
          ? {
              headerClass:
                'ag-header-align-center bg-aux-gray-dark aux-black border-aux-gray-dark flex items-center justify-center',
              headerGroupComponent: AgHeaderDropdownComponent,
              headerGroupComponentParams: {
                iconClass: 'text-black',
                expandAll: true,
              },
            }
          : {
              headerClass: 'ag-header-align-center bg-aux-gray-dark aux-black',
            };

        return {
          ...forecastHeaderParams,
          headerName,
          children: this.getForecastSubColumns(forecastMonth),
        } as ColGroupDef;
      });
  }

  private getForecastSubColumns(forecastMonth: string): (ColDef | ColGroupDef)[] {
    return [
      {
        headerName: 'Forecast',
        headerClass: 'ag-header-align-center',
        field: `EXPENSE_FORECAST::${forecastMonth}`,
        aggFunc: 'sum',
        valueFormatter: BudgetEnhancedComponent.agCurrencyFormatter,
        width: cellSize.xLarge,
        minWidth: cellSize.xLarge,
        cellClass: ['ag-cell-align-right', 'budget-cost'],
      },
      {
        ...TableConstants.dynamicColumnProps(this.compareToValue || ''),
        field: `EXPENSE_FORECAST::${forecastMonth}::SNAPSHOT`,
        aggFunc: 'sum',
        valueFormatter: BudgetEnhancedComponent.agCurrencyFormatter,
        minWidth: cellSize.xLarge,
        hide: true,
        cellClass: ['ag-cell-align-right', 'budget-cost'],
      },
      {
        headerName: 'Var ($)',
        field: `EXPENSE_FORECAST::${forecastMonth}::VAR_COST::SNAPSHOT`,
        width: cellSize.xLarge,
        minWidth: cellSize.xLarge,
        aggFunc: 'sum',
        headerClass: 'ag-header-align-center',
        cellRenderer: VariationStatusComponent,
        cellClass: ['ag-cell-align-right', 'budget-cost'],
        hide: true,
        valueFormatter: BudgetEnhancedComponent.agCurrencyFormatter,
      },
      {
        headerName: 'Var (%)',
        field: `EXPENSE_FORECAST::${forecastMonth}::VAR_PERC::SNAPSHOT`,
        width: cellSize.large,
        minWidth: cellSize.large,
        aggFunc: 'sum',
        valueFormatter: (params) => Utils.percentageFormatter(params.value || 0),
        headerClass: 'ag-header-align-center',
        cellRenderer: VariationStatusComponent,
        cellClass: ['ag-cell-align-right', 'budget-percent'],
        hide: true,
      },
    ];
  }

  private getTimelineHeaders = (monthYears: string[] | undefined) => {
    if (!monthYears) {
      return { months: [], quarters: [], years: [] };
    }
    return {
      months: monthYears,
      quarters: [
        ...new Set(
          monthYears.map((date) => {
            return `Q${dayjs(this.parseBudgetMonthToDate(date)).quarter()} ${dayjs(
              this.parseBudgetMonthToDate(date)
            ).format('YYYY')}`;
          })
        ),
      ],
      years: [
        ...new Set(
          monthYears.map((date) => {
            return dayjs(this.parseBudgetMonthToDate(date)).format('YYYY');
          })
        ),
      ],
    };
  };

  private periodSortingFunction(a: string, b: string) {
    const a_year = a.split('-').pop();
    const b_year = b.split('-').pop();
    if (Number(a_year) < Number(b_year)) {
      return -1;
    }
    if (Number(a_year) > Number(b_year)) {
      return 1;
    }

    if (a.split('-').length > b.split('-').length) {
      return -1;
    }
    if (a.split('-').length < b.split('-').length) {
      return 1;
    }

    const a_index = period_sorting.findIndex((el) => el.toUpperCase() === a.split('-').shift());
    const b_index = period_sorting.findIndex((el) => el.toUpperCase() === b.split('-').shift());
    if (a_index < b_index) {
      return -1;
    }
    if (a_index === b_index) {
      return 0;
    }
    return 1;
  }

  private aggregateQuartersAndYears(
    budget_data: ExtendedBudgetData[],
    header_data: RequireSome<BudgetHeader, 'date_headers'>[]
  ) {
    const f_header = header_data.find((el) => el.expense_type === 'EXPENSE_FORECAST');
    const forecast_header = {
      ...f_header,
      date_headers: Object.assign([], f_header?.date_headers),
    };
    const h_header = header_data.find((el) => el.expense_type === 'EXPENSE_WP');
    const historical_header = {
      ...h_header,
      date_headers: Object.assign([], h_header?.date_headers),
    };
    const remaining_header = header_data.filter(
      (el) => el.expense_type !== 'EXPENSE_FORECAST' && el.expense_type !== 'EXPENSE_WP'
    );
    const bud_data = budget_data.map((bd) => {
      const obj: { [key: string]: any } = {};
      Object.keys(bd)
        .filter((key) => key.startsWith('EXPENSE_FORECAST::') && !key.endsWith('::SNAPSHOT'))
        .forEach((key) => {
          const splitkey = key.split('::');
          const date = dayjs(`01/${splitkey[1].replace('-', '/')}`);
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(date.year())) {
            return;
          }
          const yearKey = `EXPENSE_FORECAST::${date.year()}`;
          if (!bd[yearKey]) {
            if (obj[yearKey]) {
              obj[yearKey] += bd[key];
            } else {
              obj[yearKey] = bd[key];
              if (!forecast_header?.date_headers.find((h: string) => h === `${date.year()}`)) {
                forecast_header?.date_headers.push(`${date.year()}`);
              }
            }
          }

          const quarterStr = `Q${Math.floor(date.month() / 3) + 1}-${date.year()}`;
          const quarterKey = `EXPENSE_FORECAST::${quarterStr}`;
          if (!bd[quarterKey]) {
            if (obj[quarterKey]) {
              obj[quarterKey] += bd[key];
            } else {
              obj[quarterKey] = bd[key];
              if (!forecast_header?.date_headers.find((h: string) => h === quarterStr)) {
                forecast_header?.date_headers.push(
                  `Q${Math.floor(date.month() / 3) + 1}-${date.year()}`
                );
              }
            }
          }
          // obj[key] = bd[key];
        });
      const desc = Object.getOwnPropertyDescriptor(forecast_header, 'date_headers');
      if (desc?.writable) {
        forecast_header?.date_headers.sort(this.periodSortingFunction);
      }

      Object.keys(bd)
        .filter(
          (key) =>
            key.startsWith('EXPENSE_WP::') &&
            key !== 'EXPENSE_WP::TO_DATE' &&
            !key.endsWith('::SNAPSHOT')
        )
        .forEach((key) => {
          const splitkey = key.split('::');
          const date = dayjs(`01/${splitkey[1].replace('-', '/')}`);
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(date.year())) {
            return;
          }

          // YTD - Actuals
          const year_key = `EXPENSE_WP::${date.year()}`;
          if (!bd[year_key]) {
            if (obj[year_key]) {
              obj[year_key] += bd[key];
            } else {
              obj[year_key] = bd[key];
            }
            if (!historical_header?.date_headers.find((h: string) => h === `${date.year()}`)) {
              historical_header?.date_headers.push(`${date.year()}`);
            }
          }

          // QTD Actuals
          const quarter_str = `Q${Math.floor(date.month() / 3) + 1}-${date.year()}`;
          const quarter_key = `EXPENSE_WP::${quarter_str}`;
          if (!bd[quarter_key]) {
            if (obj[quarter_key]) {
              obj[quarter_key] += bd[key];
            } else {
              obj[quarter_key] = bd[key];
              if (!historical_header?.date_headers.find((h: string) => h === quarter_str)) {
                historical_header?.date_headers.push(quarter_str);
              }
            }
          }

          const forecast_quarter_key = `EXPENSE_FORECAST::${splitkey[1]}`;
          const plan = bd[forecast_quarter_key] || 0;
          const var_cost = bd[key] - plan;
          if (!bd[`EXPENSE_WP::${splitkey[1]}::VAR_COST`]) {
            obj[`EXPENSE_WP::${splitkey[1]}::VAR_COST`] = var_cost;
          }
          if (!bd[`EXPENSE_WP::${splitkey[1]}::VAR_PERC`]) {
            obj[`EXPENSE_WP::${splitkey[1]}::VAR_PERC`] =
              // eslint-disable-next-line no-restricted-globals
              isNaN(var_cost / plan) || !plan ? 0 : var_cost / plan;
          }

          const plan_quarter = bd[`EXPENSE_FORECAST::Q${quarter_str}::`];
          const var_cost_quarter = obj[quarter_key] - plan_quarter;
          if (!bd[`EXPENSE_WP::Q${quarter_str}::VAR_COST`]) {
            obj[`EXPENSE_WP::Q${quarter_str}::VAR_COST`] = var_cost_quarter;
          }
          if (!bd[`EXPENSE_WP::Q${quarter_str}::VAR_PERC`]) {
            obj[`EXPENSE_WP::Q${quarter_str}::VAR_PERC`] =
              // eslint-disable-next-line no-restricted-globals
              isNaN(var_cost_quarter / plan_quarter) || !plan_quarter
                ? 0
                : var_cost_quarter / plan_quarter;
          }
          const plan_year = bd[`EXPENSE_FORECAST::${date.year}::`];
          const var_cost_year = obj[year_key] - plan_quarter;
          obj[`EXPENSE_WP::${date.year()}::VAR_COST`] = var_cost_year;
          obj[`EXPENSE_WP::${date.year()}::VAR_PERC`] =
            // eslint-disable-next-line no-restricted-globals
            isNaN(var_cost_year / plan_year) || !plan_year ? 0 : var_cost_year / plan_year;
        });

      const setDataForHiddenColumn = (
        selector: string,
        callback: (date: dayjs.Dayjs, key: string) => void
      ) => {
        Object.keys(bd)
          .filter((key) => key.endsWith(selector) && !key.startsWith('TO_DATE::'))
          .forEach((key) => {
            const splitkey = key.split('::');
            const date = dayjs(`01/${splitkey[0].replace('-', '/')}`);
            if (Number.isNaN(date.year())) {
              return;
            }

            callback(date, key);
          });
      };

      setDataForHiddenColumn('::PLAN', (date: dayjs.Dayjs, key: string) => {
        const planKey = `${date.year()}::PLAN`;

        obj[planKey] = (obj[planKey] || 0) + bd[key];
      });

      setDataForHiddenColumn('::VAR_COST', (date: dayjs.Dayjs) => {
        const planKey = `${date.year()}::PLAN`;
        const varCostKey = `${date.year()}::VAR_COST`;

        const actuals = obj[`EXPENSE_WP::${date.year()}`] || 0;

        obj[`${varCostKey}`] = actuals - obj[planKey];
      });

      setDataForHiddenColumn('::VAR_PERC', (date: dayjs.Dayjs) => {
        const planKey = `${date.year()}::PLAN`;
        const varPercKey = `${date.year()}::VAR_PERC`;

        const varCost = obj[`${date.year()}::VAR_COST`] || 0;

        obj[`${varPercKey}`] = this.getVarPerc(varCost, obj[planKey]);
      });

      // Fill data for quarters
      Object.keys({ ...obj })
        .filter((key) => key.startsWith('EXPENSE_WP::Q'))
        .forEach((key) => {
          const [quarter] = key.match(/Q\d-\d{4}$/) || [];
          if (!quarter) {
            return;
          }

          const quarterNumber = +quarter[1];

          const [quarterYear] = quarter.match(/\d{4}$/) || [];

          if (!quarterYear) {
            return;
          }

          const plan = Object.keys({ ...bd })
            .filter((field) => field.match(new RegExp(`-${quarterYear}::PLAN`)))
            .filter((field) => dayjs(field.replace('::PLAN', '')).quarter() === quarterNumber)
            .reduce((sum, planKey) => {
              return sum + bd[planKey];
            }, 0);

          const quarter_key = `EXPENSE_WP::${quarter}`;
          const planKey = `${quarter}::PLAN`;
          const varCostKey = `${quarter}::VAR_COST`;
          const varPerc = `${quarter}::VAR_PERC`;

          const varCost = this.getVarCost(obj[quarter_key], plan);

          obj[planKey] = plan;
          obj[varCostKey] = varCost;
          obj[varPerc] = this.getVarPerc(varCost, plan);
        });

      const h_desc = Object.getOwnPropertyDescriptor(historical_header, 'date_headers');
      if (h_desc?.writable) {
        historical_header?.date_headers.sort(this.periodSortingFunction);
      }

      return { ...bd, ...obj };
    });
    const headers: RequireSome<BudgetHeader, 'date_headers'>[] = Object.assign(remaining_header, [
      historical_header,
      forecast_header,
    ]);
    return [bud_data, headers];
  }

  openSnapshotModal = () => {
    this.overlayService.open({
      content: SnapshotModalComponent,
    });
  };
}
