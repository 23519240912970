<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>
<ng-container *ngIf="(loading$ | async) === false">
  <div
    class="px-7 py-4 bg-aux-gray-light flex flex-col border-b"
    *ngIf="{ info: budgetInfo$ | async, analytics: analytics$ | async } as obj"
  >
    <aux-processing-loader
      class="mb-7 mt-3"
      *ngIf="iCloseMonthsProcessing$ | async"
      [message]="closeMonthProcessingMessage"
    >
    </aux-processing-loader>
    <div class="aux-container flex">
      <div class="pr-7 border-r mr-7">
        <div>
          <!-- <div class="text-base">Viewing:</div> -->
          <ng-select
            class="select select__big"
            style="width: 250px"
            [clearable]="false"
            [searchable]="false"
            [multiple]="false"
            [formControl]="selectedQuarter"
          >
            <ng-option *ngFor="let quarter of quarters" [value]="quarter">
              {{ quarter.replace('-', ' ') }}
              {{ quarter === currentQuarter ? '(Current)' : '' }}
            </ng-option>
          </ng-select>
        </div>
        <div class="mt-4 font-bold">
          <div class="text-lg leading-5 pb-2">Quarter Close</div>
          <div class="month-name">{{ selectedQuarter.value.replace('-', ' ') }}</div>
        </div>
      </div>
      <div class="text-aux-gray-darkest py-4">
        <div class="text-lg mb-5">Quarter Stats</div>
        <div class="grid grid-cols-2 gap-8" *ngIf="obj.analytics">
          <div>
            <div class="text-sm">Starting Accrual Balance</div>
            <div class="text-lg">{{ obj.analytics.starting_accrual_balance | money }}</div>
          </div>
          <div>
            <div class="text-sm">Initial WP Estimate</div>
            <div class="text-lg">{{ obj.analytics.initial_wp | money }}</div>
          </div>
          <div>
            <div class="text-sm">Adjustments</div>
            <div class="text-lg">{{ obj.analytics.total_adjustments | money }}</div>
          </div>
          <div>
            <div class="text-sm">Invoices Received</div>
            <div class="text-lg">{{ obj.analytics.invoices_received | money }}</div>
          </div>
          <div>
            <div class="text-sm">Ending Accrual Balance</div>
            <div class="text-lg">{{ obj.analytics.ending_accruals | money }}</div>
          </div>
        </div>
      </div>
      <div class="flex-grow"></div>
      <div>
        <aux-close-quarter-check-list
          [readonly]="selectedQuarter.value !== currentQuarter"
          [isCurrentQuarterSelected$]="isCurrentQuarterSelected$"
          [isAdminUser]="isAdminUser"
          [processing]="(iCloseMonthsProcessing$ | async)!"
          *ngIf="isQuarterCloseEnabled$ | async"
          [quarter]="selectedQuarter.value"
        ></aux-close-quarter-check-list>
        <aux-static-check-list
          *ngIf="(isQuarterCloseEnabled$ | async) === false"
        ></aux-static-check-list>
      </div>
    </div>
  </div>

  <div class="px-4 py-4">
    <div class="flex justify-between items-center mb-4 px-7 aux-container">
      <div>
        <!-- <ng-select-->
        <!--   class="select select__big w-96"-->
        <!--   [clearable]="false"-->
        <!--   [searchable]="false"-->
        <!--   [multiple]="true"-->
        <!--   [(ngModel)]="selectedTags"-->
        <!--   [items]="tags"-->
        <!-- ></ng-select>-->
      </div>
      <div class="flex space-x-4 text-sm items-center">
        <button
          class="btn--secondary pl-3"
          type="button"
          [disabled]="(isDownloadVendorEstimateButtonDisabled$ | async) !== false"
          (click)="onDownloadVendorEstimates()"
        >
          <span inlineSVG="download.svg"></span>
          Download Vendor Estimate(s)
        </button>
        <button
          type="button"
          class="btn--secondary pl-3"
          (click)="onAddVendorEstimateUploadClick()"
        >
          <span inlineSVG="circle-plus.svg"></span>
          <span class="ml-2">Add Vendor Estimate(s)</span>
        </button>
        <aux-export-excel-button
          [gridAPI]="gridAPI"
          [variant]="exportButtonVariant"
          [excelOptions]="excelOptions"
          [getDynamicExcelParamsCallback]="getDynamicExcelParams"
        >
        </aux-export-excel-button>

        <!--<button class="btn btn--blue w-32 h-10" type="button" (click)="onInMonthClose()">-->
        <!--  Close Month-->
        <!--</button>-->
        <aux-menu #forecast_down_up_load_menu>
          <button
            type="button"
            role="menuitem"
            class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 px-4 py-2 text-sm w-full flex items-center focus:outline-none"
            (click)="onVendorEstimateUploadClick(); forecast_down_up_load_menu.close()"
          >
            <span inlineSVG="file-upload.svg"></span>
            <span class="ml-2">Upload Vendor Estimate</span>
          </button>
        </aux-menu>
      </div>
    </div>

    <div *ngIf="gridData$ | async as gridData">
      <div class="mb-8 w-full" *ngIf="showGrid$ | async">
        <ag-grid-angular
          style="height: 400px"
          class="ag-theme-aux tabular-nums month-close-table w-full"
          [rowData]="gridData"
          [gridOptions]="$any(gridOptions$ | async)"
          [components]="components"
          (gridReady)="onGridReady($event)"
          (firstDataRendered)="onFirstDataRendered($event)"
          (cellValueChanged)="onCellValueChanged($event)"
          (modelUpdated)="refreshRows()"
        >
        </ag-grid-angular>
      </div>
    </div>

    <div class="mb-8">
      <div class="border-b border-gray-200">
        <nav class="flex -mb-px space-x-8" aria-label="Tabs">
          <ng-container *ngFor="let tab of tabs; index as i">
            <button
              type="button"
              *ngIf="tab.show | async"
              (click)="onTabChange(i)"
              class="px-1 py-4 text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
              [ngClass]="{
                'border-aux-blue-light text-aux-blue-light': i == activeTabIndex,
                'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                  i != activeTabIndex
              }"
            >
              {{ tab.label }}
            </button>
          </ng-container>
        </nav>
      </div>
    </div>

    <div class="aux-container pr-4 mb-8" *ngIf="selectedQuarter.value === currentQuarter">
      <ng-container
        *ngVar="{
          isAdjustmentAvailable: isAdjustmentAvailable$ | async,
          processing: iCloseMonthsProcessing$ | async
        } as obj"
      >
        <aux-workflow-panel-general-item
          *ngIf="isWorkflowAvailable$ | async"
          [workflowName]="workflowName"
          [isWorkflowNameVisible]="false"
          [tooltipButton]="
            !obj.isAdjustmentAvailable && !obj.processing ? inMonthAdjustmentTooltip : ''
          "
          [auditTextCallback]="getWorkflowAuditText"
          [lockAvailable]="obj.isAdjustmentAvailable && !obj.processing"
          [isAdminUser]="isAdminUser"
        >
        </aux-workflow-panel-general-item>
      </ng-container>
    </div>

    <div id="inMonthAdjustmentTable"></div>
    <ng-container *ngIf="budgetInfo$ | async as info">
      <ng-container
        *ngIf="(categoryQuery.selectLoading() | async) === false && (loadingSPT$ | async) === false"
      >
        <div class="font-semibold">Accrual Modification / Monthly Detail</div>
        <div class="font-semibold">
          {{ info.name }} -
          {{ selectedCategoryHeader$ | async }}
        </div>
        <div class="text-aux-blue-light mb-4 tabular-nums">
          Month: {{ selectedMonth$ | async | date: 'MMMM y' }}
        </div>

        <!--      <ng-container *ngIf="{ manual: isSelectedVendorHasManualForecast$ | async } as obj">-->
        <!--        <ng-container *ngIf="obj.manual === true">-->
        <!--          <div class="flex items-center justify-center flex-col text-aux-gray-dark mt-28 space-y-5">-->
        <!--            <div inlineSVG="documents.svg" class="w-20"></div>-->
        <!--            <div class="text-xl font-bold">Selected vendor has a manual forecast</div>-->
        <!--          </div>-->
        <!--        </ng-container>-->

        <!--        <ng-container *ngIf="obj.manual === false">-->
        <div
          *ngIf="{
            disableAdjustment:
              ((isQuarterCloseEnabled$ | async) &&
                (adjustmentWorkflow$ | async)?.properties?.locked) ||
              currentMonth !== (selectedMonth$ | async) ||
              (userHasAdjustPermission$ | async) === false
          } as obj3"
          style="min-height: 500px"
        >
          <div
            class="font-medium gap-5 grid my-4 px-8 text-base items-end grid-cols-12 z-10 bg-white sticky top-0 py-2"
            *ngIf="(forecastData$ | async)?.length"
          >
            <div
              [ngClass]="{
                'col-span-2': (isSelectedCategoryTypeInvestigator$ | async) === true,
                'col-span-4': (isSelectedCategoryTypeInvestigator$ | async) === false
              }"
            ></div>
            <div>Total ($)</div>
            <div>Remaining ($)</div>
            <div class="col-span-2 flex flex-col items-center justify-center">
              <div class="font-bold">Auxilius Forecast</div>
              <hr class="my-2 w-full" />
              <div class="grid w-full grid-cols-2 gap-5 text-xxs font-medium pl-5">
                <div>Accrual (Units)</div>
                <div>Accrual ($)</div>
              </div>
            </div>
            <div class="col-span-2 flex flex-col items-center justify-center">
              <div class="font-bold">
                <div
                  class="flex items-center justify-center space-x-2"
                  *ngIf="(isSelectedCategoryTypeInvestigator$ | async) === false"
                >
                  <div>Vendor Estimate</div>
                  <trash-solid-icon
                    class="text-aux-error cursor-pointer"
                    [size]="16"
                    *ngIf="
                      isAdminUser &&
                      (removeVendorEstimateLoading$ | async) === false &&
                      (doesSelectedMonthHasVendorEstimate$ | async) === true &&
                      !isSelectedMonthInPast
                    "
                    (click)="removeBudgetVendorEstimate(info?.vendor_id || '', info.name || '')"
                  ></trash-solid-icon>
                  <ng-container *ngIf="removeVendorEstimateLoading$ | async">
                    <div class="border-8 h-8 m-auto spinner w-8"></div>
                  </ng-container>
                </div>

                <div *ngIf="(isSelectedCategoryTypeInvestigator$ | async) === true">
                  Evidence Based
                </div>
              </div>
              <hr class="my-2 w-full" />
              <div class="grid grid-cols-2 w-full gap-5 text-xxs font-medium pl-5">
                <div>Accrual (Units)</div>
                <div>Accrual ($)</div>
              </div>
            </div>
            <div
              class="col-span-2 flex flex-col items-center justify-center"
              *ngIf="(isSelectedCategoryTypeInvestigator$ | async) === true"
            >
              <div class="font-bold">
                <div class="flex items-center justify-center space-x-2">
                  <div>Vendor Estimate</div>
                  <trash-solid-icon
                    class="text-aux-error cursor-pointer"
                    [size]="16"
                    *ngIf="
                      isAdminUser &&
                      (removeVendorEstimateLoading$ | async) === false &&
                      (doesSelectedMonthHasVendorEstimate$ | async) === true &&
                      !isSelectedMonthInPast
                    "
                    (click)="removeBudgetVendorEstimate(info?.vendor_id || '', info.name || '')"
                  ></trash-solid-icon>
                  <ng-container *ngIf="removeVendorEstimateLoading$ | async">
                    <div class="border-8 h-8 m-auto spinner w-8"></div>
                  </ng-container>
                </div>
              </div>
              <hr class="my-2 w-full" />
              <div class="grid grid-cols-2 w-full gap-5 text-xxs font-medium pl-5">
                <div>Accrual (Units)</div>
                <div>Accrual ($)</div>
              </div>
            </div>
            <div class="font-bold">Total Monthly Accrual</div>
            <div class="font-bold">Adjustment</div>
          </div>

          <ng-container *ngFor="let category of forecastData$ | async; trackBy: identify">
            <ng-container
              [ngTemplateOutlet]="categoryTemplate"
              [ngTemplateOutletContext]="{ cat: category, isChild: false }"
            ></ng-container>
          </ng-container>

          <ng-template let-cat="cat" #categoryTemplate let-isChild="isChild">
            <ng-container
              *ngIf="{
                investigatorMode: (isSelectedCategoryTypeInvestigator$ | async) === true,
                investigatorEstimate: investigatorEstimate$ | async
              } as obj2"
            >
              <ng-container
                *ngIf="{
                  open: isCatOpen(getCatType(cat).id) | async,
                  loading: isCatLoading(getCatType(cat).id) | async,
                  category: getCatType(cat)
                } as obj"
              >
                <div
                  class="py-5 px-8 grid gap-5 text-base grid-cols-12 tabular-nums"
                  [ngClass]="{
                    'bg-aux-gray-light': obj.open,
                    'border rounded-md mb-5': !isChild,
                    'border-t border-b -mx-8': isChild
                  }"
                >
                  <div
                    class="relative"
                    [ngClass]="{
                      'col-span-2': obj2.investigatorMode,
                      'col-span-4': !obj2.investigatorMode
                    }"
                  >
                    <div class="flex items-center font-semibold text-base h-full justify-between">
                      <span class="overflow-ellipsis overflow-hidden">{{ obj.category.name }}</span>
                      <button (click)="toggleCat(obj.category.id)" type="button" class="ml-2">
                        <chevron-down-solid-icon *ngIf="!obj.open"></chevron-down-solid-icon>
                        <chevron-up-solid-icon *ngIf="obj.open"></chevron-up-solid-icon>
                      </button>
                    </div>
                    <div
                      *ngIf="obj2.investigatorMode && obj.open"
                      class="font-normal text-xs absolute -bottom-3 aux-link cursor-pointer"
                      [routerLink]="[investigatorDetailLink]"
                    >
                      View Investigator Detail
                    </div>
                  </div>

                  <div class="flex items-center col-span-1">
                    <!-- Total($) -->
                    {{ obj.category[BudgetType.BUDGET_PRIMARY]?.total_cost | money }}
                  </div>
                  <div class="flex items-center col-span-1">
                    <!-- Remaining($) -->
                    {{
                      $any(obj.category[BudgetType.BUDGET_PRIMARY]?.total_cost) -
                        $any(obj.category[BudgetType.BUDGET_PRIMARY]?.total_wp) | money
                    }}
                  </div>

                  <ng-container *ngIf="obj2.investigatorMode">
                    <div class="col-span-2 grid grid-cols-2 gap-5 pl-5">
                      <!-- Accrual(Units) -->
                      <div class="flex items-center">—</div>
                      <!-- Accrual($) -->
                      <div class="flex items-center">
                        {{ obj.category[BudgetType.BUDGET_PRIMARY]?.total_forecast | money }}
                      </div>
                    </div>

                    <div class="col-span-2 grid grid-cols-2 gap-5 pl-5">
                      <!-- Accrual(Units) -->
                      <div class="flex items-center">—</div>
                      <!-- Accrual($) -->
                      <div class="flex items-center">
                        {{ obj2?.investigatorEstimate?.total_all | money }}
                      </div>
                    </div>

                    <div class="col-span-2 grid grid-cols-2 gap-5 pl-5">
                      <!-- Accrual(Units) -->
                      <div class="flex items-center">—</div>
                      <!-- Accrual($) -->
                      <div class="flex items-center">
                        {{
                          obj.category[BudgetType.BUDGET_VENDOR_ESTIMATE]?.total_forecast | money
                        }}
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!obj2.investigatorMode">
                    <div class="col-span-2 grid grid-cols-2 gap-5 pl-5">
                      <!-- Accrual(Units) -->
                      <div class="flex items-center">—</div>
                      <!-- Accrual($) -->
                      <div class="flex items-center">
                        {{ obj.category[BudgetType.BUDGET_PRIMARY]?.total_forecast | money }}
                      </div>
                    </div>

                    <div class="col-span-2 grid grid-cols-2 gap-5 pl-5">
                      <!-- Accrual(Units) -->
                      <div class="flex items-center">—</div>
                      <!-- Accrual($) -->
                      <div class="flex items-center">
                        {{
                          obj.category[BudgetType.BUDGET_VENDOR_ESTIMATE]?.total_forecast | money
                        }}
                      </div>
                    </div>
                  </ng-container>

                  <!--Total Monthly Accrual-->
                  <div class="flex items-center">
                    {{
                      $any(obj.category[BudgetType.BUDGET_PRIMARY]?.total_override_adjusted) | money
                    }}
                  </div>

                  <ng-container *ngIf="obj2.investigatorMode">
                    <ng-container
                      [ngTemplateOutlet]="adjustmentTemplate"
                      [ngTemplateOutletContext]="{
                        val: obj.category[BudgetType.BUDGET_VENDOR_ESTIMATE]?.total_forecast
                          ? (obj.category[BudgetType.BUDGET_PRIMARY]?.total_override_adjusted ||
                              0) -
                            (obj.category[BudgetType.BUDGET_VENDOR_ESTIMATE]?.total_forecast || 0)
                          : obj2?.investigatorEstimate?.show_adjustment
                          ? obj.category[BudgetType.BUDGET_PRIMARY]?.adjustment
                          : (obj.category[BudgetType.BUDGET_PRIMARY]?.total_override_adjusted ||
                              0) - (obj2?.investigatorEstimate?.total_all || 0)
                      }"
                    ></ng-container>
                  </ng-container>

                  <ng-container *ngIf="!obj2.investigatorMode">
                    <ng-container
                      [ngTemplateOutlet]="adjustmentTemplate"
                      [ngTemplateOutletContext]="{
                        val: obj.category[BudgetType.BUDGET_PRIMARY]?.adjustment
                      }"
                    ></ng-container>
                  </ng-container>

                  <ng-container *ngIf="obj.open">
                    <ng-container *ngIf="obj.loading">
                      <div class="border-8 h-32 m-auto my-16 spinner w-32 col-span-12"></div>
                    </ng-container>
                    <ng-container *ngIf="!obj.loading">
                      <div
                        *ngFor="let sub_category_id of obj.category.sub_category_ids"
                        class="col-span-11"
                      >
                        <ng-container
                          *ngIf="
                            categoryQuery.selectCategory(sub_category_id) | async as sub_category
                          "
                        >
                          <ng-container
                            [ngTemplateOutlet]="categoryTemplate"
                            [ngTemplateOutletContext]="{
                              cat: sub_category,
                              isChild: true
                            }"
                          ></ng-container>
                        </ng-container>
                      </div>

                      <ng-container *ngFor="let sub_activity of obj.category.activities">
                        <div
                          class="flex items-center"
                          [ngClass]="{
                            'col-span-2': obj2.investigatorMode,
                            'col-span-4': !obj2.investigatorMode
                          }"
                        >
                          <div class="text-base overflow-ellipsis line-clamp-3">
                            {{ sub_activity.name }}
                          </div>
                        </div>

                        <div class="flex items-center col-span-1">
                          <!-- Total($) -->

                          {{ sub_activity[BudgetType.BUDGET_PRIMARY]?.total_cost | money }}
                        </div>

                        <div class="flex items-center col-span-1">
                          <!-- Remaining($) -->
                          {{
                            $any(sub_activity[BudgetType.BUDGET_PRIMARY]?.total_cost) -
                              $any(sub_activity[BudgetType.BUDGET_PRIMARY]?.total_wp) | money
                          }}
                        </div>

                        <ng-container *ngIf="obj2.investigatorMode">
                          <div class="col-span-2 grid grid-cols-2 gap-5 pl-5">
                            <!-- Accrual(Units) -->
                            <ng-container
                              *ngIf="obj.category.category_type !== 'CATEGORY_DISCOUNT'"
                            >
                              <div *ngIf="sub_activity.unit_cost !== 0" class="flex items-center">
                                {{
                                  $any(sub_activity[BudgetType.BUDGET_PRIMARY]?.total_forecast) /
                                    $any(sub_activity.unit_cost) | auxNumber
                                }}
                              </div>
                              <div *ngIf="sub_activity.unit_cost === 0" class="flex items-center">
                                —
                              </div>
                            </ng-container>
                            <ng-container
                              *ngIf="obj.category.category_type === 'CATEGORY_DISCOUNT'"
                            >
                              <div class="flex items-center">—</div>
                            </ng-container>
                            <!-- Accrual($) -->
                            <div class="flex items-center">
                              {{
                                $any(sub_activity[BudgetType.BUDGET_PRIMARY]?.total_forecast)
                                  | money
                              }}
                            </div>
                          </div>
                          <div class="col-span-2 grid grid-cols-2 gap-5 pl-5">
                            <!-- Accrual(Units) -->
                            <div class="flex items-center">—</div>
                            <!-- Accrual($) -->
                            <div class="flex items-center">
                              {{
                                (sub_activity.name === 'Other'
                                  ? obj2?.investigatorEstimate?.other
                                  : sub_activity.name === 'Overhead'
                                  ? obj2?.investigatorEstimate?.overhead
                                  : obj2?.investigatorEstimate?.patient
                                ) | money
                              }}
                            </div>
                          </div>
                          <div class="col-span-2 grid grid-cols-2 gap-5 pl-5">
                            <!-- Accrual(Units) -->
                            <div *ngIf="sub_activity.unit_cost !== 0" class="flex items-center">
                              {{
                                $any(
                                  sub_activity[BudgetType.BUDGET_VENDOR_ESTIMATE]?.total_forecast
                                ) / $any(sub_activity.unit_cost) | auxNumber
                              }}
                            </div>
                            <div *ngIf="sub_activity.unit_cost === 0" class="flex items-center">
                              —
                            </div>
                            <!-- Accrual($) -->
                            <div class="flex items-center">
                              {{
                                $any(
                                  sub_activity[BudgetType.BUDGET_VENDOR_ESTIMATE]?.total_forecast
                                ) | money
                              }}
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!obj2.investigatorMode">
                          <div class="col-span-2 grid grid-cols-2 gap-5 pl-5">
                            <!-- Accrual(Units) -->

                            <ng-container
                              *ngIf="obj.category.category_type !== 'CATEGORY_DISCOUNT'"
                            >
                              <div *ngIf="sub_activity.unit_cost !== 0" class="flex items-center">
                                {{
                                  $any(sub_activity[BudgetType.BUDGET_PRIMARY]?.total_forecast) /
                                    $any(sub_activity.unit_cost) | auxNumber
                                }}
                              </div>
                              <div *ngIf="sub_activity.unit_cost === 0" class="flex items-center">
                                —
                              </div>
                            </ng-container>
                            <ng-container
                              *ngIf="obj.category.category_type === 'CATEGORY_DISCOUNT'"
                            >
                              <div class="flex items-center">—</div>
                            </ng-container>

                            <!-- Accrual($) -->
                            <div class="flex items-center">
                              {{
                                $any(sub_activity[BudgetType.BUDGET_PRIMARY]?.total_forecast)
                                  | money
                              }}
                            </div>
                          </div>

                          <div class="col-span-2 grid grid-cols-2 gap-5 pl-5">
                            <!-- Accrual(Units) -->
                            <div *ngIf="sub_activity.unit_cost !== 0" class="flex items-center">
                              {{
                                $any(
                                  sub_activity[BudgetType.BUDGET_VENDOR_ESTIMATE]?.total_forecast
                                ) / $any(sub_activity.unit_cost) | auxNumber
                              }}
                            </div>
                            <div *ngIf="sub_activity.unit_cost === 0" class="flex items-center">
                              —
                            </div>
                            <!-- Accrual($) -->
                            <div class="flex items-center">
                              {{
                                $any(
                                  sub_activity[BudgetType.BUDGET_VENDOR_ESTIMATE]?.total_forecast
                                ) | money
                              }}
                            </div>
                          </div>
                        </ng-container>

                        <div class="flex items-center">
                          <!-- Final Accrual($) -->
                          <div>
                            {{
                              $any(sub_activity[BudgetType.BUDGET_PRIMARY]?.total_override_adjusted)
                                | money
                            }}
                          </div>

                          <div
                            inlineSVG="eye.svg"
                            [setSVGAttributes]="{ class: 'w-5 h-5' }"
                            class="w-5 h-5 ml-2 cursor-pointer"
                            *ngIf="obj3.disableAdjustment"
                            (click)="onOpenDialog(obj.category, sub_activity, true)"
                          ></div>

                          <pencil-outline-icon
                            *ngIf="
                              !obj3.disableAdjustment &&
                              obj.category.category_type !== 'CATEGORY_DISCOUNT'
                            "
                            [size]="16"
                            class="w-4 ml-2 cursor-pointer"
                            (click)="onOpenDialog(obj.category, sub_activity)"
                          ></pencil-outline-icon>
                        </div>

                        <ng-container *ngIf="obj2.investigatorMode">
                          <ng-container
                            [ngTemplateOutlet]="adjustmentTemplate"
                            [ngTemplateOutletContext]="{
                              val: sub_activity[BudgetType.BUDGET_VENDOR_ESTIMATE]?.total_forecast
                                ? (sub_activity[BudgetType.BUDGET_PRIMARY]
                                    ?.total_override_adjusted || 0) -
                                  (sub_activity[BudgetType.BUDGET_VENDOR_ESTIMATE]
                                    ?.total_forecast || 0)
                                : obj2?.investigatorEstimate?.show_adjustment
                                ? sub_activity[BudgetType.BUDGET_PRIMARY]?.adjustment
                                : (sub_activity[BudgetType.BUDGET_PRIMARY]
                                    ?.total_override_adjusted || 0) -
                                  ((sub_activity.name === 'Other'
                                    ? obj2?.investigatorEstimate?.other
                                    : sub_activity.name === 'Overhead'
                                    ? obj2?.investigatorEstimate?.overhead
                                    : obj2?.investigatorEstimate?.patient) || 0)
                            }"
                          ></ng-container>
                        </ng-container>

                        <ng-container *ngIf="!obj2.investigatorMode">
                          <ng-container
                            [ngTemplateOutlet]="adjustmentTemplate"
                            [ngTemplateOutletContext]="{
                              val: sub_activity[BudgetType.BUDGET_PRIMARY]?.adjustment
                            }"
                          ></ng-container>
                        </ng-container>

                        <!--                        <div-->
                        <!--                          [ngClass]="{-->
                        <!--                            'text-aux-error':-->
                        <!--                              $any(sub_activity[BudgetType.BUDGET_PRIMARY]?.adjustment) > 0,-->
                        <!--                            'text-aux-green':-->
                        <!--                              $any(sub_activity[BudgetType.BUDGET_PRIMARY]?.adjustment) < 0-->
                        <!--                          }"-->
                        <!--                          class="flex items-center"-->
                        <!--                        >-->
                        <!--                          &lt;!&ndash; Override($) &ndash;&gt;-->
                        <!--                          {{-->
                        <!--                            $any(sub_activity[BudgetType.BUDGET_PRIMARY]?.adjustment) > 0-->
                        <!--                              ? '+'-->
                        <!--                              : ''-->
                        <!--                          }}{{ sub_activity[BudgetType.BUDGET_PRIMARY]?.adjustment | money }}-->
                        <!--                        </div>-->
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-template>

          <ng-template #adjustmentTemplate let-val="val">
            <div
              [ngClass]="{
                'text-aux-error': $any(val) > 0,
                'text-aux-green': $any(val) < 0
              }"
              class="flex items-center"
            >
              <!-- Override($) -->{{ $any(val) > 0 ? '+' : '' }}{{ val | money }}
            </div>
          </ng-template>

          <div *ngIf="!(categoryQuery.selectTopCategories$ | async)?.length" class="mt-5">
            No budget data found for this vendor
          </div>
        </div>
        <!--        </ng-container>-->
        <!--      </ng-container>-->
      </ng-container>
    </ng-container>
    <div
      style="min-height: 500px"
      *ngIf="(categoryQuery.selectLoading() | async) === true || (loadingSPT$ | async) === true"
    >
      <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
    </div>
  </div>
</ng-container>
