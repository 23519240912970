import { Component } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { CustomOverlayRef } from '../../overlay/custom-overlay-ref';
import { enter, leave } from '../../../animations';

@Component({
  selector: 'aux-notification-toast',
  templateUrl: './notification-toast.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  animations: [
    trigger('enterLeave', [
      transition(':enter', [useAnimation(enter)]),
      transition(':leave', [useAnimation(leave)]),
    ]),
  ],
})
export class NotificationToastComponent {
  messages = [] as string[];

  constructor(public ref: CustomOverlayRef<null, { messages?: string[]; mode: string }>) {
    if (this.ref.data?.messages) {
      this.messages = this.ref.data.messages;
    }
  }
}
