import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';
import { Column, IHeaderGroupParams, IProvidedColumn } from 'ag-grid-community';

interface IEnhanceHeaderGroupParams extends IHeaderGroupParams {
  dataId?: string;
}

@Component({
  template: `
    <div class="flex items-center justify-center space-x-2">
      <span [attr.data-id]="params?.dataId">{{ params?.displayName }}</span>
      <button class="flex items-center no-underline aux-link" (click)="toggleExpand()">
        <span
          class="text-white"
          inlineSVG="chevron-right.svg"
          [setSVGAttributes]="{
            class: 'w-4 h-4',
            transform: !visible ? 'scale(-1, 1)' : ''
          }"
        ></span>
        <span
          class="text-white"
          inlineSVG="chevron-right.svg"
          [setSVGAttributes]="{
            class: 'w-4 h-4',
            transform: 'rotate(180)' + (!visible ? ' scale(-1, 1)' : '')
          }"
        ></span>
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgHeaderExpandComponent implements IHeaderGroupAngularComp {
  params!: IEnhanceHeaderGroupParams;

  visible = false;

  agInit(params: IEnhanceHeaderGroupParams): void {
    this.params = params;
  }

  filterCols(column: IProvidedColumn, index: number, columns: IProvidedColumn[]) {
    return index && index !== columns.length - 1;
  }

  toggleExpand() {
    const fields = this.params?.columnGroup
      .getProvidedColumnGroup()
      .getChildren()
      .filter(this.filterCols)
      .map((e) => (e as Column).getColDef().field || '');
    this.params?.columnApi.setColumnsVisible(fields, !this.visible);
    this.visible = !this.visible;
  }
}
