import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';

export interface RemoveDialogInput {
  header: string;
  cannotDeleteMessage: string;
  routeInputs: RouteInput[];
}

export interface RouteInput {
  componentName: string;
  name: string[];
  link: string;
}

@Component({
  template: `
    <div [innerHTML]="cannotDeleteMessage"></div>
    <div class="mb-4" *ngFor="let route of routeInputs">
      <br />{{ route.componentName }}<br />
      <ul class="list-disc pl-4" *ngFor="let name of route.name">
        <li *ngIf="name">
          <a class="aux-link cursor-pointer" [routerLink]="[route.link]" (click)="this.ref.close()"
            >{{ name }}
          </a>
        </li>
      </ul>
    </div>
    <div class="flex items-center space-x-4">
      <button class="btn btn--blue" disabled="true">Remove</button
      ><button class="aux-link" (click)="this.ref.close()">Cancel</button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveDialogComponent {
  header: string;

  cannotDeleteMessage: string;

  routeInputs: RouteInput[];

  constructor(public ref: CustomOverlayRef<any, RemoveDialogInput>) {
    this.header = ref.data?.header || '';
    this.cannotDeleteMessage = ref.data?.cannotDeleteMessage || '';
    this.routeInputs = ref.data?.routeInputs || [];
  }
}
