import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { GqlService, PatientGroupType } from '@services/gql.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { PatientGroupsModel } from './patient-groups.model';
import { PatientGroupsStore } from './patient-groups.store';

@Injectable({ providedIn: 'root' })
export class PatientGroupsService {
  constructor(
    private patientGroupsStore: PatientGroupsStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService
  ) {}

  get(patientGroupTypes?: PatientGroupType[]) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.patientGroupsStore.setLoading(true);

        return this.gqlService
          .listPatientGroups$(
            patientGroupTypes || [
              PatientGroupType.PATIENT_GROUP_FORECAST_ONLY,
              PatientGroupType.PATIENT_GROUP_STANDARD,
            ]
          )
          .pipe(
            map(({ data, success, errors }) => {
              let patientGroups = [] as PatientGroupsModel[];
              if (data) {
                patientGroups = data.map((patientGroup) => {
                  return {
                    ...patientGroup,
                    curveOnly: patientGroup.type === PatientGroupType.PATIENT_GROUP_FORECAST_ONLY,
                  } as PatientGroupsModel;
                });
                this.patientGroupsStore.set(patientGroups);
              }

              this.patientGroupsStore.setLoading(false);
              return { success, errors, data: patientGroups };
            })
          );
      })
    );
  }

  async add({
    name,
    description,
    type,
    rank_order,
  }: {
    name: string;
    description: string | null;
    type: PatientGroupType;
    rank_order: number | null;
  }) {
    const { errors, success, data } = await this.gqlService
      .createPatientGroup$({ name, description, type, rank_order })
      .toPromise();

    let patientGroup: PatientGroupsModel | null = null;
    if (success && data) {
      patientGroup = {
        ...data,
        curveOnly: data.type === PatientGroupType.PATIENT_GROUP_FORECAST_ONLY,
      } as PatientGroupsModel;

      this.patientGroupsStore.add(patientGroup);
    }

    return { errors, success, data: patientGroup };
  }

  async update(patientGroup: PatientGroupsModel) {
    const { success, errors, data } = await this.gqlService
      .updatePatientGroup$({
        id: patientGroup.id,
        name: patientGroup.name,
        description: patientGroup.description,
        rank_order: patientGroup.rank_order,
        type: patientGroup.type,
      })
      .toPromise();

    if (success && data) {
      this.patientGroupsStore.update(patientGroup.id, {
        name: patientGroup.name,
        description: patientGroup.description,
        rank_order: patientGroup.rank_order,
        type: patientGroup.type,
        curveOnly: patientGroup.type === PatientGroupType.PATIENT_GROUP_FORECAST_ONLY,
      });
    }

    return { success, errors, data };
  }

  async remove(patientGroup: PatientGroupsModel) {
    const { success, errors } = await this.gqlService
      .removePatientGroup$(patientGroup.id)
      .toPromise();
    if (success) {
      this.patientGroupsStore.remove(patientGroup.id);
    }

    return { success, errors };
  }
}
