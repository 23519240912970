<div class="border rounded border-aux-gray-dark grid grid-cols-12 h-28 py-3 relative">
  <div class="flex flex-col justify-center rounded-l pl-5 pr-5 col-span-7" (click)="openList()">
    <div class="font-bold text-aux-black">{{ config.header }}</div>
    <div
      class="flex items-center space-x-4"
      [ngClass]="{ invisible: loading }"
      *ngIf="config.options && config.options.length > 0"
    >
      <div class="text-sm">{{ selected }}</div>
      <div>
        <div class="-mt-1 w-2 h-2 border-r border-b transform rotate-45 border-aux-gray-dark-100"></div>
      </div>
    </div>
    <div
      class="text-sm text-aux-gray-darkest"
      *ngIf="!loading && config.sub"
      [innerHtml]="config.sub"
    ></div>
  </div>
  <div class="flex space-x-2 items-center justify-center rounded-r border-l col-span-5">
    <svg
      *ngIf="config.showArrowUp"
      class="w-6 h-6 text-aux-error"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
        clip-rule="evenodd"
      ></path>
    </svg>
    <div
      class="text-lg font-bold text-aux-gray-darkest"
      *ngIf="!loading"
      [innerHtml]="config.data[selectedIndex]"
    ></div>
    <div class="spinner" *ngIf="loading"></div>
  </div>

  <div class="absolute top-32">
    <div cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>
  </div>
</div>

<ng-template
  #overlay="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isListOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'ttt'"
  (detach)="closeList()"
  (backdropClick)="closeList()"
>
  <div
    class="bg-white shadow-lg overflow-hidden rounded text-aux-black text-sm w-full border border-aux-gray-dark"
  >
    <ul
      tabindex="-1"
      role="listbox"
      class="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
      (keydown)="onListKeydown($event)"
    >
      <ng-container *ngFor="let option of config.options; index as index">
        <li
          id="listbox-option-{{ index }}"
          role="option"
          class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-16"
          [ngClass]="{
            'bg-aux-gray-light': index === highlighted
          }"
          (mouseenter)="highlightOption(index)"
          (mouseleave)="highlighted = null"
          (click)="selectOption()"
        >
          <span
            class="font-normal block truncate"
            [ngClass]="{
              'font-medium': index === selectedIndex
            }"
          >
            {{ option }}
          </span>

          <div
            *ngIf="index === selectedIndex"
            class="absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <svg
              class="w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
