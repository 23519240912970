<ng-container *ngIf="(loadedOnce$ | async) === false">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="(loadedOnce$ | async) === true">
  <div class="px-8 py-8 bg-aux-gray-light border-b">
    <div class="flex justify-between items-center mb-3.5">
      <h1 class="text-2xl text-aux-gray-darkest font-bold h-11 flex items-end">Audit History</h1>
      <div class="flex space-x-2">
        <div class="flex justify-between items-center space-x-4">
          <aux-processing-loader *ngIf="loading$ | async" [message]="processorMessage">
          </aux-processing-loader>
          <div *ngIf="(loading$ | async) === false">
            <aux-button
              classList="h-8 bg-white"
              variant="secondary"
              label="Refresh Audit History"
              icon="cloud-download"
              (click)="triggerFetchAuditHistory()"
            ></aux-button>
          </div>
          <!--          <button class="h-9 pl-3 btn btn&#45;&#45;secondary pl-3 mr-1" (click)="triggerFetchAuditLog()">-->
          <!--            <span inlineSVG="cloud-download.svg" style="padding-right: 0.5em"></span> Sync-->
          <!--          </button>-->
          <button class="h-9 focus:outline-none aux-link underline mr-1" (click)="resetFilter()">
            Reset Filters
          </button>
          <aux-export-excel-button
            [className]="'ml-1 h-8 bg-white'"
            [gridAPI]="gridAPI"
            [excelOptions]="excelOptions"
            [getDynamicExcelParamsCallback]="getDynamicExcelParams.bind(this)"
          >
          </aux-export-excel-button>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-10 content-start mb-6 w-full">
      <div class="flex justify-start col-span-10 w-full">
        <div>
          <div class="h-4 mb-1"></div>
          <aux-input
            style="width: 679px"
            class="mr-2 mb-5"
            placeholder="Search"
            [(ngModel)]="nameFilterValue"
            (change)="filterChanges()"
            (keyup)="updateSearch($event?.target)"
            icon="search.svg"
          ></aux-input>
        </div>
        <div class="w-full mr-2 mb-5" style="width: 480px">
          <div class="text-xs mb-1">Category</div>
          <ng-select
            [clearable]="false"
            [multiple]="true"
            (change)="filterChanges()"
            [formControl]="selectedCategory"
            placeholder="All"
          >
            <ng-container *ngVar="category$ | async as categories">
              <ng-option [value]="''" *ngIf="categories && categories.length > 1">All</ng-option>
              <ng-option [value]="category.id" *ngFor="let category of categories">
                <span [title]="category.name">{{ category.name }}</span>
              </ng-option>
            </ng-container>
          </ng-select>
        </div>
        <div class="w-full mb-5" style="width: 480px">
          <div class="text-xs mb-1">Action:</div>
          <ng-select
            [clearable]="false"
            [multiple]="true"
            [formControl]="selectedAction"
            (change)="filterChanges()"
            placeholder="All"
          >
            <ng-container *ngVar="actions$ | async as actions">
              <ng-option [value]="''" *ngIf="actions && actions.length > 1">All</ng-option>
              <ng-option [value]="action.id" *ngFor="let action of actions">
                <span [title]="action.name">{{ action.name }}</span>
              </ng-option>
            </ng-container>
          </ng-select>
        </div>
      </div>
      <div class="flex justify-start col-span-10 w-full">
        <div class="flex justify-start items-start">
          <aux-input
            class="mr-2 w-96"
            label="From"
            [type]="'date'"
            placeholder="YYYY-MM-DD"
            [formControl]="fromDate"
            (change)="filterChanges()"
          ></aux-input>
          <aux-input
            class="mr-2 w-96"
            label="To"
            [type]="'date'"
            placeholder="YYYY-MM-DD"
            [formControl]="toDate"
            (change)="filterChanges()"
          ></aux-input>
        </div>
        <div class="w-full mr-2" style="width: 480px">
          <div class="text-xs mb-1">Vendor</div>
          <ng-select
            placeholder="All"
            [multiple]="true"
            [clearable]="false"
            (change)="filterChanges()"
            [formControl]="selectedVendor"
          >
            <ng-container *ngVar="vendors$ | async as vendors">
              <ng-option [value]="''" *ngIf="vendors && vendors.length > 1">All</ng-option>
              <ng-option [value]="vendor.id" *ngFor="let vendor of vendors">
                <span [title]="vendor.name">{{ vendor.name }}</span>
              </ng-option>
            </ng-container>
          </ng-select>
        </div>
        <div class="w-full" style="width: 480px">
          <div class="text-xs mb-1">User</div>
          <ng-select
            [multiple]="true"
            [clearable]="false"
            (change)="filterChanges()"
            [formControl]="selectedUser"
            placeholder="All"
          >
            <ng-container *ngVar="users$ | async as users">
              <ng-option [value]="''" *ngIf="users && users.length > 1">All</ng-option>
              <ng-option [value]="user.sub" *ngFor="let user of users">
                <span [title]="user.given_name">{{
                  user.given_name + ' ' + user.family_name
                }}</span>
              </ng-option>
            </ng-container>
          </ng-select>
        </div>
      </div>
      <!--        <div class="grid mr-2">-->
      <!--          <div class="mr-2 w-96 mb-1">-->
      <!--            <div class="text-xs mb-1">Materiality:</div>-->
      <!--            <ng-select-->
      <!--              class="select select__big"-->
      <!--              [clearable]="false"-->
      <!--              [multiple]="false"-->
      <!--              [searchable]="false"-->
      <!--              bindValue="value"-->
      <!--            >-->
      <!--            </ng-select>-->
      <!--          </div>-->
      <!--          <div class="w-96 mr-2">-->
      <!--            <div class="text-xs mb-1">Departments/Role</div>-->
      <!--            <ng-select-->
      <!--              class="select select__big"-->
      <!--              [multiple]="false"-->
      <!--              [clearable]="false"-->
      <!--              (change)="userChanges($event)"-->
      <!--            >-->
      <!--              <ng-container *ngVar="users$ | async as users">-->
      <!--                <ng-option [value]="''" *ngIf="users.length > 1">All</ng-option>-->
      <!--                <ng-option [value]="user.sub" *ngFor="let user of users">-->
      <!--                  <span [title]="user.given_name">{{-->
      <!--                    user.given_name + ' ' + user.family_name-->
      <!--                  }}</span>-->
      <!--                </ng-option>-->
      <!--              </ng-container>-->
      <!--            </ng-select>-->
      <!--          </div>-->
      <!--        </div>-->
    </div>
  </div>
</ng-container>

<ng-container *ngIf="(loadedOnce$ | async) === true">
  <div class="px-8 py-6">
    <ag-grid-angular
      style="height: 400px"
      class="ag-theme-alpine audit-history-table tabular-nums w-full"
      [gridOptions]="$any(gridOptions$ | async)"
      (firstDataRendered)="onDataRendered($event)"
      (gridReady)="onGridReady($event)"
      (columnResized)="autoSize()"
      [quickFilterText]="nameFilterValue"
    >
    </ag-grid-angular>
  </div>
</ng-container>
