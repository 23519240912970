<ng-container *ngIf="$any(loading$ | async)">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<div *ngIf="!$any(loading$ | async)">
  <ng-container *ngIf="{ activeIndex: activeTabIndex$ | async } as obj">
    <div *ngIf="tabs.length > 1">
      <ng-container *ngFor="let tab of tabs; index as i">
        <button
          *ngIf="tab.show | async"
          type="button"
          (click)="changeTab(i, tab.label)"
          class="px-4 py-2 rounded text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
          [ngClass]="{
            'border-aux-blue-light bg-aux-blue-light text-white': i == obj.activeIndex,
            'border-transparent text-gray-500 hover:text-gray-700':
              i != obj.activeIndex
          }"
        >
          {{ tab.label }}
        </button>
      </ng-container>
    </div>
  </ng-container>
  <div class="flex justify-end mb-4">
    <aux-button
      *ngIf="(editModeGrid$ | async) === false"
      variant="secondary"
      label="Edit"
      icon="pencil"
      classList="h-8"
      [svgAttributes]="{ class: 'w-4 h-4' }"
      [onClick]="editGrid.bind(this)"
    ></aux-button>

    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="secondary"
      label="Cancel"
      icon="x"
      classList="h-8"
      [svgAttributes]="{ class: 'w-4 h-4' }"
      [onClick]="cancelEditMode.bind(this)"
    ></aux-button>

    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="success"
      label="Save"
      icon="check"
      classList="h-8"
      class="ml-1"
      [disabled]="!hasChanges"
      [svgAttributes]="{ class: 'w-4 h-4' }"
      [onClick]="onSaveAll"
    ></aux-button>
  </div>

  <ag-grid-angular
    id="patientBudgetEntryGrid"
    class="ag-theme-aux tabular-nums"
    [gridOptions]="$any(gridOptions$ | async)"
    [rowData]="$any(gridData$ | async)"
    [enableFillHandle]="(editModeGrid$ | async) === true"
    [rowDragManaged]="true"
    [animateRows]="true"
    (gridReady)="onGridReady($event)"
    (cellValueChanged)="cellValueChanged($event)"
    (rowDragEnd)="onRowDragEnd($event)"
    (gridSizeChanged)="sizeColumnsToFit()"
  >
  </ag-grid-angular>

  <div class="mt-4">
    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="primary"
      label="Add Entry"
      [onClick]="onAddPatientProtocol.bind(this)"
    ></aux-button>
  </div>
</div>
