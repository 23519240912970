import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'aux-processing-loader',
  template: `<div class="flex items-center p-4 border border-aux-warn bg-white">
    <span
      class="animate-spin"
      style="animation: spin 5s linear infinite"
      inlineSVG="loader.svg"
    ></span>
    <span class="ml-6 text-lg">{{ message }}</span>
  </div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessingLoaderComponent {
  @Input() message!: string;
}
