import { Injectable } from '@angular/core';
import {
  EntityState,
  EntityStore,
  EntityUIStore,
  getEntityType,
  StoreConfig,
} from '@datorama/akita';
import { BudgetForecastSetting } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface ForecastSettingsState
  extends EntityState<
    RequireSome<Partial<BudgetForecastSetting>, 'id' | 'amount_type' | 'override'> & {
      total_cost: number;
      total_wp: number;
      total_accrual: number;
      total_forecast: number;
      total_override: number;
      unit_cost: number;
    }
  > {}

export type ForecastSettingsModel = getEntityType<ForecastSettingsState>;

export type ForecastSettingsUI = {
  showError: boolean;
  errorMessage?: string;
  unforecasted: boolean;
};

export interface ForecastSettingsUIState extends EntityState<ForecastSettingsUI> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'forecast-settings' })
export class ForecastSettingsStore extends EntityStore<ForecastSettingsState> {
  ui!: EntityUIStore<ForecastSettingsUIState>;

  constructor() {
    super({});

    const defaultUiState: ForecastSettingsUI = {
      showError: false,
      unforecasted: false,
    };
    this.createUIStore().setInitialEntityState(defaultUiState);
  }
}
