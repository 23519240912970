<ng-container *ngIf="$any(timelineQuery.selectLoading() | async) || (saving$ | async)!">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>
<ng-container *ngIf="!$any(timelineQuery.selectLoading() | async) && !(saving$ | async)!">
  <aux-workflow-panel
    [workflowName]="workflowName"
    [isAdminUser]="isAdminUser"
    *ngIf="isQuarterCloseEnabled$ | async"
    [processing]="(iCloseMonthsProcessing$ | async)!"
  ></aux-workflow-panel>
  <div class="form-group flex justify-between my-4">
    <aux-input
      class="w-72"
      placeholder="Search"
      [(ngModel)]="nameFilterValue"
      icon="search.svg"
    ></aux-input>
    <aux-button
      variant="primary"
      label="Add Milestone"
      *ngIf="userHasModifyPermissions$ | async"
      classList="h-10"
      [disabled]="(isTimeLineFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
      [onClick]="this.addTimelineMilestone.bind(this)"
      [auxTooltip]="
        (isTimeLineFinalized$ | async) || (iCloseMonthsProcessing$ | async)
          ? messagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE
          : ''
      "
    >
    </aux-button>
  </div>
  <ag-grid-angular
    id="timelineGrid"
    class="ag-theme-aux tabular-nums"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    [quickFilterText]="nameFilterValue"
    (firstDataRendered)="onDataRendered($event)"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="sizeColumnsToFit()"
    (rowGroupOpened)="onGroupOpened($event)"
    (rowDataChanged)="onRowDataChanged()"
  >
  </ag-grid-angular>
</ng-container>

<aux-save-changes [onSaveChanges]="onSaveAll" *ngIf="(hasChanges$ | async)!"></aux-save-changes>
