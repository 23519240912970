import { GridOptions } from 'ag-grid-community';

export const gridOptions: GridOptions = {
  headerHeight: 45,
  defaultColDef: {
    wrapText: true,
    autoHeight: true,
    cellClass: 'text-left',
    rowDrag: false,
  },
  columnDefs: [
    {
      headerName: 'Property',
      headerClass: 'ag-header-align-center font-bold',
      field: 'prop',
      suppressMenu: true,
    },
    {
      headerName: 'Description',
      headerClass: 'ag-header-align-center font-bold',
      field: 'description',
      suppressMenu: true,
      width: 550,
    },
    {
      headerName: 'Type',
      headerClass: 'ag-header-align-center font-bold',
      field: 'type',
      suppressMenu: true,
    },
    {
      headerName: 'Default value',
      headerClass: 'ag-header-align-center font-bold',
      field: 'default',
      suppressMenu: true,
    },
    {
      headerName: 'Example',
      headerClass: 'ag-header-align-center font-bold',
      field: 'example',
      suppressMenu: true,
      width: 300,
    },
  ],
};

export const gridData = [
  {
    prop: 'type',
    description: 'Button type in HTML',
    type: `'button' | 'submit' | 'reset'`,
    default: `'button'`,
    example: 'type="submit"',
  },
  {
    prop: 'disabled',
    description: 'Disabled state of button',
    type: 'boolean | null',
    default: 'false',
    example: '[disabled]="isDisabled"',
  },
  {
    prop: 'classList',
    description: 'The list of classes for button',
    type: 'string',
    default: `''`,
    example: 'classList="btn--blue btn bg-white"',
  },
  {
    prop: 'icon',
    description: 'The name of icon for button (Note: check the Iconography tab for names)',
    type: 'string',
    default: `''`,
    example: 'icon="check"',
  },
  {
    prop: 'loading',
    description: 'Loading state of button',
    type: 'boolean | null',
    default: 'false',
    example: '[loading]="(isBtnLoading$ | async)"',
  },
  {
    prop: 'label',
    description: 'The label to show',
    type: 'string',
    default: `''`,
    example: 'label="Save"',
  },
  {
    prop: 'svgAttributes',
    description: 'The list of attributes for icon (f.e. to set some class)',
    type: 'Record<string, string>',
    default: '{}',
    example: `[setSVGAttributes]="{ class: 'w-5 h-5' }"`,
  },
  {
    prop: 'variant',
    description: 'The variant of button (see the Variants section)',
    type: `'success' | 'primary' | 'negative' | 'secondary' | 'custom'`,
    default: `'primary'`,
    example: 'variant="negative"',
  },
  {
    prop: 'spinnerSize',
    description: 'The size of spinner (tailwind value, X converts to class="w-X h-X" )',
    type: 'number',
    default: '5',
    example: '[spinnerSize]="4"',
  },
  {
    prop: 'disableLoading',
    description:
      'Says if loading state of button should be disabled. Useful for cases when it not necessary to wait for callback',
    type: 'boolean',
    default: 'false',
    example: '[disableLoading]="true"',
  },
  {
    prop: 'onClick',
    description:
      'The click event handler (Note: use arrow function or .bind(this) for callback to keep context)',
    type: 'VoidFunction',
    default: '() => {}',
    example: '[onClick]="onSave.bind(this)" (if onSave is not an arrow function)',
  },
];
