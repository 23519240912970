import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';

export type ActionParams = ICellRendererParams & {
  deleteClickFN: undefined | ((params: ActionParams) => void);
  editClickFN: undefined | ((params: { rowNode: RowNode }) => void);
};

@Component({
  selector: 'aux-actions',
  template: `
    <div class="flex h-full items-center justify-center">
      <button
        class="p-0 w-8 h-8 flex justify-center items-center"
        type="button"
        (click)="onEditClick()"
      >
        <span
          inlineSVG="edit.svg"
          class="text-aux-blue"
          [setSVGAttributes]="{ class: 'w-4 h-4' }"
        ></span>
      </button>

      <button
        class="p-0 w-8 h-8 flex justify-center items-center"
        type="button"
        (click)="onDeleteClick()"
      >
        <trash-solid-icon [size]="16" class="text-aux-error"></trash-solid-icon>
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsComponent implements ICellRendererAngularComp {
  params!: ActionParams;

  refresh(): boolean {
    return false;
  }

  agInit(params: ActionParams): void {
    this.params = params;
  }

  onEditClick() {
    if (this.params.editClickFN) {
      this.params.editClickFN({ rowNode: this.params.node });
    }
  }

  onDeleteClick() {
    if (this.params.deleteClickFN) {
      this.params.deleteClickFN(this.params);
    }
  }
}
