import { Route, Routes } from '@angular/router';
import { buildRoutesByFeatureFlags, FeatureFlag } from '@models/feature-flag.model';
import { Flags } from '@services/launch-darkly.service';
import { ClosingPageComponent } from './closing-page.component';
import { QuarterCloseComponent } from './tabs/quarter-close/quarter-close.component';
import { ReconciliationComponent } from './tabs/reconciliation/reconciliation.component';
import { ROUTING_PATH } from '../../app-routing-path.const';

export const CLOSING_PAGE_ROUTES_FF: Routes = [
  {
    path: ROUTING_PATH.CLOSING.RECONCILIATION,
    component: ReconciliationComponent,
    data: {
      feature: FeatureFlag.CLOSING_RECONCILIATION,
    },
  },
];

export const buildClosingPageRoutes = (featureFlags: Flags | null): Route => {
  const enabledRoutes: Routes = [
    {
      path: ROUTING_PATH.CLOSING.QUARTER_CLOSE,
      component: QuarterCloseComponent,
    },
    ...buildRoutesByFeatureFlags(featureFlags, CLOSING_PAGE_ROUTES_FF),
  ];

  return {
    path: ROUTING_PATH.CLOSING.INDEX,
    component: ClosingPageComponent,
    children: [...enabledRoutes, { path: '**', redirectTo: ROUTING_PATH.CLOSING.QUARTER_CLOSE }],
  };
};
