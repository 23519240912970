import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationService } from '@models/organization/organization.service';
import { TabGroupConfig } from '@components/tab-group/tab-group.component';
import { BudgetType, EventType, listTrialsQuery } from '@services/gql.service';
import { switchMap, tap } from 'rxjs/operators';

import { TrialsService } from '@models/trials/trials.service';
import { AuthQuery } from '@models/auth/auth.query';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '@services/event.service';
import { FormBuilder, Validators } from '@angular/forms';
import { TrialModel } from '@models/trials/trials.store';
import { OverlayService } from '@services/overlay.service';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { MainStore } from 'src/app/layouts/main-layout/state/main.store';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { ROUTING_PATH } from '../../app-routing-path.const';
import { SettingsService } from './settings.service';

@UntilDestroy()
@Component({
  selector: 'aux-settings',
  templateUrl: './settings.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnDestroy {
  tabs: TabGroupConfig[] = [
    { label: 'Users', route: ROUTING_PATH.SETTINGS.USERS },
    { label: 'User Permissions', route: ROUTING_PATH.SETTINGS.USER_PERMISSIONS },
    {
      label: 'Exchange Rates',
      route: ROUTING_PATH.SETTINGS.EXCHANGE_RATES,
      show: this.launchDarklyService.select$((flags) => flags.tab_exchange_rates),
    },
    {
      label: 'Integrations',
      route: ROUTING_PATH.SETTINGS.INTEGRATIONS,
      show: this.launchDarklyService.select$((flags) => flags.tab_integrations),
    },
  ];

  currentTrial: listTrialsQuery | undefined;

  editMode = new BehaviorSubject(false);

  isAdminUser = false;

  fg = this.formBuilder.group({
    auxilius_start_date: null,
    short_name: ['', Validators.required],
    therapy_area: ['', Validators.required],
  });

  constructor(
    private vendorsService: OrganizationService,
    private trialsService: TrialsService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private eventService: EventService,
    private formBuilder: FormBuilder,
    private authQuery: AuthQuery,
    private mainStore: MainStore,
    public service: SettingsService,
    private trialUserService: TrialUserService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.trialUserService.get().pipe(untilDestroyed(this)).subscribe();

    this.vendorsService
      .getListWithTotalBudgetAmount(BudgetType.BUDGET_PRIMARY)
      .pipe(untilDestroyed(this))
      .subscribe();

    this.mainStore.update({ fullPage: true });

    this.eventService
      .select$(EventType.TRIAL_CHANGED)
      .pipe(
        untilDestroyed(this),
        switchMap(({ trial_id }) => {
          return combineLatest([this.authQuery.isLoggedIn$, of({ trial_id })]);
        }),
        switchMap((arr) => {
          return combineLatest([of(arr), this.trialsService.get()]);
        })
      )
      .subscribe(([[, { trial_id }], { data }]) => {
        if (data?.length) {
          this.setCurrentTrial(data.find((trial) => trial.id === trial_id) || data[0]);
        }
      });

    this.authQuery.isLoggedIn$
      .pipe(
        untilDestroyed(this),
        switchMap((x: boolean) => {
          if (x) {
            return this.trialsService.get().pipe(
              tap(async ({ data }) => {
                if (data?.length) {
                  const { queryParams } = this.route.snapshot;
                  let trialKey = queryParams.trial;
                  if (
                    queryParams.trial &&
                    data.filter((d) => d.id === queryParams.trial).length !== 0
                  ) {
                    trialKey = queryParams.trial;
                  } else {
                    trialKey = this.authQuery.getValue().trial_id;
                  }
                  this.setCurrentTrial(data.find((trial) => trial.id === trialKey) || data[0]);
                }
              })
            );
          }
          return of([]);
        })
      )
      .subscribe();

    this.authQuery.adminUser$.pipe(untilDestroyed(this)).subscribe((event) => {
      this.isAdminUser = event;
    });
  }

  setCurrentTrial(currTrial: listTrialsQuery) {
    this.currentTrial = {
      ...currTrial,
      nct_id:
        currTrial.nct_id === null || currTrial.nct_id === undefined
          ? currTrial.name
          : currTrial.nct_id,
    };
    this.fg.patchValue({
      ...this.currentTrial,
    });
    this.fg.markAsUntouched();
    this.editMode.next(false);
  }

  async canDeactivate(): Promise<boolean> {
    if (this.fg.touched) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await result.afterClosed$.toPromise();
      return !!event.data;
    }
    return true;
  }

  editModeCancel() {
    this.fg.reset();
    this.fg.patchValue({
      ...this.currentTrial,
    });
    this.fg.markAsUntouched();
    this.editMode.next(false);
  }

  async onSaveEdits() {
    const { short_name, therapy_area, auxilius_start_date } = this.fg.value;
    if (this.fg.valid) {
      if (this.currentTrial) {
        const trial = {
          auxilius_start_date,
          short_name,
          therapy_area,
        } as Partial<TrialModel>;
        const success = await this.trialsService.update(this.currentTrial?.id, trial);
        if (success) {
          this.currentTrial = {
            ...this.currentTrial,
            auxilius_start_date,
            short_name,
            therapy_area,
          };
        }
        this.editMode.next(false);
        this.fg.markAsUntouched();
      }
    }
  }

  ngOnDestroy() {
    this.mainStore.update({ fullPage: false });
  }
}
