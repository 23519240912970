import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

@Component({
  template: ` <div [innerHTML]="params.template"></div> `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgHtmlHeaderComponent implements IHeaderAngularComp {
  params!: IHeaderParams;

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
