import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api-graphql';
import { Storage } from '@aws-amplify/storage';
import { LicenseManager } from 'ag-grid-enterprise';
import { akitaDevtools } from '@datorama/akita';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

akitaDevtools();

Auth.configure({ Auth: environment.cognito });
API.configure({ API: environment.appSync });
Storage.configure({ Storage: environment.s3 });

LicenseManager.setLicenseKey(
  'CompanyName=Auxilius,LicensedApplication=Auxilius,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-025524,ExpiryDate=20_April_2023_[v2]_MTY4MTk0NTIwMDAwMA==ae945ff1fbb73cf62385d40c66aeddb5'
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
