import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import {
  AnalyticsCardType,
  GqlService,
  PatientProtocolType,
  SitePatientTrackerFlat,
} from '@services/gql.service';
import { PaymentSchedulesStore } from '@models/payment-schedules/payment-schedules.store';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { expand, map, reduce, switchMap, tap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { groupBy } from 'lodash-es';
import { Utils } from '@services/utils';
import { EventService } from '@services/event.service';
import { combineLatest, EMPTY } from 'rxjs';
import {
  PatientTrackerContractSums,
  PatientTrackerModel,
  PatientTrackerStore,
  PatientTrackerSums,
} from './patient-tracker.store';

@Injectable({ providedIn: 'root' })
export class PatientTrackerService {
  constructor(
    private patientTrackerStore: PatientTrackerStore,
    private gqlService: GqlService,
    private paymentSchedulesStore: PaymentSchedulesStore,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private eventService: EventService
  ) {}

  fetchAll(
    patient_protocol_types1: PatientProtocolType[],
    patient_protocol_types2: PatientProtocolType[],
    patient_tracker_version_id: string | null
  ) {
    const fetchParams = {
      patient_protocol_types: patient_protocol_types1.slice(),
      tpsa_patient_protocol_types: patient_protocol_types2.slice(),
      patient_tracker_version_id,
      page: {
        offset: 0,
        limit: 500,
      },
    };

    return this.gqlService
      .fetchSitePatientTrackersFlat$({
        ...fetchParams,
      })
      .pipe(
        expand(({ success, data }) => {
          if (success && data) {
            if (Number.isInteger(data.next_offset) && data.next_offset >= 0) {
              fetchParams.page.offset = data.next_offset;
              return this.gqlService.fetchSitePatientTrackersFlat$({
                ...fetchParams,
              });
            }
          }
          return EMPTY;
        }),
        reduce(
          (acc, curr) => {
            if (acc.data && curr.success && curr.data) {
              acc.data = acc.data.concat(curr.data.items);
              return acc;
            }
            return {
              success: false,
              data: null,
              errors: curr.errors,
            };
          },
          {
            success: true,
            data: [] as Array<SitePatientTrackerFlat> | null,
            errors: [] as string[],
          }
        )
      );
  }

  private getExpensesAmount(
    expensesAmount: number,
    patientProtocolType: PatientProtocolType,
    patientProtocolTypes: PatientProtocolType[]
  ): number {
    return patientProtocolTypes.indexOf(patientProtocolType) >= 0 ? expensesAmount : 0;
  }

  private getSumAmounts = (
    sitePatients: SitePatientTrackerFlat[],
    expensesAmountKey: keyof Pick<
      SitePatientTrackerFlat,
      'sps_expense_amount' | 'sps_contract_expense_amount'
    >,
    prefix = ''
  ) => {
    const sum_expense_amount_key = `sum_expense_amount${prefix}`;
    const sum_other_amount_key = `sum_other_amount${prefix}`;
    const sum_visit_amount_key = `sum_visit_amount${prefix}`;
    const sum_overhead_amount_key = `sum_overhead_amount${prefix}`;

    return sitePatients
      .filter((sptf) => !!sptf[expensesAmountKey])
      .reduce(
        (accum, sptf) => {
          return {
            [sum_expense_amount_key]:
              accum[sum_expense_amount_key] + (sptf[expensesAmountKey] || 0),
            [sum_other_amount_key]:
              accum[sum_other_amount_key] +
              this.getExpensesAmount(
                sptf[expensesAmountKey] || 0,
                sptf.sps_pp_patient_protocol_type,
                [
                  PatientProtocolType.PATIENT_PROTOCOL_OTHER,
                  PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED,
                  PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL,
                ]
              ),
            [sum_visit_amount_key]:
              accum[sum_visit_amount_key] +
              this.getExpensesAmount(
                sptf[expensesAmountKey] || 0,
                sptf.sps_pp_patient_protocol_type,
                [PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT]
              ),
            [sum_overhead_amount_key]:
              accum[sum_overhead_amount_key] +
              this.getExpensesAmount(
                sptf[expensesAmountKey] || 0,
                sptf.sps_pp_patient_protocol_type,
                [PatientProtocolType.PATIENT_PROTOCOL_OVERHEAD]
              ),
          };
        },
        {
          [sum_expense_amount_key]: 0,
          [sum_other_amount_key]: 0,
          [sum_visit_amount_key]: 0,
          [sum_overhead_amount_key]: 0,
        }
      );
  };

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.patientTrackerStore.setLoading(true);
        return this.fetchAll([], [PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT], null);
      }),
      tap(this.applyPatientTrackerData)
    );
  }

  applyPatientTrackerData = ({
    success,
    data,
    errors,
  }: GraphqlResponse<SitePatientTrackerFlat[]>) => {
    this.patientTrackerStore.remove(() => true);
    this.paymentSchedulesStore.remove(() => true);

    if (success && data) {
      const groupedData = groupBy(data, 'sps_site_id');
      const max_create_date = data.reduce(
        (prev, current) => {
          return new Date(prev.create_date || '01-01-1970') >
            new Date(current.create_date || '01-01-1970')
            ? prev
            : current;
        },
        data && data[0] ? data[0] : { create_date: '01-01-1970' }
      );
      const source_refresh_date = Utils.dateFormatter(max_create_date.create_date || '01-01-1970', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });

      for (const [site_id, patientTrackerPerSites] of Object.entries(groupedData)) {
        const ids: string[] = [];

        const patientTrackersPerPatients = groupBy(patientTrackerPerSites, 'patient_id');

        for (const [patient_id, patientTrackers] of Object.entries(patientTrackersPerPatients)) {
          patientTrackers.forEach((patientTracker) => {
            const id = (patient_id === 'null' ? site_id : patient_id) + patientTracker.sps_id;
            ids.push(id);
            this.paymentSchedulesStore.add({
              id,
              patient_id,
              external_patient_id: patientTracker.external_patient_id,
              amount: patientTracker.sps_expense_amount,
              amount_contract: patientTracker.sps_contract_expense_amount,
              sps_expense_currency: patientTracker.sps_expense_currency,
              sps_contract_expense_currency: patientTracker.sps_contract_expense_currency,
              completion_date: patientTracker.completion_date,
              site_id,
              patient_protocol_id: patientTracker.sps_pp_id,
              patient_protocol_type: patientTracker.sps_pp_patient_protocol_type,
              total_payment_schedule_amount:
                patientTracker.sps_site_total_payment_schedule_amount || 0,
            });
          });
        }

        const patientTrackerPerSitesPerPatients = groupBy(
          patientTrackerPerSites.filter((x) => x.patient_id),
          'patient_id'
        );

        const sums_per_patient = Object.entries(patientTrackerPerSitesPerPatients).reduce(
          (acc, [patient_id, values]) => {
            acc[patient_id] = {
              ...(this.getSumAmounts(values, 'sps_expense_amount') as PatientTrackerSums),
              ...(this.getSumAmounts(
                values,
                'sps_contract_expense_amount',
                '_contract'
              ) as PatientTrackerContractSums),
            };
            return acc;
          },
          {} as Record<string, PatientTrackerSums & PatientTrackerContractSums>
        );

        this.patientTrackerStore.add({
          sums_per_patient,
          id: site_id,
          patient_id: null,
          site_payment_schedule_ids: ids,
          external_patient_id: null,
          source_refresh_date,
        });
      }
    } else {
      this.overlayService.error(errors);
    }

    this.patientTrackerStore.setLoading(false);
  };

  getByVersion(patient_tracker_version_id: string) {
    this.patientTrackerStore.setLoading(true);
    return this.fetchAll(
      [],
      [PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT],
      patient_tracker_version_id
    ).subscribe(this.applyPatientTrackerData);
  }

  add(patientTracker: PatientTrackerModel) {
    this.patientTrackerStore.add(patientTracker);
  }

  update(id: string, patientTracker: Partial<PatientTrackerModel>) {
    this.patientTrackerStore.update(id, patientTracker);
  }

  remove(id: ID) {
    this.patientTrackerStore.remove(id);
  }

  async getAnalyticsCards() {
    const proms = [
      this.eventService
        .selectAnalyticsCard$({
          analytics_card_type: AnalyticsCardType.PATIENT_TRACKER_AVG_ENROLLEES_TO_DATE,
        })
        .toPromise(),
      this.eventService
        .selectAnalyticsCard$({
          analytics_card_type: AnalyticsCardType.PATIENT_TRACKER_FORECASTED_AVG_COST_ENROLLEES,
        })
        .toPromise(),
      this.eventService
        .selectAnalyticsCard$({
          analytics_card_type: AnalyticsCardType.PATIENT_TRACKER_AVG_COST_THROUGH_EOT,
        })
        .toPromise(),
    ];

    const [ptaetd, ptface, ptacte] = await Promise.all(proms);
    const resp: {
      header: string;
      data: string;
      firstProp: { status?: string; label: string };
      secondProp: { status?: string; label: string };
    }[] = [];

    // Card 1
    let analyticsCard: {
      header: string;
      data: string;
      firstProp: { status?: string; label: string };
      secondProp: { status?: string; label: string };
    } = {
      header: 'Average Cost, Enrollees to Date',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'middle',
        label: 'Prior 1 month',
      },
      secondProp: {
        status: 'middle',
        label: 'Prior 3 months',
      },
    };
    if (ptaetd.success && ptaetd.data) {
      analyticsCard = this.parseAvgEnrolleesResponse(ptaetd.data.data);
    }
    resp.push(analyticsCard);

    // Card 2
    analyticsCard = {
      header: 'Forecasted Average Cost through EOT, Enrollees to Date',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'middle',
        label: 'Prior 3 months',
      },
      secondProp: {
        status: 'middle',
        label: 'vs. Current Budget',
      },
    };
    if (ptface.success && ptface.data) {
      const current_budgeted_average =
        ptacte && ptacte.data && ptacte.data.data
          ? this.getCurrentBudgetedAverage(ptacte.data.data)
          : 0;
      analyticsCard = this.parseForecastedAvgCostEnrolleesResponse(
        ptface.data.data,
        current_budgeted_average
      );
    }
    resp.push(analyticsCard);

    // Card 3
    analyticsCard = {
      header: 'Budgeted Average Cost through EOT',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'middle',
        label: 'Prior 1 months',
      },
      secondProp: {
        status: 'middle',
        label: 'Prior 3 months',
      },
    };
    if (ptacte.success && ptacte.data) {
      analyticsCard = this.parseAvgCostThroughEOTResponse(ptacte.data.data);
    }
    resp.push(analyticsCard);

    return resp;
  }

  getInvestigatorDetailsAnalyticsCards() {
    const defaultValues = [
      {
        header: 'In-Month Accrued to Date',
        sub: 'Current $<br />% of Forecast',
        percentval: Utils.zeroHyphen,
        data: Utils.zeroHyphen,
      },
      {
        header: 'Total Accrued To Date',
        data: Utils.zeroHyphen,
        sub: 'Current $<br />% of Forecast',
        config: {
          options: [''],
          data: [''],
          sub: 'Current $<br />% of Forecast',
          header: 'Total Accrued To Date',
        },
      },
      {
        header: 'Total Average Enrollee Cost by Site',
        data: Utils.zeroHyphen,
        sub: Utils.zeroHyphen,
        config: {
          options: [''],
          data: [''],
          header: 'Total Average Enrollee Cost by Site',
        },
      },
    ];

    return combineLatest([
      this.eventService
        .selectAnalyticsCard$({
          analytics_card_type: AnalyticsCardType.INVESTIGATOR_DETAILS_IN_MON_ACC_TO_DATE,
        })
        .pipe(
          map(({ success, data }) => {
            if (success && data) {
              return this.parseINVESTIGATOR_DETAILS_IN_MON_ACC_TO_DATE(data.data);
            }
            return defaultValues[0];
          })
        ),
      this.eventService
        .selectAnalyticsCard$({
          analytics_card_type: AnalyticsCardType.INVESTIGATOR_DETAILS_TOTAL_ACC_BY_DATE,
        })
        .pipe(
          map(({ success, data }) => {
            if (success && data) {
              return this.parseINVESTIGATOR_DETAILS_TOTAL_ACC_BY_DATE(data.data);
            }
            return defaultValues[1];
          })
        ),
      this.eventService
        .selectAnalyticsCard$({
          analytics_card_type: AnalyticsCardType.INVESTIGATOR_DETAILS_TOTAL_AVG_COST_BY_SITE,
        })
        .pipe(
          map(({ success, data }) => {
            if (success && data) {
              return this.parseINVESTIGATOR_DETAILS_TOTAL_AVG_COST_BY_SITE(data.data);
            }
            return defaultValues[2];
          })
        ),
    ]);
  }

  getCurrentBudgetedAverage(str: string) {
    const obj = JSON.parse(str) as { [key: string]: string }[];
    const date = new Date();
    const current_mo_yr = `${date
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${date.getFullYear()}`;
    const current_date_value = obj.filter((o) => o.month_year === current_mo_yr);
    let current_mo_total = 0;
    if (current_date_value && current_date_value.length > 0) {
      current_mo_total = parseFloat(current_date_value[0].average) || 0;
    }
    return current_mo_total;
  }

  parseAvgEnrolleesResponse(str: string) {
    // [{"monthly_average":null,"month_year":"JAN-2021","monthly_patients":0,"monthly_total":null},...]
    const obj = JSON.parse(str) as { [key: string]: string }[];

    const resp = {
      header: 'Average Cost, Enrollees to Date',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'middle',
        label: 'Prior 1 month',
      },
      secondProp: {
        status: 'middle',
        label: 'Prior 3 months',
      },
    };

    const date = new Date();
    const current_mo_yr = `${date
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${date.getFullYear()}`;
    const one_mo_back = new Date(date.setMonth(date.getMonth() - 1));
    const three_mo_back = new Date(date.setMonth(date.getMonth() - 2)); // subtract 2 since one is subtracted above
    const one_mo_back_str = `${one_mo_back
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${one_mo_back.getFullYear()}`;
    const three_mo_back_str = `${three_mo_back
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${three_mo_back.getFullYear()}`;

    const current_date_value = obj.filter((o) => o.month_year === current_mo_yr);
    let current_mo_total = 0;
    if (current_date_value && current_date_value.length > 0) {
      current_mo_total = parseFloat(current_date_value[0].monthly_average) || 0;
      resp.data = Utils.currencyFormatter(current_mo_total);
    }
    const one_mo_value = obj.filter((o) => o.month_year === one_mo_back_str);
    if (one_mo_value && one_mo_value.length > 0) {
      const this_val = parseFloat(one_mo_value[0].monthly_average) || 0;
      resp.firstProp.status = this_val > current_mo_total ? 'low' : 'high';
    }

    const three_mo_value = obj.filter((o) => o.month_year === three_mo_back_str);
    if (three_mo_value && three_mo_value.length > 0) {
      const this_val = parseFloat(three_mo_value[0].monthly_average) || 0;
      resp.secondProp.status = this_val > current_mo_total ? 'low' : 'high';
    }

    return resp;
  }

  parseForecastedAvgCostEnrolleesResponse(str: string, current_budgeted_average: number) {
    // "[{"month_year":"JUN-2021","forecasted_avg_cost":1043.0700000000002},...]"
    const obj = JSON.parse(str) as { [key: string]: string }[];

    const resp = {
      header: 'Forecasted Average Cost through EOT, Enrollees to Date',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'middle',
        label: 'Prior 3 months',
      },
      secondProp: {
        status: 'middle',
        label: 'vs. Current Budget',
      },
    };

    const date = new Date();
    const current_mo_yr = `${date
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${date.getFullYear()}`;
    const three_mo_back = new Date(date.setMonth(date.getMonth() - 3)); // subtract 2 since one is subtracted above
    const three_mo_back_str = `${three_mo_back
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${three_mo_back.getFullYear()}`;

    const current_date_value = obj.filter((o) => o.month_year === current_mo_yr);
    let current_mo_total = 0;
    if (current_date_value && current_date_value.length > 0) {
      current_mo_total = parseFloat(current_date_value[0].forecasted_avg_cost) || 0;
      resp.data = Utils.currencyFormatter(current_mo_total);
    }

    const three_mo_value = obj.filter((o) => o.month_year === three_mo_back_str);
    if (three_mo_value && three_mo_value.length > 0) {
      const this_val = parseFloat(three_mo_value[0].forecasted_avg_cost) || 0;
      resp.firstProp.status = this_val > current_mo_total ? 'low' : 'high';
    }

    resp.secondProp.status = current_budgeted_average > current_mo_total ? 'low' : 'high';
    return resp;
  }

  parseAvgCostThroughEOTResponse(str: string) {
    // "[{"average":"5215.35","enrollee_count":0,"month_year":"JUL-2021","payment_total":"5215.35"},...]"
    const obj = JSON.parse(str) as { [key: string]: string }[];
    const resp = {
      header: 'Budgeted Average Cost through EOT',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'middle',
        label: 'Prior 1 months',
      },
      secondProp: {
        status: 'middle',
        label: 'Prior 3 months',
      },
    };

    const date = new Date();
    const current_mo_yr = `${date
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${date.getFullYear()}`;
    const one_mo_back = new Date(date.setMonth(date.getMonth() - 1));
    const three_mo_back = new Date(date.setMonth(date.getMonth() - 2)); // subtract 2 since one is subtracted above
    const one_mo_back_str = `${one_mo_back
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${one_mo_back.getFullYear()}`;
    const three_mo_back_str = `${three_mo_back
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${three_mo_back.getFullYear()}`;

    const current_date_value = obj.filter((o) => o.month_year === current_mo_yr);
    let current_mo_total = 0;
    if (current_date_value && current_date_value.length > 0) {
      current_mo_total = parseFloat(current_date_value[0].average) || 0;
      resp.data = Utils.currencyFormatter(current_mo_total);
    }
    const one_mo_value = obj.filter((o) => o.month_year === one_mo_back_str);
    if (one_mo_value && one_mo_value.length > 0) {
      const this_val = parseFloat(one_mo_value[0].average) || 0;
      resp.firstProp.status = this_val > current_mo_total ? 'low' : 'high';
    } else {
      resp.firstProp.status = 'middle';
    }

    const three_mo_value = obj.filter((o) => o.month_year === three_mo_back_str);
    if (three_mo_value && three_mo_value.length > 0) {
      const this_val = parseFloat(three_mo_value[0].average) || 0;
      resp.secondProp.status = this_val > current_mo_total ? 'low' : 'high';
    } else {
      resp.secondProp.status = 'middle';
    }

    return resp;
  }

  parseINVESTIGATOR_DETAILS_IN_MON_ACC_TO_DATE(data: string) {
    const json = JSON.parse(data) as {
      current_cash: string;
      percentage_of_forecast: string;
    };

    return {
      header: 'In-Month Accrued to Date',
      sub: 'Current $<br />% of Forecast',
      percentval: `${
        json.percentage_of_forecast
          ? `${Utils.percentageFormatter(parseFloat(json.percentage_of_forecast))}`
          : Utils.zeroHyphen
      }`,
      data: `${
        json.current_cash
          ? `${Utils.currencyFormatter(parseFloat(json.current_cash))}`
          : Utils.zeroHyphen
      }`,
    };
  }

  parseINVESTIGATOR_DETAILS_TOTAL_ACC_BY_DATE(data: string) {
    try {
      const json = JSON.parse(data) as {
        current_cash: string;
        cost_type: string;
        percentage_of_forecast: string;
      }[];

      return {
        header: 'Total Accrued To Date',
        data: `${json[0].current_cash}%`,
        sub: 'Current $<br />% of Forecast',
        config: {
          options: json.map((f) => f.cost_type),
          data: json.map(
            (f) =>
              `${Utils.currencyFormatter(parseFloat(f.current_cash))}<br />${
                f.percentage_of_forecast
                  ? Utils.percentageFormatter(parseFloat(f.percentage_of_forecast))
                  : Utils.zeroHyphen
              }`
          ),
          sub: 'Current $<br />% of Forecast',
          selected: 'Patient Visit',
          header: 'Total Accrued To Date',
        },
      };
    } catch (e) {
      return {
        header: '',
        data: ``,
        sub: '',
        config: {
          options: ['Patient Visit', 'Overhead', 'Discontinued'],
          data: [Utils.zeroHyphen, Utils.zeroHyphen, Utils.zeroHyphen],
          sub: 'Current $<br />% of Forecast',
          header: 'Total Accrued To Date',
        },
      };
    }
  }

  parseINVESTIGATOR_DETAILS_TOTAL_AVG_COST_BY_SITE(data: string) {
    const json = JSON.parse(data) as {
      site: string;
      average_patient_cost: string;
    }[];

    return {
      header: 'Total Average Enrollee Cost by Site',
      data: Utils.zeroHyphen,
      sub: Utils.zeroHyphen,
      config: {
        options: json.map((f) => `Site: ${f.site}`),
        data: json.map((f) => Utils.currencyFormatter(parseFloat(f.average_patient_cost))),
        header: 'Total Average Enrollee Cost by Site',
      },
    };
  }
}
