import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { BehaviorSubject } from 'rxjs';
import { OverlayService } from '@services/overlay.service';
import { FileMetadata } from '@services/api.service';
import {
  AmountType,
  BudgetInfo,
  BudgetType,
  DocumentType,
  EntityType,
  ExpenseType,
  GqlService,
  NoteType,
} from '@services/gql.service';
import { Utils } from '@services/utils';

import { AuthQuery } from '@models/auth/auth.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { FullActivity } from '../../../forecast-accruals-page/tabs/forecast/category/category.query';
import { ActivityStore } from '../../../forecast-accruals-page/tabs/forecast/activity/activity.store';
import { WorkflowQuery } from './close-quarter-check-list/store';
import { WORKFLOW_NAMES } from './close-quarter-check-list';
import { MessagesConstants } from '../../../../constants/messages.constants';

@UntilDestroy()
@Component({
  selector: 'aux-in-month-manual-override',
  templateUrl: './quarter-close-manual-override.component.html',
  styles: [
    `
      :host {
        display: block;
      }
      ::ng-deep .scrollbar-visible::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      ::ng-deep .scrollbar-visible::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuarterCloseManualOverrideComponent {
  @ViewChild('fileManager') fileManager: FileManagerComponent | undefined;

  loading$ = new BehaviorSubject(false);

  error$ = new BehaviorSubject('');

  zeroHyphen = Utils.zeroHyphen;

  override_type: 'units' | 'costs' = 'costs';

  modified_units = 0;

  modified_units_display = 0;

  units = 0;

  modified_costs = 0;

  modified_costs_display = 0;

  budget_override_notes: { message: string; created_by: string; create_date: Date }[] = [];

  noteAddedByUser = '';

  metadata: FileMetadata = { documentType: DocumentType.DOCUMENT_ACCRUAL_SUMMARY };

  sub_activity!: FullActivity;

  budget_info!: BudgetInfo;

  BudgetType = BudgetType;

  isUnitsDisable = true;

  previewMode: boolean;

  isWorkflowLocked$ = this.workflowQuery.getLockStatusByWorkflowName(WORKFLOW_NAMES.ADJUSTMENT);

  saveButtonTooltipText = '';

  pathFn: () => string = () => '';

  constructor(
    public ref: CustomOverlayRef<
      any,
      {
        sub_activity: FullActivity;
        budget_info: BudgetInfo;
        preview_mode: boolean;
      }
    >,
    private overlayService: OverlayService,
    private vendorQuery: OrganizationQuery,
    private gqlService: GqlService,
    private authQuery: AuthQuery,
    private activityStore: ActivityStore,
    private mainQuery: MainQuery,
    private workflowQuery: WorkflowQuery
  ) {
    this.sub_activity = this.ref.data?.sub_activity as FullActivity;
    this.budget_info = this.ref.data?.budget_info as BudgetInfo;

    this.previewMode = this.ref.data?.preview_mode || false;

    this.modified_costs =
      this.sub_activity[BudgetType.BUDGET_PRIMARY]?.total_override ||
      (this.sub_activity[BudgetType.BUDGET_PRIMARY]?.total_accrual as number);
    this.modified_costs_display = this.roundTwoDecimals(this.modified_costs);

    this.modified_units = this.modified_costs / this.sub_activity.unit_cost;
    this.modified_units_display = this.roundTwoDecimals(this.modified_units);

    if (Number.isNaN(this.modified_units) || !Number.isFinite(this.modified_units)) {
      this.isUnitsDisable = true;
      this.override_type = 'costs';
      this.modified_units = 0;
    } else {
      this.isUnitsDisable = false;
      this.units =
        Math.floor(
          ((this.sub_activity[BudgetType.BUDGET_PRIMARY]?.total_forecast as number) /
            this.sub_activity.unit_cost) *
            100
        ) / 100;
    }

    if (this.sub_activity.budget_override_note) {
      this.budget_override_notes = JSON.parse(
        Utils.unscrubUserInput(this.sub_activity.budget_override_note)
      );
    }

    this.setSaveButtonTooltipText();
  }

  getVendorName(): any {
    return this.vendorQuery.getActive()?.name;
  }

  getVendorId(): any {
    return this.vendorQuery.getActive()?.id;
  }

  getFilePaths() {
    const trialId = this.mainQuery.getValue().trialKey;
    return () =>
      `trials/${trialId}/month-close-manual-adjustment/${this.budget_info.current_month}/`;
  }

  async onSave() {
    const { data, success, errors } = await this.gqlService
      .createBudgetExpense$({
        budget_version_id: this.budget_info.budget_version_id,
        activity_id: this.sub_activity.id,
        expense_type_id: ExpenseType.EXPENSE_ACCRUAL_OVERRIDE,
        expense_detail: JSON.stringify({}),
        period_start: this.budget_info.current_month,
        period_end: this.budget_info.current_month,
        source: 'BASE',
        amount_type: `AMOUNT_${this.sub_activity.activity_type?.substr(9)}` as AmountType,
        amount_curr: 'CURRENCY_USD',
        amount: +this.modified_costs,
      })
      .toPromise();

    if (data && success && this.noteAddedByUser.length !== 0) {
      const path = this.getFilePaths();
      if (this.fileManager) {
        const files = this.fileManager.fileQuery.getAll();
        for (const file of files) {
          this.fileManager.fileStore.update(file.id, { ...file, key: `${path()}${file.key}` });
        }
        this.fileManager.fileService.uploadFiles(
          {
            vendor: this.getVendorId(),
            documentType: DocumentType.DOCUMENT_ACCRUAL_SUMMARY,
            entity_id: data.id,
            entity_type_id: EntityType.BUDGET_EXPENSE,
          },
          false,
          true
        );
      }
      const { errors: cErrors, success: cSuccess, data: cData } = await this.gqlService
        .createNote$({
          entity_ids: [data.id],
          entity_type: EntityType.BUDGET_EXPENSE,
          note_type: NoteType.NOTE_TYPE_GENERAL,
          message: Utils.scrubUserInput(this.noteAddedByUser),
        })
        .toPromise();
      if (cSuccess && cData) {
        this.overlayService.success();
        this.ref.close(true);
      } else {
        this.overlayService.error(cErrors);
      }
    } else {
      this.overlayService.error(errors);
    }
    this.ref.close();
  }

  onUnitChanged() {
    this.modified_costs = this.modified_units * this.sub_activity.unit_cost;
    this.modified_costs_display = this.roundTwoDecimals(this.modified_costs);
  }

  onCostChanged() {
    if (!this.isUnitsDisable) {
      this.modified_costs = this.modified_costs_display;
      this.modified_units = this.modified_costs / this.sub_activity.unit_cost;
      this.modified_units_display = this.roundTwoDecimals(this.modified_units);
    }
  }

  roundTwoDecimals(numToRound: number) {
    return Math.round(numToRound * 100) / 100;
  }

  private setSaveButtonTooltipText(): void {
    this.isWorkflowLocked$.pipe(untilDestroyed(this)).subscribe((isLocked) => {
      if (isLocked) {
        this.saveButtonTooltipText = MessagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE;
        return;
      }

      this.saveButtonTooltipText = this.previewMode
        ? MessagesConstants.CHANGES_UNABLE_SINCE_PERIOD_CLOSED
        : '';
    });
  }
}
