import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  template: `
    <div class="flex items-center justify-start text-aux-black">
      <div *ngIf="params?.data?.logo">
        <img
          [alt]="params?.data?.name"
          [src]="params?.data?.logo"
          style="max-height: 30px;max-width: 100px;margin-right: 25px"
        />
      </div>
      <div *ngIf="params?.data?.name">{{ params?.data?.name }}</div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgIntegrationsVendorComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  refresh(): boolean {
    return false;
  }

  async agInit(params: ICellRendererParams) {
    this.params = params;
  }
}
