<div class="text-xs block mb-1" *ngIf="label">{{ label }}</div>

<div
  class="text-sm flex justify-between items-center px-3 h-12 w-full border border-aux-gray-dark font-medium rounded-md shadow-sm"
  [ngClass]="{
    'ring-2 ring-aux-blue-light-200 border-aux-blue': isListOpen
  }"
  (click)="openList(trigger)"
>
  <ng-container *ngIf="selected; else placeholderTemplate">
    <span class="">{{ selected }}</span>
  </ng-container>

  <ng-template #placeholderTemplate>
    <span class="text-gray-500">{{ placeholder || label }}</span>
  </ng-template>

  <div>
    <chevron-down-solid-icon class="text-aux-gray-darkest"></chevron-down-solid-icon>
  </div>
</div>

<div class="absolute">
  <div cdkOverlayOrigin #trigger="cdkOverlayOrigin" class="mt-2"></div>
</div>

<ng-template
  #overlay="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isListOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'ttt'"
  [cdkConnectedOverlayMinWidth]="trigger.elementRef.nativeElement.offsetWidth"
  (detach)="closeList()"
  (backdropClick)="closeList()"
>
  <div
    class="bg-white shadow-lg overflow-hidden rounded text-aux-black text-sm w-full border border-aux-gray-dark"
  >
    <ul
      tabindex="-1"
      role="listbox"
      class="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
      (keydown)="onListKeydown($event)"
    >
      <ng-container *ngFor="let option of options; index as index">
        <li
          id="listbox-option-{{ index }}"
          role="option"
          class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-16"
          [ngClass]="{
            'bg-aux-gray-light': index === highlighted
          }"
          (mouseenter)="highlightOption(index)"
          (mouseleave)="highlighted = null"
          (click)="selectOption()"
        >
          <span
            class="font-normal block truncate"
            [ngClass]="{
              'font-medium': index === selectedIndex
            }"
          >
            {{ option }}
          </span>

          <div
            *ngIf="index === selectedIndex"
            class="absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <svg
              class="w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
