import { NgModule } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { CdkTreeModule } from '@angular/cdk/tree';
import { InlineSVGModule } from 'ng-inline-svg';
import { OverlayModule } from '@angular/cdk/overlay';
import { AgActionsComponent } from '@components/ag-actions/ag-actions.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgHeroiconsModule } from '@dimaslz/ng-heroicons';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChartsModule } from 'ng2-charts';
import { OverlayService } from '@services/overlay.service';
import { AgHeaderDropdownComponent } from './ag-header-dropdown.component';
import { ButtonComponent } from './button/button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { OverlayComponent } from './overlay/overlay.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { SidebarNavComponent } from './sidebar-nav/sidebar-nav.component';
import { AuxCardSelectComponent } from './aux-card-select/aux-card-select.component';
import { InputComponent } from './form-inputs/input/input.component';
import { DirectivesModule } from '../directives/directives.module';
import { SelectComponent } from './form-inputs/select/select.component';
import { ToggleComponent } from './form-inputs/toggle/toggle.component';
import { FileViewerComponent } from './file-manager/file-viewer.component';
import { RadioButtonModule } from './radio-button/radio-button.module';
import { FileManagerDialogComponent } from './file-manager-dialog/file-manager-dialog.component';
import { MenuComponent } from './menu/menu.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { AgDatePickerComponent } from './datepicker/ag-date-picker/ag-date-picker.component';
import { AgPulseMinusComponent } from './ag-actions/ag-pulse-minus.component';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { CompareComponent } from './compare/compare.component';
import { AgCheckboxComponent } from './ag-actions/ag-checkbox.component';
import { AuxPatientDropdownComponent } from './aux-patient-dropdown/aux-patient-dropdown.component';
import { GuardWarningComponent } from './guard-warning/guard-warning.component';
import { ExportExcelButtonComponent } from './export-excel-button/export-excel-button.component';
import { AgHtmlHeaderComponent } from './ag-html-header/ag-html-header.component';
import { CanvasChartComponent } from './canvas-chart/canvas-chart.component';
import { SaveChangesComponent } from './save-changes/save-changes.component';
import { ToggleCurrencyComponent } from './toggle-currency/toggle-currency.component';
import { AgIntegrationsVendorComponent } from '../pages/settings/integrations/state/ag-integrations-vendor.component';
import { ProcessingLoaderComponent } from './processing-loader/processing-loader.component';
import { AgCellWrapperComponent } from './ag-cell-wrapper/ag-cell-wrapper.component';
import { RemoveDialogComponent } from './remove-dialog/remove-dialog.component';
import { AgIntegrationsConnectComponent } from '../pages/settings/integrations/state/ag-integrations-connect.component';

@NgModule({
  imports: [
    CommonModule,
    PortalModule,
    OverlayModule,
    CdkTreeModule,
    NgHeroiconsModule,
    InlineSVGModule,
    RouterModule,
    DirectivesModule,
    ReactiveFormsModule,
    FormsModule,
    RadioButtonModule,
    AgGridModule,
    ChartsModule,
  ],
  declarations: [
    ButtonComponent,
    CheckboxComponent,
    OverlayComponent,
    FileManagerComponent,
    SidebarNavComponent,
    AuxCardSelectComponent,
    InputComponent,
    SelectComponent,
    ToggleComponent,
    FileViewerComponent,
    FileManagerDialogComponent,
    MenuComponent,
    DatepickerComponent,
    AgDatePickerComponent,
    AgActionsComponent,
    AgPulseMinusComponent,
    TabGroupComponent,
    TooltipDirective,
    TooltipComponent,
    CompareComponent,
    AgCheckboxComponent,
    AuxPatientDropdownComponent,
    GuardWarningComponent,
    ExportExcelButtonComponent,
    AgHtmlHeaderComponent,
    AgHeaderDropdownComponent,
    CanvasChartComponent,
    SaveChangesComponent,
    ToggleCurrencyComponent,
    AgIntegrationsVendorComponent,
    AgIntegrationsConnectComponent,
    ProcessingLoaderComponent,
    AgCellWrapperComponent,
    RemoveDialogComponent,
  ],
  exports: [
    ButtonComponent,
    CheckboxComponent,
    FileManagerComponent,
    SidebarNavComponent,
    AuxCardSelectComponent,
    InputComponent,
    SelectComponent,
    ToggleComponent,
    FileViewerComponent,
    RadioButtonModule,
    MenuComponent,
    DatepickerComponent,
    AgActionsComponent,
    TabGroupComponent,
    TooltipDirective,
    TooltipComponent,
    CompareComponent,
    AgCheckboxComponent,
    AuxPatientDropdownComponent,
    ExportExcelButtonComponent,
    AgHtmlHeaderComponent,
    AgHeaderDropdownComponent,
    CanvasChartComponent,
    SaveChangesComponent,
    ToggleCurrencyComponent,
    AgIntegrationsVendorComponent,
    ProcessingLoaderComponent,
  ],
  providers: [OverlayService],
})
export class ComponentsModule {}
