<ng-container *ngIf="{ matrixReadOnly: matrixReadOnly$ | async } as obj">
  <ng-container *ngVar="matrixUsers$ | async as users">
    <div class="pl-4 my-12" *ngIf="users.length">
      <div class="text-xl font-semibold">User Permissions</div>

      <div
        class="grid mt-8 overflow-auto"
        [ngStyle]="{
          'grid-template-columns': 'repeat(' + (users.length + 1) + ',1fr)'
        }"
      >
        <div></div>
        <div
          *ngFor="let user of matrixUsers$ | async; first as first; last as last"
          class="p-4 text-xs text-center border-t border-b"
          [ngClass]="{
            'border-l rounded-tl': first,
            'border-r rounded-tr': last
          }"
        >
          <div class="font-bold whitespace-nowrap">
            {{ user.given_name }} {{ user.family_name }}
          </div>
          <div class="whitespace-nowrap">{{ user.title }}, {{ user.organization?.name }}</div>
        </div>

        <ng-container
          *ngFor="let permission of permissions$ | async; first as pFirst; last as pLast"
        >
          <div
            class="p-4 space-y-2 text-sm border-b border-l border-r"
            [ngClass]="{
              'border-t': pFirst,
              'rounded-tl': pFirst,
              'rounded-bl': pLast
            }"
          >
            <div class="font-bold whitespace-nowrap">{{ permission.header }}</div>
            <div *ngFor="let child of permission.children" class="pl-2 whitespace-nowrap">
              {{ child.permission }}
            </div>
          </div>

          <div
            class="flex flex-col items-center justify-center p-4 space-y-2 text-sm border-b border-r"
            *ngFor="let user of matrixUsers$ | async; first as first; last as last"
            [ngClass]="{
              'rounded-br': pLast && last
            }"
          >
            <div>
              <aux-checkbox
                class="text-sm"
                [checked]="isGroupChecked(permission.children, user)"
                [indeterminate]="isGroupIndeterminate(permission.children, user)"
                (customChange)="onCheckboxGroupChange($event, permission.children, user)"
              ></aux-checkbox>
            </div>
            <div *ngFor="let child of permission.children" class="h-5">
              <ng-container *ngIf="user.permissions[$any(child.id)] as perm">
                <aux-checkbox
                  class="text-sm"
                  (customChange)="onCheckboxChange($event, child, user)"
                  [checked]="perm === 'E'"
                  [disabled]="
                    obj.matrixReadOnly
                      ? true
                      : child.permission_user_profile === 'PROFILE_ADMIN'
                      ? user.role === 'ROLE_USER'
                      : false
                  "
                ></aux-checkbox>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
