<div class="flex items-start filters" [formGroup]="form">
  <div class="max-w-sm w-full">
    <div class="h-5 mb-1"></div>
    <aux-input
      placeholder="Search"
      formControlName="search"
      icon="search.svg"
      class="w-full"
    ></aux-input>
  </div>
  <div class="pl-4">
    <span class="text-xs mb-1">Document Type:</span>
    <ng-select
      class="w-60 tabular-nums"
      [multiple]="true"
      [clearable]="true"
      [searchable]="false"
      placeholder="All"
      formControlName="documentTypes"
      (change)="onFilterChange()"
      [items]="documentLibrary.documentTypeOptions"
      bindValue="value"
    >
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngIf="items.length == 1">
          <div class="flex" *ngFor="let item of items | slice:0:1">
            <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
            <span class="ng-value-label overflow-hidden overflow-ellipsis">{{$any(item).label}}</span>
          </div>
        </div>
        <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">{{items.length}} Selected</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="pl-4">
    <span class="text-xs mb-1">Vendor:</span>
    <ng-select
      class="w-60 tabular-nums"
      [multiple]="true"
      [clearable]="true"
      [searchable]="false"
      placeholder="All"
      formControlName="vendors"
      (change)="onFilterChange()"
      [items]="this.documentLibrary.vendors"
      bindValue="value"
    >
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngIf="items.length == 1">
          <div class="flex" *ngFor="let item of items | slice:0:1">
            <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
            <span class="ng-value-label overflow-hidden overflow-ellipsis">{{$any(item).label}}</span>
          </div>
        </div>
        <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">{{items.length}} Selected</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="pl-4">
    <span class="text-xs mb-1">Site:</span>
    <ng-select
      class="w-60 tabular-nums"
      [multiple]="true"
      [clearable]="true"
      [searchable]="false"
      placeholder="All"
      formControlName="sites"
      (change)="onFilterChange()"
      [items]="this.documentLibrary.sites"
      bindValue="value"
    >
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngIf="items.length == 1">
          <div class="flex" *ngFor="let item of items | slice:0:1">
            <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
            <span class="ng-value-label overflow-hidden overflow-ellipsis">{{$any(item).label}}</span>
          </div>
        </div>
        <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">{{items.length}} Selected</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="pl-4">
    <span class="text-xs mb-1">From:</span>
    <aux-input
      [type]="'date'"
      placeholder="YYYY-MM-DD"
      formControlName="dateFrom"
      (change)="onFilterChange()"
      class="w-36"
    ></aux-input>
  </div>
  <div class="pl-4">
    <span class="text-xs mb-1">To:</span>
    <aux-input
      [type]="'date'"
      placeholder="YYYY-MM-DD"
      formControlName="dateTo"
      (change)="onFilterChange()"
      class="w-36"
    ></aux-input>
  </div>
  <button
    class="pt-8 pl-4 focus:outline-none aux-link underline whitespace-nowrap"
    (click)="resetAllFilters()"
  >
    Clear All
  </button>
</div>
