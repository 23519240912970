import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'aux-button',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnChanges {
  isHandlingClick$ = new BehaviorSubject(false);

  spinnerClassNames = 'spinner w-5 h-5';

  defaultClasses = {
    success: 'btn--success',
    primary: 'btn--primary',
    negative: 'btn--negative',
    secondary: 'btn--secondary',
    custom: '',
  };

  classNames = '';

  @Input() type?: 'button' | 'submit' | 'reset' = 'button';

  @Input() disabled: boolean | null = false;

  @Input() classList? = '';

  @Input() icon = '';

  @Input() loading: boolean | null = false;

  @Input() label = '';

  @Input() svgAttributes: Record<string, string> = {};

  @Input() variant: 'success' | 'primary' | 'negative' | 'secondary' | 'custom' = 'primary';

  @Input() spinnerSize = 5;

  @Input() disableLoading = false;

  @Input() onClick: VoidFunction = () => {};

  ngOnChanges() {
    this.classNames = `relative ${this.defaultClasses[this.variant]} ${this.classList}`;
    this.spinnerClassNames = `spinner w-${this.spinnerSize} h-${this.spinnerSize}`;
  }

  async handleClick() {
    if (this.disableLoading) {
      this.onClick();

      return;
    }

    this.isHandlingClick$.next(true);

    try {
      await this.onClick();
    } finally {
      this.isHandlingClick$.next(false);
    }
  }
}
