import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl } from '@angular/forms';
import { map, switchMap } from 'rxjs/operators';
import { AuthQuery } from '@models/auth/auth.query';
import { TrialsQuery } from '@models/trials/trials.query';
import { AuthService } from '@models/auth/auth.service';
import { OverlayService } from '@services/overlay.service';
import { EventType } from '@services/gql.service';
import { EventService } from '@services/event.service';
import { AppInitService } from '@services/app-init.service';
import { IdleService } from '@services/idle.service';

import { LaunchDarklyService } from '@services/launch-darkly.service';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { UserTasksService } from '@models/user-tasks';
import { MainQuery } from './state/main.query';
import { MainStore } from './state/main.store';
import { NewTrialDialogComponent } from './new-trial-dialog/new-trial-dialog.component';
import { MainService } from './state/main.service';
import { ROUTING_PATH } from '../../app-routing-path.const';

@UntilDestroy()
@Component({
  selector: 'aux-main-layout',
  templateUrl: './main-layout.component.html',
  styles: [],
  styleUrls: ['./main-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent implements OnDestroy {
  accountLink = `/${ROUTING_PATH.ACCOUNT.INDEX}`;

  trialFc = new FormControl('');

  isTrialTasksVisible$ = new BehaviorSubject(this.isTrialTasksVisible(this.route.url));

  trials$ = this.trialsQuery.selectAll().pipe(
    map((value) => {
      return [
        ...value,
        { id: 'NEW_TRIAL', short_name: '+ Add New Trial', onboarding_complete: true },
      ];
    })
  );

  error = false;

  showProfileSection$: Observable<boolean>;

  showHomeLink$: Observable<boolean>;

  showTaskSection$ = this.launchDarklyService.select$((flags) => flags.section_task_list);

  showRoadmapLink$ = this.launchDarklyService.select$((flags) => flags.section_footer_roadmap);

  constructor(
    public authQuery: AuthQuery,
    public trialsQuery: TrialsQuery,
    public mainQuery: MainQuery,
    public mainStore: MainStore,
    public authService: AuthService,
    public appInitService: AppInitService,
    private cdr: ChangeDetectorRef,
    private overlayService: OverlayService,
    private eventService: EventService,
    private userTasksService: UserTasksService,
    // don't remove the mainService from constructor
    private mainService: MainService,
    private launchDarklyService: LaunchDarklyService,
    private idleService: IdleService,
    private route: Router
  ) {
    this.idleService.disableEvents.next(false);
    this.mainQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.error = false;

        if (value.trialKey) {
          if (this.trialFc.value !== value.trialKey) {
            this.trialFc.setValue(value.trialKey);
          }
        } else {
          this.error = true;
        }
      });

    this.eventService
      .select$(EventType.TRIAL_CHANGED)
      .pipe(untilDestroyed(this))
      .subscribe(({ trial_id }) => {
        if (this.mainQuery.getValue().trialKey !== trial_id) {
          this.trialFc.setValue(trial_id, { emitEvent: false });
          this.mainStore.update({ trialKey: trial_id });
        }
      });

    this.eventService
      .select$(EventType.ENVIRONMENT_BUILT)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.logout();
      });

    this.trialFc.valueChanges.pipe(untilDestroyed(this)).subscribe(async (value) => {
      if (this.mainQuery.getValue().trialKey !== value) {
        if (value === 'NEW_TRIAL') {
          const ref = this.overlayService.open<string>({ content: NewTrialDialogComponent });

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ref.afterClosed$.subscribe((newTrialKey) => {
            // if (value1.data) {
            //   this.mainStore.update({ trialKey: value1.data });
            // } else {
            //   this.trialFc.setValue(this.mainStore.getValue().trialKey);
            // }
            if (newTrialKey.data) {
              this.trialFc.setValue(newTrialKey.data);
            } else {
              this.trialFc.setValue(this.mainStore.getValue().trialKey);
            }
          });
        } else {
          await this.mainService.setTrial(value);
          this.mainStore.update({ trialKey: value });
        }
      }
    });

    this.showProfileSection$ = launchDarklyService.select$((flags) => flags.section_profile);
    this.showHomeLink$ = launchDarklyService.select$((flags) => flags.nav_portfolio);

    this.route.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isTrialTasksVisible$.next(this.isTrialTasksVisible(event.url));
      }
    });

    this.showTaskSection$
      .pipe(
        switchMap((show) => {
          if (show) {
            return this.userTasksService.getUserTaskList$();
          }
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.idleService.disableEvents.next(true);
  }

  isTrialTasksVisible(url: string) {
    return ['/home'].indexOf(url) === -1;
  }

  async logout() {
    localStorage.removeItem(`customView`);
    await this.authService.signOut();
    window.location.reload();
  }

  routingHome() {
    if (this.launchDarklyService.flags$.getValue().nav_portfolio) {
      this.route.navigate([`/${ROUTING_PATH.HOME}`]);
    }
  }
}
