import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { AuthQuery } from '@models/auth/auth.query';
import { TrialsQuery } from '@models/trials/trials.query';
import { combineLatest, EMPTY, forkJoin, from, Observable, of } from 'rxjs';
import {
  AnalyticsCardType,
  Approval,
  BudgetType,
  BudgetViewInput,
  CreateUserCustomViewInput,
  ExpenseType,
  GqlService,
  listBudgetGridQuery,
  PeriodType,
  RemoveVendorEstimateBudgetVersionInput,
  UpdateUserCustomViewInput,
  ViewLocation,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { groupBy, round } from 'lodash-es';
import { ApiService } from '@services/api.service';
import { Utils } from '@services/utils';
import { EventService } from '@services/event.service';
import { OrganizationModel } from '@models/organization/organization.store';
import { BudgetStore } from './budget.store';
import { BudgetQuery } from './budget.query';
import { ApprovalStore } from './approval.store';
import { ApprovalQuery } from './approval.query';
import { ApprovalState } from './approval.model';
import { BudgetState, createInitialState } from './budget.model';
import { BudgetGridService } from './budget-grid.service';

@Injectable({ providedIn: 'root' })
export class BudgetService {
  initialLoading = true;

  constructor(
    private budgetStore: BudgetStore,
    private approvalStore: ApprovalStore,
    private mainQuery: MainQuery,
    private vendorsQuery: OrganizationQuery,
    private authQuery: AuthQuery,
    private trialsQuery: TrialsQuery,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private budgetQuery: BudgetQuery,
    private approvalQuery: ApprovalQuery,
    private apiService: ApiService,
    private eventService: EventService,
    private budgetGridService: BudgetGridService
  ) {}

  getBudgetData(period_type: PeriodType, fetchAll = false, in_month = false) {
    const budget_version_id = null;

    return combineLatest([
      fetchAll ? of(null) : this.vendorsQuery.selectActive((x) => x.id),
      this.budgetQuery.select('budget_type'),
      this.mainQuery.select('trialKey'),
    ]).pipe(
      switchMap(([vendor_id, budget_type]) => {
        this.budgetStore.setLoading(true);
        return this.gqlService.listBudgetGrid$({
          period_type,
          budget_version_id,
          vendor_id: vendor_id || null,
          budget_type,
          in_month,
        });
      }),
      this.budgetCacheMechanism(),
      map(({ data, success, errors }) => {
        if (this.initialLoading) {
          this.budgetStore.update(
            createInitialState(
              data?.budget_info && data.budget_info[0].budget_type
                ? data.budget_info[0].budget_type
                : BudgetType.BUDGET_PRIMARY
            )
          );
          this.initialLoading = false;
        }
        let response = false;

        if (data && success) {
          const { header_data, budget_data: raw_data, budget_info: bi } = data;
          const vendors = this.vendorsQuery.getAllVendors();

          this.budgetStore.update({
            header_data: header_data || [],
            budget_info: this.budgetGridService.getBudgetInfo(bi, vendors) || [],
            budget_data: this.budgetGridService.getActualBudgetData(raw_data, vendors, period_type),
          });

          response = true;
        }

        if (!response) {
          this.overlayService.error(errors);
        }

        this.budgetStore.setLoading(false);

        return response;
      })
    );
  }

  getBudgetListRequestInputs(
    vendors: OrganizationModel[],
    period_type: PeriodType,
    budget_type: BudgetType,
    in_month: boolean,
    vendor?: OrganizationModel | null
  ): BudgetViewInput[] {
    const commonInputs = {
      period_type,
      budget_type,
      in_month,
    };

    const requestsInputList: BudgetViewInput[] = [];

    if (vendor) {
      requestsInputList.push(
        {
          ...commonInputs,
          budget_version_id: null,
          vendor_id: vendor.id || null,
        },
        {
          ...commonInputs,
          budget_version_id: vendor?.baseline_budget_version?.budget_version_id || null,
          vendor_id: vendor.id || null,
        }
      );
    } else if (vendors?.length) {
      requestsInputList.push({
        ...commonInputs,
        budget_version_id: null,
        vendor_id: null,
      });

      vendors
        .filter((v) => v.baseline_budget_version)
        .forEach((v) => {
          requestsInputList.push({
            ...commonInputs,
            budget_version_id: v?.baseline_budget_version?.budget_version_id || null,
            vendor_id: v.id || null,
          });
        });
    }

    return requestsInputList;
  }

  getBudgetDataForEBG(period_type: PeriodType, fetchAll = false, in_month = false) {
    return combineLatest([
      fetchAll ? of(null) : this.vendorsQuery.selectActive(),
      this.budgetQuery.select('budget_type'),
      this.vendorsQuery.selectLoading(),
      this.mainQuery.select('trialKey'),
    ]).pipe(
      switchMap(([vendor, budget_type, isVendorsLoading]) => {
        if (isVendorsLoading) {
          return EMPTY;
        }

        return combineLatest([of(vendor), of(this.vendorsQuery.getAllVendors()), of(budget_type)]);
      }),
      switchMap(([vendor, vendors, budget_type]) => {
        this.budgetStore.setLoading(true);

        const requestsInputList = this.getBudgetListRequestInputs(
          vendors,
          period_type,
          budget_type,
          in_month,
          vendor
        );

        if (!requestsInputList.length) {
          return of([]);
        }

        return forkJoin(requestsInputList.map((input) => this.gqlService.listBudgetGrid$(input)));
      }),
      this.budgetsCacheMechanism(),
      map((gridDataList: listBudgetGridQuery[]) => {
        const [actualGridData, ...baseListGridData] = gridDataList;
        const isAllSuccess = gridDataList.length ? gridDataList.every((data) => !!data) : false;

        let response = false;
        this.budgetStore.update(
          createInitialState(
            actualGridData?.budget_info && actualGridData.budget_info[0].budget_type
              ? actualGridData.budget_info[0].budget_type
              : BudgetType.BUDGET_PRIMARY
          )
        );

        if (isAllSuccess && actualGridData) {
          const { header_data, budget_data: raw_data, budget_info: bi } = actualGridData;
          const vendors = this.vendorsQuery.getAllVendors();

          const budget_data = this.budgetGridService.getBudgetGridWithBaseLineForVendors(
            raw_data,
            vendors,
            baseListGridData.map((data) => data?.budget_data || []),
            period_type
          );

          this.budgetStore.update({
            header_data: header_data || [],
            budget_info: this.budgetGridService.getBudgetInfo(bi, vendors) || [],
            budget_data,
            current_data: budget_data,
          });

          response = true;
        }

        this.budgetStore.setLoading(false);

        return response;
      })
    );
  }

  getMonthCloseApprovals() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.approvalStore.setLoading(true);
        this.approvalStore.remove(() => true);
        return this.gqlService.listMonthCloseApprovals$();
      }),
      map((listMonthCloseApprovals) => {
        const listOfApprovals: Approval[] = [];
        listMonthCloseApprovals.data?.forEach((a) => {
          const approval = {
            category: a.category,
            vendor_id: a.vendor_id,
            aux_user_id: a.aux_user_id,
            permission: a.permission,
          } as Approval;

          listOfApprovals.push(approval);
        });
        const approvalState: ApprovalState = {
          approvals: listOfApprovals,
        };
        this.approvalStore.update(approvalState);
        this.approvalStore.setLoading(false);
        return true;
      })
    );
  }

  getInMonthBudgetData(quarter_start_month: string | null): Observable<Partial<BudgetState>> {
    return combineLatest([this.mainQuery.select('trialKey')]).pipe(
      switchMap(() => {
        this.budgetStore.setLoading(true);
        return combineLatest([
          this.gqlService.listBudgetGrid$({
            period_type: PeriodType.PERIOD_MONTH,
            budget_version_id: null,
            vendor_id: null,
            budget_type: BudgetType.BUDGET_PRIMARY,
            in_month: true,
            cache_enabled: true,
            quarter_start_month,
          }),
        ]);
      }),
      this.budgetsCacheMechanism(),
      map(([primary]: listBudgetGridQuery[]) => {
        this.budgetStore.setLoading(false);

        const { header_data, budget_data: primary_data, budget_info } = primary;
        const date_headers = header_data?.filter(
          (data) => data.expense_type === ExpenseType.EXPENSE_FORECAST
        )[0].date_headers;

        const budget_data = (primary_data || []).map((bd) => {
          const groupedExpenses = groupBy(bd.expenses, 'expense_type');

          const forecast: Record<string, number> = {};
          // In case of no forecast, populate all dates with 0 since it caused issues with total rows
          date_headers?.forEach((x) => {
            forecast[x] = round(0, 2);
          });

          (groupedExpenses[ExpenseType.EXPENSE_FORECAST] || []).forEach((x) => {
            forecast[x.period || ''] = round(x.amount || 0, 2);
          });

          // Override the forecast numbers if at close exists
          (groupedExpenses[ExpenseType.EXPENSE_FORECAST_AT_CLOSE] || []).forEach((x) => {
            forecast[x.period || ''] = round(x.amount || 0, 2);
          });

          const accrual: Record<string, number> = {};
          (groupedExpenses[ExpenseType.EXPENSE_ACCRUAL] || []).forEach((x) => {
            accrual[x.period || ''] = round(x.amount || 0, 2);
          });

          const accrual_adjusted: Record<string, number> = {};
          (groupedExpenses[ExpenseType.EXPENSE_ACCRUAL_ADJUSTED] || []).forEach((x) => {
            accrual_adjusted[x.period || ''] = round(x.amount || 0, 2);
          });

          const adjustment: Record<string, number> = {};
          for (const period of Object.keys(forecast)) {
            adjustment[period] = Object.prototype.hasOwnProperty.call(accrual_adjusted, period)
              ? (accrual_adjusted[period] || 0) -
                (Object.prototype.hasOwnProperty.call(accrual, period)
                  ? accrual[period]
                  : forecast[period] || 0)
              : 0;
          }

          const wp_ltd = round(
            (groupedExpenses[ExpenseType.EXPENSE_WP] || []).filter((r) => r.period === 'TO_DATE')[0]
              ?.amount || 0,
            2
          );

          return {
            ...bd,
            remaining_cost: 0,
            remaining_percentage: 0,
            wp_percentage: 0,
            wp_cost: 0,
            wp_unit_num: 0,
            remaining_unit_num: 0,
            accrual: 0,
            forecast: 0,
            total_monthly_accrual: 0,
            adjustment: 0,
            wp_ltd,
            forecast_obj: forecast,
            accrual_obj: accrual,
            accrual_adjusted_obj: accrual_adjusted,
            adjustment_obj: adjustment,
          };
        });

        const state = {
          header_data: header_data || [],
          budget_info: budget_info || [],
          budget_data,
        } as Partial<BudgetState>;
        this.budgetStore.update(state);

        return state;
      })
    );
  }

  getInMonthBudgetDataForReconciliation(
    dontUseStore = false,
    budget_version_id: string | null = null
  ): Observable<Partial<BudgetState>> {
    return combineLatest([this.mainQuery.select('trialKey')]).pipe(
      switchMap(() => {
        this.budgetStore.setLoading(true);
        return combineLatest([
          this.gqlService.listBudgetGrid$({
            period_type: PeriodType.PERIOD_MONTH,
            budget_version_id,
            vendor_id: null,
            budget_type: BudgetType.BUDGET_PRIMARY,
            in_month: true,
            cache_enabled: false,
          }),
          this.gqlService.listBudgetGrid$({
            period_type: PeriodType.PERIOD_MONTH,
            budget_version_id: null,
            vendor_id: null,
            budget_type: BudgetType.BUDGET_VENDOR_ESTIMATE,
            in_month: true,
            cache_enabled: false,
          }),
        ]);
      }),
      map(([primary, vendorEstimate]) => {
        this.budgetStore.setLoading(false);

        if (primary.data && primary.success) {
          const { header_data, budget_data: primary_data, budget_info } = primary.data;
          const vendor_estimate_data = vendorEstimate?.data?.budget_data || [];

          const vendorEstimateExpenses = (vendor_estimate_data || []).reduce((acc, bd) => {
            const groupedExpenses = groupBy(bd.expenses, 'expense_type');
            let forecast = 0;
            (groupedExpenses[ExpenseType.EXPENSE_FORECAST] || []).forEach((x) => {
              forecast = x.amount || 0;
            });

            if (!acc[bd.vendor_id || '']) {
              acc[bd.vendor_id || ''] = {};
            }

            acc[bd.vendor_id || ''][bd.cost_category || ''] = { forecast };
            return acc;
          }, {} as Record<string, Record<string, { forecast: number }>>);

          const budget_data = (primary_data || []).map((bd) => {
            const groupedExpenses = groupBy(bd.expenses, 'expense_type');

            let forecast = 0;
            if (
              bd.vendor_id &&
              bd.cost_category &&
              bd.cost_category !== 'Investigator' &&
              vendorEstimateExpenses[bd.vendor_id] &&
              vendorEstimateExpenses[bd.vendor_id][bd.cost_category]
            ) {
              forecast = vendorEstimateExpenses[bd.vendor_id][bd.cost_category].forecast;
            } else {
              (groupedExpenses[ExpenseType.EXPENSE_FORECAST] || []).forEach((x) => {
                forecast = x.amount || 0;
              });
            }

            let total_monthly_accrual = 0;
            (groupedExpenses[ExpenseType.EXPENSE_ACCRUAL_ADJUSTED] || []).forEach((x) => {
              total_monthly_accrual = x.amount || 0;
            });

            const adjustment = total_monthly_accrual - forecast;

            return {
              ...bd,
              remaining_cost: 0,
              remaining_percentage: 0,
              wp_percentage: 0,
              wp_cost: 0,
              wp_unit_num: 0,
              remaining_unit_num: 0,
              accrual: 0,
              forecast,
              total_monthly_accrual,
              adjustment,
            };
          });

          if (dontUseStore) {
            return {
              header_data: header_data || [],
              budget_info: budget_info || [],
              budget_data,
            };
          }
          this.budgetStore.update({
            header_data: header_data || [],
            budget_info: budget_info || [],
            budget_data,
          } as Partial<BudgetState>);
        }

        this.overlayService.error(primary.errors);
        return {
          header_data: [],
          budget_info: [],
          budget_data: [],
        } as Partial<BudgetState>;
      })
    );
  }

  getBudgetWorkPerformed() {
    return this.eventService
      .selectAnalyticsCard$({
        analytics_card_type: AnalyticsCardType.BUDGET_WORK_PERFORMED,
      })
      .pipe(
        switchMap(({ data, success, errors }) => {
          if (success && data) {
            this.budgetStore.update({
              work_performed: this.parseWPResponse(data.data),
            });
          }
          return of({ success: false, errors, data: null });
        })
      );
  }

  getInvoicesTotal() {
    return this.eventService
      .selectAnalyticsCard$({
        analytics_card_type: AnalyticsCardType.BUDGET_INVOICES,
      })
      .pipe(
        switchMap(({ data, success, errors }) => {
          if (success && data) {
            this.budgetStore.update({
              invoices: this.parseInvoicesResponse(data.data),
            });
          }
          return of({ success: false, errors, data: null });
        })
      );
  }

  getPendingChanges() {
    return this.eventService
      .selectAnalyticsCard$({
        analytics_card_type: AnalyticsCardType.BUDGET_PENDING_CHANGES,
      })
      .pipe(
        switchMap(({ data, success, errors }) => {
          if (success && data) {
            this.budgetStore.update({
              pendingChanges: this.parsePendingChangesResponse(data.data),
            });
          }
          return of({ success: false, errors, data: null });
        })
      );
  }

  parseWPResponse(str: string) {
    const obj = JSON.parse(str) as { [key: string]: { [key: string]: string } };
    const resp: { [key: string]: { [key: string]: string } } = {};

    Object.keys(obj).forEach((key: string) => {
      if (!resp[key]) {
        resp[key] = {
          wp_total: '',
          wp_percentage: '',
        };
      }
      resp[key].wp_total = Utils.currencyFormatter(parseFloat(obj[key].wp_total));
      resp[key].wp_percentage = Utils.percentageFormatter(parseFloat(obj[key].wp_percentage));
    });

    return resp;
  }

  parseInvoicesResponse(str: string) {
    const obj = JSON.parse(str) as { [key: string]: { [key: string]: string } };

    const resp: { [key: string]: { [key: string]: string } } = {};

    Object.keys(obj).forEach((key: string) => {
      if (!resp[key]) {
        resp[key] = {
          invoice_total: '',
          direct_cost_total: '',
          invoice_percentage: '',
        };
      }
      resp[key].invoice_total = Utils.currencyFormatter(parseFloat(obj[key].invoice_total));
      resp[key].direct_cost_total = Utils.currencyFormatter(parseFloat(obj[key].direct_cost_total));
      resp[key].invoice_percentage = Utils.percentageFormatter(
        parseFloat(obj[key].invoice_percentage)
      );
    });

    return resp;
  }

  parsePendingChangesResponse(str: unknown) {
    const obj = str as { [key: string]: string };

    const resp: { [key: string]: string } = {};

    Object.keys(obj).forEach((key: string) => {
      resp[key] = Utils.currencyFormatter(parseFloat(obj[key]));
    });

    return resp;
  }

  budgetsCacheMechanism() {
    return switchMap(
      (
        gridDataList: {
          success: boolean;
          data: listBudgetGridQuery | null;
          errors: string[];
        }[]
      ) => {
        if (!gridDataList.length) {
          return of([]);
        }
        return forkJoin(
          gridDataList
            .filter(({ data }) => data?.cache_info)
            .map(({ data }) =>
              from(this.apiService.getFileAsJson<any>(data?.cache_info?.cache_file || ''))
            )
        );
      }
    );
  }

  budgetCacheMechanism() {
    return switchMap(
      ({
        data,
        success,
        errors,
      }: {
        success: boolean;
        data: listBudgetGridQuery | null;
        errors: string[];
      }) => {
        if (data && success && data.cache_info?.cache_file) {
          return from(
            this.apiService.getFileAsJson<listBudgetGridQuery>(data.cache_info?.cache_file)
          ).pipe(
            map((x) => {
              if (x) {
                return { data: x, success: true, errors: [] };
              }

              return { data, success, errors };
            })
          );
        }
        return of({ data, success, errors });
      }
    );
  }

  async removeVendorEstimate(input: RemoveVendorEstimateBudgetVersionInput) {
    const { success, errors } = await this.gqlService
      .removeVendorEstimateBudgetVersion$({
        id: input.id,
        vendor_id: input.vendor_id,
        target_month: input.target_month,
      })
      .toPromise();

    if (errors.length) {
      this.overlayService.error(errors);
    }

    return success;
  }

  async setBudgetVersionAsBaseline(id: string, organization_id: string) {
    const { success, errors } = await this.gqlService
      .setBudgetVersionAsBaseline$(id, organization_id)
      .toPromise();
    if (errors.length) {
      this.overlayService.error(errors);
    }

    return success;
  }

  async removeBudgetVersion(id: string) {
    const { success, errors } = await this.gqlService.removeBudgetVersion$(id).toPromise();
    if (errors.length) {
      this.overlayService.error(errors);
    }
    return success;
  }

  async saveUserCustomView(data: CreateUserCustomViewInput) {
    const { success, errors } = await this.gqlService.createUserCustomView$(data).toPromise();
    if (errors.length) {
      this.overlayService.error(errors);
    }
    return success;
  }

  async listUserCustomView() {
    const { success, data, errors } = await this.gqlService
      .listUserCustomViews$(ViewLocation.VIEW_LOCATION_BUDGET_GRID)
      .toPromise();

    if (success && data) {
      return data;
    }
    this.overlayService.error(errors);
    return null;
  }

  async updateUserCustomView(item: UpdateUserCustomViewInput) {
    const { success, errors } = await this.gqlService.updateUserCustomView$(item).toPromise();
    if (errors.length) {
      this.overlayService.error(errors);
      return false;
    }
    return success;
  }

  async removeUserCustomView(id: string) {
    if (id) {
      const { success, errors } = await this.gqlService.removeUserCustomView$(id).toPromise();

      if (errors.length) {
        this.overlayService.error(errors);
        return false;
      }

      return success;
    }
    this.overlayService.error(`This view doesn't have id!`);
    return false;
  }
}
