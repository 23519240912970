import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { listUserTasksQuery } from '@services/gql.service';

export interface UserTasksState extends EntityState<listUserTasksQuery> {}

export type UserTasksModel = getEntityType<listUserTasksQuery>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-tasks', idKey: 'task_id' })
export class UserTasksStore extends EntityStore<UserTasksState> {
  constructor() {
    super({});
  }
}
