import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AuthStore, AuthState } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  isLoggedIn$ = this.select((user) => !!user.email);

  fullName$ = this.select((user) => `${user.given_name} ${user.family_name}`);

  name$ = this.select((user) => `${user.given_name}`);

  isUser$ = this.select((user) => !user.is_admin);

  adminUser$ = this.select((user) => user.email.includes('@auxili.us'));

  getFullName() {
    const user = this.getValue();
    return `${user.given_name} ${user.family_name}`;
  }

  getEmail() {
    const user = this.getValue();
    return user.email;
  }

  constructor(protected store: AuthStore) {
    super(store);
  }

  isAuxAdmin() {
    return this.getValue().email.includes('@auxili.us');
  }
}
