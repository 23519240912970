import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { InlineSVGModule } from 'ng-inline-svg';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeagoFormatter, TimeagoModule } from 'ngx-timeago';
import { PipeModule } from '@pipes/pipe.module';
import { AgGridModule } from 'ag-grid-angular';
import { ChartsModule } from 'ng2-charts';
import { ComponentsModule } from '@components/components.module';
import { PortfolioTaskListComponent, PortfolioTasksComponent } from './portfolio-tasks';
import { PortfolioCompleteComponent } from './portfolio-complete/portfolio-complete.component';
import { PortfolioStatusComponent } from './portfolio-status/portfolio-status.component';
import { PortfolioDashboardComponent } from './portfolio-dashboard.component';
import { DaysAgoFormatter } from './days-ago-pipe.formatter';

@NgModule({
  declarations: [
    PortfolioTaskListComponent,
    PortfolioTasksComponent,
    PortfolioCompleteComponent,
    PortfolioStatusComponent,
    PortfolioDashboardComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgSelectModule,
    PipeModule,
    AgGridModule,
    ComponentsModule,
    ChartsModule,
    InlineSVGModule,
    TimeagoModule.forRoot({
      formatter: {
        provide: TimeagoFormatter,
        useClass: DaysAgoFormatter,
      },
    }),
  ],
  exports: [PortfolioTaskListComponent],
})
export class PortfolioDashboardModule {}
