import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { PaymentStatus } from '@services/gql.service';
import { Utils } from '@services/utils';

@Component({
  template: `
    <div class="flex items-center whitespace-nowrap">
      <div class="w-3 h-3 rounded-full mr-1" [ngClass]="bgClass"></div>
      <div [ngClass]="textClass" class="mr-1">{{ status }}</div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentStatusComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  status!: string;

  bgClass!: string;

  textClass!: string;

  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    // console.log(params);
    const status: PaymentStatus | string = params.data.payment_status || '';

    switch (status) {
      case PaymentStatus.PAYMENT_STATUS_PAID_IN_FULL:
        this.bgClass = 'bg-aux-green-dark';
        this.textClass = 'text-aux-green-dark';
        this.status = 'Paid';
        break;
      case PaymentStatus.PAYMENT_STATUS_DECLINED:
      case PaymentStatus.PAYMENT_STATUS_SCHEDULED:
      case PaymentStatus.PAYMENT_STATUS_PARTIAL_PAYMENT:
      case PaymentStatus.PAYMENT_STATUS_UNPAID:
      default:
        this.textClass = '';
        this.status = Utils.zeroHyphen;
        break;
    }
  }
}
