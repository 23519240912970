import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { Document } from '@services/gql.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Utils } from '@services/utils';
import { DocumentLibraryService } from '../document-library.service';

export interface EditMultipleDocumentsModalData {
  selectedRows: Document[];
  formGroup: FormGroup;
}

@Component({
  selector: 'aux-edit-multiple-documents-modal',
  templateUrl: './edit-multiple-documents-modal.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMultipleDocumentsModalComponent {
  selectedRows: Document[] = [];

  formGroup!: any;

  selectedType: string | null = null;

  selectedVendor: string | null = null;

  selectedSite: string | null = null;

  documentTypeOptions = Utils.DOCUMENT_OPTIONS.sort(({ label }, { label: label12 }) =>
    Utils.alphaNumSort(label, label12)
  );

  constructor(
    public ref: CustomOverlayRef<any, EditMultipleDocumentsModalData>,
    public documentLibrary: DocumentLibraryService
  ) {
    if (this.ref.data) {
      this.selectedRows = this.ref.data.selectedRows;
      this.formGroup = this.ref.data.formGroup;
    }
  }

  onApply = () => {
    this.selectedRows.forEach((document) => {
      const rowControl = this.formGroup.controls.table.controls.find(
        (control: FormControl) => control.value.id === document.id
      ) as FormGroup;

      rowControl.controls.document_type_id.setValue(this.selectedType);
      rowControl.controls.vendor_id.setValue(this.selectedVendor);
      rowControl.controls.site_id.setValue(this.selectedSite);
    });

    this.ref.close({ updateGrid: true });
  };
}
