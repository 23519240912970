<div class="overflow-auto" style="max-height: 80vh">
  <div class="w-screen max-w-5xl"></div>
  <ng-container *ngIf="(loading$ | async) === true">
    <div class="border-8 h-32 m-auto my-16 spinner w-32"></div>
  </ng-container>

  <form [formGroup]="fg" (ngSubmit)="onSubmit()" *ngIf="(loading$ | async) === false">
    <div class="mb-2 text-xl font-semibold">
      {{ mode === 'add' ? 'Add New Vendor' : 'Vendor' }}
    </div>

    <div class="grid grid-cols-5 gap-5 mb-3">
      <aux-input [formControl]="vendorFc" label="Entity Name" validators="required"></aux-input>
    </div>

    <aux-checkbox class="text-xs" class="mb-3 text-sm" formControlName="is_third_party">
      3rd party vendor?
    </aux-checkbox>

    <div *ngIf="getFc('is_third_party')?.value">
      <div class="aux-select">
        <label for="vendor">Select Parent Vendor</label>
        <ng-select
          id="vendor"
          formControlName="parent_organization_id"
          bindValue="id"
          bindLabel="name"
          [clearable]="false"
          [searchable]="false"
          [appendTo]="'body'"
          class="mb-3"
        >
          <ng-option [value]="vendor" *ngFor="let vendor of organizationQuery.selectAll() | async">
            <span [title]="vendor.name">{{ vendor.name }}</span>
          </ng-option>
        </ng-select>
      </div>
      <aux-checkbox
        class="text-xs"
        class="mb-3 text-sm"
        formControlName="costs_included_in_parent_wo"
      >
        Costs included in parent WO?
      </aux-checkbox>
    </div>

    <div class="mb-2 text-xl font-semibold">Primary Contact</div>
    <div class="grid grid-cols-5 mb-3 gap-5">
      <div>
        <aux-input
          id="given_name"
          formControlName="given_name"
          label="First Name"
          auxFormError
          (change)="refreshVendorValidators()"
        ></aux-input>
      </div>
      <div>
        <aux-input
          id="family_name"
          formControlName="family_name"
          label="Last Name"
          auxFormError
          (change)="refreshVendorValidators()"
        ></aux-input>
      </div>
      <div>
        <aux-input
          id="email"
          formControlName="email"
          label="Email"
          auxFormError
          (change)="refreshVendorValidators()"
        ></aux-input>
      </div>
      <div>
        <aux-input
          id="title"
          formControlName="title"
          label="Title"
          auxFormError
          (change)="refreshVendorValidators()"
        ></aux-input>
      </div>
      <div>
        <aux-input
          id="phone_number"
          formControlName="phone_number"
          label="Phone Number"
          auxFormError
          (change)="refreshVendorValidators()"
        ></aux-input>
      </div>
    </div>

    <aux-checkbox
      class="text-xs"
      class="mb-3 text-sm"
      formControlName="costs_included_in_parent_wo"
    >
      Costs included in parent WO?
    </aux-checkbox>

    <div class="flex mt-10 space-x-4">
      <button class="w-48 text-sm btn btn--blue" type="submit">
        {{ mode === 'add' ? 'Save Vendor' : 'Save Edits' }}
      </button>
      <button
        class="text-sm font-normal aux-link focus:outline-none"
        (click)="onCancel()"
        type="button"
        *ngIf="mode === 'add'"
      >
        Cancel
      </button>
    </div>
  </form>

  <ng-container>
    <hr class="mb-4 mt-8" />
    <div class="grid grid-cols-4 gap-5 mt-4">
      <div>
        <div class="font-medium text-xl">Contracts</div>
        <div class="text-sm h-10 mb-2">Upload Work Orders, MSA, RFP responses</div>
        <aux-file-manager
          #fileManager
          [eager]="false"
          [pathFn]="getFilePaths('contracts')"
          (onFilesAdded)="onFilesAdded('contracts')"
          [fetchFilesOnInit]="true"
          [metadataFn]="getMetadata()"
          [document_entity_id]="vendorId"
          [document_entity_type_id]="ORGANIZATION"
          [document_type_id]="DOCUMENT_VENDOR_CONTRACT"
          class="h-24"
        ></aux-file-manager>
        <aux-file-viewer
          [fileManager]="fileManager"
          [onlyShowUploaded]="false"
          [disableFirstFileMargin]="true"
        ></aux-file-viewer>
      </div>
      <div>
        <div class="font-medium text-xl">Proposal</div>
        <div class="text-sm h-10 mb-2"></div>
        <aux-file-manager
          #fileManager2
          [eager]="false"
          [pathFn]="getFilePaths('proposals')"
          (onFilesAdded)="onFilesAdded('proposals')"
          [fetchFilesOnInit]="true"
          [metadataFn]="getMetadata()"
          [document_entity_id]="vendorId"
          [document_entity_type_id]="ORGANIZATION"
          [document_type_id]="DOCUMENT_PROPOSAL"
          class="h-24"
        ></aux-file-manager>
        <aux-file-viewer
          [fileManager]="fileManager2"
          [onlyShowUploaded]="false"
          [disableFirstFileMargin]="true"
        ></aux-file-viewer>
      </div>
      <div>
        <div class="font-medium text-xl">Other</div>
        <div class="text-sm h-10 mb-2"></div>
        <aux-file-manager
          #fileManager3
          [eager]="false"
          [pathFn]="getFilePaths('other')"
          (onFilesAdded)="onFilesAdded('other')"
          [fetchFilesOnInit]="true"
          [metadataFn]="getMetadata()"
          [document_entity_id]="vendorId"
          [document_entity_type_id]="ORGANIZATION"
          [document_type_id]="DOCUMENT_OTHER"
          class="h-24"
        ></aux-file-manager>
        <aux-file-viewer
          [fileManager]="fileManager3"
          [onlyShowUploaded]="false"
          [disableFirstFileMargin]="true"
        ></aux-file-viewer>
      </div>
    </div>
  </ng-container>
</div>
