import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { Utils } from '@services/utils';
import { BehaviorSubject } from 'rxjs';
import {
  ColGroupDef,
  Column,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from 'ag-grid-community';
import { PatientProtocolQuery } from '@models/patient-protocol/patient-protocol.query';
import { PaymentSchedulesQuery } from '@models/payment-schedules/payment-schedules.query';
import { SitesQuery } from '@models/sites/sites.query';
import { groupBy, merge } from 'lodash-es';
import { RowClassParams } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { Currency } from '@services/gql.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { FormControl } from '@angular/forms';
import {
  AMOUNT_PATTERN,
  PATIENT_PROTOCOL_PATIENT_VISIT,
  PatientBudgetTableDataService,
  PatientProtocolComparator,
  CURRENCY_PATTERN,
} from './patient-budget-table-data.service';
import { COST_COLUMN_PROPS, getVisitInformationColumn } from './patient-budget-cols.const';
import { TableConstants } from '../../../../constants/table.constants';

export enum PatientTableType {
  VISITS_COSTS = 'visits_costs',
  OTHER_COSTS = 'other_costs',
}

@Component({
  selector: 'aux-sites-table',
  templateUrl: './patient-budget-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class PatientBudgetTableComponent implements OnInit {
  @Input() showGrid$!: BehaviorSubject<boolean>;

  @Input() tableType!: PatientTableType;

  @Input() patientGroupId!: string;

  @Output() gridApiChanged = new EventEmitter<{
    gridApi: GridApi;
    columnApi: ColumnApi;
  }>();

  selectedCurrencies$ = new BehaviorSubject({
    isPrimaryCurrency: true,
    isContractCurrency: true,
  });

  gridAPI!: GridApi;

  gridColumnApi!: ColumnApi;

  gridOptions$: BehaviorSubject<GridOptions> = new BehaviorSubject({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      cellRenderer: AgCellWrapperComponent,
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    suppressMenuHide: true,
    getRowStyle: (params: RowClassParams): any => {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold', color: 'var(--text-aux-black)' };
      }
      return {};
    },
  } as GridOptions);

  gridData$ = new BehaviorSubject<any[]>([]);

  selectedSites = new FormControl('');

  selectedSiteValues: string[] = [];

  constructor(
    private patientProtocolQuery: PatientProtocolQuery,
    private paymentSchedulesQuery: PaymentSchedulesQuery,
    public sitesQuery: SitesQuery,
    private patientTableDataService: PatientBudgetTableDataService
  ) {}

  ngOnInit(): void {
    const mapGridValues = new Map<PatientTableType, PatientProtocolComparator>([
      [PatientTableType.VISITS_COSTS, this.visitsPatientComparator],
      [PatientTableType.OTHER_COSTS, this.otherCostsPatientComparator],
    ]);

    const comparator = mapGridValues.get(this.tableType) as PatientProtocolComparator;

    const data = this.patientTableDataService.getCostsGridData(comparator, this.patientGroupId);

    const isThereAnyDateValue = !!(
      data.filter((x) => x.target_date_days_out || x.target_tolerance_days_out).length || 0
    );

    const allColumns = [...getVisitInformationColumn(isThereAnyDateValue), ...this.getCostsCols()];

    this.gridData$.next(data);
    this.gridOptions$.next({
      ...this.gridOptions$.getValue(),
      columnDefs: allColumns,
      excelStyles: [...Utils.auxExcelStyle],
    });
  }

  otherCostsPatientComparator: PatientProtocolComparator = (patientType) => {
    return patientType.patient_protocol_type !== PATIENT_PROTOCOL_PATIENT_VISIT;
  };

  visitsPatientComparator: PatientProtocolComparator = (patientType) => {
    return !this.otherCostsPatientComparator(patientType);
  };

  getCostsCols() {
    const groupedPaymentSchedules = groupBy(
      this.paymentSchedulesQuery.getAll(),
      'patient_protocol_id'
    );
    const siteColumns: (ColDef | ColGroupDef)[] = [];
    const siteSet = new Set<string>();
    this.patientProtocolQuery.getAll().forEach((patientProtocol) => {
      let data = {};
      const groupedPaymentSchedule = groupedPaymentSchedules[patientProtocol.id];
      if (groupedPaymentSchedule) {
        groupedPaymentSchedule.forEach((paymentSchedule) => {
          if (!siteSet.has(paymentSchedule.site_id)) {
            const site = this.sitesQuery.getEntity(paymentSchedule.site_id);

            if (site) {
              siteSet.add(paymentSchedule.site_id);
              siteColumns.push({
                headerName: site.site_no,
                headerClass: 'ag-header-align-center',
                tooltipField: paymentSchedule.site_id,
                headerTooltip: site.name,
                children: [
                  {
                    ...COST_COLUMN_PROPS,
                    headerName: `${paymentSchedule.sps_expense_currency}`,
                    field: `${paymentSchedule.site_id}${AMOUNT_PATTERN.PRIMARY}`,
                    valueFormatter: (params) =>
                      Utils.agCurrencyFormatter(
                        params,
                        paymentSchedule.sps_expense_currency as Currency
                      ),
                    cellRenderer: AgCellWrapperComponent,
                    cellRendererParams: {
                      customLocator: `${site.site_no}${AMOUNT_PATTERN.PRIMARY}`,
                    },
                    cellClass: [TableConstants.STYLE_CLASSES.EXCEL_ALIGN_RIGHT],
                  },
                  {
                    ...COST_COLUMN_PROPS,
                    headerName: `${paymentSchedule.sps_contract_expense_currency}`,
                    field: `${paymentSchedule.site_id}${AMOUNT_PATTERN.CONTRACT}`,
                    valueFormatter: (params) =>
                      Utils.agCurrencyFormatter(
                        params,
                        paymentSchedule.sps_contract_expense_currency as Currency
                      ),
                    cellRenderer: AgCellWrapperComponent,
                    cellRendererParams: {
                      customLocator: `${site.site_no}${AMOUNT_PATTERN.CONTRACT}`,
                    },
                    cellClass: [TableConstants.STYLE_CLASSES.EXCEL_ALIGN_RIGHT],
                  },
                ],
              } as ColDef | ColGroupDef);

              siteColumns.push(TableConstants.SPACER_COLUMN);
            }
          }
          data = {
            ...data,
            [paymentSchedule.site_id]: paymentSchedule.amount,
          };
        });
      }
    });

    return siteColumns.sort((a: ColDef, b: ColDef) =>
      Utils.alphaNumSort(a.headerName as string, b.headerName as string)
    );
  }

  onSiteSelected(site_ids: string[]) {
    this.selectedSiteValues = site_ids;

    const columns = this.gridColumnApi?.getAllColumns() || [];

    const allSitesIds = this.sitesQuery.getSortedListBy('site_no').map((site) => site.id);

    if (site_ids.length === 0) {
      const allSitesColumns = columns.filter(
        (column) => this.allCheck(allSitesIds, column) && this.canColumnBeVisible(column)
      );
      this.gridColumnApi.setColumnsVisible(allSitesColumns, true);
    } else {
      allSitesIds.forEach((id) => {
        if (site_ids.includes(id)) {
          const selectedColumns = columns.filter(
            (column) => this.check(id, column) && this.canColumnBeVisible(column)
          );
          this.gridColumnApi.setColumnsVisible(selectedColumns, true);
        } else {
          const unselectedColumns = columns.filter((column) => this.check(id, column));
          this.gridColumnApi.setColumnsVisible(unselectedColumns, false);
        }
      });
    }
    this.sizeColumnsToFit();
  }

  onGridReady({ api, columnApi }: GridReadyEvent) {
    api.sizeColumnsToFit();
    this.gridAPI = api;
    this.gridColumnApi = columnApi;
    this.gridApiChanged.emit({
      gridApi: this.gridAPI,
      columnApi,
    });
    this.gridAPI.resetRowHeights();
    Utils.updateGridLayout(this.gridAPI, 'patientBudgetGrid', true);

    this.selectedCurrencies$
      .pipe(untilDestroyed(this))
      .subscribe(({ isPrimaryCurrency, isContractCurrency }) => {
        const columns = this.gridColumnApi.getAllColumns() || [];

        const primaryColIds =
          this.selectedSiteValues.length === 0
            ? columns.filter((x) => x.getColId().includes(AMOUNT_PATTERN.PRIMARY))
            : columns.filter(
                (x) =>
                  x.getColId().includes(AMOUNT_PATTERN.PRIMARY) &&
                  this.allCheck(this.selectedSiteValues, x)
              );
        const contractColIds =
          this.selectedSiteValues.length === 0
            ? columns.filter((x) => x.getColId().includes(AMOUNT_PATTERN.CONTRACT))
            : columns.filter(
                (x) =>
                  x.getColId().includes(AMOUNT_PATTERN.CONTRACT) &&
                  this.allCheck(this.selectedSiteValues, x)
              );

        this.gridColumnApi?.setColumnsVisible(primaryColIds, isPrimaryCurrency);
        this.gridColumnApi?.setColumnsVisible(contractColIds, isContractCurrency);
        this.sizeColumnsToFit();
      });
  }

  check = (id: string, column: Column) => {
    return column.getColId().includes(id);
  };

  allCheck = (ids: string[], column: Column) => {
    const filteredIds = ids.filter((id) => this.check(id, column));
    return filteredIds.length !== 0;
  };

  canColumnBeVisible(column: Column) {
    return (
      (this.selectedCurrencies$.getValue().isPrimaryCurrency &&
        column.getColId().includes(AMOUNT_PATTERN.PRIMARY) &&
        !column.getColId().includes(AMOUNT_PATTERN.CONTRACT)) ||
      (this.selectedCurrencies$.getValue().isContractCurrency &&
        column.getColId().includes(AMOUNT_PATTERN.CONTRACT))
    );
  }

  onDataRendered() {
    this.gridAPI?.setPinnedBottomRowData([
      merge(
        {
          patient_protocol_name: 'Total',
        },
        this.generatePinnedBottomData(this.gridData$.getValue()),
        ...this.getCurrenciesForTotalRow()
      ),
    ]);
  }

  private getCurrenciesForTotalRow() {
    return this.gridColumnApi
      .getAllDisplayedColumns()
      ?.filter((col) =>
        [AMOUNT_PATTERN.CONTRACT, AMOUNT_PATTERN.PRIMARY].some((key) =>
          col.getColId().endsWith(key)
        )
      )
      .map((col) => {
        const colId = col.getColId();

        const isAmountContractCell = colId.endsWith(AMOUNT_PATTERN.CONTRACT);

        const amountKey = isAmountContractCell ? AMOUNT_PATTERN.CONTRACT : AMOUNT_PATTERN.PRIMARY;

        const currencyKey = isAmountContractCell
          ? CURRENCY_PATTERN.CONTRACT
          : CURRENCY_PATTERN.PRIMARY;

        const siteId = colId.replace(amountKey, '');

        return { [`${siteId}${currencyKey}`]: col.getColDef().headerName };
      });
  }

  generatePinnedBottomData(rows: Record<string, number>[]) {
    const ignoreColsForTotal = [
      'target_date_days_out',
      'target_tolerance_days_out',
      'patient_protocol_name',
      'patient_protocol_id',
    ];

    return rows.reduce((accum, value) => {
      const result = { ...accum };

      Object.entries(value).forEach(([key, val]) => {
        if (ignoreColsForTotal.indexOf(key) === -1) {
          result[key] = (accum[key] || 0) + val;
        }
      });

      return result;
    }, {});
  }

  sizeColumnsToFit(): void {
    this.gridAPI.sizeColumnsToFit();
  }
}
