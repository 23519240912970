import { Flags } from '@services/launch-darkly.service';
import { Route, Routes } from '@angular/router';

type Flag = keyof Flags;

export const buildRoutesByFeatureFlags = (
  featureFlags: Flags | null,
  flagRoutes: Routes
): Routes => {
  return flagRoutes.reduce((routes: Routes, featureFlagRoute: Route) => {
    const isFeatureFlagRouteEnabled =
      featureFlags && !!featureFlags[featureFlagRoute.data?.feature as Flag];
    if (isFeatureFlagRouteEnabled) {
      return [...routes, featureFlagRoute];
    }

    return routes;
  }, []);
};

export enum FeatureFlag {
  ACCOUNT_SETTINGS = 'tab_profile_settings',
  BUDGET_ENHANCED = 'tab_budget_budget_enhanced',
  BUDGET_LIBRARY = 'tab_budget_budget_library',
  BUDGET_CHANGE_ORDER = 'tab_change_order_review',
  CLOSING_RECONCILIATION = 'tab_budget_cash_management',
  FORECAST_DRIVERS = 'tab_forecast_drivers',
  PATIENT_TRACKER = 'nav_patient',
  PATIENT_BUDGET = 'tab_trial_settings_patient_protocol',
  SITES_INVESTIGATOR_DETAIL = 'tab_forecast_investigator_detail',
  SITES_INVESTIGATOR_FORECAST = 'tab_forecast_investigator_forecast',
  SETTINGS_EXCHANGE_RATES = 'tab_exchange_rates',
  SETTINGS_INTEGRATIONS = 'tab_integrations',
  VENDOR_PAYMENTS_PAYMENT_SCHEDULE = 'tab_budget_payment_schedule',
}
