import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { isEqual } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OverlayService } from '@services/overlay.service';
import { OrganizationStore } from '@models/organization/organization.store';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { FormControl } from '@angular/forms';
import { debounceTime, finalize, map, skip, startWith, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subscription } from 'rxjs';
import { memo } from 'helpful-decorators';
import {
  ActivityType,
  AmountType,
  BudgetType,
  DiscountType,
  DriverPatientGroup,
  DriverSiteGroup,
  DriverType,
  EntityType,
  EventType,
  ForecastMethodType,
  GqlService,
  listDriverSiteGroupsQuery,
  PermissionType,
  updateBudgetForecastSettingMutation,
} from '@services/gql.service';
import { ViewportScroller } from '@angular/common';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { EventService } from '@services/event.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { TimelineService } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.service';
import { PatientGroupsService } from 'src/app/pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.service';

import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { AuthService } from '@models/auth/auth.service';
import { Option } from '@components/components.type';
import { ActivatedRoute } from '@angular/router';
import { AuthQuery } from '@models/auth/auth.query';
import { getEntityType } from '@datorama/akita';
import { CategoryService } from './category/category.service';
import { ForecastAccrualsPageComponent } from '../../forecast-accruals-page.component';
import { CategoryQuery, FullActivity, FullCategory, FullSettings } from './category/category.query';
import { ForecastTimelineDialogComponent } from './forecast-timeline-dialog/forecast-timeline-dialog.component';
import { ForecastSettingsService } from './settings/forecast-settings.service';
import { ForecastSettingsQuery } from './settings/forecast-settings.query';
import { ForecastSettingsState, ForecastSettingsStore } from './settings/forecast-settings.store';
import { ForecastService } from './state/forecast.service';
import { ForecastQuery } from './state/forecast.query';
import { ActivityQuery } from './activity/activity.query';
import { PatientCurveService } from './patient-curve/patient-curve.service';
import { PatientCurveQuery } from './patient-curve/patient-curve.query';
import { ForecastDiscountDialogComponent } from './forecast-discount-dialog/forecast-discount-dialog.component';
import { WorkflowQuery } from '../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { WORKFLOW_NAMES } from '../../../closing-page/tabs/quarter-close/close-quarter-check-list';
import { ROUTING_PATH } from '../../../../app-routing-path.const';
import { CategoryStore } from './category/category.store';
import { MessagesConstants } from '../../../../constants/messages.constants';
import { SiteCurveQuery } from './drivers/forecast-sites/site-curve/site-curve.query';
import { SiteCurveService } from './drivers/forecast-sites/site-curve/site-curve.service';
import { FormControlConstants } from '../../../../constants/form-control.constants';

@UntilDestroy()
@Component({
  selector: 'aux-forecast',
  templateUrl: './forecast.component.html',
  styles: [
    `
      ::ng-deep .ng-select.ng-select-disabled .ng-arrow-wrapper {
        display: none;
      }

      ::ng-deep .ng-select.ng-select-disabled > .ng-select-container {
        background-color: var(--aux-gray-light);
      }

      ::ng-deep .ng-select.ng-select-disabled .ng-value {
        color: var(--aux-gray-dark-100);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastComponent implements AfterViewInit {
  readonly messagesConstants = MessagesConstants;

  readonly formControlConstants = FormControlConstants;

  @ViewChildren('forecastFilters') forecastFilters!: QueryList<TemplateRef<any>>;

  workflowName = WORKFLOW_NAMES.FORECAST_METHODOLOGY;

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  patientDriversLink = `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}`;

  siteDriversLink = `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES}`;

  isPatientDriversLinkVisible = false;

  isSiteDriversLinkVisible = false;

  isForecastFinalized$ = this.workflowQuery.getLockStatusByWorkflowName(this.workflowName);

  initialSettings: Record<string, FullSettings | undefined> = {};

  cards = [
    {
      header: 'Accrual Accuracy this Month / 3 months',
      data: '98% / 97%',
    },
    {
      header: 'Forecast Accuracy this month / 3 months',
      data: '96% / 95%',
    },
    {
      header: 'Straightline  approach / Modified approach',
      data: '65% / 35%',
    },
  ];

  DriverType = DriverType;

  driverOptions: Option<DriverType>[] = [
    {
      value: DriverType.DRIVER_PATIENT,
      label: 'Patient',
    },
    {
      value: DriverType.DRIVER_SITE,
      label: 'Site',
    },
    {
      value: DriverType.DRIVER_TIME,
      label: 'Time',
    },
  ];

  methodOptions: Option<ForecastMethodType>[] = [
    {
      value: ForecastMethodType.FORECAST_STRAIGHTLINE,
      label: 'Straight Line',
    },
    {
      value: ForecastMethodType.FORECAST_FRONTLOADED,
      label: 'Frontloaded',
    },
    {
      value: ForecastMethodType.FORECAST_BACKLOADED,
      label: 'Backloaded',
    },
    {
      value: ForecastMethodType.FORECAST_CUSTOM,
      label: 'Custom',
    },
  ];

  selectedVendor = new FormControl('');

  highlightedPatient: string | null = null;

  isOpen: { [key: string]: boolean } = {};

  edits = new Set<string>();

  saveCheck = new BehaviorSubject(false);

  showAnalyticsSection$: Observable<boolean>;

  activitiesByDriverLoading$ = new BehaviorSubject(false);

  unforecastedCostsLoading$ = new BehaviorSubject(false);

  isAdminUser = false;

  isSelectedVendorHasManualForecast$ = this.organizationQuery
    .selectActive()
    .pipe(
      map((org) => !!this.organizationQuery.getPrimaryBudgetVersion(org?.id || '')?.manual_forecast)
    );

  driverPatientGroups$ = this.patientCurveQuery.selectAll();

  siteDriverSettingId = '';

  unfilteredSiteCurveGroups: listDriverSiteGroupsQuery[] = [];

  hideDiscounts$ = new BehaviorSubject(false);

  budget_id!: string;

  isSiteDriverAvailable?: boolean;

  isPatientDriverAvailable?: boolean;

  successForecast$ = new BehaviorSubject(true);

  costCategory$ = this.categoryQuery.selectTopCategories$.pipe(
    map((cost) =>
      cost.filter(
        (x) =>
          x.category_type === 'CATEGORY_PASSTHROUGH' || x.category_type === 'CATEGORY_INVESTIGATOR'
      )
    )
  );

  isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

  serviceCategory$ = this.categoryQuery.selectTopCategories$.pipe(
    map((cost) =>
      cost.filter(
        (x) =>
          x.category_type !== 'CATEGORY_PASSTHROUGH' &&
          x.category_type !== 'CATEGORY_INVESTIGATOR' &&
          x.category_type !== 'CATEGORY_DISCOUNT'
      )
    )
  );

  changeDiscountTotalAmount = new FormControl(0);

  changeDiscountTotalPercent = new FormControl({ value: 0, disabled: this.isForecastFinalized$ });

  discountTypes = [
    { id: DiscountType.DISCOUNT_PERCENTAGE, label: '% of Relevant Services' },
    { id: DiscountType.DISCOUNT_TOTAL, label: '$ of Relevant Services' },
  ];

  selectedDiscountType = new FormControl({
    id: DiscountType.DISCOUNT_PERCENTAGE,
    label: '% of Relevant Services',
  });

  discountableTotal = 0;

  selectedDriverOptions = DriverType.DRIVER_TIME;

  selectedMethodOptions = ForecastMethodType.FORECAST_STRAIGHTLINE;

  expensesAmount = { amount: 0, amount_perct: 0 };

  subscription!: Subscription;

  userHasModifyPermissions = false;

  whenStickyPosition = false;

  doesNotHavePermissionsTooltip =
    'You do not have permission to perform this action. Please contact your system administrator if this has been restricted by mistake.';

  constructor(
    private route: ActivatedRoute,
    public organizationQuery: OrganizationQuery,
    public categoryQuery: CategoryQuery,
    private overlayService: OverlayService,
    private categoryService: CategoryService,
    public categoryStore: CategoryStore,
    private organizationStore: OrganizationStore,
    private organizationService: OrganizationService,
    private forecastPage: ForecastAccrualsPageComponent,
    private forecastSettingsService: ForecastSettingsService,
    private forecastSettingsQuery: ForecastSettingsQuery,
    private forecastSettingsStore: ForecastSettingsStore,
    private viewportScroller: ViewportScroller,
    private gqlService: GqlService,
    private launchDarklyService: LaunchDarklyService,
    private forecastService: ForecastService,
    public forecastQuery: ForecastQuery,
    private mainQuery: MainQuery,
    private timelineService: TimelineService,
    private activityQuery: ActivityQuery,
    private patientGroupsService: PatientGroupsService,
    private patientCurveService: PatientCurveService,
    private patientCurveQuery: PatientCurveQuery,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private workflowQuery: WorkflowQuery,
    private authQuery: AuthQuery,
    private eventService: EventService,
    private siteCurveQuery: SiteCurveQuery,
    private siteCurveService: SiteCurveService
  ) {
    this.patientGroupsService.get().pipe(untilDestroyed(this)).subscribe();
    this.patientCurveService.get().pipe(untilDestroyed(this)).subscribe();
    this.siteCurveService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.unfilteredSiteCurveGroups = val.data || [];
      });
    this.timelineService.getTimelineItems().pipe(untilDestroyed(this)).subscribe();
    this.getListBudgetVersionExpenses();

    this.initVendorFilter();

    combineLatest([this.isForecastFinalized$, this.iCloseMonthsProcessing$])
      .pipe(untilDestroyed(this))
      .subscribe(([isFinalized, iCloseMonthsProcessing]) => {
        if (isFinalized || iCloseMonthsProcessing) {
          this.changeDiscountTotalPercent.disable();
        } else {
          this.changeDiscountTotalPercent.enable();
        }
      });

    this.categoryService.getCategories().pipe(untilDestroyed(this)).subscribe();

    this.showAnalyticsSection$ = launchDarklyService.select$(
      (flags) => flags.section_forecast_analytics
    );

    this.showAnalyticsSection$
      .pipe(
        switchMap((flag) => {
          if (flag) {
            this.activitiesByDriverLoading$.next(true);
            return this.forecastService.getActivitiesByDriver();
          }
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.activitiesByDriverLoading$.next(false);
      });

    this.showAnalyticsSection$
      .pipe(
        switchMap((flag) => {
          if (flag) {
            this.unforecastedCostsLoading$.next(true);

            return this.forecastService.getUnforecastedCosts();
          }

          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.unforecastedCostsLoading$.next(false);
      });

    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          this.forecastSettingsStore.ui.update(() => ({ showError: false }));
          return combineLatest([
            this.patientCurveQuery.selectAll(),
            this.siteCurveQuery.selectAll(),
          ])
            .pipe(
              tap(([x, y]) => {
                this.isPatientDriverAvailable = !!x?.length;
                this.isSiteDriverAvailable = !!y?.length;
                if (this.isSiteDriverAvailable) {
                  this.siteDriverSettingId = y[0].driver_setting_id;
                }
              })
            )
            .toPromise();
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.selectedDiscountType.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
      if (val?.id === DiscountType.DISCOUNT_TOTAL) {
        this.changeDiscountTotalPercent.setValue(this.expensesAmount.amount_perct);
        this.changeDiscountTotalAmount.enable();
        this.changeDiscountTotalPercent.disable();
      } else if (val?.id === DiscountType.DISCOUNT_PERCENTAGE) {
        this.changeDiscountTotalAmount.disable();
        this.changeDiscountTotalPercent.enable();
        this.changeDiscountTotalAmount.setValue(this.expensesAmount.amount);
      }
    });

    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_MODIFY_FORECAST_METHODOLOGY],
      })
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.userHasModifyPermissions = x;
      });

    combineLatest([
      this.changeDiscountTotalAmount.valueChanges,
      this.changeDiscountTotalPercent.valueChanges,
    ])
      .pipe(
        skip(2),
        debounceTime(1000),
        untilDestroyed(this),
        tap(() => {
          if (
            this.expensesAmount?.amount !== this.changeDiscountTotalAmount.value ||
            this.expensesAmount?.amount_perct !== this.changeDiscountTotalPercent.value
          ) {
            this.saveCheck.next(true);
          }
        })
      )
      .subscribe();

    this.authQuery.adminUser$.pipe(untilDestroyed(this)).subscribe((event) => {
      this.isAdminUser = event;
    });

    this.eventService
      .select$(EventType.TRIAL_CHANGED)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.saveCheck.next(false);
        this.initialSettings = {};
      });
  }

  ngAfterViewInit(): void {
    this.forecastFilters.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        finalize(() => {
          setTimeout(() => {
            this.forecastPage.rightSideContainer.next(null);
          }, 0);
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.forecastPage.rightSideContainer.next(this.forecastFilters.first || null);
        }, 0);
      });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const header = document.getElementById('stickyHeader');

    const sticky = header?.offsetTop;

    this.whenStickyPosition = typeof sticky !== 'undefined' && window.scrollY > sticky;
  }

  async canDeactivate(): Promise<boolean> {
    if (this.saveCheck.getValue()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await result.afterClosed$.toPromise();
      return !!event.data;
    }
    return true;
  }

  getPatientGroupName(id: string | null | undefined): DriverPatientGroup | null {
    if (typeof id !== 'string') {
      return null;
    }
    return this.patientCurveQuery.getEntity(id) || null;
  }

  getSiteGroupName(id: string | null | undefined): DriverSiteGroup | null {
    if (typeof id !== 'string') {
      return null;
    }
    return this.siteCurveQuery.getEntity(id) || null;
  }

  onPeriodChange(settings: FullSettings, mode: { name: 'cat' | 'act'; id: string }) {
    const ref = this.overlayService.open<
      { phaseID: string | null; startMilID: string | null; endMilID: string | null } | null,
      { settings: FullSettings }
    >({
      content: ForecastTimelineDialogComponent,
      data: { settings },
    });
    ref.afterClosed$.subscribe((value) => {
      if (value.data) {
        const { endMilID, startMilID, phaseID } = value.data;

        this.edits.add(settings.id);
        this.saveCheck.next(true);
        this.setInitialSettings(settings.id);

        this.forecastSettingsStore.update(settings.id, {
          milestone_category: phaseID,
          period_start_milestone_id: startMilID,
          period_end_milestone_id: endMilID,
        });

        if (mode.name === 'act') {
          this.afterActivityStateChange(mode.id);
        } else {
          this.afterCategoryStateChange(mode.id);
        }
      }
    });
  }

  onDriverChange(
    e: DriverType | null,
    settings: FullSettings,
    mode: { name: 'cat' | 'act'; id: string }
  ) {
    this.edits.add(settings.id);
    this.setInitialSettings(settings.id);

    this.saveCheck.next(true);

    if (
      e &&
      (e === DriverType.DRIVER_SITE ||
        e === DriverType.DRIVER_PATIENT ||
        e === DriverType.DRIVER_SERVICES_BLENDED)
    ) {
      if (
        (e === DriverType.DRIVER_SITE && !this.isSiteDriverAvailable) ||
        (e === DriverType.DRIVER_PATIENT && !this.isPatientDriverAvailable) ||
        e === DriverType.DRIVER_SERVICES_BLENDED
      ) {
        this.forecastSettingsStore.update(settings.id, {
          driver: e,
        });
        if (e === DriverType.DRIVER_SITE) {
          this.forecastSettingsStore.ui.update(settings.id, () => ({
            showError: true,
            errorMessage: 'Site Driver has not been added',
          }));
        } else if (e === DriverType.DRIVER_PATIENT) {
          this.forecastSettingsStore.ui.update(settings.id, () => ({
            showError: true,
            errorMessage: 'Patient Driver has not been added',
          }));
        }
      } else {
        this.forecastSettingsStore.update(settings.id, {
          driver: e,
          driver_setting_id: null,
          forecast_method: null,
          period_start_milestone_id: null,
          period_end_milestone_id: null,
        });

        this.forecastSettingsStore.ui.update(settings.id, () => ({
          showError: false,
          errorMessage: '',
        }));
      }
    } else {
      this.forecastSettingsStore.update(settings.id, {
        driver: e,
      });
      this.forecastSettingsStore.ui.update(settings.id, () => ({
        showError: false,
        errorMessage: '',
      }));
    }
    const uiForecast = this.forecastSettingsStore.ui.getValue();
    const isThereAnyOtherError = uiForecast?.ids?.some((id) => {
      return !!uiForecast?.entities?.[id]?.showError;
    });

    if (!isThereAnyOtherError && !this.successForecast$.getValue()) {
      this.successForecast$.next(true);
    }

    if (mode.name === 'act') {
      this.afterActivityStateChange(mode.id);
    } else {
      this.afterCategoryStateChange(mode.id);
    }
  }

  onMethodChange(
    e: ForecastMethodType | string | null,
    settings: FullSettings,
    mode: {
      name: 'act' | 'cat';
      prop: 'forecast_method' | 'driver_setting';
      id: string;
    }
  ) {
    this.edits.add(settings.id);
    this.setInitialSettings(settings.id);
    this.saveCheck.next(true);
    if (mode.prop === 'driver_setting') {
      this.forecastSettingsStore.update(settings.id, {
        driver_setting_id: e as string,
      });
    } else {
      this.forecastSettingsStore.update(settings.id, {
        forecast_method: e as ForecastMethodType,
      });
    }

    if (mode.name === 'act') {
      this.afterActivityStateChange(mode.id);
    } else {
      this.afterCategoryStateChange(mode.id);
    }
  }

  identify(index: number, item: FullCategory) {
    return item.id;
  }

  onOrganizationSelected(orgId: string) {
    this.organizationStore.setActive(orgId);
    this.saveCheck.next(false);
    this.initialSettings = {};
  }

  getCatType(cat: any) {
    return cat as FullCategory;
  }

  @memo()
  isCatOpen(id: string) {
    return this.categoryQuery.ui.selectEntity(id, 'isOpen') as Observable<boolean>;
  }

  @memo()
  isCategoryUnforecasted(id: string) {
    return this.forecastSettingsQuery.ui.selectEntity(id, 'unforecasted') as Observable<boolean>;
  }

  @memo()
  isActivityUnforecasted(act_id: string) {
    return this.activityQuery.selectEntity(act_id).pipe(
      switchMap((act) => {
        return combineLatest([
          this.forecastSettingsQuery.selectEntity(act?.primary_settings_id),
          this.forecastSettingsQuery.ui.selectEntity(act?.primary_settings_id),
        ]).pipe(
          map(([settings, ui]) => {
            const catOverride = !!this.forecastSettingsQuery.getEntity(
              this.categoryQuery.getEntity(act?.category_id)?.primary_settings_id
            )?.override;
            return (settings?.override || !catOverride) && ui?.unforecasted;
          })
        );
      })
    );
  }

  @memo()
  isCatLoading(id: string) {
    return this.categoryQuery.ui.selectEntity(id, 'isLoading') as Observable<boolean>;
  }

  @memo()
  isShowError(set_id: string) {
    return this.forecastSettingsQuery.ui.selectEntity(set_id, 'showError') as Observable<boolean>;
  }

  @memo()
  isShowErrorMessage(set_id: string) {
    return this.forecastSettingsQuery.ui.selectEntity(set_id, 'errorMessage') as Observable<string>;
  }

  toggleCat(id: string) {
    this.categoryService.toggleCategory(id);
  }

  isCategoryIndeterminate(category_id: string) {
    const num_activity_checked_count = this.getCatType(this.categoryQuery.getEntity(category_id))
      .activity_checked_count;
    if (num_activity_checked_count) {
      return num_activity_checked_count > 0;
    }
    return false;
  }

  toggleCatApplyAll(checked: boolean, settings: FullSettings, category_id: string) {
    const ids = this.getAllSubSettingIDs(category_id);
    const isCategoryIndeterminate = this.isCategoryIndeterminate(category_id);

    this.setInitialSettings(settings.id);

    ids.forEach((id) => {
      this.edits.add(id);
    });
    this.edits.add(settings.id);

    if (checked || isCategoryIndeterminate) {
      ids.forEach((id) => {
        this.edits.delete(id);
      });
    }

    this.saveCheck.next(true);
    const obj = {
      forecast_method: null,
      driver_setting_id: null,
      period_end_date: null,
      period_start_date: null,
      period_start_milestone_id: null,
      period_end_milestone_id: null,
      driver: null,
      milestone_category: null,
    };
    this.forecastSettingsService.update(settings.id, {
      override: !settings.override,
      ...obj,
    });
    this.forecastSettingsStore.update(ids, {
      ...obj,
    });
    this.forecastSettingsStore.ui.update(settings.id, {
      unforecasted: false,
    });

    this.afterCategoryStateChange(category_id);
  }

  saveChanges = async () => {
    const promises: Promise<{
      success: boolean;
      data: updateBudgetForecastSettingMutation | null;
      errors: string[];
    } | null>[] = [];
    let discountUpdated = false;
    if (
      this.expensesAmount?.amount !== this.changeDiscountTotalAmount.value ||
      this.expensesAmount?.amount_perct !== this.changeDiscountTotalPercent.value
    ) {
      if (this.discountableTotal !== 0) {
        this.setDiscountAmounts();
      }
      const budgetInput = {
        discount_amount: Math.abs(this.changeDiscountTotalAmount.value) * -1 || 0,
        discount_amount_perct: this.changeDiscountTotalPercent.value || 0,
        discount_type: this.selectedDiscountType.value?.id || null,
        id: this.budget_id,
      };
      await this.gqlService.updateBudgetVersion$(budgetInput).toPromise();
      discountUpdated = true;
    }

    for (const id of this.edits) {
      const set = this.forecastSettingsQuery.getEntity(id);
      this.successForecast$.next(true);
      this.forecastSettingsStore.ui.update(id, () => ({ showError: false }));
      let success = true;

      if (
        this.isDriverTimeFilled(set) ||
        this.isDriverSettingIdFilled(set, DriverType.DRIVER_PATIENT) ||
        this.isDriverSettingIdFilled(set, DriverType.DRIVER_SITE)
      ) {
        success = false;
        this.forecastSettingsStore.ui.update(id, () => ({ showError: true }));
      }

      if (
        (set?.driver === DriverType.DRIVER_PATIENT && !this.isPatientDriverAvailable) ||
        (set?.driver === DriverType.DRIVER_SITE && !this.isSiteDriverAvailable)
      ) {
        this.isPatientDriversLinkVisible =
          set?.driver === DriverType.DRIVER_PATIENT && !this.isPatientDriverAvailable;
        this.isSiteDriversLinkVisible =
          set?.driver === DriverType.DRIVER_SITE && !this.isSiteDriverAvailable;
        this.successForecast$.next(false);
        this.forecastSettingsStore.ui.update(id, () => ({ showError: true }));
        this.viewportScroller.scrollToAnchor('notSuccessForecastMessage');
        return;
      }

      if (!success) {
        this.viewportScroller.scrollToAnchor(id);
        return;
      }

      promises.push(this.forecastSettingsService.syncSetting(id));
    }

    let forecastUpdated = false;
    if (promises.length || discountUpdated) {
      const ref = this.overlayService.loading();
      const responses = await Promise.all(promises);
      if (responses.every((x) => x?.success) || discountUpdated) {
        const { success, errors } = await this.gqlService
          .processEvent$({
            type: EventType.BUDGET_FORECAST_SETTINGS_UPDATED,
            entity_type: EntityType.ORGANIZATION,
            entity_id: this.selectedVendor.value,
          })
          .toPromise();

        if (success) {
          forecastUpdated = true;
          this.edits.clear();
        } else {
          forecastUpdated = false;
          this.overlayService.error(errors);
        }
      } else {
        forecastUpdated = false;
      }
      ref.close();
    }

    if (forecastUpdated || discountUpdated) {
      this.overlayService.success('Changes successfully saved!');
      this.saveCheck.next(false);
      this.initialSettings = {};
    } else {
      this.overlayService.error('Something went wrong');
    }
    this.getListBudgetVersionExpenses();
  };

  toggleActivityApplyAll(
    checked: boolean,
    activity: FullActivity,
    category_settings: FullSettings
  ) {
    const activity_settings = activity.primary_settings;
    this.edits.add(activity_settings.id);
    this.setInitialSettings(activity_settings.id);
    this.saveCheck.next(true);
    this.forecastSettingsService.update(
      activity_settings.id,
      checked
        ? {
            override: !activity_settings.override,
            forecast_method: category_settings.forecast_method,
            driver: category_settings.driver,
            driver_setting_id: category_settings.driver_setting_id,
            period_start_milestone_id: category_settings.period_start_milestone_id,
            period_end_milestone_id: category_settings.period_end_milestone_id,
            milestone_category: category_settings.milestone_category?.id,
          }
        : {
            override: !activity_settings.override,
            forecast_method: null,
            driver: null,
            driver_setting_id: null,
            period_start_milestone_id: null,
            period_end_milestone_id: null,
            milestone_category: null,
          }
    );

    this.forecastSettingsStore.ui.update(activity_settings.id, {
      unforecasted: false,
    });

    const overrideCount = !activity_settings.override ? 1 : -1;
    this.categoryService.updateCategoryOverride(activity.category_id, overrideCount);

    this.afterActivityStateChange(activity.id);
  }

  async onOpenDiscountDialog() {
    const overlayRef = await this.overlayService
      .open({
        content: ForecastDiscountDialogComponent,
        data: { vendorId: this.selectedVendor.value },
      })
      .afterClosed$.toPromise();

    if (overlayRef.data) {
      this.discountableTotal = this.getDiscountableTotal();
      this.setDiscountAmounts();
    }
  }

  @memo()
  getTimeMethod(forecast_method: string | null) {
    const val = this.methodOptions.filter((x) => x.value === forecast_method);
    return val[0]?.label || '';
  }

  onHideActivitiesWithNoRemainingCost(bool: boolean) {
    this.categoryStore.hideActivitiesWithNoRemainingCost$.next(bool);
  }

  onShowOnlyUnforecasted(bool: boolean) {
    this.categoryStore.hideForecastedActivities$.next(bool);
  }

  clearFilters() {
    this.categoryStore.hideActivitiesWithNoRemainingCost$.next(false);
    this.categoryStore.hideForecastedActivities$.next(false);
  }

  pageLockedTooltipText(isActionDisabled: boolean): string {
    return isActionDisabled ? MessagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE : '';
  }

  driverTooltipText(isForecastFinalized: boolean): string {
    if (isForecastFinalized) {
      return MessagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE;
    }

    return this.userHasModifyPermissions ? '' : this.doesNotHavePermissionsTooltip;
  }

  methodAndPeriodTooltipText(isForecastFinalized: boolean): string {
    if (isForecastFinalized) {
      return MessagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE;
    }

    return this.userHasModifyPermissions ? '' : MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
  }

  private isDriverTimeFilled(settings: getEntityType<ForecastSettingsState> | undefined): boolean {
    return !!(
      settings?.driver &&
      settings?.driver === DriverType.DRIVER_TIME &&
      (!settings?.forecast_method ||
        !settings?.period_start_milestone_id ||
        !settings?.period_end_milestone_id)
    );
  }

  private isDriverSettingIdFilled(
    settings: getEntityType<ForecastSettingsState> | undefined,
    driverType: DriverType
  ): boolean {
    return !!(settings?.driver && settings?.driver === driverType && !settings.driver_setting_id);
  }

  private getAllSubSettingIDs(cat_id: string) {
    const settings_ids: string[] = [];
    const category = this.categoryQuery.getEntity(cat_id);
    if (category) {
      settings_ids.push(
        ...this.activityQuery
          .getAll({
            filterBy: (activity) =>
              this.forecastSettingsQuery.getEntity(activity.primary_settings_id)?.override ===
                false && category.activity_ids.includes(activity.id),
          })
          .map((activity) => activity.primary_settings_id)
      );

      this.categoryQuery
        .getAll({
          filterBy: (sub_category) =>
            this.forecastSettingsQuery.getEntity(sub_category.primary_settings_id)?.override ===
              false && sub_category.parent_category_id === cat_id,
        })
        .forEach((sub_category) => {
          settings_ids.push(sub_category.primary_settings_id);
          settings_ids.push(...this.getAllSubSettingIDs(sub_category.id));
        });
    }
    return settings_ids;
  }

  private afterActivityStateChange(activity_id: string) {
    const needFlag = this.isActivityNeedUnforecastedFlag(activity_id);
    const act = this.activityQuery.getEntity(activity_id);
    if (!act || !act.primary_settings_id) {
      return;
    }
    const hasFlag = !!this.forecastSettingsQuery.ui.getEntity(act.primary_settings_id)
      ?.unforecasted;

    if (hasFlag !== needFlag) {
      this.forecastSettingsStore.ui.update(act.primary_settings_id, {
        unforecasted: needFlag,
      });

      this.afterCategoryStateChange(act.category_id);
    }

    this.checkChanges(act.primary_settings_id);
  }

  private isCategoryNeedUnforecastedFlag(category_id: string) {
    const cat = this.categoryQuery.getEntity(category_id);
    if (!cat) {
      return true;
    }
    const hasError = this.checkIfCategoryHasError(category_id);
    if (hasError) {
      return true;
    }

    const isCatOpen = !!this.categoryQuery.ui.getEntity(category_id)?.isOpen;
    if (!isCatOpen) {
      return !!cat.activity_checked_count;
    }

    return (
      this.checkIfAnyChildCategoriesHasError(category_id) ||
      this.checkIfAnyChildActivitiesHasError(category_id)
    );
  }

  private isActivityNeedUnforecastedFlag(activity_id: string) {
    return this.checkIfActivityHasError(activity_id);
  }

  private checkIfCategoryHasError(category_id: string) {
    const cat = this.categoryQuery.getEntity(category_id);
    if (!cat) {
      return true;
    }

    const settings_id = cat.primary_settings_id;
    if (!settings_id) {
      return true;
    }
    const set = this.forecastSettingsQuery.getEntity(settings_id);
    if (!set) {
      return true;
    }
    if (!set.override) {
      return false;
    }

    return cat.parent_category_id ? false : this.checkIfSettingHasError(settings_id);
  }

  private checkIfSettingHasError(settings_id: string) {
    const settings = this.forecastSettingsQuery.getEntity(settings_id);
    if (!settings) {
      return true;
    }

    if (!settings.driver) {
      return true;
    }

    switch (settings.driver) {
      case DriverType.DRIVER_PATIENT:
        return !(this.isPatientDriverAvailable && settings.driver_setting_id);
      case DriverType.DRIVER_SITE:
        return !(this.isSiteDriverAvailable && settings.driver_setting_id);
      case DriverType.DRIVER_TIME:
        return !(
          settings.forecast_method &&
          settings.period_end_milestone_id &&
          settings.period_start_milestone_id
        );
      default:
        return true;
    }
  }

  private checkIfActivityHasError(activity_id: string) {
    const act = this.activityQuery.getEntity(activity_id);
    if (!act) {
      return true;
    }

    const settings_id = act.primary_settings_id;
    if (!settings_id) {
      return true;
    }

    return this.checkIfSettingHasError(settings_id);
  }

  private checkIfAnyChildActivitiesHasError(category_id: string) {
    const cat = this.categoryQuery.getEntity(category_id);
    if (!cat) {
      return true;
    }

    const cat_override = this.forecastSettingsQuery.getEntity(cat.primary_settings_id)?.override;

    let activities = cat.activity_ids.map((act_id) => this.activityQuery.getEntity(act_id));

    if (cat_override) {
      activities = activities.filter(
        (act) => this.forecastSettingsQuery.getEntity(act?.primary_settings_id)?.override
      );
    }

    if (activities.length) {
      return activities.some((act) => {
        if (!act) {
          return true;
        }

        return this.checkIfActivityHasError(act.id);
      });
    }

    return false;
  }

  private checkIfAnyChildCategoriesHasError(category_id: string): boolean {
    const cat = this.categoryQuery.getEntity(category_id);
    if (!cat) {
      return true;
    }

    if (cat.sub_category_ids.length) {
      return cat.sub_category_ids.some((sub_cat_id) => {
        return (
          this.checkIfCategoryHasError(sub_cat_id) ||
          this.checkIfAnyChildCategoriesHasError(sub_cat_id) ||
          this.checkIfAnyChildActivitiesHasError(sub_cat_id)
        );
      });
    }

    return false;
  }

  private setInitialSettings(id: string) {
    if (!this.initialSettings[id]) {
      this.initialSettings[id] = this.forecastSettingsQuery.getEntity(id) as FullSettings;
    }
  }

  private checkChanges(id: string) {
    const hasNoChanges = isEqual(
      this.initialSettings[id],
      this.forecastSettingsQuery.getEntity(id)
    );

    if (hasNoChanges) {
      this.edits.delete(id);
    }

    this.saveCheck.next(!!this.edits.size);
  }

  private afterCategoryStateChange(category_id: string) {
    const needFlag = this.isCategoryNeedUnforecastedFlag(category_id);
    const cat = this.categoryQuery.getEntity(category_id);
    if (!cat || !cat.primary_settings_id) {
      return;
    }
    const hasFlag = !!this.forecastSettingsQuery.ui.getEntity(cat.primary_settings_id)
      ?.unforecasted;

    if (hasFlag !== needFlag) {
      this.forecastSettingsStore.ui.update(cat.primary_settings_id, {
        unforecasted: needFlag,
      });

      if (cat.parent_category_id) {
        this.afterCategoryStateChange(cat.parent_category_id);
      }
    }

    this.checkChanges(cat.primary_settings_id);
  }

  private setVendorId(vendorId: string) {
    this.selectedVendor.setValue(vendorId);
    this.organizationStore.setActive(vendorId);
  }

  private initVendorFilter() {
    combineLatest([this.route.queryParams, this.organizationService.get()])
      .pipe(untilDestroyed(this))
      .subscribe(([{ vendor_id }]) => {
        const orgId = this.organizationQuery.getActive()?.id;

        if (orgId) {
          this.setVendorId(orgId);
        } else {
          const vendors = this.organizationQuery.getAllVendors();
          if (vendors.length) {
            const vendorFromQueryUrl = vendors.find(({ id }) => id === vendor_id);

            this.setVendorId(vendorFromQueryUrl?.id || vendors[0].id);
          }
        }
      });
  }

  private getListBudgetVersionExpenses() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.organizationQuery
      .selectActiveId()
      .pipe(
        switchMap((vendor_id) => {
          if (vendor_id) {
            return this.gqlService.listBudgetVersionsExpenses$(
              [BudgetType.BUDGET_PRIMARY],
              vendor_id
            );
          }
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe(({ data, success, errors }) => {
        if (!this.changeDiscountTotalAmount.value) {
          this.changeDiscountTotalAmount.setValue(0);
          this.expensesAmount.amount = 0;
        }
        if (!this.changeDiscountTotalPercent.value) {
          this.changeDiscountTotalPercent.setValue(0);
          this.expensesAmount.amount_perct = 0;
        }
        if (success && data?.length) {
          const current_budget = data.filter((b) => b.is_current)[0];
          this.budget_id = current_budget.budget_version_id;
          this.selectedDiscountType.setValue(
            this.discountTypes.filter(
              (x) => x.id === (current_budget.discount_type || DiscountType.DISCOUNT_PERCENTAGE)
            )[0]
          );
          let showDiscount = false;
          data[0].expense_amounts.forEach((x) => {
            if (x.amount_type === AmountType.AMOUNT_DISCOUNT) {
              this.expensesAmount.amount = x.amount || 0;
              this.expensesAmount.amount_perct = x.amount_perct || 0;
              showDiscount = showDiscount || !!x.amount || !!x.amount_perct;
              this.changeDiscountTotalAmount.setValue(x.amount || 0);
              this.changeDiscountTotalPercent.setValue(x.amount_perct || 0);
              this.discountableTotal = this.getDiscountableTotal();
              if (this.discountableTotal !== 0) {
                this.setDiscountAmounts();
              }
            }
          });
          this.hideDiscounts$.next(!showDiscount);
        } else {
          this.overlayService.error(errors);
        }
      });
  }

  private getDiscountableTotal() {
    return this.activityQuery.getAll().reduce((total_cost, activity) => {
      if (
        (activity as FullActivity).activity_type === ActivityType.ACTIVITY_SERVICE &&
        activity.discount_flag
      ) {
        return total_cost + (activity.total_cost || 0);
      }
      return total_cost;
    }, 0);
  }

  private setDiscountAmounts() {
    if (this.selectedDiscountType.value.id === DiscountType.DISCOUNT_PERCENTAGE) {
      this.expensesAmount.amount =
        Math.round(this.discountableTotal * Math.abs(this.expensesAmount.amount_perct || 0)) / -100;
      this.changeDiscountTotalAmount.setValue(this.expensesAmount.amount || 0, {
        emitEvent: false,
      });
    } else if (this.selectedDiscountType.value.id === DiscountType.DISCOUNT_TOTAL) {
      const calc =
        Math.round((Math.abs(this.expensesAmount.amount) / this.discountableTotal) * 10000) / 100 ||
        0;
      this.expensesAmount.amount_perct = Number.isFinite(calc) ? calc : 0;
      this.changeDiscountTotalPercent.setValue(this.expensesAmount.amount_perct || 0, {
        emitEvent: false,
      });
      this.changeDiscountTotalAmount.setValue(
        Math.abs(this.changeDiscountTotalAmount.value) * -1 || 0,
        {
          emitEvent: false,
        }
      );
      this.expensesAmount.amount = Math.abs(this.changeDiscountTotalAmount.value) * -1;
    }
  }
}
