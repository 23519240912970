import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

const INITIAL_POSITION = '-70px';
const ANIMATE_TIMING = '0.3s linear';

@Component({
  selector: 'aux-save-changes',
  templateUrl: './save-changes.component.html',
  styles: [
    `
      .save-changes {
        box-shadow: 0 -2px 5px 0 rgba(229, 229, 229, 1);
      }
    `,
  ],
  animations: [
    trigger('upDownAnimation', [
      transition(':enter', [
        style({ bottom: INITIAL_POSITION }),
        animate(ANIMATE_TIMING, style({ bottom: 0 })),
      ]),
      transition(':leave', [animate(ANIMATE_TIMING, style({ bottom: INITIAL_POSITION }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveChangesComponent implements OnInit, OnDestroy {
  @Input() onSaveChanges!: VoidFunction;

  moveJiraWidget(offset: string) {
    const widget = document.getElementById('jsd-widget');
    if (widget) {
      widget.style.bottom = offset;
    }
  }

  ngOnDestroy(): void {
    this.moveJiraWidget('0');
  }

  ngOnInit(): void {
    this.moveJiraWidget('90px');
  }
}
