<form class="font-inter" [formGroup]="fg" (ngSubmit)="onSave()">
  <div class="w-screen max-w-md"></div>
  <div class="text-xl font-bold mb-4">Confirm Manual Override</div>

  <div class="text-xs mb-2">Enter New Forecast:</div>
  <aux-input class="mb-8 w-16" formControlName="units"></aux-input>

  <div class="text-xs mb-2">Spread difference (10 units) over:</div>
  <div class="flex space-x-4 mb-8">
    <div class="flex items-center">
      <input
        [id]="'spread-next'"
        name="spread_type"
        type="radio"
        value="Next Month"
        class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
        formControlName="spread_type"
      />
      <label [for]="'spread-next'" class="ml-2">
        <span class="block text-sm font-medium text-gray-700">Next Month</span>
      </label>
    </div>
    <div class="flex items-center">
      <input
        [id]="'spread-total'"
        name="spread_type"
        type="radio"
        value="Total Period"
        class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
        formControlName="spread_type"
      />
      <label [for]="'spread-total'" class="ml-2">
        <span class="block text-sm font-medium text-gray-700">Total Period</span>
      </label>
    </div>
  </div>

  <div class="flex space-x-4 mt-8">
    <button class="w-48 text-sm btn btn--blue" type="submit">
      <span class="spinner w-6 h-6 mr-3" *ngIf="loading$ | async"></span>
      <span>Save</span>
    </button>

    <button
      class="text-sm font-normal aux-link focus:outline-none"
      (click)="ref.close()"
      type="button"
    >
      Cancel
    </button>
  </div>
</form>
