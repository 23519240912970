import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Utils } from '@services/utils';
import { differenceWith, isEqual, isBoolean } from 'lodash-es';
import {
  GridApi,
  GridOptions,
  ICellRendererParams,
  RowGroupOpenedEvent,
  RowNode,
} from 'ag-grid-community';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MilestoneCategoryService } from '@models/milestone-category/milestone-category.service';
import { MilestoneQuery } from '@models/milestone-category/milestone/milestone.query';
import { MilestoneCategoryQuery } from '@models/milestone-category/milestone-category.query';
import { BehaviorSubject } from 'rxjs';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { OverlayService } from '@services/overlay.service';
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import { AgActionsComponent } from '@components/ag-actions/ag-actions.component';
import { AuthQuery } from '@models/auth/auth.query';
import { AuthService } from '@models/auth/auth.service';
import { CreateTimelineMilestoneInput, EventType, PermissionType } from '@services/gql.service';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import {
  TimelineService,
  UpdateDependencyInput,
  UpdateTimelineMilestone,
} from './state/timeline.service';
import { TimelineQuery } from './state/timeline.query';
import { TimelineDialogComponent } from './timeline-dialog/timeline-dialog.component';
import { TimelineDialogFormValue } from './timeline-dialog/timeline-dialog.model';
import { WorkflowQuery } from '../../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { WORKFLOW_NAMES } from '../../../../closing-page/tabs/quarter-close/close-quarter-check-list';
import { TableConstants } from '../../../../../constants/table.constants';
import { MessagesConstants } from '../../../../../constants/messages.constants';
import { MainQuery } from '../../../../../layouts/main-layout/state/main.query';

interface TimelineGridData {
  milestone_category_id: string;
  milestone_name: string;
  milestone_id: string;
  track_from_milestone_name: string;
  track_from_milestone_id: string | null;
  contract_start_date: string;
  contract_end_date: string;
  revised_start_date: string | null;
  revised_end_date: string | null;
  actual_start_date: string | null;
  actual_end_date: string | null;
  description: string | null;
  id: string;
}

@UntilDestroy()
@Component({
  selector: 'aux-timeline',
  templateUrl: './timeline.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent {
  readonly messagesConstants = MessagesConstants;

  gridData$ = new BehaviorSubject<TimelineGridData[]>([]);

  previousGridData: TimelineGridData[] = [];

  expandedState: Record<string, boolean> = {};

  userHasModifyPermissions$ = new BehaviorSubject<boolean>(false);

  workflowName = WORKFLOW_NAMES.TIMELINE;

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  isTimeLineFinalized$ = this.workflowQuery.getLockStatusByWorkflowName(this.workflowName);

  isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

  hasChanges$ = new BehaviorSubject(false);

  saving$ = new BehaviorSubject(false);

  initialValues: TimelineGridData[] = [];

  valuesBeforeRemove: TimelineGridData[] = [];

  createInputs: Omit<CreateTimelineMilestoneInput, 'timeline_id'>[] = [];

  updateInputs: UpdateTimelineMilestone[] = [];

  updateDependencyInputs: UpdateDependencyInput[] = [];

  deleteInputs: string[] = [];

  disabledStateTooltipText$ = new BehaviorSubject('');

  gridAPI!: GridApi;

  gridOptions = {
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      sortable: false,
      minWidth: TableConstants.ACTIONS_WIDTH,
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    groupDisplayType: TableConstants.AG_SYSTEM.CUSTOM,
    suppressAutoSize: true,
    initialGroupOrderComparator: (params) => {
      const a = this.milestoneCategoryQuery.getEntity(params.nodeA.key)?.grouping_order || 0;
      const b = this.milestoneCategoryQuery.getEntity(params.nodeB.key)?.grouping_order || 0;
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    },
    columnDefs: [
      {
        headerName: '',
        field: 'actions',
        getQuickFilterText: () => '',
        cellRendererSelector: (params: ICellRendererParams) => {
          return params.node.group || !params.node.parent ? '' : { component: AgActionsComponent };
        },
        cellRendererParams: {
          processing$: this.iCloseMonthsProcessing$,
          disabled$: this.isTimeLineFinalized$,
          editClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
            this.onUpdateLine(rowNode);
          },
          hideDeleteButton: !this.authQuery.getValue().is_admin,
          deleteClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
            this.onRemoveLine(rowNode);
          },
          tooltipText$: this.disabledStateTooltipText$,
        },
        editable: false,
        width: TableConstants.ACTIONS_WIDTH,
        cellClass: TableConstants.STYLE_CLASSES.CELL_JUSTIFY_CENTER,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Category',
        field: 'milestone_category_id',
        getQuickFilterText: () => '',
        rowGroup: true,
        hide: true,
        valueFormatter: (params: ValueFormatterParams) => {
          const category = this.milestoneCategoryQuery.getEntity(params.value);
          return category?.name || '';
        },
      },
      {
        headerName: 'Name',
        field: 'milestone_name',
        minWidth: 250,
        tooltipField: 'milestone_name',
        cellClass: 'text-left',
        showRowGroup: true,
        cellRenderer: TableConstants.AG_SYSTEM.AG_GROUP_CELL_RENDERER,
        cellRendererParams: {
          suppressCount: true,
        },
      },
      {
        headerName: 'Description',
        field: 'description',
        getQuickFilterText: () => '',
        minWidth: 250,
        tooltipField: 'description',
        cellClass: 'text-left',
      },
      {
        headerName: 'Track From',
        field: 'track_from_milestone_name',
        getQuickFilterText: () => '',
        minWidth: 200,
        tooltipField: 'track_from_milestone_name',
        cellClass: 'text-left',
      },
      {
        headerName: 'Months',
        getQuickFilterText: () => '',
        minWidth: 100,
        valueFormatter: (val) => {
          if (!val.data) {
            return '';
          }
          const {
            contract_start_date,
            contract_end_date,
            revised_start_date,
            revised_end_date,
            actual_start_date,
            actual_end_date,
          } = val.data;

          return Utils.getDurationInMonths(
            new Date(actual_start_date || revised_start_date || contract_start_date),
            new Date(actual_end_date || revised_end_date || contract_end_date)
          );
        },
      },
      {
        headerName: 'Start Date',
        getQuickFilterText: () => '',
        minWidth: 150,
        valueFormatter: (val) => {
          if (!val.data) {
            return '';
          }
          const { contract_start_date, revised_start_date, actual_start_date } = val.data;

          if (actual_start_date) return `${actual_start_date}`;
          if (revised_start_date) return `${revised_start_date}`;
          return `${contract_start_date}`;
        },
      },
      {
        headerName: 'End Date',
        getQuickFilterText: () => '',
        minWidth: 150,
        valueFormatter: (val) => {
          if (!val.data) {
            return '';
          }
          const { contract_end_date, revised_end_date, actual_end_date } = val.data;

          if (actual_end_date) return `${actual_end_date}`;
          if (revised_end_date) return `${revised_end_date}`;
          return `${contract_end_date}`;
        },
      },
    ],
    getRowClass: Utils.oddEvenRowClass,
  } as GridOptions;

  nameFilterValue = '';

  isAdminUser = false;

  constructor(
    private timelineService: TimelineService,
    public timelineQuery: TimelineQuery,
    private milestoneCategoryService: MilestoneCategoryService,
    private milestoneQuery: MilestoneQuery,
    private milestoneCategoryQuery: MilestoneCategoryQuery,
    private overlayService: OverlayService,
    private authQuery: AuthQuery,
    private workflowQuery: WorkflowQuery,
    private authService: AuthService,
    private mainQuery: MainQuery
  ) {
    this.milestoneCategoryService.get().pipe(untilDestroyed(this)).subscribe();
    this.timelineService.getTimelineItems().pipe(untilDestroyed(this)).subscribe();
    this.timelineQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe(({ items }) => {
        const gridData: TimelineGridData[] = [];
        items.forEach((item) => {
          const track_milestone = this.milestoneQuery.getEntity(item.track_from_milestone?.id);
          const milestone = this.milestoneQuery.getEntity(item.milestone.id);
          const data: TimelineGridData = {
            id: item.id,
            milestone_category_id: item.milestone.milestone_category_id,
            milestone_name: milestone?.name || item.milestone.name || '',
            milestone_id: item.milestone.id,
            track_from_milestone_name: track_milestone?.name || '',
            track_from_milestone_id: item.track_from_milestone?.id || null,
            contract_start_date: item.contract_start_date,
            contract_end_date: item.contract_end_date,
            revised_start_date: item.revised_start_date || null,
            revised_end_date: item.revised_end_date || null,
            actual_start_date: item.actual_start_date || null,
            actual_end_date: item.actual_end_date || null,
            description: milestone?.description || null,
          };
          gridData.push(data);
        });
        this.gridData$.next(gridData);
      });
    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_MODIFY_TRIAL_TIMELINE],
      })
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.userHasModifyPermissions$.next(x);
      });
    this.authQuery.adminUser$.pipe(untilDestroyed(this)).subscribe((event) => {
      this.isAdminUser = event;
    });

    this.gridData$.pipe(untilDestroyed(this)).subscribe((gridData) => {
      this.initialValues = gridData;

      if (this.valuesBeforeRemove.length) {
        setTimeout(() => {
          this.gridAPI?.setRowData(this.valuesBeforeRemove);
          this.valuesBeforeRemove = [];
        }, 0);
      }

      if (!this.hasChanges$.getValue()) {
        this.gridAPI?.setRowData(gridData);
      }

      this.checkChanges();
    });

    this.isTimeLineFinalized$.pipe(untilDestroyed(this)).subscribe((val: boolean): void => {
      this.disabledStateTooltipText$.next(
        val ? MessagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE : ''
      );
    });
  }

  addTimelineMilestone() {
    const ref = this.overlayService.open<
      Omit<CreateTimelineMilestoneInput, 'timeline_id'>,
      { mode: 'edit' | 'add'; formValue?: TimelineDialogFormValue; forbiddenNames?: string[] }
    >({
      content: TimelineDialogComponent,
      data: {
        mode: 'add',
        forbiddenNames: this.gridData$.value.map((x) => x.milestone_name),
      },
    });

    ref.afterClosed$.subscribe(async (resp) => {
      if (resp.data) {
        this.createInputs.push(resp.data);
      }
    });
  }

  async onRemoveLine(rowNode: RowNode) {
    if (!rowNode.data) {
      return;
    }

    const { id, milestone_name, milestone_id } = rowNode.data as TimelineGridData;
    const { items } = this.timelineQuery.getValue();
    const tracking_milestones = items.filter((x) => x.track_from_milestone?.id === milestone_id);
    if (tracking_milestones?.length > 0) {
      let milestoneNames = '';
      for (const tracking_milestone of tracking_milestones) {
        milestoneNames += `- ${tracking_milestone.milestone.name}<br>`;
      }
      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Milestone',
        message: `Are you sure that you want to remove ${milestone_name}? The following milestones will no longer track this milestone:<br>${milestoneNames}`,
        okBtnText: 'Remove',
      });
      const event = await resp.afterClosed$.toPromise();
      if (event.data?.result) {
        for (const tracking_milestone of tracking_milestones) {
          const milestone = this.milestoneQuery.getEntity(tracking_milestone.milestone.id);
          const input: UpdateTimelineMilestone = {
            id: tracking_milestone.id,
            name: tracking_milestone.milestone.name,
            milestone_category_id: tracking_milestone.milestone.milestone_category_id,
            milestone_id: tracking_milestone.milestone.id,
            description: milestone?.description || null,
            track_from_milestone_id: null,
            actual_end_date: tracking_milestone.actual_end_date,
            actual_start_date: tracking_milestone.actual_start_date,
            contract_end_date: tracking_milestone.contract_end_date,
            contract_start_date: tracking_milestone.contract_start_date,
            revised_end_date: tracking_milestone.revised_end_date,
            revised_start_date: tracking_milestone.revised_start_date,
          };
          // eslint-disable-next-line no-await-in-loop
          await this.timelineService.updateTimelineMilestone(input);
          this.updateInputs.push(input);
        }
        this.gridAPI.removeItems([rowNode]);
        await this.timelineService.deleteTimelineMilestone(id);
        this.deleteInputs.push(id);
        this.hasChanges$.next(true);
      }
    } else {
      const resp = this.overlayService.openConfirmDialog({
        header: 'Remove Milestone',
        message: `Are you sure that you want to remove ${milestone_name}?`,
        okBtnText: 'Remove',
      });
      const event = await resp.afterClosed$.toPromise();
      if (event.data?.result) {
        this.gridAPI.removeItems([rowNode]);
        await this.timelineService.deleteTimelineMilestone(id);
        this.deleteInputs.push(id);
        this.hasChanges$.next(true);
      }
    }
  }

  onUpdateLine(rowNode: RowNode) {
    if (!rowNode.data) {
      return;
    }

    const {
      actual_end_date,
      actual_start_date,
      contract_end_date,
      contract_start_date,
      milestone_category_id,
      track_from_milestone_id,
      revised_end_date,
      revised_start_date,
      milestone_name,
      description,
      id,
      milestone_id,
    } = rowNode.data as TimelineGridData;

    const ref = this.overlayService.open<
      { update: UpdateTimelineMilestone; updateDependency: UpdateDependencyInput[] },
      {
        mode: 'edit' | 'add';
        forbiddenNames: string[];
        formValue?: TimelineDialogFormValue;
        timeline_milestone_id?: string;
        milestone_id?: string;
      }
    >({
      content: TimelineDialogComponent,
      data: {
        mode: 'edit',
        forbiddenNames: this.gridData$.value
          .filter((x) => x.milestone_name !== milestone_name)
          .map((x) => x.milestone_name),
        formValue: {
          id,
          description,
          name: milestone_name,
          milestone_id,
          milestone_category_id,
          revised_end_date,
          revised_start_date,
          contract_start_date,
          contract_end_date,
          actual_end_date,
          actual_start_date,
          track_from_milestone_id,
        },
        milestone_id,
        timeline_milestone_id: id,
      },
    });

    ref.afterClosed$.subscribe(async (resp) => {
      if (resp.data) {
        this.updateInputs.push(resp.data.update);
        this.updateDependencyInputs.push(...resp.data.updateDependency);
      }
    });
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;
    this.gridOptions.columnApi?.setColumnVisible(
      'actions',
      this.userHasModifyPermissions$.getValue()
    );
    api.sizeColumnsToFit();
    this.updateGridLayout();
  }

  onDataRendered({ api }: { api: GridApi }) {
    api.forEachNode((node) => {
      if (node.key && !isBoolean(this.expandedState[node.key])) {
        node.setExpanded(true);
      }
    });

    api.sizeColumnsToFit();
  }

  onGroupOpened({ expanded, node }: RowGroupOpenedEvent) {
    if (node.key) {
      this.expandedState[node.key] = expanded;
    }

    this.updateGridLayout();
  }

  onRowDataChanged() {
    const gridData = this.gridData$.value;
    const changedMilestoneCategoryId = differenceWith(gridData, this.previousGridData, isEqual)[0]
      ?.milestone_category_id;

    this.gridOptions.api?.forEachNode((rowNode: RowNode) => {
      if (rowNode.key) {
        const currentExpandedState = isBoolean(this.expandedState[rowNode.key])
          ? this.expandedState[rowNode.key]
          : true;
        const expanded = rowNode.key === changedMilestoneCategoryId ? true : currentExpandedState;

        rowNode.setExpanded(expanded);
      }
    });

    this.sizeColumnsToFit();
    this.checkChanges();
    this.previousGridData = gridData;
  }

  sizeColumnsToFit(): void {
    this.gridOptions.api?.sizeColumnsToFit();
  }

  updateGridLayout(): void {
    Utils.updateGridLayout(this.gridAPI, 'timelineGrid');
  }

  checkChanges() {
    this.hasChanges$.next(
      this.createInputs.length > 0 ||
        this.updateInputs.length > 0 ||
        this.updateDependencyInputs.length > 0 ||
        this.deleteInputs.length > 0
    );
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges$.getValue()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await result.afterClosed$.toPromise();
      return !!event.data;
    }
    return true;
  }

  onSaveAll = async () => {
    this.saving$.next(true);

    if (this.deleteInputs.length > 0) {
      this.deleteInputs.forEach((x) => {
        this.timelineService.deleteTimelineMilestone(x);
      });
    }
    const resp = await this.timelineService.saveChanges(
      this.createInputs,
      this.updateInputs,
      this.updateDependencyInputs,
      this.deleteInputs
    );

    if (resp.success) {
      this.overlayService.success(`Successfully Saved`);
    } else {
      this.overlayService.error(`An error has occurred`);
    }

    this.createInputs = [];
    this.updateInputs = [];
    this.updateDependencyInputs = [];
    this.deleteInputs = [];
    this.saving$.next(false);
    this.hasChanges$.next(false);
  };
}
