// eslint-disable-next-line import/no-extraneous-dependencies
import * as Zen from 'zen-observable-ts';
import gql from 'fake-tag';
import { printError } from 'graphql';
import API, { graphqlOperation } from '@aws-amplify/api-graphql';
import {
  AnalyticsCardInput,
  EventType,
  getAnalyticsCardQuery,
  GqlService,
  NotificationPage,
  NotificationStatus,
  notificationSubscription,
  SubscriptionResponse,
} from '@services/gql.service';
import { zenToRx } from '@services/utils';
import { Observable, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, filter, map, pluck, startWith, switchMap } from 'rxjs/operators';
import { AuthQuery } from '@models/auth/auth.query';
import { OverlayService } from '@services/overlay.service';
import { NotificationPages, SubscriptionEvents } from '@services/event.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { AuthService } from '@models/auth/auth.service';
import { MainStore } from '../layouts/main-layout/state/main.store';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class EventService {
  events$ = new Subject<{
    type: EventType;
    data: SubscriptionEvents[NotificationStatus][EventType];
    page: NotificationPage;
    status: NotificationStatus;
  }>();

  select$<T extends keyof SubscriptionEvents[NotificationStatus.SUCCESS]>(type: T) {
    return this.events$.pipe(
      filter((event) => {
        return event.status === NotificationStatus.SUCCESS && event.type === type;
      }),
      pluck('data')
    ) as Observable<SubscriptionEvents[NotificationStatus.SUCCESS][T]>;
  }

  selectAnalyticsCard$(input: AnalyticsCardInput) {
    return this.select$(EventType.ANALYTICS_CARD_UPDATED).pipe(
      startWith(null),
      filter((e) => {
        return e ? e.analyticsCardType === input.analytics_card_type : true;
      }),
      switchMap((data) => {
        if (data) {
          return of({
            success: true,
            errors: [] as string[],
            data: {
              data: data.analyticsCardData,
              primary_key: data.entity_id,
              sort_key: input.analytics_card_type,
              __typename: 'AnalyticsCard',
            } as getAnalyticsCardQuery,
          });
        }
        return this.gqlService.getAnalyticsCard$(input);
      })
    );
  }

  constructor(
    private authQuery: AuthQuery,
    private overlayService: OverlayService,
    private router: Router,
    private gqlService: GqlService,
    private authService: AuthService,
    private mainStore: MainStore
  ) {
    this.authQuery
      .select('sub')
      .pipe(
        switchMap((sub) => {
          if (sub) {
            return this.notificationListener$(sub);
          }

          return of(null);
        }),
        untilDestroyed(this)
      )
      .subscribe((value) => {
        if (value) {
          const { errors, data, success } = value;
          if (success && data) {
            this.events$.next({
              type: data.type,
              data: data.json,
              page: data.page,
              status: data.status,
            });
          } else if (errors) {
            this.overlayService.error(errors);
          }
        }
      });

    this.events$.pipe(untilDestroyed(this)).subscribe(({ type, data, page, status }) => {
      // region Event log
      console.group(`New Event ::: ${type}`);
      console.log(`DATA ::: `, data);
      console.log(`PAGE ::: ${page}`);
      console.log(`STATUS ::: ${status}`);
      console.log(`CURRENT URL ::: ${this.router.url}`);
      console.groupEnd();
      // endregion

      const {
        notification_message,
      } = data as SubscriptionEvents[NotificationStatus.ERROR][typeof type];

      if (
        notification_message &&
        (page === NotificationPage.GLOBAL || NotificationPages[page] === this.router.url)
      ) {
        switch (status) {
          case NotificationStatus.SUCCESS:
            this.overlayService.success(notification_message);
            break;
          case NotificationStatus.ERROR:
            this.overlayService.error(notification_message, 6e5);
            break;
          default:
            this.overlayService.success(notification_message);
            break;
        }
      }

      this.mainStore.setProcessingLoadingState(type, false);
    });
  }

  notificationListener$(
    sub: string
  ): Observable<{
    success: boolean;
    errors: string[];
    data: {
      json: SubscriptionEvents[NotificationStatus][EventType];
      sub: string;
      type: EventType;
      page: NotificationPage;
      status: NotificationStatus;
    } | null;
  }> {
    const statement = gql`
      subscription notification($sub: String!) {
        notification(sub: $sub) {
          __typename
          data
          sub
          type
          status
          page
        }
      }
    `;
    const gqlAPIServiceArguments: any = {
      sub,
    };
    return zenToRx(
      API.graphql(graphqlOperation(statement, gqlAPIServiceArguments)) as Zen.Observable<
        SubscriptionResponse<{ notification: notificationSubscription }>
      >
    ).pipe(
      map(({ value: { errors: graphQLError, data: rawData } }) => {
        let errors = [] as string[];
        if (graphQLError) {
          errors = graphQLError.map((error) => printError(error));
        }

        if (rawData) {
          const { type, data: strJson, page, status } = rawData.notification;
          let json: any;
          try {
            json = JSON.parse(strJson);
          } catch (e) {
            console.error(e);
            return {
              success: false,
              errors: ['json could not be parsed'],
              data: null,
            };
          }
          return {
            success: true,
            errors,
            data: { json, sub, type, page, status },
          };
        }

        return {
          success: true,
          errors,
          data: null,
        };
      }),
      catchError((err) => {
        return of({
          success: false,
          data: null,
          errors: (err.errors || []).map((res: { message: string }) => res.message) as string[],
        });
      })
    );
  }
}
