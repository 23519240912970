import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgHeroiconsModule } from '@dimaslz/ng-heroicons';
import { DirectivesModule } from '@directives/directives.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { ComponentsModule } from '../components.module';
import { NotificationToastComponent } from './notification-toast/notification-toast.component';
import { SpinnerDialogComponent } from './spinner-dialog/spinner-dialog.component';
import { TimeoutDialogComponent } from './timeout-dialog/timeout-dialog.component';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    UserDialogComponent,
    NotificationToastComponent,
    SpinnerDialogComponent,
    TimeoutDialogComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgHeroiconsModule,
    FormsModule,
    DirectivesModule,
  ],
  exports: [ConfirmDialogComponent, UserDialogComponent],
})
export class DialogsModule {}
