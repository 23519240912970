import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { WorkflowModel } from './close-quarter-check-list/store';

type InMonthAdjustmentParams = ICellRendererParams & {
  current_month: boolean;
  adjustmentWorkflow$: Observable<WorkflowModel>;
  isWorkflowAvailable$: Observable<boolean>;
  userHasAdjustPermission$: Observable<boolean>;
};

@Component({
  template: `
    <div class="flex items-center w-full">
      <div class="flex-grow"></div>
      <div [attr.auto-qa]="autoTestAttribute">{{ params.valueFormatted }}</div>
      <div class="text-aux-blue cursor-pointer ml-1.5" *ngIf="!params.node.rowPinned">
        <ng-template #edit>
          <span
            inlineSVG="edit.svg"
            data-id="edit-icon-inmonth-adjustment"
            [setSVGAttributes]="{ class: 'w-3 h-3' }"
            *ngIf="params.current_month"
          ></span>
        </ng-template>
        <ng-template #eye>
          <span inlineSVG="eye.svg" [setSVGAttributes]="{ class: 'w-4 h-4' }"></span>
        </ng-template>
        <ng-container
          *ngIf="{
            isQuarterCloseEnabled: params.isWorkflowAvailable$ | async,
            adjustmentWorkflow: params.adjustmentWorkflow$ | async,
            userHasAdjustPermission: params.userHasAdjustPermission$ | async
          } as obj"
        >
          <ng-container *ngIf="obj.adjustmentWorkflow && obj.isQuarterCloseEnabled">
            <ng-container
              [ngTemplateOutlet]="edit"
              *ngIf="
                obj.adjustmentWorkflow.properties.locked === false && obj.userHasAdjustPermission
              "
            ></ng-container>
            <ng-container
              [ngTemplateOutlet]="eye"
              *ngIf="
                !params.current_month ||
                obj.adjustmentWorkflow.properties.locked ||
                !obj.userHasAdjustPermission
              "
            ></ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="(params.isWorkflowAvailable$ | async) === false">
          <ng-container [ngTemplateOutlet]="edit"></ng-container>
          <ng-container [ngTemplateOutlet]="eye" *ngIf="!params.current_month"></ng-container>
        </ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgQuarterCloseAdjustmentComponent
  extends AgCellWrapperComponent
  implements ICellRendererAngularComp {
  params!: InMonthAdjustmentParams;

  agInit(params: InMonthAdjustmentParams): void {
    this.params = params;
    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }

  refresh(): boolean {
    return false;
  }
}
