import { Injectable } from '@angular/core';
import { CreatePatientProtocolInput, GqlService, PatientProtocolType } from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';

import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { switchMap, tap } from 'rxjs/operators';
import { PatientProtocolModel, PatientProtocolStore } from './patient-protocol.store';

@Injectable({ providedIn: 'root' })
export class PatientProtocolService {
  constructor(
    private patientProtocolStore: PatientProtocolStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService
  ) {}

  get(
    patient_protocol_types: Array<PatientProtocolType> = [],
    group_id: string = '',
    should_get_all = false
  ) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.patientProtocolStore.setLoading(true);
        this.patientProtocolStore.remove(() => true);

        return this.gqlService.listPatientProtocols$(patient_protocol_types).pipe(
          tap(({ success, data }) => {
            if (success && data) {
              if (should_get_all) {
                this.patientProtocolStore.set(data);
              } else {
                const patientProtocols = group_id
                  ? data.filter((x) => x.patient_group_id === group_id)
                  : data.filter((x) => !x.patient_group_id);

                this.patientProtocolStore.set(patientProtocols);
              }
            }
            this.patientProtocolStore.setLoading(false);
          })
        );
      })
    );
  }

  getForPatientBudgetTable(patient_protocol_types: Array<PatientProtocolType> = []) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.patientProtocolStore.setLoading(true);
        this.patientProtocolStore.remove(() => true);
        return this.gqlService.listPatientProtocols$(patient_protocol_types).pipe(
          tap(({ success, data }) => {
            if (success && data) {
              this.patientProtocolStore.set(data);
            }
            this.patientProtocolStore.setLoading(false);
          })
        );
      })
    );
  }

  async add(patientProtocol: CreatePatientProtocolInput) {
    const { errors, success, data } = await this.gqlService
      .createPatientProtocol$(patientProtocol)
      .toPromise();

    if (success && data) {
      this.patientProtocolStore.add(data);
    } else {
      this.overlayService.error(errors);
    }

    return {
      success,
      errors,
      data,
    };
  }

  update(id: string, patientProtocol: Partial<PatientProtocolModel>) {
    this.patientProtocolStore.update(id, patientProtocol);
  }

  async upsert(
    upsertData: (CreatePatientProtocolInput & {
      patient_protocol_id: string | null;
    })[]
  ) {
    const allErrors: string[][] = [];

    for (const mil of upsertData) {
      const {
        patient_protocol_id,
        patient_protocol_type,
        patient_group_id,
        name,
        target_date_days_out,
        target_tolerance_days_out,
        order_by,
      } = mil;
      if (patient_protocol_id) {
        // eslint-disable-next-line no-await-in-loop
        await this.gqlService
          .updatePatientProtocol$({
            id: patient_protocol_id,
            patient_protocol_type,
            name,
            target_date_days_out: target_date_days_out || 0,
            target_tolerance_days_out: target_tolerance_days_out || 0,
            order_by,
          })
          .pipe(
            tap(({ success, data, errors }) => {
              if (success && data) {
                this.patientProtocolStore.update(data.id, data);
              } else {
                allErrors.push(errors);
              }
            })
          )
          .toPromise();
      } else {
        // eslint-disable-next-line no-await-in-loop
        await this.gqlService
          .createPatientProtocol$({
            patient_protocol_type,
            name,
            patient_group_id,
            target_date_days_out: target_date_days_out || 0,
            target_tolerance_days_out: target_tolerance_days_out || 0,
            order_by,
          })
          .pipe(
            tap(({ success, data, errors }) => {
              if (success && data) {
                this.patientProtocolStore.add(data);
              } else {
                allErrors.push(errors);
              }
            })
          )
          .toPromise();
      }
    }

    if (allErrors.length) {
      this.overlayService.error(...allErrors);
    }

    return !!allErrors.length;
  }

  async remove(ids: string[]) {
    const proms: Promise<any>[] = [];
    const allErrors: string[][] = [];
    ids.forEach((id) => {
      proms.push(
        this.gqlService
          .removePatientProtocol$(id)
          .pipe(
            tap(({ success, errors, data }) => {
              if (success && data) {
                this.patientProtocolStore.remove(id);
              } else {
                allErrors.push(errors);
              }
            })
          )
          .toPromise()
      );
    });

    await Promise.all(proms);

    if (allErrors.length) {
      this.overlayService.error(...allErrors);
    }

    return !!allErrors.length;
  }
}
