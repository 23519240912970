<cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <cdk-nested-tree-node
    *cdkTreeNodeDef="let node"
    class="flex items-center h-10 px-4 text-aux-black cursor-pointer hover:bg-gray-100 focus:outline-none"
    [routerLink]="node.url"
    #active="routerLinkActive"
    routerLinkActive=""
  >
    <ng-container
      *ngVar="isActiveLink(node.url, active.isActive, node.activeUrls || []) as isActive"
    >
      <div
        class="flex h-7.5 items-center justify-center"
        [ngClass]="{
          'text-aux-blue': isActive,
          'w-7.5': obj.svg
        }"
        *ngIf="{
          svg: (isActive && node.activeSvg) || node.svg
        } as obj"
      >
        <div [inlineSVG]="obj.svg" *ngIf="obj.svg" [ngClass]="node.className"></div>
        <div class="relative" *ngIf="!obj.svg && isActive">
          <div class="h-6 w-1 bg-aux-blue rounded-full absolute -bottom-3"></div>
        </div>
      </div>
      <a
        [ngClass]="{ 'text-aux-blue font-bold': isActive }"
        class="flex-grow font-medium text-sm ml-2"
      >
        {{ node.name }}
      </a>
    </ng-container>
  </cdk-nested-tree-node>

  <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: isDivider">
    <hr class="my-4" />
  </cdk-nested-tree-node>

  <cdk-nested-tree-node
    *cdkTreeNodeDef="let node; when: hasChild"
    class="h-10 text-aux-black"
    [routerLink]="node.url"
    routerLinkActive=""
    #active="routerLinkActive"
  >
    <div class="flex h-10 items-center px-4 cursor-pointer hover:bg-gray-100">
      <div
        *ngIf="{
          svg: (active.isActive && node.activeSvg) || node.svg
        } as obj"
        class="flex h-7.5 items-center justify-center"
        [ngClass]="{
          'text-aux-blue': active.isActive,
          'w-7.5': obj.svg
        }"
      >
        <div [inlineSVG]="obj.svg" *ngIf="obj.svg" [ngClass]="node.className"></div>
        <div class="relative" *ngIf="!obj.svg && active.isActive">
          <div class="h-6 w-1 bg-aux-blue rounded-full absolute -bottom-3"></div>
        </div>
      </div>

      <a
        [ngClass]="{ 'text-aux-blue font-bold': active.isActive }"
        class="flex-grow font-medium text-sm ml-2"
      >
        {{ node.name }}
      </a>

      <button
        [attr.aria-label]="'Toggle ' + node.name"
        cdkTreeNodeToggle
        [ngClass]="{
          '-rotate-180': treeControl.isExpanded(node),
          'text-aux-blue': active.isActive
        }"
        class="focus:outline-none transition duration-300 transform ease-in-out w-8 h-8 rounded-full flex items-center justify-center hover:bg-gray-200"
      >
        <chevron-down-solid-icon></chevron-down-solid-icon>
      </button>
    </div>

    <div class="overflow-hidden" [@slideVertical]="treeControl.isExpanded(node) ? 'show' : null">
      <ng-container cdkTreeNodeOutlet></ng-container>
    </div>
  </cdk-nested-tree-node>
</cdk-tree>
