import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WorkflowModel, WorkflowQuery, WorkflowService } from '../close-quarter-check-list/store';
import { getAuditText } from './workflow-panel.utils';
import { WORKFLOW_NAMES } from '../close-quarter-check-list';
import { ROUTING_PATH } from '../../../../../app-routing-path.const';

@UntilDestroy()
@Component({
  selector: 'aux-workflow-panel',
  templateUrl: './workflow-panel.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPanelComponent implements OnInit {
  @Input() workflowName!: string;

  @Input() className = '';

  @Input() isAdminUser = false;

  @Input() processing = false;

  quarterCloseLink = `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`;

  workflowList$ = this.workflowQuery.selectAll({
    filterBy: [
      (workFlow) => workFlow.name !== this.workflowName,
      (workflow) => workflow.name !== WORKFLOW_NAMES.ADJUSTMENT,
    ],
  });

  isLoading$ = this.workflowQuery.selectLoading();

  getTitleFn = getAuditText(
    'Locked by',
    'Unlocked - You must lock this section before closing your month.'
  );

  getAuditTextFn = (workflow: WorkflowModel): string => {
    if (!workflow.properties.locked) {
      return 'Unlocked';
    }

    return `Locked on ${workflow.update_date}`;
  };

  constructor(private workflowService: WorkflowService, private workflowQuery: WorkflowQuery) {}

  ngOnInit(): void {
    this.workflowService.getWorkflowList(this.isAdminUser).pipe(untilDestroyed(this)).subscribe();
  }
}
