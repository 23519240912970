import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DocumentLibraryService } from '../document-library.service';

@Component({
  selector: 'aux-document-library-filters',
  templateUrl: './document-library-filters.component.html',
  styleUrls: ['./document-library-filters.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentLibraryFiltersComponent {
  @Input() onFilterChange!: () => void;

  @Input() form!: FormGroup;

  constructor(public documentLibrary: DocumentLibraryService) {}

  resetAllFilters() {
    Object.entries(this.form.controls).forEach(([controlName, control]) => {
      if (controlName !== 'table') {
        control.reset();
      }
    });

    this.onFilterChange();
  }
}
