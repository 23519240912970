import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { RequireSome } from '@services/utils';
import { BudgetVersion } from '@services/gql.service';

export interface BudgetLibraryState
  extends EntityState<RequireSome<Partial<BudgetVersion>, 'budget_version_id'>> {}

export type BudgetLibraryModel = getEntityType<BudgetLibraryState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'budget-library' })
export class BudgetLibraryStore extends EntityStore<BudgetLibraryState> {
  constructor() {
    super({});
  }
}
