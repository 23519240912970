import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'aux-forms',
  templateUrl: './forms.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormsComponent {
  selectedOption = new FormControl();

  datePicked = new FormControl();

  option = '';

  textInput = '';

  textInputWithDescription = '';

  searchBar = '';

  textFieldFilledOut = 'Filled out field';

  getLineNumbers(upperBound: number) {
    return Array(upperBound)
      .fill(1)
      .map((x, i) => i + 1);
  }
}
