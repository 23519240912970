import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TimelineQuery } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.query';

import { MilestoneState, MilestoneStore } from './milestone.store';

@Injectable({ providedIn: 'root' })
export class MilestoneQuery extends QueryEntity<MilestoneState> {
  constructor(protected store: MilestoneStore, private timelineQuery: TimelineQuery) {
    super(store);
  }
}
