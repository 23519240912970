<div
  *ngIf="currentWorkflow$ | async as currentWorkflow"
  class="border border-aux-gray-dark flex items-center justify-between p-2.5 pl-4 pr-4 bg-aux-gray-light"
>
  <div class="flex items-center">
    <span inlineSVG="lock.svg" *ngIf="currentWorkflow.properties.locked"></span>
    <span inlineSVG="lock-open.svg" *ngIf="!currentWorkflow.properties.locked"></span>
    <div class="ml-3">
      <p class="text-aux-black font-bold" *ngIf="isWorkflowNameVisible">
        {{ currentWorkflow.name }}
      </p>
      <p class="text-sm">
        {{ auditTextCallback(currentWorkflow) }}
      </p>
    </div>
  </div>

  <div [auxTooltip]="tooltipButton" *ngIf="currentWorkflow.properties.locked">
    <button
      class="btn--secondary pl-3 bg-white"
      (click)="onToggleLockWorkflow(currentWorkflow)"
      [disabled]="!lockAvailable"
    >
      <span class="mr-2" inlineSVG="lock-open.svg"></span>
      Unlock
    </button>
  </div>

  <div [auxTooltip]="tooltipButton" *ngIf="!currentWorkflow.properties.locked">
    <button
      class="btn--primary pl-3"
      (click)="onToggleLockWorkflow(currentWorkflow)"
      [disabled]="!lockAvailable"
    >
      <span class="mr-2" inlineSVG="lock.svg"></span>
      Lock {{ currentWorkflow.name }}
    </button>
  </div>
</div>
