<div class="flex justify-between items-center mb-8">
  <h1 class="text-2xl text-aux-gray-darkest font-bold h-11 flex items-center">Sites</h1>
  <div class="flex space-x-2">
    <ng-container [ngTemplateOutlet]="rightSideContainer | async"></ng-container>
  </div>
</div>
<div>
  <div class="mb-4">
    <aux-tab-group [tabs]="tabs"></aux-tab-group>
  </div>

  <router-outlet></router-outlet>
</div>
