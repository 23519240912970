<ng-container *ngIf="(paymentMilestoneQuery.selectLoading() | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<div *ngIf="(paymentMilestoneQuery.selectLoading() | async) === false">
  <div class="flex justify-end mb-4">
    <aux-button
      *ngIf="(editModeGrid$ | async) === false"
      variant="secondary"
      label="Edit"
      icon="pencil"
      classList="h-8"
      [svgAttributes]="{ class: 'w-4 h-4' }"
      [onClick]="editGrid.bind(this)"
    ></aux-button>

    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="secondary"
      label="Cancel"
      icon="x"
      classList="h-8"
      [svgAttributes]="{ class: 'w-4 h-4' }"
      [onClick]="cancelEditMode.bind(this)"
    ></aux-button>

    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="success"
      label="Save"
      icon="check"
      classList="h-8"
      class="ml-1"
      [disabled]="!hasChanges"
      [svgAttributes]="{ class: 'w-4 h-4' }"
      [onClick]="onSaveAll"
    ></aux-button>
  </div>

  <ag-grid-angular
    id="paymentMilestonesGrid"
    class="ag-theme-aux tabular-nums mb-4"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    [enableFillHandle]="(editModeGrid$ | async) === true"
    (gridReady)="gridReady($event)"
    (cellValueChanged)="cellValueChanged($event)"
    (gridSizeChanged)="sizeColumnsToFit()"
  >
  </ag-grid-angular>

  <div class="mt-4">
    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="primary"
      label="Add Payment Milestone"
      [onClick]="onAddPaymentMilestone.bind(this)"
    ></aux-button>
  </div>
</div>
