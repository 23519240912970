import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { listDriverSiteDistributionsQuery } from '@services/gql.service';

export interface SiteDistributionState extends EntityState<listDriverSiteDistributionsQuery> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'site-distribution' })
export class SiteDistributionStore extends EntityStore<SiteDistributionState> {
  constructor() {
    super({});
  }
}
